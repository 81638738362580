export default {
  spacerThickness: 75,
  spacerCPSQM: 170,

  // US is in $ based on Lucid total costs
  spacerCostPerLinearMetre: {
    uk: 12.75,
    eu: 12.75,
    us: 15
  },

  wastage: 1.15,
  continuousGrainWastage: 1.4,
  usWastage: 1.4,
  usContinuousGrainWastage: 1.4,

  plinthClipsPerPlinth: 5,

  handleOrientations: {
    h: 'Horizontal',
    v: 'Vertical',
    c: 'Centered',
    vc: 'Vertically Centered',
    vbt: 'Vertical Base Top',
    vwb: 'Vertical Wall Bottom',
    updn: 'Centered Upside Down'
  },

  regions: {
    uk: {
      text: 'United Kingdom',
      value: 'uk',
      currencies: [{ value: 'GBP', label: '£ - Pounds', default: true }]
    },
    us: {
      text: 'USA',
      value: 'us',
      currencies: [{ value: 'USD', label: '$ - Dollars', default: true }]
    }
  },

  grains: [{ hoz: 'Horizontal Grain' }, { vert: 'Vertical Grain' }, { none: 'N/A Grain Direction' }],

  additionalCharges: {
    earlySpacers: 50
  },

  frontTypes: [
    // doors
    { value: 'lhd-b', label: 'Left hung base door' },
    { value: 'rhd-b', label: 'Right hung base door' },
    { value: 'lhd-w', label: 'Left hung wall door' },
    { value: 'rhd-w', label: 'Right hung wall door' },
    { value: 'thd-w', label: 'Top hung wall door' },
    { value: 'lhd-b-a', label: 'Left hung appliance door low' },
    { value: 'lhd-w-a', label: 'Left hung appliance door high' },
    { value: 'rhd-b-a', label: 'Right hung appliance door low' },
    { value: 'rhd-w-a', label: 'Right hung appliance door high' },
    { value: 'bhd-b-a', label: 'Bottom hung appliance door' },
    // corner front doors
    {
      value: 'lhd-b-cnr',
      label: 'Left hung base corner door',
      presets: {
        uk: {
          qty: [3]
        },
        us: {
          qty: [3]
        }
      }
    },
    {
      value: 'rhd-b-cnr',
      label: 'Right hung base corner door',
      presets: {
        uk: {
          qty: [3]
        },
        us: {
          qty: [3]
        }
      }
    },
    {
      value: 'lhd-w-cnr',
      label: 'Left hung wall corner door',
      presets: {
        uk: {
          qty: [3]
        },
        us: {
          qty: [3]
        }
      }
    },
    {
      value: 'rhd-w-cnr',
      label: 'Right hung wall corner door',
      presets: {
        uk: {
          qty: [3]
        },
        us: {
          qty: [3]
        }
      }
    },
    // 2part doors
    {
      value: 'lhd-b-2part',
      label: 'Left hung 2 part corner door',
      presets: {
        uk: {
          w: [250],
          h: [800],
          qty: [2]
        },
        us: {
          w: [13.3],
          h: [30],
          qty: [2]
        }
      },
      tip: 'Overriding auto dimensions will be treated as custom units.'
    },
    {
      value: 'rhd-b-2part',
      label: 'Right hung 2 part corner door',
      presets: {
        uk: {
          w: [250],
          h: [800],
          qty: [2]
        },
        us: {
          w: [13.3],
          h: [30],
          qty: [2]
        }
      },
      tip: 'Overriding auto dimensions will be treated as custom units.'
    },
    // PLATSA
    { value: 'lhd-b-platsa', label: 'Left hung platsa base door' },
    { value: 'rhd-b-platsa', label: 'Right hung platsa base door' },
    { value: 'lhd-w-platsa', label: 'Left hung platsa wall door' },
    { value: 'rhd-w-platsa', label: 'Right hung platsa wall door' },
    // PAX
    {
      value: 'lhd-b-pax',
      label: 'Left hung pax door',
      tip: 'Will be cut at exact size entered. Check Notion for correct sizes.'
    },
    {
      value: 'rhd-b-pax',
      label: 'Right hung pax door',
      tip: 'Will be cut at exact size entered. Check Notion for correct sizes.'
    },
    // paxsplit is always joined
    {
      value: 'lhd-b-paxsplit',
      label: 'Left hung splt pax base',
      tip: 'Must selected joined option! Will be cut at exact size entered.'
    },
    {
      value: 'rhd-b-paxsplit',
      label: 'Right hung split pax door',
      tip: 'Must selected joined option! Will be cut at exact size entered.'
    },
    {
      value: 'lhd-w-paxsplit',
      label: 'Left hung splt pax base',
      tip: 'Must selected joined option! Will be cut at exact size entered.'
    },
    {
      value: 'rhd-w-paxsplit',
      label: 'Right hung split pax door',
      tip: 'Must selected joined option! Will be cut at exact size entered.'
    },
    // CUSTOM
    {
      value: 'lhd-b-custom',
      label: 'Left hung custom sized base door',
      tip: 'Will be cut at exact size entered with no hinge cups'
    },
    {
      value: 'rhd-b-custom',
      label: 'Right hung custom sized base door',
      tip: 'Will be cut at exact size entered with no hinge cups'
    },
    {
      value: 'lhd-w-custom',
      label: 'Left hung custom sized wall door',
      tip: 'Will be cut at exact size entered with no hinge cups'
    },
    {
      value: 'rhd-w-custom',
      label: 'Right hung custom sized wall door',
      tip: 'Will be cut at exact size entered with no hinge cups'
    },
    {
      value: 'thd-w-custom',
      label: 'Top hung wall door custom',
      tip: 'Will be cut at exact size entered'
    },
    // WM
    { value: 'lhd-b-wm', label: 'Left hung washing machine door' },
    { value: 'rhd-b-wm', label: 'Right hung washing machine door' },
    {
      value: 'lhd-b-renlig',
      label: 'Left hung Renlig washing machine door'
    },
    {
      value: 'rhd-b-renlig',
      label: 'Right hung Renlig washing machine door'
    },
    // drawers
    { value: 'drw', label: 'Drawer' },
    {
      value: 'drw-int',
      label: 'Internal Drawer',
      tip: 'Will be cut at exact size entered. Check Notion for correct sizes. Should have NO handle.'
    },
    {
      value: 'drw-custom',
      label: 'Custom sized drawer',
      tip: 'Will be cut at exact size entered with no drawer fixing holes'
    },
    { value: 'drw-pantry', label: 'Pantry Double Drawer', tip: 'Can only be offered as a double of 600 or 800.' },
    { value: 'drw-platsa', label: 'Plasta Drawer' },
    // misc
    {
      value: 'blank',
      label: 'Blank front',
      tip: 'Back face will not have hinge cups or drawer fixing holes. This should have handle set to NONE'
    }
  ],

  legacyFrontTypes: [
    { original: 'lhd-a', updated: 'lhd-b-a' },
    { original: 'rhd-a', updated: 'rhd-b-a' },
    { original: 'bhd-a', updated: 'bhd-b-a' },
    { original: 'lhd-2-p-cnr', updated: 'lhd-b-2part' },
    { original: 'rhd-2-p-cnr', updated: 'rhd-b-2part' },
    { original: 'lhd-cnr', updated: 'lhd-b-cnr' },
    { original: 'rhd-cnr', updated: 'rhd-b-cnr' },
    { original: 'lhd-wcnr', updated: 'lhd-w-cnr' },
    { original: 'rhd-wcnr', updated: 'rhd-w-cnr' },
    { original: 'lhd-c', updated: 'lhd-b-custom' },
    { original: 'rhd-c', updated: 'rhd-b-custom' },
    { original: 'drw-c', updated: 'drw-custom' }
  ],

  legacyJoinedFrontTypes: [
    { original: { value: 'lhd-b-paxsplit+bot' }, updated: { value: 'lhd-b-paxsplit', joiningPos: 'bot' } },
    { original: { value: 'rhd-b-paxsplit+bot' }, updated: { value: 'rhd-b-paxsplit', joiningPos: 'bot' } },
    { original: { value: 'lhd-w-paxsplit+top' }, updated: { value: 'lhd-w-paxsplit', joiningPos: 'top' } },
    { original: { value: 'rhd-w-paxsplit+top' }, updated: { value: 'rhd-w-paxsplit', joiningPos: 'top' } },
    { original: { value: 'lhd-w-joined+top' }, updated: { value: 'lhd-w', joiningPos: 'top' } },
    { original: { value: 'lhd-b-joined+top' }, updated: { value: 'lhd-b', joiningPos: 'top' } },
    { original: { value: 'lhd-b-joined+mid' }, updated: { value: 'lhd-b', joiningPos: 'mid' } },
    { original: { value: 'lhd-b-joined+bot' }, updated: { value: 'lhd-b', joiningPos: 'bot' } },
    { original: { value: 'rhd-w-joined+top' }, updated: { value: 'rhd-w', joiningPos: 'top' } },
    { original: { value: 'rhd-b-joined+top' }, updated: { value: 'rhd-b', joiningPos: 'top' } },
    { original: { value: 'rhd-b-joined+mid' }, updated: { value: 'rhd-b', joiningPos: 'mid' } },
    { original: { value: 'rhd-b-joined+bot' }, updated: { value: 'rhd-b', joiningPos: 'bot' } },
    { original: { value: 'drw-joined+top' }, updated: { value: 'drw', joiningPos: 'top' } },
    { original: { value: 'drw-joined+mid' }, updated: { value: 'drw', joiningPos: 'mid' } },
    { original: { value: 'drw-joined+bot' }, updated: { value: 'drw', joiningPos: 'bot' } },
    { original: { value: 'bhd-b-a+joined+top' }, updated: { value: 'bhd-b-a', joiningPos: 'top' } },
    { original: { value: 'bhd-b-a+joined+mid' }, updated: { value: 'bhd-b-a', joiningPos: 'mid' } },
    { original: { value: 'bhd-b-a+joined+bot' }, updated: { value: 'bhd-b-a', joiningPos: 'bot' } },
    { original: { value: 'drw-custom+joined+top' }, updated: { value: 'drw-custom', joiningPos: 'top' } },
    { original: { value: 'drw-custom+joined+mid' }, updated: { value: 'drw-custom', joiningPos: 'mid' } },
    { original: { value: 'drw-custom+joined+bot' }, updated: { value: 'drw-custom', joiningPos: 'bot' } },
    { original: { value: 'lhd-b-custom+joined+top' }, updated: { value: 'lhd-b-custom', joiningPos: 'top' } },
    { original: { value: 'lhd-b-custom+joined+mid' }, updated: { value: 'lhd-b-custom', joiningPos: 'mid' } },
    { original: { value: 'lhd-b-custom+joined+bot' }, updated: { value: 'lhd-b-custom', joiningPos: 'bot' } },
    { original: { value: 'rhd-b-custom+joined+top' }, updated: { value: 'rhd-b-custom', joiningPos: 'top' } },
    { original: { value: 'rhd-b-custom+joined+mid' }, updated: { value: 'rhd-b-custom', joiningPos: 'mid' } },
    { original: { value: 'rhd-b-custom+joined+bot' }, updated: { value: 'rhd-b-custom', joiningPos: 'bot' } },
    { original: { value: 'lhd-w-custom+joined+top' }, updated: { value: 'lhd-w-custom', joiningPos: 'top' } },
    { original: { value: 'rhd-w-custom+joined+top' }, updated: { value: 'rhd-w-custom', joiningPos: 'top' } }
  ],

  coverPanelTypes: [
    { value: 'v-plinth', label: 'Vented Plinth', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' },
    { value: 'plinth', label: 'Plinth', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' },
    { value: 'end-b', label: 'End base cover panel', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' },
    { value: 'end-w', label: 'End wall cover panel', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' },
    {
      value: 'filler',
      label: 'Filler panel',
      shortMaxTip: 'Max is 94". 3mm will be trimmed from the top. Width will be cut at exact size entered.',
      longMaxTip: 'Max is 118". 3mm will be trimmed from the top. Width will be cut at exact size entered.'
    },
    { value: 'back', label: 'Back panel', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' },
    { value: 'top', label: 'Top panel', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' },
    { value: 'underside', label: 'Underside panel', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' },
    { value: 'shelf', label: 'Shelf', shortMaxTip: 'max is 94"', longMaxTip: 'max is 118"' }
  ],

  urtilTypes: [
    { value: 'o', label: 'Open', cost: 225, laminateSurcharge: 100 },
    { value: 's', label: 'Single Door', cost: 260, laminateSurcharge: 100 },
    { value: 't', label: 'Twin Doors', cost: 290, laminateSurcharge: 100 }
    // {
    //   value: 'of',
    //   label: 'Open with Formica Back',
    //   cost: 290,
    //   laminateSurcharge: 100
    // }
  ],

  optionsOfCompartmentsAllowed: [1, 2, 3, 4, 5, 6],

  pantryTypes: [
    {
      value: 'm',
      label: 'Matching Base',
      tip: 'base material will be matched to carcass material',
      cost: 225,
      laminateSurcharge: 100
    },
    {
      value: 'l',
      label: 'Laminate Base',
      tip: 'base material will be a different laminate to carcass material',
      cost: 290,
      laminateSurcharge: 100
    }
  ],

  pantryHinges: [
    {
      value: 'o',
      label: 'No Hinges (Open)',
      hTip: 'choose to have no hinges (for open pantry); both, left or right hinge holes'
    },
    {
      value: 'l',
      label: 'Left Hinge',
      hTip: 'choose to have no hinges (for open pantry); both, left or right hinge holes'
    },
    {
      value: 'r',
      label: 'Right Hinge',
      hTip: 'choose to have no hinges (for open pantry); both, left or right hinge holes'
    },
    {
      value: 'b',
      label: 'Both Hinges',
      hTip: 'choose to have no hinges (for open pantry); both, left or right hinge holes'
    }
  ],

  spacerMaterials: [
    { label: 'Ash', value: 'ash' },
    { label: 'Birch', value: 'birch' },
    { label: 'Oak', value: 'oak' },
    { label: 'Walnut', value: 'walnut' },
    { label: 'Douglas Fir', value: 'douglas' },
    { label: 'Sapele', value: 'sapele' },
    { label: 'Maple', value: 'maple' },
    { label: 'Beech', value: 'beech' }
  ],

  getHandle(value) {
    for (var i = this.handles.length - 1; i >= 0; i--) {
      if (this.handles[i].value == value) {
        return this.handles[i]
      }
    }
  },

  getWorktop(sku, us) {
    const worktops = us ? this.usWorktops : this.worktops

    for (var i = worktops.length - 1; i >= 0; i--) {
      if (worktops[i].sku == sku) {
        return worktops[i]
      }
    }
  },

  getFrontType(value) {
    for (var i = this.frontTypes.length - 1; i >= 0; i--) {
      if (this.frontTypes[i].value == value) {
        return this.frontTypes[i]
      }
    }
  },

  getFrontTypeLabel(value) {
    for (var i = this.frontTypes.length - 1; i >= 0; i--) {
      if (this.frontTypes[i].value == value) {
        return this.frontTypes[i].label
      }
    }
  },

  getUrtilType(value) {
    for (var i = 0; i < this.urtilTypes.length; i++) {
      if (this.urtilTypes[i].value == value) {
        return this.urtilTypes[i]
      }
    }
  },

  getPantryType(value) {
    return this.pantryTypes.find(o => o.value === value)
  },

  getPantryHinge(value) {
    return this.pantryHinges.find(o => o.value === value)
  },

  getSpacerMaterial(value) {
    return this.spacerMaterials.find(o => o.value === value)
  },

  getCoverPanelType(value) {
    for (var i = this.coverPanelTypes.length - 1; i >= 0; i--) {
      if (this.coverPanelTypes[i].value == value) {
        return this.coverPanelTypes[i]
      }
    }
  },

  getCoverPanelTypeLabel(value) {
    for (var i = this.coverPanelTypes.length - 1; i >= 0; i--) {
      if (this.coverPanelTypes[i].value == value) {
        return this.coverPanelTypes[i].label
      }
    }
  },

  getDefaultCurrencyForRegion(region) {
    for (var i = 0; i < this.regions[region].currencies.length; i++) {
      if (this.regions[region].currencies[i].default) {
        return this.regions[region].currencies[i].value
      }
    }
  },

  updateFrontIfLegacy(val) {
    for (var i = 0; i < this.legacyFrontTypes.length; i++) {
      if (val == this.legacyFrontTypes[i].original) {
        val = this.legacyFrontTypes[i].updated
        break
      }
    }
    return val
  },

  getHandleOptions(selectedType, handles, width, height) {
    let selection = handles.filter(handle => {
      return !['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(handle.type)
    })

    const normalHozSRG = handles.filter(handle => {
      return ['semiGrab'].includes(handle.type) && ['h', 'c', 'updn'].includes(handle.orientation)
    })

    const normalAndMidHozSRG = handles.filter(handle => {
      return ['semiGrab', 'midSemiGrab'].includes(handle.type) && ['h', 'c', 'updn'].includes(handle.orientation)
    })

    const hozSRG = handles.filter(handle => {
      return (
        ['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(handle.type) &&
        ['h', 'c', 'updn'].includes(handle.orientation)
      )
    })

    const normalVertSRG = handles.filter(handle => {
      return ['semiGrab'].includes(handle.type) && ['v', 'vc', 'vbt', 'vwb'].includes(handle.orientation)
    })

    const normalAndMidVertSRG = handles.filter(handle => {
      return ['semiGrab', 'midSemiGrab'].includes(handle.type) && ['v', 'vc', 'vbt', 'vwb'].includes(handle.orientation)
    })

    const vertSRG = handles.filter(handle => {
      return (
        ['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(handle.type) &&
        ['v', 'vc', 'vbt', 'vwb'].includes(handle.orientation)
      )
    })

    // height is too low
    if (selectedType) {
      if (selectedType == 'drw-int') {
        selection = handles.filter(handle => {
          return ['none'].includes(handle.type)
        })
      }

      if (width) {
        // change option to switch case
        width < 200
          ? null
          : width < 400
          ? selection.push(...normalHozSRG)
          : width < 600
          ? selection.push(...normalAndMidHozSRG)
          : selection.push(...hozSRG)
      }

      if (height) {
        // change option to switch case
        height < 200
          ? null
          : height < 400
          ? selection.push(...normalVertSRG)
          : height < 600
          ? selection.push(...normalAndMidVertSRG)
          : selection.push(...vertSRG)
      }

      return selection
    }
  },

  getPantryLightingWidth(pantryWidth) {
    let lightingWidth
    switch (pantryWidth) {
      case 600:
        lightingWidth = 400
        break
      case 800:
        lightingWidth = 600
        break
      case 1000:
      case 1200:
        lightingWidth = 800
        break
    }
    return lightingWidth
  },

  getPantryShelfDepth(pantryDepth, shelfType) {
    let adjShelfDepth
    let fixedShelfDepth
    switch (pantryDepth) {
      case 367:
        adjShelfDepth = 300
        fixedShelfDepth = 300
        break
      case 591:
        adjShelfDepth = 300
        fixedShelfDepth = 400
        break
    }

    if (shelfType === 'adj') {
      return adjShelfDepth
    } else if (shelfType === 'fixed') {
      return fixedShelfDepth
    }
  },

  getPantryShelfWidth(pantryWidth, shelfType) {
    let adjShelfWidth = pantryWidth - 18 * 2
    let fixedShelfWidth = pantryWidth - 18 * 2 + 28

    if (shelfType === 'adj') {
      return adjShelfWidth
    } else if (shelfType === 'fixed') {
      return fixedShelfWidth
    }
  },

  updateJoinedFrontIfLegacy(val) {
    for (const frontType of this.legacyJoinedFrontTypes) {
      if (val == frontType.original.value) {
        val = frontType.updated
      }
    }
    return val
  },

  getOrientation(val) {
    return this.handleOrientations[val]
  }
}
