<template>
  <v-row class="align-center">
    <v-col :cols="3">
      <MegaCell :data.sync="data.location" :row="rowNum" :col="0" dataType="String"></MegaCell>
    </v-col>
    <v-col :cols="3">
      <MegaSelect :row="rowNum" :col="1" :options="spacerMaterials" :data.sync="data.material"></MegaSelect>
    </v-col>
    <v-col :cols="2">
      <MegaCell :data.sync="data.l" :row="rowNum" :col="2" :unit="region == 'us' ? 'inch' : 'mm'"></MegaCell>
    </v-col>
    <v-col :cols="1">
      <MegaCell :data.sync="data.qty" :row="rowNum" :col="3"></MegaCell>
    </v-col>
    <v-col :cols="1">
      <v-icon @click="rotateDialog = true">mdi-format-rotate-90</v-icon>
    </v-col>
    <v-col class="d-flex" :order="wonky ? 'first' : 'last'" :class="[{ 'justify-end': !wonky }]" :cols="2">
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-2" @click="$emit('copy', rowNum)" v-on="on">mdi-content-copy</v-icon>
        </template>
        <span>Copy</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small @click="$emit('delete', rowNum)" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </v-col>
    <!-- Rotate Dialog -->
    <v-dialog v-model="rotateDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Rotate SpacerPanel (visual)</v-card-title>
        <v-card-text class="pt-4">
          <v-checkbox
            type="number"
            label="Make spacer panels horizontal on the visual."
            v-model.number="data.visualHoz"
          ></v-checkbox>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import MegaSelect from '@/components/quoting/items/MegaSelect'
import MegaCell from '@/components/quoting/items/MegaCell'

export default {
  name: 'SpacerPanel',
  props: ['rowNum', 'data', 'wonky', 'region'],
  components: { MegaCell, MegaSelect },
  data() {
    return {
      spacerMaterialsEu: [
        { label: 'Ash', value: 'ash' },
        { label: 'Birch', value: 'birch' },
        { label: 'Oak', value: 'oak' },
        { label: 'Walnut', value: 'walnut' },
        { label: 'Douglas Fir', value: 'douglas' },
        { label: 'Sapele', value: 'sapele' },
        { label: 'Maple', value: 'maple' },
        { label: 'Beech', value: 'beech' }
      ],
      spacerMaterialsUs: [
        { label: 'Birch', value: 'birch' },
        { label: 'Maple', value: 'maple' },
        { label: 'Oak', value: 'oak' },
        { label: 'Walnut', value: 'walnut' }
      ],
      rotateDialog: false
    }
  },
  computed: {
    spacerMaterials() {
      return this.region === 'us' ? this.spacerMaterialsUs : this.spacerMaterialsEu
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  border-bottom: solid 1px #eee;
  .col {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
</style>
