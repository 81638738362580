var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class HingesUs {
  constructor(w, h, front) {
    this.models = {}
    this.width = w
    this.height = Math.round(h)
    this.centerHingeThreshold = 1267
    this.fourHingeDoorDimensions = { w1521: 606.6, h1521: 1521, h1797: 1797, h1997: 1997 }
    this.cabinet = front.cabinet
    this.type = front.type
    this.special = front.special
    this.handleType = front.handle.value
    this.handleOrientation = front.handle.orientation

    if (this.special === 'pax' || this.special === 'paxsplit') {
      this.cupGroupOffset = { x: 22.75, y: 124 }
    } else {
      this.cupGroupOffset = { x: 23.5, y: 62 }
    }
    this.cupDiameter = 35.5
    this.drillOffset = { x: 9.5, y: 22.5 }
    this.topHingeExtraOffset = 63.5

    switch (this.special) {
      case 'platsa':
        this.drillDiameter = 3
        break
      case 'pax':
      case 'paxsplit':
        this.drillDiameter = 5
        break
      default:
        this.drillDiameter = 8
    }

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    if (this.type == 'thd' || this.type == 'bhd' || this.special == 'wm') return

    var xPos = this.type == 'lhd' ? this.cupGroupOffset.x : this.width - this.cupGroupOffset.x

    this.models.bottomHinge = this.getHingeCupPaths()
    this.models.topHinge = this.getHingeCupPaths()

    // remove top hinge cup from bottom door
    if (this.special === 'paxsplit') {
      switch (this.height) {
        case 861:
        case 916:
        case 925:
        case 980:
          this.models.topHinge = null
          break
        case 1080:
          this.models.centerHinge = this.getHingeCupPaths()
          this.models.bottomHinge = null
          break
        case 1368:
          this.models.centerHinge = this.getHingeCupPaths()
          break
        default:
          // make blank if wrong height input
          this.models.topHinge = null
          this.models.bottomHinge = null
          break
      }
    }

    // Create additional hinges for taller doors
    if (this.special === 'pax') {
      switch (this.height) {
        case 1944:
        case 1999:
          this.models.centerHinge = this.getHingeCupPaths()
          break
        case 2296:
        case 2351:
          this.models.upperHinge = this.getHingeCupPaths()
          this.models.lowerHinge = this.getHingeCupPaths()
          break
        default:
          // make blank if wrong height input
          this.models.topHinge = null
          this.models.bottomHinge = null
          break
      }
    }

    if (this.height >= this.centerHingeThreshold && !['paxsplit', 'pax'].includes(this.special)) {
      if (this.width === this.fourHingeDoorDimensions.w && this.height === this.fourHingeDoorDimensions.h) {
        this.models.upperHinge = this.getHingeCupPaths()
        this.models.lowerHinge = this.getHingeCupPaths()
      } else if (
        this.height === this.fourHingeDoorDimensions.h1797 ||
        this.height === this.fourHingeDoorDimensions.h1997
      ) {
        this.models.upperHinge = this.getHingeCupPaths()
        this.models.lowerHinge = this.getHingeCupPaths()
      } else {
        this.models.centerHinge = this.getHingeCupPaths()
      }
    }

    var hinges = Object.values(this.models)
    if (this.type == 'rhd') {
      for (var i = 0; i < hinges.length; i++) {
        makerjs.model.rotate(hinges[i], 180, [0, 0])
      }
    }

    // catch top hinges which need to be moved
    var topOffset = this.height - this.cupGroupOffset.y
    var bottomOffset = 0

    if (
      (this.width == 197 && (this.handleType == 'grab' || this.handleType == 'semiGrab')) ||
      (this.handleType == 'j' && this.handleOrientation == 'h')
    ) {
      if (this.cabinet == 'w') {
        bottomOffset = this.topHingeExtraOffset
      } else {
        topOffset -= this.topHingeExtraOffset
      }
    }

    if (this.models.bottomHinge)
      makerjs.model.move(this.models.bottomHinge, [xPos, this.cupGroupOffset.y + bottomOffset])
    if (this.models.topHinge) makerjs.model.move(this.models.topHinge, [xPos, topOffset])

    // pax
    if (this.special == 'pax') {
      switch (this.height) {
        case 1944:
          makerjs.model.move(this.models.topHinge, [xPos, 1820])
          makerjs.model.move(this.models.centerHinge, [xPos, 988])
          makerjs.model.move(this.models.bottomHinge, [xPos, 124])
          break
        case 1999:
          makerjs.model.move(this.models.topHinge, [xPos, 1875])
          makerjs.model.move(this.models.centerHinge, [xPos, 1043])
          makerjs.model.move(this.models.bottomHinge, [xPos, 179])
          break
        case 2296:
          makerjs.model.move(this.models.topHinge, [xPos, 2172])
          makerjs.model.move(this.models.upperHinge, [xPos, 1244])
          makerjs.model.move(this.models.lowerHinge, [xPos, 1052])
          makerjs.model.move(this.models.bottomHinge, [xPos, 124])
          break
        case 2351:
          makerjs.model.move(this.models.topHinge, [xPos, 2227])
          makerjs.model.move(this.models.upperHinge, [xPos, 1299])
          makerjs.model.move(this.models.lowerHinge, [xPos, 1107])
          makerjs.model.move(this.models.bottomHinge, [xPos, 179])
          break
      }
    } else if (this.special == 'paxsplit') {
      switch (this.height) {
        case 861:
        case 925:
          makerjs.model.move(this.models.bottomHinge, [xPos, 124])
          break
        case 916:
        case 980:
          makerjs.model.move(this.models.bottomHinge, [xPos, 179])
          break
        case 1080:
          makerjs.model.move(this.models.topHinge, [xPos, 956])
          makerjs.model.move(this.models.centerHinge, [xPos, 124])
          break
        case 1368:
          makerjs.model.move(this.models.topHinge, [xPos, 1244])
          makerjs.model.move(this.models.centerHinge, [xPos, 668])
          makerjs.model.move(this.models.bottomHinge, [xPos, 124])
          break
      }
    }

    if (this.height >= this.centerHingeThreshold && !['paxsplit', 'pax'].includes(this.special)) {
      if (this.width === this.fourHingeDoorDimensions.w && this.height === this.fourHingeDoorDimensions.h) {
        makerjs.model.move(this.models.lowerHinge, [xPos, 379.5])
        makerjs.model.move(this.models.upperHinge, [xPos, 1141.5])
      } else if (this.height === this.fourHingeDoorDimensions.h1797) {
        makerjs.model.move(this.models.upperHinge, [xPos, 1148.25])
        makerjs.model.move(this.models.lowerHinge, [xPos, 648.75])
      } else if (this.height === this.fourHingeDoorDimensions.h1997) {
        makerjs.model.move(this.models.upperHinge, [xPos, 1298.25])
        makerjs.model.move(this.models.lowerHinge, [xPos, 698.75])
      } else {
        makerjs.model.move(this.models.centerHinge, [xPos, this.height / 2])
      }
    }
  }

  getHingeCupPaths() {
    var cupGroup = {
      models: {
        cup: new makerjs.models.Ellipse(this.cupDiameter / 2, this.cupDiameter / 2),
        drillTop: new makerjs.models.Ellipse(this.drillDiameter / 2, this.drillDiameter / 2),
        drillBottom: new makerjs.models.Ellipse(this.drillDiameter / 2, this.drillDiameter / 2)
      }
    }

    cupGroup.models.cup.layer = layerNames.hingeCup.label
    cupGroup.models.drillTop.layer = this.drillDiameter + 'mm hinge drill @ 14mm'
    cupGroup.models.drillBottom.layer = this.drillDiameter + 'mm hinge drill @ 14mm'

    makerjs.model.move(cupGroup.models.drillTop, [this.drillOffset.x, this.drillOffset.y])
    makerjs.model.move(cupGroup.models.drillBottom, [this.drillOffset.x, -this.drillOffset.y])

    return cupGroup
  }
}
