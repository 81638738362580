<template>
  <div id="week-resources">
    <v-container fluid class="flex-grow-0 pa-0">
      <v-row class="ma-0 header">
        <v-col v-for="(day, date) in tasksByDay" :key="date">
          <v-row wrap class="align-baseline">
            <v-col cols="12" class="py-1 no-border text-body-2" :class="$utils.datePPF(date)">
              {{ $moment(date).format('ddd') }}
            </v-col>
            <v-col cols="4" class="py-1 no-border text-h4" :class="$utils.datePPF(date)">
              {{ $moment(date).format('D') }}
            </v-col>
            <v-col cols="4" class="py-1 d-flex align-baseline">
              <v-icon small class="cutting">mdi-hand-saw</v-icon>
              <input
                :style="{ width: '30px' }"
                class="mr-1 outlined px-1 text-center"
                name="cutting"
                size="mini"
                v-model.number="capacityObject[date].cutting"
                @input="$emit('cutHoursChanged', capacityObject[date].date.seconds, $event.target.value)"
                type="number"
              />
              <v-icon small class="packing">mdi-package-variant</v-icon>
              <input
                :style="{ width: '30px' }"
                class="mr-1 outlined px-1 text-center"
                name="packing"
                size="mini"
                v-model.number="capacityObject[date].packing"
                @input="$emit('packHoursChanged', capacityObject[date].date.seconds, $event.target.value)"
                type="number"
              />
            </v-col>
            <v-col cols="4" class="py-1 text-right d-flex justify-end align-baseline">
              <div
                class="text-body-1 mr-1"
                :class="{
                  'red--text':
                    Math.round((capacityForDay(capacityObject[date]) / getDailyRequiredCapacity(date)) * 100) < 100,
                  'green--text':
                    Math.round((capacityForDay(capacityObject[date]) / getDailyRequiredCapacity(date)) * 100) >= 100
                }"
              >
                {{ Math.round((capacityForDay(capacityObject[date]) / getDailyRequiredCapacity(date)) * 100) }}%
              </div>
              <div class="text-caption text--secondary">of {{ getDailyRequiredCapacity(date) }}</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="flex-grow-1 pa-0 d-flex flex-column">
      <div class="worker-type working text-body-2 pl-2 py-1">Working</div>
      <v-row class="ma-0">
        <v-col v-for="(day, key) in capacityObject" class="ma-0 pa-0" :key="key">
          <draggable
            class="draggable"
            :class="{
              disabled:
                day.working.find(o => {
                  return o.timetasticId === draggedEmployee
                }) != undefined && day.date.seconds != activeDaySeconds
            }"
            v-model="day.working"
            :group="
              day.working.find(o => {
                return o.timetasticId === draggedEmployee
              }) == undefined || day.date.seconds === activeDaySeconds
                ? 'employed'
                : null
            "
            @start="
              e => {
                draggedEmployee = day.working[e.oldIndex].timetasticId
                activeDaySeconds = day.date.seconds
              }
            "
            @end="
              () => {
                draggedEmployee = null
                activeDaySeconds = null
              }
            "
            @add="$emit('resourcesChanged', day.date.seconds)"
            @remove="$emit('resourcesChanged', day.date.seconds)"
          >
            <v-hover class="worker working" v-for="(working, key) of day.working" :key="key" v-slot="{ hover }">
              <div
                class="worker working"
                @click="
                  () => {
                    selectedAdjustment = working
                    activeDaySeconds = day.date.seconds
                    adjustmentDialog = true
                  }
                "
              >
                <div class="name">{{ working.name }}</div>
                <v-icon v-if="hover">mdi-pencil</v-icon>
                <div class="hours" v-else>
                  {{ working.hours }}
                </div>
              </div>
            </v-hover>
          </draggable>
        </v-col>
      </v-row>

      <div class="worker-type working text-body-2 pl-2 py-1">Freelancers</div>
      <v-row class="ma-0">
        <v-col v-for="(day, key) in capacityObject" class="ma-0 pa-0" :key="key">
          <v-hover class="worker working" v-for="(worker, key) of day.freelancers" :key="key" v-slot="{ hover }">
            <div class="worker working" @click="removeFreelancer(day, worker)">
              <div class="name">{{ worker.name }}</div>
              <v-icon v-if="hover">mdi-delete</v-icon>
              <div v-else class="hours">{{ worker.hours }}</div>
            </div>
          </v-hover>

          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small text class="add-resource-btn">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(staff, index) in availableFreelancers" :key="index" class="d-flex">
                  <div class="text-body-2">{{ staff.name }}</div>
                  <v-spacer></v-spacer>
                  <v-chip small class="mx-2" @click="addFreelancer(day, staff, 4)">1/2 Day</v-chip>
                  <v-chip small @click="addFreelancer(day, staff, 8)">Full Day</v-chip>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>

      <div class="worker-type working text-body-2 pl-2 py-1">Overtime</div>
      <v-row class="ma-0">
        <v-col v-for="(day, key) in capacityObject" class="ma-0 pa-0" :key="key">
          <v-hover class="worker working" v-for="(worker, key) of day.overtime" :key="key" v-slot="{ hover }">
            <div class="worker working" @click="removeOvertime(day, worker)">
              <div class="name">{{ worker.name }}</div>
              <v-icon v-if="hover">mdi-delete</v-icon>
              <div v-else class="hours">{{ worker.hours }}</div>
            </div>
          </v-hover>

          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small text class="add-resource-btn">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <div class="mini-title">Working</div>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item v-for="staff in day.working" :key="staff.timetasticId" class="d-flex">
                  <div class="text-body-2">{{ staff.name }}</div>
                  <v-spacer></v-spacer>
                  <v-chip small class="mx-2" @click="addOvertime(day, staff, 4)">1/2 Day</v-chip>
                  <v-chip small @click="addOvertime(day, staff, 8)">Full Day</v-chip>
                </v-list-item>

                <div>
                  <v-list-item>
                    <div class="mini-title">Not Working</div>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item v-for="staff in $store.getters.notWorking(day)" :key="staff.timetasticId" class="d-flex">
                    <div class="text-body-2">{{ staff.name }}</div>
                    <v-spacer></v-spacer>
                    <v-chip small class="mx-2" @click="addOvertime(day, staff, 4)">1/2 Day</v-chip>
                    <v-chip small @click="addOvertime(day, staff, 8)">Full Day</v-chip>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>

      <div class="worker-type holidays text-body-2 pl-2 py-1">Holidays</div>
      <v-row class="ma-0">
        <v-col v-for="(day, key) in capacityObject" class="ma-0 pa-0" :key="key">
          <v-hover class="worker holiday" v-for="(worker, key) of day.holidays" :key="key" v-slot="{ hover }">
            <div class="worker holiday" @click="removeHoliday(day, worker)">
              <div class="name">{{ worker.name }}</div>
              <v-icon v-if="hover">mdi-delete</v-icon>
            </div>
          </v-hover>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small text class="add-resource-btn">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(staff, index) in availableEmployed" :key="index" class="d-flex">
                  <div class="text-body-2" @click="addHoliday(day, staff)">{{ staff.name }}</div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>

      <div class="worker-type sick text-body-2 pl-2 py-1">Sick</div>
      <v-row class="ma-0">
        <v-col v-for="(day, key) in capacityObject" class="ma-0 pa-0" :key="key">
          <draggable
            class="draggable"
            v-model="day.sick"
            :group="day.date.seconds === activeDaySeconds ? 'employed' : null"
            @start="
              e => {
                draggedEmployee = day.working[e.oldIndex].timetasticId
                activeDaySeconds = day.date.seconds
              }
            "
            @end="
              () => {
                draggedEmployee = null
                activeDaySeconds = null
              }
            "
            @add="$emit('resourcesChanged', day.date.seconds)"
            @remove="$emit('resourcesChanged', day.date.seconds)"
          >
            <div class="worker sick" v-for="(working, key) of day.sick" :key="key">
              <div class="name">{{ working.name }}</div>
              <div class="hours">{{ working.hours }}</div>
            </div>
          </draggable>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="adjustmentDialog" width="400">
      <AdjustmentDetail
        :data.sync="selectedAdjustment"
        @change="$emit('resourcesChanged', activeDaySeconds)"
      ></AdjustmentDetail>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import AdjustmentDetail from '@/components/schedule/AdjustmentDetail'

export default {
  name: 'WeekResources',
  components: { draggable, AdjustmentDetail },
  props: ['taskTypeGroups', 'tasksByDay', 'focussedTask', 'staffUnitsPerDay', 'capacityObject'],
  data() {
    return {
      adjustmentDialog: false,
      selectedAdjustment: 'hello',
      draggedEmployee: null,
      activeDaySeconds: null
    }
  },
  computed: {
    availableFreelancers() {
      return this.$store.state.staff.filter(staff => {
        return staff.staffType === 'freelance' && !staff.departureDate
      })
    },
    availableEmployed() {
      return this.$store.state.staff.filter(staff => {
        return staff.staffType === 'employed' && !staff.departureDate
      })
    }
  },
  // computed: {
  //   cuttingValue: {
  //     get() {
  //       return this.capacityObject[date].cutting
  //     },
  //     set(val) {
  //       this.$emit('cutHoursChanged', date, val)
  //     }
  //   },
  //   packingValue: {
  //     get() {
  //       return this.capacityObject[date].packing
  //     },
  //     set(val) {
  //       this.$emit('packHoursChanged', date, val)
  //     }
  //   }
  // },
  methods: {
    addHoliday(day, employed) {
      day.holidays.push({
        id: employed.id + 'HOL',
        name: employed.name
      })
      day.working = day.working.filter(working => {
        return working.timetasticId !== employed.timetasticId
      })
      this.$emit('resourcesChanged', day.date.seconds)
      return
    },
    removeHoliday(day, employed) {
      day.holidays = day.holidays.filter(working => {
        return working.id !== employed.id
      })
      this.$emit('resourcesChanged', day.date.seconds)
    },
    addFreelancer(day, freelancer, hours) {
      day.freelancers.push({
        id: freelancer.id,
        name: freelancer.name,
        hours: hours
      })
      this.$emit('resourcesChanged', day.date.seconds)
      return
    },
    removeFreelancer(day, freelancer) {
      day.freelancers = day.freelancers.filter(f => {
        return f.id !== freelancer.id
      })
      this.$emit('resourcesChanged', day.date.seconds)
    },
    addOvertime(day, employee, hours) {
      day.overtime.push({
        timetasticId: employee.timetasticId,
        name: employee.name,
        hours: hours
      })
      this.$emit('resourcesChanged', day.date.seconds)
      return
    },
    removeOvertime(day, employee) {
      day.overtime = day.overtime.filter(f => {
        return f.timetasticId !== employee.timetasticId
      })
      this.$emit('resourcesChanged', day.date.seconds)
    },
    openCapacityDetails(date, capacity) {
      this.selectedCapacityDetails = {
        date,
        capacity,
        requiredCapacity: this.getDailyRequiredCapacity(date)
      }
      this.capacityDetailsOverlay = true
    },
    getDailyRequiredCapacity(date) {
      let count = 0

      for (var i = 0; i < this.tasksByDay[date].length; i++) {
        // should we exclude this from capacity?
        var t = this.tasksByDay[date][i]
        if (t.type != 'dispatch') {
          count += parseInt(this.tasksByDay[date][i].units)
        }
      }

      return count
    },
    capacityForDay(day) {
      if (day) {
        let hours = 0
        let workers = day.working.concat(day.freelancers).concat(day.overtime)
        for (const worker of workers) {
          hours += worker.hours
        }
        return hours * (this.staffUnitsPerDay / 8) // 8 hour day
      }
    },
    onSortEnd(e) {
      this.$emit('onSortEnd', e)
    }
  }
}
</script>

<style lang="scss" scoped>
#week-resources {
  .header {
    border-bottom: solid 1px $ruleGrey;
  }

  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: width 0.5s;
  .col {
    border-right: solid 1px $ruleGrey;
    &:last-child,
    &.no-border {
      border: none;
    }
    .task-type {
      background: $sand;
      border-top: solid 1px $ruleGrey;
      display: flex;
      justify-content: flex-end;
      padding: 4px;
    }
    .count {
      white-space: nowrap;
    }
  }

  .worker {
    margin: 4px;
    padding: 4px;
    border-radius: 3px;
    cursor: pointer;
    background: #eee;
    display: flex;
    justify-content: space-between;
  }

  .worker-type {
    &.working {
      background: $primary;
      color: white;
    }
    &.holidays {
      background: $accent;
    }
    &.sick {
      background: $accent;
    }
  }

  .add-resource-btn {
    min-width: 25px;
    padding: 5px;
  }

  .draggable {
    min-height: 100%;
    &.disabled {
      opacity: 20%;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .cutting {
    color: $cut;
  }
  .packing {
    color: $pack;
  }
}
</style>
