import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class GrabHandle {
  constructor(front, region) {
    // common
    this.models = {}
    this.width = 140
    this.height = 30
    this.insetX = 35
    this.insetY = 0
    this.front = front
    this.region = region

    // unique
    this.outlineOvershoot = 5
    this.outerRadius = 1
    this.innerRadius = 2
    this.pocketOffsetLarge = 19.5
    this.pocketOffsetSmall = 5
    this.millInset = 5
    this.millHeight = 25

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.front.handleOutline = {
      layer: layerNames.cutout.label,
      paths: {
        arc1: {
          type: 'arc',
          origin: [-this.outerRadius, -this.outerRadius],
          radius: this.outerRadius,
          startAngle: 0,
          endAngle: 90
        },
        line1: {
          type: 'line',
          origin: [0, -this.outerRadius],
          end: [0, -this.height + this.innerRadius]
        },
        arc2: {
          type: 'arc',
          origin: [this.innerRadius, -this.height + this.innerRadius],
          radius: this.innerRadius,
          startAngle: 180,
          endAngle: 270
        },
        line2: {
          type: 'line',
          origin: [this.innerRadius, -this.height],
          end: [this.width - this.innerRadius, -this.height]
        },
        arc3: {
          type: 'arc',
          origin: [this.width - this.innerRadius, -this.height + this.innerRadius],
          radius: this.innerRadius,
          startAngle: 270,
          endAngle: 0
        },
        line3: {
          type: 'line',
          origin: [this.width, -this.height + this.innerRadius],
          end: [this.width, -this.outerRadius]
        },
        arc4: {
          type: 'arc',
          origin: [this.width + this.outerRadius, -this.outerRadius],
          radius: this.outerRadius,
          startAngle: 90,
          endAngle: 180
        },
        line4: {
          type: 'line',
          origin: [this.width + this.outerRadius, 0],
          end: [this.width + this.outerRadius, this.outlineOvershoot]
        },
        line5: {
          type: 'line',
          origin: [this.width + this.outerRadius, this.outlineOvershoot],
          end: [-this.outerRadius, this.outlineOvershoot]
        },
        line6: {
          type: 'line',
          origin: [-this.outerRadius, this.outlineOvershoot],
          end: [-this.outerRadius, 0]
        }
      }
    }

    if (this.region !== 'us') {
      this.models.handleMill = {
        layer: layerNames.handleMill.label,
        origin: this.origin,
        paths: {
          line1: {
            type: 'line',
            origin: [this.millInset, -this.height + this.millInset],
            end: [this.width - this.millInset, -this.height + this.millInset]
          },
          line2: {
            type: 'line',
            origin: [this.width - this.millInset, -this.height + this.millInset],
            end: [this.width - this.millInset, -this.height + this.millInset + this.millHeight]
          },
          line3: {
            type: 'line',
            origin: [this.width - this.millInset, -this.height + this.millInset + this.millHeight],
            end: [this.millInset, -this.height + this.millInset + this.millHeight]
          },
          line4: {
            type: 'line',
            origin: [this.millInset, -this.height + this.millInset + this.millHeight],
            end: [this.millInset, -this.height + this.millInset]
          }
        }
      }
    }
  }
}
