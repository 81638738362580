<template>
  <div id="deliveries">
    <l-map style="height: 100%; width: 100%" :zoom="zoom" :center="center">
      <l-tile-layer :url="url"></l-tile-layer>
      <v-marker-cluster>
        <l-marker v-for="(s, index) in shipmentsWithPostcode" v-if="s.latlng" :lat-lng="s.latlng" :key="index">
          <l-tooltip>
            <div>
              <span>Order: </span>
              <span>{{ s.orderNumber || s.invoiceNumber }}</span>
            </div>
            <div>
              <span>Customer: </span>
              <span>{{ s.customerEmail }}</span>
            </div>
            <div>
              <span>Date: </span>
              <span>{{ $moment.unix(s.deliveryDate.seconds).format('Do MMM YYYY') }}</span>
            </div>
          </l-tooltip>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script type="text/javascript">
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet'
import { Icon } from 'leaflet'

import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'Deliveies',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 6,
      center: [54, -4.5],
      shipments: []
    }
  },
  computed: {
    shipmentsWithPostcode() {
      return this.shipments.filter(s => {
        return s.latlng
      })
    }
  },
  async created() {
    await this.$bind('shipments', this.$db.collection('shipments').where('shipped', '!=', true))
  }
}
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

#deliveries {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
