<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-for="(label, key) in config.labels"
        class="caption"
        :cols="label.span"
        :key="key"
        :class="[{ 'text-right': key == config.labels.length - 1 && !wonkyMode }]"
        :order="getWonkyKey(key, config.labels.length)"
        >{{ label.title }}
      </v-col>
    </v-row>
    <v-divider class="my-2" />

    <component
      :is="config.itemComponent"
      v-for="(item, key) in version[config.itemType]"
      :rowNum="key"
      :data="item"
      :materials="materialOptions"
      :handles="handleOptions"
      :key="key"
      :wonky="wonkyMode"
      :location="version.deliveryLocation"
      :region="quote.region"
      :grainDirection="version.grainDirection"
      :requiresVerticalGrain="requiresVerticalGrain"
      @copy="copyRow"
      @copyBottom="copyToBottom"
      @delete="deleteRow"
      @rowUp="rowUp"
      @rowDown="rowDown"
    ></component>

    <v-row fluid row>
      <v-col cols="12">
        <v-btn class="ma-0 mt-2" fab outlined color="primary" small @click="addEmptyRow"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import uid from 'uid'
import Front from '@/components/quoting/items/Front'
import CoverPanel from '@/components/quoting/items/CoverPanel'
import SpacerPanel from '@/components/quoting/items/SpacerPanel'
import Worktop from '@/components/quoting/items/Worktop'
import Urtil from '@/components/quoting/items/Urtil'
import Pantry from '@/components/quoting/items/Pantry'

export default {
  name: 'megaTable',
  props: ['config', 'version', 'quote'],
  components: { Front, CoverPanel, SpacerPanel, Worktop, Urtil, Pantry },
  data() {
    return {
      numCols: this.config.labels.length,
      wonkyMode: this.$store.state.user.wonkyMode
    }
  },
  computed: {
    numRows() {
      return this.version[this.config.itemType].length
    },
    materialOptions() {
      var o = []
      for (var i = 0; i < this.version.materials.length; i++) {
        o.push({
          hex: this.version.materials[i].displayColour,
          value: this.version.materials[i].id,
          label: this.version.materials[i].label,
          w: this.version.materials[i].w,
          h: this.version.materials[i].h,
          thickness: this.version.materials[i].thickness,
          hasGrain: this.version.materials[i].hasGrain,
          sides: this.version.materials[i].sides
        })
      }
      return o
    },
    handleOptions() {
      var o = []
      for (var i = 0; i < this.version.handles.length; i++) {
        o.push({
          value: this.version.handles[i].id,
          label: this.version.handles[i].label,
          type: this.version.handles[i].value,
          orientation: this.version.handles[i].orientation
        })
      }
      return o
    },
    requiresVerticalGrain() {
      let groupHeight = 0
      let itemNumber = null
      // FIX FOR CORNER DOOR STACKING
      for (const front of this.version.fronts) {
        let specialType = front.frontType && front.frontType.includes('-') ? front.frontType.split('-')[2] : null
        let stackQty = specialType == 'cnr' ? 1 : front.qty
        // is the front veneer
        const material = this.version.materials.find(o => {
          return o.id === front.material
        })

        if (!material.hasGrain) continue

        // reset the group
        if (front.itemNum !== itemNumber) {
          itemNumber = front.itemNum
          groupHeight = front.h * stackQty + 13 * stackQty - 13
        } else {
          groupHeight += (front.h + 13) * stackQty
        }

        if (groupHeight > 1200) return true
      }
      return false
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKey)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKey)
  },
  methods: {
    getWonkyKey(key, total) {
      if (this.wonkyMode) {
        return key < total - 1 ? 2 : 1
      }
    },
    addEmptyRow() {
      var item = {
        uid: uid(10)
      }
      // add some default values
      switch (this.config.itemType) {
        case 'fronts':
          if (this.version.fronts.length == 0) {
            item.material = this.version.materials[0].id
            item.handle = this.version.handles[0].id
          } else {
            item.material = this.version.fronts[this.version.fronts.length - 1].material
            item.handle = this.version.fronts[this.version.fronts.length - 1].handle
          }
          break
        case 'coverPanels':
          if (this.version.coverPanels.length == 0) {
            item.material = this.version.materials[0].id
          } else {
            item.material = this.version.coverPanels[this.version.coverPanels.length - 1].material
          }
          item.doubleSided = false
          break
      }

      // create item type array on object if doesn't exist
      if (!this.version[this.config.itemType]) {
        this.$set(this.version, this.config.itemType, [])
      }

      this.version[this.config.itemType].push(item)
      this.$store.commit('setSelectedCell', {
        row: this.version[this.config.itemType].length - 1,
        col: 0
      })
    },
    copyRow(i) {
      var clone = Object.assign({}, this.version[this.config.itemType][i])
      clone.uid = uid(10)
      this.version[this.config.itemType].splice(i, 0, clone)
    },
    copyToBottom(i) {
      // assigns an item number 1 greater than the last in the list
      var itemNum =
        parseInt(this.version[this.config.itemType][this.version[this.config.itemType].length - 1].itemNum) + 1
      var clone = Object.assign({}, this.version[this.config.itemType][i])
      clone.uid = uid(10)
      if (itemNum) clone.itemNum = itemNum
      this.version[this.config.itemType].push(clone)
    },
    deleteRow(i) {
      this.version[this.config.itemType].splice(i, 1)
    },
    moveFocusRight(row, col) {
      if (
        !(this.$store.state.selectedCell.col > this.numCols - 3) &&
        !(this.$store.state.focussedCell.col > this.numCols - 3)
      ) {
        this.$store.commit('setSelectedCell', { row: row, col: col + 1 })
      }
    },
    moveFocusLeft(row, col) {
      if (this.$store.state.selectedCell.col > 0 || this.$store.state.focussedCell.col > 0) {
        this.$store.commit('setSelectedCell', { row: row, col: col - 1 })
      }
    },
    moveFocusUp(row, col) {
      if (this.$store.state.selectedCell.row > 0 || this.$store.state.focussedCell.row) {
        this.$store.commit('setSelectedCell', { row: row - 1, col: col })
      }
    },
    moveFocusDown(row, col) {
      if (
        this.$store.state.selectedCell.row < this.numRows - 1 &&
        this.$store.state.focussedCell.row < this.numRows - 1
      ) {
        this.$store.commit('setSelectedCell', { row: row + 1, col: col })
      } else {
        this.addEmptyRow()
      }
    },
    rowUp(row) {
      if (row > 0) {
        var item = this.version[this.config.itemType].splice(row, 1)
        this.version[this.config.itemType].splice(row - 1, 0, item[0])
      }
    },
    rowDown(row) {
      if (row < this.version[this.config.itemType].length - 1) {
        var item = this.version[this.config.itemType].splice(row, 1)
        this.version[this.config.itemType].splice(row + 1, 0, item[0])
      }
    },
    onKey(event) {
      if (this.$store.state.selectedCell.col == null && this.$store.state.focussedCell.col == null) {
        // console.log('Block it')
        return
      }

      var row =
        this.$store.state.focussedCell.row != null
          ? this.$store.state.focussedCell.row
          : this.$store.state.selectedCell.row
      var col =
        this.$store.state.focussedCell.col != null
          ? this.$store.state.focussedCell.col
          : this.$store.state.selectedCell.col

      // capture shifted keys
      let altKeyUsed = false
      if (event.altKey) {
        altKeyUsed = true

        switch (event.keyCode) {
          case 9: // tab
            event.preventDefault()
            this.moveFocusLeft(row, col)
            break
          case 67: // c
            if (!this.$store.state.focussedCell.col) {
              this.copyRow(this.$store.state.selectedCell.row)
            }
            break
          case 66: // b
            if (!this.$store.state.focussedCell.col) {
              this.copyToBottom(this.$store.state.selectedCell.row)
            }
            break
          case 68: // b
            if (!this.$store.state.focussedCell.col) {
              this.deleteRow(this.$store.state.selectedCell.row)
            }
            break
          default:
            altKeyUsed = false
        }
      }
      if (!altKeyUsed) {
        switch (event.keyCode) {
          case 9: //tab
            event.preventDefault()
            this.moveFocusRight(row, col)
            break
          case 39: // right
            if (!this.$store.state.cellEditable) {
              event.preventDefault()
              this.moveFocusRight(row, col)
            }
            break
          case 37: // left
            if (!this.$store.state.cellEditable) {
              event.preventDefault()
              this.moveFocusLeft(row, col)
            }
            break
          case 38: // arrow up
            if (this.$store.state.focussedCell.row == null && this.$store.state.focussedCell.col == null) {
              event.preventDefault()
              this.moveFocusUp(row, col)
            }
            break
          case 40: // arrow down
            if (this.$store.state.focussedCell.row == null && this.$store.state.focussedCell.col == null) {
              event.preventDefault()
              this.moveFocusDown(row, col)
            }
            break
          case 13: // enter
            if (this.$store.state.focussedCell.row == null) {
              this.$store.commit('setCellEditable', true)
            }
            this.$store.commit('toggleSelectFocus', false)
            break
          default:
            // if nothing focussed enter quick edit mode
            if (this.$store.state.focussedCell.col == null) {
              // If input is alphanumeric then set as prevUserInput
              if (
                (event.keyCode > 47 && event.keyCode < 58) || // numeric (0-9)
                (event.keyCode > 64 && event.keyCode < 91) || // upper alpha (A-Z)
                (event.keyCode > 96 && event.keyCode < 123)
              ) {
                // lower alpha (a-z)
                this.$store.commit('toggleSelectFocus', event.key)
              }
            }
        }
      }
    }
  }
}
</script>
