import Vue from 'vue'
import moment from 'moment'

moment.updateLocale('en', {
  week: {
    dow: 1, // First day of week is Monday
    doy: 4 // First week of year must contain 4 January (7 + 1 - 4)
  }
})

Vue.prototype.$moment = moment
