<template>
  <div>
    <v-form v-if="!submitting">
      <h3>Contact Information</h3>
      <div class="form-group">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.firstName"
              label="First Name"
              v-validate="'required'"
              data-vv-name="First Name"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('First Name')"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.lastName"
              label="Last Name"
              v-validate="'required'"
              data-vv-name="Last Name"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Last Name')"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          v-model="form.email"
          label="Customer Email"
          v-validate="'required|email'"
          data-vv-name="Customer Email"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('Customer Email')"
          required
        >
        </v-text-field>
        <v-text-field
          v-model="form.phone1"
          label="Phone Number"
          v-validate="'required'"
          data-vv-name="Phone"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('Phone')"
          required
        >
        </v-text-field>
      </div>
      <h3 class="mt-4">Delivery Address</h3>
      <div class="form-group">
        <v-text-field
          v-model="form.deliveryAddressLine1"
          label="Address Line 1"
          v-validate="'required'"
          data-vv-name="Delivery Address Line 1"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('Delivery Address Line 1')"
          required
        >
        </v-text-field>
        <v-text-field v-model="form.deliveryAddressLine2" label="Address Line 2"> </v-text-field>
        <v-text-field v-model="form.deliveryCity" label="City"> </v-text-field>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="form.deliveryState"
              :items="$utils.usStateCodes"
              label="State"
              v-validate="'required'"
              data-vv-name="Delivery State"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Delivery State')"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.deliveryPostcode"
              label="Zip code"
              v-validate="'required'"
              data-vv-name="Delivery Zip code"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Delivery Zip code')"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="mt-5  d-flex justify-end">
        <v-btn color="accent" depressed class="mx-0" @click="submit">{{
          foc ? 'Place Order' : 'Continue to Payment'
        }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import uid from 'uid'

export default {
  name: 'ContactInformationUS',
  props: ['form', 'foc'],
  data() {
    return {
      uid: uid,
      submitting: false,
      calculatingSalesTax: false
    }
  },
  methods: {
    submit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('success')
        }
      })
    }
  }
}
</script>
