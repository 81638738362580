<template>
  <div id="customerQuote">
    <v-alert class="ma-0 mb-3 caption" :value="!loading && !activeVersion.pricing" type="info" color="accent">
      Woops! This quote has been unpublished. If you're trying to view this quote please inform a member our quote team
      who will re-publish it for you 👍
    </v-alert>

    <div v-if="$vuetify.breakpoint.smAndUp && !loading" class="toolbar no-print">
      <img src="@/assets/p-logo.png" alt="Plykea" height="30px" class="mr-4" />
      <transition name="fade" mode="out-in">
        <v-tabs
          v-if="activeVersion.customerCadShared && !checkoutActive"
          :height="80"
          background-color="primary"
          dark
          slider-color="accent"
          @change="onTabChange"
        >
          <v-tab :key="0">Quote</v-tab>
          <v-tab :key="1">Visual</v-tab>
        </v-tabs>
        <v-btn
          v-else-if="checkoutActive"
          color="accent"
          text
          class="mx-0 px-0"
          @click="
            () => {
              currentTab = 0
              checkoutActive = false
            }
          "
          ><v-icon>mdi-chevron-left</v-icon>Back
        </v-btn>
      </transition>
      <v-spacer></v-spacer>
      <transition name="fade">
        <v-select
          v-if="!quote.invoiceID && versionNames.length > 1"
          dark
          dense
          hide-details
          v-model="selectedVersion"
          :items="versionNames"
          class="mt-0 pt-0 body-1"
        >
        </v-select>
      </transition>
      <v-btn
        class="ma-0 ml-3"
        v-if="
          $vuetify.breakpoint.smAndUp &&
            !checkoutActive &&
            !(quote.orders && quote.orders[quote.versions[selectedVersion].id])
        "
        color="accent"
        :disabled="!activeVersion.allowOrder || (quote.invoices && quote.invoices[activeVersion.id])"
        depressed
        dark
        @click="checkoutActive = true"
        >Place Order
      </v-btn>
      <v-btn
        class="ma-0 ml-3"
        v-else-if="$vuetify.breakpoint.smAndUp && !checkoutActive && order && order.balanceOutstanding && !order.foc"
        color="accent"
        depressed
        dark
        @click="checkoutActive = true"
        >Pay Balance
      </v-btn>
      <h3
        color="white"
        class="mx-4"
        style="color: white"
        v-else-if="quote.orders && quote.orders[quote.versions[selectedVersion].id]"
      ></h3>
    </div>

    <component
      v-if="!loading && activeVersion.pricing"
      :quote="quote"
      :version="activeVersion"
      :customerEmail="quote.customerEmail"
      :customerView="true"
      :is="publishedComponent"
      :key="selectedVersion"
      :order="order"
      @orderCreated="onOrderCreated"
    ></component>

    <v-overlay :value="loading">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import QuoteView from '@/views/QuoteView'
import CustomerCad from '@/components/CustomerCad'
import Checkout from '@/components/customer/Checkout'

export default {
  name: 'CustomerQuote',
  components: { QuoteView, CustomerCad, Checkout },
  props: ['quoteID', 'versionID', 'skipQuoteView'],
  data() {
    return {
      loading: true,
      quote: null,
      selectedVersion: 0,
      currentTab: 0,
      checkoutActive: this.skipQuoteView,
      order: null
    }
  },
  methods: {
    onTabChange(val) {
      this.currentTab = val
    },
    onOrderCreated(orderID) {
      this.$bind('order', this.$db.collection('orders').doc(orderID))
    }
  },
  watch: {
    selectedVersion() {
      if (this.quote.orders && this.quote.orders[this.activeVersion.id]) {
        this.$bind('order', this.$db.collection('orders').doc(this.quote.orders[this.activeVersion.id].orderID))
      } else if (this.order) {
        this.$unbind('order')
      }
    }
  },
  computed: {
    // used for version select list - TODO version select list can contain whole object as result rather than key... probably better approach
    versionNames() {
      var a = []
      for (var i = 0; i < this.quote.versions.length; i++) {
        var version = this.quote.versions[i]
        if (version.published) {
          var name =
            version.title == null
              ? this.$moment(this.$utils.ensureFirestoreTimestamp(version.date).toDate()).format('Do MMM YYYY H:mma')
              : version.title
          a.push({ text: name, value: i })
        }
      }
      return a
    },
    activeVersion() {
      if (this.quote != null) {
        return this.quote.versions[this.selectedVersion]
      } else {
        return false
      }
    },
    publishedVersions() {
      var a = []
      for (var i = 0; i < this.quote.versions.length; i++) {
        if (this.quote.versions[i].published) {
          var title =
            this.quote.versions[i].title == null
              ? this.$utils.formatDateTime(this.quote.versions[i].date)
              : this.quote.versions[i].title
          a.push({
            title: title,
            key: i
          })
        }
      }
      return a
    },
    publishedComponent() {
      let result
      if (this.checkoutActive) {
        result = 'Checkout'
      } else {
        switch (this.currentTab) {
          case 0:
            result = 'QuoteView'
            break
          case 1:
            result = 'CustomerCad'
            break
        }
      }
      return result
    }
  },
  created() {
    this.$bind('quote', this.$db.collection('quotes').doc(this.quoteID)).then(async () => {
      for (var i = 0; i < this.quote.versions.length; i++) {
        if (this.versionID == this.quote.versions[i].id) {
          this.selectedVersion = i
          break
        }
      }

      if (this.quote.orders && this.quote.orders[this.versionID]) {
        this.$bind('order', this.$db.collection('orders').doc(this.quote.orders[this.versionID].orderID))
      }

      this.loading = false
    })
  },
  mounted() {
    window.dataLayer.push({
      event: 'quote_view'
    })
  }
}
</script>
<style lang="scss" scoped>
#customerQuote {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .toolbar {
    width: 100%;
    background-color: $primary;
    display: flex;
    padding: 0 16px;
    align-items: center;
    min-height: 80px;
  }
}
</style>
