var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class GrabHandle {
  constructor(front) {
    // common
    this.models = {}
    this.width = 35
    this.height = 35
    this.insetX = 30
    this.insetY = 25
    this.front = front

    // unique
    this.pocketOffset = 20
    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.circleA = new makerjs.models.Ellipse(this.width / 2, this.height / 2)
    this.models.circleA.layer = layerNames.cutout.label
    makerjs.model.move(this.models.circleA, [this.width / 2, -this.height / 2])

    this.models.circleB = new makerjs.models.Ellipse(this.width / 2, this.height / 2)
    this.models.circleB.layer = layerNames.corebox.label
    makerjs.model.move(this.models.circleB, [this.width / 2, -this.height / 2])

    this.models.pocket = new makerjs.models.Ellipse(
      this.width / 2 + this.pocketOffset,
      this.height / 2 + this.pocketOffset
    )
    this.models.pocket.layer = layerNames.cbHandlePocket.label
    makerjs.model.move(this.models.pocket, [this.width / 2, -this.height / 2])
  }
}
