<template>
  <v-row class="align-center">
    <v-col class="d-flex align-center" :cols="2">
      <div class="d-flex flex-column mr-1">
        <v-icon small @click="$emit('rowUp', rowNum)">mdi-menu-up</v-icon>
        <v-icon small @click="$emit('rowDown', rowNum)">mdi-menu-down</v-icon>
      </div>
      <MegaSelect
        :row="rowNum"
        :col="0"
        :options="coverPanelTypes"
        :displayValue="true"
        :data.sync="data.coverPanelType"
        @change="onTypeChange"
      ></MegaSelect>
    </v-col>
    <v-col :cols="1" class="d-flex">
      <MegaCell :data.sync="data.itemNum" :row="rowNum" :col="1" dataType="String"></MegaCell>
      <v-tooltip v-if="Object.keys(tips).includes(data.material)" top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ tips[data.material] }}</span>
      </v-tooltip>
    </v-col>
    <v-col :cols="2" class="d-flex align-center">
      <div
        v-if="data.material"
        class="colour mr-2"
        :style="{ 'background-color': findCoverPanelHex(data.material) }"
      ></div>
      <MegaSelect
        :row="rowNum"
        :col="2"
        :options="filteredMaterials"
        :data.sync="data.material"
        placeholder="Select material"
        noOptions="No materials created yet"
      ></MegaSelect>
    </v-col>
    <v-col cols="1" class="d-flex">
      <v-icon :disabled="noLightingCoverPanel" @click="lightingDialog = true">mdi-lightbulb-on</v-icon>
      <v-icon :disabled="isVPlinth" @click="ventDialog = true" class="ml-2 pt-1">mdi-fan</v-icon>
      <v-icon v-if="data.coverPanelType === 'back' && grainRequired" @click="grainDialog = true" class="ml-2 pt-1"
        >mdi-waves</v-icon
      >
    </v-col>
    <v-col :cols="1">
      <MegaCell
        :data.sync="data.w"
        :row="rowNum"
        :col="3"
        :unit="region == 'us' ? 'inch' : 'mm'"
        :max="data.h > shortLengthMax || requiresVerticalGrain ? shortLengthMax : longLengthMax"
      ></MegaCell>
    </v-col>
    <v-col :cols="1">
      <MegaCell
        :data.sync="data.h"
        :row="rowNum"
        :col="4"
        :unit="region == 'us' ? 'inch' : 'mm'"
        :max="data.w > shortLengthMax ? shortLengthMax : longLengthMax"
      ></MegaCell>
    </v-col>
    <v-col :cols="2">
      <v-checkbox
        class="text-caption mt-0 pt-0"
        v-model="data.doubleSided"
        label="Double Sided"
        v-if="hasGrain"
        :disabled="lacquerCheckDisabled"
        hide-details
      >
        <template v-slot:label>
          <span class="text-caption">Double Sided</span>
        </template>
      </v-checkbox>
      <div v-else class="text-caption">n/a</div>
    </v-col>
    <v-col :cols="1">
      <MegaCell :data.sync="data.qty" :row="rowNum" :col="5"></MegaCell>
    </v-col>
    <v-col class="d-flex" :order="wonky ? 'first' : 'last'" :class="[{ 'justify-end': !wonky }]" xs2>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-2" @click="$emit('copy', rowNum)" v-on="on">mdi-content-copy</v-icon>
        </template>
        <span>Copy</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-2" @click="$emit('copyBottom', rowNum)" v-on="on">mdi-arrow-collapse-down</v-icon>
        </template>
        <span>Copy to bottom</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small @click="$emit('delete', rowNum)" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </v-col>

    <!-- Lighting Dialog -->
    <v-dialog v-model="lightingDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Lighting</v-card-title>
        <v-card-text class="pt-4">
          <p>
            Dimension should be inputted as the total sum of all lightings put together eg: "800" for 2 lights of 400.
            <br />
            Total width of light must be at least 60mm less than Cover Panel width (input is disabled without width).
            <br />
            You can enter an offset dimension and the choose which side to offset from.
          </p>
          <v-form ref="form">
            <v-text-field
              type="number"
              :rules="[v => (parseInt(v) < data.w - 60 && parseInt(v) > 200) || 'Must be less than width minus 60mm']"
              :disabled="!data.w"
              v-model.number="data.lighting"
              placeholder="Sum Total Light Dimension"
            ></v-text-field>
            <v-radio-group :disabled="!data.w || !data.lighting" v-model="data.lightingRef">
              <v-radio label="From the right" value="r"></v-radio>
              <v-radio label="From the left" value="l"></v-radio>
              <v-radio @click="data.lightingOffset = null" label="Centered" value="c"></v-radio>
            </v-radio-group>
            <v-text-field
              type="number"
              :rules="[
                v =>
                  (parseInt(v) <= data.w - data.lighting - 60 && parseInt(v) >= 30) ||
                  (data.lightingRef == 'c' && !data.lightingOffset) ||
                  'Must be inset by at least 30mm from each end of the panel and not oversized offset.'
              ]"
              :disabled="!data.w || !data.lighting || !data.lightingRef || data.lightingRef == 'c'"
              v-model.number="data.lightingOffset"
              placeholder="Offset Dimension"
            ></v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Vent Dialog -->
    <v-dialog v-model="ventDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Vented Plinth</v-card-title>
        <v-card-text class="pt-4">
          <p>
            Enter the dimension of the vent [should be 100mm less than carcass above], then enter the offset dimension
            and from which side of the plinth to offset. (input is disabled without width).
          </p>
          <v-text-field
            type="number"
            :rules="[
              v =>
                (parseInt(v) <= data.w - 100 && parseInt(v) >= 50) ||
                'Must be at least 50mm and less than width minus 100mm and not oversized offset.'
            ]"
            :disabled="!data.w"
            v-model.number="data.vent"
            placeholder="Vent Dimension"
          ></v-text-field>
          <v-radio-group :disabled="!data.w || !data.vent" v-model="data.ventRef">
            <v-radio label="From the right" value="r"></v-radio>
            <v-radio label="From the left" value="l"></v-radio>
          </v-radio-group>
          <v-text-field
            type="number"
            :rules="[
              v =>
                (parseInt(v) <= data.w - data.vent - 50 && parseInt(v) >= 50) ||
                'Must be inset by at least 50mm from each end of plinth.'
            ]"
            :disabled="!data.w || !data.vent || !data.ventRef"
            v-model.number="data.ventOffset"
            placeholder="Offset Dimension"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Grain Direction Dialog -->
    <v-dialog v-model="grainDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Grain Direction</v-card-title>
        <v-card-text class="pt-4">
          <p class="font-weight-bold">
            Default Grain is = {{ grainDirection ? Object.values(grainDirection)[0] : 'N/A' }}
          </p>
          <p>
            Make this item have an opposing grain direction to the default.
            <v-switch
              v-model="data.opposingGrain"
              :label="
                data.opposingGrain
                  ? Object.values(grainDirection)[0] == 'Horizontal Grain'
                    ? 'Opposing Grain is = Vertical Grain'
                    : 'Opposing Grain is = Horizontal Grain'
                  : 'Default Grain'
              "
              :disabled="onlyHozAllowed || onlyVertAllowed"
            ></v-switch>
          </p>
          <p v-if="onlyHozAllowed">
            WARNING! <br />
            Item is too wide; it's not possible to have a Vertical grain.
          </p>
          <p v-if="onlyVertAllowed">
            WARNING! <br />
            Item is too tall; it's not possible to have a Horizontal grain.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import productData from '@/js/productData'

import MegaSelect from '@/components/quoting/items/MegaSelect'
import MegaCell from '@/components/quoting/items/MegaCell'

export default {
  name: 'CoverPanel',
  props: ['rowNum', 'data', 'materials', 'wonky', 'region', 'location', 'requiresVerticalGrain', 'grainDirection'],
  components: { MegaCell, MegaSelect },
  data() {
    return {
      usShortMax: 2388, //96" which allows 94"
      usLongMax: 2998, //120" which allows 118"
      twoInchesInMM: 52,
      coverPanelTypes: productData.coverPanelTypes,
      lightingDialog: false,
      ventDialog: false,
      grainDialog: false,
      shippingZone: ''
    }
  },
  async created() {
    await this.getShippingZoneName(this.location)
  },
  watch: {
    'data.w': function(val) {
      if (val > 1210 && Object.values(this.grainDirection)[0] === 'Horizontal Grain') {
        this.data.opposingGrain = false // cant be vert
      } else if (val > 1210 && Object.values(this.grainDirection)[0] !== 'Horizontal Grain') {
        this.data.opposingGrain = true // oppose vert or N/A, needs to be hoz
      }
    },
    'data.h': function(val) {
      if (val > 1210 && Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        this.data.opposingGrain = false
      } else if (val > 1210 && Object.values(this.grainDirection)[0] !== 'Vertical Grain') {
        this.data.opposingGrain = true
      }
    },
    //watch rules in this.$refs.form
    lightingDialog() {
      if (!this.$refs.form.validate()) this.$refs.form.reset()
    }
  },
  computed: {
    noLightingCoverPanel() {
      return !['underside', 'shelf'].includes(this.data.coverPanelType)
    },
    usJumboSheet() {
      return this.usLongMax + this.twoInchesInMM
    },
    usNormalSheet() {
      return this.usShortMax + this.twoInchesInMM
    },
    longLengthMax() {
      const m = this.materials.find(m => {
        return m.value === this.data.material
      })

      if (this.region === 'us' && this.data.coverPanelType && m) {
        if (m.w === this.usJumboSheet && this.shippingZone === 'San Francisco') {
          return this.usLongMax
        } else {
          return this.usShortMax
        }
      } else if (this.region === 'uk' && this.data.coverPanelType && m) {
        return this.data.coverPanelType === 'plinth' ? m.w : m.w - 20
      } else {
        return 0
      }
    },
    shortLengthMax() {
      const m = this.materials.find(m => {
        return m.value === this.data.material
      })
      if (!m) {
        return 0
      } else {
        return this.data.coverPanelType === 'plinth' ? m.h : m.h - 20
      }
    },
    hasGrain() {
      const m = this.materials.find(m => {
        return m.value === this.data.material
      })
      return m?.hasGrain || false
    },
    filteredMaterials() {
      return this.materials.filter(material => {
        return material.thickness >= 18
      })
    },
    lacquerCheckDisabled() {
      let val = false
      switch (this.data.coverPanelType) {
        case 'v-plinth':
        case 'plinth':
        case 'shelf':
          val = true
          break
      }
      return val
    },
    isVPlinth() {
      let val = true
      switch (this.data.coverPanelType) {
        case 'v-plinth':
          val = false
          break
      }
      return val
    },
    tips() {
      let tips = {}

      this.materials.forEach(material => {
        if (this.region === 'us' && this.data.coverPanelType) {
          if (material.w === this.usJumboSheet && this.shippingZone === 'San Francisco') {
            tips[material.value] = productData.getCoverPanelType(this.data.coverPanelType).longMaxTip
          } else {
            tips[material.value] = productData.getCoverPanelType(this.data.coverPanelType).shortMaxTip
          }
        }
      })

      return tips
    },
    onlyHozAllowed() {
      if (this.data.w > 1210 && Object.values(this.grainDirection)[0] === 'Horizontal Grain') {
        return true // must disable
      } else if (this.data.w > 1210 && Object.values(this.grainDirection)[0] !== 'Horizontal Grain') {
        return true // must disable
      } else {
        return false // else narrow
      }
    },
    onlyVertAllowed() {
      if (this.data.h > 1210 && Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        return true
      } else if (this.data.h > 1210 && Object.values(this.grainDirection)[0] !== 'Vertical Grain') {
        return true
      } else {
        return false
      }
    },
    grainRequired() {
      if (!this.hasGrain) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    onTypeChange(val) {
      switch (val) {
        case 'v-plinth':
          this.data.vent = null
          this.data.ventOffset = null
          this.data.ventRef = null
          break
        case 'plinth':
        case 'shelf':
          this.data.doubleSided = true
          break
        default:
          this.data.doubleSided = false
      }
    },
    async getShippingZoneName(loc) {
      const shippingZoneSnap = await this.$db
        .collection('shippingZones')
        .doc(loc)
        .get()

      if (shippingZoneSnap.exists) {
        this.shippingZone = shippingZoneSnap.data().name
      }
    },
    findCoverPanelHex(materialCode) {
      if (!materialCode) return []
      const match = this.materials.find(material => {
        return material.value === materialCode
      })
      return match.hex
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  border-bottom: solid 1px #eee;
  .col {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.colour {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border: solid 1px #999;
  border-radius: 3px;
  margin-right: 12px;
}
</style>
