<template>
  <v-menu transition="scale-transition" :close-on-content-click="true" offset-y>
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="timestamp ? $utils.humanFromVuetify($utils.vuetifyTimestamp(timestamp)) : null"
        class="caption"
        :label="label"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="disabled"
        @click:clear="onClear"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="timestamp ? $utils.vuetifyTimestamp(timestamp) : null"
      :disabled="disabled"
      :first-day-of-week="1"
      scrollable
      @input="changed"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePickerPlus',
  props: ['timestamp', 'placeholder', 'clearable', 'label', 'disabled'],
  methods: {
    changed(a) {
      this.$emit('update:timestamp', this.$utils.timestampFromVuetify(a))
    },
    onClear() {
      this.$emit('update:timestamp', null)
    }
  }
}
</script>
