var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"align-start pa-0",attrs:{"id":"day","fluid":"","fill-height":""}},[_c('v-row',{staticClass:"ma-0"},_vm._l((_vm.taskTypeGroups),function(taskTypeGroup,index){return _c('v-col',{key:index,staticClass:"pa-0"},[_c('div',{staticClass:"coloured d-flex justify-space-between pa-2",class:taskTypeGroup.name},[_c('div',{class:taskTypeGroup.name},[_vm._v(" "+_vm._s(taskTypeGroup.label)+" ")]),_c('v-spacer'),_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.getDailyUnitsByType(taskTypeGroup.name))+" ")]),(taskTypeGroup.name === 'finish')?_c('div',[_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_vm._v(_vm._s(_vm.getDailyMegaUnitsByType(taskTypeGroup.name))+" mµ ")]):_vm._e()],1),_vm._l((_vm.tasks),function(task,key){return (taskTypeGroup.types.indexOf(task.type) >= 0)?_c('div',{key:key,staticClass:"task body-2",class:[
          task.type,
          _vm.$utils.camelize(task.subtype),
          { coloured: !task.complete && !task.paused },
          { paused: task.paused }
        ],staticStyle:{"flex-direction":"column"},attrs:{"id":task.id},on:{"click":function($event){return _vm.$emit('openTask', task)}}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v(_vm._s(task.customerEmail))]),_c('v-spacer'),_c('div',[_vm._v(_vm._s(task.units))]),(task.type === 'finish' || task.type === 'assemble')?_c('div',[_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_vm._v(_vm._s(_vm.$utils.toFixedNumber(task.muUnits, 1))+" mµ ")]):_vm._e(),(task.complete)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1),_c('div',{staticClass:"d-flex justify-space-between align-end"},[_c('div',[(task.type === 'cut' && !task.toolpathed)?_c('v-icon',{style:({
                'padding-bottom': '1px',
                'padding-right': '3px',
                color: task.complete ? 'black' : 'white'
              }),attrs:{"size":14}},[_vm._v("mdi-alert")]):_vm._e(),(
                task.type === 'dispatch' && task.subtype === 'To customer' && task.courierBooked && !task.complete
              )?_c('v-icon',{attrs:{"size":15,"dark":""}},[_vm._v("mdi-truck-check")]):_vm._e(),_vm._v(" "+_vm._s(task.orderNumber || task.invoiceNumber)+" ")],1),_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(task.objectType)+" "+_vm._s(taskTypeGroup.name == 'dispatch' ? (task.subtype ? task.subtype : task.type) : '')+" ")])])]):_vm._e()})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }