<template>
  <v-container fluid>
    <v-row wrap v-if="settings">
      <v-col cols="12" md="4">
        <h3>Lead Times</h3>
      </v-col>
      <v-col cols="6" md="4">
        <h4>UK</h4>
        <v-text-field
          v-for="(a, index) in sortedObject(settings.leadTimes.uk)"
          v-model.number="settings.leadTimes.uk[a[0]]"
          :label="a[0]"
          :key="index"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="4">
        <h4>US</h4>
        <v-text-field
          v-for="(a, index) in sortedObject(settings.leadTimes.us)"
          v-model.number="settings.leadTimes.us[a[0]]"
          :label="a[0]"
          :key="index"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="8" offset-md="4" class="pt-0">
        <v-btn block color="accent" depressed @click="updateLeadTimes">
          <span v-if="!leadTimesSubmitting">Save</span>
          <v-progress-circular v-else :size="20" :width="2" indeterminate></v-progress-circular>
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
        <h3>Mu Settings</h3>
      </v-col>
      <v-col cols="6" md="4">
        <v-text-field
          :disabled="!['sho@plykea.com', 'kath@plykea.com', 'adam@plykea.com'].includes($store.state.user.email)"
          v-model.number="muSettings.muCutCapacity"
          label="Cut Capacity"
          suffix="per day"
        ></v-text-field>
        <v-text-field
          :disabled="!['sho@plykea.com', 'kath@plykea.com', 'adam@plykea.com'].includes($store.state.user.email)"
          v-model.number="muSettings.muFinishCapacity"
          label="Finish Capacity"
          suffix="per person per day"
        ></v-text-field>
        <v-text-field
          :disabled="!['sho@plykea.com', 'kath@plykea.com', 'adam@plykea.com'].includes($store.state.user.email)"
          v-model.number="muSettings.muPackCapacity"
          label="Pack Capacity"
          suffix="per day"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="8" offset-md="4" class="pt-0">
        <v-btn block color="accent" depressed @click="updateMuSettings">
          <span v-if="!muSettingsSubmitting">Save</span>
          <v-progress-circular v-else :size="20" :width="2" indeterminate></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProductionSettings',
  data() {
    return {
      settings: null,
      leadTimes: null,
      leadTimesSubmitting: false,
      muSettingsSubmitting: false,
      muSettings: {
        muCutCapacity: null,
        muFinishCapacity: null,
        muPackCapacity: null
      }
    }
  },
  async created() {
    const settings = await this.$db
      .collection('settings')
      .where('name', '==', 'public')
      .get()
    this.settingsRef = settings.docs[0].ref
    this.settings = settings.docs[0].data()

    // Set default values
    this.muSettings.muCutCapacity = this.settings.muCutCapacity
    this.muSettings.muFinishCapacity = this.settings.muFinishCapacity
    this.muSettings.muPackCapacity = this.settings.muPackCapacity
  },
  methods: {
    async updateLeadTimes() {
      if (!this.leadTimesSubmitting) {
        this.leadTimesSubmitting = true
        await this.settingsRef.update({
          leadTimes: this.settings.leadTimes
        })
        this.leadTimesSubmitting = false
        this.$emit('snackbar', 'Lead Times Updated')
      }
    },
    async updateMuSettings() {
      if (!this.muSettingsSubmitting) {
        this.muSettingsSubmitting = true
        await this.settingsRef.update({
          muCutCapacity: this.muSettings.muCutCapacity,
          muFinishCapacity: this.muSettings.muFinishCapacity,
          muPackCapacity: this.muSettings.muPackCapacity
        })
        this.muSettingsSubmitting = false
        this.$emit('snackbar', 'Mu Settings Updated')
      }
    },
    sortedObject(o) {
      return Object.entries(o).sort()
    }
  }
}
</script>
