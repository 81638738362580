var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"staff-settings"}},[_c('div',{attrs:{"id":"view-sub-nav"}},[_c('v-btn',{attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":function () {
          _vm.dialogMode = 'Create'
          _vm.selectedItem = _vm.newItemForm
          _vm.itemDialog = true
        }}},[_vm._v("Create New Staff Member")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":"","clearable":true},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1),(_vm.data)?_c('v-data-table',{attrs:{"items":_vm.data.filter(function (o) {
        return !o.departureDate
      }),"loading":_vm.loading,"headers":_vm.headers,"sort-by":"name","items-per-page":-1,"search":_vm.tableSearch,"id":"materials-table"},scopedSlots:_vm._u([{key:"item.capacity",fn:function(ref){
      var item = ref.item;
return [(item.staffType === 'employed')?_c('div',_vm._l((item.capacity[item.capacity.length - 1].hours),function(day,key){return (item.capacity[key] !== null)?_c('v-chip',{key:key,staticClass:"mx-1",attrs:{"outlined":""}},[_vm._v(_vm._s(_vm.daysOfWeek[key])+" "),_c('v-avatar',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(item.capacity[item.capacity.length - 1].hours[key])+" ")])],1):_vm._e()}),1):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('td',{staticClass:"d-flex justify-end align-center"},[_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function () {
                  _vm.dialogMode = 'Edit'
                  _vm.itemDialog = true
                  _vm.selectedItem = item
                }}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit Staff Member")])]),(_vm.$store.state.user.permissions.admin)?_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function () {
                  _vm.deleteDialog = true
                  _vm.selectedItem = item
                }}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Staff Member")])]):_vm._e()],1)]}}],null,false,3217382383)}):_vm._e(),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.itemDialog),callback:function ($$v) {_vm.itemDialog=$$v},expression:"itemDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text title"},[_vm._v(" "+_vm._s(_vm.dialogMode == 'Create' ? 'Create New Staff Member' : 'Edit Staff Member')+" ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-form',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.selectedItem.name),callback:function ($$v) {_vm.$set(_vm.selectedItem, "name", $$v)},expression:"selectedItem.name"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.staffTypes,"label":"Staff Type","hide-details":""},model:{value:(_vm.selectedItem.staffType),callback:function ($$v) {_vm.$set(_vm.selectedItem, "staffType", $$v)},expression:"selectedItem.staffType"}})],1)],1),(_vm.selectedItem.staffType === 'employed')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Timetastic ID","disabled":_vm.dialogMode !== 'Create'},model:{value:(_vm.selectedItem.timetasticId),callback:function ($$v) {_vm.$set(_vm.selectedItem, "timetasticId", _vm._n($$v))},expression:"selectedItem.timetasticId"}})],1)],1):_vm._e(),(_vm.selectedItem.staffType === 'employed')?_c('div',{staticClass:"label mb-4 mt-4"},[_vm._v(" Daily Capacity in hours ")]):_vm._e(),(_vm.selectedItem.staffType === 'employed')?_c('div',_vm._l((_vm.selectedItem.capacity),function(schedule,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":schedule.from ? _vm.$moment.unix(schedule.from.seconds).format('Do MMM YYYY') : null,"label":"Start date","prepend-icon":"mdi-calendar","disabled":_vm.dialogMode === 'Edit' && !_vm.draftSchedule(schedule),"readonly":""}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"value":schedule.from ? _vm.$utils.vuetifyTimestamp(schedule.from) : null,"no-title":"","scrollable":"","first-day-of-week":1},on:{"input":function (val) {
                        schedule.from = _vm.$utils.timestampFromVuetify(val)
                      }}})],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',_vm._l((_vm.daysOfWeek),function(day,index){return _c('v-col',{key:index},[(_vm.selectedItem.capacity)?_c('v-text-field',{key:index,attrs:{"disabled":_vm.dialogMode === 'Edit' && !_vm.draftSchedule(schedule),"label":day,"outlined":""},model:{value:(schedule.hours[index]),callback:function ($$v) {_vm.$set(schedule.hours, index, _vm._n($$v))},expression:"schedule.hours[index]"}}):_vm._e()],1)}),1)],1)],1)}),1):_vm._e()],1)],1),_c('v-card-actions',[(_vm.dialogMode === 'Edit')?_c('v-btn',{staticClass:"pa-0",attrs:{"text":""},on:{"click":_vm.createNewSchedule}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create new schedule")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function () {
              _vm.itemDialog = false
              _vm.resetNewItemValues()
            }}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"accent","depressed":"","dark":"","disabled":_vm.submitting},on:{"click":function($event){_vm.dialogMode === 'Create' ? _vm.createNewItem() : _vm.updateItem()}}},[_vm._v(_vm._s(_vm.dialogMode === 'Create' ? 'Create' : 'Update'))])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"warning white--text title"},[_vm._v("Remove Staff Member")]),_c('v-card-text',{staticClass:"pt-6"},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.tmpDepartureDate ? _vm.$utils.humanFromVuetify(_vm.$utils.vuetifyTimestamp(_vm.tmpDepartureDate)) : null,"label":"Departure date","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"value":_vm.tmpDepartureDate ? _vm.$utils.vuetifyTimestamp(_vm.tmpDepartureDate) : null,"no-title":"","scrollable":""},on:{"input":function (val) {
                _vm.tmpDepartureDate = _vm.$utils.timestampFromVuetify(val)
              }}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function () {
              _vm.deleteDialog = false
              _vm.tmpDepartureDate = null
            }}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"warning","depressed":"","dark":""},on:{"click":_vm.removeStaffMember}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }