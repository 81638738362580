<template>
  <v-app>
    <v-navigation-drawer
      v-if="$store.state.user.isAuth && !$route.meta.printView && !$route.meta.allowPublic"
      v-model="drawer"
      mini-variant
      fixed
      app
    >
      <v-list>
        <v-list-item>
          <v-icon @click.stop="drawer = !drawer">mdi-menu</v-icon>
        </v-list-item>
      </v-list>
      <v-list dense>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/quotes">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Quotes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/invoiced">
          <v-list-item-icon>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Awaiting Payment</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/production">
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Production</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/dispatched">
          <v-list-item-icon>
            <v-icon>mdi-truck</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dispatched</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/samples">
          <v-list-item-icon>
            <v-icon>mdi-view-column</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Samples</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/offcuts">
          <v-list-item-icon>
            <v-icon>mdi-shape</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Offcuts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense class="mt-auto">
        <v-list-item to="/recuts">
          <v-list-item-icon>
            <v-icon>mdi-repeat</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Recuts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/stock">
          <v-list-item-icon>
            <v-icon>mdi-bookshelf</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Stock</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$store.state.user.permissions.admin" to="/orders">
          <v-list-item-icon>
            <v-icon>mdi-receipt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$store.state.user.permissions.settings" to="/analytics">
          <v-list-item-icon>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Analytics</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$store.state.user.permissions.settings" to="/settings">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/user">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>User Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      v-if="user !== null && !$route.meta.printView && !$route.meta.allowPublic"
      color="primary"
      dark
      class="elevation-0"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!drawer"></v-app-bar-nav-icon>
      <h1 class="text-h5">
        {{ $store.state.pageTitle }}
      </h1>
      <v-spacer></v-spacer>
      <v-btn class="ml-4 mr-0" color="accent" dark fab small @click="quoteAddDialog = !quoteAddDialog">
        <v-icon>mdi-clipboard-text</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </v-main>

    <!-- New Quote Dialog -->
    <v-dialog
      v-if="this.$firebase.auth().currentUser && !this.$route.meta.allowPublic"
      v-model="quoteAddDialog"
      width="500"
    >
      <QuoteAdd @cancel="quoteAddDialog = false" @complete="quoteAddDialog = false"></QuoteAdd>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar color="accent" v-model="$store.state.snackbar" :timeout="$store.state.snackbarTimeout" top class="py-0"
      >{{ $store.state.snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-icon :size="20" v-bind="attrs" @click="$store.commit('closeSnackbar')" class="mr-1">mdi-close </v-icon>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import QuoteAdd from '@/components/QuoteAdd'

export default {
  name: 'Default',
  components: { QuoteAdd },
  data() {
    return {
      defaultRoute: this.$route.path,
      user: this.$firebase.auth().currentUser,
      settingsDocID: 'mPPqOiiHvphomjk1qrI1',
      applicationVersion: 278,
      drawer: !this.$vuetify.breakpoint.mdAndDown,
      quoteAddDialog: false
    }
  },
  async created() {
    // Load in various settings
    await this.$db
      .collection('settings')
      .doc(this.settingsDocID)
      .onSnapshot(doc => {
        if (this.applicationVersion < doc.data().applicationVersion) {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Your version of MegaAdmin is out of date. Please refresh your browser',
            snackbarTimeout: -1
          })
        }
        this.$store.commit('muSettings', doc.data())
      })

    // Load Settings
    await this.$db
      .collection('settings')
      .doc('pricing')
      .onSnapshot(doc => {
        this.$store.commit('updatePricing', doc.data())
      })

    // Load Materials
    await this.$db.collection('materials').onSnapshot(snap => {
      let materials = []
      for (const m of snap.docs) {
        let mat = m.data()
        if (mat.colours) {
          mat.colours.push({
            hex: '#FFFFFF',
            text: 'TBC',
            value: 'tbc'
          })
        }
        materials.push(mat)
      }
      this.$store.commit('updateMaterials', materials)
    })

    // Load worktops
    await this.$db.collection('worktops').onSnapshot(snap => {
      let worktops = []
      for (const w of snap.docs) {
        worktops.push(w.data())
      }
      this.$store.commit('updateWorktops', worktops)
    })

    // Load Handles
    await this.$db.collection('handles').onSnapshot(snap => {
      let handles = []
      for (let h of snap.docs) {
        handles.push(h.data())
      }
      this.$store.commit('updateHandles', handles)
    })

    // Load Staff
    await this.$db.collection('staff').onSnapshot(snap => {
      let staff = []
      for (let doc of snap.docs) {
        let d = doc.data()
        d.id = doc.id
        staff.push(d)
      }
      this.$store.commit('updateStaff', staff)
    })
  }
}
</script>
<style lang="scss">
.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss" scoped>
h1 {
  font-weight: 500 !important;
}
</style>
