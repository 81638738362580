<template>
  <div id="schedule">
    <div id="view-sub-nav">
      <v-btn fab small color="accent" class="mr-2 elevation-0" @click="newTaskDialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark small color="primary" class="elevation-0 mx-2" @click="showUnscheduled = !showUnscheduled">
        {{ unscheduledByShipment.length }}
      </v-btn>
      <v-btn @click="resetPagination" outlined class="mx-2">Today</v-btn>
      <v-icon class="ml-2" @click="paginateCalendar('prev')">mdi-chevron-left</v-icon>
      <v-icon class="ml-2 mr-3" @click="paginateCalendar('next')">mdi-chevron-right</v-icon>
      <div class="activeRangeString headline">
        {{ activeRangeString }}
      </div>
      <div v-if="resourcingChangesToSave.length > 0">
        <v-btn hide-details @click="saveResourcingChanges" outlined class="ml-4">
          Save Changes
        </v-btn>
        <v-btn hide-details text @click="updateCapacity">
          Reset
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="resourceMode" fab x-small color="primary" class="mr-4 elevation-0" @click="updateCapacity(true)">
        <v-icon>mdi-cloud-download</v-icon>
      </v-btn>
      <v-btn
        fab
        small
        color="info"
        class="mr-6 elevation-0"
        v-if="selectedCalendarView === 'custom-daily'"
        @click="calculationDialog = true"
      >
        <v-icon>mdi-calculator</v-icon>
      </v-btn>
      <v-switch
        hide-details
        v-if="selectedCalendarView === 'custom-daily'"
        class="mr-6 mt-0"
        small
        v-model="resourceMode"
        label="View Resources"
      ></v-switch>
      <!-- LEGACY TOTAL (in units) -->
      <!-- <div
        v-if="dataRangeCapacity"
        class="d-flex align-baseline mr-4"
        :class="{
          'red--text': dataRangeCapacity.percentComplete < 100,
          'green--text': dataRangeCapacity.percentComplete >= 100
        }"
      >
        <div class="headline mr-2">{{ dataRangeCapacity.percentComplete }}%</div>
        <div class="text-body-2 text--secondary">of {{ dataRangeCapacity.totalUnits }}</div>
      </div> -->
      <v-select
        v-model="selectedCalendarView"
        :items="calendarViews"
        hide-details
        class="pt-0 mt-0 ml-2"
        style="flex-grow: 0"
        @change="onRangeChange"
      >
      </v-select>
    </div>

    <v-progress-linear v-if="!tasksLoaded || !capacityLoaded" indeterminate></v-progress-linear>

    <div v-show="tasksLoaded" id="schedule-body">
      <div ref="unscheduledContainer" id="unscheduled-container" :class="{ open: showUnscheduled }">
        <v-expansion-panels>
          <v-expansion-panel v-for="(shipment, i) in unscheduledByShipment" :key="i">
            <v-expansion-panel-header :class="unscheduledShipmentColour(shipment)">
              <div v-if="shipment.length > 0 && tasksBounded" class="text-truncate">
                <v-icon v-if="!isTaskPropertyTrue(shipment[0].shipmentID, 'dblCheck')" size="small" class="mr-1"
                  >mdi-shield-check</v-icon
                >
                <v-icon v-if="!isTaskPropertyTrue(shipment[0].shipmentID, 'toolpathed')" size="small" class="mr-1"
                  >mdi-desktop-classic</v-icon
                >
                {{ shipment[0].orderNumber || shipment[0].invoiceNumber }}
                {{ shipment[0].customerEmail }}
                {{
                  shipment[0].targetDeliveryDate
                    ? 'WK' + $utils.momentFromTimestamp(shipment[0].targetDeliveryDate).week()
                    : 'ASAP'
                }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <draggable
                v-model="unscheduledByShipment[i]"
                group="tasks"
                @end="onSortEnd"
                class="draggable-list"
                :sort="false"
                id="unscheduled"
              >
                <div
                  class="task body-2 justify-space-between"
                  v-for="(task, key) in shipment"
                  :class="[task.type, $utils.camelize(task.subtype), task.paused ? 'paused' : 'coloured']"
                  :id="task.id"
                  :key="key"
                >
                  <div class="type">
                    {{ task.objectType }}
                    {{ task.subtype ? task.subtype : task.type }}
                  </div>
                  <div class="count">
                    {{ task.units }}
                    <v-icon small dark @click="openTask(task)">mdi-pencil</v-icon>
                  </div>
                </div>
              </draggable>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <draggable group="tasks" @end="onSortEnd" id="unscheduled" class="draggable-list"> </draggable>
      </div>

      <!-- COMPONENT -->
      <div id="calendar-container">
        <transition name="fade-transition" mode="out-in">
          <component
            v-if="tasksLoaded && capacityLoaded"
            :is="activeComponent"
            :taskTypeGroups="taskTypeGroups"
            :today="today"
            :tasksByDay.sync="tasksByDay"
            :capacityObject.sync="capacityObject"
            :focussedTask="focussedTask"
            :staffUnitsPerDay="staffUnitsPerDay"
            :showUnscheduled="showUnscheduled"
            @onSortEnd="onSortEnd"
            @openTask="openTask"
            @focusTask="focusTask"
            @resourcesChanged="resourcesChanged"
            @cutHoursChanged="cutHoursChanged"
            @packHoursChanged="packHoursChanged"
          >
          </component>
        </transition>
      </div>
    </div>

    <!-- DIALOGS -->
    <v-dialog v-model="taskDialog" width="1000" content-class="task-dialog" @input="onTaskDialogClose">
      <v-card v-if="selectedTask">
        <v-card-title class="white--text title coloured" :class="selectedTask.type">
          {{ selectedTask.customerEmail }}
          {{ selectedTask.invoiceNumber || selectedTask.orderNumber }}
          {{ selectedTask.objectType }} {{ selectedTask.subtype }}
        </v-card-title>
        <v-card-text>
          <v-row wrap>
            <v-col md="6">
              <h3 class="my-4">Notes</h3>
              <v-textarea
                class="mb-3"
                hide-details
                placeholder="Tap to add notes"
                outlined
                v-model="selectedTask.notes"
              ></v-textarea>
              <div v-if="orderForSelectedTask && selectedTask.type === 'dispatch'">
                <h3 class="my-4">Useful Info</h3>
                <div>
                  <span class="text-body-2">Delivery Postcode</span>
                  <span class="ml-4 text-body-1">{{
                    orderForSelectedTask.customerContactDetails.deliveryPostcode
                  }}</span>
                </div>
                <div>
                  <span class="text-body-2">Customer Name</span>
                  <span class="ml-4 text-body-1"
                    >{{ orderForSelectedTask.customerContactDetails.firstName }}
                    {{ orderForSelectedTask.customerContactDetails.lastName }}</span
                  >
                </div>
                <div>
                  <span class="text-body-2">Customer Phone</span>
                  <span class="ml-4 text-body-1">{{
                    orderForSelectedTask.customerContactDetails.mobile ||
                      orderForSelectedTask.customerContactDetails.phone1
                  }}</span>
                </div>
                <div v-if="this.selectedTask.recutOrderNumber">
                  <h3 class="my-4">Order has recut</h3>
                  <p>
                    Shipment must contain recut from order number
                    {{ this.selectedTask.recutOrderNumber }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="4" v-if="!displayDispatchEtaPicker">
              <h3 class="my-4">Actions</h3>
              <v-btn
                class="my-2"
                v-if="selectedTask.dateScheduled == null"
                color="warning"
                block
                outlined
                @click="deleteSelectedTask"
                ><v-icon dark small class="mr-2">mdi-warning</v-icon>Delete Task</v-btn
              >
              <v-btn class="my-2" color="primary" block outlined @click="openSprayerView(selectedTask.shipmentID)"
                ><v-icon small class="mr-2">mdi-spray</v-icon>Open Sprayer List</v-btn
              >
              <v-btn class="my-2" color="primary" block outlined @click="openPackingView(selectedTask.shipmentID)"
                ><v-icon small class="mr-2">mdi-clipboard-check</v-icon>Open Packing List</v-btn
              >
              <v-btn class="my-2" color="primary" block outlined @click="printableSVG(selectedTask.shipmentRef)"
                ><v-icon small class="mr-2">mdi-chart-tree</v-icon>Open Visual</v-btn
              >
              <v-btn
                v-if="selectedTask.type === 'dispatch' && selectedTask.subtype === 'To customer'"
                class="my-2"
                color="primary"
                block
                outlined
                @click="toggleCourierBookedStatus"
                ><v-icon small class="mr-2">mdi-truck-check</v-icon
                >{{ selectedTask.courierBooked ? 'Courier Not Booked' : 'Courier Booked' }}</v-btn
              >
              <v-btn
                v-if="selectedTask.type === 'dispatch' && selectedTask.subtype === 'To customer'"
                class="my-2"
                color="primary"
                block
                outlined
                @click="displayDispatchEtaPicker = true"
                ><v-icon small class="mr-2">mdi-clock-time-four</v-icon>Set Dispatch ETA</v-btn
              >
              <v-btn v-if="selectedTask.siblingId" class="my-2" color="primary" block outlined @click="unsplitTask"
                ><v-icon small class="mr-2">mdi-set-center</v-icon>Unsplit Task</v-btn
              >
              <div v-else>
                <v-btn
                  v-if="!splitDialog"
                  class="my-2"
                  color="primary"
                  block
                  outlined
                  @click="
                    () => {
                      splitDialog = true
                      splitVal = Math.round(selectedTask.units / 2)
                    }
                  "
                  ><v-icon small class="mr-2">mdi-arrow-split-vertical</v-icon>Split Task</v-btn
                >
                <div class="d-flex align-center" v-else :style="{ height: '36px' }">
                  <div class="text-center" :style="{ width: '20px' }">{{ selectedTask.units - splitVal }}</div>
                  <v-slider
                    v-model="splitVal"
                    :max="selectedTask.units - 1"
                    min="1"
                    hide-details
                    class="mx-1"
                  ></v-slider>
                  <div class="text-center" :style="{ width: '20px' }">{{ splitVal }}</div>
                  <v-btn
                    color="primary"
                    outlined
                    fab
                    x-small
                    class="ml-2"
                    @click="
                      () => {
                        splitDialog = false
                      }
                    "
                    :disabled="splittingProcess"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                  <v-btn
                    color="primary"
                    outlined
                    fab
                    x-small
                    class="ml-2"
                    @click="splitTask"
                    :disabled="splittingProcess"
                    ><v-icon>mdi-arrow-split-vertical</v-icon></v-btn
                  >
                </div>
              </div>
              <v-btn
                class="mt-8"
                v-if="!selectedTask.complete"
                color="accent"
                block
                depressed
                :dark="
                  !(
                    selectedTask.type === 'dispatch' &&
                    selectedTask.subtype === 'To customer' &&
                    !selectedTask.dispatchEta
                  ) && !tmpOverideDispatchEta
                "
                :disabled="
                  selectedTask.type === 'dispatch' &&
                    selectedTask.subtype === 'To customer' &&
                    !selectedTask.dispatchEta &&
                    !tmpOverideDispatchEta
                "
                @click="completeSelectedTask"
                >Task Complete 🙌</v-btn
              >
              <v-btn
                class="mt-8"
                v-if="selectedTask.complete"
                color="accent"
                block
                depressed
                dark
                @click="uncompleteTask"
                >Task Not Complete</v-btn
              >
            </v-col>
            <v-col cols="4" v-else>
              <h3 class="my-4">Dispatch ETA</h3>
              <v-btn
                class="mb-4"
                @click="
                  () => {
                    tmpOverideDispatchEta = true
                    displayDispatchEtaPicker = false
                  }
                "
                block
                depressed
                >Unavailable</v-btn
              >
              <v-time-picker v-model="selectedTask.dispatchEta" format="24hr" full-width></v-time-picker>
              <v-btn class="mt-8" color="accent" block depressed dark @click="updateDispatchETA">Done</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newTaskDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Add Task</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-autocomplete :items="taskTypes" v-model="form.type" label="Type" required></v-autocomplete>
            <v-autocomplete
              v-if="form.type && taskSubTypes[form.type]"
              :items="taskSubTypes[form.type]"
              v-model="form.subtype"
              label="Sub Type"
              required
            ></v-autocomplete>
            <v-text-field
              v-model="form.customerEmail"
              label="Customer Email"
              v-validate="'required|email'"
              data-vv-name="email"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('email')"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.invoiceNumber"
              label="Invoice Number"
              v-validate="'required'"
              data-vv-name="Invoice Number"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Intercom Conversation')"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.units"
              label="Units"
              v-validate="'required'"
              data-vv-name="Units"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Units')"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.workHours"
              label="Work Hours Per Head"
              v-validate="'required'"
              data-vv-name="Work Hour Per Head"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Units')"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.muUnits"
              disabled
              label="MegaUnits"
              data-vv-name="MegaUnits"
              suffix="Mµ (mµ per day X work hour per head)"
            ></v-text-field>
            <v-text-field
              v-model="form.objectType"
              label="Label"
              v-validate="'required'"
              data-vv-name="Label"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Label')"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="newTaskDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="createNewTask">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="calculationDialog" @click:outside="calculationDialog = false">
      <mu-calculator
        v-if="tasksLoaded && capacityLoaded"
        :tasks-by-day="tasksByDay"
        :capacity-object="capacityObject"
      />
    </v-dialog>

    <v-overlay :value="savingResources">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Day from '@/components/schedule/Day'
import Week from '@/components/schedule/Week'
import Month from '@/components/schedule/Month'
import WeekResources from '@/components/schedule/WeekResources'
import MonthResources from '@/components/schedule/MonthResources'
import MuCalculator from '@/components/schedule/MuCalculator'

export default {
  name: 'Schedule',
  components: { draggable, Day, Week, Month, WeekResources, MonthResources, MuCalculator },
  data() {
    return {
      today: this.$moment().format('YYYY-MM-DD'),
      calendarViews: [
        { value: 'day', text: 'Day', component: Day },
        { value: 'custom-daily', text: 'Week', component: Week },
        { value: 'month', text: 'Month', component: Month }
      ],
      resourceViews: [
        { value: 'custom-daily', text: 'Week', component: WeekResources },
        { value: 'month', text: 'Month', component: MonthResources }
      ],
      taskTypes: [
        { value: 'finish', text: 'Finish' },
        { value: 'cut', text: 'Cut' },
        { value: 'sand', text: 'Sand' },
        { value: 'oil', text: 'Oil' },
        { value: 'assemble', text: 'Assemble' },
        { value: 'pack', text: 'Pack' },
        { value: 'dispatch', text: 'Dispatch' }
      ],
      taskSubTypes: {
        dispatch: [
          { value: 'To customer', text: 'To Customer' },
          { value: 'Customer collection', text: 'Customer Collection' },
          { value: 'To sprayer', text: 'To Sprayer' }
        ]
      },
      taskTypeGroups: [
        {
          name: 'dispatch',
          types: ['dispatch'],
          label: 'Dispatch / Other'
        },
        { name: 'cut', types: ['cut'], label: 'Cut' },
        // { name: 'sand', types: ['sand', 'oil'], label: 'Sand & Oil' },
        { name: 'finish', types: ['finish', 'assemble'], label: 'Finish' },
        { name: 'pack', types: ['pack'], label: 'Pack' }
      ],
      selectedCalendarView: 'day',
      tasksLoaded: false,
      capacityLoaded: false,
      tasks: [],
      tasksBounded: false,
      boundTasks: {},
      focussedTask: null,
      unscheduledByShipment: [],
      tasksByDay: [],
      unscheduledTasks: [],
      capacity: null,
      selectedTask: null,
      selectedQuote: null,
      selectedVersion: null,
      showUnscheduled: false,
      staffUnitsPerDay: 45,
      cuttingHourPerDay: 8,
      packingHourPerDay: 6,
      form: {},
      tmpValue: null,
      taskDialog: false,
      newTaskDialog: false,
      calculationDialog: false,
      resourceMode: false,
      resourcingChangesToSave: [],
      savingResources: false,
      displayDispatchEtaPicker: false,
      tmpOverideDispatchEta: false,
      orderForSelectedTask: null,
      splitDialog: false,
      splitVal: 0,
      splittingProcess: false
    }
  },
  async mounted() {
    this.loadTasks()
    this.loadUnscheduledTasks()
    this.updateCapacity()
    this.updateTasksByDay()
  },
  computed: {
    capacityObject() {
      if (!this.capacity) return {}
      let o = {}
      for (const day of this.capacity) {
        if (day.cutting == null) {
          day.cutting = this.cuttingHourPerDay
        }
        if (day.packing == null) {
          day.packing = this.packingHourPerDay
        }
        o[this.$moment.unix(day.date.seconds).format('YYYY-MM-DD')] = day
      }
      return o
    },
    activeComponent() {
      return !this.resourceMode
        ? this.calendarViews.find(i => this.selectedCalendarView === i.value).component
        : this.resourceViews.find(i => this.selectedCalendarView === i.value).component
    },
    calendarDuration() {
      return this.$moment(this.activeDateRange.end).diff(this.$moment(this.activeDateRange.start), 'days') + 1
    },
    activeDateRange() {
      let r
      switch (this.selectedCalendarView) {
        case 'day':
          r = {
            start: this.today,
            end: this.today
          }
          break
        case 'custom-daily':
          r = {
            start: this.$moment(this.today)
              .startOf('week')
              .format('YYYY-MM-DD'),
            end: this.$moment(this.today)
              .startOf('week')
              .add(4, 'days')
              .format('YYYY-MM-DD')
          }
          break
        case 'month':
          {
            const startWeekday = this.$moment(this.today)
              .startOf('month')
              .isoWeekday()
            const endWeekday = this.$moment(this.today)
              .endOf('month')
              .isoWeekday()

            const startAdjust = startWeekday < 6 ? 1 - startWeekday : 8 - startWeekday
            const endAdjust = 5 - endWeekday
            r = {
              start: this.$moment(this.today)
                .startOf('month')
                .add(startAdjust - 7, 'days')
                .format('YYYY-MM-DD'),
              end: this.$moment(this.today)
                .endOf('month')
                .add(endAdjust + 7, 'days')
                .format('YYYY-MM-DD')
            }
          }
          break
      }
      return r
    },
    dataRangeCapacity() {
      if (!this.capacityObject || !this.tasksByDay) return null

      let totalUnits = 0
      for (const day of Object.values(this.tasksByDay)) {
        for (const task of day) {
          if (task.type !== 'dispatch') {
            totalUnits += parseInt(task.units)
          }
        }
      }

      let hours = 0
      for (const day of Object.values(this.capacityObject)) {
        const workers = day.working.concat(day.freelancers).concat(day.overtime)
        for (const worker of workers) {
          hours += worker.hours
        }
      }

      return {
        totalUnits,
        percentComplete: Math.round(((hours * 5) / totalUnits) * 100)
      }
    },
    activeRangeString() {
      var startMoment = this.$moment(this.activeDateRange.start)
      var endMoment = this.$moment(this.activeDateRange.end)
      let s

      switch (this.selectedCalendarView) {
        case 'day':
          s = this.$moment(this.today).format('dddd Do MMMM YYYY')
          break
        case 'month':
          s = this.$moment(this.today).format('MMMM YYYY')
          break
        case 'custom-daily':
          s =
            startMoment.month() == endMoment.month()
              ? this.$moment(this.today).format('MMMM YYYY [/ Week] ww')
              : startMoment.format('MMM') + ' - ' + endMoment.format('MMM YYYY [/ Week] ww')
          break
      }
      return s
    }
  },
  watch: {
    unscheduledTasks: {
      handler() {
        this.updateUnscheduledByShipment()
      },
      deep: true
    },
    tasks() {
      this.updateTasksByDay()
    },
    'form.workHours': function(val) {
      if (val) {
        this.form.muUnits = val * (this.$store.state.mu.finishCapacity / 8)
      } else {
        this.form.muUnits = null
      }
    }
  },
  methods: {
    onRangeChange() {
      this.resourceMode = false
      this.loadTasks()
      this.updateCapacity()
    },
    async openTask(task) {
      this.selectedTask = task
      this.tmpValue = task.notes
      this.taskDialog = true

      // load in the shipment so we can display stuff about it...
      this.orderForSelectedTask = null
      if (task.orderNumber) {
        const orderSnap = await this.$db
          .collection('orders')
          .where('orderNumber', '==', task.orderNumber)
          .get()
        this.orderForSelectedTask = orderSnap.docs[0].data()
      }
    },
    deleteSelectedTask() {
      this.taskDialog = false
      this.$db
        .collection('tasks')
        .doc(this.selectedTask.id)
        .delete()
        .then(() => {
          this.selectedTask = null
        })
    },
    uncompleteTask() {
      this.taskDialog = false
      this.$db
        .collection('tasks')
        .doc(this.selectedTask.id)
        .update({
          complete: false
        })
        .then(() => {
          this.selectedTask = null
          this.updateTasksByDay()
        })
        .catch(e => {
          console.log('error', e)
        })
    },
    toggleCourierBookedStatus() {
      this.taskDialog = false
      this.$db
        .collection('tasks')
        .doc(this.selectedTask.id)
        .update({
          courierBooked: !this.selectedTask.courierBooked
        })
        .then(() => {
          this.selectedTask = null
          this.updateTasksByDay()
        })
        .catch(e => {
          console.log('error', e)
        })
    },
    completeSelectedTask() {
      this.taskDialog = false

      this.$db
        .collection('tasks')
        .doc(this.selectedTask.id)
        .update({
          complete: true
        })
        .then(() => {
          this.selectedTask = null
          this.updateTasksByDay()
        })
        .catch(e => {
          console.log('error', e)
        })
    },
    updateDispatchETA() {
      this.$db
        .collection('tasks')
        .doc(this.selectedTask.id)
        .update({
          dispatchEta: this.selectedTask.dispatchEta
        })
      this.$db
        .collection('shipments')
        .doc(this.selectedTask.shipmentID)
        .update({
          dispatchEta: this.selectedTask.dispatchEta
        })

      this.displayDispatchEtaPicker = false
    },
    loadTasks() {
      this.tasksLoaded = false
      this.$bind(
        'tasks',
        this.$db
          .collection('tasks')
          .where('dateScheduled', '>=', this.$utils.timestampFromVuetify(this.activeDateRange.start))
          .where('dateScheduled', '<=', this.$utils.timestampFromVuetify(this.activeDateRange.end))
      ).then(() => {
        this.tasksLoaded = true
      })
    },
    async splitTask() {
      this.splittingProcess = true
      let taskClone = { ...this.selectedTask }
      taskClone.siblingId = this.selectedTask.id
      taskClone.originalMuUnits = this.selectedTask?.muUnits ? this.selectedTask.muUnits : null
      taskClone.originalUnits = this.selectedTask.units
      taskClone.shipmentRef = this.$db.collection('shipments').doc(this.selectedTask.shipmentID)
      taskClone.units = this.splitVal
      taskClone.muUnits = this.selectedTask?.muUnits
        ? this.$utils.toFixedNumber((this.selectedTask?.muUnits / this.selectedTask.units) * this.splitVal, 1)
        : null
      delete taskClone.id
      if (!taskClone.muUnits) delete taskClone.muUnits

      const newTask = await this.$db.collection('tasks').add(taskClone)
      const originalTask = this.$db.collection('tasks').doc(this.selectedTask.id)
      const taskUpdateInfo = {
        siblingId: newTask.id,
        originalUnits: this.selectedTask.units,
        originalMuUnits: this.selectedTask?.muUnits ? this.selectedTask.muUnits : null,
        units: this.selectedTask.units - this.splitVal,
        muUnits: this.selectedTask?.muUnits
          ? this.$utils.toFixedNumber(
              (this.selectedTask?.muUnits / this.selectedTask.units) * (this.selectedTask.units - this.splitVal),
              1
            )
          : null
      }
      if (!taskUpdateInfo.originalMuUnits) delete taskUpdateInfo.originalMuUnits
      if (!taskUpdateInfo.muUnits) delete taskUpdateInfo.muUnits
      await originalTask.update(taskUpdateInfo)

      this.splitDialog = false
      this.taskDialog = false
      this.splittingProcess = false
    },
    async unsplitTask() {
      await this.$db
        .collection('tasks')
        .doc(this.selectedTask.siblingId)
        .delete()

      await this.$db
        .collection('tasks')
        .doc(this.selectedTask.id)
        .update({
          units: this.selectedTask.originalUnits,
          muUnits: this.selectedTask?.originalMuUnits ? this.selectedTask?.originalMuUnits : null,
          siblingId: this.$firebase.firestore.FieldValue.delete(),
          originalUnits: this.$firebase.firestore.FieldValue.delete()
        })

      this.taskDialog = false
    },
    async generateRequiredDays(queryRange, force) {
      const batch = this.$db.batch()

      for (var i = 0; i < this.calendarDuration; i++) {
        let momentDay = this.$moment(this.activeDateRange.start).add(i, 'days')
        let dayCapacity = this.capacity.find(o => {
          return momentDay.isSame(o.date.seconds * 1000, 'day')
        })

        // save default capacity if doesn't exist in DB
        if (!dayCapacity && momentDay.isBetween(queryRange[0], queryRange[1], undefined, '[]')) {
          let capacityData = this.getDaysBaseCapacityFromSchedules(momentDay)
          capacityData.dateCreated = this.$firebase.firestore.Timestamp.now()
          capacityData.createdBy = this.$store.state.user.email

          const doc = this.$db.collection('capacity').doc()
          batch.set(doc, capacityData)
        } else if (force && momentDay.isBetween(queryRange[0], queryRange[1], undefined, '[]')) {
          let capacityData = this.getDaysBaseCapacityFromSchedules(momentDay)
          capacityData.dateCreated = this.$firebase.firestore.Timestamp.now()
          capacityData.createdBy = this.$store.state.user.email

          let capacityForDay = this.capacity.find(o => {
            return momentDay.isSame(this.$moment.unix(o.date.seconds), 'day')
          })
          const doc = this.$db.collection('capacity').doc(capacityForDay.id)
          batch.update(doc, capacityData)
        }
      }

      await batch.commit()
      return true
    },
    async updateHolidays() {
      const getHolidays = this.$firebase.functions().httpsCallable('getHolidays')
      const holidays = await getHolidays({
        startDate: this.$moment(this.activeDateRange.start).format('YYYY-MM-DD'),
        endDate: this.$moment(this.activeDateRange.end).format('YYYY-MM-DD')
      })

      // add any missing holidays
      let addHolidayBatch = this.$db.batch()

      let updatedData = {}

      for (const holiday of holidays.data.holidays) {
        for (var i = 0; i < holiday.duration; i++) {
          const momentDay = this.$moment(holiday.startDate).add(i, 'days')
          let capacityForDay = this.capacity.find(o => {
            return momentDay.isSame(this.$moment.unix(o.date.seconds), 'day')
          })

          if (!capacityForDay) continue

          if (!updatedData[momentDay.format('YYYY-MM-DD')]) {
            updatedData[momentDay.format('YYYY-MM-DD')] = capacityForDay
          }
          let data = updatedData[momentDay.format('YYYY-MM-DD')]

          // get staff details from Store
          let worker = this.$store.state.staff.find(o => {
            return o.staffType === 'employed' ? o.timetasticId === holiday.userId : false
          })

          // Quote team members are not found as not in the Staff section at the moment
          if (worker) {
            // Check if already in holiday
            let foundInHolidayArray = data.holidays.find(o => {
              return o.timetasticId === worker.timetasticId
            })

            if (!foundInHolidayArray)
              data.holidays.push({
                name: worker.name,
                timetasticId: worker.timetasticId
              })

            // Ensure worker not in working array
            data.working = data.working.filter(w => {
              return w.timetasticId !== holiday.userId
            })

            addHolidayBatch.update(this.$db.collection('capacity').doc(capacityForDay.id), data)
          }
        }
      }

      await addHolidayBatch.commit()

      let removeHolidayBatch = this.$db.batch()
      for (const day of this.capacity) {
        const maHolidays = day.holidays
        for (const holiday of maHolidays) {
          const testMoment = this.$moment.unix(day.date.seconds)

          const bookedHoliday = holidays.data.holidays.find(o => {
            const startMoment = this.$moment(o.startDate)
            const endMoment = this.$moment(o.endDate)
            return o.userId === holiday.timetasticId && testMoment.isBetween(startMoment, endMoment, undefined, '[]')
          })

          if (!bookedHoliday) {
            const staffScheduleForDate = this.$store.getters.staffHoursByMoment(testMoment, holiday)
            if (staffScheduleForDate) {
              const hours = staffScheduleForDate[testMoment.isoWeekday() - 1]

              // add to working array
              day.working.push({
                hours: hours,
                name: holiday.name,
                timetasticId: holiday.timetasticId
              })

              // Remove from holidays array
              day.holidays = day.holidays.filter(w => {
                return w.timetasticId !== holiday.timetasticId
              })
              removeHolidayBatch.update(this.$db.collection('capacity').doc(day.id), day)
            }
            // TODO not looked into why sometimes this returns null or if that is a problem
          }
        }
      }
      await removeHolidayBatch.commit()
    },
    async updateCapacity(force) {
      this.capacityLoaded = false

      // Set query range as a const for use after initial snap
      const startTimestamp = this.$utils.timestampFromVuetify(this.activeDateRange.start, 'start')
      const endTimestamp = this.$utils.timestampFromVuetify(this.activeDateRange.end, 'end')
      const queryRange = [this.$moment(this.activeDateRange.start), this.$moment(this.activeDateRange.end)]

      // Do initial get to check missing items and holiday
      /*
			const initialCapacitySnap = await this.$db
				.collection('capacity')
				.where('date', '>=', startTimestamp)
				.where('date', '<=', endTimestamp)
				.orderBy('date')
				.get()
				*/

      // Now setup a listener for ongoing changes to data
      await this.$bind(
        'capacity',
        this.$db
          .collection('capacity')
          .where('date', '>=', startTimestamp)
          .where('date', '<=', endTimestamp)
          .orderBy('date')
      )

      await this.generateRequiredDays(queryRange, force)
      // this.updateHolidays(this.capacity) // DISABLED UNTIL FIX IS MADE!
      this.capacityLoaded = true
    },
    getDaysBaseCapacityFromSchedules(momentDay) {
      let working = []
      let notWorking = []

      const employed = this.$store.state.staff.filter(o => {
        return o.staffType === 'employed' && !o.departureDate
      })

      // Track whether staff member is working or not working
      for (const s of employed) {
        const staffScheduleForDate = this.$store.getters.staffHoursByMoment(momentDay, s)
        if (staffScheduleForDate) {
          const hours = staffScheduleForDate[momentDay.isoWeekday() - 1]
          if (hours > 0) {
            working.push({
              name: s.name,
              timetasticId: s.timetasticId,
              hours
            })
          } else {
            notWorking.push({
              name: s.name,
              timetasticId: s.timetasticId
            })
          }
        }
      }

      return {
        date: this.$utils.timestampFromMoment(momentDay),
        working,
        holidays: [],
        sick: [],
        overtime: [],
        freelancers: []
      }
    },

    paginateCalendar(direction) {
      var increment = this.selectedCalendarView == 'custom-daily' ? 'week' : this.selectedCalendarView
      if (direction == 'next') {
        this.today = this.$moment(this.today)
          .add(1, increment)
          .format('YYYY-MM-DD')
      } else {
        this.today = this.$moment(this.today)
          .subtract(1, increment)
          .format('YYYY-MM-DD')
      }

      this.loadTasks()
      this.updateCapacity()
    },
    resetPagination() {
      this.today = this.$moment().format('YYYY-MM-DD')
      this.loadTasks()
      this.updateCapacity()
    },
    async loadUnscheduledTasks() {
      await this.$bind('unscheduledTasks', this.$db.collection('tasks').where('dateScheduled', '==', null))
      if (this.unscheduledTasks.length > 0) {
        this.unscheduledTasks.forEach(task => {
          if (task.shipmentID) {
            this.bindShipmentToTask(task)
          }
        })
      }
    },
    async bindShipmentToTask(task) {
      if (!task.shipmentID) return // Exit if no shipmentID is present

      this.boundTasks[task.shipmentID] = {
        dblCheck: null,
        toolpathed: null,
        materialOrdered: null
      }

      await this.$bind(`boundTasks.${task.shipmentID}`, this.$db.collection('shipments').doc(task.shipmentID))

      this.tasksBounded = true
      // console.log(task.shipmentID, this.boundTasks[task.shipmentID])
    },
    unscheduledShipmentColour(shipment) {
      let tag = shipment[0].shipmentID
      if (!tag) return ''

      if (shipment[0].paused) return 'paused'
      // console.log(boundTasks[tag])
      if (this.boundTasks[tag] && !shipment[0].pasued) {
        let boundTask = this.boundTasks[tag]
        if (boundTask?.dblCheck && boundTask?.toolpathed && boundTask?.materialOrdered) return 'info'
        if (boundTask?.dblCheck && boundTask?.toolpathed && !boundTask?.materialOrdered) return 'success'
        if (boundTask?.dblCheck && !boundTask?.toolpathed && !boundTask?.materialOrdered) return 'warning'
        if (!boundTask?.dblCheck && !boundTask?.toolpathed && !boundTask?.materialOrdered) return 'error'
        if (!boundTask?.dblCheck && boundTask?.toolpathed && !boundTask?.materialOrdered) return 'rare' // shouldnt really happen
      }
    },
    isTaskPropertyTrue(shipmentID, property) {
      if (!this.boundTasks[shipmentID] || this.boundTasks[shipmentID][property] == undefined) {
        return false // Assume property is false if boundTasks[shipmentID] does not exist or property is undefined
      } else {
        return this.boundTasks[shipmentID][property]
      }
    },
    updateTasksByDay() {
      // create the array
      var o = {}
      let i
      for (i = 0; i <= this.calendarDuration - 1; i++) {
        o[
          this.$moment(this.activeDateRange.start)
            .add(i, 'days')
            .format('YYYY-MM-DD')
        ] = []
      }
      // add the tasks
      for (let task of this.tasks) {
        var date = this.$utils.vuetifyTimestamp(task.dateScheduled)
        if (o[date]) {
          o[date].push(task)
        }
      }

      // sort each array by index
      var dates = Object.keys(o)
      for (i = 0; i < dates.length; i++) {
        var dateArray = o[dates[i]]
        dateArray.sort((a, b) => {
          return a.index > b.index ? 1 : -1
        })
      }

      this.tasksByDay = o
    },
    updateUnscheduledByShipment() {
      // convert to a keyed object
      var o = this.$utils.groupArrayByKey(this.unscheduledTasks, 'shipmentID')
      // convert back into an array
      var a = Object.values(o)
      // sort it
      a.sort((a, b) => {
        return a[0].targetDeliveryDate.seconds < b[0].targetDeliveryDate.seconds ? -1 : 0
      })
      // set it
      this.unscheduledByShipment = a
    },
    createNewTask() {
      this.submitting = true

      this.form.dateCreated = this.$firebase.firestore.Timestamp.now()
      this.form.dateScheduled = null
      this.form.shipmentID = null
      this.form.shipmentRef = null
      this.form.targetDeliveryDate = this.$firebase.firestore.Timestamp.now()
      this.form.manuallyCreated = true
      this.form.complete = null
      this.form.inProduction = true

      // clean up
      if (!this.form.subtype) {
        delete this.form.subtype
      }
      if (!this.form.objectType) {
        delete this.form.objectType
      }

      this.$db
        .collection('tasks')
        .add(this.form)
        .then(() => {
          this.newTaskDialog = false
          this.submitting = false
          this.$refs.form.reset()
          this.$validator.reset()
        })
        .catch(err => {
          console.log('Error', err)
        })
    },
    openPackingView(shipmentID) {
      var routeData = this.$router.resolve({
        name: 'packingView',
        params: { shipmentID: shipmentID }
      })
      window.open(routeData.href, '_blank')
    },
    openSprayerView(shipmentID) {
      var routeData = this.$router.resolve({
        name: 'sprayerView',
        params: { shipmentID: shipmentID }
      })
      window.open(routeData.href, '_blank')
    },
    printableSVG(shipmentID) {
      var routeData = this.$router.resolve({
        name: 'customerSVG',
        params: {
          quoteID: shipmentID.quoteID,
          versionID: shipmentID.versionID
        }
      })
      window.open(routeData.href, '_blank')
    },
    focusTask(task) {
      if (this.focussedTask && this.focussedTask.shipmentID == task.shipmentID) {
        this.focussedTask = null
      } else {
        this.focussedTask = task
      }
    },
    onTaskDialogClose() {
      this.displayDispatchEtaPicker = false
      this.tmpOverideDispatchEta = false

      if (this.selectedTask.notes !== this.tmpValue) {
        this.$db
          .collection('tasks')
          .doc(this.selectedTask.id)
          .update({
            notes: this.selectedTask.notes
          })
          .then(() => {
            this.$store.commit('openSnackbar', {
              snackbarText: 'Task notes updated'
            })
          })
      }
    },
    // save changes back to database when task moved
    onSortEnd(e) {
      let ref, i
      let batch = this.$db.batch()

      // Move task between days
      if (e.to !== e.from) {
        let date = e.to.id == 'unscheduled' ? null : e.to.id
        batch.update(this.$db.collection('tasks').doc(e.clone.id), {
          dateScheduled: this.$utils.timestampFromVuetify(date)
        })
      }
      // Update all the indexes in the from list
      if (e.from.id !== 'unscheduled') {
        for (i = 0; i < this.tasksByDay[e.from.id].length; i++) {
          ref = this.$db.collection('tasks').doc(this.tasksByDay[e.from.id][i].id)
          batch.update(ref, {
            index: i
          })
        }
      }
      // update the indexes in the to list
      if (e.to !== e.from && e.to.id !== 'unscheduled') {
        for (i = 0; i < this.tasksByDay[e.to.id].length; i++) {
          ref = this.$db.collection('tasks').doc(this.tasksByDay[e.to.id][i].id)
          batch.update(ref, {
            index: i
          })
        }
      }
      batch.commit()
    },
    resourcesChanged(date) {
      if (!this.resourcingChangesToSave.includes(date)) {
        this.resourcingChangesToSave.push(date)
      }
    },
    cutHoursChanged(date, cutHours) {
      if (this.capacityObject[date]?.cutting) {
        this.capacityObject[date].cutting = cutHours
      }
      this.resourcesChanged(date)
    },
    packHoursChanged(date, packHours) {
      if (this.capacityObject[date]?.packing) {
        this.capacityObject[date].packing = packHours
      }
      this.resourcesChanged(date)
    },
    async saveResourcingChanges() {
      this.savingResources = true

      let batch = this.$db.batch()

      for (const day of this.resourcingChangesToSave) {
        const obj = this.capacity.find(o => {
          return o.date.seconds === day
        })
        const data = this.capacityObject[this.$moment.unix(day).format('YYYY-MM-DD')]
        batch.update(this.$db.collection('capacity').doc(obj.id), data)
      }
      await batch.commit()

      this.resourcingChangesToSave = []
      this.savingResources = false
    }
  }
}
</script>
<style lang="scss" scoped>
.task-dialog {
  .action {
    .v-btn {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

#schedule {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  #schedule-body {
    width: 100%;
    flex-grow: 1;
    display: flex;

    #calendar-container {
      flex-grow: 1;
      position: relative;
    }
    #unscheduled-container {
      width: 0%;
      transition: width 0.5s;
      overflow: hidden;
      background: #fafafa;
      border-right: solid 1px #e0e0e0;
      box-shadow: inset -1px 0px 5px 0px rgba(0, 0, 0, 0.2);

      #unscheduled {
        height: 100%;
      }

      &.open {
        width: 20%;
      }

      ::v-deep.v-expansion-panel {
        margin: 0 !important;
        .v-expansion-panel-content__wrap {
          padding: 2px;
        }
        .v-expansion-panel-content {
          background: $sand;
          border-top: solid 1px $ruleGrey;
        }
      }
      ::v-deep.v-expansion-panel-header {
        padding: 0 4px 0 8px;
        &.paused {
          background: repeating-linear-gradient(-45deg, #ffebee, #ffebee 10px, #fff 10px, #fff 20px) !important;
          outlined: solid 1px #ffebee;
        }
        &.rare {
          background: $rare;
        }
      }
    }
  }
}

::v-deep .header {
  .past {
    opacity: 0.4;
  }
  .present {
    color: $pinkLink;
  }
}

::v-deep .task {
  margin: 4px;
  padding: 4px;
  border-radius: 3px;
  cursor: pointer;
  background: #eee;
  display: flex;
  &.sortable-ghost,
  &.ghost {
    opacity: 0.2;
  }
  &.inverted {
    background: white;
  }
  &.paused {
    background: repeating-linear-gradient(-45deg, #ffebee, #ffebee 10px, #fff 10px, #fff 20px) !important;
    outlined: solid 1px #ffebee;
  }
}
</style>
