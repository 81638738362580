let makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

import GrabHandle from '@/js/cad/production/handles/GrabHandle'
import SemiRecessedGrab from '@/js/cad/production/handles/SemiRecessedGrab'
import CirclePull from '@/js/cad/production/handles/CirclePull'
import SemiRecessedCircle from '@/js/cad/production/handles/SemiRecessedCircle'
import EdgePullRear from '@/js/cad/production/handles/EdgePullRear'
import JProfile from '@/js/cad/production/handles/JProfile'
import DPull from '@/js/cad/production/handles/DPull'
import HalfQuartArc from '@/js/cad/production/handles/HalfQuartArc'

export default class TwoPartCornerDoor {
  constructor(front, cornerRadius) {
    this.models = {}
    this.width = front.w === 250 ? 254 : parseInt(front.w)
    this.height = front.h === 800 ? 797 : parseInt(front.h)
    this.handle = front.handle
    this.type = front.type
    this.frontCornerRadius = cornerRadius
    this.cupDiameter = 35.5
    this.smallDrillDiameter = 5
    this.largeDrillDiameter = 8
    this.gap = 13
    this.topHingeExtraOffset = this.handle.value == 'j' ? 50 : 0

    this.cupPositions = [
      [23.5, 48.75],
      [23.5, this.height - 48.75 - this.topHingeExtraOffset],
      [this.width - 12.75, 48.75],
      [this.width - 12.75, this.height - 48.75 - this.topHingeExtraOffset]
    ]
    this.smallDrillPositions = [
      [this.width - 22.25, 26.25],
      [this.width - 22.25, 71.25],
      [this.width - 22.25, this.height - 71.25 - this.topHingeExtraOffset],
      [this.width - 22.25, this.height - 26.25 - this.topHingeExtraOffset],
      [this.width + this.gap + 28.5, 32.75],
      [this.width + this.gap + 28.5, 64.75],
      [this.width + this.gap + 28.5, this.height - 64.75 - this.topHingeExtraOffset],
      [this.width + this.gap + 28.5, this.height - 32.75 - this.topHingeExtraOffset]
    ]
    this.largeDrillPositions = [
      [33, 26.25],
      [33, 71.25],
      [33, this.height - 71.25 - this.topHingeExtraOffset],
      [33, this.height - 26.25 - this.topHingeExtraOffset]
    ]

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.outlineA = new makerjs.models.RoundRectangle(this.width, this.height, this.frontCornerRadius)
    this.models.outlineB = new makerjs.models.RoundRectangle(this.width, this.height, this.frontCornerRadius)
    makerjs.model.move(this.models.outlineB, [this.width + this.gap, 0])

    this.models.outlineA.layer = layerNames.cutout.label
    this.models.outlineB.layer = layerNames.cutout.label

    // do the cups
    if (this.height === 797) {
      // only if standard H800
      this.models.cups = {
        models: {}
      }
      for (let i = 0; i < this.cupPositions.length; i++) {
        this.models.cups.models[i] = new makerjs.models.Ellipse(this.cupDiameter / 2, this.cupDiameter / 2)
        makerjs.model.move(this.models.cups.models[i], this.cupPositions[i])
        this.models.cups.models[i].layer = layerNames.hingeCup.label
      }
      // 5mm drill
      this.models.smallDrill = new makerjs.models.Holes(this.smallDrillDiameter / 2, this.smallDrillPositions)
      this.models.smallDrill.layer = layerNames.hingeDrillMedium.label

      // 8mm drill
      this.models.largeDrill = new makerjs.models.Holes(this.largeDrillDiameter / 2, this.largeDrillPositions)
      this.models.largeDrill.layer = layerNames.hingeDrillLarge.label
    }

    this.addHandle()
    if (this.type == 'rhd') {
      let poop = makerjs.model.mirror(this, true, false)
      this.models = poop.models
      makerjs.model.move(this, [this.width * 2 + this.gap, 0])
    }
  }

  addHandle() {
    let handleType = this.handle.value
    let handleWidth
    switch (handleType) {
      case 'semiGrab':
        handleWidth = 140
        break
      case 'lrgHalfArc':
        handleWidth = 105
        break
      case 'halfArc':
        handleWidth = 90
        break
      case 'lrgQuartArc':
        handleWidth = 52.5
        break
      case 'quartArc':
        handleWidth = 45
        break
    }

    switch (handleType) {
      case 'none':
        break
      case 'grab':
        this.models.handle = new GrabHandle(this.models)
        break
      case 'semiGrab':
        this.models.handle = new SemiRecessedGrab(this.models, handleWidth, 'uk')
        break
      case 'lrgHalfArc':
      case 'halfArc':
      case 'lrgQuartArc':
      case 'quartArc':
        this.models.handle = new HalfQuartArc(this.models, handleType, handleWidth, 'uk')
        break
      case 'circle':
        this.models.handle = new CirclePull(this.models)
        break
      case 'semiCircle':
      case 'semiCir':
        this.models.handle = new SemiRecessedCircle(this.models)
        break
      case 'edge-br-s':
      case 'edge-bl-s':
      case 'edge-w-s':
      case 'edge-sn-s':
      case 'edge-cp-s':
      case 'edge-br-s-r':
      case 'edge-bl-s-r':
      case 'edge-w-s-r':
      case 'edge-sn-s-r':
      case 'edge-cp-s-r':
        this.models.handle = new EdgePullRear(this.models, 76, this.width, this.height)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(this.width, this.height)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        makerjs.model.move(this.models.boundingbox, [this.width + this.gap, 0])
        break
      case 'edge-br-l':
      case 'edge-bl-l':
      case 'edge-w-l':
      case 'edge-sn-l':
      case 'edge-cp-l':
      case 'edge-br-l-r':
      case 'edge-bl-l-r':
      case 'edge-w-l-r':
      case 'edge-sn-l-r':
      case 'edge-cp-l-r':
        this.models.handle = new EdgePullRear(this.models, 201, this.width, this.height)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(this.width, this.height)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'j':
        this.models.handle = new JProfile(this.models, this.width, true)
        this.models.handleClone = makerjs.model.mirror(this.models.handle, true, false)
        this.models.handleOutlineB = makerjs.model.mirror(this.models.handleOutlineA, true, false)
        break
      case 'dpull-br-s':
      case 'dpull-bl-s':
      case 'dpull-w-s':
        this.models.handle = new DPull(this.models, 128, this.width, this.height)
        break
      case 'dpull-br-l':
      case 'dpull-bl-l':
      case 'dpull-w-l':
        this.models.handle = new DPull(this.models, 192, this.width, this.height)
        break
      default:
        console.log('handle type not implemented yet', handleType)
    }

    if (this.models.handle) {
      // adjust handle position
      this.rotateHandle()
      this.moveHandle()

      // subtract any handle outline
      if (this.models.handleOutline) {
        makerjs.model.combineSubtraction(this.models.outlineB, this.models.handleOutline)
      }
    }
    if (this.models.handleOutlineA) {
      makerjs.model.combineSubtraction(this.models.outlineA, this.models.handleOutlineA)
      makerjs.model.combineSubtraction(this.models.outlineB, this.models.handleOutlineB)
    }
  }

  rotateHandle() {
    let hinge = this.type
    let position = this.handle.orientation
    let rotation = 0

    // set the required rotation of the handle
    if (['v', 'vc', 'vbt'].includes(position)) {
      if (hinge == 'lhd') {
        rotation = 270
      } else if (hinge == 'rhd') {
        rotation = -90
      }
    }

    // rotate the handle and it's outline
    if (this.models.handleOutline) makerjs.model.rotate(this.models.handleOutline, rotation, [0, 0])
    makerjs.model.rotate(this.models.handle, rotation, [0, 0])
  }

  moveHandle() {
    // let hinge = this.type
    let handleType = this.handle.value
    let xPos = 0
    let yPos = this.height - this.models.handle.insetY

    if (this.handle.orientation == 'h') {
      xPos = this.width * 2 + this.gap - (this.models.handle.width + this.models.handle.insetX)
      if (handleType == 'j') {
        xPos = 0
        yPos = this.height
      } else if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
        xPos = this.width + this.gap + this.width / 2
      } else if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
        xPos = this.width * 2 + this.gap + this.models.handle.shadowOffset + 0.025 // 0.025 is overlap tolerance
      }
    } else if (this.handle.orientation == 'c') {
      xPos =
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? this.width + this.gap + this.width / 2
          : this.width + this.gap + this.width / 2 - this.models.handle.width / 2
    } else if (['v', 'vbt'].includes(this.handle.orientation)) {
      xPos =
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? this.width * 2 + this.gap - this.models.handle.insetY
          : this.width * 2 + this.gap
      yPos =
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? this.height - this.models.handle.width / 2 - this.models.handle.insetX
          : this.height - this.models.handle.insetX
    } else if (this.handle.orientation == 'vc') {
      xPos =
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? this.width * 2 + this.gap - this.models.handle.insetY
          : this.width * 2 + this.gap
      yPos =
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? this.height / 2
          : this.height / 2 + this.models.handle.width / 2
    } else if (this.handle.orientation == 'vwb') {
      xPos = this.width * 2 + this.gap
      // console.log(this.models.handle.height)
      yPos = this.models.handle.width + this.models.handle.insetX
    }

    if (this.models.handleOutline) makerjs.model.move(this.models.handleOutline, [xPos, yPos])
    makerjs.model.move(this.models.handle, [xPos, yPos])

    if (handleType == 'j') {
      makerjs.model.move(this.models.handleOutlineA, [-50, yPos])
      makerjs.model.move(this.models.handleClone, [this.width * 2 + this.gap, yPos])
      makerjs.model.move(this.models.handleOutlineB, [this.width * 2 + this.gap + 50, yPos])
    }

    if (handleType.split('-').includes('dpull')) {
      if (this.type === 'lhd') {
        let xAdjust =
          this.handle.orientation == 'v' ? 50 : this.handle.orientation == 'c' ? (128 + this.width) / 2 : 128 + 50
        makerjs.model.move(this.models.handle, [this.width * 2 + 13 - xAdjust, this.height - 50])
      } else {
        makerjs.model.move(this.models.handle, [this.width * 2 + 13 - 50, this.height - 50])
      }
    }
  }
}
