const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'
import Lighting from '../features/addLighting'

export default class Lengths {
  constructor(
    urtil,
    materialThickness,
    sideWidth,
    lightingInfo,
    backInfo,
    rebateDistFromFront,
    tSlotInfo,
    grooveInfo,
    trimInfo,
    engravingInfo,
    sideRebateInfo
  ) {
    this.models = {
      bottomSide: { models: {} }
    }
    //VARS
    this.urtil = urtil
    this.materialThickness = materialThickness
    this.sideWidth = sideWidth

    this.lightingInfo = lightingInfo
    this.lightingHeight = lightingInfo?.height

    this.backInfo = backInfo
    this.backRebateToolDiameter = backInfo?.rebateToolDiameter
    this.backRebateWidth = backInfo?.rebateWidth

    this.rebateDistFromFront = rebateDistFromFront
    this.tSlotTolerance = tSlotInfo?.tolerance
    this.tSlotRebateHeight = tSlotInfo?.rebateHeight
    this.sideRebateDepth = sideRebateInfo?.depth

    this.grooveWidth = grooveInfo?.width
    this.grooveInset = grooveInfo?.inset
    this.grooveSpacing = grooveInfo?.spacing

    this.font = engravingInfo?.font
    this.fontSize = engravingInfo?.fontSize
    this.label = engravingInfo?.label
    this.orientationLabel = engravingInfo?.orientationLabel
    this.labelTrim = trimInfo?.label

    //calcs
    this.numOfDividers = urtil.compartments - 1
    this.fullTSlotHeight = materialThickness + tSlotInfo?.tolerance
    this.halfTSlotHeight = (materialThickness + tSlotInfo?.tolerance) / 2
    this.lightingWidth = urtil.lighting + lightingInfo?.padding
    this.internalVoid = sideWidth - sideRebateInfo?.depth * 2 - this.fullTSlotHeight * this.numOfDividers

    this.createLengths()
  }

  createLengths() {
    // top and bottom pieces
    let bottom = this.models.bottomSide.models
    bottom.outline = new makerjs.models.Rectangle(this.sideWidth, this.urtil.d)
    bottom.outline.layer = layerNames.cutout.label

    bottom.backRebate = new makerjs.models.Rectangle(
      this.sideWidth + this.backRebateToolDiameter,
      this.backRebateWidth + 1
    )
    bottom.backRebate.layer = layerNames.urtilBackPocket.label
    makerjs.model.move(bottom.backRebate, [-this.backRebateToolDiameter / 2, this.urtil.d - this.backRebateWidth])

    // Back pocket cut line (on-path)
    bottom.backRebateLine = {
      layer: layerNames.backPocketLine.label,
      paths: {
        rebateLine: {
          type: 'line',
          // origin x = 2, this.backRebateToolDiameter[=8] - 12mm toolbit / 2
          origin: [2, this.urtil.d - this.backRebateWidth / 2],
          end: [this.sideWidth - 2, this.urtil.d - this.backRebateWidth / 2]
        }
      }
    }

    // location label engraving
    const topEngrave = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    bottom.topEngrave = makerjs.model.mirror(topEngrave, true, false)
    bottom.topEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(bottom.topEngrave, [100, this.urtil.d - this.backRebateWidth / 2 - 4])

    // Engrave 'TRIM' on backRebate to show trimming edge
    if (this.urtil.trimming) {
      const trimText = new makerjs.models.Text(this.font, this.labelTrim.toUpperCase(), this.fontSize)
      bottom.backRebateEngraveL = makerjs.model.mirror(trimText, true, false)
      bottom.backRebateEngraveL.layer = layerNames.specialEngrave.label

      makerjs.model.move(bottom.backRebateEngraveL, [this.urtil.w - 30, this.urtil.d - this.backRebateWidth / 2])

      bottom.backRebateEngraveR = makerjs.cloneObject(bottom.backRebateEngraveL)
      bottom.backRebateEngraveR.layer = layerNames.specialEngrave.label
      makerjs.model.move(bottom.backRebateEngraveR, [40, this.urtil.d - this.backRebateWidth / 2])
    }

    // DOOR GROOVES
    if (this.urtil.type === 's' || this.urtil.type === 't') {
      bottom.frontGroove = new makerjs.models.Rectangle(this.sideWidth + this.backRebateToolDiameter, this.grooveWidth)
      bottom.frontGroove.layer = layerNames.urtilBottomDoorGroove.label
      makerjs.model.move(bottom.frontGroove, [-this.backRebateToolDiameter / 2, this.grooveInset])
    }
    if (this.urtil.type === 't') {
      bottom.backGroove = new makerjs.models.Rectangle(this.sideWidth + this.backRebateToolDiameter, this.grooveWidth)
      bottom.backGroove.layer = layerNames.urtilBottomDoorGroove.label
      makerjs.model.move(bottom.backGroove, [
        -this.backRebateToolDiameter / 2,
        this.grooveInset + this.grooveWidth + this.grooveSpacing
      ])
    }

    // FIN SLOTS ACCORDING TO COMPARTMENTS
    let xSpacing = this.internalVoid / this.urtil.compartments
    if (this.numOfDividers > 0) {
      let yPos = this.rebateDistFromFront
      for (let i = 0; i < this.numOfDividers; i++) {
        let xPos = xSpacing * (i + 1) + this.fullTSlotHeight * i + this.sideRebateDepth
        bottom['slotRebate' + (i + 1)] = new makerjs.models.Rectangle(
          this.materialThickness + this.tSlotTolerance,
          this.urtil.d - this.rebateDistFromFront
        )
        makerjs.model.move(bottom['slotRebate' + (i + 1)], [xPos, yPos])
        bottom['slotRebate' + (i + 1)].layer = layerNames.urtilFinPocket.label

        bottom['tSlotTrack' + (i + 1)] = {
          layer: layerNames.tSlotGroove.label,
          paths: {
            line1: {
              type: 'line',
              origin: [0, 0],
              end: [this.materialThickness + this.tSlotTolerance - 10, 0]
            },
            line2: {
              type: 'line',
              origin: [this.materialThickness + this.tSlotTolerance - 10, 0],
              end: [
                this.materialThickness + this.tSlotTolerance - 10,
                this.urtil.d - this.rebateDistFromFront - this.tSlotRebateHeight / 2
              ]
            },
            line3: {
              type: 'line',
              origin: [
                this.materialThickness + this.tSlotTolerance - 10,
                this.urtil.d - this.rebateDistFromFront - this.tSlotRebateHeight / 2
              ],
              end: [this.materialThickness + this.tSlotTolerance - 10, 0]
            },
            line4: {
              type: 'line',
              origin: [this.materialThickness + this.tSlotTolerance - 10, 0],
              end: [0, 0]
            }
          }
        }

        makerjs.model.move(bottom['tSlotTrack' + (i + 1)], [xPos + 5, yPos + 5])
      }
      // cloning bottom to make top piece
      this.models.topSide = makerjs.cloneObject(this.models.bottomSide)
      for (let i = 0; i < this.numOfDividers; i++) {
        // flip tSlotTrack
        let xMirrorPos = (xSpacing + this.fullTSlotHeight) * (i + 1) + this.sideRebateDepth
        this.models.topSide.models['tSlotTrack' + (i + 1)] = makerjs.model.mirror(
          this.models.topSide.models['tSlotTrack' + (i + 1)],
          true,
          false
        )
        makerjs.model.move(this.models.topSide.models['tSlotTrack' + (i + 1)], [xMirrorPos - 5, yPos + 5])
      }
    } else {
      this.models.topSide = makerjs.cloneObject(this.models.bottomSide)
    }

    // orientation label engraving
    const orientationEngrave = new makerjs.models.Text(this.font, this.orientationLabel.toUpperCase(), this.fontSize)
    this.models.topSide.models.orientationEngrave = makerjs.model.mirror(orientationEngrave, true, false)
    this.models.topSide.models.orientationEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(this.models.topSide.models.orientationEngrave, [
      this.urtil.w - 100,
      this.urtil.d - this.backRebateWidth / 2 - 4
    ])

    // positioning
    makerjs.model.move(this.models.topSide, [0, this.urtil.d + 50])

    if (this.urtil.type === 's') {
      this.models.topSide.models.frontGroove.layer = layerNames.urtilTopDoorGroove.label
    }
    if (this.urtil.type === 't') {
      this.models.topSide.models.frontGroove.layer = layerNames.urtilTopDoorGroove.label
      this.models.topSide.models.backGroove.layer = layerNames.urtilTopDoorGroove.label
    }

    if (this.urtil.lighting) {
      bottom.lighting = new Lighting(this.urtil, this.sideWidth, this.backInfo, this.lightingInfo)
    }
  }
}
