import store from '@/store'
import utils from '@/js/utils'
import Materials from '@/js/Materials'

export default class MasterPricing {
  static calculatePriceForMaterial(material, area, perimeter, sheets, items) {
    const sheetCost = Materials.getSheetCostFromProperties(
      material.value,
      material.w,
      material.h,
      material.thickness,
      material.sides
    )

    if (!sheetCost) return false

    // If whole sheets not specified calculate on area used only eg - Ürtil doors
    if (!sheets) {
      sheets = utils.toFixedNumber(area / (material.w * material.h) / 1000000, 2)
    }

    // Sheet
    const sheetsCost = sheetCost * sheets
    const cadCost =
      material.grainDirection && material.continuousGrain
        ? store.state.pricing.costs.cadPerSheetWithGrain.value
        : store.state.pricing.costs.cadPerSheet.value
    const cad = sheets * cadCost

    // Edge
    const machining = (perimeter / 1000) * store.state.pricing.costs.cutCostPerM.value
    const edgeSand = (perimeter / 1000) * store.state.pricing.costs.edgeSandPerM.value
    const edgeOsmo = material.grainDirection ? 0 : (perimeter / 1000) * store.state.pricing.costs.edgeOsmoPerM.value

    // Face
    const faceSand = material.grainDirection
      ? (area / 1000000) * store.state.pricing.costs.surfaceSandPerM2.value * 2
      : 0
    const faceLaquer =
      material.grainDirection && !material.unfinished
        ? (area / 1000000) * store.state.pricing.costs.lacquerPerM2.value * 2
        : 0

    // Pack & Crate
    const packCrate = items * store.state.pricing.costs.packCratePerItem.value

    // // 4mm Handles Cost of Goods
    // if (material.thickness === 4) {
    //   // for 4mm handles
    //   external = utils.toFixedNumber(sheetsCost, 2)
    //   internal = utils.toFixedNumber(cad + machining, 2)
    //   total = utils.toFixedNumber(
    //     internal * store.state.pricing.ukMultiplier.internalMarkup.value +
    //       external * store.state.pricing.ukMultiplier.externalMarkup.value,
    //     2
    //   )
    // }

    const external = utils.toFixedNumber(sheetsCost + faceLaquer, 2)
    const internal = utils.toFixedNumber(cad + machining + edgeSand + edgeOsmo + faceSand + packCrate, 2)
    const total = utils.toFixedNumber(
      internal * store.state.pricing.ukMultiplier.internalMarkup.value +
        external * store.state.pricing.ukMultiplier.externalMarkup.value,
      2
    )

    return {
      internal,
      external,
      total,
      profit: {
        value: utils.toFixedNumber(total - (internal + external), 2),
        percentile: utils.toFixedNumber(((internal + external) / total) * 100, 2)
      }
    }
  }

  static getHandle(value) {
    let h = null
    store.state.handles.find(handle => {
      if (handle.value === value) {
        h = handle
      }
    })
    return h
  }
}
