let makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

import Hinges from '@/js/cad/production/Hinges'
import HingesUs from '@/js/cad/production/HingesUs'

import GrabHandle from '@/js/cad/production/handles/GrabHandle'
import SemiRecessedGrab from '@/js/cad/production/handles/SemiRecessedGrab'
import CirclePull from '@/js/cad/production/handles/CirclePull'
import SemiRecessedCircle from '@/js/cad/production/handles/SemiRecessedCircle'
import EdgePullRear from '@/js/cad/production/handles/EdgePullRear'
import JProfile from '@/js/cad/production/handles/JProfile'
import Invisipull from '@/js/cad/production/handles/Invisipull'
import DPull from '@/js/cad/production/handles/DPull'
import HalfQuartArc from '@/js/cad/production/handles/HalfQuartArc'

export default class CornerFrontDoor {
  constructor(front, font, region) {
    this.front = front
    this.font = font
    this.region = region
    this.handle = front.handle

    this.frontCornerRadius = 2
    this.invisipullAdjust = 30
    this.jProfileAdjust = 26
    this.frontRadius = 2
    this.lacquerCrossSize = 25
    this.frontSpacing = region == 'uk' ? 13 : 11

    this.models = {}
    switch (region) {
      case 'uk':
        this.width = front.w === 600 ? 597 : parseInt(front.w)
        this.narrowFillerWidth = front.cabinet == 'w' ? 100 : 60
        this.wideFillerWidth = front.cabinet == 'w' ? 120 : 80
        this.height = front.h === 800 ? 797 : parseInt(front.h)
        break
      case 'us':
        this.width = parseInt(front.w)
        this.narrowFillerWidth = front.cabinet == 'w' ? 102 : 51
        this.wideFillerWidth = front.cabinet == 'w' ? 127 : 70
        this.height = parseInt(front.h)
        break
    }

    this.cornerFront()
    this.cornerFillerPairs()
  }

  cornerFront() {
    let handleType = this.front.handle.value

    // make any size adjustments
    let doorHeight = this.height
    let doorWidth = this.width

    if (handleType == 'invisipull') {
      doorHeight += this.invisipullAdjust
    } else if (handleType == 'j' && this.front.handle.orientation == 'h') {
      doorHeight -= this.jProfileAdjust
    } else if (handleType == 'j' && this.front.handle.orientation == 'v') {
      doorWidth -= this.jProfileAdjust
    }

    // front outline
    if (handleType == 'j') {
      this.models.outline = {
        paths: {
          line1: {
            type: 'line',
            origin: [0, 0],
            end: [doorWidth, 0]
          },
          line2: {
            type: 'line',
            origin: [doorWidth, 0],
            end: [doorWidth, doorHeight]
          },
          line3: {
            type: 'line',
            origin: [doorWidth, doorHeight],
            end: [0, doorHeight]
          },
          line4: {
            type: 'line',
            origin: [0, doorHeight],
            end: [0, 0]
          }
        }
      }

      if (this.front.handle.orientation == 'h') {
        if (this.front.cabinet == 'w') {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line3,
            this.models.outline.paths.line2,
            this.frontRadius
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line3,
            this.models.outline.paths.line4,
            this.frontRadius
          ) //
        } else {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line1,
            this.models.outline.paths.line2,
            this.frontRadius
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line1,
            this.models.outline.paths.line4,
            this.frontRadius
          )
        }
      }
      if (this.front.handle.orientation == 'v') {
        if (this.front.type === 'rhd') {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line2,
            this.models.outline.paths.line1,
            this.frontRadius
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line2,
            this.models.outline.paths.line3,
            this.frontRadius
          )
        } else {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line4,
            this.models.outline.paths.line1,
            this.frontRadius
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line4,
            this.models.outline.paths.line3,
            this.frontRadius
          )
        }
      }
    } else {
      this.models.outline = new makerjs.models.RoundRectangle(doorWidth, doorHeight, this.frontCornerRadius)
    }

    this.models.outline.layer = layerNames.cutout.label

    this.addHandle(doorWidth, doorHeight)

    if (this.front.type === 'lhd') {
      // move to make space for filler
      let xPos = this.narrowFillerWidth + this.wideFillerWidth + this.frontSpacing * 2
      for (const model in this.models) {
        makerjs.model.moveRelative(this.models[model], [xPos, 0])
      }
    }
  }

  addHandle(doorWidth, doorHeight) {
    let handleType = this.handle.value
    let handleWidth
    switch (handleType) {
      case 'semiGrab':
        handleWidth = 140
        break
      case 'midSemiGrab':
        handleWidth = 200
        break
      case 'lrgSemiGrab':
        handleWidth = 400
        break
      case 'lrgHalfArc':
        handleWidth = 105
        break
      case 'halfArc':
        handleWidth = 90
        break
      case 'lrgQuartArc':
        handleWidth = 52.5
        break
      case 'quartArc':
        handleWidth = 45
        break
    }

    let skipHandle = false

    switch (handleType) {
      case 'none':
        skipHandle = true
        break
      case 'grab':
        this.models.handle = new GrabHandle(this.models, this.region)
        break
      case 'semiGrab':
      case 'midSemiGrab':
      case 'lrgSemiGrab':
        this.models.handle = new SemiRecessedGrab(this.models, handleWidth, this.region)
        if (!this.hasGrain) {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth, doorHeight)
          this.models.boundingbox.layer = layerNames.boundingBox.label
        }
        break
      case 'lrgHalfArc':
      case 'halfArc':
      case 'lrgQuartArc':
      case 'quartArc':
        this.models.handle = new HalfQuartArc(this.models, handleType, handleWidth, this.region)
        break
      case 'circle':
        this.models.handle = new CirclePull(this.models)
        break
      case 'semiCircle':
      case 'semiCir':
        this.models.handle = new SemiRecessedCircle(this.models)
        break
      case 'edge-br-s':
      case 'edge-bl-s':
      case 'edge-w-s':
      case 'edge-sn-s':
      case 'edge-cp-s':
      case 'edge-br-s-r':
      case 'edge-bl-s-r':
      case 'edge-w-s-r':
      case 'edge-sn-s-r':
      case 'edge-cp-s-r':
        this.models.handle = new EdgePullRear(this.models, 76, doorWidth, doorHeight)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth, doorHeight)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'edge-br-l':
      case 'edge-bl-l':
      case 'edge-w-l':
      case 'edge-sn-l':
      case 'edge-cp-l':
      case 'edge-br-l-r':
      case 'edge-bl-l-r':
      case 'edge-w-l-r':
      case 'edge-sn-l-r':
      case 'edge-cp-l-r':
        this.models.handle = new EdgePullRear(this.models, 201, doorWidth, doorHeight)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth, doorHeight)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'j': {
        let size
        this.front.handle.orientation == 'v' ? (size = this.height) : (size = this.width)
        this.models.handle = new JProfile(this.models, size)
        if (this.front.handle.orientation == 'v' && this.front.type == 'rhd') {
          makerjs.model.move(this.models.outline, [26, 0])
        }
        if (this.front.handle.orientation == 'h' && this.front.cabinet == 'w') {
          makerjs.model.move(this.models.outline, [0, 26])
        }

        if (this.hasGrain && this.front.handle.orientation == 'v') {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2 + this.jProfileAdjust, doorHeight + 2)
        } else if (this.hasGrain && this.front.handle.orientation != 'v') {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2 + this.jProfileAdjust)
        } else if (!this.hasGrain) {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2 + this.jProfileAdjust)
          makerjs.model.move(this.models.boundingbox, [0, this.jProfileAdjust])
        }
        makerjs.model.move(this.models.boundingbox, [-1, -1])
        this.models.boundingbox.layer = layerNames.boundingBox.label

        break
      }
      case 'invisipull':
        this.models.handle = new Invisipull(this.models, this.width)
        makerjs.model.move(this.models.outline, [0, -this.invisipullAdjust])
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2)
        makerjs.model.move(this.models.boundingbox, [-1, -(this.invisipullAdjust + 1)])
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'invisipull-short':
        this.models.handle = new Invisipull(this.models, this.width, true)
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2)
        makerjs.model.move(this.models.boundingbox, [-1, -1])
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'dpull-br-s':
      case 'dpull-bl-s':
      case 'dpull-w-s':
        this.models.handle = new DPull(this.models, 128, doorWidth, doorHeight)
        break
      case 'dpull-br-l':
      case 'dpull-bl-l':
      case 'dpull-w-l':
        this.models.handle = new DPull(this.models, 192, doorWidth, doorHeight)
        break
      default:
        skipHandle = true
        console.log('handle type not implemented yet', handleType)
    }

    if (!skipHandle) {
      // adjust handle position

      // if (!this.models.handle.fixedRotation) this.rotateHandle()
      this.rotateHandle()
      this.moveHandle()

      // subtract any handle outline
      if (this.models.handleOutline) {
        makerjs.model.combineSubtraction(this.models.outline, this.models.handleOutline)
      }
    }

    if (
      !(
        // if not the following:
        (
          this.front.special === 'custom' ||
          this.front.type === 'blank' ||
          // joined+top drw door with w==197||297 needs to be empty hinge.
          (this.front.joiningPos === 'top' && this.front.type === 'drw' && (this.width === 197 || this.width === 297))
        )
      )
    ) {
      switch (this.front.type) {
        case 'drw':
          if (this.front.special === 'platsa') {
            this.models.drw = new PlatsaDrawerFixings(this.width, this.height)
          } else {
            this.models.drw =
              this.region === 'us'
                ? new DrawerHolesUs(this.width, this.height, this.front)
                : new DrawerHoles(this.width, this.height, this.front)
          }
          break
        default:
          this.models.hinges =
            this.region === 'us'
              ? new HingesUs(this.width, this.height, this.front)
              : new Hinges(this.width, this.height, this.front, this.count)
      }
    }
  }

  rotateHandle() {
    let hinge = this.front.type
    let cabinet = this.front.cabinet
    let position = this.front.handle.orientation
    let handleType = this.front.handle.value
    let rotation = 0

    // set the required rotation of the handle
    if (((position == 'h' || position == 'c') && cabinet == 'w') || position == 'updn') {
      rotation = 180
    }
    if ((position == 'h' || position == 'c') && cabinet == 'w') {
      if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
        hinge == 'lhd' ? (rotation = 270) : (rotation = 180)
      } else {
        rotation = 180
      }
    } else if (position.charAt(0) == 'v') {
      if (hinge == 'lhd') {
        rotation = 270
      } else if (hinge == 'rhd') {
        handleType == 'quartArc' || handleType == 'lrgQuartArc' ? (rotation = -90) : (rotation = 90)
      }
    } else if (
      position == 'h' &&
      cabinet == 'b' &&
      hinge == 'rhd' &&
      (handleType == 'quartArc' || handleType == 'lrgQuartArc')
    ) {
      rotation = 90
    }

    // rotate the handle and it's outline
    if (this.models.handleOutline) makerjs.model.rotate(this.models.handleOutline, rotation, [0, 0])
    makerjs.model.rotate(this.models.handle, rotation, [0, 0])
  }

  moveHandle() {
    let hinge = this.front.type
    let cabinet = this.front.cabinet
    let position = this.front.handle.orientation
    let handleType = this.front.handle.value
    let xPos = 0
    let yPos = 0

    // all VERTICAL handles
    if (position.charAt(0) == 'v' && hinge != 'drw') {
      xPos = hinge == 'lhd' ? this.width - this.models.handle.insetY : this.models.handle.insetY

      if (position == 'vc') {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          yPos = this.height / 2
        } else {
          yPos =
            hinge == 'lhd'
              ? this.height / 2 + this.models.handle.width / 2
              : this.height / 2 - this.models.handle.width / 2
        }
      } else if (position == 'vbt') {
        yPos =
          hinge == 'lhd'
            ? 797 - this.models.handle.insetX
            : 797 - (this.models.handle.width + this.models.handle.insetX)
      } else if (position == 'vwb') {
        yPos =
          hinge == 'lhd' ? 800 + this.models.handle.width + this.models.handle.insetX : 800 + this.models.handle.insetX
      } else if (cabinet == 'b') {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          yPos = this.height - this.models.handle.insetX - this.models.handle.width
        } else {
          yPos =
            hinge == 'lhd'
              ? this.height - this.models.handle.insetX
              : this.height - (this.models.handle.width + this.models.handle.insetX)
        }
      } else if (cabinet == 'w') {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          yPos = this.models.handle.insetX + this.models.handle.width
        } else {
          yPos = hinge == 'lhd' ? this.models.handle.width + this.models.handle.insetX : this.models.handle.insetX
        }
      }
    }

    // all HORIZONTAL handles
    // && !NOT drw, thd, bhd, blank, 197w grab/semigrab
    else if (
      position == 'h' &&
      hinge != 'drw' &&
      hinge != 'thd' &&
      hinge != 'bhd' &&
      !(this.width == 197 && (handleType == 'grab' || handleType == 'semiGrab'))
    ) {
      yPos = cabinet == 'b' ? this.height - this.models.handle.insetY : this.models.handle.insetY
      if (hinge == 'lhd') {
        // need to use switch case for large-halfArc and large-quartArc
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          xPos = this.width - this.models.handle.width - this.models.handle.insetX
          // yPos = cabinet == 'b' ? this.height - this.models.handle.insetY : this.models.handle.insetY
        } else if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
          xPos = this.width + this.models.handle.shadowOffset + 0.025 // 0.025 overlap tolerance
        } else {
          xPos =
            cabinet == 'b'
              ? this.width - (this.models.handle.width + this.models.handle.insetX)
              : this.width - this.models.handle.insetX
        }
      } else {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          xPos = this.models.handle.width + this.models.handle.insetX
        } else if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
          xPos = -this.models.handle.shadowOffset - 0.025 // 0.025 overlap tolerance
        } else {
          xPos = cabinet == 'b' ? this.models.handle.insetX : this.models.handle.width + this.models.handle.insetX
        }
      }
    }

    // all CENTRAL handles
    // && drw, thd, bhd, blank, 197w grab/semigrab
    else if (
      position == 'c' ||
      hinge == 'drw' ||
      (this.width == 197 && (handleType == 'grab' || handleType == 'semiGrab')) ||
      hinge == 'thd' ||
      hinge == 'bhd' ||
      hinge == 'lhd' ||
      hinge == 'rhd' ||
      hinge == 'blank'
    ) {
      if ((cabinet == 'b' || hinge == 'drw' || hinge == 'blank') && position != 'updn') {
        yPos = this.height - this.models.handle.insetY
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? (xPos = this.width / 2)
          : (xPos = this.width / 2 - this.models.handle.width / 2)
      } else {
        yPos = this.models.handle.insetY
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? (xPos = this.width / 2)
          : (xPos = this.width / 2 + this.models.handle.width / 2)
      }
    } else {
      console.warn('Front move not caught')
    }

    if (this.models.handleOutline) makerjs.model.move(this.models.handleOutline, [xPos, yPos])
    makerjs.model.move(this.models.handle, [xPos, yPos])
  }

  cornerFillerPairs() {
    let handleType = this.handle.value
    let cabinetType = this.front.cabinet

    // make narrow filler
    this.models.narrowFiller = {
      models: {
        outline: {},
        marking: {},
        snazzyText: {}
      }
    }
    this.models.narrowFiller.models.outline = new makerjs.models.RoundRectangle(
      this.narrowFillerWidth,
      handleType == 'invisipull' && cabinetType == 'w' ? this.height + this.invisipullAdjust : this.height,
      this.frontRadius
    )
    this.addNoLacquerMarkPairs(this.models.narrowFiller, this.narrowFillerWidth, this.height)
    this.addLabel(this.models.narrowFiller, this.narrowFillerWidth)
    this.models.narrowFiller.models.outline.layer = layerNames.cutout.label

    // make wide filler
    this.models.wideFiller = {
      models: {
        outline: {},
        marking: {},
        snazzyText: {}
      }
    }
    this.models.wideFiller.models.outline = new makerjs.models.RoundRectangle(
      this.wideFillerWidth,
      handleType == 'invisipull' && cabinetType == 'w' ? this.height + this.invisipullAdjust : this.height,
      this.frontRadius
    )
    this.addNoLacquerMarkPairs(this.models.wideFiller, this.wideFillerWidth, this.height)
    this.addLabel(this.models.wideFiller, this.wideFillerWidth)
    this.models.wideFiller.models.outline.layer = layerNames.cutout.label

    // need to move both fillers
    if (this.front.type === 'lhd') {
      makerjs.model.move(this.models.narrowFiller, [this.wideFillerWidth + this.frontSpacing, 0])
    } else {
      // rhd
      makerjs.model.move(this.models.narrowFiller, [this.width + this.frontSpacing, 0])
      makerjs.model.move(this.models.wideFiller, [this.width + this.narrowFillerWidth + this.frontSpacing * 2, 0])
    }
  }

  addNoLacquerMarkPairs(model, fillerWidth, fillerHeight) {
    model.models.marking = {
      paths: {
        topEdge: {
          type: 'line',
          origin: [-this.lacquerCrossSize / 2, -this.lacquerCrossSize / 2],
          end: [this.lacquerCrossSize / 2, this.lacquerCrossSize / 2]
        },
        bottomEdge: {
          type: 'line',
          origin: [-this.lacquerCrossSize / 2, this.lacquerCrossSize / 2],
          end: [this.lacquerCrossSize / 2, -this.lacquerCrossSize / 2]
        }
      }
    }
    makerjs.model.move(model.models.marking, [fillerWidth / 2, fillerHeight / 2])
    model.models.marking.layer = layerNames.cpEngrave.label
  }

  addLabel(model, fillerWidth) {
    let fillerLabel = 'FP' + this.front.itemNum.toString()
    let text = new makerjs.models.Text(this.font, fillerLabel, 8)
    model.models.snazzyText = makerjs.model.mirror(text, true, false)
    model.models.snazzyText.layer = layerNames.engrave.label

    let textMeasurements = makerjs.measure.modelExtents(model.models.snazzyText)
    makerjs.model.moveRelative(model.models.snazzyText, [-textMeasurements.center[0], -textMeasurements.center[1]])
    makerjs.model.moveRelative(model.models.snazzyText, [fillerWidth / 2, 20])
  }
}
