import store from '@/store'
import utils from '@/js/utils'

export default class Materials {
  static getProperties(material) {
    const type = store.state.materials?.find(type => {
      return type.value === material.material
    })

    if (!type) return null

    let typeColourIndex = type.colours?.findIndex(colour => colour.value === material.colourCode)

    type.colour = type.colours[typeColourIndex]

    return type
  }

  static getSheetCostFromProperties(type, w, h, thickness, sides) {
    // Strip 'jumbo' from legacy materials
    type = type.split('Jumbo')[0]
    type = type.split('New')[0]
    type = type === 'fenix' ? 'fenixNtm' : type

    // Swap width height of legacy materials
    if (h > w) {
      h = [w, (w = h)][0]
    }

    const materialType = store.state.materials.find(t => {
      return t.value === type
    })

    if (!materialType) {
      console.error('Material not found', type, w, h, thickness)
      return false
    }

    const size = materialType.sizes.find(size => {
      if (materialType.hasGrain) {
        return size.w === w && size.h === h && size.thickness === thickness
      } else {
        return size.w === w && size.h === h && size.thickness === thickness && size?.sides === sides
      }
    })

    if (!size) {
      return false
    }

    return utils.toFixedNumber(size.cost, 2)
  }

  static getSheetSizesForType(type) {
    // Strip 'jumbo' from legacy materials
    type = type.split('Jumbo')[0]
    type = type.split('New')[0]
    type = type === 'fenix' ? 'fenixNtm' : type

    const materialType = store.state.materials.find(t => {
      return t.value === type
    })

    return materialType
  }

  static getMaterialsByRegion(region) {
    return store.state.materials?.filter(m => {
      return m.regions.includes(region)
    })
  }

  static getWorktopsByRegion(region) {
    return store.state.worktops?.filter(w => {
      return w.region.includes(region)
    })
  }

  static hasGrain(value) {
    const material = store.state.materials.find(m => m.value === value)
    if (!material) {
      return null
    } else {
      return material.hasGrain
    }
  }

  static getNameFromValue(value) {
    const material = store.state.materials.find(m => m.value === value)
    return material.text
  }

  static getColoursFromValue(value) {
    if (!value) return []
    const material = store.state.materials.find(m => {
      return m.value === value
    })
    return material.colours
  }

  // static getWorktopColour(material) {
  //   if (!material) return []
  //   const colouredMaterials = store.state.materials?.filter(material => material.colours)
  //   const colours = colouredMaterials.map(colouredMaterial => colouredMaterial.colours)
  //   for (const materialSet of colours) {
  //     for (const colourInfo of materialSet) {
  //       if (colourInfo.value === material.colourCode) {
  //         return colourInfo
  //       }
  //     }
  //   }
  // }
}
