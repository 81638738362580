import paper from 'paper'
import utils from '@/js/utils'
import GrabHandle from '@/js/cad/customer/handles/GrabHandle'
import SemiRecessedGrab from '@/js/cad/customer/handles/SemiRecessedGrab'
import Circle from '@/js/cad/customer/handles/Circle'
import SemiRecessedCircle from '@/js/cad/customer/handles/SemiRecessedCircle'
import JProfile from '@/js/cad/customer/handles/JProfile'
import EdgePull from '@/js/cad/customer/handles/EdgePull'
import DPull from '@/js/cad/customer/handles/DPull'
import HalfQuartArc from '@/js/cad/customer/handles/HalfQuartArc'

export default class TwoPartCorner {
  constructor(name, data, region, grainDirection) {
    this.data = data
    this.region = region
    this.grainDirection = grainDirection

    this.cornerRadius = 5
    this.doorWidth = region === 'us' && data.w === 338 ? 338 : region === 'uk' && data.w === 250 ? 254 : data.w
    this.doorHeight = region === 'us' && data.h === 760 ? 760 : region === 'uk' && data.w === 800 ? 800 : data.h
    this.labelSize = 40
    this.labelPadding = 50

    this.createPaths()
    this.outline.name = 'outline'
    this.firstDimension = this.getDimension()
    this.secondDimension = this.getDimension()
    this.secondDimension.translate(this.doorWidth, 0)
    // this.dimension = new paper.Group([this.firstDimension, this.secondDimension])
    // this.dimension.name = 'dimension'
    this.firstDimension.name = 'dimension'
    this.secondDimension.name = 'dimension'
    // this.item = new paper.Group([this.outline, this.dimension, this.createHinge(), this.getLabel()])
    this.grain = this.getGrainDirection()
    let itemGroup = [this.outline, this.firstDimension, this.secondDimension, this.createHinge(), this.getLabel()]
    if (this.grain) {
      this.grain.name = 'grain'
      itemGroup.push(this.grain)
    }
    this.item = new paper.Group([...itemGroup])
    this.item.name = name
    this.item.data.originalBounds = this.item.bounds
  }

  createPaths() {
    var a = new paper.Path.Rectangle(
      new paper.Point(0, 0),
      new paper.Size(this.doorWidth, this.doorHeight),
      this.cornerRadius
    )
    var b = new paper.Path.Rectangle(
      new paper.Point(this.doorWidth, 0),
      new paper.Size(this.doorWidth, this.doorHeight),
      this.cornerRadius
    )

    a.strokeColor = utils.isColourDark(this.data.material.uiColour) ? 'white' : 'black'
    b.strokeColor = utils.isColourDark(this.data.material.uiColour) ? 'white' : 'black'
    a.fillColor = this.data.material.uiColour
    b.fillColor = this.data.material.uiColour

    let handleType = this.data.handle.value
    let insertMaterial = this.data.handle.insertMaterial
    let handleWidth
    switch (handleType) {
      case 'semiGrab':
        handleWidth = 140
        break
      case 'lrgHalfArc':
        handleWidth = 105
        break
      case 'halfArc':
        handleWidth = 90
        break
      case 'lrgQuartArc':
        handleWidth = 52.5
        break
      case 'quartArc':
        handleWidth = 45
        break
    }

    switch (handleType) {
      case 'none':
        // dp nothing innit
        break
      case 'grab':
        this.handle = new GrabHandle()
        break
      case 'semiGrab':
        this.handle = new SemiRecessedGrab(insertMaterial, handleWidth)
        break
      case 'lrgHalfArc':
      case 'halfArc':
      case 'lrgQuartArc':
      case 'quartArc':
        this.handle = new HalfQuartArc(insertMaterial, handleWidth, handleType)
        break
      case 'circle':
        this.handle = new Circle()
        break
      case 'semiCir':
        this.handle = new SemiRecessedCircle(insertMaterial)
        break
      case 'j':
        this.handle = new JProfile(insertMaterial, this.doorWidth, this.cornerRadius, true)
        this.handleClone = new JProfile(insertMaterial, this.doorWidth, this.cornerRadius, true)
        break
      case 'edge-br-s':
      case 'edge-br-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#D8C384')
        break
      case 'edge-br-l':
      case 'edge-br-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#D8C384')
        break
      case 'edge-bl-s':
      case 'edge-bl-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#000000')
        break
      case 'edge-bl-l':
      case 'edge-bl-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#000000')
        break
      case 'edge-w-s':
      case 'edge-w-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#ffffff')
        break
      case 'edge-w-l':
      case 'edge-w-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#ffffff')
        break
      case 'edge-sn-s':
      case 'edge-sn-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#e0e0e0')
        break
      case 'edge-sn-l':
      case 'edge-sn-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#e0e0e0')
        break
      case 'dpull-br':
      case 'dpull-br-s':
        this.handle = new DPull(this.data.w, this.data.h, 137, '#D8C384')
        break
      case 'dpull-br-l':
        this.handle = new DPull(this.data.w, this.data.h, 201, '#D8C384')
        break
      case 'dpull-bl-s':
        this.handle = new DPull(this.data.w, this.data.h, 137, '#000000')
        break
      case 'dpull-bl-l':
        this.handle = new DPull(this.data.w, this.data.h, 201, '#000000')
        break
      case 'dpull-w-s':
        this.handle = new DPull(this.data.w, this.data.h, 137, '#ffffff')
        break
      case 'dpull-w-l':
        this.handle = new DPull(this.data.w, this.data.h, 201, '#ffffff')
        break
      default:
        console.log('Handle type not implemented in Customer CAD', handleType)
    }
    if (this.handle) {
      this.positionHandle()
    }
    let handlePath

    if (this.handle && handleType == 'grab') {
      var f
      if (this.data.type == 'rhd') {
        f = a.subtract(this.handle.path)
        a.remove()
        this.outline = new paper.Group([f, b])
      } else {
        f = b.subtract(this.handle.path)
        b.remove()
        this.outline = new paper.Group([f, a])
      }
      this.handle.path.remove()
      this.outline.fillColor = this.data.material.uiColour
      this.outline.strokeColor = 'black'
    } else if (this.handle && handleType == 'j') {
      handlePath = this.handle ? this.handle.path : null
      this.outline = new paper.Group([a, b, handlePath, this.handleClone.path])
    } else {
      handlePath = this.handle ? this.handle.path : null
      this.outline = new paper.Group([a, b, handlePath])
    }
  }

  positionHandle() {
    let xPos, yPos
    let orientation = this.data.handle.orientation
    let cabinet = this.data.cabinet
    let frontType = this.data.type
    let handleType = this.data.handle.value
    let path = this.handle.path

    let rotation

    if (['v', 'vc', 'vbt'].includes(orientation)) {
      // rotate
      rotation = frontType == 'lhd' ? 90 : 270
      path.rotate(rotation)
      xPos = frontType == 'lhd' ? this.doorWidth * 2 - this.handle.height / 2 : this.handle.height / 2
      if (orientation == 'vc') {
        yPos = this.data.h / 2
        // } else if (orientation == 'vbt') {
        //   yPos = this.data.h - 800 + this.handle.width / 2 + this.handle.insetX
      } else {
        yPos =
          cabinet == 'b'
            ? this.handle.width / 2 + this.handle.insetX
            : this.data.h - this.handle.width / 2 - this.handle.insetX
      }
      if (handleType.split('-').includes('dpull')) {
        xPos = frontType == 'lhd' ? this.doorWidth * 2 - 50 : 50
      }
      if ((handleType == 'halfArc' || handleType == 'lrgHalfArc') && orientation == 'v') {
        yPos = this.handle.width + this.handle.insetX
      }
    } else if (orientation == 'h') {
      // if (cabinet == 'w') path.rotate(180)
      xPos =
        frontType == 'lhd'
          ? this.doorWidth * 2 - this.handle.width / 2 - this.handle.insetX
          : this.handle.width / 2 + this.handle.insetX
      yPos =
        // cabinet == 'w'
        //   ? this.data.h - this.handle.height / 2 - this.handle.insetY
        this.handle.height / 2 + this.handle.insetY

      if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
        if (frontType == 'lhd') {
          rotation = 90
          xPos = this.doorWidth * 2 - this.handle.width / 2 - this.handle.insetY
        } else {
          rotation = 0
          xPos = this.handle.width / 2 + this.handle.insetY
        }
        path.rotate(rotation)
      } else if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
        // horizontal half circle is the same as centered
        xPos = frontType == 'lhd' ? this.doorWidth * 1.5 : this.doorWidth * 0.5
      }

      if (handleType == 'j') xPos = this.doorWidth / 2 + this.doorWidth + 25
    } else if (orientation == 'c') {
      if (cabinet == 'w') path.rotate(180)
      xPos = frontType == 'lhd' ? this.doorWidth * 1.5 : this.doorWidth * 0.5
      yPos =
        cabinet == 'w'
          ? this.data.h - this.handle.height / 2 - this.handle.insetY
          : this.handle.height / 2 + this.handle.insetY
    }
    path.position = new paper.Point(xPos, yPos)
  }

  createHinge() {
    var size = 150
    var s = new paper.Point(-size / 2, -size / 2)
    var m = new paper.Point(-30, 30)
    var e = new paper.Point(size / 2, size / 2)

    var vector = e.subtract(s).normalize(30)
    var arrow = new paper.Group([
      new paper.Path.Arc(s, m, e),
      new paper.Path([
        e.add(vector.rotate(100)),
        e,
        e.add(vector.rotate(185)) //top
      ])
    ])
    arrow.strokeWidth = 1

    arrow.strokeColor = utils.isColourDark(this.data.material.uiColour) ? 'white' : 'black'
    arrow.selectedColor = 'transparent'

    switch (this.data.type) {
      case 'lhd':
        arrow.scale(-1, 1)
        arrow.translate(new paper.Point(this.doorWidth * 2 - size / 2, this.data.h / 2))
        break
      case 'rhd':
        arrow.translate(new paper.Point(size / 2, this.data.h / 2))
        break
    }
    return arrow
  }

  getLabel() {
    var text = new paper.PointText(
      new paper.Point(this.doorWidth / 2, this.doorHeight + this.labelSize + this.labelPadding)
    )
    text.justification = 'center'
    text.fillColor = 'black'
    text.selectedColor = 'transparent'
    text.fontSize = this.labelSize
    text.fontFamily = 'Roboto Mono'
    text.content = this.data.itemNum.toString().toUpperCase()
    return text
  }

  getDimension() {
    let width, height
    this.region == 'us' ? (width = Math.round((this.doorWidth / 25.4) * 100) / 100) : (width = this.doorWidth)
    this.region == 'us' ? (height = Math.round((this.doorHeight / 25.4) * 100) / 100) : (height = this.doorHeight)
    let dimension = new paper.PointText(new paper.Point(this.doorWidth / 2, this.doorHeight / 2))
    dimension.justification = 'center'
    dimension.fillColor = 'transparent'
    dimension.selectedColor = 'transparent'
    dimension.fontSize = this.labelSize
    dimension.fontFamily = 'Roboto Mono'
    dimension.content = `W${width.toString().toUpperCase()} x H${height.toString().toUpperCase()}`
    if (this.doorHeight >= this.doorWidth) {
      dimension.rotate(-90)
      // dimension.translate(dimension.bounds.width / 4, 0)
    } else {
      dimension.translate(0, dimension.bounds.height / 2)
    }
    return dimension
  }

  getGrainDirection() {
    if (this.data.material.hasGrain && this.grainDirection) {
      const grainIconPath =
        'M36.875,74.49023a3.99582,3.99582,0,0,1,.626-5.61328A77.36856,77.36856,0,0,1,65.02979,56.11914c15.38134-3.84375,39.17675-4.78906,65.189,12.55274,18.82227,12.54785,38.5,16.42773,58.48682,11.52734a70.77906,70.77906,0,0,0,24.80175-11.32715,3.99975,3.99975,0,0,1,4.9917,6.251,77.36856,77.36856,0,0,1-27.52881,12.75781,77.24806,77.24806,0,0,1-18.71533,2.31152c-13.38574.001-29.4541-3.51758-46.47363-14.86426C106.959,62.7793,87.28076,58.89941,67.29443,63.80078A70.77906,70.77906,0,0,0,42.49268,75.12793,4.00386,4.00386,0,0,1,36.875,74.49023Z' +
        'm176.63232,50.38184a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.02051-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,124.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90234,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,131.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z' +
        'm0,56a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.01953-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,180.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90332,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,187.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z'

      const makeGrainIcon = (posX, posY, deg) => {
        const grainIcon = new paper.CompoundPath(grainIconPath)
        grainIcon.scale(0.2)
        grainIcon.rotate(deg)
        grainIcon.position = new paper.Point(posX, posY)
        grainIcon.strokeCap = 'round'
        // grainIcon.strokeWidth = 2
        grainIcon.fillColor = 'transparent'
        return grainIcon
      }

      let grainInfo = []
      if (Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        grainInfo.push(makeGrainIcon(70, 70, -90))
        grainInfo.push(makeGrainIcon(this.doorWidth * 2 - 70, 70, -90))
      } else {
        grainInfo.push(makeGrainIcon(70, 70, 0))
        grainInfo.push(makeGrainIcon(this.doorWidth * 2 - 70, 70, 0))
      }
      return new paper.Group(...grainInfo)
    } else {
      return null
    }
  }
}
