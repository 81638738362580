import paper from 'paper'

export default class DPull {
  constructor(width, height, length, colour) {
    this.path
    this.width = length
    this.height = 10
    this.insetX = 45
    this.insetY = 45
    this.colour = colour

    this.draw()
  }

  draw() {
    this.path = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(this.width, this.height))
    this.path.fillColor = this.colour
    this.path.strokeColor = 'black'
  }
}
