var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"cockups"}},[(_vm.loading)?_c('v-overlay',{staticClass:"text-center",attrs:{"color":"primary","opacity":0.9}},[_c('v-progress-circular',{attrs:{"indeterminate":""}}),_c('p',{staticClass:"mt-5"},[_vm._v("Just crunching some numbers... can take a little while...")])],1):_vm._e(),_c('div',{attrs:{"id":"view-sub-nav"}},[_c('DateRangePicker',{staticClass:"align-self-start",attrs:{"empty":true},on:{"change":_vm.dateRangeChanged}}),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.addDialog = true}}},[_vm._v("Add Recut")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center offset-2",attrs:{"cols":"3"}},[_c('div',{staticClass:"text-h5\tmb-6"},[_vm._v("Qty")]),_c('canvas',{ref:"pieByCause"})]),_c('v-col',{staticClass:"text-center offset-2",attrs:{"cols":"3"}},[_c('div',{staticClass:"text-h5\tmb-6"},[_vm._v("Cost")]),_c('canvas',{ref:"pieByCauseCost"})])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.recuts,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$moment.unix(item.date.seconds).format('Do MMM YYYY')))]}},{key:"item.cause",fn:function(ref){
var item = ref.item;
return _vm._l((item.cause),function(cause){return _c('v-chip',{key:cause},[_vm._v(_vm._s(_vm.cockupSettings.types.find(function (o) { return o.id === cause; }).name))])})}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.value ? _vm.$numeral(item.value).format('$0,0.00') : null))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.quoteID)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewQuote(item)}}},on),[_vm._v("mdi-clipboard-text")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Original Quote")])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('RecutTracker',{on:{"cancel":function($event){_vm.addDialog = false},"complete":function($event){_vm.addDialog = false}}})],1),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.quoteDialog),callback:function ($$v) {_vm.quoteDialog=$$v},expression:"quoteDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"no-print elevation-0",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Quote")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function () {
              _vm.quoteDialog = false
              this$1.selectedRecut = null
            }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.selectedRecut)?_c('QuoteEdit',{attrs:{"quoteID":_vm.selectedRecut.quoteID,"versionID":_vm.selectedRecut.versionID}}):_vm._e(),_c('v-card-text')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }