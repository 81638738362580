var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-overlay',{staticClass:"text-center",attrs:{"color":"primary","opacity":0.9}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('div',[_c('div',{attrs:{"id":"view-sub-nav"}},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":this.selected.length == 0,"depressed":"","outlined":"","color":"primary"},on:{"click":_vm.generateSalesTax}},[_vm._v("Generate Missing Sales Tax Data")]),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":this.selected.length == 0,"depressed":"","outlined":"","color":"primary"},on:{"click":_vm.bulkInvoice}},[_vm._v("Generate Invoice"+_vm._s(this.selected.length > 1 ? 's' : ''))]),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":this.selected.length !== 1,"depressed":"","outlined":"","color":"primary"},on:{"click":function () {
            _vm.addInvoiceDialog = true
            _vm.invoice = {}
          }}},[_vm._v("Add Missing Invoice Data")])],1),_c('v-data-table',{attrs:{"items":_vm.missingInvoice,"headers":_vm.headers,"show-select":""},scopedSlots:_vm._u([{key:"item.orderNumber",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().orderNumber || '-')+" ")]}},{key:"item.invoiceNumber",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().xeroInvoiceNumber || '-')+" ")]}},{key:"item.orderType",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().type)+" ")]}},{key:"item.customerEmail",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().customerContactDetails.email)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.addInvoiceDialog),callback:function ($$v) {_vm.addInvoiceDialog=$$v},expression:"addInvoiceDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Invoice Data")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Xero Invoice ID"},model:{value:(_vm.invoice.id),callback:function ($$v) {_vm.$set(_vm.invoice, "id", $$v)},expression:"invoice.id"}}),_c('v-text-field',{attrs:{"label":"Xero Invoice Number"},model:{value:(_vm.invoice.number),callback:function ($$v) {_vm.$set(_vm.invoice, "number", $$v)},expression:"invoice.number"}}),_c('v-text-field',{attrs:{"label":"Xero Invoice URL"},model:{value:(_vm.invoice.url),callback:function ($$v) {_vm.$set(_vm.invoice, "url", $$v)},expression:"invoice.url"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.addInvoiceData}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }