<template>
  <canvas ref="myChart" width="800" height="400"></canvas>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'line-chart',
  props: ['type', 'chartdata', 'options'],
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.chart = new Chart(this.$refs['myChart'], {
      type: 'line',
      data: this.chartdata,
      options: this.options
    })
  },
  watch: {
    chartdata: {
      handler() {
        this.chart.update()
      },
      deep: true
    }
  }
}
</script>
