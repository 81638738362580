import paper from 'paper'

export default class Circle {
  constructor() {
    this.path
    this.width = 35
    this.height = 35
    this.insetX = 30
    this.insetY = 25

    this.draw()
  }

  draw() {
    this.path = new paper.Path.Circle(new paper.Point(this.width / 2, this.height / 2), this.width / 2)
    this.path.fillColor = 'white'
    this.path.strokeColor = 'black'
  }
}
