<template>
  <div>
    <div id="view-sub-nav">
      <v-spacer />
      <v-btn depressed outlined color="primary" @click="updatePricing"
        ><span v-if="!pricingSubmitted">Save</span>
        <v-progress-circular v-else :size="20" :width="2" indeterminate></v-progress-circular
      ></v-btn>
    </div>
    <v-container fluid>
      <v-row wrap v-if="pricing" class="pt-4">
        <v-col cols="12" md="4" class="py-0">
          <h4>UK Multipliers</h4>
        </v-col>
        <v-col cols="12" md="8">
          <v-row wrap>
            <v-col cols="4" v-for="(a, index) in sortedObject(pricing.ukMultiplier)" class="py-0" md="6" :key="index">
              <v-text-field
                v-model.number="pricing.ukMultiplier[a[0]].value"
                :label="a[1].label"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-8">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <h4>US Multipliers</h4>
        </v-col>
        <v-col cols="12" md="8">
          <v-row wrap>
            <v-col cols="4" v-for="(a, index) in sortedObject(pricing.usMultiplier)" class="py-0" md="6" :key="index">
              <v-text-field
                v-model.number="pricing.usMultiplier[a[0]].value"
                :label="a[1].label"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-8">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <h4>UK Sheet Processing Costs</h4>
        </v-col>
        <v-col cols="12" md="8">
          <v-row wrap>
            <v-col v-for="(a, index) in sortedObject(pricing.costs)" class="py-0" cols="12" md="6" :key="index">
              <v-text-field
                v-model.number="pricing.costs[a[0]].value"
                prefix="£"
                :label="a[1].label"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-8">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <h4>UK Worktop Processing Costs</h4>
        </v-col>
        <v-col cols="12" md="8">
          <v-row wrap>
            <v-col
              v-for="(a, index) in sortedObject(pricing.ukWorktopCosts)"
              class="py-0"
              cols="12"
              md="6"
              :key="index"
            >
              <v-text-field
                v-model.number="pricing.ukWorktopCosts[a[0]].value"
                :prefix="a[0] == 'ukWorktopMultiplier' ? 'x' : '£'"
                :label="a[1].label"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-8">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <h4>US Worktop Processing Costs</h4>
        </v-col>
        <v-col cols="12" md="8">
          <v-row wrap>
            <v-col
              v-for="(a, index) in sortedObject(pricing.usWorktopCosts)"
              class="py-0"
              cols="12"
              md="6"
              :key="index"
            >
              <v-text-field
                v-model.number="pricing.usWorktopCosts[a[0]].value"
                :prefix="a[0] == 'usWorktopMultiplier' ? 'x' : '$'"
                :label="a[1].label"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-8">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <h4>US Lucid Costs</h4>
        </v-col>
        <v-col cols="12" md="8">
          <v-row wrap>
            <v-col v-for="(a, index) in sortedObject(pricing.usCosts)" class="py-0" cols="12" md="6" :key="index">
              <v-text-field
                v-model.number="pricing.usCosts[a[0]].value"
                prefix="$"
                :label="a[1].label"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PricingSettings',
  data() {
    return {
      regionUs: false,
      pricing: null,
      pricingRef: null,
      pricingSubmitted: false
    }
  },
  async created() {
    const pricingSnap = await this.$db
      .collection('settings')
      .doc('pricing')
      .get()
    this.pricing = pricingSnap.data()
    this.pricingRef = pricingSnap.ref
  },
  methods: {
    async updatePricing() {
      if (!this.pricingSubmitted) {
        this.pricingSubmitted = true
        await this.pricingRef.update(this.pricing)
        this.pricingSubmitted = false
        this.$store.commit('openSnackbar', { snackbarText: 'Pricing Updated' })
      }
    },
    sortedObject(o) {
      return Object.entries(o).sort()
    }
  }
}
</script>
