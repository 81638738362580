import utils from '@/js/utils'

const laminateEdgeMuFactor = 2.46
const veneerEdgeMuFactor = 1
const veneerSingleSurfaceMuFactor = 1.075 //half of double surface = 2.15 / 2
const singleMuPerimeter = 1000
const singleMuArea = 1000000 // single-sided
const assemblyAndPackMuFactorUrtil = 27.5

export default {
  get(urtils, materials, taskType) {
    let mu = 0
    if (!urtils) return mu

    materials.forEach(material => {
      if (!material.value || !material.thickness || !material.w || !material.h) return

      let perimeter = 0
      let surface = 0

      for (const urtil of urtils) {
        if (
          !urtil.type ||
          !urtil.carcass ||
          (urtil.type == 's' && !urtil.material1) ||
          (urtil.type == 't' && (!urtil.material1 || !urtil.material2))
        ) {
          continue
        }

        if (taskType == 'finish') {
          // Carcass
          if (material.id === urtil.carcass) {
            perimeter += 2 * (urtil.w + urtil.h) + urtil.d * 4
            if (material.hasGrain) {
              surface += (2 * urtil.d * urtil.h + 2 * urtil.d * urtil.w) * 2 // inside and out for 2 sides and 2 bases
            }

            // shelves
            if (urtil.shelving) {
              const shelfPerimeter = (urtil.w / urtil.compartments + urtil.d) * 2
              const numShelves = urtil.shelvingOptions.qty
              perimeter += shelfPerimeter * numShelves
              if (material.hasGrain) {
                surface += urtil.d * (urtil.w / urtil.compartments) * 2 * numShelves
              }
            }

            // fins
            if (urtil.compartments > 1) {
              const finPerimeter = urtil.h
              const numFins = urtil.compartments - 1
              perimeter += finPerimeter * numFins
              if (material.hasGrain) {
                surface += urtil.d * urtil.h * 2 * numFins
              }
            }
          }

          // Back
          if (material.id === urtil.back && material.hasGrain) {
            surface += urtil.w * urtil.h
          }

          // Doors
          if (urtil.type !== 'o' && urtil.material1 && material.id === urtil.material1) {
            perimeter += urtil.w / 2 + urtil.h
            if (material.hasGrain) {
              surface += (urtil.w / 2) * urtil.h * 2
            }
          }
          if (urtil.type === 't' && urtil.material2 && material.id === urtil.material2) {
            perimeter += urtil.w / 2 + urtil.h
            if (material.hasGrain) {
              surface += (urtil.w / 2) * urtil.h * 2
            }
          }

          // mu total per urtil
          const edgeMu = material.hasGrain
            ? (perimeter / singleMuPerimeter) * veneerEdgeMuFactor
            : (perimeter / singleMuPerimeter) * laminateEdgeMuFactor
          const surfaceMu = material.hasGrain ? (surface / singleMuArea) * veneerSingleSurfaceMuFactor : 0
          mu += edgeMu + surfaceMu
        }
      }
    })

    // calculating assembly mu in terms of per urtil
    for (const urtil of urtils) {
      if (taskType == 'assemble') {
        let assemblyMu = assemblyAndPackMuFactorUrtil
        if (urtil.preAssembled && !urtil.trimming) assemblyMu *= 0.7
        if (!urtil.preAssembled && urtil.trimming) assemblyMu *= 0.3
        mu += assemblyMu
      }
    }

    // console.log(mu)
    return utils.toFixedNumber(mu, 2)
  }
}
