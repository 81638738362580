import { render, staticRenderFns } from "./CustomerQuote.vue?vue&type=template&id=07a1ecfe&scoped=true&"
import script from "./CustomerQuote.vue?vue&type=script&lang=js&"
export * from "./CustomerQuote.vue?vue&type=script&lang=js&"
import style0 from "./CustomerQuote.vue?vue&type=style&index=0&id=07a1ecfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a1ecfe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAlert,VBtn,VIcon,VOverlay,VProgressCircular,VSelect,VSpacer,VTab,VTabs})
