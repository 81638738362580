<template>
  <div id="shipping-settings">
    <div id="view-sub-nav">
      <v-btn
        depressed
        outlined
        color="primary"
        @click="
          () => {
            dialogMode = 'Create'
            selectedItem = newItemForm
            itemDialog = true
          }
        "
        >Create New Shipping Zone</v-btn
      >
      <v-spacer />
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      v-if="shippingZones"
      :items="shippingZones"
      :loading="loading"
      :headers="headers"
      sort-by="name"
      :items-per-page="-1"
      :search="tableSearch"
      id="materials-table"
    >
      <template v-slot:item.actions="{ item }">
        <td class="d-flex justify-end align-center">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                @click="
                  () => {
                    dialogMode = 'Edit'
                    itemDialog = true
                    selectedItem = item
                  }
                "
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Edit Shipping Zone</span>
          </v-tooltip>
          <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                @click="
                  () => {
                    deleteDialog = true
                    selectedItem = item
                  }
                "
                v-on="on"
                >mdi-delete</v-icon
              >
            </template>
            <span>Delete Shipping Zone</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>

    <!-- CREATE -->
    <v-dialog v-model="itemDialog" width="800">
      <v-card>
        <v-card-title class="primary white--text title">
          {{ dialogMode == 'Create' ? 'Create New Shipping Zone' : 'Edit Shipping Zone' }}
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field label="Name" v-model="selectedItem.name"></v-text-field>
            <v-select v-model="selectedItem.regions" :items="regions" multiple label="Region"></v-select>
            <v-text-field label="Cost" v-model.number="selectedItem.cost"></v-text-field>
            <div v-if="selectedItem.regions && selectedItem.regions.includes('eu') && selectedItem.cost > 0">
              <v-text-field label="Insurance" v-model.number="selectedItem.insurance"></v-text-field>
              <v-text-field label="Customs Clearance" v-model.number="selectedItem.customsClearance"></v-text-field>
              <v-text-field label="Import Vat" v-model.number="selectedItem.importVat"></v-text-field>
              <v-text-field label="Transit Time" v-model="selectedItem.transitTime"></v-text-field>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                itemDialog = false
                resetNewItemValues()
              }
            "
            >Cancel</v-btn
          >
          <v-btn
            color="accent"
            depressed
            dark
            @click="dialogMode === 'Create' ? createNewItem() : updateItem()"
            :disabled="submitting"
            >{{ dialogMode === 'Create' ? 'Create' : 'Update' }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Delete Shipping Zone</v-card-title>
        <v-card-text class="pt-6">
          Are you sure you want to delete this shipping zone?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="deleteItem(selectedItem)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ShippingSettings',
  data() {
    return {
      loading: true,
      data: null,
      tableSearch: null,
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Region',
          sortable: false,
          value: 'regions'
        },
        {
          text: 'Customs Clearance',
          sortable: false,
          value: 'customsClearance'
        },
        {
          text: 'Insurance',
          sortable: false,
          value: 'insurance'
        },
        {
          text: 'Import VAT',
          sortable: false,
          value: 'importVat'
        },
        {
          text: 'Cost',
          sortable: false,
          value: 'cost'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ],
      deleteDialog: false,
      itemDialog: false,
      submitting: false,
      selectedItem: {},
      dialogMode: null,
      regions: [
        { text: 'UK', value: 'uk' },
        { text: 'EU', value: 'eu' },
        { text: 'US', value: 'us' }
      ],
      newItemForm: {},
      shippingZones: null
    }
  },
  async mounted() {
    this.resetNewItemValues()
    await this.$bind('shippingZones', this.$db.collection('shippingZones')).then(() => {
      this.loading = false
    })
  },
  methods: {
    resetNewItemValues() {
      this.newItemForm = {
        name: null,
        regions: [],
        cost: null,
        insurance: null,
        customsClearance: null,
        importVat: null,
        transitTime: null
      }
    },
    async createNewItem() {
      this.submitting = true

      if (!this.newItemForm.hasGrain) {
        this.newItemForm.colours = []
      }

      const newDocRef = this.$db.collection('shippingZones').doc()
      await newDocRef.set(this.newItemForm)

      this.resetNewItemValues()
      this.submitting = false
      this.itemDialog = false
    },
    async updateItem() {
      this.submitting = true
      await this.$db
        .collection('shippingZones')
        .doc(this.selectedItem.id)
        .set(this.selectedItem)

      this.submitting = false
      this.itemDialog = false
      this.$store.commit('openSnackbar', {
        snackbarText: 'Shipping Zone Updated'
      })
    },
    deleteItem(item) {
      this.deleteDialog = false
      this.$db
        .collection('shippingZones')
        .doc(item.id)
        .delete()
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Shipping Zone Deleted'
          })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.colour {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border: solid 1px #999;
  border-radius: 50%;
  margin-right: 5px;
}

#material-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  #materials-table {
    flex: 1;
    position: relative;
  }
}

::v-deep .v-dialog__content {
  position: absolute;
  .v-dialog {
    padding-left: 15px;
    position: absolute;
    left: auto;
    right: 0;
    width: 50%;
    box-shadow: none;
    &:before {
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      width: 15px;
      box-shadow: -15px 0 15px -15px inset rgb(0 0 0 / 15%);
      left: 0;
      z-index: 1;
    }
    // box-shadow: -5px 0px 15px 0px rgb(0 0 0 / 15%)
  }
}
</style>
