const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class AdjustableShelves {
  constructor(urtil, materialThickness, finDepth, adjShelfInfo, trimInfo, engravingInfo) {
    this.models = { shelves: { models: {} } }
    // VARS
    this.urtil = urtil
    this.materialThickness = materialThickness
    this.finDepth = finDepth

    this.shelfAdjustableShelfInset = adjShelfInfo.shelfInset
    this.shelfAdjustablePinInset = adjShelfInfo.pinInset
    this.shelfAdjustablePinRadius = adjShelfInfo.pinRadius

    this.trimmingTolerance = trimInfo.tolerance
    this.engravingInfo = engravingInfo

    //CALCS
    this.internalWidth = this.urtil.w - this.materialThickness * 2
    this.numOfDividers = this.urtil.compartments - 1

    this.createAdjustableShelves()
  }

  createAdjustableShelves() {
    let shelfWidth =
      this.urtil.compartments > 1
        ? (this.internalWidth - this.numOfDividers * this.materialThickness) / this.urtil.compartments
        : this.internalWidth
    shelfWidth -= this.shelfAdjustableShelfInset * 2
    if (this.urtil.trimming) {
      shelfWidth += this.trimmingTolerance * 2
    }
    const shelfDepth = this.finDepth - this.shelfAdjustableShelfInset * 2

    for (let i = 0; i < this.urtil.shelvingOptions.qty; i++) {
      let shelf = (this.models.shelves.models['shelf-a-' + i] = {
        models: {
          outline: new makerjs.models.Rectangle(shelfWidth, shelfDepth)
        }
      })
      shelf.layer = layerNames.cutout.label

      if (!this.urtil.trimming) {
        shelf.models.holeA = new makerjs.models.Ellipse(this.shelfAdjustablePinRadius, this.shelfAdjustablePinRadius)
        shelf.models.holeA.layer = layerNames.shelfDrill.label

        shelf.models.holeB = makerjs.model.clone(shelf.models.holeA)
        shelf.models.holeC = makerjs.model.clone(shelf.models.holeA)
        shelf.models.holeD = makerjs.model.clone(shelf.models.holeA)

        makerjs.model.move(shelf.models.holeA, [
          10 - this.shelfAdjustableShelfInset,
          this.shelfAdjustablePinInset - this.shelfAdjustableShelfInset
        ])
        makerjs.model.move(shelf.models.holeB, [
          shelfWidth - (10 - this.shelfAdjustableShelfInset),
          this.shelfAdjustablePinInset - this.shelfAdjustableShelfInset
        ])
        makerjs.model.move(shelf.models.holeC, [
          10 - this.shelfAdjustableShelfInset,
          shelfDepth - this.shelfAdjustablePinInset + this.shelfAdjustableShelfInset
        ])
        makerjs.model.move(shelf.models.holeD, [
          shelfWidth - (10 - this.shelfAdjustableShelfInset),
          shelfDepth - this.shelfAdjustablePinInset + this.shelfAdjustableShelfInset
        ])
      }

      // add Urtil Label in Notes layer
      let shelfLabel = new makerjs.models.Text(this.engravingInfo.font, this.engravingInfo.label, 50)
      shelf.models.shelfLabelText = makerjs.model.mirror(shelfLabel, true, false)
      shelf.models.shelfLabelText.layer = layerNames.warning.label

      let textMeasurements = makerjs.measure.modelExtents(shelf.models.shelfLabelText)
      makerjs.model.moveRelative(shelf.models.shelfLabelText, [
        shelfWidth / 2 - textMeasurements.center[0],
        shelfDepth / 2 - textMeasurements.center[1]
      ])

      // two rows per column for shelves - shifted to the left.
      if (i === 0) {
        makerjs.model.move(shelf, [this.urtil.h + 50 + (50 + this.urtil.w), this.urtil.d + 50])
      } else if (i === 1) {
        makerjs.model.move(shelf, [this.urtil.h + 50 + (50 + this.urtil.w), 0])
      } else if (i % 2 === 0) {
        makerjs.model.move(shelf, [
          this.urtil.h + 50 + (50 + this.urtil.w) + (50 + shelfWidth) * ((i + 2) / 2 - 1),
          this.urtil.d + 50
        ])
      } else {
        makerjs.model.move(shelf, [this.urtil.h + 50 + (50 + this.urtil.w) + (50 + shelfWidth) * ((i + 1) / 2 - 1), 0])
      }
    }

    // ADJUST FOR COMPARTMENTS
    // turn number into alphabet
    for (let i = 0; i < this.urtil.shelvingOptions.qty; i++) {
      let shelf = this.models.shelves.models['shelf-a-' + i]
      for (let j = 0; j < this.numOfDividers; j++) {
        let cloneID = String.fromCharCode(97 + (j + 2))
        let clone = (this.models.shelves.models['shelf-' + cloneID + '-' + i] = makerjs.model.clone(shelf))
        let spacingUnit = Math.ceil(this.urtil.shelvingOptions.qty / 2)
        makerjs.model.moveRelative(clone, [(shelfWidth + 50) * spacingUnit * (j + 1), 0])
      }
    }
  }
}
