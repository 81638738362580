<template>
  <v-row row class="worktop">
    <v-col :cols="12">
      <v-row class="sheet align-center">
        <v-col :cols="2">
          <MegaSelect
            :row="rowNum"
            :col="1"
            :options="materials"
            :data.sync="data.material"
            :placeholder="data.material ? 'Update from: ' + data.material : 'Material'"
          ></MegaSelect>
        </v-col>
        <v-col :cols="2">
          <MegaSelect
            :row="rowNum"
            :col="2"
            :options="thicknesses"
            :data.sync="data.thickness"
            placeholder="Thickness"
          ></MegaSelect>
        </v-col>
        <v-col :cols="2" class="d-flex align-center">
          <div class="colour mr-2" :style="{ 'background-color': findHexFromColour(data) }"></div>
          <MegaSelect
            :row="rowNum"
            :col="3"
            :options="materialColours"
            :data.sync="data.colourCode"
            :itemText="
              i => {
                return i.value + ' - ' + i.text
              }
            "
            :placeholder="data.colourCode ? 'Update from: ' + data.colourCode : 'Colour'"
          ></MegaSelect>
        </v-col>

        <v-col :cols="2">
          <MegaSelect
            :row="rowNum"
            :col="4"
            :options="availableFaces"
            :data.sync="data.faces"
            placeholder="Faces"
          ></MegaSelect>
        </v-col>
        <v-col :cols="2">
          <MegaSelect
            :row="rowNum"
            :col="5"
            :options="availableSizes"
            :data.sync="data.size"
            placeholder="Size"
          ></MegaSelect>
        </v-col>
        <v-col class="d-flex" :order="wonky ? 'first' : 'last'" :class="[{ 'justify-end': !wonky }]" :cols="2">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" @click="$emit('copy', rowNum)" v-on="on">mdi-content-copy</v-icon>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon small @click="$emit('delete', rowNum)" v-on="on">mdi-delete</v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="sheetUsage">
        <v-spacer></v-spacer>
        <v-col :cols="12" v-if="data.remArea" class="d-flex justify-end align-center">
          <div class="font-weight-bold mr-2">Remaining Sheet Area:</div>
          <div>{{ data.remArea.remainingArea / 1000000 }}</div>
          <div v-if="data.remArea.unplacedSections.length > 0" class="d-flex">
            <div class="mx-2">||</div>
            <div class="font-weight-bold red--text">Unplaced Sections:</div>
            <div v-for="(section, index) in data.remArea.unplacedSections" class="ml-2 red--text">
              {{ section.name
              }}{{
                data.remArea.unplacedSections.length > 1 && index != data.remArea.unplacedSections.length - 1 ? ',' : ''
              }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row
        class="section"
        :class="[{ last: key == data.sections.length - 1 }, { first: key == 0 }]"
        row
        align-center
        v-for="(section, key) in data.sections"
        :key="key"
      >
        <v-col cols="2" class="d-flex">
          <input name="name" v-model="section.name" style="width: 100%" @focus="sectionFocussed" />
        </v-col>
        <v-col cols="1" class="pr-4" :class="section.verticallyRotated ? 'edge-labels-rotated' : 'edge-labels'">
          <div>
            <div class="label">Back</div>
            <div class="rule"></div>
          </div>
          <div>
            <div class="label">Front</div>
            <div class="rule"></div>
          </div>
        </v-col>
        <v-col cols="2" class="finishing">
          <div class="mx-2">
            <div class="box">
              <div
                :class="
                  section.dogleg && !section.verticallyRotated
                    ? 'label-shifted'
                    : section.dogleg && section.verticallyRotated
                    ? 'label-rotated'
                    : 'label'
                "
              >
                Finishing
              </div>
              <v-checkbox
                v-model="section.finished.front"
                :class="
                  section.dogleg && !section.verticallyRotated
                    ? 'front-shifted'
                    : section.dogleg && section.verticallyRotated
                    ? 'front-shifted-rotated'
                    : !section.dogleg && section.verticallyRotated
                    ? 'front-rotated'
                    : 'front'
                "
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="section.finished.left"
                :class="
                  section.dogleg && !section.verticallyRotated
                    ? 'left-shifted'
                    : section.dogleg && section.verticallyRotated
                    ? 'left-shifted-rotated'
                    : !section.dogleg && section.verticallyRotated
                    ? 'left-rotated'
                    : 'left'
                "
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="section.finished.right"
                :class="section.verticallyRotated ? 'right-rotated' : 'right'"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="section.finished.back"
                :class="section.verticallyRotated ? 'back-rotated' : 'back'"
                hide-details
              ></v-checkbox>
              <div :class="section.verticallyRotated ? 'innerBox-rotated' : 'innerBox'" v-if="section.dogleg">
                <div
                  :class="section.verticallyRotated ? 'innerLabel-rotated' : 'innerLabel'"
                  :style="section.verticallyRotated ? 'margin-top: 2%;' : 'margin-top: 8%;'"
                >
                  Dogleg
                </div>
                <v-checkbox
                  v-model="section.finished.dogLong"
                  :class="section.verticallyRotated ? 'dogLong-rotated' : 'dogLong'"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="section.finished.dogShort"
                  :class="section.verticallyRotated ? 'dogShort-rotated' : 'dogShort'"
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="2" class="radius">
          <div class="mx-2">
            <div class="box" v-if="section.radii">
              <div
                :class="
                  section.dogleg && !section.verticallyRotated
                    ? 'label-shifted'
                    : section.dogleg && section.verticallyRotated
                    ? 'label-rotated'
                    : 'label'
                "
              >
                Radius (mm)
              </div>
              <input
                name="radiusBL"
                type="number"
                v-model.number="section.radii.bl"
                size="mini"
                :class="section.verticallyRotated ? 'bl-rotated' : 'bl'"
              />
              <input
                name="radiusBR"
                type="number"
                v-model.number="section.radii.br"
                size="mini"
                :class="section.verticallyRotated ? 'br-rotated' : 'br'"
              />
              <input
                name="radiusFR"
                type="number"
                v-model.number="section.radii.fr"
                size="mini"
                :class="section.verticallyRotated ? 'fr-rotated' : 'fr'"
              />
              <input
                v-if="section.dogleg"
                name="radiusFM"
                type="number"
                v-model.number="section.radii.fm"
                size="mini"
                :class="section.verticallyRotated ? 'fm-dog-rotated' : 'fm-dog'"
              />
              <input
                name="radiusFL"
                type="number"
                v-model.number="section.radii.fl"
                size="mini"
                :class="
                  section.dogleg && !section.verticallyRotated
                    ? 'fl-dog'
                    : section.dogleg && section.verticallyRotated
                    ? 'fl-dog-rotated'
                    : !section.dogleg && section.verticallyRotated
                    ? 'fl-rotated'
                    : 'fl'
                "
              />
              <div :class="section.verticallyRotated ? 'innerBox-rotated' : 'innerBox'" v-if="section.dogleg">
                <div :class="section.verticallyRotated ? 'innerLabel-rotated' : 'innerLabel'">Dogleg</div>
                <input
                  name="radiusInner"
                  type="number"
                  v-model.number="section.radii.i"
                  size="mini"
                  :class="section.verticallyRotated ? 'i-rotated' : 'i'"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="2" class="dimensions">
          <div class="mx-2">
            <div class="box">
              <div
                :class="
                  section.dogleg && !section.verticallyRotated
                    ? 'label-shifted'
                    : section.dogleg && section.verticallyRotated
                    ? 'label-rotated'
                    : 'label'
                "
              >
                Dimensions ({{ region === 'us' ? 'inches' : 'mm' }})
              </div>
              <InchInput
                v-if="region === 'us'"
                :data.sync="section.d"
                :displayClass="section.verticallyRotated ? 'depth-rotated' : 'depth'"
              ></InchInput>
              <input
                v-else
                name="depth"
                type="number"
                v-model.number="section.d"
                size="mini"
                :class="section.verticallyRotated ? 'depth-rotated' : 'depth'"
              />
              <InchInput
                v-if="region === 'us'"
                :data.sync="section.l"
                :displayClass="
                  section.dogleg && !section.verticallyRotated
                    ? 'length-shifted'
                    : section.dogleg && section.verticallyRotated
                    ? 'length-shifted-rotated'
                    : !section.dogleg && section.verticallyRotated
                    ? 'length-rotated'
                    : 'length'
                "
              ></InchInput>
              <input
                v-else
                name="length"
                type="number"
                v-model.number="section.l"
                size="mini"
                :class="
                  section.dogleg && !section.verticallyRotated
                    ? 'length-shifted'
                    : section.dogleg && section.verticallyRotated
                    ? 'length-shifted-rotated'
                    : !section.dogleg && section.verticallyRotated
                    ? 'length-rotated'
                    : 'length'
                "
              />
              <!-- dimension calc length -->
              <InchInput
                v-if="region === 'us' && section.dogleg && section.l && section.li"
                :displayClass="section.verticallyRotated ? 'doglegLengthCalc-rotated' : 'doglegLengthCalc'"
                :value="doglegCalc(section.l, section.li)"
                disabled
              ></InchInput>
              <input
                v-else-if="region == 'uk' && section.dogleg && section.l && section.li"
                name="length"
                size="mini"
                :class="section.verticallyRotated ? 'doglegLengthCalc-rotated' : 'doglegLengthCalc'"
                :value="doglegCalc(section.l, section.li)"
                disabled
              />
              <!-- dimension calc depth -->
              <InchInput
                v-if="region === 'us' && section.dogleg && section.d && section.di"
                :displayClass="section.verticallyRotated ? 'doglegDepthCalc-rotated' : 'doglegDepthCalc'"
                :value="doglegCalc(section.d, section.di)"
                disabled
              ></InchInput>
              <input
                v-else-if="region == 'uk' && section.dogleg && section.d && section.di"
                name="length"
                size="mini"
                :class="section.verticallyRotated ? 'doglegDepthCalc-rotated' : 'doglegDepthCalc'"
                :value="doglegCalc(section.d, section.di)"
                disabled
              />
              <div :class="section.verticallyRotated ? 'innerBox-rotated' : 'innerBox'" v-if="section.dogleg">
                <div :class="section.verticallyRotated ? 'innerLabel-rotated' : 'innerLabel'">Dogleg</div>
                <InchInput
                  v-if="region === 'us'"
                  :data.sync="section.di"
                  :displayClass="section.verticallyRotated ? 'doglegDepth-rotated' : 'doglegDepth'"
                ></InchInput>
                <input
                  v-else
                  name="doglegDepth"
                  type="number"
                  v-model.number="section.di"
                  size="mini"
                  :class="section.verticallyRotated ? 'doglegDepth-rotated' : 'doglegDepth'"
                />
                <InchInput
                  v-if="region === 'us'"
                  :data.sync="section.li"
                  :displayClass="section.verticallyRotated ? 'doglegLength-rotated' : 'doglegLength'"
                ></InchInput>
                <input
                  v-else
                  name="doglegLength"
                  type="number"
                  v-model.number="section.li"
                  size="mini"
                  :class="section.verticallyRotated ? 'doglegLength-rotated' : 'doglegLength'"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="2" class="d-flex align-center">
          <div class="d-flex mr-6 align-center ml-2">
            <div class="vertical-display text-center mr-4">
              <div class="d-flex align-center">
                <v-icon small>mdi-arrow-collapse-right</v-icon>
                <v-checkbox
                  v-model="section.displayVerticalRight"
                  :disabled="section.customCoreThickness"
                  @change="section.displayVerticalLeft = false"
                  dense
                  hide-details
                  class="my-0 py-0"
                >
                </v-checkbox>
                <v-checkbox
                  v-model="section.displayVerticalLeft"
                  :disabled="section.customCoreThickness"
                  @change="section.displayVerticalRight = false"
                  dense
                  hide-details
                  class="my-0 py-0"
                >
                </v-checkbox>
                <v-icon small>mdi-arrow-collapse-left</v-icon>
              </div>
            </div>
            <div class="features d-flex flex-column">
              <div class="upstandFeature d-inline-flex align-center">
                <v-checkbox
                  v-model="section.customCoreThickness"
                  dense
                  hide-details
                  class="upstand-check my-0 py-0"
                  @change="resetUpstand(section)"
                >
                </v-checkbox>
                <div class="label">
                  Upstand
                </div>
                <input
                  :style="{ width: '70px' }"
                  class="ml-2 outlined px-1"
                  v-if="section.customCoreThickness"
                  name="Thickness"
                  v-model.number="section.coreThickness"
                  placeholder="Thickness"
                  size="mini"
                />
              </div>
              <div class="doglegFeature d-inline-flex align-center">
                <v-checkbox v-model="section.dogleg" dense hide-details class="upstand-check my-0 py-0" />
                <div class="label">Dogleg</div>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="1" class="d-flex justify-end align-center">
          <div class="pr-2">
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click="rotateSection(key)">mdi-rotate-right-variant</v-icon>
              </template>
              <span>Rotate</span>
            </v-tooltip>
          </div>
          <div class="pr-2">
            <v-tooltip top :open-delay="500" v-if="data.sections.length > 1">
              <template v-slot:activator="{ on }">
                <v-icon small @click="removeSection(key)" v-on="on">mdi-delete</v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small @click="addSection" v-on="on">mdi-plus</v-icon>
              </template>
              <span>Add</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import uid from 'uid'
import MegaSelect from '@/components/quoting/items/MegaSelect'
import InchInput from '@/components/quoting/items/InchInput'
import Materials from '@/js/Materials'
import store from '@/store'

export default {
  name: 'Worktop',
  props: ['rowNum', 'data', 'wonky', 'region'],
  components: { MegaSelect, InchInput },
  watch: {
    data: {
      handler(to) {
        if (!to.sections && to.size) {
          this.$set(this.data, 'sections', [])
          this.addSection()
        }
        if (!this.availableFaces.includes(to.faces)) {
          this.data.faces = null
          this.data.size = null
        }
        if (!this.availableSizes.includes(to.size)) {
          this.data.size = null
        }
      },
      deep: true
    },
    'data.sections': function(val) {
      if (val) {
        this.data.remArea = this.calcRemArea(this.data.size, this.data.sections)
      }
    }
  },
  created() {
    if (this.data.thickness && typeof this.data.thickness === 'string') {
      this.data.thickness = parseInt(this.data.thickness)
    }
    if (this.data.material === 'fenixNTM') {
      this.data.material = 'fenixNtm'
    }

    if (this.data.size && this.data.sections) {
      this.data.remArea = this.calcRemArea(this.data.size, this.data.sections)
    }
  },
  computed: {
    materials() {
      const a = []
      for (const worktop of this.$store.state.worktops) {
        if (worktop.region === this.region) a.push({ label: worktop.text, value: worktop.value })
      }
      return a
    },
    materialColours() {
      if (!this.selectedWorktop || !this.data.material) return []
      return Materials.getColoursFromValue(this.selectedWorktop.material)
    },
    selectedWorktop() {
      if (!this.data.material) return null
      return this.$store.state.worktops.find(m => {
        return m.value === this.data.material
      })
    },
    thicknesses() {
      if (!this.selectedWorktop) return []
      const a = []
      for (const size of this.selectedWorktop.sizes) {
        if (!a.includes(size.thickness)) {
          a.push(size.thickness)
        }
      }
      return a
    },
    availableFaces() {
      if (!this.selectedWorktop || !this.data.material || !this.data.thickness) return []

      const filteredSizes = this.selectedWorktop.sizes.filter(s => {
        return s.thickness === this.data.thickness
      })

      const a = []
      for (const size of filteredSizes) {
        if (!a.includes(size.sided)) {
          a.push(size.sided)
        }
      }
      return a
    },
    availableSizes() {
      if (!this.selectedWorktop || !this.data.material || !this.data.thickness || this.data.sided) return []

      const filteredSizes = this.selectedWorktop.sizes.filter(s => {
        return s.thickness === this.data.thickness && s.sided === this.data.faces
      })

      const a = []
      for (const size of filteredSizes) {
        if (!a.includes(size.size)) {
          a.push(size.size)
        }
      }
      return a
    }
  },
  methods: {
    addSection() {
      var l
      if (this.data.size == 'regular' || this.data.size == 'large') {
        l = this.data.size == 'regular' ? 2430 : 3040
      } else {
        l = parseInt(this.data.size.split('x')) - 10
      }

      this.data.sections.push({
        uid: uid(10),
        d: 700,
        l: l,
        di: 600,
        li: 600,
        name: 'Section ' + (this.data.sections.length + 1),
        finished: {
          front: true,
          left: true,
          right: true,
          back: true,
          dogLong: true,
          dogShort: true
        },
        radii: {
          fl: 0,
          fr: 0,
          bl: 0,
          br: 0,
          i: 5,
          fm: 0
        }
      })
    },
    rotateSection(key) {
      this.data.sections[key].verticallyRotated = !this.data.sections[key].verticallyRotated
      if (this.data.sections[key].verticallyRotated) {
        this.data.remArea = this.calcRemArea(this.data.size, this.data.sections)
      }
    },
    removeSection(key) {
      this.data.sections.splice(key, 1)
    },
    sectionFocussed() {
      this.$store.commit('setSelectedCell', { row: null, col: null })
      this.$store.commit('setFocussedCell', { row: null, col: null })
    },
    resetUpstand(section) {
      section.displayVerticalRight = null
      section.displayVerticalLeft = null
      if (!section.customCoreThickness) {
        section.coreThickness = null
      }
    },
    findHexFromColour(worktopMaterial) {
      if (!this.selectedWorktop || !this.data.material || !worktopMaterial.colourCode) return []

      if (!store) return []
      let worktopColour
      const colouredMaterials = store.state.materials?.filter(material => material.colours)
      const colours = colouredMaterials.map(colouredMaterial => colouredMaterial.colours)
      for (const materialSet of colours) {
        for (const colourInfo of materialSet) {
          if (colourInfo.value === worktopMaterial.colourCode) {
            worktopColour = colourInfo
          }
        }
      }
      return worktopColour.hex
    },
    doglegCalc(outerDim, innerDim) {
      if (outerDim > innerDim) {
        return outerDim - innerDim
      } else {
        return 'error'
      }
    },
    calcRemArea(size, sections) {
      if (!size || !sections || !sections.length > 0) return

      let sheetWidth
      let sheetHeight
      if (size) {
        sheetWidth = size == 'regular' ? 2430 : 3040
        sheetHeight = size == 'regular' ? 1210 : 1290
      }

      // Create grid representing the sheet
      let grid = Array.from(Array(sheetHeight), () => Array(sheetWidth).fill(0))

      // Create a copy of sections and sort the copy by area in descending order
      let sortedSections = [...sections].sort((a, b) => {
        const aArea = (a.verticallyRotated ? a.d : a.l) * (a.verticallyRotated ? a.l : a.d)
        const bArea = (b.verticallyRotated ? b.d : b.l) * (b.verticallyRotated ? b.l : b.d)
        return bArea - aArea
      })

      let unplacedSections = []

      // Function to check if a section can be placed at the specified position
      function canPlaceSection(x, y, width, height) {
        for (let i = y; i < y + height; i++) {
          for (let j = x; j < x + width; j++) {
            if (grid[i][j] !== 0) {
              return false // Section overlaps with existing section
            }
          }
        }
        return true
      }

      // Function to place a section at the specified position
      function placeSection(x, y, width, height) {
        for (let i = y; i < y + height; i++) {
          for (let j = x; j < x + width; j++) {
            grid[i][j] = 1 // Mark grid cells as occupied by section
          }
        }
      }

      // Iterate through sorted sections and place them on the sheet
      for (let section of sortedSections) {
        let sectionWidth = section.verticallyRotated ? section.d : section.l
        let sectionHeight = section.verticallyRotated ? section.l : section.d

        let placed = false

        // Try to place the section at the bottom-left corner of the grid
        for (let y = 0; y <= sheetHeight - sectionHeight && !placed; y++) {
          for (let x = 0; x <= sheetWidth - sectionWidth && !placed; x++) {
            if (canPlaceSection(x, y, sectionWidth, sectionHeight)) {
              placeSection(x, y, sectionWidth, sectionHeight)
              placed = true
            }
          }
        }

        if (!placed) {
          // Section couldn't be placed, record it in unplacedSections array
          unplacedSections.push(section)
        }
      }

      // Calculate remaining area by counting empty cells in the grid
      let remainingArea = grid.reduce(
        (acc, row) => acc + row.reduce((rowAcc, cell) => rowAcc + (cell === 0 ? 1 : 0), 0),
        0
      )

      // Return unplaced sections and remaining area
      return {
        unplacedSections: unplacedSections,
        remainingArea: remainingArea
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/colours';
.worktop {
  .layout {
    > .flex {
      padding: 0 8px !important;
    }
  }
  .sheet {
    border-bottom: solid 1px grey;
    background-color: $ruleGrey;
  }
  input.outlined {
    border: solid 1px grey;
  }
  .section {
    input:focus {
      outline: none;
      border: solid 1px $palePink;
    }

    .vertical-display {
      .v-input--selection-controls__input {
        margin-right: 0;
      }
    }

    .upstand-check {
      .v-input--selection-controls__input {
        margin-right: 2px !important;
      }
    }

    &.first {
      border-top: solid 1px grey;
    }
    &.last {
      border-bottom: solid 1px grey;
    }
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #ccc;
    font-size: 12px;

    .edge-labels {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80px;
      > div {
        display: flex;
        align-items: center;
        .label {
          flex: 1;
        }
        .rule {
          flex: 1;
          height: 1px;
          background: grey;
        }
      }
    }

    .edge-labels-rotated {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      height: 80px;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .label {
          flex: 1;
        }
        .rule {
          flex: 1;
          margin-left: 10px;
          float: left;
          width: 1px;
          height: 100%;
          background: grey;
        }
      }
    }
    .box .label-shifted {
      margin-bottom: 12%;
    }
    .box .label-rotated {
      margin-top: 12%;
    }

    .finishing,
    .radius,
    .dimensions {
      .box {
        position: relative;
        width: 100%;
        height: 70px;
        border: solid grey 1px;
        display: flex;
        align-items: center;
        justify-content: center;

        .innerBox {
          position: absolute;
          width: 50%;
          height: 50%;
          border-right: solid grey 1px;
          border-top: solid grey 1px;
          bottom: 0;
          transform: translateX(-50%);

          .innerLabel {
            margin-top: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .innerBox-rotated {
          position: absolute;
          width: 50%;
          height: 50%;
          border-right: solid grey 1px;
          border-bottom: solid grey 1px;
          bottom: 0;
          transform: translate(-50%, -100%);

          .innerLabel-rotated {
            margin-top: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .v-input--checkbox,
        .mega-cell,
        input {
          background: white;
          position: absolute;
          margin: 0;
          width: 25px;
          text-align: center;
        }

        .back {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .back-rotated {
          top: 50%;
          right: 0%;
          transform: translate(50%, -50%);
        }
        .front {
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
        }
        .front-rotated {
          bottom: 50%;
          left: 0%;
          transform: translate(-50%, 50%);
        }
        .front-shifted {
          bottom: 0;
          left: 75%;
          transform: translate(-50%, 50%);
        }
        .front-shifted-rotated {
          bottom: 25%;
          left: 0;
          transform: translate(-50%, 50%);
        }
        .left {
          left: 0%;
          transform: translateX(-50%);
        }
        .left-rotated {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .left-shifted {
          bottom: 50%;
          left: 0%;
          transform: translateX(-50%);
        }
        .left-shifted-rotated {
          top: 0%;
          left: 75%;
          transform: translate(-50%, -50%);
        }
        .right {
          right: 0%;
          bottom: 50%;
          transform: translate(50%, 50%);
        }
        .right-rotated {
          right: 50%;
          bottom: 0%;
          transform: translate(50%, 50%);
        }
        .dogLong {
          top: 0%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .dogLong-rotated {
          top: 5%;
          right: 0%;
          transform: translateX(50%);
        }
        .dogShort {
          top: 50%;
          right: 0%;
          transform: translate(50%, -50%);
        }
        .dogShort-rotated {
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
        }

        .bl {
          top: 0;
          left: 0;
          transform: translate(-50%, -50%);
        }
        .bl-rotated {
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
        .br {
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
        .br-rotated {
          bottom: 0;
          right: 0;
          transform: translate(50%, 50%);
        }
        .fr {
          bottom: 0;
          right: 0;
          transform: translate(50%, 50%);
        }
        .fr-rotated {
          bottom: 0;
          left: 0;
          transform: translate(-50%, 50%);
        }
        .fl {
          bottom: 0;
          left: 0;
          transform: translate(-50%, 50%);
        }
        .fl-rotated {
          top: 0;
          left: 0;
          transform: translate(-50%, -50%);
        }
        .fl-dog {
          top: 25%;
          left: 0;
          transform: translate(-50%, 50%);
          z-index: 1;
        }
        .fl-dog-rotated {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        .fm-dog {
          bottom: 0%;
          left: 50%;
          transform: translate(-50%, 50%);
          z-index: 1;
        }
        .fm-dog-rotated {
          bottom: 50%;
          left: 0%;
          transform: translate(-50%, 50%);
          z-index: 1;
        }
        .i {
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
        .i-rotated {
          bottom: 0%;
          right: 0%;
          transform: translate(50%, 50%);
        }
        .depth {
          right: 0;
          transform: translateX(50%);
        }
        .depth-rotated {
          bottom: 0;
          right: 50%;
          transform: translate(50%, 50%);
        }
        .length {
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
        }
        .length-rotated {
          top: 50%;
          left: 0%;
          transform: translate(-50%, -50%);
        }
        .length-shifted {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .length-shifted-rotated {
          top: 50%;
          right: 0%;
          transform: translate(50%, -50%);
        }
        .doglegDepth {
          top: 50%;
          right: 0;
          transform: translate(50%, -50%);
          z-index: 1;
        }
        .doglegDepth-rotated {
          top: 50%;
          right: 50%;
          transform: translate(50%, 50%);
          z-index: 1;
        }
        .doglegLength {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        .doglegLength-rotated {
          top: 50%;
          right: 0%;
          transform: translate(50%, -50%);
          z-index: 1;
        }
        .doglegLengthCalc {
          bottom: 0%;
          left: 75%;
          transform: translate(-50%, 50%);
        }
        .doglegLengthCalc-rotated {
          bottom: 25%;
          left: 0%;
          transform: translate(-50%, 50%);
        }
        .doglegDepthCalc {
          top: 0%;
          left: 0%;
          transform: translate(-50%, 50%);
        }
        .doglegDepthCalc-rotated {
          top: 0%;
          left: 75%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .dimensions {
      .box {
        input {
          width: 50px;
        }
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.colour {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border: solid 1px #999;
  border-radius: 3px;
  margin-right: 12px;
}
</style>
