<template>
  <div id="lucid-pricing-view">
    <v-progress-linear v-if="loading" :indeterminate="true" class="ma-0" color="accent" :height="5"></v-progress-linear>
    <div v-if="!loading" id="view-sub-nav" class="flex justify-end">
      <v-btn class="ml-2" depressed outlined color="primary" @click="csv">Download CSV</v-btn>
    </div>
    <v-container fluid v-if="!loading">
      <v-row>
        <v-col cols="12">
          <h2>Materials</h2>
        </v-col>

        <v-col
          v-for="material in materials"
          class="elevation-0"
          v-if="material.itemCount > 0"
          :key="material.id"
          cols="12"
        >
          <v-row>
            <v-col cols="6" class="py-1"
              >{{ material.label }} ({{ sheetCount[material.id] }})
              <span v-if="material.handlesCost == 0 && material.size">[{{ material.size }}]</span></v-col
            >
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(combinedCosts[material.id].materialTotalCost).format('0,0.00') }}</v-col
            >
          </v-row>
        </v-col>
        <v-col
          v-for="copyMaterial in copyMaterialData"
          class="elevation-0"
          v-if="copyMaterialData.length > 0"
          :key="copyMaterial.id"
          cols="12"
        >
          <v-row>
            <v-col cols="6" class="py-1"
              >{{ copyMaterial.label }} ({{ copyMaterial.sheetCount }}) [{{ copyMaterial.sheetSize }}]</v-col
            >
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(copyMaterial.materialTotalCost).format('0,0.00') }}</v-col
            >
          </v-row>
        </v-col>
        <v-col>
          <v-divider class="mt-1 mb-4" />
          <v-row>
            <v-col cols="6" class="py-1"><h4>Material Total</h4></v-col>
            <v-col cols="6" class="py-1 text-right">${{ $numeral(combinedCosts.material).format('0,0.00') }}</v-col>
          </v-row>
          <v-divider class="mt-4" />
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col cols="12">
          <h2>Breakdown</h2>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6" class="py-1">Total Area Sq Ft</v-col>
            <v-col cols="6" class="py-1 text-right">{{ $utils.toFixedNumber(combinedCosts.area, 2) }} sq ft</v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">Total Linear Ft</v-col>
            <v-col cols="6" class="py-1 text-right">{{ $utils.toFixedNumber(combinedCosts.perimeter, 2) }} ft</v-col>
          </v-row>
          <v-row v-if="combinedCosts.jHandlesPerimeter > 0">
            <v-col cols="6" class="py-1">J-Handles Linear Ft</v-col>
            <v-col cols="6" class="py-1 text-right"
              >{{ $utils.toFixedNumber(combinedCosts.jHandlesPerimeter, 2) }} ft</v-col
            >
          </v-row>
          <v-divider class="mt-4 mb-4" />
          <v-row>
            <v-col cols="6" class="py-1"><h4>Customer Total Payment</h4></v-col>
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(version.pricing.total - version.pricing.discountedSubtotal).format('0,0.00') }}</v-col
            >
          </v-row>
          <v-divider class="mt-4" />
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col cols="12">
          <h2>Costs</h2>
        </v-col>
        <v-col cols="12">
          <v-row v-if="version.pricing.worktops && version.pricing.worktops.cost > 0">
            <v-col cols="6" class="py-1">Worktops</v-col>
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(version.pricing.worktops.cost).format('0,0.00') }}</v-col
            >
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">CAD</v-col>
            <v-col cols="6" class="py-1 text-right">${{ $numeral(combinedCosts.cad).format('0,0.00') }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">CNC</v-col>
            <v-col cols="6" class="py-1 text-right">${{ $numeral(combinedCosts.cutting).format('0,0.00') }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">Edge Finishing</v-col>
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(combinedCosts.edgeFinishing).format('0,0.00') }}</v-col
            >
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">Face Finishing</v-col>
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(combinedCosts.faceFinishing).format('0,0.00') }}</v-col
            >
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">Handles ({{ combinedCosts.numHandles }})</v-col>
            <v-col cols="6" class="py-1 text-right">${{ $numeral(combinedCosts.handlesCost).format('0,0.00') }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">Pack & Crate</v-col>
            <v-col cols="6" class="py-1 text-right">${{ $numeral(combinedCosts.packaging).format('0,0.00') }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">Pack & Crate Per Sq Foot</v-col>
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(combinedCosts.packagingPerSqFt).format('0,0.00') }}</v-col
            >
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6" class="py-1">Freight</v-col>
            <v-col cols="6" class="py-1 text-right"
              >${{ $numeral(version.pricing.shipping * 2).format('0,0.00') }}</v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="my-6" />
      <v-row>
        <v-col cols="6" :class="version.lucidCoverCost ? 'red--text' : ''">
          <h2>Lucid Total</h2>
        </v-col>
        <v-col cols="6" :class="version.lucidCoverCost ? 'text-right red--text' : 'text-right'">
          <h2>${{ $numeral(total).format('0,0.00') }}</h2>
          <h4 v-if="version.lucidCoverCost">(Recut Covered by Lucid)</h4>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import productData from '@/js/productData'
import usPricing from '@/js/pricing/usPricing'
import utils from '@/js/utils'
import store from '@/store'
import filesaver from 'file-saver'

export default {
  name: 'LucidPricingView',
  props: ['orderID'],
  data() {
    return {
      productData: productData,
      version: {},
      order: {},
      materials: [],
      loading: true
    }
  },
  watch: {
    orderID() {
      this.loadPricing()
    }
  },
  mounted() {
    this.loadPricing()
  },
  computed: {
    sheetCount() {
      let sheetCount = {}
      for (const m of this.materials) {
        if (this.version.materialUsage[m.id]?.qtyOverride > 0) {
          sheetCount[m.id] = Math.ceil(this.version.materialUsage[m.id].qtyOverride)
        } else {
          sheetCount[m.id] = m.requiredSheetsRounded
        }
      }
      return sheetCount
    },
    totalSheetCount() {
      let totalSheetCount = 0
      for (const m of this.materials) {
        if (this.version.materialUsage[m.id]?.qtyOverride > 0) {
          totalSheetCount += Math.ceil(this.version.materialUsage[m.id].qtyOverride)
        } else {
          totalSheetCount += m.requiredSheetsRounded
        }
      }

      if (this.version.copyMaterial && this.version.copyMaterial.length > 0) {
        for (const copy of this.version.copyMaterial) {
          totalSheetCount += copy.qtyOverride ? copy.qtyOverride : 1
        }
      }
      return totalSheetCount
    },
    copyMaterialData() {
      let copyMaterialArray = []
      if (this.version.copyMaterial && this.version.copyMaterial.length > 0) {
        let copyMaterial = {
          id: '',
          label: '',
          sheetCount: 0,
          sheetSize: '',
          materialTotalCost: 0
        }

        for (const copy of this.version.copyMaterial) {
          copyMaterial.id = copy.id
          copyMaterial.label = copy.name
          copyMaterial.sheetCount = copy.qtyOverride ? copy.qtyOverride : 1
          copyMaterial.sheetSize = `${copy.sheetOverride.w}x${copy.sheetOverride.h}`
          copyMaterial.materialTotalCost = copyMaterial.sheetCount * copy.sheetOverride.cost
          copyMaterialArray.push(copyMaterial)
        }
      }
      return copyMaterialArray
    },
    combinedCosts() {
      const mmPerFoot = 304.8
      const sqMmPerSqFoot = 92903.04

      let costs = {
        material: 0,
        cad: 0,
        cutting: 0,
        packaging: 0,
        packagingPerSqFt: 0,
        edgeFinishing: 0,
        faceFinishing: 0,
        handlesCost: 0,
        jHandlesPerimeter: 0,
        numHandles: 0,
        perimeter: 0,
        area: 0
      }
      costs.packaging = utils.toFixedNumber(store.state.pricing.usCosts.packAndCrateFixedCost.value, 2)

      for (const m of this.materials) {
        if ((m.total += 0) && this.sheetCount) {
          costs[m.id] = {}
          costs[m.id].materialTotalCost = this.sheetCount[m.id] * m.sheetCost
          costs.material += costs[m.id].materialTotalCost
          if (m.sheetCost > 0) {
            // 4mm sheet is not included in price (=$0)
            costs.cad += m.programmingCost
            costs.cutting += m.cuttingCost
            costs.edgeFinishing += m.edgeFinishingCost
            costs.faceFinishing += m.surfaceFinishingCost
            costs.packagingPerSqFt += m.packagingPerSqFt
            costs.perimeter += m.perimeter / mmPerFoot
            costs.area += m.area / sqMmPerSqFoot
          }
          if (m.handlesCost > 0) {
            // costs.faceFinishing += m.surfaceFinishingC
            costs.jHandlesPerimeter += m.jHandlesPerimeterFt
            costs.numHandles += m.numHandles
            costs.handlesCost += m.lucidHandlesCost
          }
        }
      }

      // add on copyMaterial sheet costs
      if (this.version.copyMaterial && this.version.copyMaterial.length > 0) {
        for (const copy of this.version.copyMaterial) {
          costs[copy.id] = {}
          costs[copy.id].materialTotalCost = copy?.qtyOverride
            ? copy.sheetOverride.cost * copy.qtyOverride
            : copy.sheetOverride.cost * copy.qty
          costs.material += costs[copy.id].materialTotalCost
        }
      }
      return costs
    },
    total() {
      return (
        this.combinedCosts.material +
        this.combinedCosts.cad +
        this.combinedCosts.cutting +
        this.combinedCosts.packaging +
        this.combinedCosts.packagingPerSqFt +
        this.combinedCosts.edgeFinishing +
        this.combinedCosts.faceFinishing +
        this.combinedCosts.handlesCost +
        this.version.pricing.shipping * 2 +
        this.version.pricing.worktops.cost
      )
    },
    materialDataString() {
      let materialCombo = ''
      let materialComboArray = []

      for (const material of this.materials) {
        if (material.label.includes('Fenix')) {
          if (!materialComboArray.includes('Fenix')) {
            materialComboArray.push('Fenix')
          }
        } else if (material.label.includes('Formica')) {
          if (!materialComboArray.includes('Formica')) {
            materialComboArray.push('Formica')
          }
        } else if (material.label.includes('Birch')) {
          if (!materialComboArray.includes('Birch')) {
            materialComboArray.push('Birch')
          }
        } else if (material.label.includes('Oak')) {
          if (!materialComboArray.includes('Oak')) {
            materialComboArray.push('Oak')
          }
        } else if (material.label.includes('Walnut')) {
          if (!materialComboArray.includes('Walnut')) {
            materialComboArray.push('Walnut')
          }
        } else if (material.label.includes('Maple')) {
          if (!materialComboArray.includes('Maple')) {
            materialComboArray.push('Maple')
          }
        }
      }

      materialComboArray.forEach((material, index) => {
        if (index + 1 == materialComboArray.length) {
          materialCombo += material
        } else {
          materialCombo += material + ' / '
        }
      })

      return materialCombo
    },
    handleDataString() {
      let handleCombo = ''

      this.version.handles.forEach((handleType, index) => {
        if (index + 1 == this.version.handles.length) {
          handleCombo += handleType.value
        } else {
          handleCombo += handleType.value + ' / '
        }
      })

      return handleCombo
    }
  },
  methods: {
    async loadPricing() {
      this.loading = true

      const orderRef = await this.$db
        .collection('orders')
        .doc(this.orderID)
        .get()
      this.order = orderRef.data()

      const quoteRef = await this.$db
        .collection('quotes')
        .doc(orderRef.data().quoteID)
        .get()

      this.version = this.$utils.getVersionByID(quoteRef.data().versions, orderRef.data().versionID)

      const pricing = usPricing.fronts(
        this.version.fronts.concat(this.version.coverPanels),
        this.version.materials,
        this.version.handles
      )

      for (const m of this.version.materials) {
        const material = pricing[m.id]
        if (material) {
          material.label = m.label
          this.materials.push(material)
        }
      }

      this.loading = false
    },
    csv() {
      let s =
        'Plykea Order Number,Customer Email,Customer Total Payment,Material Type,Handle Type,Total Sheets,Total Area (sq ft),Total Linear (ft),JHandle Linear (ft),Insert Count,Total Material Cost,CAD,CNC,Edge Finishing,Face Finishing,Handles,Packaging (fixed),Packaging per Sq Ft,Worktops,Freight,Lucid Total\n'
      let orderData = `${this.order.orderNumber},${this.order.customerEmail},${utils.toFixedNumber(
        this.version.pricing.total - this.version.pricing.discountedSubtotal,
        2
      )}`
      let materialData = this.materialDataString
      let handleData = this.handleDataString
      let sheetNumData = this.totalSheetCount
      let breakdownData = `${utils.toFixedNumber(this.combinedCosts.area, 2)},${utils.toFixedNumber(
        this.combinedCosts.perimeter,
        2
      )},${utils.toFixedNumber(this.combinedCosts.jHandlesPerimeter, 2)},${this.combinedCosts.numHandles}`

      let pricingData = `${utils.toFixedNumber(this.combinedCosts.material, 2)},${utils.toFixedNumber(
        this.combinedCosts.cad,
        2
      )},${utils.toFixedNumber(this.combinedCosts.cutting, 2)},${utils.toFixedNumber(
        this.combinedCosts.edgeFinishing,
        2
      )},${utils.toFixedNumber(this.combinedCosts.faceFinishing, 2)},${utils.toFixedNumber(
        this.combinedCosts.handlesCost,
        2
      )},${utils.toFixedNumber(this.combinedCosts.packaging, 2)},${utils.toFixedNumber(
        this.combinedCosts.packagingPerSqFt,
        2
      )},${utils.toFixedNumber(this.version.pricing.worktops.cost, 2)},${utils.toFixedNumber(
        this.version.pricing.shipping * 2,
        2
      )}`
      let lucidTotalData = `${utils.toFixedNumber(this.total, 2)}`
      if (this.version.lucidCoverCost) lucidTotalData += ' (lucid fault)'

      s +=
        orderData +
        ',' +
        materialData +
        ',' +
        handleData +
        ',' +
        sheetNumData +
        ',' +
        breakdownData +
        ',' +
        pricingData +
        ',' +
        lucidTotalData +
        '\n'

      var blob = new Blob([s], { type: 'text/csv' })
      filesaver.saveAs(blob, `usPricing${-this.order.orderNumber}.csv`)
    }
  }
}
</script>
