import productData from '@/js/productData'
import MasterPricing from '@/js/pricing/MasterPricing'
import Materials from '@/js/Materials'
import store from '@/store'
import utils from '@/js/utils'

export default {
  fronts(items, materials, handleOptions, exchangeRate) {
    var o = {
      cost: 0,
      total: 0,
      incomplete: false,
      notifications: []
    }

    if (!items) {
      return o
    }

    materials.forEach(material => {
      if (!material.value || !material.thickness || !material.w || !material.h) return

      let area = 0
      let perimeter = 0
      let handlesCost = 0
      let itemCount = 0
      let plinthTotal = 0
      for (let item of items) {
        let specialType = item.frontType && item.frontType.includes('-') ? item.frontType.split('-')[2] : null
        let cabinetType = item.frontType && item.frontType.includes('-') ? item.frontType.split('-')[1] : null
        const cornerNarrowFillerWidth = cabinetType && cabinetType == 'w' ? 100 : 60
        const cornerWideFillerWidth = cabinetType && cabinetType == 'w' ? 120 : 80

        // ignore any imcomplete items
        if (item.itemNum == undefined || item.w == undefined || item.h == undefined || item.qty == undefined) {
          o.incomplete = true
          return
        }

        //v.plinths incomplete
        if (
          item.coverPanelType === 'v-plinth' &&
          (item.itemNum == undefined ||
            item.w == undefined ||
            item.h == undefined ||
            item.qty == undefined ||
            item.vent == undefined ||
            item.ventRef == undefined ||
            item.ventOffset == undefined)
        ) {
          o.incomplete = true
          return
        }

        // Handle Standard Plinths
        if (
          material.hasGrain &&
          item.material === material.id &&
          item.coverPanelType &&
          ['plinth', 'v-plinth'].includes(item.coverPanelType) &&
          item.h === 75
        ) {
          const pricing = MasterPricing.calculatePriceForMaterial(
            material,
            item.w * item.h * item.qty,
            (item.w + item.h) * 2 * item.qty,
            false,
            item.qty
          )

          plinthTotal += pricing.external * 1.5
          continue
        }

        var width = parseInt(item.w)
        var height = parseInt(item.h)
        var qty = parseInt(item.qty)

        if (item.material === material.id) {
          // change area  & perimeter calculation for corner door
          if (specialType == 'cnr') {
            area += width * height + cornerNarrowFillerWidth * height + cornerWideFillerWidth * height
            perimeter +=
              (width + height) * 2 + (cornerNarrowFillerWidth + height) * 2 + (cornerWideFillerWidth + height) * 2
          } else {
            area += width * height * qty
            perimeter += (width + height) * 2 * qty
          }
          // qty is correct
          itemCount += qty
        }

        // handle
        if (item.handle) {
          let handle = handleOptions.find(handle => {
            return handle.id == item.handle
          })
          let masterHandle = MasterPricing.getHandle(handle.value)

          if (masterHandle) {
            if (handle.insert === material.id) {
              // change qty for insert handle cost of corner door
              handlesCost += specialType == 'cnr' ? masterHandle.costUK * 1 : masterHandle.costUK * qty

              let insertWidth = null
              let insertHeight = null
              // switch area calculation for j-profile depending on hoz or vert
              // TODO: also switch for joined single or double
              if (handle.value == 'j') {
                switch (handle.orientation) {
                  case 'h':
                    // horizontal j-profile
                    insertWidth = width * masterHandle.insertWidthValue // should be 1% in settings (not mm)
                    insertHeight = masterHandle.insertHeightValue
                    break
                  case 'v':
                    // vertical j-profile
                    insertWidth = height * masterHandle.insertWidthValue // should be 1% in settings (not mm)
                    insertHeight = masterHandle.insertHeightValue
                    break
                }
              } else {
                insertWidth =
                  masterHandle.insertWidthUnit == 'mm'
                    ? masterHandle.insertWidthValue
                    : width * masterHandle.insertWidthValue
                insertHeight =
                  masterHandle.insertHeightUnit == 'mm'
                    ? masterHandle.insertHeightValue
                    : height * masterHandle.insertHeightValue
              }
              // change qty for insert handle cost of corner door
              let handleArea = specialType == 'cnr' ? insertWidth * insertHeight * 1 : insertWidth * insertHeight * qty

              const verticalJProfAreaMultiplier = 1.21 // 92/76 where 92 is 52x600 JProf on a reg sheet. 76 is what actually fits when CADed
              const horizontalJProfAreaMultiplier = 1.18 // 92/78 for vertical
              area +=
                handle.value == 'j' && handle.orientation == 'v'
                  ? handleArea * verticalJProfAreaMultiplier
                  : handle.value == 'j' && handle.orientation == 'h'
                  ? handleArea * horizontalJProfAreaMultiplier
                  : handleArea
            } else if (item.material == material.id) {
              // change qty for handle cost of corner door
              handlesCost += specialType == 'cnr' ? masterHandle.costUK * 1 : masterHandle.costUK * qty
            }
          } else {
            o.notifications.push('Discontinued handle needs to be updated')
          }
        }
      }

      // Material Usage
      var m = (o[material.id] = {})

      var wastage = material.continuousGrain === true ? productData.continuousGrainWastage : productData.wastage
      m.name = material.colourCode ? material.colourCode : material.value
      m.area = area
      m.areaWithWastage = area * wastage
      m.perimeter = perimeter
      m.sheetArea = material.w * material.h
      m.requiredSheets = m.areaWithWastage / m.sheetArea
      m.requiredSheetsRounded = Math.ceil(m.requiredSheets)
      m.itemCount = itemCount
      m.total = 0

      // adjust sheet usage for continuous grain (if sheet usage modulus is greater than 0.5 add another sheet)
      if (material.continuousGrain && material.thickness >= 18) {
        m.requiredSheetsRounded += Math.round(m.requiredSheets % 1)
      }

      if (material.thickness >= 18 && (m.area > 0 || plinthTotal > 0)) {
        m.handlesCost = handlesCost * store.state.pricing.ukMultiplier.handlesMarkup.value
        m.plinthTotal = plinthTotal
        m.pricing = MasterPricing.calculatePriceForMaterial(
          material,
          m.area,
          m.perimeter,
          m.requiredSheetsRounded,
          itemCount
        )

        if (m.pricing) {
          m.total = utils.toFixedNumber((m.handlesCost + m.pricing.total + plinthTotal) * exchangeRate, 2)
          o.total = utils.toFixedNumber(o.total + m.total, 2)
        } else {
          return false
        }
      } else if (material.colourCode && material.thickness === 4 && m.area > 0) {
        const sheetCost = Materials.getSheetCostFromProperties(
          material.value,
          material.w,
          material.h,
          material.thickness,
          material.sides
        )

        // cost of sheets * number of sheets required - NEW!
        m.total = sheetCost * m.requiredSheetsRounded
        o.total = utils.toFixedNumber(o.total + m.total, 2)
      } else if (material.thickness === 4 && m.area > 0) {
        // calculate sheet cost for 4mm VENEER
        // currently just charging the whole sheet for 4mm handles
        const sheetCost = Materials.getSheetCostFromProperties(
          material.value,
          material.w,
          material.h,
          material.thickness
          // no sides
        )

        // cost of sheets * number of sheets required - NEW!
        m.total = sheetCost * m.requiredSheetsRounded
        o.total = utils.toFixedNumber(o.total + m.total, 2)
      }
    })
    return o
  },

  // worktops
  worktops(worktops, exchangeRate) {
    var o = {
      cost: 0,
      total: 0,
      incomplete: false,
      debug: {
        numWorktops: worktops.length,
        items: []
      }
    }

    for (let w of worktops) {
      // ignore any imcomplete items
      if (w.material == undefined || w.size == undefined || w.faces == undefined || w.thickness == undefined) {
        o.incomplete = true
        break
      }
      if (w.thickness && w.material && w.size && w.faces) {
        const ukWorktops = store.state.worktops.filter(o => {
          return o.region === 'uk'
        })
        const worktopMaterial = ukWorktops.find(o => {
          return o.value === w.material
        })
        const worktop = worktopMaterial.sizes.find(o => {
          return o.thickness === w.thickness && o.sided === w.faces && o.size === w.size
        })

        // calculate the total required finished perimeter length
        let perimeter = 0
        let radiiCost = 0
        let doglegCost = 0

        if (w?.remArea) {
          if (w?.remArea.unplacedSections && w?.remArea.unplacedSections.length > 0) {
            o.incomplete = true
            break
          }
        }

        for (let section of w.sections) {
          // ignore any imcomplete items
          if (
            (section.customCoreThickness && !section.coreThickness) ||
            section.d == '' ||
            section.l == '' ||
            section.name == '' ||
            section.radii.bl === '' ||
            section.radii.br === '' ||
            section.radii.fl === '' ||
            section.radii.fr === '' ||
            (section.dogleg &&
              (section.radii.fm === '' || section.radii.i === '' || section.di === '' || section.li === ''))
          ) {
            o.incomplete = true
            break
          }

          if (section.finished) {
            if (section.finished.front) perimeter += parseInt(section.l)
            if (section.finished.back) perimeter += parseInt(section.l)
            if (section.finished.left) perimeter += parseInt(section.d)
            if (section.finished.right) perimeter += parseInt(section.d)
            if (section.dogleg && section.finished.dogLong) perimeter += parseInt(section.li)
            if (section.dogleg && section.finished.dogShort) perimeter += parseInt(section.di)
          }
          // calculate and radius cutting cost
          if (section.radii) {
            for (let r in section.radii) {
              if (!section.dogleg && ['fl', 'fr', 'bl', 'br'].includes(r) && parseInt(section.radii[r]) > 0)
                radiiCost += store.state.pricing.ukWorktopCosts.radiusCost.value
              if (section.dogleg && ['i', 'fm'].includes(r) && parseInt(section.radii[r]) > 0)
                radiiCost += store.state.pricing.ukWorktopCosts.radiusCost.value
              break
            }
          }
          // add dogleg flat fee if required
          if (section.dogleg && doglegCost == 0) {
            doglegCost += store.state.pricing.ukWorktopCosts.doglegFlatFee.value
          }
        }
        const cost =
          (worktop.cost + store.state.pricing.ukWorktopCosts.sectionCutCost.value * w.sections.length + radiiCost) *
          exchangeRate
        const internalCost =
          (perimeter / 1000) * store.state.pricing.ukWorktopCosts.finishingCostPerMetre.value +
          doglegCost * exchangeRate
        const total = cost * store.state.pricing.ukWorktopCosts.ukWorktopMultiplier.value + internalCost

        o.cost += cost
        o.total += utils.toFixedNumber(total, 2)

        o.debug.items.push({
          code: w.thickness + '-' + w.material + '-' + w.size + '-' + w.faces,
          cost: cost,
          finishing: (perimeter / 1000) * store.state.pricing.ukWorktopCosts.finishingCostPerMetre.value,
          perimeter: perimeter / 1000,
          total: total,
          data: worktop
        })
      } else {
        o.incomplete = true
      }
    }

    o.cost = utils.toFixedNumber(o.cost, 2)
    o.total = utils.toFixedNumber(o.total, 2)

    return o
  },

  // urtils
  urtils(urtils, materials, exchangeRate) {
    var o = {
      total: 0,
      incomplete: false,
      notifications: [],
      pricing: []
    }
    if (!urtils) {
      return o
    }

    materials.forEach(material => {
      if (!material.value || !material.thickness || !material.w || !material.h) return

      let m = (o[material.id] = {
        areaWithWastage: 0,
        perimeter: 0,
        itemCount: 0
      })

      urtils.forEach(urtil => {
        // check incomplete
        if (
          !urtil.type ||
          !urtil.carcass ||
          !urtil.back ||
          !urtil.w ||
          !urtil.d ||
          !urtil.h ||
          (urtil.type == 's' && !urtil.material1) ||
          (urtil.type == 't' && (!urtil.material1 || !urtil.material2)) ||
          (urtil.shelving && urtil.shelvingOptions.qty < 1)
        ) {
          o.incomplete = true
          return
        }

        // check incomplete by door coverage
        if (urtil.type == 's' && !urtil.doorCoverageOne) {
          o.incomplete = true
          return
        } else if (urtil.type == 't' && (!urtil.doorCoverageOne || !urtil.doorCoverageTwo)) {
          o.incomplete = true
          return
        }

        // Update Material Usage and costs
        // Carcass (priced using standard pricing algorithm)
        if (material.id === urtil.carcass) {
          let carcassArea = urtil.w * urtil.d * 2 + urtil.h * urtil.d * 2
          let perimeter = (urtil.w + urtil.d) * 4 + (urtil.h + urtil.d) * 4

          // Calculate compartment divider cost (fins)
          if (urtil.compartments) {
            const finArea = urtil.h * urtil.d
            const finPerimeter = (urtil.h + urtil.d) * 2

            const numFins = urtil.compartments - 1

            carcassArea += finArea * numFins
            perimeter += finPerimeter * numFins
            m.itemCount += numFins
          }

          if (urtil.shelving) {
            const shelfArea = urtil.w * urtil.d
            const shelfPerimeter = (urtil.w / urtil.compartments + urtil.d) * 2 * urtil.compartments

            const numShelves = urtil.shelvingOptions.qty

            carcassArea += shelfArea * numShelves
            perimeter += shelfPerimeter * numShelves
            m.itemCount += numShelves
          }

          // DONE - switch 0.5 sheets to requiredCarcassSheetsRounded
          let requiredCarcassSheetsRounded = Math.ceil(carcassArea / (material.w * material.h))
          const carcassCost = MasterPricing.calculatePriceForMaterial(
            material,
            carcassArea,
            perimeter,
            requiredCarcassSheetsRounded, // this was set at 0.5 sheets
            1
          )
          o.total += carcassCost.total

          // Update material usage
          m.areaWithWastage += carcassArea
          m.perimeter += perimeter
          m.itemCount += 4
        }

        // Back (priced as external costs * external markup)
        if (material.id === urtil.back) {
          const backMaterialSheetCost = Materials.getSheetCostFromProperties(
            material.value,
            material.w,
            material.h,
            6,
            material.sides
          )
          const sheetUsage = ((urtil.w * urtil.h) / (1220 * 1220)) * 1.5
          const backCost = utils.toFixedNumber(
            (backMaterialSheetCost * sheetUsage +
              ((urtil.w * urtil.h * 2) / 1000000) * store.state.pricing.costs.lacquerPerM2.value) *
              store.state.pricing.ukMultiplier.externalMarkup.value,
            2
          )
          o.total += backCost

          m.areaWithWastage += urtil.w * urtil.h
          m.perimeter += (urtil.w + urtil.h) * 2
          m.itemCount += 1
        }

        // Doors
        if (urtil.type !== 'o' && urtil.material1 && material.id === urtil.material1) {
          const leftDoorCost = calculateUrtilDoorCost(material, urtil, 1)
          o.total += leftDoorCost

          let leftDoorArea = (urtil.w / urtil.compartments) * urtil.doorCoverageOne * urtil.h
          m.areaWithWastage += leftDoorArea
          m.perimeter += urtil.w + urtil.h
          m.itemCount += 1
        }
        if (urtil.type === 't' && urtil.material2 && material.id === urtil.material2) {
          const rightDoorCost = calculateUrtilDoorCost(material, urtil, 2)
          o.total += rightDoorCost

          let rightDoorArea = (urtil.w / urtil.compartments) * urtil.doorCoverageTwo * urtil.h
          m.areaWithWastage += rightDoorArea
          m.perimeter += urtil.w + urtil.h
          m.itemCount += 1
        }
      })
    })
    o.total *= exchangeRate * store.state.pricing.ukMultiplier.urtilMarkup.value
    o.total = utils.toFixedNumber(o.total, 2)
    return o
  },

  pantries(pantries, materials, exchangeRate) {
    var o = {
      total: 0,
      incomplete: false,
      notifications: [],
      pricing: []
    }
    if (!pantries) {
      return o
    }

    materials.forEach(material => {
      if (!material.value || !material.thickness || !material.w || !material.h) return

      let m = (o[material.id] = {
        areaWithWastage: 0,
        perimeter: 0,
        itemCount: 0
      })

      pantries.forEach(pantry => {
        // check incomplete
        if (
          !pantry.pantryType ||
          !pantry.name ||
          !pantry.w ||
          !pantry.d ||
          !pantry.h ||
          !pantry.pantryHinge ||
          !pantry.back ||
          (pantry.pantryType == 'l' && !pantry.base) ||
          (pantry.pantryType == 'l' && pantry.base == pantry.carcass) ||
          !pantry.carcass
        ) {
          o.incomplete = true
          return
        }

        // Update Material Usage and costs
        // Carcass (priced using standard pricing algorithm)
        if (material.id === pantry.carcass) {
          let pantryArea
          let perimeter
          if (!pantry.base) {
            pantryArea = pantry.w * pantry.d * 2 + pantry.h * pantry.d * 2
            perimeter = (pantry.w + pantry.d) * 4 + (pantry.h + pantry.d) * 4
          } else {
            pantryArea = pantry.w * pantry.d * 1 + pantry.h * pantry.d * 2
            perimeter = (pantry.w + pantry.d) * 2 + (pantry.h + pantry.d) * 4
          }

          const shelfArea = pantry.w * pantry.d
          const shelfPerimeter = (pantry.w + pantry.d) * 2

          const numShelves = 3 // 2 adjustable and 1 fixed

          pantryArea += shelfArea * numShelves
          perimeter += shelfPerimeter * numShelves

          // DONE - switch 0.5 sheets to requiredCarcassSheetsRounded
          let requiredCarcassSheetsRounded = Math.ceil(pantryArea / (material.w * material.h))

          const carcassCost = MasterPricing.calculatePriceForMaterial(
            material,
            pantryArea,
            perimeter,
            requiredCarcassSheetsRounded, // this was set at 0.5 sheets
            1
          )
          o.total += carcassCost.total

          // Update material usage
          m.areaWithWastage += pantryArea
          m.perimeter += perimeter
          if (!pantry.base) {
            m.itemCount += 4 + numShelves
          } else {
            m.itemCount += 3 + numShelves
          }
        }

        if (material.id === pantry.base) {
          const baseArea = pantry.w * pantry.d
          const basePerimeter = (pantry.w + pantry.d) * 2

          // DONE - switch 0.5 sheets to requiredCarcassSheetsRounded (base will always fit on a half sheet)
          let requiredBaseSheetsRounded = Math.ceil(baseArea / (1220 * 1220))

          const baseCost = MasterPricing.calculatePriceForMaterial(
            material,
            baseArea,
            basePerimeter,
            requiredBaseSheetsRounded, // this was set at 0.5 sheets
            1
          )

          o.total += baseCost.total

          // Update material usage
          m.areaWithWastage += baseArea
          m.perimeter += basePerimeter
          m.itemCount += 1
        }

        // Back (priced as external costs * external markup)
        if (material.id === pantry.back) {
          const backMaterialSheetCost = Materials.getSheetCostFromProperties(
            material.value,
            material.w,
            material.h,
            6,
            material.sides
          )

          const sheetUsage = ((pantry.w * pantry.h) / (1220 * 1220)) * 1.5
          const backCost = utils.toFixedNumber(
            (backMaterialSheetCost * sheetUsage +
              ((pantry.w * pantry.h * 2) / 1000000) * store.state.pricing.costs.lacquerPerM2.value) *
              store.state.pricing.ukMultiplier.externalMarkup.value,
            2
          )
          o.total += backCost

          m.areaWithWastage += pantry.w * pantry.h
          m.perimeter += (pantry.w + pantry.h) * 2
          m.itemCount += 1
        }
      })
    })
    o.total *= exchangeRate * store.state.pricing.ukMultiplier.pantryMarkup.value
    o.total = utils.toFixedNumber(o.total, 2)
    return o
  }
}

function calculateUrtilDoorCost(material, urtil, doorNum) {
  const materialCost = Materials.getSheetCostFromProperties(
    material.value,
    material.w,
    material.h,
    material.hasGrain ? 9 : 6,
    material.sides
  )

  let doorArea
  let doorPerimeter
  if (doorNum === 1) {
    doorArea = (urtil.w / urtil.compartments) * urtil.doorCoverageOne * urtil.h
    doorPerimeter = ((urtil.w / urtil.compartments) * urtil.doorCoverageOne + urtil.h) * 2
  } else {
    doorArea = (urtil.w / urtil.compartments) * urtil.doorCoverageTwo * urtil.h
    doorPerimeter = ((urtil.w / urtil.compartments) * urtil.doorCoverageTwo + urtil.h) * 2
  }

  const sheetUsage = (doorArea / (material.w * material.h)) * 1.5

  //add-on cost for reeded glass
  const radCorner = 1.15 * 4
  const polishHandle = 29.39
  const waterJetHole = 4.09
  const polishPerMetre = 1.98
  const feltRunners = 5
  const perimeterPolishInMetre = (doorPerimeter / 1000) * polishPerMetre
  const addOnCost = radCorner + polishHandle + waterJetHole + feltRunners + perimeterPolishInMetre

  let doorCost
  if (material.hasGrain) {
    doorCost =
      (materialCost * sheetUsage + ((doorArea * 2) / 1000000) * store.state.pricing.costs.lacquerPerM2.value) *
      store.state.pricing.ukMultiplier.externalMarkup.value
  } else if (material.value === 'frostedGlass' || material.value === 'clearGlass') {
    doorCost = materialCost * sheetUsage * store.state.pricing.ukMultiplier.externalMarkup.value + addOnCost
  } else {
    doorCost = materialCost * sheetUsage * store.state.pricing.ukMultiplier.externalMarkup.value
  }
  return utils.toFixedNumber(doorCost, 2)
}
