const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class SRGHandle {
  constructor(width, height) {
    this.models = {}
    this.handleWidth = parseFloat(width) + 38
    this.handleHeight = parseFloat(height)

    this.handleCornerRadius = 4
    this.screwHoleRadius = 1.5

    this.screwHoleOffset = 5
    this.screwHoleYSpacing = 39

    // calculate spacing and hole count
    this.screwHoleCount = null
    this.screwHoleSpacing = null
    switch (this.handleWidth) {
      case 178:
        this.screwHoleCount = 3
        this.screwHoleXSpacing = 84
        break
      case 238:
        this.screwHoleCount = 4
        this.screwHoleXSpacing = 76
        break
      case 438:
        this.screwHoleCount = 6
        this.screwHoleXSpacing = 85.6
        break
    }

    this.generateSRGHandle()
  }

  generateSRGHandle() {
    this.models.outline = {
      layer: layerNames.insertCutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.handleWidth, 0]
        },
        line2: {
          type: 'line',
          origin: [this.handleWidth, 0],
          end: [this.handleWidth, this.handleHeight]
        },
        line3: {
          type: 'line',
          origin: [this.handleWidth, this.handleHeight],
          end: [0, this.handleHeight]
        },
        line4: {
          type: 'line',
          origin: [0, this.handleHeight],
          end: [0, 0]
        }
      }
    }

    // make fillets
    this.models.outline.paths.arc1 = makerjs.path.fillet(
      this.models.outline.paths.line1,
      this.models.outline.paths.line2,
      this.handleCornerRadius
    )
    this.models.outline.paths.arc2 = makerjs.path.fillet(
      this.models.outline.paths.line4,
      this.models.outline.paths.line1,
      this.handleCornerRadius
    )

    // add screw holes
    const screwHolesPos = []
    for (let i = 0; i < this.screwHoleCount; i++) {
      screwHolesPos.push([this.screwHoleOffset + this.screwHoleXSpacing * i, this.screwHoleOffset])
    }
    screwHolesPos.push([screwHolesPos[0][0], screwHolesPos[0][1] + this.screwHoleYSpacing])
    screwHolesPos.push([
      screwHolesPos[this.screwHoleCount - 1][0],
      screwHolesPos[this.screwHoleCount - 1][1] + this.screwHoleYSpacing
    ])
    this.models.screwHoles = new makerjs.models.Holes(this.screwHoleRadius, screwHolesPos)
    this.models.screwHoles.layer = layerNames.insertCountersink.label

    // joined SRG
    if (this.handleHeight === 101) {
      this.models.outline.paths.arc3 = makerjs.path.fillet(
        this.models.outline.paths.line2,
        this.models.outline.paths.line3,
        this.handleCornerRadius
      )
      this.models.outline.paths.arc4 = makerjs.path.fillet(
        this.models.outline.paths.line3,
        this.models.outline.paths.line4,
        this.handleCornerRadius
      )

      this.models.mirrorHoles = makerjs.model.mirror(this.models.screwHoles, false, true)
      makerjs.model.moveRelative(this.models.mirrorHoles, [0, this.handleHeight])

      this.models.shadowLine = {
        layer: layerNames.shadowLine.label,
        paths: {
          line1: {
            type: 'line',
            origin: [-2, this.handleHeight / 2],
            end: [this.handleWidth + 2, this.handleHeight / 2]
          }
        }
      }
    }
  }
}
