import productData from '@/js/productData'
import utils from '@/js/utils'
import store from '@/store'
import Materials from '@/js/Materials'
import MasterPricing from '@/js/pricing/MasterPricing'

const mmPerFoot = 304.8
const sqMmPerSqFoot = 92903.04

export default {
  fronts(items, materials, handleOptions) {
    let legacyHandle = false

    let o = {
      cost: 0,
      total: 0,
      incomplete: false,
      notifications: [],
      details: []
    }

    if (!items) {
      return o
    }

    let numHandles = 0
    let handleArea = 0
    let handlesCost = 0
    let lucidHandlesCost = 0
    let handlesPerimeterFt = 0
    let jHandlesPerimeterFt = 0

    for (let material of materials) {
      // ignore any incomplete materials
      if (!material.value || !material.w || !material.h || !material.thickness) continue

      let area = 0
      let perimeter = 0
      let itemCount = 0
      let handle = null

      // FRONT ITEMS (18mm)
      let frontsUsingMaterial = items.filter(item => {
        return item.material === material.id
      })

      for (let item of frontsUsingMaterial) {
        // ignore any imcomplete items
        if (item.itemNum == undefined || item.w == undefined || item.h == undefined || item.qty == undefined) {
          o.incomplete.push('Front: ' + item.itemNum)
          continue
        }

        const width = parseInt(item.w)
        const height = parseInt(item.h)
        //change qty for corner doors
        const qty = parseInt(item.qty)

        let frontTypeArray =
          item.frontType && item.frontType.includes('-') ? item.frontType.split('-') : [item.frontType]
        let hingeType = frontTypeArray[0]
        let cabinetType = frontTypeArray[1]
        let specialType = frontTypeArray.length == 3 ? frontTypeArray[2] : null

        // change area calc for corner doors
        const cornerNarrowFillerWidth = cabinetType && cabinetType == 'w' ? 102 : 51
        const cornerWideFillerWidth = cabinetType && cabinetType == 'w' ? 102 : 70
        area +=
          specialType == 'cnr'
            ? width * height + cornerNarrowFillerWidth * height + cornerWideFillerWidth * height
            : width * height * qty
        itemCount += qty

        // find handle and make it global
        if (item.handle) {
          handle = handleOptions.find(handle => {
            return handle.id == item.handle
          })
        }

        if (item.frontType) {
          // adding LINEAR FEET from door features

          const hingeLinearMM = 161.79
          const drwHoleLinearMM = 31.42
          const fourHingeDoorDimensions = { w: 606.6, h: 1521 }
          const tenHoleDrwDimensions = { w: 378, h: 759 } // check if width has minus shadowGap
          const centerHingeThreshold = 1267

          // set basic perimeter from width + height of door
          perimeter +=
            specialType == '2part'
              ? (width + height) * 2 * 2 * qty
              : specialType == 'cnr'
              ? (width + height) * 2 + (cornerNarrowFillerWidth + height) * 2 + (cornerWideFillerWidth + height) * 2
              : (width + height) * 2 * qty

          if (!['pax', 'paxsplit'].includes(specialType)) {
            if (hingeType.includes('hd')) {
              if (height < centerHingeThreshold) {
                if (!['custom', 'a', '2part', 'cnr'].includes(specialType)) {
                  perimeter += hingeLinearMM * 2 * qty
                } else if (specialType == '2part') {
                  perimeter += hingeLinearMM * 2 * 2 * qty
                } else if (specialType == 'cnr') {
                  perimeter += hingeLinearMM * 2
                }
              } else if (height >= centerHingeThreshold) {
                if (height == fourHingeDoorDimensions.h && width == fourHingeDoorDimensions.w) {
                  perimeter += hingeLinearMM * 4 * qty
                } else {
                  perimeter += hingeLinearMM * 3 * qty
                }
                // super tall doors has extra 2 hinges
                if ((width == 606.6 && height == 1521) || height == 1797 || height == 1997) {
                  perimeter += hingeLinearMM * 2 * qty
                }
              }
            } else if (hingeType.includes('drw')) {
              let holeCount = null
              if (width != tenHoleDrwDimensions.w) {
                if (height <= 100) {
                  holeCount = 2
                } else if (height <= 165.1) {
                  holeCount = 4
                } else if (height <= 212.725) {
                  holeCount = 5
                } else {
                  holeCount = 6
                }
              } else {
                if (height == tenHoleDrwDimensions.h) {
                  holeCount = 10
                }
              }
              perimeter += holeCount * drwHoleLinearMM * 2 * qty
            }
          } else {
            // US pax
            let hingeCount = null
            if (specialType == 'pax') {
              switch (height) {
                case 1944:
                case 1999:
                  hingeCount = 3
                  break
                case 2296:
                case 2351:
                  hingeCount = 4
                  break
              }
            } else if (specialType == 'paxsplit') {
              switch (height) {
                case 861:
                case 925:
                case 916:
                case 980:
                  hingeCount = 1
                  break
                case 1080:
                  hingeCount = 2
                  break
                case 1368:
                  hingeCount = 3
                  break
              }
            }
            perimeter += hingeCount * hingeLinearMM * qty
          }

          // joined - add metal bracket linear mm if not j-prof
          if (item?.joiningPos && handle.value != 'j') {
            perimeter += 223.4 * qty
          }

          // also add avg engraving (in mm)
          perimeter += 20 * qty
        } else if (item.coverPanelType) {
          perimeter += (width + height) * 2 * qty
        }

        if (handle && item.handle) {
          let masterHandle = MasterPricing.getHandle(handle.value)
          let handleQty = specialType == 'cnr' ? 1 : qty

          if (masterHandle) {
            // do not count for J-Profile (only JProf has insertLinearFtCost)
            if (!masterHandle.insertLinearFtCost) {
              // and also for invisipull - when adding handlesPerimeterFt
              if (!['j', 'invisipull', 'invisipull-short'].includes(handle.value)) {
                handlesPerimeterFt += masterHandle.insertAddLinearFt * handleQty
              }
              // unit cost for INSERT handles (and not J-Prof)
              if (
                [
                  'semiGrab',
                  'midSemiGrab',
                  'lrgSemiGrab',
                  'halfArc',
                  'lrgHalfArc',
                  'quartArc',
                  'lrgQuartArc',
                  'semiCir'
                ].includes(handle.value)
              ) {
                numHandles += handleQty
                // split lucidHandlesCost; what Lucid charges plykea
                lucidHandlesCost += masterHandle.costUS * handleQty
                // what plykea charges client
                handlesCost += masterHandle.costUS * handleQty
              }
            }
          } else {
            if (legacyHandle === false) {
              o.notifications.push('Discontinued handle needs to be updated')
              legacyHandle = true
            }
          }

          // add linear ft cost for j-profile handle
          let insertLinearFt = null
          let insertHeightFt = null
          let handleAreaSqFt = null

          if (handle.value == 'j') {
            switch (handle.orientation) {
              case 'h':
                // horizontal j-profile
                insertLinearFt = width / mmPerFoot // in ft
                handleAreaSqFt = (width * masterHandle.insertHeightValue) / sqMmPerSqFoot // in sq ft
                break
              case 'v':
                // vertical j-profile
                insertLinearFt = height / mmPerFoot // in ft
                handleAreaSqFt = (height * masterHandle.insertHeightValue) / sqMmPerSqFoot // in sq ft
                break
            }

            // split lucidHandlesCost to Lucid pricing; what Lucid charges Plykea
            lucidHandlesCost += insertLinearFt * masterHandle.insertLinearFtCost * handleQty

            // handlesCost is what Plykea charges clients
            handlesCost +=
              insertLinearFt *
              masterHandle.insertLinearFtCost *
              handleQty *
              store.state.pricing.usMultiplier.jHandles.value

            // add handles linear feet for just jHandle
            jHandlesPerimeterFt += insertLinearFt * handleQty
            // add handles linear feet to TOTAL
            handlesPerimeterFt += (3 * insertLinearFt + 0.1) * handleQty
          } else if (handle.value == 'invisipull') {
            // add handles linear feet
            handlesPerimeterFt += (width / mmPerFoot + 0.2) * handleQty
          } else if (handle.value == 'invisipull-short') {
            // add handles linear feet
            handlesPerimeterFt += (width / mmPerFoot) * handleQty
          } else {
            insertLinearFt =
              masterHandle.insertWidthUnit == 'mm'
                ? masterHandle.insertWidthValue / mmPerFoot
                : (width / mmPerFoot) * masterHandle.insertWidthValue
            insertHeightFt =
              masterHandle.insertHeightUnit == 'mm'
                ? masterHandle.insertHeightValue / mmPerFoot
                : (height / mmPerFoot) * masterHandle.insertHeightValue
          }

          // let insertMaterial = materials.find(material => material.thickness == 4 && material.id == handle.insert)

          if (insertLinearFt && masterHandle.insertLinearFtCost) {
            handleArea += handleAreaSqFt * handleQty
          } else if (insertLinearFt && insertHeightFt) {
            handleArea += insertHeightFt * insertLinearFt * handleQty
          }
        }
      }

      // wastage
      let wastage = material.continuousGrain === true ? productData.usContinuousGrainWastage : productData.usWastage

      let m = (o[material.id] = {})
      m.name = material.colourCode ? material.colourCode : material.value
      m.area = handleArea > 0 && material.thickness == 4 ? handleArea * sqMmPerSqFoot : area
      m.areaWithWastage = area * wastage
      // perimeter here will be in mm (will switch back to ft when calculating totals)
      m.perimeter = handlesPerimeterFt * mmPerFoot + perimeter
      // j-handle linear ft
      m.jHandlesPerimeterFt = jHandlesPerimeterFt
      m.numHandles = numHandles
      m.sheetArea = material.w * material.h
      m.requiredSheets = m.areaWithWastage / m.sheetArea
      m.requiredSheetsRounded = Math.ceil(m.requiredSheets)
      m.itemCount = material.thickness == 4 ? numHandles : itemCount
      m.total = 0

      // adjust sheet usage for continuous grain (if sheet usage modulus is greate than 0.5 add another sheet)
      if (material.continuousGrain) {
        m.requiredSheetsRounded += Math.round(m.requiredSheets % 1)
      }

      // split lucidHandlesCost; what Lucid charges plykea
      m.lucidHandlesCost = material.thickness == 4 ? lucidHandlesCost : 0
      // handlesCost is what Plykea charges clients
      m.handlesCost = material.thickness == 4 ? handlesCost * store.state.pricing.usMultiplier.generalHandles.value : 0

      let sheetCost = Materials.getSheetCostFromProperties(
        material.value,
        material.w,
        material.h,
        material.thickness,
        material.sides
      )

      if (!sheetCost) continue

      if (sheetCost === false) {
        material = null
        sheetCost = 0
      }

      // total for 4mm handles
      if (m.area > 0 && m.handlesCost > 0 && material.thickness == 4) {
        m.cost = utils.toFixedNumber(m.handlesCost, 2)
        m.sheetCost = 0 // no 4mm material sheet cost
        m.total = m.cost
      }
      // total for 18mm fronts
      else {
        m.id = material.id
        m.size = material.size
        m.sheetCost = utils.toFixedNumber(sheetCost, 2)
        m.materialCost = utils.toFixedNumber(m.requiredSheetsRounded * sheetCost, 2)
        m.programmingCost = utils.toFixedNumber(
          m.requiredSheetsRounded * store.state.pricing.usCosts.programmingPerSheet.value,
          2
        )
        m.cuttingCost = utils.toFixedNumber(
          (m.perimeter / mmPerFoot) * store.state.pricing.usCosts.cuttingPerFoot.value,
          2
        )
        m.edgeFinishingCost = utils.toFixedNumber(
          (m.perimeter / mmPerFoot) * store.state.pricing.usCosts.edgeFinishPerFoot.value,
          2
        )
        m.surfaceFinishingCost = utils.toFixedNumber(
          material && material.hasGrain
            ? (m.area / sqMmPerSqFoot) * store.state.pricing.usCosts.faceFinishPerSqFoot.value
            : 0,
          2
        )
        m.finishingCost = utils.toFixedNumber(
          material.unfinished
            ? (m.edgeFinishingCost + m.surfaceFinishingCost) * 0.2
            : m.edgeFinishingCost + m.surfaceFinishingCost,
          2
        )
        // FIXED COST PER PACK
        let packagingCost = utils.toFixedNumber(store.state.pricing.usCosts.packAndCrateFixedCost.value, 2)

        // PACK AND CRATE PER SQUARE FOOT
        m.packagingPerSqFt = utils.toFixedNumber(
          (m.area / sqMmPerSqFoot) * store.state.pricing.usCosts.packAndCratePerSqFoot.value,
          2
        )

        m.cost = utils.toFixedNumber(m.materialCost + m.programmingCost + m.cuttingCost + m.finishingCost, 2)
        m.total = utils.toFixedNumber(
          m.cost * store.state.pricing.usMultiplier.lucidMarkup.value + packagingCost + m.packagingPerSqFt,
          2
        )
      }

      o.details.push(m)
      o.cost = utils.toFixedNumber(o.cost + m.cost, 2)
      o.total = utils.toFixedNumber(o.total + m.total, 2)
    }
    return o
  },

  worktops(o, version, notifications) {
    let skuWarning = false

    for (var i = 0; i < version.worktops.length; i++) {
      var w = version.worktops[i]
      if (w.thickness && w.material && w.size && w.faces) {
        const usWorktops = store.state.worktops.filter(o => {
          return o.region === 'us'
        })
        const worktopMaterial = usWorktops.find(o => {
          return o.value === w.material
        })
        const worktop = worktopMaterial.sizes.find(o => {
          return o.thickness === w.thickness && o.sided === w.faces && o.size === w.size
        })

        let cutCost = store.state.pricing.usWorktopCosts.usWorktopCutoutCost.value
        let finishCost = store.state.pricing.usWorktopCosts.usWorktopFinishCost.value
        let usWorktopMultiplier = store.state.pricing.usWorktopCosts.usWorktopMultiplier.value

        var cost = worktop.cost + cutCost + finishCost
        var total = cost * usWorktopMultiplier

        o.cost += cost
        o.total += total

        o.debug.items.push({
          code: w.thickness + '-' + w.material + '-' + w.size + '-' + w.faces,
          cost: cost,
          total: total,
          data: worktop
        })
      } else {
        if (!skuWarning) {
          notifications.push('Unable to create SKU for worktop. Check all fields are complete.')
          skuWarning = true
        }
      }
    }
  }
}
