var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class MetalJoiningBracket {
  constructor(width, height, radius) {
    this.models = {}

    this.models.bracket = new makerjs.models.Dome(width, height, radius)

    this.models.bracket.layer = layerNames.joiningBracket.label
  }
}
