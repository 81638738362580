<template>
  <v-card>
    <v-toolbar color="primary" dark class="elevation-0">
      <v-toolbar-title>Lead Times</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="align-center">
        <v-select hide-details v-model="selectedRegion" :items="regions"> </v-select>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :loading="loading" :headers="headers" :items="regionalLeadTimes" hide-default-footer> </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'LeadTimes',
  data() {
    return {
      loading: true,
      leadTimes: null,
      regions: [],
      selectedRegion: 'uk',
      headers: [
        {
          text: 'Item',
          value: 'item',
          sortable: false
        },
        {
          text: 'Lead',
          value: 'lead',
          sortable: false
        },
        {
          text: 'Date W/C',
          value: 'deliveryDate',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  computed: {
    regionalLeadTimes() {
      var a = []
      if (this.regions.length > 0) {
        var region = this.leadTimes[this.selectedRegion]
        var regionKeys = Object.keys(region)

        for (var i = 0; i < regionKeys.length; i++) {
          var deliveryDate =
            parseInt(this.$moment().format('d')) > 5
              ? this.$moment()
                  .startOf('isoWeek')
                  .add(region[regionKeys[i]] + 1, 'weeks')
              : this.$moment()
                  .startOf('isoWeek')
                  .add(region[regionKeys[i]], 'weeks')

          a.push({
            item: regionKeys[i].charAt(0).toUpperCase() + regionKeys[i].slice(1),
            lead: region[regionKeys[i]] + ' weeks',
            deliveryDate: deliveryDate.format('Do MMM') + '  / WK ' + deliveryDate.week()
          })
        }
      }
      return a
    }
  },
  async created() {
    const settings = await this.$db
      .collection('settings')
      .doc('mPPqOiiHvphomjk1qrI1')
      .get()
    this.leadTimes = settings.data().leadTimes
    this.regions = Object.keys(this.leadTimes)
    this.loading = false
  }
}
</script>
