<template>
  <v-container fluid>
    <v-row wrap>
      <v-col cols="12" md="8">
        <QuotePeriodOverview class="mb-3"></QuotePeriodOverview>
        <UsQuotePeriodOverview></UsQuotePeriodOverview>
      </v-col>
      <v-col cols="12" md="4">
        <LeadTimes class="mb-3"></LeadTimes>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsQuotePeriodOverview from '@/components/dash/UsQuotePeriodOverview'
import QuotePeriodOverview from '@/components/dash/QuotePeriodOverview'
import LeadTimes from '@/components/dash/LeadTimes'

export default {
  name: 'dash',
  data() {
    return {}
  },
  components: {
    QuotePeriodOverview,
    LeadTimes,
    UsQuotePeriodOverview
  },
  created() {
    this.$numeral.locale('GBP')
  }
}
</script>
