<template>
  <v-form>
    <h3>Contact Information</h3>
    <div class="form-group">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.firstName"
            label="First Name"
            v-validate="'required'"
            data-vv-name="First Name"
            data-vv-validate-on="blur"
            :error-messages="errors.collect('First Name')"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.lastName"
            label="Last Name"
            v-validate="'required'"
            data-vv-name="Last Name"
            data-vv-validate-on="blur"
            :error-messages="errors.collect('Last Name')"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-model="form.email"
        label="Customer Email"
        v-validate="'required|email'"
        data-vv-name="Customer Email"
        data-vv-validate-on="blur"
        :error-messages="errors.collect('Customer Email')"
        required
      >
      </v-text-field>
      <v-row v-if="region === 'uk'">
        <v-col cols="11">
          <v-text-field
            ref="mobileInput"
            v-model="form.mobile"
            label="Mobile Number"
            type="tel"
            v-validate="'required|mobile'"
            data-vv-name="Mobile Number"
            data-vv-validate-on="blur"
            :error-messages="errors.collect('Mobile Number')"
          >
          </v-text-field>
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
            </template>
            <span>Required to send SMS updates when your order is dispatched</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <h3 class="mt-4">Delivery Addess</h3>
    <div class="form-group">
      <v-text-field
        v-model="form.deliveryAddressLine1"
        label="Address Line 1"
        v-validate="'required'"
        data-vv-name="Delivery Address Line 1"
        data-vv-validate-on="blur"
        :error-messages="errors.collect('Delivery Address Line 1')"
        required
      >
      </v-text-field>
      <v-text-field v-model="form.deliveryAddressLine2" label="Address Line 2"> </v-text-field>
      <v-text-field
        v-model="form.deliveryPostcode"
        label="Postcode"
        v-validate="region === 'uk' ? 'required|postcode' : 'required'"
        data-vv-name="Delivery Postcode"
        data-vv-validate-on="blur"
        :error-messages="errors.collect('Delivery Postcode')"
        required
      >
      </v-text-field>
      <v-autocomplete
        v-if="region != 'uk'"
        v-model="form.deliveryCountry"
        :items="$utils.shippingCountryCodes"
        label="Country"
        v-validate="'required'"
        data-vv-name="Delivery Country"
        data-vv-validate-on="blur"
        :error-messages="errors.collect('Delivery Country')"
        :autocomplete="uid(10)"
      >
      </v-autocomplete>
    </div>
    <h3 class="mt-4">Delivery Date</h3>
    <div class="form-group">
      <div v-if="estimatedShippingMoment">
        <p class="mt-2">
          Your order will automatically be assigned a delivery date of week commencing
          {{
            estimatedShippingMoment
              .clone()
              .startOf('week')
              .format('dddd Do MMMM')
          }}. If you require a <b>later</b> delivery date please let us know.
        </p>
        <v-dialog v-model="datePicker" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="!estimatedShippingMoment"
              :value="preferredDeliveryDate ? $moment(preferredDeliveryDate).format('dddd Do MMM YYYY') : null"
              :label="estimatedShippingMoment ? 'Preferred delivery date' : 'Calculating shipping date...'"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="preferredDeliveryDate"
            scrollable
            :min="estimatedShippingMoment.toISOString()"
            @change="datePicker = false"
            no-title
          ></v-date-picker>
        </v-dialog>
      </div>
      <div v-else class="pt-2">
        <v-progress-circular indeterminate :size="20" :width="2" class="mr-1 mb-1"></v-progress-circular>
        Calculating shipping date...
      </div>
    </div>
    <div class="mt-5 d-flex justify-end">
      <v-btn color="accent" depressed class="mx-0" @click="submit">{{
        foc ? 'Place Order' : 'Continue to Payment'
      }}</v-btn>
    </div>
  </v-form>
</template>

<script>
import uid from 'uid'
import '@/js/validators'
import { parsePhoneNumber } from 'awesome-phonenumber'

export default {
  name: 'ContactInformationEU',
  props: ['form', 'foc', 'region', 'version'],
  data() {
    return {
      uid: uid,
      parsePhoneNumber: parsePhoneNumber,
      datePicker: false,
      preferredDeliveryDate: null,
      estimatedShippingMoment: null
    }
  },
  async created() {
    const getLeadTimeForVersionFunc = this.$firebase.functions().httpsCallable('getLeadTimeForVersion')
    const leadTimeForVersion = await getLeadTimeForVersionFunc({
      region: this.region,
      version: this.version
    })
    this.estimatedShippingMoment = this.$moment().add(leadTimeForVersion.data, 'weeks')
  },
  methods: {
    async submit() {
      if (this.region === 'uk') {
        this.form.deliveryCountry = 'GB'
      }
      if (this.preferredDeliveryDate) {
        this.form.preferredDeliveryDate = this.$utils.timestampFromVuetify(this.preferredDeliveryDate)
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.form.email = this.form.email.toLowerCase()
          if (this.region === 'uk') {
            const pn = this.parsePhoneNumber(this.form.mobile, 'GB')
            this.form.mobile = pn.getNumber('e164')
          }
          this.$emit('success')
        }
      })
    }
  }
}
</script>
