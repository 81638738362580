var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class JBracket {
  constructor(width) {
    // common
    this.models = {}
    this.width = width

    this.pocketHeight = 31.5

    // unique
    this.outerRadius = 1
    this.pocketOffsetSmall = 5

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.pocket = new makerjs.models.Rectangle(this.width + 10, -this.pocketHeight)
    makerjs.model.move(this.models.pocket, [-5, 5])
    this.models.pocket.layer = layerNames.cbHandlePocket.label
  }
}
