var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"production-table"}},[_c('div',{staticClass:"table-header"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":true},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1),_c('v-data-table',{attrs:{"items":_vm.orders,"headers":_vm.headers,"search":_vm.tableSearch,"loading":_vm.loading,"no-data-text":_vm.noDataText,"items-per-page":-1,"hide-default-footer":"","sort-by":"dateCreated","sort-desc":"","must-sort":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$utils.relativeTime(props.item.dateCreated)))]),_c('td',[_vm._v(_vm._s(props.item.orderNumber))]),_c('td',[_vm._v(_vm._s(props.item.customerContactDetails.email))]),_c('td',[_vm._v(_vm._s(props.item.creatorEmail || 'n/a'))]),_c('td',[_vm._v(" "+_vm._s(_vm.$utils.formatCurrency(props.item.currency, props.item.total))+" ")]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function () {
                    _vm.selectedOrder = props.item
                    _vm.paymentDialog = true
                    _vm.payment.date = _vm.$moment().format('YYYY-MM-DD')
                    _vm.payment.value = null
                  }}},on),[_vm._v("mdi-currency-usd")])]}}],null,true)},[_c('span',[_vm._v("Manually add payment")])]),(_vm.$store.state.user.permissions.admin)?_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function () {
                    _vm.deleteOrderDialog = true
                    _vm.selectedOrder = props.item
                  }}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Order")])]):_vm._e()],1)])]}}])}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.paymentDialog),callback:function ($$v) {_vm.paymentDialog=$$v},expression:"paymentDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text title"},[_vm._v("Manually Add Payment")]),_c('v-card-text',{staticClass:"pt-6"},[_c('v-date-picker',{attrs:{"landscape":""},model:{value:(_vm.payment.date),callback:function ($$v) {_vm.$set(_vm.payment, "date", $$v)},expression:"payment.date"}}),(_vm.selectedOrder)?_c('v-text-field',{staticClass:"mt-3",attrs:{"label":"Amount","prefix":_vm.selectedOrder.region == 'us' ? '$' : '£'},model:{value:(_vm.payment.amount),callback:function ($$v) {_vm.$set(_vm.payment, "amount", $$v)},expression:"payment.amount"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.paymentDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.payment.amount,"depressed":"","dark":_vm.payment.amount != null},on:{"click":_vm.addPayment}},[_vm._v("Add Payment")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteOrderDialog),callback:function ($$v) {_vm.deleteOrderDialog=$$v},expression:"deleteOrderDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"warning white--text title"},[_vm._v("Delete Order")]),_c('v-card-text',{staticClass:"pt-6"},[_vm._v("Are you sure you want to delete this order?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteOrderDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"warning"},on:{"click":function($event){return _vm.deleteOrder(_vm.selectedOrder)}}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }