<template>
  <v-row class="align-center">
    <v-col cols="1" style="display: flex; align-items: center">
      <div class="d-flex flex-column mr-1">
        <v-icon small @click="$emit('rowUp', rowNum)">mdi-menu-up</v-icon>
        <v-icon small @click="$emit('rowDown', rowNum)">mdi-menu-down</v-icon>
      </div>
      <MegaCell :data.sync="data.name" :row="rowNum" :col="0" :dataType="'String'" placeholder="Name"></MegaCell>
    </v-col>
    <v-col cols="1">
      <MegaSelect
        :row="rowNum"
        :col="1"
        :options="urtilTypes"
        :data.sync="data.type"
        placeholder="Select type"
      ></MegaSelect>
    </v-col>
    <v-col cols="1" class="d-flex align-center">
      <div v-if="data.carcass" class="colour mr-2" :style="{ 'background-color': findMaterialHex(data.carcass) }"></div>
      <MegaSelect
        :row="rowNum"
        :col="2"
        :options="carcassMaterials"
        :data.sync="data.carcass"
        placeholder="Select carcass material"
        noOptions="No 18mm materials created"
      ></MegaSelect>
    </v-col>
    <v-col cols="1" class="d-flex align-center">
      <div v-if="data.back" class="colour mr-2" :style="{ 'background-color': findMaterialHex(data.back) }"></div>
      <MegaSelect
        :row="rowNum"
        :col="3"
        :options="backMaterial"
        :data.sync="data.back"
        placeholder="Select back material"
        noOptions="No single sided 6mm materials created"
      ></MegaSelect>
    </v-col>
    <v-col cols="2" class="d-flex">
      <v-icon @click="lightingDialog = true">mdi-lightbulb-on</v-icon>
      <v-icon @click="shelvingDialog = true" class="ml-1 pt-1">mdi-bookshelf</v-icon>
      <v-icon @click="trimmingDialog = true" class="ml-1 pt-1">mdi-hand-saw</v-icon>
      <v-icon v-if="grainRequired" @click="grainDialog = true" class="ml-1 pt-1">mdi-waves</v-icon>
      <v-icon @click="doorDialog = true" class="ml-1 pt-1">mdi-door</v-icon>
      <v-icon @click="preAssembledDialog = true" class="ml-2 pt-1">mdi-package-variant</v-icon>
    </v-col>
    <v-col cols="1">
      <MegaCell :data.sync="data.w" :row="rowNum" :col="5" :min="100" :max="materialDimensions.h"></MegaCell>
    </v-col>
    <v-col cols="1">
      <MegaCell :data.sync="data.h" :row="rowNum" :col="6" :min="100" :max="1000"></MegaCell>
    </v-col>
    <v-col cols="1">
      <MegaCell :data.sync="data.d" :row="rowNum" :col="7" :min="100" :max="650"></MegaCell>
    </v-col>
    <v-col cols="1" class="d-flex align-center">
      <div
        v-if="data.material1"
        class="colour mr-2"
        :style="{ 'background-color': findMaterialHex(data.material1) }"
      ></div>
      <MegaSelect
        v-if="data.type !== 'o'"
        :row="rowNum"
        :col="8"
        :options="doorMaterial"
        :data.sync="data.material1"
        placeholder="Select material"
        noOptions="No double sided 6mm materils created"
      ></MegaSelect>
      <div v-else class="caption text--disabled">N/A</div>
    </v-col>
    <v-col cols="1" class="d-flex align-center">
      <div
        v-if="data.material2"
        class="colour mr-2"
        :style="{ 'background-color': findMaterialHex(data.material2) }"
      ></div>
      <MegaSelect
        v-if="data.type == 't'"
        :row="rowNum"
        :col="9"
        :options="doorMaterial"
        :data.sync="data.material2"
        :placeholder="data.type == 't' ? 'Select material' : 'N/A'"
        noOptions="No double sided 6mm materils created"
      ></MegaSelect>
      <div v-else class="caption text--disabled">N/A</div>
    </v-col>
    <v-col class="d-flex" :order="wonky ? 'first' : 'last'" :class="[{ 'justify-end': !wonky }]" cols="1" v>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" @click="$emit('copy', rowNum)" v-on="on">mdi-content-copy</v-icon>
        </template>
        <span>Copy</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" @click="$emit('copyBottom', rowNum)" v-on="on">mdi-arrow-collapse-down</v-icon>
        </template>
        <span>Copy to bottom</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small @click="$emit('delete', rowNum)" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </v-col>

    <!-- Lighting Dialog -->
    <v-dialog v-model="lightingDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Lighting</v-card-title>
        <v-card-text class="pt-4">
          <p>
            Dimension should be inputted as the total sum of all lightings put together eg: "800" for 2 lights of 400.
            Total width of light must be at least 60mm less than Ürtil width (input is disabled without width).
          </p>
          <v-text-field
            type="number"
            :rules="[v => parseInt(v) < data.w - 60 || 'Must be less than width minus 60mm']"
            :disabled="!data.w"
            v-model.number="data.lighting"
            placeholder="Sum Total Light Dimension"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Shelving Dialog -->
    <v-dialog v-if="shelvingDialog" v-model="shelvingDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Shelving</v-card-title>
        <v-card-text class="pt-6">
          <v-row class="px-0" :style="{ 'border-bottom': 'none' }">
            <v-col cols="7">
              <v-select
                :items="data.trimming ? trimShelvingOptions : shelvingOptions"
                v-model="data.shelving"
                label="Shelving type"
                @change="resetShelvingOptions"
              ></v-select>

              <!-- Adjustable settings -->
              <div
                v-if="data.shelvingOptions !== null && (data.shelving === 'adjustable' || data.shelving === 'blank')"
              >
                <v-text-field
                  v-model.number="data.shelvingOptions.qty"
                  label="Number of shelves"
                  :min="1"
                  :rules="[v => parseInt(v) > 0 || 'Must be at least 1 shelf']"
                ></v-text-field>
              </div>
              <div v-if="data.shelvingOptions && data.shelving === 'fixed'">
                <div class="d-flex flex-column-reverse">
                  <div v-for="(shelf, key) of data.shelvingOptions.heights" class="d-flex align-end" :key="key">
                    <v-text-field
                      v-model.number="data.shelvingOptions.heights[key]"
                      :prefix="key === 0 ? 'Bottom Shelf (internal height)' : 'Shelf ' + (key + 1)"
                      :suffix="data.shelvingOptions.heights[key] ? 'mm' : null"
                      :disabled="key !== 0"
                      placeholder="Automatic"
                      hide-details
                      class="mr-3"
                    ></v-text-field>
                    <v-icon class="mt-3 mb-1" @click="removeShelf(key)">mdi-minus-circle</v-icon>
                  </div>
                </div>
                <v-icon class="mt-5" @click="addShelf">mdi-plus-circle</v-icon>
              </div>
            </v-col>
            <v-col cols="5" class="d-flex justify-end">
              <v-select
                v-model.number="data.compartments"
                :items="compartmentsOptions"
                label="Number of Compartments"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Trimming Dialog -->
    <v-dialog v-model="trimmingDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Trim To Fit</v-card-title>
        <v-card-text class="pt-4">
          <p>
            Input the width for the Ürtil as normal and 10mm tolerance will be added to that measurement. This allows
            the Ürtil to be trimmed on site should the opening not be exactly as expected.
            <v-switch
              v-model="data.trimming"
              :label="data.trimming ? 'added trim tolerance' : 'no trim tolerance'"
              @change="resetShelvingSelection"
            ></v-switch>
          </p>
          <p v-if="data.shelving === 'fixed'">
            WARNING! <br />
            Fixed Shelves do not work with Trim to Fit Urtils, it will be deselected if you enable Trimming.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Grain Direction Dialog -->
    <v-dialog v-model="grainDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Grain Direction</v-card-title>
        <v-card-text class="pt-4">
          <p class="font-weight-bold">
            Default Grain is = {{ grainDirection ? Object.values(grainDirection)[0] : 'N/A' }}
          </p>
          <p>
            Make this item have an opposing grain direction to the default.
            <v-switch
              v-model="data.opposingGrain"
              :label="
                data.opposingGrain
                  ? Object.values(grainDirection)[0] == 'Horizontal Grain'
                    ? 'Opposing Grain is = Vertical Grain'
                    : 'Opposing Grain is = Horizontal Grain'
                  : 'Default Grain'
              "
              :disabled="onlyHozAllowed || onlyVertAllowed"
            ></v-switch>
          </p>
          <p v-if="onlyHozAllowed">
            WARNING! <br />
            Item is too wide; it's not possible to have a Vertical grain.
          </p>
          <p v-if="onlyVertAllowed">
            WARNING! <br />
            Item is too tall; it's not possible to have a Horizontal grain.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Door Dialog -->
    <v-dialog v-model="doorDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Urtil Door Compartment Coverage</v-card-title>
        <v-card-text class="pt-4">
          <p>
            The selection will decide how much compartments each Urtil door will cover.
          </p>
          <v-col cols="10" class="d-flex justify-space-between" v-if="data.type !== 'o' && data.compartments > 0">
            <v-select
              v-model.number="data.doorCoverageOne"
              :items="coverageOptions"
              label="Door 1 Compartment Coverage"
            ></v-select>
            <v-switch
              class="ml-10"
              v-if="data.type !== 't'"
              v-model.number="data.singleDoorSide"
              :label="data.singleDoorSide ? 'Right Single Door' : 'Left Single Door'"
            ></v-switch>
          </v-col>
          <v-col cols="10" class="d-flex justify-end" v-if="data.type === 't' && data.compartments > 0">
            <v-select
              v-model.number="data.doorCoverageTwo"
              :items="coverageOptions"
              label="Door 2 Compartment Coverage"
            />
          </v-col>
          <p v-if="data.type === 'o'">
            WARNING! <br />
            No doors available.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Pre-Assembled Dialog -->
    <v-dialog v-model="preAssembledDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Pre-Assembled Delivery</v-card-title>
        <v-card-text class="pt-4">
          <v-alert v-if="disableUrtilPreAssembly" type="warning" class="d-block">
            Urtil Trim-Fit is always sent flat-packed.
          </v-alert>
          <p>
            When turned on, the pantry will be sent pre-assembled with the additional cost of £{{
              this.$store.state.pricing.costs.preAssemblyCostPerUrtil.value
            }}. Default is to be sent flat-packed.
            <v-switch
              v-model="data.preAssembled"
              :label="data.preAssembled ? 'pre-assembled' : 'sent flat-packed'"
              :disabled="disableUrtilPreAssembly"
            ></v-switch></p
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import productData from '@/js/productData'

import MegaSelect from '@/components/quoting/items/MegaSelect'
import MegaCell from '@/components/quoting/items/MegaCell'

export default {
  name: 'Urtil',
  props: ['rowNum', 'data', 'materials', 'wonky', 'grainDirection'],
  components: { MegaCell, MegaSelect },
  data() {
    return {
      compartmentsOptions: productData.optionsOfCompartmentsAllowed,
      urtilTypes: productData.urtilTypes,
      lightingDialog: false,
      shelvingDialog: false,
      trimmingDialog: false,
      grainDialog: false,
      doorDialog: false,
      preAssembledDialog: false,
      disableUrtilPreAssembly: false,
      centerDivideThreshold: 1000,
      shelvingOptions: [
        {
          text: 'Blank',
          value: 'blank'
        },
        {
          text: 'Adjustable',
          value: 'adjustable'
        },
        {
          text: 'Fixed',
          value: 'fixed'
        },
        {
          text: 'None',
          value: null
        }
      ],
      trimShelvingOptions: [
        {
          text: 'Blank',
          value: 'blank'
        },
        {
          text: 'Adjustable',
          value: 'adjustable'
        },
        {
          text: 'None',
          value: null
        }
      ],
      coverageOptions: []
    }
  },
  watch: {
    'data.w': function(val) {
      if (val > 1210 && Object.values(this.grainDirection)[0] === 'Horizontal Grain') {
        this.data.opposingGrain = false // cant be vert
      } else if (val > 1210 && Object.values(this.grainDirection)[0] !== 'Horizontal Grain') {
        this.data.opposingGrain = true // oppose vert or N/A, needs to be hoz
      }
      this.checkCompartments()
    },
    'data.h': function(val) {
      if (val > 1210 && Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        this.data.opposingGrain = false
      } else if (val > 1210 && Object.values(this.grainDirection)[0] !== 'Vertical Grain') {
        this.data.opposingGrain = true
      }
    },
    'data.trimming': function(val) {
      if (val) {
        this.data.preAssembled = false
        this.disableUrtilPreAssembly = true
      } else {
        this.disableUrtilPreAssembly = false
      }
    },
    'data.compartments': function() {
      this.setCoverageOptions()
      this.checkCompartments()
      this.setDoorCoverage()
    },
    'data.type': function() {
      this.setDoorCoverage()
    }
  },
  created() {
    // default urtil compartment will always be 1.
    if (!this.data.compartments) {
      this.data.compartments = 1
    }

    this.setCoverageOptions()
  },
  computed: {
    materialDimensions() {
      let o = { w: null, h: null }
      if (!this.data.carcass) return { w: 0, h: 0 }
      for (var i = 0; i < this.materials.length; i++) {
        if (this.data.carcass == this.materials[i].value) {
          o.w = this.materials[i].w - 40
          o.h = this.materials[i].h - 40
          break
        }
      }
      return o
    },
    carcassMaterials() {
      return this.materials.filter(material => {
        return material.thickness == 18
      })
    },
    backMaterial() {
      return this.materials.filter(material => {
        return material.thickness == 6 && (material.hasGrain || material?.sides == 1)
      })
    },
    doorMaterial() {
      return this.materials.filter(material => {
        return material.thickness == 6 && (material.hasGrain || material?.sides == 2)
      })
    },
    hasGrainBack() {
      const m = this.materials.find(m => {
        return m.value === this.data.back
      })
      return m?.hasGrain || false
    },
    onlyHozAllowed() {
      if (this.data.w > 1210 && Object.values(this.grainDirection)[0] === 'Horizontal Grain') {
        return true // must disable
      } else if (this.data.w > 1210 && Object.values(this.grainDirection)[0] !== 'Horizontal Grain') {
        return true // must disable
      } else {
        return false // else narrow
      }
    },
    onlyVertAllowed() {
      if (this.data.h > 1210 && Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        return true
      } else if (this.data.h > 1210 && Object.values(this.grainDirection)[0] !== 'Vertical Grain') {
        return true
      } else {
        return false
      }
    },
    grainRequired() {
      if (!this.hasGrainBack) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    resetShelvingSelection() {
      if (this.data.trimming && this.data.shelving === 'fixed') {
        this.data.shelving = null
        this.data.shelvingOptions = null
      }
    },
    resetShelvingOptions() {
      if (this.data.shelving === 'fixed') {
        this.data.shelvingOptions = {
          heights: [null],
          qty: 1
        }
      } else {
        this.data.shelvingOptions = {
          qty: 0
        }
      }
    },
    addShelf() {
      this.data.shelvingOptions.heights.push(null)
      this.data.shelvingOptions.qty = this.data.shelvingOptions.heights.length
    },
    removeShelf(key) {
      this.data.shelvingOptions.heights.splice(key, 1)
      this.data.shelvingOptions.qty = this.data.shelvingOptions.heights.length
    },
    setCoverageOptions() {
      if (this.data.compartments === 1) {
        this.coverageOptions = [0.5]
      } else {
        this.coverageOptions = []
        for (let i = 1; i < this.data.compartments; i++) {
          this.coverageOptions.push(i)
        }
      }
    },
    checkCompartments() {
      if (this.data.w > this.centerDivideThreshold && this.data.compartments === 1) {
        this.data.compartments = 2
      }
    },
    setDoorCoverage() {
      if (this.data.compartments === 1) {
        if (this.data.type === 's') {
          this.data.doorCoverageOne = 0.5
          this.data.doorCoverageTwo = null
        } else if (this.data.type === 't') {
          this.data.doorCoverageOne = 0.5
          this.data.doorCoverageTwo = 0.5
        } else {
          this.data.doorCoverageOne = null
          this.data.doorCoverageTwo = null
        }
      } else if (this.data.compartments === 2) {
        if (this.data.type === 's') {
          this.data.doorCoverageOne = 1
          this.data.doorCoverageTwo = null
        } else if (this.data.type === 't') {
          this.data.doorCoverageOne = 1
          this.data.doorCoverageTwo = 1
        } else {
          this.data.doorCoverageOne = null
          this.data.doorCoverageTwo = null
        }
      } else {
        this.data.doorCoverageOne = null
        this.data.doorCoverageTwo = null
      }
    },
    findMaterialHex(materialCode) {
      if (!materialCode) return []
      const match = this.materials.find(material => {
        return material.value === materialCode
      })
      return match.hex
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  border-bottom: solid 1px #eee;
  .col {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
::v-deep .v-text-field__prefix {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
}

.colour {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border: solid 1px #999;
  border-radius: 3px;
  margin-right: 12px;
}
</style>
