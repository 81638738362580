<template>
  <v-container fill-height fluid>
    <v-row justify="center" v-if="!loggedIn">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title class="primary white--text title">Mega Login</v-card-title>
          <v-alert :value="alert" class="mt-0" :type="alert.type">{{ alert.message }}</v-alert>
          <v-card-text v-if="alert.type !== 'success'">
            <v-form ref="form">
              <v-text-field
                v-model="form.email"
                label="Email"
                v-validate="'required|email'"
                data-vv-name="Email"
                data-vv-validate-on="blur"
                :error-messages="errors.collect('Email')"
                prepend-icon="mdi-account"
                required
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions v-if="alert.type !== 'success'">
            <v-btn block @click="validate" color="accent" class="elevation-0" :disabled="submitting"
              >{{ !submitting ? 'Login' : '' }}
              <v-progress-circular
                v-if="submitting"
                indeterminate
                color="primary"
                class="pa-2"
                :size="20"
                :width="3"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'auth',
  data() {
    return {
      loggedIn: false,
      submitting: false,
      alert: false,
      form: {
        email: null
      },
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please input a valid email address',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then(result => {
        if (result && !this.submitting) {
          this.submitting = true
          this.signIn()
        }
      })
    },
    async signIn() {
      const signInMethods = await this.$firebase.auth().fetchSignInMethodsForEmail(this.form.email)

      if (signInMethods.length > 0) {
        try {
          const sendLoginLink = this.$firebase.functions().httpsCallable('sendLoginLink')
          await sendLoginLink({
            forceLocalhost: false,
            email: this.form.email
          })
        } catch (error) {
          this.alert = {
            type: 'error',
            message: error
          }
          return false
        }

        window.localStorage.setItem('emailForSignIn', this.form.email)

        this.alert = {
          type: 'success',
          message: 'A login link has been sent to ' + this.form.email
        }
      } else {
        this.alert = {
          type: 'warning',
          message: 'No account exists for the email ' + this.form.email
        }
      }
    }
  }
}
</script>
