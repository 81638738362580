var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

import GrabHandle from '@/js/cad/production/handles/GrabHandle'
import SemiRecessedGrab from '@/js/cad/production/handles/SemiRecessedGrab'
import CirclePull from '@/js/cad/production/handles/CirclePull'
import SemiRecessedCircle from '@/js/cad/production/handles/SemiRecessedCircle'
import EdgePullRear from '@/js/cad/production/handles/EdgePullRear'
import JProfile from '@/js/cad/production/handles/JProfile'
import DPull from '@/js/cad/production/handles/DPull'

export default class TwoPartCornerDoor {
  constructor(front, cornerRadius, region) {
    this.models = {}
    this.region = region
    this.width = front.w === 338 ? 338 : parseInt(front.w)
    this.height = front.h === 762 ? 762 : parseInt(front.h)
    this.handle = front.handle
    this.type = front.type
    this.frontCornerRadius = cornerRadius
    this.cupDiameter = 35.5
    this.smallDrillDiameter = 5
    this.largeDrillDiameter = 8
    this.gap = 11
    this.topHingeExtraOffset = this.handle.value == 'j' ? 63.5 : 0
    this.cupPositions = [
      [23.5, 62],
      [23.5, this.height - 62 - this.topHingeExtraOffset],
      [this.width - 12.75, 62],
      [this.width - 12.75, this.height - 62 - this.topHingeExtraOffset]
    ]
    this.smallDrillPositions = [
      [315.75, 39.5],
      [315.75, 84.5],
      [315.75, this.height - 84.5 - this.topHingeExtraOffset],
      [315.75, this.height - 39.5 - this.topHingeExtraOffset],
      [this.width + this.gap + 28.5, 45.75],
      [this.width + this.gap + 28.5, 78.25],
      [this.width + this.gap + 28.5, this.height - 78.25 - this.topHingeExtraOffset],
      [this.width + this.gap + 28.5, this.height - 45.75 - this.topHingeExtraOffset]
    ]
    this.largeDrillPositions = [
      [33, 39.5],
      [33, 84.5],
      [33, this.height - 84.5 - this.topHingeExtraOffset],
      [33, this.height - 39.5 - this.topHingeExtraOffset]
    ]

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.outlineA = new makerjs.models.RoundRectangle(this.width, this.height, this.frontCornerRadius)
    this.models.outlineB = new makerjs.models.RoundRectangle(this.width, this.height, this.frontCornerRadius)
    makerjs.model.move(this.models.outlineB, [this.width + this.gap, 0])

    this.models.outlineA.layer = layerNames.cutout.label
    this.models.outlineB.layer = layerNames.cutout.label

    // do the cups
    if (this.height === 762) {
      // only if standard H30"
      this.models.cups = {
        models: {}
      }
      for (var i = 0; i < this.cupPositions.length; i++) {
        this.models.cups.models[i] = new makerjs.models.Ellipse(this.cupDiameter / 2, this.cupDiameter / 2)
        makerjs.model.move(this.models.cups.models[i], this.cupPositions[i])
        this.models.cups.models[i].layer = layerNames.hingeCup.label
      }
      // 5mm drill
      this.models.smallDrill = new makerjs.models.Holes(this.smallDrillDiameter / 2, this.smallDrillPositions)
      this.models.smallDrill.layer = layerNames.hingeDrillMedium.label

      // 8mm drill
      this.models.largeDrill = new makerjs.models.Holes(this.largeDrillDiameter / 2, this.largeDrillPositions)
      this.models.largeDrill.layer = layerNames.hingeDrillLarge.label
    }

    this.addHandle()
    if (this.type == 'rhd') {
      let poop = makerjs.model.mirror(this, true, false)
      this.models = poop.models
      makerjs.model.move(this, [this.width * 2 + this.gap, 0])
    }
  }

  addHandle() {
    let handleWidth = 140
    switch (this.handle.value) {
      case 'none':
        break
      case 'grab':
        this.models.handle = new GrabHandle(this.models)
        break
      case 'semiGrab':
        this.models.handle = new SemiRecessedGrab(this.models, handleWidth, this.region)
        break
      case 'circle':
        this.models.handle = new CirclePull(this.models)
        break
      case 'semiCircle':
      case 'semiCir':
        this.models.handle = new SemiRecessedCircle(this.models)
        break
      case 'edge-br-s-r':
      case 'edge-bl-s-r':
      case 'edge-w-s-r':
      case 'edge-sn-s-r':
      case 'edge-cp-s-r':
        this.models.handle = new EdgePullRear(this.models, 76)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(this.width, this.height)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        makerjs.model.move(this.models.boundingbox, [this.width + this.gap, 0])
        break
      case 'edge-br-l-r':
      case 'edge-bl-l-r':
      case 'edge-w-l-r':
      case 'edge-sn-l-r':
      case 'edge-cp-l-r':
        this.models.handle = new EdgePullRear(this.models, 201)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(this.width, this.height)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'j':
        this.models.handle = new JProfile(this.models, this.width, true)
        this.models.handleClone = makerjs.model.mirror(this.models.handle, true, false)
        this.models.handleOutlineB = makerjs.model.mirror(this.models.handleOutlineA, true, false)
        break
      case 'dpull-br-s':
      case 'dpull-bl-s':
      case 'dpull-w-s':
        this.models.handle = new DPull(this.models, 128, this.width, this.height)
        break
      case 'dpull-br-l':
      case 'dpull-bl-l':
      case 'dpull-w-l':
        this.models.handle = new DPull(this.models, 192, this.width, this.height)
        break
      default:
        console.log('handle type not implemented yet', this.handle.value)
    }

    this.rotateHandle()
    if (this.models.handle) {
      this.moveHandle()
    }

    // subtract any handle outline
    if (this.models.handleOutline) {
      makerjs.model.combineSubtraction(this.models.outlineB, this.models.handleOutline)
    }
    if (this.models.handleOutlineA) {
      makerjs.model.combineSubtraction(this.models.outlineA, this.models.handleOutlineA)
      makerjs.model.combineSubtraction(this.models.outlineB, this.models.handleOutlineB)
    }
  }

  rotateHandle() {
    var hinge = this.type
    var position = this.handle.orientation
    var rotation = 0

    // set the required rotation of the handle
    if (position == 'v') {
      if (hinge == 'lhd') {
        rotation = 270
      } else if (hinge == 'rhd') {
        rotation = -90
      }
    }

    // rotate the handle and it's outline
    if (this.models.handleOutline) makerjs.model.rotate(this.models.handleOutline, rotation, [0, 0])
    makerjs.model.rotate(this.models.handle, rotation, [0, 0])
  }

  moveHandle() {
    var xPos = 0
    var yPos = this.height - this.models.handle.insetY

    if (this.handle.orientation == 'h') {
      xPos = this.width * 2 + this.gap - (this.models.handle.width + this.models.handle.insetX)
      if (this.handle.value == 'j') {
        xPos = 0
        yPos = this.height
      }
    } else if (this.handle.orientation == 'c') {
      xPos = this.width + this.gap + this.width / 2 - this.models.handle.width / 2
    } else if (this.handle.orientation == 'v') {
      xPos = this.width * 2 + this.gap
      yPos -= this.models.handle.insetX
    }

    if (this.models.handleOutline) makerjs.model.move(this.models.handleOutline, [xPos, yPos])
    makerjs.model.move(this.models.handle, [xPos, yPos])

    if (this.handle.value == 'j') {
      makerjs.model.move(this.models.handleOutlineA, [-50, yPos])
      makerjs.model.move(this.models.handleOutlineB, [this.width * 2 + this.gap + 50, yPos])
      makerjs.model.move(this.models.handleClone, [this.width * 2 + this.gap, yPos])
    }

    if (this.handle.value.split('-').includes('dpull')) {
      if (this.type === 'lhd') {
        makerjs.model.move(this.models.handle.models.drill, [-50, 50])
      } else {
        let yAdjust = this.handle.value.split('-').includes('s') ? 128 - 50 : 192 - 50
        makerjs.model.move(this.models.handle.models.drill, [-50, -yAdjust])
      }
    }
  }
}
