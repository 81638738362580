<template>
  <v-card>
    <v-card-title class="text-h4">Schedule Information</v-card-title>
    <v-card class="ma-6 pa-5">
      <div class="text-h6 text-center">Weekly Data</div>
      <v-divider></v-divider>
      <v-container class="d-flex">
        <v-col col="4">
          <v-card-actions @click="showWeeklyCut = !showWeeklyCut" class="d-block">
            <v-card class="cut-expansion px-3 d-flex" fluid>
              Weekly Cut
              <v-spacer></v-spacer>
              <v-icon class="expand-icon">{{ showWeeklyCut ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-card>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="showWeeklyCut" class="mx-5">
              <v-divider></v-divider>
              <div class="d-flex justify-space-between text-body-2 mt-3">
                <div>Example</div>
                <div>No Data</div>
              </div>
            </div>
          </v-expand-transition>
        </v-col>
        <v-col col="4">
          <v-card-actions @click="showWeeklyFinish = !showWeeklyFinish" class="d-block">
            <v-card class="finish-expansion px-3 d-flex" fluid>
              Weekly Finish
              <v-spacer></v-spacer>
              <v-icon class="expand-icon">{{ showWeeklyFinish ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-card>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="showWeeklyFinish" class="mx-5">
              <v-divider></v-divider>
              <!-- WEEKLY FINISH INFO -->
              <div class="d-flex justify-space-between text-body-2 mt-3">
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Total Finishers</div>
                  </template>
                  <div class="text-caption">Weekly Total Available Finishers</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCap().weeklyPersons + ' head' }}</div>
              </div>
              <div class="d-flex justify-space-between text-body-2 mt-1">
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Total Work Hours</div>
                  </template>
                  <div class="text-caption">Weekly Total Available Hours</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCap().weeklyHours + ' hrs' }}</div>
              </div>
              <div class="d-flex justify-space-between text-body-2 mt-1">
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Total Mµ Capacity</div>
                  </template>
                  <div class="text-caption">Weekly Total Available Mµ</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCap().weekMuFinishCap + ' mµ' }}</div>
              </div>
              <div class="d-flex justify-space-between text-body-2 mt-1">
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Total Mµ Scheduled</div>
                  </template>
                  <div class="text-caption">Weekly Total Scheduled Mµ</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyMuFinishTotal() + ' mµ' }}</div>
              </div>
              <div class="d-flex justify-space-between text-body-2 mt-1">
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Avg Mµ Req Per Hour</div>
                  </template>
                  <div class="text-caption">Weekly Average Required Mµ / Total Hour</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCalcMu().avgMuPerHour + ' mµ/hr' }}</div>
              </div>
              <div class="d-flex justify-space-between text-body-2 mt-1">
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Avg Mµ Req Per Head</div>
                  </template>
                  <div class="text-caption">Weekly Average Required Mµ / Total Head</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCalcMu().avgMuPerHead + ' mµ/head' }}</div>
              </div>
              <div
                class="d-flex justify-space-between text-body-2 mt-1"
                :class="getWeeklyFinishCalcMu().rangeMuDiffPerHead > 10 ? 'red--text' : 'green--text'"
              >
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Range Mµ Difference</div>
                  </template>
                  <div class="text-caption">Weekly Range in Mµ Difference / Total Head per Day</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCalcMu().rangeMuDiffPerHead + ' mµ/head/day' }}</div>
              </div>
              <div
                class="d-flex justify-space-between text-body-2 mt-1"
                :class="getWeeklyFinishCalcMu().avgMuDiffPerHead < 0 ? 'red--text' : 'green--text'"
              >
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Avg Mµ Difference</div>
                  </template>
                  <div class="text-caption">Weekly Average Mµ Difference / Total Head per Day</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCalcMu().avgMuDiffPerHead + ' mµ/head/day' }}</div>
              </div>
              <div
                class="d-flex justify-space-between text-body-2 mt-1"
                :class="getWeeklyFinishCalcMu().avgMuWeeklyRem < 0 ? 'red--text' : 'green--text'"
              >
                <v-tooltip bottom :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <div class="data-title mr-2" v-on="on">Avg. Mµ Remainder</div>
                  </template>
                  <div class="text-caption">Weekly Average Mµ Remainder / Total Workdays</div>
                </v-tooltip>
                <div class="text-right">{{ getWeeklyFinishCalcMu().avgMuWeeklyRem + ' %' }}</div>
              </div>
            </div>
          </v-expand-transition>
        </v-col>
        <v-col col="4">
          <v-card-actions @click="showWeeklyPack = !showWeeklyPack" class="d-block">
            <v-card class="pack-expansion px-3 d-flex" fluid>
              Weekly Pack
              <v-spacer></v-spacer>
              <v-icon class="expand-icon">{{ showWeeklyPack ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-card>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="showWeeklyPack" class="mx-5">
              <v-divider></v-divider>
              <div class="d-flex justify-space-between text-body-2 mt-3">
                <div>Example</div>
                <div>No Data</div>
              </div>
            </div>
          </v-expand-transition>
        </v-col>
      </v-container>
    </v-card>
    <div class="d-lg-flex flex-lg-row align-baseline justify-center flex-sm-column mx-5">
      <v-container v-for="(day, date) in tasksByDay" :key="date" class="px-0">
        <div class="mx-2">
          <v-card class="px-3 py-5 elevation-5">
            <div class="text-body-2 ml-5" :class="$utils.datePPF(date)">
              {{ $moment(date).format('ddd') }}
            </div>
            <div class="d-flex align-baseline pb-1 ml-5">
              <div class="text-h4" :class="$utils.datePPF(date)">
                {{ $moment(date).format('D') }}
              </div>
            </div>
            <!-- FINISH DAILY INFO -->
            <v-card-actions @click="showFinish = !showFinish">
              <v-col col="12">
                <v-card class="finish-expansion px-3 d-flex" fluid>
                  Finish
                  <v-spacer></v-spacer>
                  <v-icon class="expand-icon">{{ showFinish ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-card>
              </v-col>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="showFinish" class="mx-5">
                <v-divider></v-divider>
                <div class="d-flex justify-space-between text-body-2 mt-3">
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">Head Cap <v-icon size="small">mdi-cog</v-icon></div>
                    </template>
                    <div class="text-caption">Capacity per Head</div>
                  </v-tooltip>
                  <div class="text-right">{{ getDailyFinishCapMu(date).muCapPerHead + ' mµ/pers/day' }}</div>
                </div>
                <div class="d-flex justify-space-between text-body-2 mt-1">
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">Finishers <v-icon size="small">mdi-account</v-icon></div>
                    </template>
                    <div class="text-caption">Finishers from Staff Total</div>
                  </v-tooltip>
                  <div class="text-right">{{ getDailyFinishCap(date).persons + ' head' }}</div>
                </div>
                <div class="d-flex justify-space-between text-body-2 mt-1">
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">
                        Hr Cap
                      </div>
                    </template>
                    <div class="text-caption">Available Capacity Hour</div>
                  </v-tooltip>
                  <div class="text-right">
                    {{ getDailyFinishCap(date).hours + ' hrs' }}
                  </div>
                </div>
                <div class="d-flex justify-space-between text-body-2 mt-1">
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">
                        Mµ Cap
                      </div>
                    </template>
                    <div class="text-caption">Available Capacity Mµ</div>
                  </v-tooltip>
                  <div class="text-right">{{ getDailyFinishCapMu(date).muCap + ' mµ/day' }}</div>
                </div>
                <div class="d-flex justify-space-between text-body-2 mt-1">
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">
                        Mµ Total
                      </div>
                    </template>
                    <div class="text-caption">Daily Mµ Total</div>
                  </v-tooltip>
                  <div class="text-right">{{ getDailyMuFinishTotal(date) + ' mµ/day' }}</div>
                </div>
                <div class="d-flex justify-space-between text-body-2 mt-1">
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">
                        Req Mµ Hour
                      </div>
                    </template>
                    <div class="text-caption">Required Mµ per Hour</div>
                  </v-tooltip>
                  <div class="text-right">{{ getDailyFinishCalcMu(date).muPerHourNeeded + ' mµ/hr' }}</div>
                </div>
                <div class="d-flex justify-space-between text-body-2 mt-1">
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">
                        Req Mµ Head
                      </div>
                    </template>
                    <div class="text-caption">Required Mµ per Head per Day</div>
                  </v-tooltip>
                  <div class="text-right">
                    {{ getDailyFinishCalcMu(date).muPerHeadNeeded + ' mµ/day' }}
                  </div>
                </div>
                <div
                  class="d-flex justify-space-between text-body-2 mt-1"
                  :class="getDailyFinishCalcMu(date).muDiffPerHead < 0 ? 'red--text' : 'green--text'"
                >
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">
                        Diff Mµ Head
                      </div>
                    </template>
                    <div class="text-caption">Difference Mµ per Head per Day</div>
                  </v-tooltip>
                  <div class="text-right">{{ getDailyFinishCalcMu(date).muDiffPerHead + ' mµ/day' }}</div>
                </div>
                <div
                  class="d-flex justify-space-between text-body-2 mt-1"
                  :class="getDailyFinishCalcMu(date).muDailyRem < 0 ? 'red--text' : 'green--text'"
                >
                  <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on }">
                      <div class="data-title mr-2" v-on="on">
                        Mµ Rem
                      </div>
                    </template>
                    <div class="text-caption">Daily Mµ Remainder</div>
                  </v-tooltip>
                  <div class="text-right">
                    {{ getDailyFinishCalcMu(date).muDailyRem + ' %' }}
                  </div>
                </div>
              </div>
            </v-expand-transition>
          </v-card>
        </div>
      </v-container>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'MuCalculator',
  props: ['tasksByDay', 'capacityObject'],
  data() {
    return {
      showFinish: false,
      showWeeklyCut: false,
      showWeeklyFinish: false,
      showWeeklyPack: false
    }
  },
  methods: {
    // CALCULATIONS FOR SCHEDULE INFORMATION DIALOG
    // ===DAILY FINISH===
    getDailyMuFinishTotal(date) {
      if (!this.tasksByDay && !this.tasksByDay[date]) return null
      let totalMuFinish = this.tasksByDay[date].reduce((sum, val) => {
        return val.type === 'finish' ? sum + val.muUnits : sum
      }, 0)
      return this.$utils.toFixedNumber(totalMuFinish, 1)
    },
    getDailyFinishCap(date) {
      if (!this.capacityObject && !this.capacityObject[date]) return null
      let workerTotalHours = 0
      let workerCount = 0
      const workerObject = this.capacityObject[date]?.working
        .concat(this.capacityObject[date]?.freelancers)
        .concat(this.capacityObject[date]?.overtime)

      if (workerObject) {
        for (const worker of workerObject) {
          workerTotalHours += worker.hours
          if (worker.hours > 0) {
            workerCount++
          }
        }
      }
      if (this.capacityObject[date]?.cutting > 0 && this.capacityObject[date]?.packing > 0) workerCount -= 2 // remove 1 x cutter and 1 x packer
      workerTotalHours -= this.capacityObject[date]?.cutting + this.capacityObject[date]?.packing

      return { persons: workerCount, hours: workerTotalHours }
    },
    getDailyFinishCapMu(date) {
      if (!this.capacityObject && !this.capacityObject[date]) return null
      const muSettingsFinishCapacity = this.$store.state.mu.finishCapacity
      let workCapacityTotal = 0
      if (this.getDailyFinishCap(date) && this.getDailyFinishCap(date).hours > 0) {
        workCapacityTotal = this.getDailyFinishCap(date).hours * (muSettingsFinishCapacity / 8)
      }
      return { muCap: workCapacityTotal, muCapPerHead: muSettingsFinishCapacity }
    },
    getDailyFinishCalcMu(date) {
      let muPerHourNeeded = 0
      let muPerHeadNeeded = 0
      let muDiffPerHead = 0
      let muDailyRem = 0

      if (this.getDailyFinishCap(date)?.hours > 0 && this.getDailyMuFinishTotal(date))
        // total daily mu / available hours
        muPerHourNeeded = this.$utils.toFixedNumber(
          this.getDailyMuFinishTotal(date) / this.getDailyFinishCap(date).hours,
          1
        )
      if (this.getDailyFinishCap(date)?.persons > 0 && this.getDailyMuFinishTotal(date))
        // total daily mu / available finishers
        muPerHeadNeeded = this.$utils.toFixedNumber(
          this.getDailyMuFinishTotal(date) / this.getDailyFinishCap(date).persons,
          1
        )
      if (this.getDailyFinishCapMu(date)?.muCapPerHead && muPerHeadNeeded)
        // mu settings (mu per head per day) - mu per head needed
        muDiffPerHead = this.$utils.toFixedNumber(this.getDailyFinishCapMu(date)?.muCapPerHead - muPerHeadNeeded, 1)
      if (this.getDailyMuFinishTotal(date) && this.getDailyFinishCapMu(date)?.muCap)
        // mu capacity - total daily mu / mu capacity * 100
        muDailyRem = this.$utils.toFixedNumber(
          ((this.getDailyFinishCapMu(date)?.muCap - this.getDailyMuFinishTotal(date)) /
            this.getDailyFinishCapMu(date)?.muCap) *
            100,
          1
        )

      return { muPerHourNeeded, muPerHeadNeeded, muDiffPerHead, muDailyRem }
    },
    // ===WEEKLY FINISH===
    getWeeklyMuFinishTotal() {
      let weekMuFinishTotal = 0
      if (this.tasksByDay) {
        for (const date in this.tasksByDay) {
          weekMuFinishTotal += this.getDailyMuFinishTotal(date)
        }
      }
      return weekMuFinishTotal
    },
    getWeeklyFinishCap() {
      let weeklyPersons = 0
      let weeklyHours = 0
      let weekMuFinishCap = 0
      if (this.tasksByDay) {
        for (const date in this.tasksByDay) {
          weeklyPersons += this.getDailyFinishCap(date).persons
          weeklyHours += this.getDailyFinishCap(date).hours
          weekMuFinishCap += this.getDailyFinishCapMu(date).muCap // per head per hour
        }
      }
      return { weekMuFinishCap, weeklyPersons, weeklyHours }
    },
    getWeeklyFinishCalcMu() {
      let avgMuPerHour = 0
      let avgMuPerHead = 0
      let rangeMuDiffPerHead = 0
      let avgMuDiffPerHead = 0
      let avgMuWeeklyRem = 0

      if (this.getWeeklyFinishCap().weeklyHours > 0 && this.getWeeklyMuFinishTotal()) {
        // total weekly mu / total weekly hours
        avgMuPerHour = this.$utils.toFixedNumber(
          this.getWeeklyMuFinishTotal() / this.getWeeklyFinishCap().weeklyHours,
          1
        )
      }
      if (this.getWeeklyFinishCap().weeklyPersons > 0 && this.getWeeklyMuFinishTotal()) {
        // total weekly mu / total weekly finishers
        avgMuPerHead = this.$utils.toFixedNumber(
          this.getWeeklyMuFinishTotal() / this.getWeeklyFinishCap().weeklyPersons,
          1
        )
      }
      if (this.getWeeklyFinishCap().weeklyPersons) {
        let weeklyMuDiffArray = []
        let weeklyHighMuDiff = 0
        let weeklyLowMuDiff = 0
        for (const date in this.tasksByDay) {
          weeklyMuDiffArray.push(this.getDailyFinishCalcMu(date)?.muDiffPerHead)
        }
        // sort weekly mu diff
        weeklyMuDiffArray.sort((a, b) => a - b)
        // find highest and lowest
        weeklyHighMuDiff = weeklyMuDiffArray.pop() //last in array
        weeklyLowMuDiff = weeklyMuDiffArray.shift() // first in array

        rangeMuDiffPerHead = weeklyHighMuDiff - weeklyLowMuDiff
      }
      if (this.getWeeklyFinishCap().weeklyPersons) {
        let weeklyTotalMuDiff = 0
        let workdays = 0
        for (const date in this.tasksByDay) {
          // find number of workdays in the week
          if (this.getDailyMuFinishTotal(date) > 0) workdays++

          // tally muDiffPerHead
          weeklyTotalMuDiff += this.getDailyFinishCalcMu(date)?.muDiffPerHead
        }
        // total weekly total mu difference per head / total weekly finishers
        avgMuDiffPerHead = this.$utils.toFixedNumber(weeklyTotalMuDiff / workdays, 1)
      }
      if (this.tasksByDay) {
        let muWeeklyTotalRem = 0
        let workdays = 0

        for (const date in this.tasksByDay) {
          // find number of workdays in the week
          if (this.getDailyMuFinishTotal(date) > 0) workdays++

          // find mu weekly total remainder
          // (total weekly mu cap - total weekly mu) / weekly mu cap * 100
          muWeeklyTotalRem += this.getDailyFinishCalcMu(date)?.muDailyRem
        }

        //total weekly mu rem / workdays
        avgMuWeeklyRem = this.$utils.toFixedNumber(muWeeklyTotalRem / workdays, 1)
      }
      return { avgMuPerHour, avgMuPerHead, rangeMuDiffPerHead, avgMuDiffPerHead, avgMuWeeklyRem }
    }
  }
}
</script>

<style lang="scss" scoped>
.cut-expansion {
  background: $cut;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
.finish-expansion {
  background: $finish;
  color: white;
  border-radius: 2px;

  cursor: pointer;
}
.pack-expansion {
  background: $pack;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
.expand-icon {
  color: white;
  cursor: pointer;
}

.data-title {
  cursor: help;
}
</style>
