const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class Lighting {
  constructor(urtil, sideWidth, backInfo, lightingInfo) {
    this.models = { lighting: { models: {} } }
    // VARS
    this.urtil = urtil
    this.sideWidth = sideWidth

    this.backRebateWidth = backInfo?.rebateWidth

    this.lightingHeight = lightingInfo?.height
    this.lightingRebateCornerPoke = lightingInfo?.rebateCornerPoke
    this.lightCableCoverWidth = lightingInfo?.cableCoverWidth
    this.lightCableWidth = lightingInfo?.cableWidth

    // CALCS
    this.lightingWidth = urtil.lighting + lightingInfo?.padding

    this.addLighting()
  }

  addLighting() {
    let lighting = this.models.lighting.models
    lighting.rebate = new makerjs.models.Rectangle(this.lightingWidth, this.lightingHeight)
    lighting.rebate.layer = layerNames.mittledGroove.label

    lighting.lines = {
      layer: layerNames.mittledCorner.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke + 5],
          end: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke]
        },
        line2: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke],
          end: [this.lightingRebateCornerPoke + 5, this.lightingRebateCornerPoke]
        },
        line3: {
          type: 'line',
          origin: [-this.lightingRebateCornerPoke + this.lightingWidth - 5, this.lightingRebateCornerPoke],
          end: [-this.lightingRebateCornerPoke + this.lightingWidth, this.lightingRebateCornerPoke]
        },
        line4: {
          type: 'line',
          origin: [-this.lightingRebateCornerPoke + this.lightingWidth, this.lightingRebateCornerPoke],
          end: [-this.lightingRebateCornerPoke + this.lightingWidth, this.lightingRebateCornerPoke + 5]
        },
        line5: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth,
            -this.lightingRebateCornerPoke + this.lightingHeight - 5
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth,
            -this.lightingRebateCornerPoke + this.lightingHeight
          ]
        },
        line6: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth,
            -this.lightingRebateCornerPoke + this.lightingHeight
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth - 5,
            -this.lightingRebateCornerPoke + this.lightingHeight
          ]
        },
        line7: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke + 5, -this.lightingRebateCornerPoke + this.lightingHeight],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingHeight]
        },
        line8: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingHeight],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingHeight - 5]
        }
      }
    }

    makerjs.model.move(this.models.lighting, [
      (this.sideWidth - this.lightingWidth) / 2,
      this.urtil.d / 2 - this.lightingHeight / 2
    ])

    // Cable housing
    let xPos = this.lightingWidth
    // Cover Channel
    lighting['cable-cover-channel'] = new makerjs.models.RoundRectangle(
      this.lightCableCoverWidth,
      this.urtil.d / 2,
      this.lightCableCoverWidth / 2
    )
    makerjs.model.move(lighting['cable-cover-channel'], [
      xPos - this.lightCableCoverWidth + (this.lightCableCoverWidth - this.lightCableWidth) / 2,
      0
    ])
    lighting['cable-cover-channel'].layer = layerNames.cableCoverGroove.label

    // Channel
    lighting['cable-channel'] = new makerjs.models.RoundRectangle(
      this.lightCableWidth,
      this.urtil.d / 2 - (this.lightCableCoverWidth - this.lightCableWidth),
      this.lightCableWidth / 2
    )
    makerjs.model.move(lighting['cable-channel'], [
      xPos -
        this.lightCableWidth -
        (this.lightCableCoverWidth - this.lightCableWidth) / 2 +
        (this.lightCableCoverWidth - this.lightCableWidth) / 2,
      (this.lightCableCoverWidth - this.lightCableWidth) / 2
    ])
    lighting['cable-channel'].layer = layerNames.cableChannelGroove.label

    // Channel plug boxes
    lighting['cable-channel-plug-box-front'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(lighting['cable-channel-plug-box-front'], [xPos - 8 + 1, this.lightingHeight - 1])
    // ;('cable-channel-plug-box-front')
    lighting['cable-channel-plug-box-front'].layer = layerNames.cableChannelGroove.label

    //
    lighting['cable-channel-plug-box-back'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(lighting['cable-channel-plug-box-back'], [
      xPos - 8 + 1,
      this.urtil.d / 2 - this.backRebateWidth - 4
    ])
    // ;('cable-channel-plug-box-back')
    lighting['cable-channel-plug-box-back'].layer = layerNames.cableChannelGroove.label

    // Cover Piece
    const coverRect = new makerjs.models.Rectangle(
      this.lightCableCoverWidth,
      this.urtil.d / 2 + this.lightingHeight / 2 - this.backRebateWidth - this.lightCableCoverWidth / 2
    )
    const coverCirc = new makerjs.models.Ellipse(this.lightCableCoverWidth / 2, this.lightCableCoverWidth / 2)
    makerjs.model.move(coverCirc, [5, 0])

    lighting['cable-cover'] = makerjs.model.combine(coverRect, coverCirc, false, true, false, true)
    // lighting['cable-cover'] = makerjs.model.distort(lighting['cable-cover'], 1.05, 1) // turn on if you want cable-cover to be a bit bigger

    lighting['cable-cover'] = makerjs.layout.cloneToRow(lighting['cable-cover'], 2, 6)
    makerjs.model.move(lighting['cable-cover'], [-this.sideWidth / 3 - this.urtil.h * 2, this.urtil.d])

    lighting['cable-cover'].layer = layerNames.cableCoverCutout.label

    // cover piece mill
    const millRect = new makerjs.models.Rectangle(
      this.lightCableCoverWidth * 2 + 6 + 20,
      this.urtil.d / 2 + this.lightingHeight / 2 - this.backRebateWidth + 20
    )
    makerjs.model.move(millRect, [
      -(this.sideWidth / 3 + this.urtil.h * 2 - this.lightCableCoverWidth + 20.5),
      this.urtil.d - 10 - this.lightCableCoverWidth / 2
    ])
    lighting['mill'] = millRect
    lighting['mill'].layer = layerNames.millCableCover.label

    // cover piece lip mill
    const lipMillRect = new makerjs.models.Rectangle(
      this.lightCableCoverWidth * 2 + 6 + 20,
      this.lightingHeight + 4 + 20
    )
    makerjs.model.move(lipMillRect, [
      -(this.sideWidth / 3 + this.urtil.h * 2 - this.lightCableCoverWidth + 20.5),
      this.urtil.d - 10 - this.lightCableCoverWidth / 2
    ])
    lighting['lip-mill'] = lipMillRect
    lighting['lip-mill'].layer = layerNames.millCableCoverLip.label
  }
}
