import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class Invisipull {
  constructor(front, width, short) {
    // common
    this.models = {}
    this.width = width
    this.insetX = 0
    this.insetY = short ? 0 : -30
    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.corebox = {
      layer: layerNames.invisiCorebox.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.width, 0]
        }
      }
    }
  }
}
