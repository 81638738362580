var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class DrawerHoles {
  constructor(w, h, front) {
    this.models = {}
    this.width = w
    this.height = h
    this.front = front

    // only time width is 197 or 297 is for drawers with 3mm drill holes
    this.drillDiameter = w === 197 || w === 297 ? 3 : 5
    this.posArray = [39, 58, 117, 136, 185, 204]
    this.posArray200 = [39, 58, 77, 136, 204, 223, 539, 558, 617, 636]
    this.offsetX = h === 80 || h === 145 || h === 215 ? 11.75 : 31.75
    this.posArrayInternal = [17.5, 36.5, 95.5, 114.5, 163.5, 182.5]

    this.holeCount
    if (this.height < 100) {
      this.holeCount = 2
    } else if (this.height < 200) {
      this.holeCount = 4
    } else {
      this.holeCount = 6
    }

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.leftHoles = this.getHoles()
    this.models.leftHoles.layer =
      this.drillDiameter === 3 ? layerNames.drawerHoleSmall.label : layerNames.drawerHoleMedium.label
    this.models.rightHoles = this.getHoles()
    this.models.rightHoles.layer =
      this.drillDiameter === 3 ? layerNames.drawerHoleSmall.label : layerNames.drawerHoleMedium.label

    makerjs.model.move(this.models.leftHoles, [this.offsetX, 0])
    makerjs.model.move(this.models.rightHoles, [this.width - this.offsetX, 0])

    if (this.front.special === 'pantry' && (this.width === 1197 || this.width === 1597)) {
      this.models.leftCenterHoles = makerjs.cloneObject(this.models.leftHoles)
      makerjs.model.move(this.models.leftCenterHoles, [this.width / 2 - 1.5 - this.offsetX, 0])
      this.models.rightCenterHoles = makerjs.cloneObject(this.models.rightHoles)
      makerjs.model.move(this.models.rightCenterHoles, [this.width / 2 + 1.5 + this.offsetX, 0])
    }
    // fail-safe to make drawers blank if pantry drawer but NOT double of 600 or 800.
    if (this.front.special === 'pantry' && ![1197, 1597].includes(this.width)) {
      delete this.models.leftHoles
      delete this.models.rightHoles
    }
  }

  getHoles() {
    // catch the 200mm pull out cupboard
    var src, count
    if (this.width === 197 || this.width === 297) {
      src = this.posArray200
      count = this.posArray200.length
    } else if (this.height === 80 || this.height === 145 || this.height === 215) {
      let srcArr = []
      for (let i = 0; i < this.holeCount; i++) {
        srcArr.push(this.posArrayInternal[i])
      }
      src = srcArr
      count = this.holeCount
    } else if (this.height < 100 && this.front.handle.value === 'j') {
      src = [this.posArray[0]]
      count = 1
    } else {
      src = this.posArray
      count = this.holeCount
    }

    var points = []
    for (var i = 0; i < count; i++) {
      points.push([0, src[i]])
    }

    return new makerjs.models.Holes(this.drillDiameter / 2, points)
  }
}
