<template>
  <div id="production-table">
    <div class="table-header align-center">
      <v-switch
        class="mr-4"
        color="primary"
        v-model="showDead"
        hide-details
        label="Dead"
        @change="bindQuotes"
      ></v-switch>
      <v-switch color="primary" v-model="filterQuotes" label="Just mine" hide-details @change="bindQuotes"></v-switch>
      <v-checkbox v-model="includeUK" label="Include UK" class="ml-5 mr-3" hide-details />
      <v-checkbox v-model="includeEU" label="Include EU" class="mr-3" hide-details />
      <v-checkbox v-model="includeUS" label="Include US" class="mr-3" hide-details />
      <v-spacer />
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :clearable="true"
      >
      </v-text-field>
    </div>
    <v-data-table
      :items="filteredQuotes"
      :headers="headers"
      :search="tableSearch"
      sort-by="dateCreated"
      sort-desc
      :loading="loading"
      :no-data-text="noDataText"
      :items-per-page="10"
      must-sort
      fixed-header
    >
      <template v-slot:item="props">
        <tr>
          <td>{{ $utils.relativeTime(props.item.dateCreated) }}</td>
          <td>{{ props.item.customerEmail }}</td>
          <td>{{ props.item.creatorEmail }}</td>
          <td class="text-right">
            <v-icon
              small
              class="mr-2"
              @click="
                () => {
                  selectedQuote = props.item.id
                  deleteDialog = true
                }
              "
              >mdi-delete</v-icon
            >
            <v-icon v-if="!showDead" small class="mr-2" @click="onThumbDown(props.item)">mdi-thumb-down</v-icon>
            <v-icon v-else small class="mr-2" @click="resurrectFromDead(props.item)">mdi-thumb-up</v-icon>
            <v-icon small @click="view(props.item.id)">mdi-pencil</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Dead Reason Dialogue -->
    <v-dialog v-model="deadReasonVisible" width="500">
      <v-card>
        <v-card-title class="primary white--text title">
          Mark as dead
        </v-card-title>
        <v-card-text>
          <v-select v-model="deadReason" :items="deadReasons" label="Select Reason"> </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deadReasonVisible = false">Cancel</v-btn>
          <v-btn color="accent" dark depressed @click="setAsDead">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialogue -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Delete Quote</v-card-title>
        <v-card-text class="pt-6">
          <p>Are you sure you want to delete the quote?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="deleteQuote(selectedQuote)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Quotes',
  data() {
    return {
      userEmail: this.$firebase.auth().currentUser.email,
      quotes: [],
      loading: true,
      selectedQuote: null,
      deleteDialog: false,
      deadReasonVisible: false,
      deadReason: null,
      deadReasons: [
        'Too expensive',
        'Lead time too long',
        'Vanished 👻',
        'Went with competitor',
        'Decided to do something different',
        'Project cancelled',
        'Process too complicated',
        'Installing non-ikea kitchen'
      ],
      filterQuotes: true,
      includeUK: true,
      includeEU: true,
      includeUS: true,
      showDead: false,
      //
      headers: [
        {
          text: 'Date Created',
          sortable: true,
          value: 'dateCreated'
        },
        {
          text: 'Customer',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'Creator',
          sortable: false,
          value: 'creatorEmail'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          value: null
        }
      ],
      tableSearch: ''
    }
  },
  created() {
    this.bindQuotes()
  },
  computed: {
    filteredQuotes() {
      // filter by user
      let fq = this.quotes.filter(q => {
        return this.filterQuotes ? q.creatorEmail === this.userEmail : true
      })

      // filter by UK
      fq = fq.filter(q => {
        switch (q.region) {
          case 'uk':
            return this.includeUK
          case 'eu':
            return this.includeEU
          case 'us':
            return this.includeUS
          default:
            return true
        }
      })

      return fq
    },
    noDataText() {
      return this.loading ? '' : 'Nothing to display'
    }
  },
  methods: {
    bindQuotes() {
      this.loading = true

      let query
      if (this.filterQuotes) {
        query = this.$db
          .collection('quoteIndex')
          .where('creatorEmail', '==', this.userEmail)
          .where('dead', '==', this.showDead)
      } else {
        query = this.$db.collection('quoteIndex').where('dead', '==', this.showDead)
      }

      this.$bind('quotes', query).then(() => {
        this.loading = false
      })
    },
    view(id) {
      this.$router.push({ name: 'quoteEdit', params: { quoteID: id } })
    },
    deleteQuote(id) {
      this.deleteDialog = false
      this.$db
        .collection('quoteIndex')
        .doc(id)
        .delete()
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Quote Deleted' })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    onThumbDown(quote) {
      this.selectedQuote = quote
      this.deadReasonVisible = true
    },
    async setAsDead() {
      await this.$db
        .collection('quoteIndex')
        .doc(this.selectedQuote.id)
        .update({
          dead: true,
          deadReason: this.deadReason
        })

      this.deadReason = null
      this.deadReasonVisible = false
      this.selectedQuote = null
    },
    async resurrectFromDead(quote) {
      await this.$db
        .collection('quoteIndex')
        .doc(quote.id)
        .update({
          dead: false,
          deadReason: null
        })
    },
    versionDateFormatter(row) {
      return this.$utils.relativeTime(row.versions[row.versions.length - 1].date)
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        if (!isDescending) {
          return a.dateCreated < b.dateCreated ? 1 : -1
        } else {
          return a.dateCreated < b.dateCreated ? -1 : 1
        }
      })
      return items
    }
  }
}
</script>
