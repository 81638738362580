<template>
  <div id="offcuts">
    <div id="view-sub-nav">
      <v-text-field
        class="pt-0"
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :clearable="true"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn depressed outlined color="primary" @click="newDialog = true">New Offcut</v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="offcuts"
      :search="tableSearch"
      :custom-filter="customSearch"
      :loading="loading"
      item-key="id"
      sortBy="dateCreated"
      sort-desc
      :items-per-page="-1"
    >
      <template v-slot:[`item.uid`]="{ item }">
        {{ item.uid.toUpperCase() }}
      </template>
      <template v-slot:[`item.material`]="{ item }">
        {{ Materials.getNameFromValue(item.material) }}
      </template>
      <template v-slot:[`item.colourCode`]="{ item }">
        {{ item.colourCode ? getColourString(item.material, item.colourCode) : '-' }}
      </template>
      <template v-slot:[`item.dimensions`]="{ item }">
        {{ item.dimensions.w + ' x ' + item.dimensions.h }}
      </template>
      <template v-slot:[`item.reserved`]="{ item }">
        {{ item.reserved ? item.reservedOrderNumber || 'Yes' : '-' }}
      </template>
      <template v-slot:[`item.dateCreated`]="{ item }">
        {{ item.dateCreated ? $utils.convertDate(item.dateCreated) : 'N/A' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on }">
            <v-icon
              small
              @click="
                () => {
                  selectedOffcut = { ...item }
                  selectedOffcut.id = item.id
                  editDialog = true
                }
              "
              v-on="on"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Edit Offcut</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on }">
            <v-icon
              small
              @click="
                () => {
                  deleteDialog = true
                  selectedOffcut = item
                }
              "
              v-on="on"
              >mdi-delete</v-icon
            >
          </template>
          <span>Remove Offcut</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog width="800" v-model="newDialog">
      <v-card>
        <v-card-title class="primary white--text title">New Offcut</v-card-title>
        <v-card-text class="mt-5">
          <v-row>
            <v-col>
              <v-text-field read-only :value="newForm.uid.toUpperCase()" label="Offcut ID"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field type="number" placeholder="Palette Location" v-model.number="newForm.pallet">
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field placeholder="Order Number" v-model="newForm.orderNumber"> </v-text-field>
            </v-col>
          </v-row>
          <v-form>
            <v-row>
              <v-col cols="2" class="d-flex align-center">
                <v-switch v-model="newForm.isWorktop" label="Worktop"></v-switch>
              </v-col>
              <v-col cols="8" offset="2">
                <v-autocomplete
                  v-model="newForm.material"
                  :items="newForm.isWorktop ? masterWorktops : masterMaterials"
                  item-text="text"
                  :placeholder="newForm.isWorktop ? 'Worktop Materials' : 'Materials'"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="newForm.material" class="mt-3">
              <v-col>
                <v-autocomplete
                  v-if="newForm.material"
                  v-model="newForm.sides"
                  :items="['Single', 'Double']"
                  placeholder="Sides"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-if="newForm.material"
                  v-model="newForm.thickness"
                  :items="newForm.isWorktop ? selectedWorktopThicknesses : selectedMaterialThicknesses"
                  placeholder="Thickness"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  type="number"
                  v-model.number="newForm.dimensions.w"
                  placeholder="Width"
                  suffix="mm"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="number"
                  v-model.number="newForm.dimensions.h"
                  placeholder="Height"
                  suffix="mm"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-autocomplete
              v-if="selectedMaterial && selectedMaterial.colours"
              v-model="newForm.colourCode"
              :items="selectedMaterial.colours"
              :item-text="
                i => {
                  return i.value + ' - ' + i.text
                }
              "
              placeholder="Colour"
              hide-details
            ></v-autocomplete>

            <v-row class="mt-3">
              <v-col cols="4">
                <v-checkbox v-model="newForm.reserved" label="Reserved" @click="resetNewFormReserve"></v-checkbox>
              </v-col>
              <v-col v-if="newForm.reserved">
                <v-text-field v-model="newForm.reservedOrderNumber" placeholder="Order Number"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="newDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed @click="saveOffcut" :disabled="newFormIncomplete">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="800" v-model="editDialog">
      <v-card v-if="selectedOffcut">
        <v-card-title class="primary white--text title">Edit Offcut</v-card-title>
        <v-card-text class="mt-5">
          <v-form>
            <v-row class="mt-3">
              <v-col cols="3">
                <v-checkbox
                  v-model="selectedOffcut.reserved"
                  label="Reserved"
                  @click="resetEditFormReserve"
                ></v-checkbox>
              </v-col>
              <v-col cols="7" v-if="selectedOffcut.reserved">
                <v-text-field v-model="selectedOffcut.reservedOrderNumber" label="Order Number"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col cols="3">
                <v-text-field type="number" v-model.number="selectedOffcut.pallet" label="Pallet:"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  type="number"
                  v-model.number="selectedOffcut.dimensions.w"
                  label="Width: "
                  suffix="mm"
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex justify-center align-center">
                X
              </v-col>
              <v-col cols="3">
                <v-text-field
                  type="number"
                  v-model.number="selectedOffcut.dimensions.h"
                  label="Height: "
                  suffix="mm"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed @click="updateOffcut" :disabled="editFormIncomplete">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="600" v-model="deleteDialog">
      <v-card v-if="selectedOffcut">
        <v-card-title v-if="selectedOffcut && !selectedOffcut.reserved" class="primary white--text title"
          >Delete Offcut</v-card-title
        >
        <v-card-title v-if="selectedOffcut && selectedOffcut.reserved" class="primary white--text title">
          Reserved Offcut Delete</v-card-title
        >
        <v-alert v-if="selectedOffcut && !selectedOffcut.reserved" type="warning">
          Are you sure you want to delete offcut?
        </v-alert>
        <v-alert v-if="selectedOffcut && selectedOffcut.reserved" type="warning">
          This offcut is reserved for {{ selectedOffcut.reservedOrderNumber }}, are you sure you want to delete?
        </v-alert>
        <v-card-text>
          <div class="d-flex">
            <p class="font-weight-bold mr-3">ID:</p>
            {{ selectedOffcut.uid.toUpperCase() }}
          </div>
          <div class="d-flex">
            <p class="font-weight-bold mr-3">Pallet:</p>
            {{ selectedOffcut.pallet }}
          </div>
          <div class="d-flex">
            <p class="font-weight-bold mr-3">Material:</p>
            {{
              selectedOffcut.colourCode
                ? selectedOffcut.thickness +
                  'mm x ' +
                  selectedOffcut.dimensions.w +
                  'mm x ' +
                  selectedOffcut.dimensions.h +
                  'mm - ' +
                  getColourString(selectedOffcut.material, selectedOffcut.colourCode) +
                  ' [ ' +
                  selectedOffcut.material +
                  ' ]'
                : selectedOffcut.thickness +
                  'mm x ' +
                  selectedOffcut.dimensions.w +
                  'mm x ' +
                  selectedOffcut.dimensions.h +
                  'mm - ' +
                  '[ ' +
                  selectedOffcut.material +
                  ' ]'
            }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn class="warning" depressed @click="deleteOffcut(selectedOffcut.id)">Delete Offcut</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import uid from 'uid'
import Materials from '@/js/Materials'

export default {
  name: 'Offcuts',
  data() {
    return {
      Materials,
      newDialog: false,
      editDialog: false,
      deleteDialog: false,
      loading: true,
      offcuts: [],
      newForm: null,
      newFormIncomplete: true,
      editFormIncomplete: true,
      masterMaterials: Materials.getMaterialsByRegion('uk'),
      masterWorktops: Materials.getWorktopsByRegion('uk'),
      selectedOffcut: null,
      headers: [
        {
          text: 'ID',
          value: 'uid'
        },
        {
          text: 'Pallet',
          value: 'pallet'
        },
        {
          text: 'Material',
          value: 'material'
        },
        {
          text: 'Colour',
          value: 'colourCode'
        },
        {
          text: 'Sides',
          value: 'sides'
        },
        {
          text: 'Thickness (mm)',
          value: 'thickness'
        },
        {
          text: 'Dimensions (mm)',
          value: 'dimensions'
        },
        {
          text: 'Reserved',
          value: 'reserved'
        },
        {
          text: 'Date Created',
          value: 'dateCreated'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          width: 100,
          value: 'actions'
        }
      ],
      tableSearch: ''
    }
  },
  async created() {
    this.resetForm()

    await this.$bind('offcuts', this.$db.collection('offcuts'))
    this.loading = false
  },
  watch: {
    newForm: {
      handler() {
        this.newFormIncomplete = this.checkNewForm()
      },
      deep: true
    },
    selectedOffcut: {
      handler() {
        this.editFormIncomplete = this.checkEditForm()
      },
      deep: true
    }
  },
  computed: {
    selectedMaterial() {
      if (!this.newForm.material) return null
      const material = this.masterMaterials.find(m => {
        return m.value === this.newForm.material
      })
      return material || null
    },
    selectedWorktop() {
      if (!this.newForm.material) return null
      const worktop = this.masterWorktops.find(w => {
        return w.value === this.newForm.material
      })
      return worktop || null
    },
    selectedMaterialThicknesses() {
      if (!this.selectedMaterial) return null

      let thicknesses = []
      for (let size of this.selectedMaterial.sizes) {
        if (!thicknesses.some(o => o.value === size.thickness)) {
          thicknesses.push({
            text: size.thickness + 'mm',
            value: size.thickness
          })
        }
      }
      return thicknesses
    },
    selectedWorktopThicknesses() {
      if (!this.selectedWorktop) return null

      let thicknesses = []
      for (let size of this.selectedWorktop.sizes) {
        if (!thicknesses.some(o => o.value === size.thickness)) {
          thicknesses.push({
            text: size.thickness + 'mm',
            value: size.thickness
          })
        }
      }
      return thicknesses
    }
  },
  methods: {
    customSearch(value, search, item) {
      let result
      const standardSearch = Object.values(item).some(
        v =>
          v &&
          v
            .toString()
            .toLowerCase()
            .includes(search)
      )
      if (item.colourCode) {
        const material = this.$store.state.materials.find(m => m.value === item.material)
        const colour = item.colourCode ? material.colours.find(c => c.value === item.colourCode) : null
        result =
          standardSearch ||
          colour.text
            .toString()
            .toLowerCase()
            .includes(search)
      } else {
        result = standardSearch
      }
      return result
    },
    resetForm() {
      this.newForm = {
        uid: uid(5),
        material: null,
        colourCode: null,
        thickness: null,
        sides: null,
        isWorktop: null,
        dimensions: {
          w: null,
          h: null
        },
        pallet: null,
        reserved: false,
        dateCreated: this.$firebase.firestore.Timestamp.now(),
        orderNumber: null,
        reservedOrderNumber: null
      }
    },
    resetEditFormReserve() {
      if (!this.selectedOffcut.reserved) this.selectedOffcut.reservedOrderNumber = null
    },
    resetNewFormReserve() {
      if (!this.newForm.reserved) this.newForm.reservedOrderNumber = null
    },
    getColourString(value, code) {
      const material = this.$store.state.materials.find(m => m.value === value)
      const colour = material.colours.find(c => c.value === code)
      if (!colour || !material) {
        console.error('missing material or colour', value, code)
        return
      } else {
        return colour.value + ' - ' + colour.text
      }
    },
    async saveOffcut() {
      if (!this.newForm.reserved) this.newForm.reservedOrderNumber = null
      await this.$db.collection('offcuts').add(this.newForm)
      this.newDialog = false
      this.resetForm()
    },
    async updateOffcut() {
      await this.$db
        .collection('offcuts')
        .doc(this.selectedOffcut.id)
        .update({
          reserved: this.selectedOffcut.reserved,
          reservedOrderNumber: this.selectedOffcut.reservedOrderNumber,
          dimensions: {
            w: this.selectedOffcut.dimensions.w,
            h: this.selectedOffcut.dimensions.h
          },
          pallet: this.selectedOffcut.pallet
        })
      this.editDialog = false
      this.selectedOffcut = null
    },
    async deleteOffcut(id) {
      await this.$db
        .collection('offcuts')
        .doc(id)
        .delete()

      this.deleteDialog = false
    },
    checkNewForm() {
      if (
        this.newForm.pallet &&
        this.newForm.orderNumber &&
        this.newForm.material &&
        this.newForm.sides &&
        this.newForm.thickness &&
        this.newForm.dimensions.w &&
        this.newForm.dimensions.h &&
        this.newForm.colourCode
      ) {
        if (this.newForm.reserved && !this.newForm.reservedOrderNumber) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    checkEditForm() {
      if (this.selectedOffcut.pallet && this.selectedOffcut.dimensions.w && this.selectedOffcut.dimensions.h) {
        if (this.selectedOffcut.reserved && !this.selectedOffcut.reservedOrderNumber) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
</script>
