var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"production-table"}},[_c('div',{staticClass:"table-header"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":true},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1),_c('div',{attrs:{"id":"dispatchedTable"}},[_c('v-data-table',{attrs:{"items":_vm.shipments,"headers":_vm.headers,"search":_vm.tableSearch,"loading":_vm.loading,"custom-sort":_vm.customSort,"no-data-text":_vm.noDataText,"sortBy":"dateScheduled"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.orderNumber || props.item.invoiceNumber))]),_c('td',[_vm._v(_vm._s(props.item.customerEmail))]),_c('td',[_vm._v(_vm._s(props.item.creatorEmail))]),_c('td',[_vm._v(_vm._s(_vm.formatLineItems(props.item.lineItems)))]),_c('td',[_vm._v(_vm._s(_vm.$utils.humanFromVuetify(props.item.dateScheduled)))]),_c('td',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewQuote(props.item)}}},on),[_vm._v("mdi-clipboard-text")])]}}],null,true)},[_c('span',[_vm._v("Original Quote")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.packingView(props.item)}}},on),[_vm._v("mdi-clipboard-check")])]}}],null,true)},[_c('span',[_vm._v("Packing List")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"margin-top":"-1px"},attrs:{"disabled":_vm.cloning,"size":15},on:{"click":function () {
                        _vm.selectedShipment = props.item
                        _vm.cloneDialog = true
                      }}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Clone to New Quote")])])],1)])]}}])})],1)]),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',[_c('v-toolbar',{staticClass:"no-print elevation-0",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function () {
              _vm.dialogVisible = false
              _vm.overlayComponent = null
            }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c(_vm.overlayComponent,{tag:"component",attrs:{"shipment":_vm.selectedShipment,"quoteID":_vm.selectedShipment.quoteID,"versionID":_vm.selectedShipment.versionID}}),_c('v-card-text')],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.cloneDialog),callback:function ($$v) {_vm.cloneDialog=$$v},expression:"cloneDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"error white--text title"},[_vm._v(" Clone Quote ")]),_c('v-card-text',[_vm._v(" Are you sure you want to clone this quote? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","flat":""},on:{"click":function($event){_vm.cloneDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"accent","depressed":"","dark":""},on:{"click":_vm.cloneToQuote}},[_vm._v("Clone")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }