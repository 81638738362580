<template>
  <v-container fluid>
    <v-row wrap>
      <v-col cols="6">
        <v-switch
          v-model="wonky"
          :label="wonky ? 'Wonky mode active' : 'Wonky mode disabled - probably for the best'"
          @change="toggleWonkyMode"
          hide-details
          class="my-0"
        ></v-switch>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn @click="logout" color="accent">Logout</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'user',
  data() {
    return {
      fsUser: null,
      user: null,
      wonky: null
    }
  },
  async created() {
    this.fsUser = this.$firebase.auth().currentUser
    this.$store.commit('setPageTitle', this.fsUser.email)
    const userSnap = await this.$db
      .collection('users')
      .where('email', '==', this.fsUser.email)
      .get()
    this.user = userSnap.docs[0]
    this.wonky = this.user.data().wonkyMode
  },
  methods: {
    async toggleWonkyMode(boolean) {
      await this.$db
        .collection('users')
        .where('email', '==', this.fsUser.email)
        .get()
      this.user.ref.update({
        wonkyMode: boolean
      })
    },
    logout() {
      this.$firebase
        .auth()
        .signOut()
        .then(
          () => {
            this.$router.push({ name: 'login' })
          },
          err => {
            console.log('Error signing out', err)
          }
        )
    }
  }
}
</script>
