<template>
  <input v-model="convertedDbValue" :class="displayClass" />
</template>

<script>
export default {
  name: 'InchInput',
  props: {
    data: Number,
    displayClass: [String, Function]
  },
  data() {
    return {
      inputValue: null
    }
  },
  created() {
    this.inputValue = this.convertedDbValue
  },
  computed: {
    convertedDbValue: {
      get: function() {
        if (!this.data) return null
        var fixedData = this.$utils.toFixedNumber(this.data / 25.4, 3)
        var adjustedData = fixedData % 1 == 0 ? parseInt(fixedData) : fixedData
        return adjustedData
      },
      set: function(value) {
        this.$emit('update:data', this.$utils.toFixedNumber(value * 25.4, 3))
      }
    }
  }
}
</script>
