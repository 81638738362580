<template>
  <div id="production-table">
    <v-data-table :items="filteredOrders" :headers="headers">
      <template v-slot:item.dateCreated="{ item }">
        {{ $moment.unix(item.dateCreated.seconds).format('Do MMM YYYY') }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on }">
            <v-icon @click="checkShipments(item)" v-on="on" small>mdi-wrench</v-icon>
          </template>
          <span>Check Shipment</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on }">
            <v-icon @click="markShipmentsCreated(item)" v-on="on" class="mx-1">mdi-check-bold</v-icon>
          </template>
          <span>Mark Shipments As Created</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on }">
            <v-icon @click="manuallyCreateShipments(item)" v-on="on">mdi-plus-thick</v-icon>
          </template>
          <span>Manually Create Shipments</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'MissingShipments',
  data() {
    return {
      missingShipments: [],
      headers: [
        {
          text: 'Order #',
          sortable: false,
          width: 100,
          value: 'orderNumber'
        },
        {
          text: 'Customer',
          value: 'customerContactDetails.email'
        },
        {
          text: 'Creator',
          value: 'creatorEmail'
        },
        {
          text: 'Date Created',
          value: 'dateCreated'
        },
        {
          text: 'Region',
          value: 'region'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  created() {
    this.$bind(
      'missingShipments',
      this.$db
        .collection('orders')
        .where('type', '==', 'mega-admin')
        .where('shipmentsCreated', '==', false)
        .where('region', '!=', 'us')
    )
  },
  computed: {
    filteredOrders() {
      return this.missingShipments.filter(o => {
        return !(o.foc == false && o.numPayments == 0)
      })
    }
  },
  methods: {
    async checkShipments(order) {
      const shipments = await this.$db
        .collection('shipments')
        .where('orderID', '==', order.id)
        .get()
      this.$store.commit('openSnackbar', {
        snackbarText: 'Number of Shipments: ' + shipments.docs.length
      })
    },
    async markShipmentsCreated(order) {
      await this.$db
        .collection('orders')
        .doc(order.id)
        .update({
          shipmentsCreated: true
        })
    },
    async manuallyCreateShipments(order) {
      try {
        const manuallyCreateShipmentsFunc = this.$firebase
          .functions()
          .httpsCallable('manuallyCreateShipmentsFromOrderID')
        await manuallyCreateShipmentsFunc(order.id)
      } catch (e) {
        this.$store.commit('openSnackbar', {
          snackbarText: 'Error:' + e.message
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#production-table {
  width: 100%;
}
</style>
