var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"customerQuote"}},[_c('v-alert',{staticClass:"ma-0 mb-3 caption",attrs:{"value":!_vm.loading && !_vm.activeVersion.pricing,"type":"info","color":"accent"}},[_vm._v(" Woops! This quote has been unpublished. If you're trying to view this quote please inform a member our quote team who will re-publish it for you 👍 ")]),(_vm.$vuetify.breakpoint.smAndUp && !_vm.loading)?_c('div',{staticClass:"toolbar no-print"},[_c('img',{staticClass:"mr-4",attrs:{"src":require("@/assets/p-logo.png"),"alt":"Plykea","height":"30px"}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.activeVersion.customerCadShared && !_vm.checkoutActive)?_c('v-tabs',{attrs:{"height":80,"background-color":"primary","dark":"","slider-color":"accent"},on:{"change":_vm.onTabChange}},[_c('v-tab',{key:0},[_vm._v("Quote")]),_c('v-tab',{key:1},[_vm._v("Visual")])],1):(_vm.checkoutActive)?_c('v-btn',{staticClass:"mx-0 px-0",attrs:{"color":"accent","text":""},on:{"click":function () {
            _vm.currentTab = 0
            _vm.checkoutActive = false
          }}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v("Back ")],1):_vm._e()],1),_c('v-spacer'),_c('transition',{attrs:{"name":"fade"}},[(!_vm.quote.invoiceID && _vm.versionNames.length > 1)?_c('v-select',{staticClass:"mt-0 pt-0 body-1",attrs:{"dark":"","dense":"","hide-details":"","items":_vm.versionNames},model:{value:(_vm.selectedVersion),callback:function ($$v) {_vm.selectedVersion=$$v},expression:"selectedVersion"}}):_vm._e()],1),(
        _vm.$vuetify.breakpoint.smAndUp &&
          !_vm.checkoutActive &&
          !(_vm.quote.orders && _vm.quote.orders[_vm.quote.versions[_vm.selectedVersion].id])
      )?_c('v-btn',{staticClass:"ma-0 ml-3",attrs:{"color":"accent","disabled":!_vm.activeVersion.allowOrder || (_vm.quote.invoices && _vm.quote.invoices[_vm.activeVersion.id]),"depressed":"","dark":""},on:{"click":function($event){_vm.checkoutActive = true}}},[_vm._v("Place Order ")]):(_vm.$vuetify.breakpoint.smAndUp && !_vm.checkoutActive && _vm.order && _vm.order.balanceOutstanding && !_vm.order.foc)?_c('v-btn',{staticClass:"ma-0 ml-3",attrs:{"color":"accent","depressed":"","dark":""},on:{"click":function($event){_vm.checkoutActive = true}}},[_vm._v("Pay Balance ")]):(_vm.quote.orders && _vm.quote.orders[_vm.quote.versions[_vm.selectedVersion].id])?_c('h3',{staticClass:"mx-4",staticStyle:{"color":"white"},attrs:{"color":"white"}}):_vm._e()],1):_vm._e(),(!_vm.loading && _vm.activeVersion.pricing)?_c(_vm.publishedComponent,{key:_vm.selectedVersion,tag:"component",attrs:{"quote":_vm.quote,"version":_vm.activeVersion,"customerEmail":_vm.quote.customerEmail,"customerView":true,"order":_vm.order},on:{"orderCreated":_vm.onOrderCreated}}):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }