import Vue from 'vue'
import numeral from 'numeral'

numeral.register('locale', 'GBP', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '£'
  }
})
numeral.register('locale', 'EUR', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '€'
  }
})
numeral.register('locale', 'USD', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
})
numeral.locale('GBP')
Vue.prototype.$numeral = numeral
