<template>
  <div id="payment">
    <div v-if="allowBankTransfer" class="mb-6">
      <h3>Payment Method</h3>

      <v-btn-toggle v-model="form.bankTransfer" class="d-flex mt-4">
        <v-btn @click="mountStripe">Card</v-btn>
        <v-btn @click="unmountStripe">Bank Transfer</v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="!form.bankTransfer">
      <h3>Card Details</h3>
      <div class="form-group">
        <v-text-field
          v-model="stripeObjects.cardName"
          label="Name on Card"
          v-validate="'required'"
          data-vv-name="Name on Card"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('Name on Card')"
          required
        >
        </v-text-field>
        <v-row wrap>
          <v-col cols="11">
            <div class="stripe-input">
              <div class="fancy-label" :class="{ tiny: cardNumberFocussed }">
                Card Number
              </div>
              <div ref="cardNumber"></div>
            </div>
          </v-col>
          <v-col cols="1" d-flex align-center class="mt-2">
            <transition name="fade">
              <img :src="cardIcons[cardType]" alt="Payment Card Icon" height="20" class="" />
            </transition>
          </v-col>
          <v-col cols="12" class="py-0"
            ><div class="error--text v-messages">
              {{ cardErrors.cardNumber }}
            </div></v-col
          >
        </v-row>
        <v-row wrap>
          <v-col cols="12" md="6">
            <div class="stripe-input">
              <div class="fancy-label" :class="{ tiny: cardExpiryFocussed }">
                Expiration Date (MM/YY)
              </div>
              <div ref="cardExpiry"></div>
            </div>
          </v-col>
          <v-col xs10 md5>
            <div class="stripe-input">
              <div class="fancy-label" :class="{ tiny: cardCvcFocussed }">
                Security Code
              </div>
              <div ref="cardCvc"></div>
            </div>
          </v-col>
          <v-col xs2 md1 d-flex align-center class="mt-2">
            <transition name="fade">
              <img :src="cardIcons.security" alt="Payment Card Icon" height="20" />
            </transition>
          </v-col>
          <v-col cols="12" md="6" class="py-0"
            ><div class="error--text v-messages">
              {{ cardErrors.cardExpiry }}
            </div></v-col
          >
          <v-col cols="12" md="6" class="py-0"
            ><div class="error--text v-messages">
              {{ cardErrors.cardCvc }}
            </div></v-col
          >
        </v-row>
      </div>
    </div>
    <v-form ref="form" id="order-form">
      <h3 class="mt-4">Billing Address</h3>
      <div class="form-group">
        <v-switch label="Same as delivery" v-model="form.billingSameAsDelivery" hide-details> </v-switch>
        <transition name="fade">
          <div v-if="!form.billingSameAsDelivery">
            <v-text-field
              v-model="form.billingAddressLine1"
              label="Address Line 1"
              v-validate="'required'"
              data-vv-name="Billing Address Line 1"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Billing Address Line 1')"
              required
            >
            </v-text-field>
            <v-text-field v-model="form.billingAddressLine2" label="Address Line 2"> </v-text-field>
            <v-text-field v-model="form.deliveryCity" label="City"> </v-text-field>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="form.deliveryState"
                  :items="$utils.usStateCodes"
                  label="State"
                  v-validate="'required'"
                  data-vv-name="Delivery State"
                  data-vv-validate-on="blur"
                  :error-messages="errors.collect('Delivery State')"
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.deliveryPostcode"
                  label="Zip code"
                  v-validate="'required'"
                  data-vv-name="Delivery Zip code"
                  data-vv-validate-on="blur"
                  :error-messages="errors.collect('Delivery Zip code')"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </transition>
      </div>
      <h3 class="mt-4">Sign Off</h3>
      <div class="form-group">
        <v-checkbox
          class="sign-off"
          v-model="form.approved"
          label="I confirm I have checked the items contained within the original quotation are correct. I understand that any changes required after placing my order may incur additional costs and / or delay my order"
          color="accent"
          v-validate="'required:true'"
          data-vv-name="Customer Approval"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('Customer Approval')"
        ></v-checkbox>
      </div>
    </v-form>
    <div class="mt-5" style="display: flex; justify-content: space-between">
      <v-btn color="primary" text class="mx-0 px-0" @click="$emit('back')"
        ><v-icon>mdi-chevron-left</v-icon>Back to information</v-btn
      >
      <v-btn color="accent" depressed class="mx-0" @click="submit">{{ form.bankTransfer ? 'Continue' : 'Pay' }}</v-btn>
    </div>
  </div>
</template>

<script>
import monodefault from 'payment-icons/min/mono/default.svg'
import security from 'payment-icons/min/mono/security-code.svg'
import visa from 'payment-icons/min/single/visa.svg'
import mastercard from 'payment-icons/min/flat/mastercard.svg'
import amex from 'payment-icons/min/flat/amex.svg'
import discover from 'payment-icons/min/flat/discover.svg'
import diners from 'payment-icons/min/flat/diners.svg'
import jcb from 'payment-icons/min/flat/jcb.svg'
import unionpay from 'payment-icons/min/flat/unionpay.svg'

export default {
  name: 'PaymentUS',
  props: ['form', 'elements', 'stripeObjects', 'allowBankTransfer'],
  data() {
    return {
      cardErrors: {},
      cardIcons: {
        visa: visa,
        mastercard: mastercard,
        amex: amex,
        discover: discover,
        diners: diners,
        jcb: jcb,
        unionpay: unionpay,
        unknown: monodefault,
        security: security
      },
      stripeElementBaseStyle: {
        base: {
          fontSize: '16px',
          fontFamily: 'proxima-nova, sans-serif',
          fontSmoothing: 'antialiased',
          letterSpacing: '0.5px'
        }
      },
      cardNumberFocussed: false,
      cardExpiryFocussed: false,
      cardCvcFocussed: false,
      cardType: 'unknown'
    }
  },
  mounted() {
    this.mountStripe()
  },
  beforeDestroy() {
    if (!this.form.bankTransfer) {
      this.unmountStripe()
    }
  },
  computed: {
    cardComplete() {
      return (
        Object.keys(this.cardErrors).length == 0 &&
        this.stripeObjects.cardNumber._complete &&
        this.stripeObjects.cardExpiry._complete &&
        this.stripeObjects.cardCvc._complete
      )
    }
  },
  methods: {
    async mountStripe() {
      await this.$nextTick()

      this.setupStripeElement('cardNumber', 'cardNumberFocussed')
      this.setupStripeElement('cardExpiry', 'cardExpiryFocussed')
      this.setupStripeElement('cardCvc', 'cardCvcFocussed')
    },
    unmountStripe() {
      this.unmountStripeElement('cardNumber')
      this.unmountStripeElement('cardExpiry')
      this.unmountStripeElement('cardCvc')
    },
    setupStripeElement(el, focus) {
      this.stripeObjects[el] = this.elements.create(el, {
        style: this.stripeElementBaseStyle,
        placeholder: ''
      })
      this.stripeObjects[el].mount(this.$refs[el])

      this.stripeObjects[el].addEventListener('change', event => {
        if (event.brand) this.cardType = event.brand

        if (event.error) {
          if (!this.cardErrors[event.elementType]) {
            this.$set(this.cardErrors, event.elementType, event.error.message)
          } else {
            this.cardErrors[event.elementType] = event.error.message
          }
        } else if (this.cardErrors[event.elementType]) {
          this.$delete(this.cardErrors, event.elementType)
        }
      })
      this.stripeObjects[el].addEventListener('focus', () => {
        this[focus] = true
      })
      this.stripeObjects[el].addEventListener('blur', () => {
        this[focus] = !this.stripeObjects[el]._empty
      })
    },
    unmountStripeElement(el) {
      this.stripeObjects[el].destroy()
      this.stripeObjects[el].removeEventListener('change')
      this.stripeObjects[el].removeEventListener('focus')
      this.stripeObjects[el].removeEventListener('blur')
    },
    submit() {
      this.$validator.validateAll().then(result => {
        if (result && (this.cardComplete || this.form.bankTransfer)) {
          this.$emit('success')
        }
      })
    }
  }
}
</script>

<style lang="scss">
#payment {
  .stripe-input {
    position: relative;
    margin-top: 16px;
    border-bottom: solid 1px #949494;
    .fancy-label {
      font-size: 16px;
      position: absolute;
      top: 5px;
      opacity: 0.7;
      transition: transform 0.25s, opacity 0.5s;
      transform-origin: top left;
      &.tiny {
        opacity: 1;
        transform: scale(0.7) translate(0, -25px);
      }
    }
  }
  .sign-off {
    .v-input__slot {
      align-items: flex-start;
    }
  }
  .StripeElement {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .v-input--switch {
    margin: 10px 0;
  }
  .v-input--checkbox {
    margin-top: 5;
  }
}
</style>
