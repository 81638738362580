import paper from 'paper'

export default class SpacerPanel {
  constructor(name, data, region) {
    this.data = data
    this.region = region
    this.materialThickness = 20
    this.labelSize = 40
    this.labelPadding = 75
    this.data.l = parseFloat(data.l)
    this.createSpacer()
    var label = this.getLabel()
    this.outline.name = 'outline'
    this.dimension = this.getDimension()
    this.dimension.name = 'dimension'
    this.item = new paper.Group([this.outline, this.dimension, label])
    this.item.name = name
    this.item.data.originalBounds = this.item.bounds
  }

  createSpacer() {
    this.outline = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(this.materialThickness, this.data.l))
    this.outline.fillColor = '#FBE6CB'
    this.outline.strokeColor = 'black'
    if (this.data.visualHoz) {
      this.outline.translate(-this.materialThickness / 2, -this.data.l / 2) // translate centre point of SP to rotating origin (0,0)
      this.outline.rotate(90)
      this.outline.translate(this.data.l / 2, this.materialThickness / 2) // translate left corner of SP to origin (0,0)
    }
  }

  getLabel() {
    var text = new paper.PointText(new paper.Point(0, 0))
    text.justification = 'center'
    text.fillColor = 'black'
    text.selectedColor = 'transparent'
    text.fontSize = this.labelSize
    text.fontFamily = 'Roboto Mono'
    text.content = `SP ${this.data.location}`

    if (this.data.visualHoz) {
      text.translate(this.data.l / 2, text.bounds.height + this.labelPadding / 2)
    } else {
      text.translate(this.materialThickness / 2, this.data.l + text.bounds.height / 2 + this.labelPadding)
      text.rotate(-90)
    }

    return text
  }

  getDimension() {
    let length
    this.region == 'us' ? (length = Math.round((this.data.l / 25.4) * 100) / 100) : (length = this.data.l)
    let dimension = new paper.PointText(new paper.Point(this.materialThickness / 2, 0))
    dimension.justification = 'center'
    dimension.fillColor = 'transparent'
    dimension.selectedColor = 'transparent'
    dimension.fontSize = this.labelSize
    dimension.fontFamily = 'Roboto Mono'
    dimension.content = `L${length.toString().toUpperCase()}`

    if (this.data.visualHoz) {
      dimension.translate(this.data.l / 2, -dimension.bounds.height / 2)
    } else {
      dimension.translate(0, -dimension.bounds.width * 1.5)
      dimension.rotate(-90)
    }
    return dimension
  }
}
