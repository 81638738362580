import MasterPricing from '@/js/pricing/MasterPricing'
import utils from '@/js/utils'

/**
 * Mu Principles
 */
// 1 Mu = 120s = avg of edge sand and wax per linear metre
// aka. time for edge sand and wax for a 1000mm x 1000mm no handle door.
// therefore laminate multiplier (includes trim, tape, oil ontop of edge sand and wax) = 295s / 120s(1Mu) = 2.46 factor
// veneer edge (only includes edge and sand) = 120s = 1Mu, so factor of 1
// veneer double surface area factor = 258s / 120s(1Mu) = 2.15 factor

const laminateEdgeMuFactor = 2.46
const veneerEdgeMuFactor = 1
const veneerDoubleSurfaceMuFactor = 2.15
const singleMuPerimeter = 1000
const doubleMuArea = 2000000 // double-sided

export default {
  get(items, materials, handleOptions) {
    const o = {}

    if (!items) return o

    materials.forEach(material => {
      if (!material.value || !material.thickness || !material.w || !material.h) return

      let materialMu = 0

      for (let item of items) {
        // ignore any imcomplete items
        if (item.itemNum == undefined || item.w == undefined || item.h == undefined || item.qty == undefined) continue
        // ignore if item not using this material
        if (item.material !== material.id) continue

        // calculate total surface area and perimeter of items
        const width = parseInt(item.w)
        const height = parseInt(item.h)
        const qty = parseInt(item.qty)
        const perimeter = (width + height) * 2 * qty
        const area = item?.coverPanelType && !item?.doubleSided ? width * height * qty : width * height * 2 * qty

        // calculate EDGE & SURFACE Mu Quantity
        const materialEdgeFactor = material.hasGrain ? veneerEdgeMuFactor : laminateEdgeMuFactor
        materialMu += (perimeter / singleMuPerimeter) * materialEdgeFactor
        materialMu += (area / doubleMuArea) * veneerDoubleSurfaceMuFactor

        // handle
        if (item.handle) {
          let handle = handleOptions.find(handle => {
            return handle.id == item.handle
          })
          let masterHandle = MasterPricing.getHandle(handle.value)

          // Linear Handle Mu Calculations (J-profile & Invisipull)
          if (['j', 'invisipull', 'invisipull-short'].includes(handle.value)) {
            // find orientation (for J-Profile)
            if (handle.orientation !== undefined) {
              switch (handle.orientation) {
                case 'h':
                  materialMu += masterHandle.mu * (item.w / 1000) * qty
                  break
                case 'v':
                  materialMu += masterHandle.mu * (item.h / 1000) * qty
                  break
              }
            } else {
              // invisipull has no orientation, always width
              materialMu += masterHandle.mu * (item.w / 1000) * qty
            }
          } else {
            materialMu += masterHandle.mu * qty
          }
        }
      }

      o[material.id] = utils.toFixedNumber(materialMu, 2)
    })

    return o
  }
}
