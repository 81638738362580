let makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class HalfQuartArc {
  constructor(front, handleType, handleWidth, region) {
    // unique
    this.pocketOffset = 19.5
    this.shadowOffset = 1.5
    this.millOffset = 5
    this.pocketToolRad = 4
    // common
    this.models = {}
    this.handleType = handleType
    this.width = handleWidth
    this.insetX = 35
    this.insetY = -this.shadowOffset - 0.025
    this.front = front
    this.region = region

    switch (handleType) {
      case 'lrgHalfArc':
      case 'halfArc':
        this.handleRad = handleWidth / 2
        this.subtractRecWidth = this.handleRad * 2
        this.subtractRecHeight = this.handleRad + this.shadowOffset
        this.subtractRecPosX = -this.handleRad
        this.subtractRecPosY = -this.shadowOffset

        this.cbRad = handleWidth / 2
        this.subtractCoreboxRecWidth = this.cbRad * 2
        this.subtractCoreboxRecHeight = this.cbRad + this.shadowOffset
        this.subtractCoreboxRecPosX = -this.cbRad
        this.subtractCoreboxRecPosY = -this.shadowOffset

        this.pocketRad = handleWidth / 2 + this.pocketOffset
        this.subtractPocketRecWidth = handleWidth + this.pocketOffset * 2
        this.subtractPocketRecHeight = handleWidth / 2 + this.pocketOffset
        this.subtractPocketRecPosX = -handleWidth / 2 - this.pocketOffset
        this.subtractPocketRecPosY = this.shadowOffset

        this.millRad = handleWidth / 2 - this.millOffset
        this.subtractMillRecWidth = this.millRad * 2
        this.subtractMillRecHeight = handleWidth / 2 - this.millOffset + this.shadowOffset
        this.subtractMillRecPosX = -this.millRad
        this.subtractMillRecPosY = this.shadowOffset
        break
      case 'lrgQuartArc':
      case 'quartArc':
        this.handleRad = handleWidth
        this.subtractRecWidth = this.handleRad - this.shadowOffset
        this.subtractRecHeight = this.handleRad - this.shadowOffset
        this.subtractRecPosX = -this.handleRad
        this.subtractRecPosY = -this.handleRad

        this.cbRad = handleWidth
        this.subtractCoreboxRecWidth = this.cbRad - this.shadowOffset
        this.subtractCoreboxRecHeight = this.cbRad - this.shadowOffset
        this.subtractCoreboxRecPosX = -this.cbRad
        this.subtractCoreboxRecPosY = -this.cbRad

        this.pocketRad = handleWidth + this.pocketOffset
        this.subtractPocketRecWidth = this.pocketRad + this.pocketToolRad
        this.subtractPocketRecHeight = this.pocketRad + this.pocketToolRad
        this.subtractPocketRecPosX = -this.pocketRad
        this.subtractPocketRecPosY = -this.pocketRad

        this.millRad = handleWidth - this.millOffset
        this.subtractMillRecWidth = this.millRad + this.pocketToolRad
        this.subtractMillRecHeight = this.millRad + this.pocketToolRad
        this.subtractMillRecPosX = -this.millRad
        this.subtractMillRecPosY = -this.millRad
        break
    }

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    // combine(modelA, modelB, A inside B, A outside B, B inside A, B outside A)
    const handleCircle = new makerjs.models.Ellipse(this.handleRad, this.handleRad)
    const subtractRec = new makerjs.models.Rectangle(this.subtractRecWidth, this.subtractRecHeight)
    subtractRec.origin = [this.subtractRecPosX, this.subtractRecPosY]
    if (this.handleType == 'halfArc' || this.handleType == 'lrgHalfArc') {
      this.front.handleOutline = makerjs.model.combine(handleCircle, subtractRec, false, true, true, false)
    } else if (this.handleType == 'quartArc' || this.handleType == 'lrgQuartArc') {
      this.front.handleOutline = makerjs.model.combine(handleCircle, subtractRec, true, false, true, false)
    }
    this.front.handleOutline.layer = layerNames.cutout.label
    this.front.handleOutline.origin = this.origin

    const coreboxCircle = new makerjs.models.Ellipse(this.cbRad, this.cbRad)
    const subtractCoreboxRec = new makerjs.models.Rectangle(this.subtractCoreboxRecWidth, this.subtractCoreboxRecHeight)
    subtractCoreboxRec.origin = [this.subtractCoreboxRecPosX, this.subtractCoreboxRecPosY]
    if (this.handleType == 'halfArc' || this.handleType == 'lrgHalfArc') {
      this.models.corebox = makerjs.model.combine(coreboxCircle, subtractCoreboxRec, false, true, false, false)
    } else if (this.handleType == 'quartArc' || this.handleType == 'lrgQuartArc') {
      this.models.corebox = makerjs.model.combine(coreboxCircle, subtractCoreboxRec, true, false, false, false)
    }
    this.models.corebox.layer = layerNames.corebox.label
    this.models.corebox.origin = this.origin

    const pocketCircle = new makerjs.models.Ellipse(this.pocketRad, this.pocketRad)
    const subtractPocketRec = new makerjs.models.Rectangle(this.subtractPocketRecWidth, this.subtractPocketRecHeight)
    subtractPocketRec.origin = [this.subtractPocketRecPosX, this.subtractPocketRecPosY]
    if (this.handleType == 'halfArc' || this.handleType == 'lrgHalfArc') {
      this.models.pocket = makerjs.model.combine(pocketCircle, subtractPocketRec, false, true, true, false)
    } else if (this.handleType == 'quartArc' || this.handleType == 'lrgQuartArc') {
      this.models.pocket = makerjs.model.combine(pocketCircle, subtractPocketRec, true, false, true, false)
    }
    this.models.pocket.layer = layerNames.cbHandlePocket.label
    this.models.pocket.origin = this.origin

    if (this.region == 'uk') {
      const millCircle = new makerjs.models.Ellipse(this.millRad, this.millRad)
      const subtractMillRec = new makerjs.models.Rectangle(this.subtractMillRecWidth, this.subtractMillRecHeight)
      subtractMillRec.origin = [this.subtractMillRecPosX, this.subtractMillRecPosY]
      if (this.handleType == 'halfArc' || this.handleType == 'lrgHalfArc') {
        this.models.handleMill = makerjs.model.combine(millCircle, subtractMillRec, false, true, true, false)
      } else if (this.handleType == 'quartArc' || this.handleType == 'lrgQuartArc') {
        this.models.handleMill = makerjs.model.combine(millCircle, subtractMillRec, true, false, true, false)
      }
      this.models.handleMill.layer = layerNames.handleMill.label
      this.models.handleMill.origin = this.origin
    }
  }
}
