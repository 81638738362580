<template>
  <div
    class="mega-cell"
    :class="{
      selected: selected,
      focussed: focussed,
      outOfBounds: outOfBounds,
      warn: warn
    }"
    @mousedown="mousedown"
  >
    <input
      ref="field"
      v-model="convertedDbValue"
      @focus="setFocus"
      @dblclick="setFocus"
      @change="onChange"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: 'MegaCell',
  props: {
    row: Number,
    col: Number,
    data: [Number, String],
    dataType: {
      type: String,
      default: 'Number'
    },
    presets: Number,
    unit: String,
    placeholder: String,
    min: Number,
    max: Number,
    disabled: Boolean,
    warn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: null
    }
  },
  created() {
    this.inputValue = this.convertedDbValue
  },
  computed: {
    focussed() {
      return (
        this.row == this.$store.state.focussedCell.row &&
        this.col == this.$store.state.focussedCell.col &&
        !this.disabled
      )
    },
    selected() {
      return (
        this.row == this.$store.state.selectedCell.row &&
        this.col == this.$store.state.selectedCell.col &&
        !this.focussed &&
        !this.disabled
      )
    },
    outOfBounds() {
      if ((this.data > this.max || this.data < this.min) && !this.focussed) {
        return true
      } else {
        return false
      }
    },
    convertedDbValue: {
      get: function() {
        if (!this.data) return null
        var fixedData = this.$utils.toFixedNumber(this.data / 25.4, 3)
        var adjustedData = fixedData % 1 == 0 ? parseInt(fixedData) : fixedData
        return this.dataType == 'Number' && this.unit == 'inch' ? adjustedData : this.data
      },
      set: function(value) {
        if (this.dataType !== 'String') {
          var val = this.unit == 'inch' ? this.$utils.toFixedNumber(value * 25.4, 3) : parseInt(value)
          this.$emit('update:data', val)
        } else {
          this.$emit('update:data', value)
        }
      }
    }
  },
  watch: {
    focussed(to) {
      if (to) {
        this.$refs.field.focus()
        if (this.$store.state.prevUserInput) {
          this.convertedDbValue = null
          this.$store.commit('clearPrevUserInput')
        }
      } else {
        this.$refs.field.blur()
      }
    },
    presets(to) {
      if (to) {
        this.convertedDbValue = this.presets
      }
    }
  },
  methods: {
    mousedown(e) {
      e.preventDefault()
      this.$store.commit('setSelectedCell', { row: this.row, col: this.col })
    },
    setFocus() {
      this.$store.commit('setFocussedCell', { row: this.row, col: this.col })
    },
    onChange() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss">
.mega-cell {
  width: 100%;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 1px;

  &.focussed {
    border: solid 0px blue;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  &.selected {
    background: #ffd4cb;
    box-shadow: inset 0 0 0px #1c3145;
  }
  &.outOfBounds,
  &.warn {
    box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
  }

  input {
    font-size: 12px;
    width: 100%;
    background: none;
    border: none;
    cursor: default;
    &:focus {
      outline: none;
    }
  }
}
</style>
