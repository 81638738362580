<template>
  <div class="pa-5">
    <div v-if="readyForAction">
      <!-- HEADER -->
      <v-container fluid>
        <v-row class="align-baseline mb-8">
          <v-col cols="6">
            <h1>Packing List</h1>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <img src="@/assets/final-logo-tight.png" alt="Plykea" width="200px" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="item py-1">
              <div class="caption text--disabled">
                Customer Name
                <v-icon :size="14" class="pl-2 no-print" @click="editNameDialog = true">mdi-pencil</v-icon>
              </div>
              <div class="body-2">
                {{ order.customerContactDetails.firstName + ' ' + order.customerContactDetails.lastName }}
              </div>
            </div>
            <div class="item py-1">
              <div class="caption text--disabled">Customer Email</div>
              <div class="body-2">{{ shipment.customerEmail }}</div>
            </div>
            <div class="item py-1">
              <div class="caption text--disabled">
                Customer Phone
                <v-icon :size="14" class="pl-2 no-print" @click="editPhoneDialog = true">mdi-pencil</v-icon>
              </div>
              <div class="body-2">
                {{ order.customerContactDetails.mobile || order.customerContactDetails.phone1 }}
              </div>
            </div>
            <div class="item py-2">
              <div class="caption text--disabled">
                Delivery Address
                <v-icon :size="14" class="pl-2 no-print" @click="editAddressDialog = true">mdi-pencil</v-icon>
              </div>
              <div class="body-2">
                <div>
                  {{
                    !order.customerContactDetails.deliverySameAsBilling
                      ? order.customerContactDetails.deliveryAddressLine1
                      : order.customerContactDetails.billingAddressLine1
                  }}
                </div>
                <div>
                  {{
                    !order.customerContactDetails.deliverySameAsBilling
                      ? order.customerContactDetails.deliveryAddressLine2
                      : order.customerContactDetails.billingAddressLine2
                  }}
                </div>
                <div>
                  {{
                    !order.customerContactDetails.deliverySameAsBilling
                      ? order.customerContactDetails.deliveryPostcode
                      : order.customerContactDetails.billingPostcode
                  }}
                </div>
                <div>
                  {{
                    order.customerContactDetails.deliverySameAsBilling
                      ? order.customerContactDetails.deliveryCountry
                      : order.customerContactDetails.billingCountry
                  }}
                </div>
              </div>
            </div>
            <div class="item py-2">
              <div class="caption text--disabled">
                Delivery Instructions
                <v-icon :size="14" class="pl-2 no-print" @click="editDeliveryInstructions = true">mdi-pencil</v-icon>
              </div>
              <div class="body-2">
                {{ order.customerContactDetails.deliveryInstructions || 'None' }}
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="text-right">
            <div class="item py-1">
              <div class="caption text--disabled">Order Number</div>
              <div class="body-1">
                {{ order.orderNumber || order.xeroInvoiceNumber }}
              </div>
            </div>

            <div class="item py-1">
              <div class="caption text--disabled">Owner</div>
              <div class="body-2">{{ order.creatorEmail }}</div>
            </div>
            <div class="item py-1 no-print">
              <div class="caption text--disabled">Intercom</div>
              <a href="order.customerContactDetails.intercomConversation" target="_blank" class="body-2"
                >Open Conversation</a
              >
            </div>

            <div class="item py-1">
              <div class="caption text--disabled">Dispatch Date</div>
              <div class="body-2">
                {{ $utils.convertDate(shipment.dateScheduled) }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- NOTES -->
      <v-container fluid v-if="version.notes">
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">Notes</span>
            <v-divider class="mt-2"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>{{ version.notes }}</p>
          </v-col>
        </v-row>
      </v-container>

      <!-- ADDITIONAL ITEMS -->
      <v-container fluid class="text-body-2">
        <v-row>
          <v-col cols="12" class="pb-1">
            <span class="text-h6 mr-2">Additional Items</span>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0 justify-space-between">
          <v-col cols="6" class="text--disabled text-caption">Item</v-col>
          <v-col cols="2" class="text--disabled text-caption text-right">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row v-for="item in additionalItemsPacked" :key="item.id" class="py-0 my-0 justify-space-between">
          <v-col cols="6">{{ item.description }}</v-col>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>

        <div v-if="numRequiredHandles && numRequiredHandles.length > 0">
          <v-row v-for="item in numRequiredHandles" :key="item.id" class="py-0 my-0">
            <v-col cols="6">{{ item.count }} x {{ item.name }}</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>
        </div>

        <v-row v-if="numPlinthClips > 0" class="py-0 my-0">
          <v-col cols="6">{{ numPlinthClips }} x Plinth Clips</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>

        <v-row v-if="numRequiredJoiningBrackets > 0" class="py-0 my-0">
          <v-col cols="6">{{ numRequiredJoiningBrackets }} x Joining Brackets</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>

        <!-- URTIL, TRIM-FIT, PANTRY ADDITIONAL ITEMS -->
        <v-row
          v-if="(version.urtils && version.urtils.length > 0) || (version.pantries && version.pantries.length > 0)"
        >
          <!-- INSTALLATION INSTRUCTIONS (one for each type) -->
          <template v-for="urtil in version.urtils">
            <v-col cols="6"
              >{{ urtil.preAssembled ? 'Pre-Assembled' : urtil.trimming ? 'Trim-Fit' : 'Flat-Pack' }} Ürtil installation
              {{ urtil.preAssembled ? '(only)' : urtil.trimming ? '' : '& flat-pack assembly' }} instructions</v-col
            >
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </template>

          <template v-if="version.pantries && version.pantries.length > 0">
            <v-col cols="6"
              >Pantry installation {{ this.flatPackPantries.length > 0 ? '& assembly' : '' }} instructions</v-col
            >
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </template>

          <!-- hex key for flat-pack (urtil only)-->
          <template v-if="version.urtils.filter(urtil => !urtil.preAssembled).length > 0 && version.urtils.length > 0">
            <v-col cols="6">Hex key for Flat-Pack Assembly</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </template>

          <!-- lighting cable cover -->
          <template
            v-if="
              (version.urtils.lighting && version.urtils.lighting.length > 0) ||
                (shelfWithLighting && shelfWithLighting.length > 0)
            "
          >
            <v-col cols="6">Lighting Cable Covers</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </template>

          <!-- BATON -->
          <v-col cols="12">
            <v-row v-for="(urtil, index) in version.urtils" :key="index">
              <v-col cols="6">{{ urtil.w - 30 }}mm Ürtil mounting baton</v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row v-for="(pantry, index) in version.pantries" :key="index">
              <v-col cols="6">{{ pantry.w - 30 }}mm Pantry mounting baton</v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>
          </v-col>

          <!-- SCREWS + PLUGS + CUPS -->
          <!-- wall-baton screws + plug -->
          <v-col cols="6">{{ numRequiredBatonScrews }} off 5 x 80 mm screws</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>

          <v-col cols="6">{{ numRequiredBatonScrews }} Wall Plugs</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>

          <v-col cols="6">{{ numRequiredMetalSpacers }} Metal Spacers</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>

          <v-col cols="6">{{ numRequiredBackScrews + numRequiredBatonScrews }} off 4 x 25 mm screws</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>

          <v-col cols="6">{{ numRequiredBatonScrews }} Brass screw cups</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>

          <template v-if="numRequiredJointScrews > 0">
            <v-col cols="6">{{ numRequiredJointScrews }} off 4 x 50 mm screws</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </template>

          <template v-if="numRequiredShelvingPins > 0">
            <v-col cols="6">{{ numRequiredShelvingPins }} Shelving pins</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </template>
        </v-row>
      </v-container>

      <!-- FRONTS -->
      <v-container
        v-for="material in version.materials"
        v-if="
          (shipment.lineItems.includes('fronts') || shipment.lineItems.includes('coverPanels')) &&
            version.pricing.fronts[material.id] &&
            version.pricing.fronts[material.id].total > 0 &&
            material.thickness !== 4 &&
            material.thickness !== 6
        "
        :key="material.id"
        fluid
        class="text-body-2"
      >
        <v-row>
          <v-col cols="12" class="pb-2">
            <span class="text-h6 mr-2" v-if="material.colourCode"
              >{{ material.thickness }}mm {{ material.label }} - {{ material.colourCode }}</span
            >
            <span class="text-h6 mr-2" v-else
              >{{ material.thickness }}mm {{ material.label }}{{ material.unfinished ? ' (Unfinished)' : '' }}</span
            >
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0">
          <v-col cols="3" class="text--disabled text-caption">Type</v-col>
          <v-col cols="2" class="text--disabled text-caption">Dimensions (mm)</v-col>
          <v-col cols="3" class="text--disabled">Feature</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="text--disabled text-caption text-center">Qty</v-col>
          <v-col cols="1" class="text--disabled text-caption text-center">Ikea Plan Location</v-col>
          <v-col cols="2" class="text--disabled text-caption text-right text-truncate">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row
          v-for="front in frontsPackingList"
          v-if="front.material == material.id"
          :key="front.id"
          class="py-0 my-0"
        >
          <v-col cols="3" class="py-1"
            >{{ front.specialLabel ? front.specialLabel : productData.getFrontTypeLabel(front.frontType)
            }}{{
              front.joiningPos && ['top', 'mid', 'bot'].includes(front.joiningPos)
                ? ' (' + getPositionName(front.joiningPos) + ')'
                : ''
            }}{{ getMaterialSides(front.material) == 1 ? ' [Single-Sided]' : '' }}</v-col
          >
          <v-col cols="2" class="py-1">{{ front.w }} x {{ front.h }}</v-col>
          <v-col cols="3" class="py-1">{{ front.handle ? getHandleName(front.handle) + ' Handle' : 'N/A' }}</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="py-1 value d-flex justify-center">{{ front.qty }}</v-col>
          <v-col cols="1" class="py-1 value d-flex justify-center">{{ front.itemNum }}</v-col>
          <v-col cols="2" class="py-1 value d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>

        <v-row
          v-for="coverPanel in version.coverPanels"
          v-if="coverPanel.material == material.id"
          :key="coverPanel.id"
          class="py-0 my-0"
        >
          <v-col cols="3"
            >{{ productData.getCoverPanelTypeLabel(coverPanel.coverPanelType)
            }}{{ getMaterialSides(coverPanel.material) == 1 ? ' [Single-Sided]' : '' }}</v-col
          >
          <v-col cols="2">{{ coverPanel.w }} x {{ coverPanel.h }}</v-col>
          <v-col cols="3">{{
            coverPanel.lighting
              ? `${coverPanel.lighting}mm Lighting ${
                  coverPanel.lightingRef === 'r'
                    ? `from the Right ${coverPanel.lightingOffset}mm`
                    : coverPanel.lightingRef === 'l'
                    ? `from the Left ${coverPanel.lightingOffset}mm`
                    : 'Centered'
                } `
              : coverPanel.coverPanelType === 'v-plinth' && coverPanel.vent
              ? `${coverPanel.vent}mm Vent from the ${coverPanel.ventRef === 'r' ? 'Right' : 'Left'} ${
                  coverPanel.ventOffset
                }mm`
              : 'n/a'
          }}</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="d-flex justify-center">{{ coverPanel.qty }}</v-col>
          <v-col cols="1" class="d-flex justify-center">{{ coverPanel.itemNum }}</v-col>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>
      </v-container>

      <!-- SPACER PANELS -->
      <v-container
        v-if="shipment.lineItems.includes('spacers') && version.spacerPanels && version.spacerPanels.length > 0"
        fluid
        class="text-body-2"
      >
        <v-row>
          <v-col cols="12" class="pb-2">
            <span class="text-h6 mr-2">Spacer Panels</span>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0">
          <v-col cols="2" class="text--disabled text-caption">Material</v-col>
          <v-col cols="2" class="text--disabled text-caption">Length (mm)</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="text--disabled text-caption">Qty</v-col>
          <v-col cols="2" class="text--disabled text-caption">Ikea Plan Location</v-col>
          <v-col cols="1" class="text--disabled text-caption text-right text-truncate">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row v-for="spacer in version.spacerPanels" :key="spacer.id" class="py-0 my-0">
          <v-col cols="2" class="py-1">{{ productData.getSpacerMaterial(spacer.material).label }}</v-col>
          <v-col cols="2" class="py-1">{{ spacer.l }}</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="py-1">{{ spacer.qty }}</v-col>
          <v-col cols="2" class="py-1">{{ spacer.location }}</v-col>
          <v-col cols="1" class="py-1 d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>
      </v-container>

      <!-- URTILS -->
      <v-container fluid v-if="version.urtils && version.urtils.length > 0" class="text-body-2">
        <v-row>
          <v-col cols="12" class="pb-2">
            <span class="text-h6 mr-2">Ürtils</span>
          </v-col>
        </v-row>
        <div v-for="urtil in version.urtils" :key="urtil.id" class="pb-2">
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-col cols="3" class="text-h8 font-weight-bold text-left">
              {{ '"' + urtil.name.toUpperCase() + '"' }} |
              {{ urtil.preAssembled ? 'Pre-Assembled' : urtil.trimming ? 'Trim-Fit' : 'Flat-Pack' }} Ürtil |
              {{ 'W' + urtil.w + ' x ' + 'H' + urtil.h + ' x ' + 'D' + urtil.d }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="text-h8 font-italic text-left"
              >{{ productData.getUrtilType(urtil.type).label }} -
              {{ getMaterialName(urtil.carcass).split('/')[0] + ' Urtil with ' }}
              {{ urtil.compartments + ' Compartments - ' }}
              {{ urtil.lighting ? urtil.lighting + 'mm Lighting - ' : '' }}
              {{ getMaterialName(urtil.back).split('/')[0] + ' Back - ' }}
              {{
                urtil.shelving
                  ? urtil.shelving[0].toUpperCase() + urtil.shelving.slice(1).toLowerCase() + ' Shelves'
                  : ''
              }}
            </v-col>
            <v-col cols="2" class="text-h8 text-right">Parts quantity: {{ getNumUrtilParts(urtil) }}</v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>

          <v-row class="pl-2">
            <v-col cols="10" class="text--disabled">Part Type</v-col>
            <v-col cols="1" class="text--disabled text-right">Qty</v-col>
            <v-col cols="1" class="text--disabled text-right">Packed/Packages</v-col>
          </v-row>

          <!-- ASSEMBLED URTIL CARCASS -->
          <v-row v-if="!urtil.trimming && urtil.preAssembled" class="pl-2">
            <v-col cols="10"
              >Assembled Ürtil Carcass with
              {{ urtil.compartments > 1 ? urtil.compartments - 1 + ' Dividers' : '' }}</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">{{ 5 + (urtil.compartments - 1) }}</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>

          <!-- TRIM-TO-FIT-URTIL FLAT-PACK CARCASS -->
          <v-row v-if="urtil.trimming || !urtil.preAssembled" class="pl-2">
            <v-col cols="10"
              >Top & Base Ürtil Carcass
              {{ !urtil.preAssembled && !urtil.trimming ? '(flat-packed)' : '(requires on-site trimming)' }}</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">2</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>
          <v-row v-if="urtil.trimming || !urtil.preAssembled" class="pl-2">
            <v-col cols="10"
              >Left & Right Ürtil Carcass Sides
              {{ !urtil.trimming ? '(with connectors)' : '(with pilot holes)' }}</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">2</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>
          <v-row v-if="(urtil.trimming || !urtil.preAssembled) && urtil.compartments > 1" class="pl-2">
            <v-col cols="10">{{ urtil.compartments - 1 }} Dividers (carcass includes slot)</v-col>
            <v-col cols="1" class="value d-flex justify-end">{{ urtil.compartments - 1 }}</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>

          <!-- ASSEMBLED + FLAT-PACKED URTIL SHELVES -->
          <v-row v-if="urtil.shelving" class="pl-2">
            <v-col cols="10">{{
              getNumShelves(urtil) +
                ' x ' +
                urtil.shelving[0].toUpperCase() +
                urtil.shelving.slice(1).toLowerCase() +
                ' Shelves'
            }}</v-col>
            <v-col cols="1" class="value d-flex justify-end">{{ getNumShelves(urtil) }}</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>

          <!-- ASSEMBLED + FLAT-PACKED URTIL DOORS -->
          <v-row v-if="urtil.material1 && urtil.type !== 'o'" class="pl-2">
            <v-col cols="10"
              >{{ getMaterialName(urtil.material1).split('/')[0] }} Ürtil
              {{ urtil.singleDoorSide ? 'Right ' : 'Left ' }}Door 1 (covering {{ urtil.doorCoverageOne }}
              {{ urtil.doorCoverageOne > 1 ? 'compartments' : 'compartment' }})
            </v-col>
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>
          <v-row v-if="urtil.material2 && urtil.type === 't'" class="pl-2">
            <v-col cols="10"
              >{{ getMaterialName(urtil.material2).split('/')[0] }} Ürtil Door 2 (covering {{ urtil.doorCoverageTwo }}
              {{ urtil.doorCoverageTwo > 1 ? 'compartments' : 'compartment' }})</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>

          <!-- TRIM-TO-FIT-URTIL FLAT-PACK BACK + DOORS -->
          <v-row v-if="(urtil.trimming || !urtil.preAssembled) && urtil.back" class="pl-2">
            <v-col cols="10"
              >{{ getMaterialName(urtil.back).split('/')[0] }} Ürtil Back
              {{ !urtil.preAssembled && !urtil.trimming ? '(flat-packed)' : '(requires on-site trimming)' }}</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>
        </div>
      </v-container>

      <!-- PANTRIES -->
      <v-container fluid v-if="version.pantries && version.pantries.length > 0" class="text-body-2">
        <v-row>
          <v-col cols="12" class="pb-2">
            <span class="text-h6 mr-2">{{ version.pantries.length === 1 ? 'Pantry' : 'Pantries' }} </span>
          </v-col>
        </v-row>
        <div v-for="pantry in version.pantries" :key="pantry.id" class="pb-2">
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-col cols="3" class="text-h8 font-weight-bold text-left">
              {{ '"' + pantry.name.toUpperCase() + '"' }} |
              {{ pantry.flatPack ? 'Flat-Packed' : 'Pre-Assembled' }} Pantry |
              {{
                pantry.pantryHinge === 'o'
                  ? 'W' + pantry.w + ' x ' + 'H' + pantry.h + ' x ' + 'D' + (pantry.d + 14)
                  : 'W' + pantry.w + ' x ' + 'H' + pantry.h + ' x ' + 'D' + pantry.d
              }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="text-h8 font-italic text-left"
              >{{ getMaterialName(pantry.carcass).split('/')[0] + ' Pantry Carcass with ' }}
              {{ pantry.base ? getMaterialName(pantry.base).split('/')[0] + ' Base and ' : 'Matching Base and ' }}
              {{ getMaterialName(pantry.back).split('/')[0] + ' Back' }}
              {{ ' - (' + productData.getPantryHinge(pantry.pantryHinge).label + ') ' }}
            </v-col>
            <v-col cols="2" class="text-h8 text-right">Parts quantity: 8</v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>

          <v-row class="pl-2">
            <v-col cols="10" class="text--disabled">Part Type</v-col>
            <v-col cols="1" class="text--disabled text-right">Qty</v-col>
            <v-col cols="1" class="text--disabled text-right">Packed/Packages</v-col>
          </v-row>

          <!-- PANTRY ASSEMBLED CARCASS -->
          <template v-if="!pantry.flatPack">
            <v-row class="pl-2">
              <v-col cols="10">{{
                pantry.base
                  ? `${getMaterialName(pantry.carcass).split('/')[0]} Carcass with ${
                      getMaterialName(pantry.base).split('/')[0]
                    } Base`
                  : `${getMaterialName(pantry.carcass).split('/')[0]} Carcass with Matching Base`
              }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">{{ pantry.base ? 4 : 5 }}</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- PANTRY BASE (if applicable) -->
            <v-row v-if="pantry.base" class="pl-2">
              <v-col cols="10"
                >{{ `${getMaterialName(pantry.base).split('/')[0]} Base`
                }}{{ getMaterialSides(pantry.base) == 1 ? ' [Single-Sided]' : '' }}</v-col
              >
              <v-col cols="1" class="value d-flex justify-end">1</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- PANTRY ADJUSTABLE SHELVES -->
            <v-row class="pl-2">
              <v-col cols="10">{{
                `Adjustable Shelf with D${productData.getPantryShelfDepth(
                  pantry.d,
                  'adj'
                )}mm x W${productData.getPantryShelfWidth(pantry.w, 'adj')}mm`
              }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">2</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- PANTRY FIXED SHELVES -->
            <v-row class="pl-2">
              <v-col cols="10">{{
                `Bottom Fixed Shelf with D${productData.getPantryShelfDepth(
                  pantry.d,
                  'fixed'
                )}mm x W${productData.getPantryShelfWidth(pantry.w, 'fixed')}mm`
              }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">1</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- PANTRY LIGHTING (if applicable) -->
            <v-row v-if="pantry.lighting" class="pl-2">
              <v-col cols="10">{{
                pantry.lighting
                  ? 'Requries 2 x ' + productData.getPantryLightingWidth(pantry.w) + 'mm Lighting Channel '
                  : ''
              }}</v-col>
              <v-col cols="2" class="value d-flex justify-end">(Not Included)</v-col>
            </v-row>
          </template>

          <!-- PANTRY FLAT-PACKED CARCASS (itemized) -->
          <template v-if="pantry.flatPack">
            <!-- TOP PIECE (lighting?) -->
            <v-row class="pl-2">
              <v-col cols="10">{{
                !pantry.lighting
                  ? `${getMaterialName(pantry.carcass).split('/')[0]} Top`
                  : `${getMaterialName(pantry.carcass).split('/')[0]} Top (with ${productData.getPantryLightingWidth(
                      pantry.w
                    )}mm Lighting Channel)`
              }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">1</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- BASE PIECE -->
            <v-row class="pl-2">
              <v-col cols="10">{{
                !pantry.base
                  ? `${getMaterialName(pantry.carcass).split('/')[0]} Base`
                  : `${getMaterialName(pantry.base).split('/')[0]} Base`
              }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">1</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- SIDE PIECE -->
            <v-row class="pl-2">
              <v-col cols="10">{{ `${getMaterialName(pantry.carcass).split('/')[0]} Sides` }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">2</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- BACK PIECE -->
            <v-row class="pl-2">
              <v-col cols="10">{{ `${getMaterialName(pantry.back).split('/')[0]} Back` }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">1</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- ADJUSTABLE SHELVES -->
            <v-row class="pl-2">
              <v-col cols="10">{{
                `${
                  getMaterialName(pantry.carcass).split('/')[0]
                } Adjustable Shelves with D${productData.getPantryShelfDepth(
                  pantry.d,
                  'adj'
                )}mm x W${productData.getPantryShelfWidth(pantry.w, 'adj')}mm`
              }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">2</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- FIXED SHELVES (lighting?)-->
            <v-row class="pl-2">
              <v-col cols="10">{{
                !pantry.lighting
                  ? `${
                      getMaterialName(pantry.carcass).split('/')[0]
                    } Fixed Shelf with D${productData.getPantryShelfDepth(
                      pantry.d,
                      'fixed'
                    )}mm x W${productData.getPantryShelfWidth(pantry.w, 'fixed')}mm`
                  : `${getMaterialName(pantry.carcass).split('/')[0]} Fixed Shelf at D${productData.getPantryShelfDepth(
                      pantry.d,
                      'fixed'
                    )}mm x W${productData.getPantryShelfWidth(
                      pantry.w,
                      'fixed'
                    )}mm (with ${productData.getPantryLightingWidth(pantry.w)}mm Lighting Channel)`
              }}</v-col>
              <v-col cols="1" class="value d-flex justify-end">1</v-col>
              <v-col cols="1" class="value d-flex justify-end"
                ><div class="box"></div>
                <div class="ml-2 box"></div
              ></v-col>
            </v-row>

            <!-- PANTRY LIGHTING (if applicable) -->
            <v-row v-if="pantry.lighting" class="pl-2">
              <v-col cols="10">{{
                pantry.lighting
                  ? 'Requries 2 x ' + productData.getPantryLightingWidth(pantry.w) + 'mm Lighting Channel '
                  : ''
              }}</v-col>
              <v-col cols="2" class="value d-flex justify-end">(Not Included)</v-col>
            </v-row>
          </template>
        </div>
      </v-container>

      <!-- WORKTOPS -->
      <v-container fluid v-if="version.worktops && version.worktops.length > 0" class="text-body-2">
        <v-row>
          <v-col cols="12" class="pb-3">
            <span class="text-h6 mr-2">Worktops</span>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0">
          <v-col cols="2" class="text--disabled text-caption">Material</v-col>
          <v-col cols="8" class="text--disabled text-caption">Sections</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="text--disabled text-caption text-right">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <template v-for="worktop in version.worktops" class="py-0 my-0 mb-4">
          <v-row v-for="section in worktop.sections" class="py-4" :key="section.id">
            <v-col cols="2" class="d-flex text-capitalize align-center"
              >{{ section.coreThickness || worktop.thickness }}mm {{ worktop.material }}<br />
              {{ findWorktopColourName(worktop) }} - {{ worktop.colourCode }}<br />{{ worktop.faces }} sided
            </v-col>

            <v-col cols="3" class="finishing px-4">
              <div class="box">
                <div :class="section.dogleg ? 'label-shifted' : 'label'">Finishing</div>
                <v-checkbox
                  hide-details
                  :class="section.dogleg ? 'front-shifted ma-0 pa-0' : 'front ma-0 pa-0'"
                  :disabled="true"
                  v-model="section.finished.front"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  :class="section.dogleg ? 'left-shifted ma-0 pa-0' : 'left ma-0 pa-0'"
                  :disabled="true"
                  v-model="section.finished.left"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  class="right ma-0 pa-0"
                  :disabled="true"
                  v-model="section.finished.right"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  class="back ma-0 pa-0"
                  :disabled="true"
                  v-model="section.finished.back"
                ></v-checkbox>
                <div class="innerBox" v-if="section.dogleg">
                  <div class="innerLabel">Dogleg</div>
                  <v-checkbox
                    v-model="section.finished.dogLong"
                    :disabled="true"
                    class="dogLong ma-0 pa-0"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="section.finished.dogShort"
                    :disabled="true"
                    class="dogShort ma-0 pa-0"
                    hide-details
                  ></v-checkbox>
                </div>
              </div>
            </v-col>
            <v-col cols="3" class="radius px-4">
              <div class="box" v-if="section.radii">
                <div :class="section.dogleg ? 'label-shifted' : 'label'">Radius</div>
                <div class="v-input bl text-center text--disabled text-body-2">
                  {{ section.radii.bl }}
                </div>
                <div class="v-input br text-center text--disabled text-body-2">
                  {{ section.radii.br }}
                </div>
                <div class="v-input fr text-center text--disabled text-body-2">
                  {{ section.radii.fr }}
                </div>
                <div v-if="section.dogleg" class="v-input fm-dog text-center text--disabled text-body-2">
                  {{ section.radii.fm }}
                </div>
                <div
                  :class="
                    section.dogleg
                      ? 'v-input fl-dog text-center text--disabled text-body-2'
                      : 'v-input fl text-center text--disabled text-body-2'
                  "
                >
                  {{ section.radii.fl }}
                </div>
                <div class="innerBox" v-if="section.dogleg">
                  <div class="innerLabel">Dogleg</div>
                  <div class="v-input i text-center text--disabled text-body-2">{{ section.radii.i }}</div>
                </div>
              </div>
            </v-col>
            <v-col cols="3" class="dimensions px-4">
              <div class="box">
                <div :class="section.dogleg ? 'label-shifted' : 'label'">Dimensions</div>
                <div class="depth v-input text-center text--disabled text-body-2">
                  {{ section.d }}
                </div>
                <div
                  :class="
                    section.dogleg
                      ? 'length-shifted v-input text-center text--disabled text-body-2'
                      : 'length v-input text-center text--disabled text-body-2'
                  "
                >
                  {{ section.l }}
                </div>
                <div v-if="section.dogleg" class="calcDepth v-input text-center text--disabled text-body-2">
                  {{ Math.abs(section.d - section.di) }}
                </div>
                <div v-if="section.dogleg" class="calcLength v-input text-center text--disabled text-body-2">
                  {{ Math.abs(section.l - section.li) }}
                </div>
                <div class="innerBox" v-if="section.dogleg">
                  <div class="innerLabel">Dogleg</div>
                  <div class="doglegDepth v-input text-center text--disabled text-body-2">
                    {{ section.di }}
                  </div>
                  <div class="doglegLength v-input text-center text--disabled text-body-2">
                    {{ section.li }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="1 d-flex justify-end align-center">
              <div class="box"></div>
              <div class="ml-2 box"></div>
            </v-col>
          </v-row>
        </template>
      </v-container>

      <!-- VISUAL -->
      <v-container fluid v-if="version.customerVisualID && version.customerCadShared">
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">Visual</span>
            <v-divider class="mt-2"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <CustomerCad :version="version" :quote="quote" :customerView="true" :disableControls="true"> </CustomerCad>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="my-6"></v-divider>

      <!-- FOOTER (disabled for now)-->
      <!-- <v-container fluid class="footer">
        <v-row>
          <v-col cols="3">
            <div class="text-caption text--disabled mb-2">Packed By</div>
            <div class="longBox mb-3"></div>
            <div class="text-caption text--disabled mb-2">Date</div>
            <div class="longBox mb-3"></div>
          </v-col>
          <v-col cols="3">
            <div class="text-caption text--disabled mb-2">Collected By</div>
            <div class="longBox mb-3"></div>
            <div class="text-caption text--disabled mb-2">Date</div>
            <div class="longBox mb-3"></div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="text-right">
            <div class="text-caption text--disabled mb-2">Total Packages</div>
            <div class="box" :style="{ 'margin-left': 'auto' }"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6"
            >Please check all the parcels are delivered intact and without damage. If your parcel packaging was damaged
            during transit please check the items and email us within 48 hours of the delivery with any issues. We
            appreciate your help with this.</v-col
          >
        </v-row>
      </v-container> -->
    </div>

    <v-dialog v-if="readyForAction" v-model="editAddressDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Edit Delivery Address</v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-text-field
              v-model="order.customerContactDetails.deliveryAddressLine1"
              label="Delivery Address Line 1"
            ></v-text-field>
            <v-text-field
              v-model="order.customerContactDetails.deliveryAddressLine2"
              label="Delivery Address Line 2"
            ></v-text-field>
            <v-text-field
              v-model="order.customerContactDetails.deliveryPostcode"
              label="Delivery Address Postcode"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="editAddressDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="updateCustomerContactDetails">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="readyForAction" v-model="editNameDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Edit Customer Name</v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-text-field v-model="order.customerContactDetails.firstName" label="First Name"></v-text-field>
            <v-text-field v-model="order.customerContactDetails.lastName" label="Last Name"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="editNameDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="updateCustomerContactDetails">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="readyForAction" v-model="editPhoneDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Edit Customer Phone</v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-text-field v-model="order.customerContactDetails.phone1" label="Customer Phone"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="editPhoneDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="updateCustomerContactDetails">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="readyForAction" v-model="editDeliveryInstructions" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Edit Delivery Instructions</v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-textarea
              outlined
              v-model="order.customerContactDetails.deliveryInstructions"
              placeholder="Eg. past the duck then turn left be careful not to wake the dog..."
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="editDeliveryInstructions = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="updateCustomerContactDetails">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="!readyForAction">
      <v-progress-circular v-if="!error" :size="50" color="primary" indeterminate></v-progress-circular>
      <div v-else>{{ error }}</div>
    </v-overlay>
  </div>
</template>

<script>
import cadMethods from '@/js/cad/CadMethods.js'
import productData from '@/js/productData'
import CustomerCad from '@/components/CustomerCad'
import store from '@/store'

export default {
  name: 'PackingView',
  components: { CustomerCad },
  props: ['shipmentID'],
  data() {
    return {
      productData: productData,
      shipment: null,
      quote: null,
      version: null,
      order: null,
      readyForAction: false,
      error: null,
      editAddressDialog: false,
      editNameDialog: false,
      editPhoneDialog: false,
      editDeliveryInstructions: false,
      // NEED TO ADJUST FOR COMPARTMENT
      centralDivideThreshold: 1000
    }
  },
  async created() {
    const shipmentSnap = await this.$db
      .collection('shipments')
      .doc(this.shipmentID)
      .get()
    this.shipment = shipmentSnap.data()

    const quoteSnap = await this.$db
      .collection('quotes')
      .doc(this.shipment.quoteID)
      .get()
    this.quote = quoteSnap.data()
    this.version = this.$utils.getVersionByID(quoteSnap.data().versions, this.shipment.versionID)

    if (!this.version.pricing) {
      this.error =
        'Quote is currently unpublished. Check with ' + this.version.creatorEmail + ' if it can be republished.'
      return
    }

    const orderSnap = await this.$db
      .collection('orders')
      .doc(this.shipment.orderID)
      .get()
    this.order = orderSnap.data()

    this.readyForAction = true

    // console.log({ shipment: this.shipment, version: this.version })
  },
  methods: {
    async updateCustomerContactDetails() {
      this.editNameDialog = false
      this.editAddressDialog = false
      this.editPhoneDialog = false
      this.editDeliveryInstructions = false

      this.$db
        .collection('orders')
        .doc(this.shipment.orderID)
        .update({
          customerContactDetails: this.order.customerContactDetails
        })

      this.$store.commit('openSnackbar', {
        snackbarText: 'Customer details updated'
      })
    },
    getHandleName(id) {
      for (var i = 0; i < this.version.handles.length; i++) {
        if (this.version.handles[i].id == id) {
          return this.version.handles[i].label
        }
      }
    },
    getHandleType(id) {
      for (var i = 0; i < this.version.handles.length; i++) {
        if (this.version.handles[i].id == id) {
          return this.version.handles[i]
        }
      }
    },
    getPositionName(pos) {
      let positionName
      switch (pos) {
        case 'top':
          positionName = 'Joined Top'
          break
        case 'mid':
          positionName = 'Joined Middle'
          break
        case 'bot':
          positionName = 'Joined Bottom'
          break
      }
      return positionName
    },
    constructFinishedEdgesString(section) {
      var s = ''
      if (section.finished.front) s += 'F'
      if (section.finished.back) s += 'B'
      if (section.finished.left) s += 'L'
      if (section.finished.right) s += 'R'
      return s
    },
    constructRadiusString(section) {
      if (!section.radii) {
        return 'n/a'
      } else {
        return section.radii.bl + ' | ' + section.radii.br + ' | ' + section.radii.fr + ' | ' + section.radii.fl
      }
    },
    getMaterialName(code) {
      for (var i = 0; i < this.version.materials.length; i++) {
        if (code == this.version.materials[i].id) {
          var m = this.version.materials[i]
          if (['Reeded Glass Frosted Frosted', 'Reeded Glass Clear Clear'].includes(m.label)) {
            let lastIndex = m.label.lastIndexOf(' ')
            return m.label.substring(0, lastIndex)
          }
          return m.colourCode ? m.label + ' / ' + m.colourCode : m.label
        }
      }
    },
    getUrtilType(type) {
      if (type == 'o') {
        return 'Open'
      } else if (type == 's') {
        return 'Single-Door'
      } else if (type == 't') {
        return 'Twin-Door'
      }
    },
    getNumShelves(urtil) {
      return urtil.shelvingOptions.qty * urtil.compartments
    },
    getMaterialSides(code) {
      const mat = this.version.materials.find(material => {
        return code === material.id
      })
      return mat?.sides
    },
    getNumUrtilParts(urtil) {
      let numParts = 0

      // carcass (+ dviders)
      numParts += 5 + (urtil.compartments - 1)

      // urtil doors
      urtil.type == 's' && urtil.material1
        ? numParts++
        : urtil.type == 't' && urtil.material2
        ? (numParts += 2)
        : (numParts += 0)

      // shelves
      if (urtil.shelvingOptions) {
        const numShelves = this.getNumShelves(urtil)
        numParts += numShelves
      }

      return numParts
    },
    findWorktopColourName(worktopMaterial) {
      if (!this.version?.worktops) return []
      if (!store) return []

      let worktopColour
      if (store.state.materials) {
        const colouredMaterials = store.state.materials?.filter(material => material.colours)
        const colours = colouredMaterials?.map(colouredMaterial => colouredMaterial.colours)
        for (const materialSet of colours) {
          for (const colourInfo of materialSet) {
            if (colourInfo.value === worktopMaterial.colourCode) {
              worktopColour = colourInfo
            }
          }
        }
        return worktopColour.text
      } else {
        return null
      }
    }
  },
  computed: {
    frontsPackingList() {
      const frontsPackingListArray = []
      this.version.fronts.forEach(front => {
        if (front.frontType.includes('-') && front.frontType.split('-')[2] == 'cnr') {
          // create special front Obj for door, narrowFiller & wideFiller

          let cnrFrontArray = [
            {
              frontType: front.frontType,
              w: front.w,
              h: front.h,
              handle: front.handle,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid
            },
            {
              frontType: front.frontType,
              w:
                this.quote.region == 'uk' && front.cabinet == 'w'
                  ? 100
                  : this.quote.region == 'uk' && front.cabinet != 'w'
                  ? 60
                  : this.quote.region == 'us' && front.cabinet == 'w'
                  ? 102
                  : 51,
              h: front.h,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid + '-nf',
              specialLabel: 'Narrow corner filler'
            },
            {
              frontType: front.frontType,
              w:
                this.quote.region == 'uk' && front.cabinet == 'w'
                  ? 120
                  : this.quote.region == 'uk' && front.cabinet != 'w'
                  ? 80
                  : this.quote.region == 'us' && front.cabinet == 'w'
                  ? 127
                  : 70,
              h: front.h,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid + '-wf',
              specialLabel: 'Wide corner filler'
            }
          ]
          cnrFrontArray.forEach(cnrUnit => frontsPackingListArray.push(cnrUnit))
        } else {
          let frontObj = {
            frontType: front.frontType,
            w: front.w,
            h: front.h,
            handle: front.handle,
            itemNum: front.itemNum,
            material: front.material,
            qty: front.qty,
            id: front.uid,
            joiningPos: front?.joiningPos,
            joiningDouble: front?.joiningDouble
          }
          frontsPackingListArray.push(frontObj)
        }
      })
      return frontsPackingListArray
    },
    shelfWithLighting() {
      const shelfArr = this.version.coverPanels.filter(cp => cp.coverPanelType === 'shelf')
      return shelfArr.filter(shelf => shelf.lighting)
    },
    flatPackPantries() {
      return this.version.pantries.filter(pantry => pantry.flatPack)
    },
    numRequiredHandles() {
      var a = []
      let o, f, ii

      for (var i = 0; i < this.version.handles.length; i++) {
        var h = this.version.handles[i]
        if (h.value.split('-')[0] == 'edge') {
          o = {
            name: h.label,
            count: 0
          }

          for (ii = 0; ii < this.version.fronts.length; ii++) {
            f = this.version.fronts[ii]

            if (f.handle == h.id) {
              if (f.frontType.split('2part').length > 1) {
                o.count += f.qty / 2
              } else {
                o.count += f.qty
              }
            }
          }

          a.push(o)
        } else if (h.value.split('-')[0] == 'dpull') {
          o = {
            name: h.label,
            count: 0
          }

          for (ii = 0; ii < this.version.fronts.length; ii++) {
            f = this.version.fronts[ii]

            if (f.handle == h.id) {
              if (f.frontType.split('2part').length > 1) {
                o.count += f.qty / 2
              } else {
                o.count += f.qty
              }
            }
          }

          a.push(o)
        }
      }
      return a
    },
    numPlinthClips() {
      var c = 0
      for (var i = 0; i < this.version.coverPanels.length; i++) {
        if (this.version.coverPanels[i].coverPanelType == 'plinth')
          c += productData.plinthClipsPerPlinth * this.version.coverPanels[i].qty
      }
      return c
    },
    numUrtilDoors() {
      let numDoors = 0

      if (this.version.urtils) {
        for (const urtil of this.version.urtils) {
          if (urtil.type === 'o') continue
          const doors = urtil.type === 's' ? 1 : 2
          numDoors += doors
        }
      }

      return numDoors
    },
    numRequiredJoiningBrackets() {
      let count = 0
      for (const front of this.version.fronts) {
        let handleType = this.getHandleType(front.handle)
        if (
          handleType.value != 'j' &&
          ['mid', 'bot'].includes(front.joiningPos) &&
          !(front.joiningPos && handleType.value == 'lrgSemiGrab' && front.w <= 647)
        ) {
          count += 2
        }
      }
      return count
    },
    numRequiredShelvingPins() {
      let num = 0

      if (this.version.urtils) {
        for (const urtil of this.version.urtils) {
          if (urtil.shelving === 'adjustable' || urtil.shelving === 'blank') {
            num += 4 * urtil.shelvingOptions.qty * urtil.compartments
          }
        }
      }

      if (this.version.pantries) {
        for (let i = 0; i < this.version.pantries.length; i++) {
          num += 4 * 2
        }
      }

      return num
    },
    numRequiredJointScrews() {
      let num = 0

      if (this.version.urtils) {
        for (const urtil of this.version.urtils) {
          if (urtil.trimming) {
            const pilot = cadMethods.calculateJoint(urtil.d)
            num += pilot.pilotCount * 4 // 4 joints per urtil
          }
        }
      }

      // joint screws for flat-pack pantry
      if (this.version.pantries && this.flatPackPantries.length > 0) {
        for (const pantry in this.flatPackPantries) {
          const pilot = cadMethods.calculateJoint(pantry.d)
          num += pilot.pilotCount * 4
        }
      }

      return num
    },
    numRequiredBackScrews() {
      // calculate back screws for trim-urtil + flat-pack
      let num = 0

      if (this.version.urtils) {
        for (const urtil of this.version.urtils) {
          let drillSpacing = 150
          if (urtil.trimming) {
            num += Math.ceil(urtil.w / drillSpacing) * 2
            num += (urtil.h / drillSpacing) * 2 >= 4 ? Math.ceil(urtil.h / drillSpacing) * 2 : 4
            if (urtil.forceCenterSupport || urtil.w > 1000) {
              num += 3
            }
          }
        }
      }

      // flat-pack pantry needs back + f.shelf
      if (this.version.pantries && this.flatPackPantries.length > 0) {
        let drillSpacing = 150
        let fixedShelfHoleSpacing = 250
        for (const pantry of this.flatPackPantries) {
          // pantry back screws
          num += Math.ceil(pantry.w / drillSpacing) * 2
          num += (pantry.h / drillSpacing) * 2 >= 4 ? Math.ceil(pantry.h / 150) * 2 : 4
          // f.shelf screws from back
          num += pantry.w / fixedShelfHoleSpacing >= 2 ? Math.ceil(pantry.w / fixedShelfHoleSpacing) : 2
        }
      }

      return num
    },
    numRequiredBatonScrews() {
      // calculate baton screws for urtil & pantry
      let num = 0
      let drillSpacing = 250
      let drillInset = 50

      if (this.version.urtils) {
        for (const urtil of this.version.urtils) {
          num += Math.ceil((urtil.w - drillInset * 2) / drillSpacing)
        }
      }

      if (this.version.pantries) {
        for (const pantry of this.version.pantries) {
          num += Math.ceil((pantry.w - drillInset * 2) / drillSpacing)
        }
      }

      return num
    },
    numRequiredMetalSpacers() {
      // calculate baton screws for urtil & pantry
      let num = 0
      let drillSpacing = 250
      let drillInset = 50

      if (this.version.urtils) {
        for (const urtil of this.version.urtils) {
          num += Math.ceil((urtil.w - drillInset * 2) / drillSpacing) * 2
        }
      }

      if (this.version.pantries) {
        for (const pantry of this.version.pantries) {
          if (pantry.pantryHinge === 'o') {
            num += Math.ceil((pantry.w - drillInset * 2) / drillSpacing) * 5
          } else {
            num += Math.ceil((pantry.w - drillInset * 2) / drillSpacing) * 2
          }
        }
      }

      return num
    },
    additionalItemsPacked() {
      if (!this.version.additionalItems) return null
      return this.version.additionalItems.filter(o => {
        return !o.excludeFromPacked
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 3.125rem;
}

.box .label-shifted {
  margin-left: 50%;
}

.finishing,
.radius,
.dimensions {
  .box {
    position: relative;
    width: 100%;
    height: 70px;
    border: solid grey 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    .innerBox {
      position: absolute;
      width: 50%;
      height: 50%;
      border-right: solid grey 1px;
      border-top: solid grey 1px;
      bottom: 0;
      transform: translateX(-50%);

      .innerLabel {
        margin-top: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .v-input {
      position: absolute;
      margin: 0;
      min-width: 25px;
      background: white;
      align-items: center;
      justify-content: center;
      ::v-deep .v-input--selection-controls__input {
        margin-right: 0px;
      }
    }

    .back {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .front {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }
    .front-shifted {
      bottom: 0;
      left: 75%;
      transform: translate(-50%, 50%);
    }
    .left {
      left: 0%;
      transform: translateX(-50%);
    }
    .left-shifted {
      bottom: 55%;
      left: 0%;
      transform: translateX(-50%);
    }
    .right {
      right: 0%;
      transform: translateX(50%);
    }
    .dogLong {
      top: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .dogShort {
      top: 50%;
      right: 0%;
      transform: translate(50%, -50%);
    }

    .bl {
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
    }
    .br {
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }
    .fr {
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
    }
    .fl {
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);
    }
    .fl-dog {
      top: 25%;
      left: 0;
      transform: translate(-50%, 50%);
      z-index: 1;
    }
    .fm-dog {
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, 50%);
      z-index: 1;
    }
    .i {
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }

    .depth {
      right: 0%;
      transform: translateX(50%);
    }
    .length {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }
    .length-shifted {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .doglegDepth {
      top: 50%;
      right: 0%;
      transform: translate(50%, -50%);
      z-index: 1;
    }
    .doglegLength {
      top: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    .calcDepth {
      bottom: 75%;
      left: 0%;
      transform: translate(-50%, 50%);
      z-index: 1;
    }
    .calcLength {
      bottom: 0;
      left: 75%;
      transform: translate(-50%, 50%);
      z-index: 1;
    }
  }
}

.footer {
  .longBox {
    width: 100%;
    height: 25px;
    border: solid 1px black;
  }
}
</style>
