<template>
  <div>
    <div id="view-sub-nav">
      <v-btn depressed outlined color="primary" @click="addItem">Create New Item</v-btn>
      <v-spacer />
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      v-if="data"
      :items="data.types"
      :headers="headers"
      sort-by="id"
      :items-per-page="-1"
      :search="tableSearch"
    >
      <template v-slot:item.name="{ item }">
        <v-text-field
          class="ma-0 pa-0"
          :style="{ fontSize: '13px' }"
          v-model="item.name"
          @blur="update"
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:item.department="{ item }">
        <v-select
          v-model="item.department"
          :style="{ fontSize: '13px' }"
          :items="departments"
          dense
          hide-details
          @change="update"
        ></v-select>
      </template>
      <template v-slot:item.expense="{ item }">
        <v-switch v-model="item.expense" :style="{ fontSize: '13px' }" dense hide-details @change="update"> </v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import uid from 'uid'

export default {
  name: 'CockupSettings',
  data() {
    return {
      loading: true,
      tableSearch: null,
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Department',
          sortable: true,
          value: 'department'
        },
        {
          text: 'Our Expense',
          value: 'expense'
        }
      ],
      submitting: false,
      data: null,
      departments: [
        {
          text: 'Sales',
          value: 'sales'
        },
        {
          text: 'Production',
          value: 'prod'
        }
      ]
    }
  },
  async mounted() {
    await this.$bind('data', this.$db.collection('settings').doc('cockup-settings'))
    this.loading = false
  },
  methods: {
    addItem() {
      this.data.types.push({
        id: uid(32),
        name: null,
        department: null
      })
    },
    update() {
      console.log('changed')
      // update in firebase
      this.$db
        .collection('settings')
        .doc('cockup-settings')
        .update({
          types: this.data.types
        })
    }
  }
}
</script>
