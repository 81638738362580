const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class FixedShelves {
  constructor(urtil, materialThickness, finDepth, finInfo, tSlotInfo, sideRebateInfo, engravingInfo) {
    this.models = { shelves: { models: {} } }
    //VARS
    this.urtil = urtil
    this.materialThickness = materialThickness
    this.shelfDepth = finDepth

    this.finHeightLoss = finInfo?.heightLoss
    this.finTSlotDepth = finInfo?.tSlotDepth
    this.tSlotTolerance = tSlotInfo?.tolerance
    this.engravingInfo = engravingInfo

    //CALCS
    this.numOfDividers = urtil.compartments - 1
    this.halfTSlotHeight = (materialThickness + tSlotInfo?.tolerance) / 2
    this.fullTSlotHeight = materialThickness + tSlotInfo?.tolerance
    this.sideRebateWidth = materialThickness + sideRebateInfo?.tolerance
    this.shelfWidth = urtil.w - materialThickness * 2 + finInfo?.tSlotDepth * 2
    this.internalVoid = urtil.w - materialThickness * 2 - this.fullTSlotHeight * this.numOfDividers
    this.slotHeight = this.shelfDepth / 2 + 6

    this.createFixedShelving()
  }

  createFixedShelving() {
    for (var i = 0; i < this.urtil.shelvingOptions.heights.length; i++) {
      let shelf = {
        layer: layerNames.cutout.label,
        paths: {
          line1: {
            type: 'line',
            origin: [0, 0],
            end: [this.finHeightLoss, this.shelfDepth]
          },
          line2: {
            type: 'line',
            origin: [this.finHeightLoss, this.shelfDepth],
            end: [this.shelfWidth - this.finHeightLoss, this.shelfDepth]
          },
          line3: {
            type: 'line',
            origin: [this.shelfWidth - this.finHeightLoss, this.shelfDepth],
            end: [this.shelfWidth, 0]
          },
          line4: {
            type: 'line',
            origin: [this.shelfWidth, 0],
            end: [0, 0]
          }
        }
      }

      let toothNotchA = new makerjs.models.Rectangle(this.finTSlotDepth, 6.5)
      makerjs.model.move(toothNotchA, [0, this.shelfDepth - 6.5])
      shelf = makerjs.model.combine(shelf, toothNotchA, false, true, true, false)

      let toothNotchB = new makerjs.models.Rectangle(this.finTSlotDepth, 6.5)
      makerjs.model.move(toothNotchB, [this.shelfWidth - this.finTSlotDepth, this.shelfDepth - 6.5])
      shelf = makerjs.model.combine(shelf, toothNotchB, false, true, true, false)

      // Add shelf slot if required
      if (this.numOfDividers > 0) {
        // making slot on horizontal fixed shelf
        for (let j = 0; j < this.numOfDividers; j++) {
          let currShelfSlot = 'shelfSlot-' + (j + 1)
          let shelfSlot = (shelf[currShelfSlot] = new makerjs.models.Rectangle(
            this.materialThickness + this.tSlotTolerance,
            this.slotHeight + 1
          ))
          makerjs.model.move(shelfSlot, [
            (this.internalVoid / this.urtil.compartments) * (j + 1) + this.fullTSlotHeight * j + this.finTSlotDepth,
            this.shelfDepth - this.slotHeight
          ])
          shelf = makerjs.model.combine(shelf, shelfSlot, false, true, true, false)
        }
      }

      this.models.shelves.models['shelf-f-' + i] = shelf

      shelf.models.notchA = new makerjs.models.Rectangle(5, this.finTSlotDepth + 8)
      makerjs.model.move(shelf.models.notchA, [this.finTSlotDepth - 5, this.shelfDepth - 18.5])
      shelf.models.notchA.layer = layerNames.finNotch.label

      shelf.models.notchB = new makerjs.models.Rectangle(5, this.finTSlotDepth + 8)
      makerjs.model.move(shelf.models.notchB, [this.shelfWidth - this.finTSlotDepth, this.shelfDepth - 18.5])
      shelf.models.notchB.layer = layerNames.finNotch.label

      shelf.layer = layerNames.cutout.label

      // add Urtil Label in Notes layer
      let shelfLabel = new makerjs.models.Text(this.engravingInfo.font, this.engravingInfo.label, 50)
      shelf.models.shelfLabelText = makerjs.model.mirror(shelfLabel, true, false)
      shelf.models.shelfLabelText.layer = layerNames.warning.label

      let textMeasurements = makerjs.measure.modelExtents(shelf.models.shelfLabelText)
      makerjs.model.moveRelative(shelf.models.shelfLabelText, [
        this.shelfWidth / 2 - textMeasurements.center[0],
        this.shelfDepth / 2 - textMeasurements.center[1]
      ])

      // CAD positioning
      // two rows per column for shelves - shifted to the left.
      if (i === 0) {
        makerjs.model.move(shelf, [this.urtil.h + 50 + (50 + this.urtil.w) * (i + 1), this.urtil.d + 50])
      } else if (i === 1) {
        makerjs.model.move(shelf, [this.urtil.h + 50 + (50 + this.urtil.w) * i, 0])
      } else if (i % 2 === 0) {
        makerjs.model.move(shelf, [this.urtil.h + 50 + (50 + this.urtil.w) * ((i + 2) / 2), this.urtil.d + 50])
      } else {
        makerjs.model.move(shelf, [this.urtil.h + 50 + (50 + this.urtil.w) * ((i + 1) / 2), 0])
      }
    }
  }
}
