const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class AdjustableShelvingPins {
  constructor(urtil, materialThickness, finDepth, adjShelfInfo, backInfo, finInfo, canvas) {
    this.models = {}

    // VARS
    this.urtil = urtil
    this.materialThickness = materialThickness
    this.finDepth = finDepth

    this.shelfAdjustableSpacing = adjShelfInfo?.spacing
    this.shelfAdjustablePinRadius = adjShelfInfo?.pinRadius
    this.shelfAdjustablePinInset = adjShelfInfo?.pinInset

    this.backRebateWidth = backInfo?.rebateWidth
    this.finTSlotDepth = finInfo?.tSlotDepth
    this.finShelfPinOffset = 50

    //CALCS
    this.shelfAdjustablePinOffset = this.calcPinOffset()
    this.numPins = this.getNumPins()
    this.pinsHeight = (this.numPins - 1) * adjShelfInfo?.spacing

    this.createFirstPins()
    switch (canvas) {
      case 'sides':
        this.addAdjustableShelvingPins()
        break
      case 'fins':
        this.addFinAdjustableShelvingPins()
        break
    }
  }

  calcPinOffset() {
    let shelfAdjustablePinOffset
    if (this.urtil.h <= 400) {
      shelfAdjustablePinOffset = 50
    } else if (this.urtil.h > 400 && this.urtil.h <= 800) {
      shelfAdjustablePinOffset = 100
    } else {
      shelfAdjustablePinOffset = 150
    }
    return shelfAdjustablePinOffset
  }

  getNumPins() {
    let numPins = Math.floor(
      (this.urtil.h - this.shelfAdjustablePinOffset - this.materialThickness * 2) / this.shelfAdjustableSpacing
    )
    if (!(numPins % 2)) numPins--
    return numPins
  }

  createFirstPins() {
    // Front Row Pins
    const pinHole = new makerjs.models.Ellipse(this.shelfAdjustablePinRadius, this.shelfAdjustablePinRadius)
    pinHole.layer = layerNames.shelfDrill.label
    this.models.pinsFront = makerjs.layout.cloneToRow(
      pinHole,
      this.numPins,
      this.shelfAdjustableSpacing - this.shelfAdjustablePinRadius * 2
    )
  }

  addAdjustableShelvingPins() {
    // Side Pins
    // -2.5mm is half the height of the actual pin.
    const yPos = (this.urtil.h - this.pinsHeight) / 2 - this.materialThickness / 2 - this.shelfAdjustablePinRadius - 2.5
    makerjs.model.move(this.models.pinsFront, [
      yPos,
      this.urtil.d - this.backRebateWidth - this.finDepth + this.shelfAdjustablePinInset
    ])

    this.models.pinsBack = makerjs.model.clone(this.models.pinsFront)
    makerjs.model.move(this.models.pinsBack, [yPos, this.urtil.d - this.backRebateWidth - this.shelfAdjustablePinInset])
  }

  addFinAdjustableShelvingPins() {
    // Fin Pins
    if (this.urtil.compartments > 1) {
      const finHeight = this.urtil.h - this.materialThickness * 2 + this.finTSlotDepth * 2
      const finPosX =
        (finHeight - this.pinsHeight) / 2 - this.materialThickness / 2 - this.shelfAdjustablePinRadius - 2.5
      this.models.finPinsBack = makerjs.model.clone(this.models.pinsFront)

      makerjs.model.move(this.models.finPinsBack, [
        -finHeight - this.finShelfPinOffset + finPosX,
        this.shelfAdjustablePinInset
      ])

      this.models.finPinsFront = makerjs.model.move(this.models.pinsFront, [
        -finHeight - this.finShelfPinOffset + finPosX,
        this.finDepth - this.shelfAdjustablePinInset
      ])

      // rename layers
      for (let o in this.models.finPinsBack.models) {
        this.models.finPinsBack.models[o].layer = layerNames.shelfDrillMax.label
      }
      for (let o in this.models.finPinsFront.models) {
        this.models.finPinsFront.models[o].layer = layerNames.shelfDrillMax.label
      }

      // delete OG pinsFront
      delete this.models.pinsFront
    }
  }
}
