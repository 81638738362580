<template>
  <v-row class="align-center py-0">
    <v-col class="d-flex align-center" cols="1">
      <div class="d-flex flex-column mr-1">
        <v-icon small @click="$emit('rowUp', rowNum)">mdi-menu-up</v-icon>
        <v-icon small @click="$emit('rowDown', rowNum)">mdi-menu-down</v-icon>
      </div>
      <MegaSelect
        :row="rowNum"
        :col="0"
        :options="frontTypeOptions"
        :displayValue="true"
        :data.sync="data.frontType"
        placeholder="Item type"
        @change="typeChange"
      ></MegaSelect>
    </v-col>
    <v-col class="d-flex justify-space-between">
      <MegaCell :data.sync="data.itemNum" :row="rowNum" :col="1" dataType="String"></MegaCell>
      <v-tooltip v-if="tip" top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ tip }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="1" class="d-flex">
      <v-icon
        :disabled="
          ['drw-int', 'lhd-b-2part', 'rhd-b-2part', 'lhd-b-cnr', 'rhd-b-cnr', 'lhd-w-cnr', 'rhd-w-cnr'].includes(
            data.frontType
          )
        "
        @click="joiningDialog = true"
        >mdi-bandage</v-icon
      >
    </v-col>
    <v-col>
      <MegaCell
        :presets="wPresets ? wPresets[0] : null"
        :data.sync="data.w"
        :unit="region == 'us' ? 'inch' : 'mm'"
        dataType="Number"
        :row="rowNum"
        :col="2"
        @change="filterHandleOptions"
      ></MegaCell>
    </v-col>
    <v-col>
      <MegaCell
        :presets="hPresets ? hPresets[0] : null"
        :data.sync="data.h"
        :unit="region == 'us' ? 'inch' : 'mm'"
        dataType="Number"
        :row="rowNum"
        :col="3"
        @change="filterHandleOptions"
      ></MegaCell>
    </v-col>
    <v-col>
      <MegaCell
        :presets="qtyPresets ? qtyPresets[0] : ['top', 'mid', 'bot'].includes(data.joiningPos) ? 1 : null"
        :disabled="
          ['top', 'mid', 'bot'].includes(data.joiningPos) ||
            ['lhd-b-cnr', 'rhd-b-cnr', 'lhd-w-cnr', 'rhd-w-cnr'].includes(data.frontType)
        "
        :data.sync="data.qty"
        dataType="Number"
        :row="rowNum"
        :col="4"
      ></MegaCell>
    </v-col>
    <v-col cols="2" class="d-flex align-center">
      <div
        v-if="data.material"
        class="colour mr-2"
        :style="{ 'background-color': findMaterialHex(data.material) }"
      ></div>
      <MegaSelect
        :row="rowNum"
        :col="5"
        :options="filteredMaterials"
        :data.sync="data.material"
        placeholder="Select material"
        noOptions="No materials created yet"
      ></MegaSelect>
    </v-col>
    <v-col cols="2">
      <MegaSelect
        :row="rowNum"
        :col="6"
        :disabled="!data.frontType"
        :options="filteredHandleOptions"
        :data.sync="data.handle"
        placeholder="Set Width then Select handle"
        noOptions="No handles created yet"
      ></MegaSelect>
    </v-col>
    <v-col class="d-flex" :order="wonky ? 'first' : 'last'" :class="[{ 'justify-end': !wonky }]" cols="2">
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" @click="$emit('copy', rowNum)" v-on="on">mdi-content-copy</v-icon>
        </template>
        <span>Copy</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" @click="$emit('copyBottom', rowNum)" v-on="on">mdi-arrow-collapse-down</v-icon>
        </template>
        <span>Copy to bottom</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small @click="$emit('delete', rowNum)" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </v-col>

    <!-- Joining Dialog -->
    <v-dialog v-model="joiningDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Joining Option</v-card-title>
        <v-card-text class="pt-4">
          <p>
            Select position of the door when joining.
          </p>
          <v-select :items="positionOptions" v-model="data.joiningPos" label="Position" />
          <p v-if="!disableJoiningDouble" class="red--text">
            Check box for inserts that are double handle looking and joined.
            <br />
            Only for "double-handle j-bracket" OR "SRG facing each other"
          </p>
          <v-checkbox
            v-model="data.joiningDouble"
            label="Joined Double Handles"
            v-if="!disableJoiningDouble"
          ></v-checkbox>
          <p>
            *Item engraving will include joining info automatically; just input item number.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import productData from '@/js/productData'

import MegaSelect from '@/components/quoting/items/MegaSelect'
import MegaCell from '@/components/quoting/items/MegaCell'

export default {
  name: 'Front',
  props: ['rowNum', 'data', 'materials', 'handles', 'wonky', 'region'],
  components: { MegaCell, MegaSelect },
  data() {
    return {
      frontTypes: productData.frontTypes,
      frontTypeOptions: [],
      joiningDialog: false,
      handleOptions: this.handles,
      wPresets: null,
      hPresets: null,
      qtyPresets: null,
      positionOptions: [
        {
          text: 'Top - brackets added to bottom of door',
          value: 'top'
        },
        {
          text: 'Middle - brackets added to top and bottom of door',
          value: 'mid'
        },
        {
          text: 'Bottom - brackets added to top of door',
          value: 'bot'
        },
        {
          text: 'Cancel Joining',
          value: 'cancel'
        }
      ]
    }
  },
  created() {
    this.filterFrontTypeOptions()

    if (this.data?.itemNum) {
      this.markJoinedDoor(this.data.joiningPos)
      this.markDoubleJoinedDoor(this.data.joiningDouble)
    }
  },
  watch: {
    'data.handle': function() {
      this.filterFrontTypeOptions()
    },
    'data.joiningPos': function(val) {
      this.markJoinedDoor(val)
    },
    'data.joiningDouble': function(val) {
      this.markDoubleJoinedDoor(val)
    }
  },

  computed: {
    tip() {
      let tip = null
      if (this.data.frontType && productData.getFrontType(this.data.frontType).tip) {
        tip = productData.getFrontType(this.data.frontType).tip
      }
      return tip
    },
    filteredMaterials() {
      return this.materials.filter(material => {
        return material.thickness == 18
      })
    },
    filteredHandleOptions() {
      let frontTypeArray
      let specialType
      if (this.data && this.data.frontType) {
        frontTypeArray = this.data.frontType.includes('-') ? this.data.frontType.split('-') : [this.data.frontType]
        specialType = frontTypeArray.length == 3 ? frontTypeArray[2] : null
      }

      if (this.data.frontType && specialType && specialType === '2part') {
        return this.handles.filter(handle => {
          return !(
            (handle.type === 'j' && handle.orientation === 'v') ||
            (handle.type.split('-')[0] == 'dpull' && handle.type.split('-').includes('l')) ||
            ['lrgSemiGrab', 'midSemiGrab'].includes(handle.type) ||
            handle.orientation === 'updn'
          )
        })
      } else if (this.data.frontType && this.data.frontType === 'drw') {
        return this.handles.filter(handle => {
          return !['v', 'vc', 'vbt', 'vwb'].includes(handle.orientation)
        })
      } else if (this.data.frontType && ['thd', 'bhd'].includes(this.data.frontType.split('-')[0])) {
        return this.handles.filter(handle => {
          return !['v', 'vc', 'vbt', 'vwb'].includes(handle.orientation)
        })
      } else {
        return productData.getHandleOptions(this.data.frontType, this.handles, this.data.w, this.data.h)
      }
    },
    disableJoiningDouble() {
      if (this.data.handle && ['top', 'mid', 'bot'].includes(this.data.joiningPos)) {
        let selectedHandleInfo = this.getHandleType(this.data?.handle)
        if (
          (selectedHandleInfo.type === 'j' && selectedHandleInfo.orientation === 'h') ||
          (['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(selectedHandleInfo.type) &&
            ['h', 'c', 'updn'].includes(selectedHandleInfo.orientation))
        ) {
          return false
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.data.joiningDouble = false
          return true
        }
      } else {
        return true
      }
    }
  },
  methods: {
    typeChange() {
      // set handle options
      this.handleOptions = this.filterHandleOptions()

      // 2part cnr presets
      let presets = productData.getFrontType(this.data.frontType).presets
      if (!presets) return
      const presetRoot = presets[this.region] || presets
      this.wPresets = presetRoot.w || null
      this.hPresets = presetRoot.h || null
      this.qtyPresets = presetRoot.qty || null
    },
    filterHandleOptions() {
      let selection = productData.getHandleOptions(this.data.frontType, this.handles, this.data.w, this.data.h)
      selection.forEach(handle => {
        if (!Object.values(handle)[0] === this.data.handle) {
          this.data.handle = ''
        }
      })
      return selection
    },
    getHandleType(id) {
      return this.handles.find(handle => {
        return handle.value === id
      })
    },
    markJoinedDoor(joined) {
      // reset joining position if joining is cancelled.
      if (joined === 'cancel') {
        this.data.joiningPos = null
        this.data.joiningDouble = false
        return
      }

      // Legacy check: if already marked with J.
      if (
        this.data.itemNum
          .toString()
          .split('')
          .includes('J')
      ) {
        // remove all non-numerical characters except for any 'D' or 'd' characters.
        this.data.itemNum = this.data.itemNum.toString().replace(/[^0-9Dd]/g, '')
      }

      if (joined) {
        if (
          !this.data.itemNum
            .toString()
            .split('')
            .includes('J')
        ) {
          this.data.itemNum += 'J'
          this.data.itemNum += `-${this.data.joiningPos.split('')[0].toUpperCase()}`
        }
      } else {
        if (
          this.data.itemNum
            .toString()
            .split('')
            .includes('J')
        ) {
          this.data.itemNum = this.data.itemNum.toString().replace(/\D/g, '')
        }
      }
    },
    markDoubleJoinedDoor(double) {
      // Legacy check: if already marked with D.
      if (
        this.data.itemNum
          .toString()
          .split('')
          .includes('D')
      ) {
        // remove the D
        this.data.itemNum = this.data.itemNum.toString().replace('D', '')
      }

      if (double) {
        if (
          !this.data.itemNum
            .toString()
            .split('')
            .includes('D')
        ) {
          this.data.itemNum += 'D'
        }
      } else {
        if (
          this.data.itemNum
            .toString()
            .split('')
            .includes('D')
        ) {
          this.data.itemNum = this.data.itemNum.toString().replace('D', '')
        }
      }
    },
    filterFrontTypeOptions() {
      let selectedHandleInfo = this.getHandleType(this.data.handle)
      if (selectedHandleInfo?.type == 'quarterCircleGrab' || selectedHandleInfo?.type == 'lrgQuarterCircleGrab') {
        this.frontTypeOptions = this.frontTypes.filter(type => {
          return ['lhd', 'rhd'].includes(type.value.split('-')[0])
        })
      } else {
        this.frontTypeOptions = this.frontTypes
      }
    },
    findMaterialHex(materialCode) {
      if (!materialCode) return []
      const match = this.materials.find(material => {
        return material.value === materialCode
      })
      return match.hex
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  border-bottom: solid 1px #eee;
  .col {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.colour {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border: solid 1px #999;
  border-radius: 3px;
  margin-right: 12px;
}
</style>
