<template>
  <div class="pa-5">
    <div v-if="readyForAction">
      <v-container fluid>
        <v-row class="align-baseline mb-8">
          <v-col cols="6">
            <h1>Packing List</h1>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <img src="@/assets/final-logo-tight.png" alt="Plykea" width="200px" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="item py-2">
              <div class="caption text--secondary">
                Customer Name
                <v-icon :size="14" class="pl-2 no-print" @click="editNameDialog = true">mdi-pencil</v-icon>
              </div>
              <div class="body-1">
                {{ order.customerContactDetails.firstName + ' ' + order.customerContactDetails.lastName }}
              </div>
            </div>
            <div class="item py-2">
              <div class="caption text--secondary">Customer Email</div>
              <div class="body-1">{{ order.customerEmail }}</div>
            </div>
            <div class="item py-2">
              <div class="caption text--secondary">
                Customer Phone
                <v-icon :size="14" class="pl-2 no-print" @click="editPhoneDialog = true">mdi-pencil</v-icon>
              </div>
              <div class="body-1">
                {{ order.customerContactDetails.phone1 }}
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="text-right">
            <div class="item py-2">
              <div class="caption text--secondary">Invoice Number</div>
              <div class="body-1">
                {{ order.orderNumber || order.xeroInvoiceNumber }}
              </div>
            </div>
            <div class="item py-2">
              <div class="caption text--secondary">
                Delivery Address
                <v-icon :size="14" class="pl-2 no-print" @click="editAddressDialog = true">mdi-pencil</v-icon>
              </div>
              <div class="body-1">
                <div>
                  {{ order.customerContactDetails.deliveryAddressLine1 }}
                </div>
                <div>
                  {{ order.customerContactDetails.deliveryAddressLine2 }}
                </div>
                <div>
                  {{ order.customerContactDetails.deliveryCity }}
                </div>
                <div>
                  {{ order.customerContactDetails.deliveryState }}
                </div>
                <div>
                  {{ order.customerContactDetails.deliveryPostcode }}
                </div>
                <div>
                  {{ order.customerContactDetails.deliveryCountry }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- NOTES -->
      <v-container fluid v-if="version.notes">
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">Notes</span>
            <v-divider class="mt-2"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>{{ version.notes }}</p>
          </v-col>
        </v-row>
      </v-container>

      <!-- VISUAL -->
      <v-container fluid v-if="version.customerVisualID">
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">Visual</span>
            <v-divider class="mt-2"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <CustomerCad :version="version" :quote="quote" :customerView="true" :disableControls="true"> </CustomerCad>
          </v-col>
        </v-row>
      </v-container>

      <!-- FRONTS -->
      <v-container
        v-for="material in version.materials"
        v-if="version.pricing.fronts[material.id].itemCount > 0"
        :key="material.id"
        fluid
        class="text-body-2"
      >
        <v-row>
          <v-col cols="12" class="pb-2">
            <span class="text-h6 mr-2" v-if="material.colourCode"
              >{{ material.label }} - {{ material.colourCode }}</span
            >
            <span class="text-h6 mr-2" v-else>{{ material.label }} - {{ material.grainDirection }}</span>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0">
          <v-col cols="1" class="text--disabled text-caption">Location</v-col>
          <v-col cols="3" class="text--disabled text-caption">Type</v-col>
          <v-col cols="2" class="text--disabled text-caption">Dimensions (inches)</v-col>
          <v-col cols="3" class="text--disabled">Handle</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="text--disabled text-caption">Qty</v-col>
          <v-col cols="2" class="text--disabled text-caption text-right">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row v-for="front in version.fronts" v-if="front.material == material.id" :key="front.id" class="py-0 my-0">
          <v-col cols="1" class="py-1">{{ front.itemNum }}</v-col>
          <v-col cols="3" class="py-1">{{ productData.getFrontTypeLabel(front.frontType) }}</v-col>
          <v-col cols="2" class="py-1">{{ $utils.mmToInch(front.w) }} x {{ $utils.mmToInch(front.h) }}</v-col>
          <v-col cols="3" class="py-1">{{ getHandleName(front.handle) }}</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="py-1">{{ front.qty }}</v-col>
          <v-col cols="2" class="py-1 value d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>

        <v-row
          v-for="coverPanel in version.coverPanels"
          v-if="coverPanel.material == material.id"
          :key="coverPanel.id"
          class="py-0 my-0"
        >
          <v-col cols="1">{{ coverPanel.itemNum }}</v-col>
          <v-col cols="3">{{ productData.getCoverPanelTypeLabel(coverPanel.coverPanelType) }}</v-col>
          <v-col cols="2">{{ $utils.mmToInch(coverPanel.w) }} x {{ $utils.mmToInch(coverPanel.h) }}</v-col>
          <v-col cols="3">n/a</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1">{{ coverPanel.qty }}</v-col>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>
      </v-container>

      <!-- SPACER PANELS -->
      <v-container v-if="version.spacerPanels && version.spacerPanels.length > 0" fluid class="text-body-2">
        <v-row>
          <v-col cols="12" class="pb-2">
            <span class="text-h6 mr-2">Spacer Panels</span>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0">
          <v-col cols="1" class="text--disabled text-caption">Location</v-col>
          <v-col cols="2" class="text--disabled text-caption">Material</v-col>
          <v-col cols="2" class="text--disabled text-caption">Length (inches)</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="text--disabled text-caption">Qty</v-col>
          <v-col cols="2" class="text--disabled text-caption text-right">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row v-for="spacer in version.spacerPanels" :key="spacer.id" class="py-0 my-0">
          <v-col cols="1" class="py-1">{{ spacer.location }}</v-col>
          <v-col cols="2" class="py-1">{{ spacer.material ? spacer.material : '?' }}</v-col>
          <v-col cols="2" class="py-1">{{ $utils.mmToInch(spacer.l) }}</v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="py-1">{{ spacer.qty }}</v-col>
          <v-col cols="2" class="py-1 d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>
      </v-container>

      <!-- WORKTOPS -->
      <v-container fluid v-if="version.worktops && version.worktops.length > 0" class="text-body-2">
        <v-row>
          <v-col cols="12" class="pb-3">
            <span class="text-h6 mr-2">Worktops</span>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0">
          <v-col cols="3" class="text--disabled text-caption">Material</v-col>
          <v-col cols="2" class="text--disabled text-caption">Sections</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="text--disabled text-caption text-right">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row v-for="worktop in version.worktops" :key="worktop.id" class="py-0 my-0">
          <v-col cols="3">{{ worktop.thickness }}mm {{ worktop.material }} {{ worktop.colourCode }}</v-col>
          <v-col cols="9">
            <v-row v-for="section in worktop.sections" class="mt-2" :key="section.id">
              <v-col cols="3" class="finishing px-4">
                <div class="box">
                  <div class="label">Finishing</div>
                  <v-checkbox
                    hide-details
                    class="front ma-0 pa-0"
                    :disabled="true"
                    v-model="section.finished.front"
                  ></v-checkbox>
                  <v-checkbox
                    hide-details
                    class="left ma-0 pa-0"
                    :disabled="true"
                    v-model="section.finished.left"
                  ></v-checkbox>
                  <v-checkbox
                    hide-details
                    class="right ma-0 pa-0"
                    :disabled="true"
                    v-model="section.finished.right"
                  ></v-checkbox>
                  <v-checkbox
                    hide-details
                    class="back ma-0 pa-0"
                    :disabled="true"
                    v-model="section.finished.back"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="3" class="radius px-4">
                <div class="box" v-if="section.radii">
                  <div class="label">Radius</div>
                  <div class="v-input bl text-center text--disabled text-body-2">
                    {{ section.radii.bl }}
                  </div>
                  <div class="v-input br text-center text--disabled text-body-2">
                    {{ section.radii.br }}
                  </div>
                  <div class="v-input fl text-center text--disabled text-body-2">
                    {{ section.radii.fl }}
                  </div>
                  <div class="v-input fr text-center text--disabled text-body-2">
                    {{ section.radii.fr }}
                  </div>
                </div>
              </v-col>
              <v-col cols="3" class="dimensions px-4">
                <div class="box">
                  <div class="label">Dimensions</div>
                  <div class="depth v-input text-center text--disabled text-body-2">
                    $utils.mmToInch({{ section.d }})
                  </div>
                  <div class="length v-input text-center text--disabled text-body-2">
                    $utils.mmToInch({{ section.l }})
                  </div>
                </div>
              </v-col>
              <v-col cols="3 d-flex justify-end align-center">
                <div class="box"></div>
                <div class="ml-2 box"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <!-- ADDITIONAL ITEMS -->
      <v-container fluid class="text-body-2">
        <v-row>
          <v-col cols="12" class="pb-1">
            <span class="text-h6 mr-2">Additional Items</span>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="my-0 justify-space-between">
          <v-col cols="6" class="text--disabled text-caption">Item</v-col>
          <v-col cols="2" class="text--disabled text-caption text-right">Packed / Packages</v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row v-for="item in version.additionalItems" :key="item.id" class="py-0 my-0 justify-space-between">
          <v-col cols="6">{{ item.description }}</v-col>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>
        <div v-if="numRequiredHandles.length > 0">
          <v-row v-for="item in numRequiredHandles" :key="item.id" class="py-0 my-0">
            <v-col cols="6">{{ item.count }} x {{ item.name }}</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end"
              ><div class="box"></div>
              <div class="ml-2 box"></div
            ></v-col>
          </v-row>
        </div>

        <v-row v-if="numPlinthClips > 0" class="py-0 my-0">
          <v-col cols="6">{{ numPlinthClips }} x Plinth Clips</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end"
            ><div class="box"></div>
            <div class="ml-2 box"></div
          ></v-col>
        </v-row>
      </v-container>

      <v-divider class="my-6"></v-divider>

      <v-container fluid class="footer">
        <v-row>
          <v-col cols="3">
            <div class="text-caption text--disabled mb-2">Packed By</div>
            <div class="longBox mb-3"></div>
            <div class="text-caption text--disabled mb-2">Date</div>
            <div class="longBox mb-3"></div>
          </v-col>
          <v-col cols="3">
            <div class="text-caption text--disabled mb-2">Collected By</div>
            <div class="longBox mb-3"></div>
            <div class="text-caption text--disabled mb-2">Date</div>
            <div class="longBox mb-3"></div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="text-right">
            <div class="text-caption text--disabled mb-2">Total Packages</div>
            <div class="box" :style="{ 'margin-left': 'auto' }"></div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-if="readyForAction" v-model="editAddressDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Edit Delivery Address</v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-text-field
              v-model="order.customerContactDetails.deliveryAddressLine1"
              label="Delivery Address Line 1"
            ></v-text-field>
            <v-text-field
              v-model="order.customerContactDetails.deliveryAddressLine2"
              label="Delivery Address Line 2"
            ></v-text-field>
            <v-text-field v-model="order.customerContactDetails.deliveryCity" label="City"> </v-text-field>
            <v-autocomplete
              disabled
              v-model="order.customerContactDetails.deliveryState"
              :items="$utils.usStateCodes"
              label="State"
              v-validate="'required'"
              data-vv-name="Delivery State"
              data-vv-validate-on="blur"
              :error-messages="errors.collect('Delivery State')"
            >
            </v-autocomplete>
            <v-text-field
              v-model="order.customerContactDetails.deliveryPostcode"
              label="Delivery Zip code"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="editAddressDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="updateCustomerContactDetails">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="readyForAction" v-model="editNameDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Edit Customer Name</v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-text-field v-model="order.customerContactDetails.firstName" label="First Name"></v-text-field>
            <v-text-field v-model="order.customerContactDetails.lastName" label="Last Name"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="editNameDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="updateCustomerContactDetails">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="readyForAction" v-model="editPhoneDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Edit Customer Phone</v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-text-field v-model="order.customerContactDetails.phone1" label="Customer Phone"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="editPhoneDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed dark @click="updateCustomerContactDetails">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="!readyForAction">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import productData from '@/js/productData'
import CustomerCad from '@/components/CustomerCad'

export default {
  name: 'LucidProductionView',
  props: ['orderID'],
  components: { CustomerCad },
  data() {
    return {
      productData: productData,
      version: null,
      order: null,
      quote: null,
      readyForAction: false,
      editNameDialog: false,
      editAddressDialog: false,
      editPhoneDialog: false
    }
  },
  async created() {
    const orderSnap = await this.$db
      .collection('orders')
      .doc(this.orderID)
      .get()
    this.order = orderSnap.data()
    const quoteSnap = await this.$db
      .collection('quotes')
      .doc(orderSnap.data().quoteID)
      .get()
    this.quote = quoteSnap.data()
    this.version = this.$utils.getVersionByID(quoteSnap.data().versions, orderSnap.data().versionID)
    this.readyForAction = true
  },
  methods: {
    async updateCustomerContactDetails() {
      this.editNameDialog = false
      this.editAddressDialog = false
      this.editPhoneDialog = false

      this.$db
        .collection('orders')
        .doc(this.orderID)
        .update({
          customerContactDetails: this.order.customerContactDetails
        })

      this.$store.commit('openSnackbar', {
        snackbarText: 'Customer details updated'
      })
    },
    getHandleName(id) {
      for (var i = 0; i < this.version.handles.length; i++) {
        if (this.version.handles[i].id == id) {
          return this.version.handles[i].label
        }
      }
    },
    constructFinishedEdgesString(section) {
      var s = ''
      if (section.finished.front) s += 'F'
      if (section.finished.back) s += 'B'
      if (section.finished.left) s += 'L'
      if (section.finished.right) s += 'R'
      return s
    },
    constructRadiusString(section) {
      if (!section.radii) {
        return 'n/a'
      } else {
        return section.radii.bl + ' | ' + section.radii.br + ' | ' + section.radii.fr + ' | ' + section.radii.fl
      }
    },
    getMaterialName(code) {
      for (var i = 0; i < this.version.materials.length; i++) {
        if (code == this.version.materials[i].id) {
          var m = this.version.materials[i]
          return m.colourCode ? m.label + ' / ' + m.colourCode : m.label
        }
      }
    }
  },
  computed: {
    numRequiredHandles() {
      var a = []
      let o, f, ii

      for (var i = 0; i < this.version.handles.length; i++) {
        var h = this.version.handles[i]
        if (h.value.split('-')[0] == 'edge') {
          o = {
            name: h.label,
            count: 0
          }

          for (ii = 0; ii < this.version.fronts.length; ii++) {
            f = this.version.fronts[ii]

            if (f.handle == h.id) {
              if (f.frontType == 'lhd-2-p-cnr' || f.frontType == 'rhd-2-p-cnr') {
                o.count += f.qty / 2
              } else {
                o.count += f.qty
              }
            }
          }

          a.push(o)
        } else if (h.value.split('-')[0] == 'dpull') {
          o = {
            name: h.label,
            count: 0
          }

          for (ii = 0; ii < this.version.fronts.length; ii++) {
            f = this.version.fronts[ii]

            if (f.handle == h.id) {
              if (f.frontType == 'lhd-2-p-cnr' || f.frontType == 'rhd-2-p-cnr') {
                o.count += f.qty / 2
              } else {
                o.count += f.qty
              }
            }
          }

          a.push(o)
        }
      }
      return a
    },
    numPlinthClips() {
      var c = 0
      for (var i = 0; i < this.version.coverPanels.length; i++) {
        if (this.version.coverPanels[i].coverPanelType == 'plinth')
          c += productData.plinthClipsPerPlinth * this.version.coverPanels[i].qty
      }
      return c
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 3.125rem;
}

.finishing,
.radius,
.dimensions {
  .box {
    position: relative;
    width: 100%;
    height: 70px;
    border: solid grey 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    .v-input {
      position: absolute;
      margin: 0;
      min-width: 25px;
      background: white;
      align-items: center;
      justify-content: center;
    }

    .back {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .front {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }
    .left {
      left: 0%;
      transform: translateX(-50%);
    }
    .right {
      right: 0%;
      transform: translateX(50%);
    }

    .bl {
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
    }
    .br {
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }
    .fr {
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
    }
    .fl {
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);
    }
    .depth {
      right: 0%;
      transform: translateX(50%);
    }
    .length {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

.footer {
  .longBox {
    width: 100%;
    height: 25px;
    border: solid 1px black;
  }
}
</style>
