<template>
  <div>This is the Monthly Resourcing View</div>
</template>

<script>
export default {
  name: 'MonthResources',
  data() {
    return {}
  }
}
</script>
