var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class EdgePullRear {
  constructor(front, width, doorWidth, doorHeight) {
    // common
    this.models = {}
    this.width = width
    this.height = 3.5
    this.rebateHeight = 20.5
    this.insetX = 20
    this.insetY = 0
    this.front = front
    this.doorWidth = doorWidth
    this.doorHeight = doorHeight

    // unique
    this.drillRadius = 1.5
    this.profileLength = 3.5
    this.yOffset = this.height - this.drillRadius
    this.drillOffsetX = 15.5
    this.drillOffsetY = 10

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.front.handleOutline = {
      layer: layerNames.cutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 1],
          end: [this.width, 1]
        },
        line2: {
          type: 'line',
          origin: [this.width, 1],
          end: [this.width, -this.height]
        },
        line3: {
          type: 'line',
          origin: [this.width, -this.height],
          end: [0, -this.height]
        },
        line4: {
          type: 'line',
          origin: [0, -this.height],
          end: [0, 1]
        }
      }
    }

    this.models.rebate = {
      layer: layerNames.pocketEdgePull.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 5],
          end: [this.width, 5]
        },
        line2: {
          type: 'line',
          origin: [this.width, 5],
          end: [this.width, -this.rebateHeight]
        },
        line3: {
          type: 'line',
          origin: [this.width, -this.rebateHeight],
          end: [0, -this.rebateHeight]
        },
        line4: {
          type: 'line',
          origin: [0, -this.rebateHeight],
          end: [0, 5]
        }
      }
    }

    // UNNESSARY HANDLE CORNER DRILL HOLE
    // this.models.drill = new makerjs.models.Holes(this.drillRadius, [
    //   [this.drillRadius, -this.yOffset],
    //   [this.width - this.drillRadius, -this.yOffset]
    // ])
    // this.models.drill.layer = '3mm drill'

    // this.models.drillRebate = new makerjs.models.Holes(this.drillRadius, [
    //   [this.drillRadius, this.drillRadius - this.rebateHeight],
    //   [this.width - this.drillRadius, this.drillRadius - this.rebateHeight]
    // ])
    // this.models.drillRebate.layer = '3mm drill @ 3.5mm'

    this.models.profile = {
      layer: layerNames.cornerCutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.drillRadius, -this.yOffset],
          end: [this.drillRadius + this.profileLength, -this.yOffset]
        },
        line2: {
          type: 'line',
          origin: [this.drillRadius, -this.yOffset],
          end: [this.drillRadius, -this.yOffset + this.profileLength]
        },
        line3: {
          type: 'line',
          origin: [this.width - this.drillRadius, -this.yOffset],
          end: [this.width - this.drillRadius - this.profileLength, -this.yOffset]
        },
        line4: {
          type: 'line',
          origin: [this.width - this.drillRadius, -this.yOffset],
          end: [this.width - this.drillRadius, -this.yOffset + this.profileLength]
        }
      }
    }

    this.models.profileTwo = {
      layer: layerNames.cornerEdgePull.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.drillRadius, -this.rebateHeight + this.drillRadius],
          end: [this.drillRadius + this.profileLength, -this.rebateHeight + this.drillRadius]
        },
        line2: {
          type: 'line',
          origin: [this.drillRadius, -this.rebateHeight + this.drillRadius],
          end: [this.drillRadius, -this.rebateHeight + this.drillRadius + this.profileLength]
        },
        line3: {
          type: 'line',
          origin: [this.width - this.drillRadius, -this.rebateHeight + this.drillRadius],
          end: [this.width - this.drillRadius - this.profileLength, -this.rebateHeight + this.drillRadius]
        },
        line4: {
          type: 'line',
          origin: [this.width - this.drillRadius, -this.rebateHeight + this.drillRadius],
          end: [this.width - this.drillRadius, -this.rebateHeight + this.drillRadius + this.profileLength]
        }
      }
    }

    this.models.pilot = new makerjs.models.Holes(this.drillRadius, [
      [this.drillOffsetX, -this.yOffset - this.drillOffsetY],
      [this.width - this.drillOffsetX, -this.yOffset - this.drillOffsetY]
    ])
    this.models.pilot.layer = layerNames.handleDrillEdgePull.label
  }
}
