import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    maxLeadTimeInWeeks: 8,
    focussedCell: {
      row: null,
      col: null
    },
    selectedCell: {
      row: null,
      col: null
    },
    prevUserInput: null,
    cellEditable: false,
    pageTitle: '',
    pricing: null,
    materials: null,
    worktops: null,
    handles: null,
    staff: null,
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    mu: null
  },
  getters: {
    focussedCell: state => {
      return state.focussedCell
    },
    selectedCell: state => {
      return state.selectedCell
    },
    notWorking: state => day => {
      return state.staff.filter(staff => {
        let include = true
        for (const worker of day.working) {
          if (worker.timetasticId === staff.timetasticId || staff.staffType !== 'employed' || staff.departureDate)
            include = false
        }
        return include
      })
    },
    staffHoursByMoment: state => (m, staff) => {
      const employee = state.staff.find(e => {
        return e.timetasticId === staff.timetasticId
      })
      let schedule
      for (const s of employee.capacity) {
        const startMoment = moment.unix(s.from.seconds)
        if (employee.departureDate && moment.unix(employee.departureDate.seconds).isSameOrBefore(m)) {
          break
        } else if (!schedule && startMoment.isSameOrBefore(m)) {
          schedule = s
        } else if (startMoment.isSameOrBefore(m) && startMoment.isAfter(moment.unix(schedule.from.seconds))) {
          schedule = s
        }
      }

      return schedule ? schedule.hours : null
    }
  },
  mutations: {
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    // Tables
    setFocussedCell(state, value) {
      state.selectedCell.row = null
      state.selectedCell.col = null
      state.focussedCell.row = value.row
      state.focussedCell.col = value.col
    },
    setSelectedCell(state, value) {
      state.focussedCell.row = null
      state.focussedCell.col = null
      state.selectedCell.row = value.row
      state.selectedCell.col = value.col
      state.cellEditable = false
    },
    toggleSelectFocus(state, prevUserInput) {
      if (state.selectedCell.row == null && state.selectedCell.col == null) {
        state.selectedCell.row = state.focussedCell.row
        state.selectedCell.col = state.focussedCell.col
        state.focussedCell.row = null
        state.focussedCell.col = null
        state.cellEditable = false
      } else {
        state.focussedCell.row = state.selectedCell.row
        state.focussedCell.col = state.selectedCell.col
        state.selectedCell.row = null
        state.selectedCell.col = null
      }
      state.prevUserInput = prevUserInput
    },
    setCellEditable(state, value) {
      state.cellEditable = value
    },
    clearSelectAndFocus(state) {
      state.focussedCell = { row: null, col: null }
      state.selectedCell = { row: null, col: null }
      state.editableModeActive = false
      state.cellEditable = false
    },
    clearPrevUserInput(state) {
      state.prevUserInput = null
    },
    // UI
    openSnackbar(state, data) {
      state.snackbarText = data.snackbarText
      state.snackbarTimeout = data.snackbarTimeout || 5000
      state.snackbar = true
    },
    closeSnackbar(state) {
      state.snackbarText = ''
      state.snackbarTimeout = null
      state.snackbar = false
    },
    // Data
    setUser(state, user) {
      state.user = user
    },
    // Pricing
    updatePricing(state, pricing) {
      state.pricing = pricing
    },
    updateMaterials(state, materials) {
      state.materials = materials
    },
    updateWorktops(state, worktops) {
      state.worktops = worktops
    },
    updateHandles(state, handles) {
      state.handles = handles
    },
    updateStaff(state, data) {
      state.staff = data
    },
    muSettings(state, data) {
      state.mu = {
        cutCapacity: data.muCutCapacity,
        finishCapacity: data.muFinishCapacity,
        packCapacity: data.muPackCapacity
      }
    }
  }
})
