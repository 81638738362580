import paper from 'paper'

export default class HalfQuartArc {
  constructor(insertMaterial, handleWidth, handleType) {
    this.path
    this.insertMaterial = insertMaterial
    this.handleType = handleType

    this.pocketSpacing = 19
    this.insetX = 35
    this.insetY = -1.5
    this.radius = 5

    switch (this.handleType) {
      case 'lrgHalfArc':
      case 'halfArc':
        this.width = handleWidth / 2 + this.pocketSpacing //radius
        this.height = handleWidth / 2 + this.pocketSpacing
        break
      case 'lrgQuartArc':
      case 'quartArc':
        this.width = handleWidth + this.pocketSpacing //radius
        this.height = handleWidth + this.pocketSpacing
        break
    }

    this.draw()
  }

  draw() {
    let throughX = this.width
    let throughY = this.height

    let arc = new paper.Path.Arc({
      from: [0, 0],
      through: [throughX, throughY],
      to: [throughX * 2, 0]
    })

    arc.closed = true
    arc.fillColor = this.insertMaterial.uiColour
    arc.strokeColor = 'black'

    let subtractShadowRec = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(throughX * 2, 3))
    let subtractQuartRecOne = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(throughX + 3, throughY))
    let subtractQuartRecTwo = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(throughX * 2, 3))

    let subtractPath
    switch (this.handleType) {
      case 'lrgHalfArc':
      case 'halfArc':
        subtractPath = subtractShadowRec
        break
      case 'lrgQuartArc':
      case 'quartArc':
        subtractPath = subtractQuartRecOne.unite(subtractQuartRecTwo)
        break
    }

    if (this.handleType === 'halfArc' || this.handleType === 'lrgHalfArc') {
      this.path = arc.subtract(subtractPath)
    } else if (this.handleType === 'quartArc' || this.handleType === 'lrgQuartArc') {
      //   let cornerRec = new paper.Rectangle(new paper.Point(throughX + 3, 3), new paper.Size(12, 12))
      let additionRoundRec = new paper.Path.Rectangle(
        new paper.Rectangle(new paper.Point(throughX + 3, 3), new paper.Size(12, 12)),
        new paper.Size(this.radius, this.radius)
      )
      let subtractRec = new paper.Path.Rectangle(new paper.Point(throughX + 3, 3), new paper.Size(6, 6))

      let quarter = arc.subtract(subtractPath)
      let quarterMinusRec = quarter.subtract(subtractRec)

      this.path = quarterMinusRec.unite(additionRoundRec)

      quarter.remove()
      quarterMinusRec.remove()

      additionRoundRec.remove()
      subtractRec.remove()
    }

    //clean-up
    subtractQuartRecOne.remove()
    subtractQuartRecTwo.remove()
    subtractShadowRec.remove()
    arc.remove()
  }
}
