<template>
  <div>
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center"
      ><v-progress-circular indeterminate></v-progress-circular>
      <p class="mt-5">Just crunching some numbers...</p>
    </v-overlay>
    <div>
      <div id="view-sub-nav">
        <DateRangePicker :empty="true" class="align-self-start" @change="dateRangeChanged"></DateRangePicker>
      </div>
      <div>
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Scheduled Value
                </v-card-title>
                <v-card-text>
                  {{ scheduledValue ? $numeral(scheduledValue).format('$0,0.00') : '-' }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Unscheduled Value
                </v-card-title>
                <v-card-text>
                  {{ unscheduledValue ? $numeral(unscheduledValue).format('$0,0.00') : '-' }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Scheduled FOC
                </v-card-title>
                <v-card-text>
                  {{ scheduledFocValue ? $numeral(scheduledFocValue).format('$0,0.00') : '-' }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Unscheduled FOC
                </v-card-title>
                <v-card-text>
                  {{ uncheduledFocValue ? $numeral(uncheduledFocValue).format('$0,0.00') : '-' }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-data-table :items="orders" :headers="headers">
        <template #item.orderNumber="{item}">
          {{ item.id }}
        </template>
        <template #item.orderNumber="{item}">
          {{ item.data().orderNumber || '-' }}
        </template>
        <template #item.invoiceNumber="{item}">
          {{ item.data().foc ? 'FOC' : item.data().xeroInvoiceNumber || '-' }}
        </template>
        <template #item.total="{item}">
          {{ $numeral(item.data().total).format('$0,0.00') }}
        </template>
        <template #item.customerEmail="{item}">
          {{ item.data().customerContactDetails.email }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script type="text/javascript">
import DateRangePicker from '@/components/ui/DateRangePicker'

export default {
  name: 'Scheduled',
  components: { DateRangePicker },
  data() {
    return {
      activeDateRange: null,
      unscheduledValue: null,
      uncheduledFocValue: null,
      scheduledValue: null,
      scheduledFocValue: null,
      orders: [],
      loading: false,
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Order #',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Invoice #',
          sortable: false,
          value: 'invoiceNumber'
        },
        {
          text: 'Total',
          sortable: false,
          value: 'total'
        },
        {
          text: 'Customer Email',
          sortable: false,
          value: 'customerEmail'
        }
      ]
    }
  },
  async created() {
    // Load unscheduled Jobs
    const unscheduledSnap = await this.$db
      .collection('tasks')
      .where('subtype', '==', 'To customer')
      .where('dateScheduled', '==', null)
      .where('complete', '==', false)
      .get()

    const uniqueOrderIds = []

    for (const task of unscheduledSnap.docs) {
      if (!uniqueOrderIds.includes(task.data().orderNumber)) {
        uniqueOrderIds.push(task.data().orderNumber)
      }
    }

    let totalValue = 0
    let focValue = 0

    for (const id of uniqueOrderIds) {
      const snap = await this.$db
        .collection('orders')
        .where('orderNumber', '==', id)
        .get()
      const value = snap.docs[0].data().region === 'eu' ? snap.docs[0].data().total : snap.docs[0].data().total / 1.2
      if (snap.docs[0].data().foc) {
        focValue += value
      } else {
        totalValue += value
      }
    }

    this.uncheduledFocValue = focValue
    this.unscheduledValue = totalValue
  },
  methods: {
    dateRangeChanged(range) {
      this.activeDateRange = range
      this.scheduledValue = null
      this.scheduledFocValue = null
      this.orders = null
      this.loadData()
    },
    async loadData() {
      this.loading = true
      this.orders = []

      const ordersSnap = await this.$db
        .collection('orders')
        .where('scheduledCompleteDate', '>=', this.activeDateRange[0].startOf('day').toDate())
        .where('scheduledCompleteDate', '<=', this.activeDateRange[1].endOf('day').toDate())
        .get()

      let totalValue = 0
      let focValue = 0

      for (const order of ordersSnap.docs) {
        if (order.data().foc) {
          focValue += order.data().total / 1.2
        } else {
          totalValue += order.data().total / 1.2
        }
      }

      this.orders = ordersSnap.docs
      this.scheduledFocValue = focValue
      this.scheduledValue = totalValue
      this.loading = false
    }
  }
}
</script>
