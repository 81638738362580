<template>
  <v-container id="day" fluid fill-height class="align-start pa-0">
    <v-row class="ma-0">
      <v-col class="pa-0" v-for="(taskTypeGroup, index) in taskTypeGroups" :key="index">
        <div class="coloured d-flex justify-space-between pa-2" :class="taskTypeGroup.name">
          <div :class="taskTypeGroup.name">
            {{ taskTypeGroup.label }}
          </div>
          <v-spacer />
          <div class="count">
            {{ getDailyUnitsByType(taskTypeGroup.name) }}
          </div>
          <div v-if="taskTypeGroup.name === 'finish'">
            <span class="mx-1">/</span>{{ getDailyMegaUnitsByType(taskTypeGroup.name) }} mµ
          </div>
        </div>
        <div
          class="task body-2"
          v-for="(task, key) in tasks"
          :class="[
            task.type,
            $utils.camelize(task.subtype),
            { coloured: !task.complete && !task.paused },
            { paused: task.paused }
          ]"
          :id="task.id"
          v-if="taskTypeGroup.types.indexOf(task.type) >= 0"
          @click="$emit('openTask', task)"
          style="flex-direction: column"
          :key="key"
        >
          <div class="d-flex justify-space-between">
            <div>{{ task.customerEmail }}</div>
            <v-spacer />
            <div>{{ task.units }}</div>
            <div v-if="task.type === 'finish' || task.type === 'assemble'">
              <span class="mx-1">/</span>{{ $utils.toFixedNumber(task.muUnits, 1) }} mµ
            </div>
            <v-icon v-if="task.complete" small>mdi-check</v-icon>
          </div>
          <div class="d-flex justify-space-between align-end">
            <div>
              <v-icon
                v-if="task.type === 'cut' && !task.toolpathed"
                :size="14"
                :style="{
                  'padding-bottom': '1px',
                  'padding-right': '3px',
                  color: task.complete ? 'black' : 'white'
                }"
                >mdi-alert</v-icon
              >
              <v-icon
                v-if="
                  task.type === 'dispatch' && task.subtype === 'To customer' && task.courierBooked && !task.complete
                "
                :size="15"
                dark
                >mdi-truck-check</v-icon
              >
              {{ task.orderNumber || task.invoiceNumber }}
            </div>
            <div class="text-right">
              {{ task.objectType }}
              {{ taskTypeGroup.name == 'dispatch' ? (task.subtype ? task.subtype : task.type) : '' }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Day',
  props: ['taskTypeGroups', 'tasksByDay', 'today'],
  computed: {
    tasks() {
      return this.tasksByDay[this.today]
    }
  },
  methods: {
    getDailyUnitsByType(type) {
      if (!this.tasks) return 0
      var units = 0
      for (var i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].type == type) {
          units += parseInt(this.tasks[i].units)
        }
      }
      return units
    },
    getDailyMegaUnitsByType(type) {
      if (!this.tasks) return 0
      let mu = 0
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].type == type) {
          mu += this.tasks[i].muUnits
        }
        // add assemble mu to finish mu total
        if (this.tasks[i].type == 'assemble' && type == 'finish') {
          mu += this.tasks[i].muUnits
        }
      }
      mu = this.$utils.toFixedNumber(mu, 1)
      return mu
    }
  }
}
</script>

<style lang="scss" scoped>
#day {
  width: 100%;
  transition: width 0.5s;
  .row {
    height: 100%;
    .col {
      border-right: solid 1px $ruleGrey;
      &:last-child {
        border: none;
      }
    }
  }
}
</style>
