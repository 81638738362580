var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class CirclePull {
  constructor(front) {
    // common
    this.models = {}
    this.width = 35
    this.height = 35
    this.insetX = 30
    this.insetY = 25
    this.front = front
    this.fixedRotation = true
    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.circle = new makerjs.models.Ellipse(this.width / 2, this.height / 2)
    makerjs.model.move(this.models.circle, [this.width / 2, -this.height / 2])
    this.models.circle.layer = layerNames.cutout.label
  }
}
