<template>
  <v-row class="align-center">
    <v-col cols="1" style="display: flex; align-items: center">
      <div class="d-flex flex-column mr-1">
        <v-icon small @click="$emit('rowUp', rowNum)">mdi-menu-up</v-icon>
        <v-icon small @click="$emit('rowDown', rowNum)">mdi-menu-down</v-icon>
      </div>
      <MegaCell :data.sync="data.name" :row="rowNum" :col="0" :dataType="'String'" placeholder="Name"></MegaCell>
    </v-col>
    <v-col cols="1" class="d-flex justify-space-between">
      <MegaSelect
        :row="rowNum"
        :col="1"
        :options="pantryTypes"
        :data.sync="data.pantryType"
        placeholder="Select type"
        @change="typeChange"
      ></MegaSelect>
      <v-tooltip v-if="tip" top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ tip }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="1" class="d-flex justify-space-between">
      <MegaSelect
        :row="rowNum"
        :col="2"
        :options="pantryHinges"
        :data.sync="data.pantryHinge"
        placeholder="Select type"
      ></MegaSelect>
      <v-tooltip v-if="hTip" top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hTip }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" class="d-flex align-center">
      <div v-if="data.carcass" class="colour mr-2" :style="{ 'background-color': findMaterialHex(data.carcass) }"></div>
      <MegaSelect
        :row="rowNum"
        :col="3"
        :options="carcassMaterials"
        :data.sync="data.carcass"
        placeholder="Select carcass material"
        noOptions="No 18mm materials created"
      ></MegaSelect>
    </v-col>
    <v-col cols="1" class="d-flex align-center">
      <div v-if="data.back" class="colour mr-2" :style="{ 'background-color': findMaterialHex(data.back) }"></div>
      <MegaSelect
        :row="rowNum"
        :col="4"
        :options="backMaterial"
        :data.sync="data.back"
        placeholder="Select back material"
        noOptions="No 6mm materials created"
      ></MegaSelect>
    </v-col>
    <v-col cols="1" class="d-flex">
      <v-icon @click="lightingDialog = true">mdi-lightbulb-on</v-icon>
      <v-icon @click="flatPackDialog = true" class="ml-2 pt-1">mdi-package-variant</v-icon>
    </v-col>
    <v-col cols="1">
      <MegaSelect
        :row="rowNum"
        :col="5"
        :options="pantryWidthOptions"
        :data.sync="data.w"
        placeholder="Select carcass width"
        noOptions="No 18mm materials created"
      ></MegaSelect>
    </v-col>
    <v-col cols="1">
      <MegaSelect
        :row="rowNum"
        :col="6"
        :options="pantryHeightOptions"
        :data.sync="data.h"
        placeholder="Select carcass height"
        noOptions="No 18mm materials created"
      ></MegaSelect>
    </v-col>
    <v-col cols="1">
      <MegaSelect
        :row="rowNum"
        :col="7"
        :options="pantryDepthOptions"
        :data.sync="data.d"
        placeholder="Select carcass depth"
        noOptions="No 18mm materials created"
      ></MegaSelect>
    </v-col>
    <v-col cols="1" class="d-flex align-center">
      <div v-if="data.base" class="colour mr-2" :style="{ 'background-color': findMaterialHex(data.base) }"></div>
      <MegaSelect
        v-if="data.pantryType == 'l'"
        :row="rowNum"
        :col="8"
        :options="baseMaterials"
        :data.sync="data.base"
        :placeholder="data.pantryType == 'l' ? 'Select material' : 'N/A'"
        noOptions="No base materials created / Matching laminate carcass"
      ></MegaSelect>
      <div v-else class="caption text--disabled">N/A</div>
    </v-col>
    <v-col class="d-flex" :order="wonky ? 'first' : 'last'" :class="[{ 'justify-end': !wonky }]" cols="1" v>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" @click="$emit('copy', rowNum)" v-on="on">mdi-content-copy</v-icon>
        </template>
        <span>Copy</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-1" @click="$emit('copyBottom', rowNum)" v-on="on">mdi-arrow-collapse-down</v-icon>
        </template>
        <span>Copy to bottom</span>
      </v-tooltip>
      <v-tooltip top :open-delay="500">
        <template v-slot:activator="{ on }">
          <v-icon small @click="$emit('delete', rowNum)" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </v-col>

    <!-- Lighting Dialog -->
    <v-dialog v-model="lightingDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Lighting Options</v-card-title>
        <v-card-text class="pt-4">
          <p>
            When turned on, the pantry will have {{ this.productData.getPantryLightingWidth(data.w) }} lighting on the
            fixed bottom shelf and the top piece. Default is to have no lights.
            <v-switch v-model="data.lighting" :label="data.lighting ? 'add 2 lights' : 'no lighting'"></v-switch></p
        ></v-card-text>
      </v-card>
    </v-dialog>

    <!-- Flat Pack Dialog -->
    <v-dialog v-model="flatPackDialog" width="600">
      <v-card>
        <v-card-title class="primary white--text title">Flat Pack Delivery</v-card-title>
        <v-card-text class="pt-4">
          <p>
            When turned on, the pantry will be sent flat-packed to the client. Default is to be sent pre-assembled.
            <v-switch
              v-model="data.flatPack"
              :label="data.flatPack ? 'sent flat-packed' : 'pre-assembled'"
            ></v-switch></p
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import productData from '@/js/productData'

import MegaSelect from '@/components/quoting/items/MegaSelect'
import MegaCell from '@/components/quoting/items/MegaCell'

export default {
  name: 'Pantry',
  props: ['rowNum', 'data', 'materials', 'wonky'],
  components: { MegaCell, MegaSelect },
  data() {
    return {
      productData: productData,
      pantryTypes: productData.pantryTypes,
      pantryHinges: productData.pantryHinges,
      hPresets: null,
      dPresets: null,
      lightingDialog: false,
      flatPackDialog: false,
      pantryWidthOptions: [600, 800, 1000, 1200],
      pantryHeightOptions: [1200, 1400, 1600],
      pantryDepthOptions: [367, 591]
    }
  },
  computed: {
    tip() {
      let tip = null
      if (this.data.pantryType) {
        tip = productData.getPantryType(this.data.pantryType).tip
      }
      return tip
    },
    hTip() {
      let hTip = null
      if (this.data.pantryHinge) {
        hTip = productData.getPantryHinge(this.data.pantryHinge).hTip
      }
      return hTip
    },
    materialDimensions() {
      let o = { w: null, h: null }
      if (!this.data.carcass) return { w: 0, h: 0 }
      for (var i = 0; i < this.materials.length; i++) {
        if (this.data.carcass == this.materials[i].value) {
          o.w = this.materials[i].w - 40
          o.h = this.materials[i].h - 40
          break
        }
      }
      return o
    },
    carcassMaterials() {
      return this.materials.filter(material => {
        return material.thickness == 18
      })
    },
    baseMaterials() {
      return this.materials.filter(material => {
        return material.thickness == 18 && !material.hasGrain && material.value !== this.data.carcass
      })
    },
    backMaterial() {
      return this.materials.filter(material => {
        return material.thickness == 6 && (material.hasGrain || material?.sides == 1)
      })
    }
  },
  methods: {
    typeChange() {
      if (this.data.pantryType == 'm') {
        this.data.base = null
      }
    },
    findMaterialHex(materialCode) {
      if (!materialCode) return []
      const match = this.materials.find(material => {
        return material.value === materialCode
      })
      return match.hex
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  border-bottom: solid 1px #eee;
  .col {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
::v-deep .v-text-field__prefix {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
}

.colour {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border: solid 1px #999;
  border-radius: 3px;
  margin-right: 12px;
}
</style>
