<template>
  <div id="quote-edit" v-if="quote !== null && selectedVersion !== null">
    <div id="view-nav">
      <v-tabs
        v-if="!quote.versions[selectedVersion].published"
        :height="60"
        background-color="primary"
        dark
        slider-color="accent"
        @change="onTabChange"
      >
        <v-tab
          v-for="(tab, key) in tabs"
          :disabled="(key == 1 && !materialHandleSet) || (tab.excludeUS && quote.region == 'us')"
          :key="key"
        >
          {{ tab.title }}</v-tab
        >
        <v-icon v-if="unsavedChanges" dark :size="20" class="ml-2">mdi-content-save</v-icon>
      </v-tabs>
      <v-tabs v-else :height="60" background-color="primary" dark slider-color="accent" @change="onTabChange">
        <v-tab :key="0">Quote</v-tab>
        <v-tab :key="1">Visual</v-tab>
        <v-tab :key="2">CAD</v-tab>
      </v-tabs>
      <div id="page-actions" class="d-flex align-center" v-if="currentTab == 0">
        <v-select dark dense hide-details v-model="selectedVersion" :items="versionNames" class="mt-0 pt-0 mr-2 body-2">
          <template v-slot:item="{ item }">
            <div class="d-flex" :style="{ width: '100%' }">
              <div class="text-caption">{{ item.text }}</div>
              <v-icon class="ml-auto" v-if="item.order" small>mdi-cart</v-icon>
            </div>
          </template>
        </v-select>
        <v-icon
          dark
          :ripple="false"
          :size="20"
          class="mx-2"
          @click="
            () => {
              tmpValue = quote.versions[selectedVersion].title
              renameDialog = !renameDialog
            }
          "
          >mdi-format-title</v-icon
        >
        <v-icon dark :size="17" class="mx-2" @click="duplicateSelectedVersion">mdi-content-copy</v-icon>
        <v-icon dark :size="19" class="mx-2" @click="addEmptyVersion">mdi-file-plus</v-icon>
        <v-icon
          class="mx-2"
          v-if="!order"
          dark
          :size="22"
          :disabled="quote.versions.length == 1"
          @click="deleteVersionDialog = true"
          >mdi-delete</v-icon
        >
        <v-btn
          class="ml-2 mr-4"
          v-if="!quote.versions[selectedVersion].published"
          small
          depressed
          color="accent"
          @click="publishSelectedVersion"
          >Publish</v-btn
        >
        <v-btn
          class="ml-2 mr-4"
          v-else
          small
          depressed
          :color="order ? 'warning' : 'accent'"
          @click="unpublishSelectedVersion"
          >{{ order ? 'Edit Order' : 'Unpublish' }}</v-btn
        >
      </div>
    </div>

    <!-- Published Quote Sub Menu -->
    <div id="view-sub-nav" v-if="quote.versions[selectedVersion].published && currentTab == 0">
      <v-btn
        class="mr-2"
        depressed
        outlined
        color="primary"
        v-if="quote.versions[selectedVersion].published"
        @click="
          () => {
            tmpValue = quote.versions[selectedVersion].notes
            editNotesDialog = true
          }
        "
        >Edit Notes</v-btn
      >
      <v-btn class="mx-2" depressed outlined color="primary" @click="copyCustomerQuoteLink">Get Quote Link</v-btn>
      <div v-if="!order" style="display: flex; align-items: center" class="ml-2">
        <v-switch
          v-model="quote.versions[selectedVersion].allowOrder"
          hide-details
          label="Allow customer to place order"
          light
          class="pt-0 mt-0 ml-3"
          @change="unsavedChanges == true"
        ></v-switch>
        <v-switch
          v-if="quote.versions[selectedVersion].allowOrder && !quote.versions[selectedVersion].foc"
          v-model="quote.versions[selectedVersion].allowDeposit"
          hide-details
          label="Allow 50% deposit"
          light
          class="pt-0 mt-0 ml-3"
          @change="unsavedChanges == true"
        ></v-switch>
        <v-switch
          v-if="quote.versions[selectedVersion].allowOrder && !quote.versions[selectedVersion].foc"
          v-model="quote.versions[selectedVersion].allowBankTransfer"
          hide-details
          label="Allow payment by bank transfer"
          light
          class="pt-0 mt-0 ml-3"
          @change="unsavedChanges == true"
        ></v-switch>
      </div>
      <div v-else-if="quote.region === 'eu'">
        <v-btn v-if="order.xeroInvoiceNumber" class="mx-2" depressed outlined color="primary" @click="copyInvoiceLink"
          >Get Invoice Link</v-btn
        >
        <v-btn v-else class="mx-2" depressed outlined color="primary" @click="generateEuInvoice"
          >Generate EU Invoice</v-btn
        >
      </div>
      <v-spacer />
      <v-btn
        v-if="quote.versions[selectedVersion].foc"
        class="mr-4"
        depressed
        outlined
        color="primary"
        @click="placeOrder"
        >Place Order</v-btn
      >
      <v-switch
        v-if="!order"
        v-model="quote.versions[selectedVersion].foc"
        hide-details
        label="Free of charge"
        light
        class="pt-0 mt-0"
        @change="unsavedChanges == true"
      ></v-switch>
      <v-btn v-else class="mr-2" depressed outlined color="primary" @click="recutDialog = true">Create Recut</v-btn>
    </div>

    <transition name="fade" mode="out-in">
      <component
        :is="quote.versions[selectedVersion].published ? publishedComponent : tabs[currentTab].tabComponent"
        :quote="quote"
        :order="order"
        :version.sync="quote.versions[selectedVersion]"
        :customerEmail="quote.customerEmail"
        :config="tabs[currentTab]"
        :key="currentTab"
        @printableSVG="printableSVG"
        @customerVisualCreated="saveVisualId"
        @uidsUpdated="unsavedChanges = true"
        @draftQuoteUpdated="ondraftQuoteUpdated"
        @snack="
          text => {
            this.snackbarTimeout = 5000
            this.snackbar = true
            this.snackbarText = text
          }
        "
      ></component>
    </transition>

    <!-- Notes dialog -->
    <v-dialog v-model="editNotesDialog" width="500">
      <v-card>
        <v-card-text class="pt-4">
          <v-textarea outline label="Order notes" hide-details v-model="tmpValue"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text dark @click="editNotesDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="updateNotes">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Recut Dialog -->
    <v-dialog v-model="recutDialog" width="800">
      <RecutTracker
        v-if="recutDialog"
        :quote="quote"
        :version="quote.versions[selectedVersion]"
        @cancel="recutDialog = false"
        @complete="recutComplete"
      ></RecutTracker>
    </v-dialog>

    <!-- Rename dialog -->
    <v-dialog v-model="renameDialog" persistent width="500">
      <v-card>
        <v-card-title class="primary white--text">Rename Version</v-card-title>
        <v-card-text class="pt-6">
          <v-text-field label="Version name" hide-details v-model="tmpValue"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text dark @click="renameDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="updateVersionTitle">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Rename dialog -->
    <v-dialog v-model="deleteVersionDialog" persistent width="500">
      <v-card>
        <v-card-title class="warning white--text title">Delete Version</v-card-title>
        <v-card-text class="pt-4"
          >Are you sure you want to delete this version? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text dark @click="deleteVersionDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="deleteVersion">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Order Warning -->
    <v-dialog v-model="editOrderDialog" width="800" scrollable>
      <v-card>
        <v-card-title class="warning white--text title">Warning!</v-card-title>
        <v-card-text>
          <p>You are about to edit an order which is in production.</p>
          <p>
            This must not be done without first checking that the changes are OK with the production team.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editOrderDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="unpublishSelectedVersion">Edit Order</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Order price change dialog -->
    <v-dialog v-model="priceChangeDialog" width="600">
      <v-card v-if="this.quote.versions[this.selectedVersion].pricing && this.draftPricing">
        <v-card-title class="primary white--text title">Order value changed</v-card-title>
        <v-card-text class="pt-4">
          <p class="py-3 text-subtitle-2">
            Updates to this order have changed the total by
            {{
              $numeral(
                this.$utils.toFixedNumber(
                  this.draftPricing.total - this.quote.versions[this.selectedVersion].pricing.total,
                  2
                )
              ).format('$0,0.00')
            }}. What would you like to do?
          </p>
          <div>
            <v-btn @click="commitOrderChanges(false)" class="mb-3" block color="primary" dark
              >Keep original total of
              {{
                $numeral(this.$utils.toFixedNumber(this.quote.versions[this.selectedVersion].pricing.total, 2)).format(
                  '$0,0.00'
                )
              }}</v-btn
            >
            <v-btn @click="commitOrderChanges(true)" block color="accent" dark
              >Update to new total of
              {{ $numeral(this.$utils.toFixedNumber(this.draftPricing.total, 2)).format('$0,0.00') }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay v-model="generatingInvoice" color="primary" opacity=".95">
      <div class="text-center">
        <v-progress-circular color="accent" indeterminate />
        <p class="mt-6">Generating Invoice</p>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import uid from 'uid'
import QuoteView from '@/views/QuoteView'
import MegaCad from '@/components/MegaCad'
import CustomerCad from '@/components/CustomerCad'
import MegaTable from '@/components/quoting/megaTable'
import Quote from '@/components/quoting/quote'
import RecutTracker from '@/components/RecutTracker'

export default {
  name: 'QuoteEdit',
  components: {
    MegaTable,
    Quote,
    QuoteView,
    MegaCad,
    CustomerCad,
    RecutTracker
  },
  props: ['quoteID', 'versionID', 'shipment'],
  data() {
    return {
      tabs: [
        {
          title: 'Quote',
          tabComponent: 'Quote'
        },
        {
          title: 'Fronts',
          tabComponent: 'MegaTable',
          itemComponent: 'Front',
          itemType: 'fronts',
          labels: [
            { span: 1, title: 'Type' },
            { span: 1, title: 'Item' },
            { span: 1, title: 'Options' },
            { span: 1, title: 'W' },
            { span: 1, title: 'H' },
            { span: 1, title: 'Qty' },
            { span: 2, title: 'Material' },
            { span: 2, title: 'Handle' },
            { span: 2, title: 'Actions' }
          ]
        },
        {
          title: 'Cover Panels',
          tabComponent: 'MegaTable',
          itemComponent: 'CoverPanel',
          itemType: 'coverPanels',
          labels: [
            { span: 2, title: 'Type' },
            { span: 1, title: 'Location' },
            { span: 2, title: 'Material' },
            { span: 1, title: 'Options' },
            { span: 1, title: 'Width' },
            { span: 1, title: 'Height' },
            { span: 2, title: 'Lacquer' },
            { span: 1, title: 'Qty' },
            { span: 1, title: 'Actions' }
          ]
        },
        {
          title: 'Spacer Panels',
          tabComponent: 'MegaTable',
          itemComponent: 'SpacerPanel',
          itemType: 'spacerPanels',
          labels: [
            { span: 3, title: 'Location' },
            { span: 3, title: 'Material' },
            { span: 2, title: 'Length' },
            { span: 1, title: 'Qty' },
            { span: 1, title: 'Options' },
            { span: 2, title: 'Actions' }
          ]
        },
        {
          title: 'Worktops',
          tabComponent: 'MegaTable',
          itemComponent: 'Worktop',
          itemType: 'worktops',
          labels: [
            { span: 2, title: 'Material' },
            { span: 2, title: 'Thickness' },
            { span: 2, title: 'Colour' },
            { span: 2, title: 'Faces' },
            { span: 2, title: 'Size' },
            { span: 2, title: 'Actions' }
          ]
        },
        {
          excludeUS: true,
          title: 'Urtils',
          tabComponent: 'MegaTable',
          itemComponent: 'Urtil',
          itemType: 'urtils',
          labels: [
            { span: 1, title: 'Name' },
            { span: 1, title: 'Type' },
            { span: 1, title: 'Carcass' },
            { span: 1, title: 'Back' },
            { span: 2, title: 'Options' },
            { span: 1, title: 'W' },
            { span: 1, title: 'H' },
            { span: 1, title: 'D' },
            { span: 1, title: 'Door Material 1' },
            { span: 1, title: 'Door Material 2' },
            { span: 1, title: 'Actions' }
          ]
        },
        {
          excludeUS: true,
          title: 'Pantry',
          tabComponent: 'MegaTable',
          itemComponent: 'Pantry',
          itemType: 'pantries',
          labels: [
            { span: 1, title: 'Name' },
            { span: 1, title: 'Type' },
            { span: 1, title: 'Hinge' },
            { span: 2, title: 'Carcass' },
            { span: 1, title: 'Back' },
            { span: 1, title: 'Options' },
            { span: 1, title: 'W' },
            { span: 1, title: 'H' },
            { span: 1, title: 'D' },
            { span: 1, title: 'Base Material' },
            { span: 1, title: 'Actions' }
          ]
        },
        {
          title: 'Visual',
          tabComponent: 'CustomerCad'
        }
      ],
      currentTab: 0,
      editNotesDialog: false,
      renameDialog: false,
      editOrderDialog: false,
      deleteVersionDialog: false,
      tmpValue: null,
      //
      autoSaveFrequency: 2000,
      unsavedChanges: false,
      editable: false,
      quote: null,
      order: null,
      selectedVersion: null,
      showCustomerDetailsForm: false,
      showEditNotesDialog: false,
      quoteApproved: null,
      loadingUi: null,
      draftPricing: null,
      draftMaterialUsage: null,
      draftMu: null,
      priceChangeDialog: false,
      generatingInvoice: false,
      recutDialog: false
    }
  },
  computed: {
    materialHandleSet() {
      return (
        this.quote.versions[this.selectedVersion].materials.length > 0 &&
        this.quote.versions[this.selectedVersion].handles.length > 0
      )
    },
    // used for version select list - TODO version select list can contain whole object as result rather than key... probably better approach
    versionNames() {
      var a = []
      for (var i = 0; i < this.quote.versions.length; i++) {
        var version = this.quote.versions[i]
        var name =
          version.title == null
            ? this.$moment(this.$utils.ensureFirestoreTimestamp(version.date).toDate()).format('Do MMM YYYY H:mma')
            : version.title
        a.push({
          text: name,
          value: i,
          order: this.quote.orders && this.quote.orders[version.id]
        })
      }
      return a
    },
    publishedComponent() {
      let c
      switch (this.currentTab) {
        case 0:
          c = 'QuoteView'
          break
        case 1:
          c = 'CustomerCad'
          break
        case 2:
          c = 'MegaCad'
          break
      }
      return c
    }
  },
  created() {
    this.bindQuoteFromID()
    // setup periodic autosave
    setInterval(this.saveIfRequired, this.autoSaveFrequency)
  },
  watch: {
    quote: {
      handler(to, from) {
        if (to !== null) {
          // legacy
          if (!this.quote.region) {
            this.quote.region = 'uk'
            this.quote.versions[this.selectedVersion].deliveryLocation = null
          }
          if (from != null) {
            this.unsavedChanges = true
          }
          // bind if new order added for current version
          if (from && this.selectedVersion !== null && this.quote.orders) {
            const versionID = this.quote.versions[this.selectedVersion].id
            if ((!from.orders || !from.orders[versionID]) && to.orders[versionID]) {
              this.getVersionOrder()
            }
          }
        }
      },
      deep: true
    },
    quoteID() {
      this.bindQuoteFromID()
    },
    selectedVersion() {
      this.getVersionOrder()
    }
  },
  methods: {
    ondraftQuoteUpdated(pricing, materialUsage, mu) {
      this.draftPricing = pricing
      this.draftMaterialUsage = materialUsage
      this.draftMu = mu
    },
    getVersionIndexPosFromID(ID) {
      for (var i = 0; i < this.quote.versions.length; i++) {
        if (this.quote.versions[i].id == ID) {
          return i
        }
      }
    },
    async getVersionOrder() {
      if (this.selectedVersion == null) return

      var versionID = this.quote.versions[this.selectedVersion].id
      if (this.quote.orders && this.quote.orders[versionID]) {
        this.$bind('order', this.$db.collection('orders').doc(this.quote.orders[versionID].orderID))
      } else {
        this.order = null
      }
    },
    bindQuoteFromID() {
      this.$bind('quote', this.$db.collection('quotes').doc(this.quoteID)).then(quote => {
        // Set page title if we're NOT in an overlay
        if (!this.shipment) {
          this.$store.commit('setPageTitle', quote.customerEmail)
        }
        if (!this.selectedVersion) {
          this.selectedVersion = this.versionID ? this.getVersionIndexPosFromID(this.versionID) : 0
        }
        this.getVersionOrder()
      })
    },
    onTabChange(val) {
      this.currentTab = val
      this.$store.commit('clearSelectAndFocus')
    },
    gotoCAD() {
      this.$router.push({
        name: 'megacad',
        params: {
          quoteID: this.quote.id,
          versionID: this.quote.versions[this.selectedVersion].id
        }
      })
    },
    async saveIfRequired() {
      // TODO think it would be better to use update just to update the version not the whole quote
      try {
        if (this.unsavedChanges) {
          const docSnap = await this.$db.collection('quotes').doc(this.quoteID)
          docSnap.set(this.quote)
          this.unsavedChanges = false

          if (this.order) {
            const updateTasksForOrderFunc = this.$firebase.functions().httpsCallable('updateTasksForOrder')
            await updateTasksForOrderFunc(this.quote.orders[this.quote.versions[this.selectedVersion].id].orderID)
          }
        }
      } catch (error) {
        // this.$store.commit('openSnackbar', { snackbarText: 'Error Saving' })
        console.log('Error', error)
      }
    },
    saveVisualId(id) {
      this.quote.versions[this.selectedVersion].customerVisualID = id
      this.unsavedChanges = true
    },
    async duplicateSelectedVersion() {
      var clone = Object.assign({}, this.quote.versions[this.selectedVersion])
      clone.id = uid(10)
      clone.date = this.$firebase.firestore.Timestamp.now()
      clone.published = false
      clone.title = 'Copy of ' + clone.title
      clone.creatorEmail = this.$firebase.auth().currentUser.email
      clone.foc = false
      delete clone.pricing
      delete clone.cockupID
      delete clone.mu

      // clone visual if exists
      if (clone.customerVisualID) {
        const visualSnap = await this.$db
          .collection('visuals')
          .doc(clone.customerVisualID)
          .get()
        const newVisual = this.$db.collection('visuals').doc()
        newVisual.set({
          json: visualSnap.data().json
        })
        clone.customerVisualID = newVisual.id
      }

      this.quote.versions.push(clone)
      this.selectedVersion = this.quote.versions.length - 1

      this.$store.commit('openSnackbar', {
        snackbarText: 'Duplicate version created',
        timeout: 5000
      })
    },
    addEmptyVersion() {
      this.quote.versions.push({
        id: uid(10),
        creatorEmail: this.$firebase.auth().currentUser.email,
        published: false,
        title: 'Quote ' + (this.quote.versions.length + 1),
        date: this.$firebase.firestore.Timestamp.now(),
        fronts: [],
        coverPanels: [],
        spacerPanels: [],
        materials: [],
        urtils: [],
        pantries: [],
        handles: [],
        worktops: [],
        discount: 0,
        foc: false,
        currency: this.quote.versions[this.quote.versions.length - 1].currency
      })
      this.selectedVersion = this.quote.versions.length - 1

      this.$store.commit('openSnackbar', {
        snackbarText: 'New version created',
        timeout: 5000
      })
    },
    deleteVersion() {
      if (this.quote.versions[this.selectedVersion].cockupID) {
        this.$db
          .collection('cockUps')
          .doc(this.quote.versions[this.selectedVersion].cockupID)
          .delete()
      }

      this.quote.versions.splice(this.selectedVersion, 1)
      this.selectedVersion = this.selectedVersion > 0 ? this.selectedVersion - 1 : 0
      this.deleteVersionDialog = false
      this.$store.commit('openSnackbar', {
        snackbarText: 'Version deleted'
      })
    },
    async publishSelectedVersion() {
      var v = this.quote.versions[this.selectedVersion]
      var errors = false
      var s = ''

      // run prepublish checks
      // check all materials are complete
      for (let material of v.materials) {
        if (
          !material.value ||
          !material.thickness ||
          !material.size ||
          (material.requiresColourCode && !material.colourCode)
        ) {
          errors = true
          s += 'Incomplete materials.'
          // console.log('Incomplete', material)
          break
        }
      }

      // check for unused materials
      for (let material of v.materials) {
        let materialInHandles
        let materialInFronts
        let materialInCoverPanels
        let materialInUrtils
        let materialInPantries

        if (v.handles) materialInHandles = v.handles.find(handle => handle.insert == material.id)
        if (v.fronts) materialInFronts = v.fronts.find(front => front.material == material.id)
        if (v.coverPanels) materialInCoverPanels = v.coverPanels.find(coverPanel => coverPanel.material == material.id)
        if (v.urtils)
          materialInUrtils = v.urtils.find(
            urtil =>
              urtil.carcass == material.id ||
              urtil.back == material.id ||
              urtil.material1 == material.id ||
              urtil.material2 == material.id
          )
        if (v.pantries)
          materialInPantries = v.pantries.find(
            pantry => pantry.carcass == material.id || pantry.base == material.id || pantry.back == material.id
          )

        if (
          !materialInHandles &&
          !materialInFronts &&
          !materialInUrtils &&
          !materialInPantries &&
          !materialInCoverPanels
        ) {
          errors = true
          s += `Delete Unused ${material.thickness}mm ${material.label}`
          // console.log('Unused', material)
          break
        }
      }

      // check all handles are complete
      for (var j = 0; j < v.handles.length; j++) {
        if (!v.handles[j].value || v.handles[j].orientation === null || v.handles[j].insert === null) {
          errors = true
          s += 'Incomplete handles. '
          break
        }
      }

      // check for unused handles
      for (let handle of v.handles) {
        let handleInFronts = v.fronts.find(front => front.handle == handle.id)

        if (!handleInFronts) {
          errors = true
          s += `Delete Unused ${handle.label}`
          // console.log('Unused', handle)
          break
        }
      }

      // delivery location
      if (!v.deliveryLocation) {
        errors = true
        s += 'Delivery location required. '
      }

      if (!v.frontTypes) {
        errors = true
        s += 'Front types must be completed'
      }

      if (!v.grainDirection) {
        errors = true
        s += 'Grain direction required.'
      }

      // Ensure no empty additional items
      if (v.additionalItems) {
        for (const item of v.additionalItems) {
          if (item.description === null || item.value === null || item.value.length === 0) {
            s += 'Incomplete additional items'
            errors = true
          }
        }
      }

      if (errors) {
        this.$store.commit('openSnackbar', { snackbarText: s, timeout: 5000 })
      } else {
        const pricingChange = this.quote.versions[this.selectedVersion].pricing
          ? this.$utils.toFixedNumber(
              this.draftPricing.total - this.quote.versions[this.selectedVersion].pricing.total,
              2
            )
          : 0

        if (this.order && pricingChange) {
          this.priceChangeDialog = true
        } else {
          v.pricing = this.draftPricing
          v.materialUsage = this.draftMaterialUsage
          v.mu = this.draftMu
          v.publishedTime = this.$firebase.firestore.Timestamp.now()
          v.published = true
          // if (v.draftPricing) delete v.draftPricing
          this.unsavedChanges = true
        }
      }

      if (v.cockupID) {
        this.$db
          .collection('cockUps')
          .doc(v.cockupID)
          .update({
            value: v.pricing.total,
            foc: v.foc,
            versionID: v.id
          })
      }
    },
    async commitOrderChanges(updatePricing) {
      try {
        // Save changes on the quote
        var v = this.quote.versions[this.selectedVersion]

        if (updatePricing) {
          v.pricing = this.draftPricing
          v.materialUsage = this.draftMaterialUsage
          if (v.draftPricing) delete v.draftPricing
        } else {
          v.draftPricing = this.draftPricing
          v.draftMaterialUsage = this.draftMaterialUsage
        }

        v.publishedTime = this.$firebase.firestore.Timestamp.now()
        v.published = true

        // save manually as we need to complete before calling the task update function
        const docSnap = await this.$db.collection('quotes').doc(this.quoteID)
        await docSnap.set(this.quote)

        // update the order...
        this.$db
          .collection('orders')
          .doc(this.quote.orders[this.quote.versions[this.selectedVersion].id].orderID)
          .update({
            balanceOutstanding: this.order.foc ? false : true,
            total: v.pricing.total
          })

        this.priceChangeDialog = false
        return
      } catch (error) {
        console.log(error)
        this.$store.commit('openSnackbar', { snackbarText: error.message })
      }
    },
    unpublishSelectedVersion() {
      this.quote.versions[this.selectedVersion].publishedTime = null
      this.quote.versions[this.selectedVersion].published = false
    },
    copyCustomerQuoteLink() {
      var link =
        window.location.origin +
        '/#/customer/quote/' +
        this.quoteID +
        '/' +
        this.quote.versions[this.selectedVersion].id
      this.$utils.copyToClipboard(link)
      this.$store.commit('openSnackbar', {
        snackbarText: 'Customer quote link copied to clipboard',
        timeout: 5000
      })
    },
    copyInvoiceLink() {
      this.$utils.copyToClipboard(this.order.xeroInvoiceURL)
      this.$store.commit('openSnackbar', {
        snackbarText: 'Invoice link copied to clipboard',
        timeout: 5000
      })
    },
    async generateEuInvoice() {
      this.generatingInvoice = true

      const generateInvoiceFunction = this.$firebase.functions().httpsCallable('generateEuInvoice')
      await generateInvoiceFunction({
        orderID: this.order.id
      })

      await this.getVersionOrder()

      this.$store.commit('openSnackbar', {
        snackbarText: 'Invoice Generated',
        timeout: 5000
      })
      this.generatingInvoice = false
    },
    updateVersionTitle() {
      this.quote.versions[this.selectedVersion].title = this.tmpValue
      this.renameDialog = false
      this.tmpValue = null
    },
    updateNotes() {
      if (!this.quote.versions[this.selectedVersion].notes) {
        this.$set(this.quote.versions[this.selectedVersion], 'notes', this.tmpValue)
      } else {
        this.quote.versions[this.selectedVersion].notes = this.tmpValue
      }
      this.editNotesDialog = false
      this.tmpValue = null
    },
    recutComplete(cockupID, empty, lucidCoverCost) {
      var clone = Object.assign({}, this.quote.versions[this.selectedVersion])

      clone.recut = true
      clone.lucidCoverCost = lucidCoverCost
      clone.recutOriginalVersionID = clone.id
      clone.recutOriginalOrderID = this.quote.orders[clone.id].orderID

      clone.id = uid(10)
      clone.date = this.$firebase.firestore.Timestamp.now()
      clone.published = false
      clone.title = 'Recut of ' + clone.title
      clone.creatorEmail = this.$firebase.auth().currentUser.email
      clone.foc = false
      clone.recut = true
      clone.cockupID = cockupID

      if (empty) {
        clone.fronts = []
        clone.coverPanels = []
        clone.spacerPanels = []
        clone.urtils = []
        clone.pantries = []
        clone.worktops = []
      }

      delete clone.pricing
      delete clone.draftPricing
      delete clone.draftMaterialUsage
      delete clone.customerVisualID

      this.quote.versions.push(clone)
      this.selectedVersion = this.quote.versions.length - 1
      this.recutDialog = false

      this.$store.commit('openSnackbar', {
        snackbarText: 'Recut created',
        timeout: 5000
      })
    },
    placeOrder() {
      var routeData = this.$router.resolve({
        name: 'customerQuote',
        params: {
          quoteID: this.quote.id,
          versionID: this.quote.versions[this.selectedVersion].id
        }
      })
      window.open(routeData.href, '_blank')
    },
    printableSVG() {
      var routeData = this.$router.resolve({
        name: 'customerSVG',
        params: {
          quoteID: this.quoteID,
          versionID: this.quote.versions[this.selectedVersion].id
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
