<template>
  <v-card>
    <v-card-title class="warning white--text title">Add Sample</v-card-title>
    <v-card-text class="mt-3">
      <v-form ref="sampleForm">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="form.firstName"
              class="no-padding"
              required
              placeholder="First Name"
              :rules="[v => !!v || 'First name is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="form.lastName"
              class="no-padding"
              required
              placeholder="Last Name"
              :rules="[v => !!v || 'Last name is required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          v-model="form.email"
          required
          :rules="emailRules"
          :validate-on-blur="true"
          placeholder="Email"
        ></v-text-field>
        <v-text-field
          v-model="form.addressLine1"
          required
          placeholder="Address Line 1"
          :rules="[v => !!v || 'Address Line 1 is required']"
        ></v-text-field>
        <v-text-field v-model="form.addressLine2" placeholder="Address Line 2"></v-text-field>
        <v-text-field
          v-model="form.city"
          required
          placeholder="City"
          :rules="[v => !!v || 'City is required']"
        ></v-text-field>
        <v-row>
          <v-col cols="12" lg="6">
            <v-autocomplete
              v-model="form.state"
              class="no-padding"
              required
              :items="stateCodes"
              :autocomplete="Math.random()"
              placeholder="State"
              :rules="[v => !!v || 'State is required']"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="form.postcode"
              required
              name="postal-code"
              placeholder="Zip code"
              :rules="[v => !!v || 'Zip code is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('cancel', form)">Cancel</v-btn>
      <v-btn color="primary" depressed dark @click="validate">Add Sample</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ukSampleForm',
  data() {
    return {
      feedBackText: null,
      stateCodes: [
        {
          text: 'Alabama',
          value: 'AL'
        },
        {
          text: 'Alaska',
          value: 'AK'
        },
        {
          text: 'American Samoa',
          value: 'AS'
        },
        {
          text: 'Arizona',
          value: 'AZ'
        },
        {
          text: 'Arkansas',
          value: 'AR'
        },
        {
          text: 'California',
          value: 'CA'
        },
        {
          text: 'Colorado',
          value: 'CO'
        },
        {
          text: 'Connecticut',
          value: 'CT'
        },
        {
          text: 'Delaware',
          value: 'DE'
        },
        {
          text: 'District Of Columbia',
          value: 'DC'
        },
        {
          text: 'Federated States Of Micronesia',
          value: 'FM'
        },
        {
          text: 'Florida',
          value: 'FL'
        },
        {
          text: 'Georgia',
          value: 'GA'
        },
        {
          text: 'Guam',
          value: 'GU'
        },
        {
          text: 'Hawaii',
          value: 'HI'
        },
        {
          text: 'Idaho',
          value: 'ID'
        },
        {
          text: 'Illinois',
          value: 'IL'
        },
        {
          text: 'Indiana',
          value: 'IN'
        },
        {
          text: 'Iowa',
          value: 'IA'
        },
        {
          text: 'Kansas',
          value: 'KS'
        },
        {
          text: 'Kentucky',
          value: 'KY'
        },
        {
          text: 'Louisiana',
          value: 'LA'
        },
        {
          text: 'Maine',
          value: 'ME'
        },
        {
          text: 'Marshall Islands',
          value: 'MH'
        },
        {
          text: 'Maryland',
          value: 'MD'
        },
        {
          text: 'Massachusetts',
          value: 'MA'
        },
        {
          text: 'Michigan',
          value: 'MI'
        },
        {
          text: 'Minnesota',
          value: 'MN'
        },
        {
          text: 'Mississippi',
          value: 'MS'
        },
        {
          text: 'Missouri',
          value: 'MO'
        },
        {
          text: 'Montana',
          value: 'MT'
        },
        {
          text: 'Nebraska',
          value: 'NE'
        },
        {
          text: 'Nevada',
          value: 'NV'
        },
        {
          text: 'New Hampshire',
          value: 'NH'
        },
        {
          text: 'New Jersey',
          value: 'NJ'
        },
        {
          text: 'New Mexico',
          value: 'NM'
        },
        {
          text: 'New York',
          value: 'NY'
        },
        {
          text: 'North Carolina',
          value: 'NC'
        },
        {
          text: 'North Dakota',
          value: 'ND'
        },
        {
          text: 'Northern Mariana Islands',
          value: 'MP'
        },
        {
          text: 'Ohio',
          value: 'OH'
        },
        {
          text: 'Oklahoma',
          value: 'OK'
        },
        {
          text: 'Oregon',
          value: 'OR'
        },
        {
          text: 'Palau',
          value: 'PW'
        },
        {
          text: 'Pennsylvania',
          value: 'PA'
        },
        {
          text: 'Puerto Rico',
          value: 'PR'
        },
        {
          text: 'Rhode Island',
          value: 'RI'
        },
        {
          text: 'South Carolina',
          value: 'SC'
        },
        {
          text: 'South Dakota',
          value: 'SD'
        },
        {
          text: 'Tennessee',
          value: 'TN'
        },
        {
          text: 'Texas',
          value: 'TX'
        },
        {
          text: 'Utah',
          value: 'UT'
        },
        {
          text: 'Vermont',
          value: 'VT'
        },
        {
          text: 'Virgin Islands',
          value: 'VI'
        },
        {
          text: 'Virginia',
          value: 'VA'
        },
        {
          text: 'Washington',
          value: 'WA'
        },
        {
          text: 'West Virginia',
          value: 'WV'
        },
        {
          text: 'Wisconsin',
          value: 'WI'
        },
        {
          text: 'Wyoming',
          value: 'WY'
        }
      ],
      form: {
        firstName: null,
        lastName: null,
        email: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        postcode: null,
        country: 'US'
      },
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']
    }
  },
  methods: {
    validate() {
      if (this.$refs.sampleForm.validate()) {
        this.$emit('add', this.form)
      }
    }
  }
}
</script>
