<template>
  <v-card>
    <v-toolbar color="primary" dark class="elevation-0">
      <v-toolbar-title>US Sales Overview</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="align-center">
        <v-menu transition="scale-transition" :close-on-content-click="true" offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              hide-details
              :value="$moment(activeDate, 'YYYY-MM').format('MMMM')"
              readonly
              v-on="on"
              prepend-icon="mdi-calendar"
            ></v-text-field>
          </template>
          <v-date-picker
            :allowed-dates="allowedDates"
            :value="activeDate"
            type="month"
            no-title
            @input="monthChanged"
          ></v-date-picker>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="tableData"
      :no-data-text="noDataText"
      :loading="loading"
      sort-desc
      sort-by="ordersValue"
    >
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.numQuotes }}</td>
          <td>{{ props.item.numOrders }}</td>
          <td class="text-right">${{ $numeral(props.item.ordersValue).format('0,0.00') }}</td>
        </tr>
      </template>
      <template slot="body.append">
        <tr>
          <td><strong>Totals</strong></td>
          <td>
            <strong>{{ totals.quotes }}</strong>
          </td>
          <td>
            <strong>{{ totals.orders }}</strong>
          </td>
          <td class="text-right">
            <strong>${{ $numeral(totals.value).format('0,0.00') }}</strong>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'QuotePeriodOverview',
  data() {
    return {
      loading: true,
      quotes: null,
      orders: null,
      activeDate: this.$moment().format('YYYY-MM'),
      headers: [
        {
          text: 'User',
          value: 'email',
          sortable: false
        },
        {
          text: 'Quotes',
          value: 'numQuotes',
          sortable: false
        },
        {
          text: 'Orders',
          value: 'numOrders',
          sortable: false
        },
        {
          text: 'Value',
          value: 'ordersValue',
          sortable: false,
          align: 'right'
        }
      ],
      tableData: [],
      totals: {
        quotes: 0,
        orders: 0,
        value: 0
      }
    }
  },
  created() {
    this.bindData()
  },
  computed: {
    noDataText() {
      return this.loading ? '' : 'No Data'
    }
  },
  methods: {
    monthChanged(val) {
      this.loading = true
      this.activeDate = val
      this.bindData()
    },
    bindData() {
      if (this.activeDate) {
        this.$bind(
          'quotes',
          this.$db
            .collection('quoteIndex')
            .where(
              'dateCreated',
              '>=',
              moment(this.activeDate, 'YYYY-MM')
                .startOf('month')
                .toDate()
            )
            .where(
              'dateCreated',
              '<=',
              moment(this.activeDate, 'YYYY-MM')
                .endOf('month')
                .toDate()
            )
        )
        this.$bind(
          'orders',
          this.$db
            .collection('orders')
            .where(
              'dateCreated',
              '>=',
              moment(this.activeDate, 'YYYY-MM')
                .startOf('month')
                .toDate()
            )
            .where(
              'dateCreated',
              '<=',
              moment(this.activeDate, 'YYYY-MM')
                .endOf('month')
                .toDate()
            )
        ).then(() => {
          this.loading = false
          this.processData()
        })
      }
    },
    processData() {
      this.totals = {
        quotes: 0,
        orders: 0,
        value: 0
      }

      var o = {}

      // add the quotes data
      this.quotes.forEach(doc => {
        if (doc.region === 'us') {
          this.totals.quotes++
          if (!o[doc.creatorEmail]) {
            o[doc.creatorEmail] = {
              email: doc.creatorEmail,
              numQuotes: 1,
              numOrders: 0,
              ordersValue: 0,
              paidValue: 0
            }
          } else {
            o[doc.creatorEmail].numQuotes++
          }
        }
      })

      // add the invoices data
      this.orders.forEach(doc => {
        if (doc.type !== 'sample-pack' && doc.region === 'us' && !doc.foc) {
          var val = doc.total

          if (!o[doc.creatorEmail]) {
            o[doc.creatorEmail] = {
              email: doc.creatorEmail,
              numQuotes: 0,
              numOrders: 1,
              ordersValue: val,
              paidValue: doc.numPayments > 0 ? val : 0
            }
          } else {
            o[doc.creatorEmail].numOrders++
            o[doc.creatorEmail].ordersValue += val
          }
          this.totals.orders++
          this.totals.value += val
        }
      })

      this.tableData = Object.values(o)
    },
    allowedDates(val) {
      var parsed = this.$moment(val, 'YYYY-MM-DD')
      return this.$moment().isAfter(parsed)
    }
  }
}
</script>
