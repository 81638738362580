const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class HalfQuartArcHandle {
  constructor(handleWidth, handleType) {
    this.models = {}

    this.handleWidth = parseInt(handleWidth)
    this.handleType = handleType
    this.handlePocketOffset = 19.5
    this.insertInset = 0.5
    this.shadowOffset = 1.5

    this.screwHoleRadius = 1.5
    this.screwHoleInsetX = 6.5
    this.baseScrewInsetY = 6.5 // the amount for most left and right screw hole to be bumped in the Y direction

    switch (handleType) {
      case 'halfArc':
      case 'lrgHalfArc':
        this.insertCircleWidth = this.handleWidth / 2 + this.handlePocketOffset - this.insertInset
        this.subtractInsertRecWidth = (this.handleWidth / 2 + this.handlePocketOffset - this.insertInset + 2) * 2
        this.subtractInsertRecHeight =
          this.handleWidth / 2 + this.handlePocketOffset - this.insertInset + this.shadowOffset
        this.subtractInsertRecPosX = -this.handleWidth / 2 - this.handlePocketOffset + this.insertInset - 2
        this.subtractInsertRecPosY = -this.shadowOffset
        this.firstHoleOriginX =
          -this.handleWidth / 2 + this.handlePocketOffset - this.insertInset - this.screwHoleInsetX
        this.firstHoleOriginY = this.shadowOffset
        this.arcRadius = this.handleWidth / 2 + this.handlePocketOffset - this.insertInset - this.screwHoleInsetX
        this.arcAngle = 180
        this.screwHoleCount = 4
        break
      case 'quartArc':
      case 'lrgQuartArc':
        this.insertCircleWidth = this.handleWidth + this.handlePocketOffset - this.insertInset
        this.subtractInsertRecWidth = this.handleWidth + this.handlePocketOffset - this.insertInset - this.shadowOffset
        this.subtractInsertRecHeight = this.handleWidth + this.handlePocketOffset - this.insertInset
        this.subtractInsertRecPosX = this.shadowOffset
        this.subtractInsertRecPosY =
          -(this.handleWidth + this.handlePocketOffset - this.insertInset) - this.shadowOffset
        this.firstHoleOriginX = -(this.handleWidth + this.handlePocketOffset - this.insertInset) - this.screwHoleInsetX
        this.firstHoleOriginY = this.shadowOffset
        this.arcRadius = this.handleWidth + this.handlePocketOffset - this.insertInset - this.screwHoleInsetX
        this.arcAngle = -90
        this.screwHoleCount = 3
        break
    }

    this.generateHalfQuartArcHandle()
  }

  generateHalfQuartArcHandle() {
    // combine(modelA, modelB, A inside B, A outside B, B inside A, B outside A)
    // handle outline
    const insertCircle = new makerjs.models.Ellipse(this.insertCircleWidth, this.insertCircleWidth)

    const subtractInsertRec = new makerjs.models.Rectangle(this.subtractInsertRecWidth, this.subtractInsertRecHeight)

    subtractInsertRec.origin = [this.subtractInsertRecPosX, this.subtractInsertRecPosY]
    if (this.handleType == 'halfArc' || this.handleType == 'lrgHalfArc') {
      this.models.handleInsert = makerjs.model.combine(insertCircle, subtractInsertRec, false, true, true, false)
    } else if (this.handleType == 'quartArc' || this.handleType == 'lrgQuartArc') {
      this.models.handleInsert = makerjs.model.combine(insertCircle, subtractInsertRec, true, false, true, false)
    }
    this.models.handleInsert.layer = layerNames.insertCutout.label

    // handle screws
    const firstHole = new makerjs.models.Ellipse(this.screwHoleRadius, this.screwHoleRadius)
    firstHole.origin = [this.firstHoleOriginX, this.firstHoleOriginY]
    // screw guide arc
    const screwGuideArc = new makerjs.paths.Arc([0, 0], this.arcRadius, this.arcAngle, 0)

    // copy and spread over arc
    let screwRow = makerjs.layout.cloneToRow(firstHole, this.screwHoleCount, 0)
    // layout on the childrenOnPath(clones, guide, y-center, not reversed, contained)
    this.models.screwHoles = makerjs.layout.childrenOnPath(screwRow, screwGuideArc, 0.5, false, true)
    if (this.handleType == 'halfArc' || this.handleType == 'lrgHalfArc') {
      //bump first hole
      makerjs.model.moveRelative(this.models.screwHoles.models[0], [0, -this.baseScrewInsetY + this.shadowOffset])
      //bump last hole
      makerjs.model.moveRelative(this.models.screwHoles.models[this.screwHoleCount - 1], [
        0,
        -this.baseScrewInsetY + this.shadowOffset
      ])
    } else if (this.handleType == 'quartArc' || this.handleType == 'lrgQuartArc') {
      //bump first hole
      makerjs.model.moveRelative(this.models.screwHoles.models[0], [this.baseScrewInsetY - this.shadowOffset, 0])
      //bump last hole
      makerjs.model.moveRelative(this.models.screwHoles.models[this.screwHoleCount - 1], [
        0,
        -this.baseScrewInsetY + this.shadowOffset
      ])
    }
    this.models.screwHoles.layer = layerNames.insertCountersink.label
  }
}
