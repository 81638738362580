<template>
  <div>
    <div id="view-sub-nav">
      <v-btn
        depressed
        outlined
        color="primary"
        @click="
          () => {
            dialogMode = 'Create'
            selectedHandle = newHandleForm
            handleDialog = true
          }
        "
        >Create New Handle</v-btn
      >
      <v-spacer />
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      v-if="handles"
      :items="handles"
      :loading="loading"
      :headers="headers"
      sort-by="text"
      :items-per-page="25"
      :search="tableSearch"
    >
      <template v-slot:item.costUK="{ item }">
        <v-text-field
          class="ma-0 pa-0"
          :style="{ fontSize: '13px' }"
          v-model.number="item.costUK"
          @change="changed(item, 'costUK')"
          hide-details
          prefix="£"
        ></v-text-field>
      </template>
      <template v-slot:item.costUS="{ item }">
        <v-text-field
          class="ma-0 pa-0"
          :style="{ fontSize: '13px' }"
          v-model.number="item.costUS"
          @change="changed(item, 'costUS')"
          hide-details
          prefix="$"
        ></v-text-field>
      </template>
      <template v-slot:item.mu="{ item }">
        <v-text-field
          class="ma-0 pa-0"
          :style="{ fontSize: '13px' }"
          v-model.number="item.mu"
          @change="changed(item, 'mu')"
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:item.orientations="{ item }">
        <v-chip
          class="table-chips ma-1"
          v-for="(orientation, key) in item.orientations"
          :key="key"
          multiple
          small-chips
          dense
          hide-details
          >{{ productData.getOrientation(orientation) }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <td class="d-flex justify-end align-center">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                @click="
                  () => {
                    dialogMode = 'Edit'
                    handleDialog = true
                    selectedHandle = item
                  }
                "
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Edit Handle</span>
          </v-tooltip>
          <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                @click="
                  () => {
                    deleteHandleDialog = true
                    selectedHandle = item
                  }
                "
                v-on="on"
                >mdi-delete</v-icon
              >
            </template>
            <span>Delete Handle</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="handleDialog" width="800">
      <v-card>
        <v-card-title class="primary white--text title">
          {{ dialogMode == 'Create' ? 'Create New Handle' : 'Edit Handle' }}
        </v-card-title>
        <v-card-text class="mt-3">
          <v-form>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Code"
                  :disabled="dialogMode === 'Edit'"
                  v-model="selectedHandle.value"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Name" v-model="selectedHandle.text"></v-text-field>
              </v-col>
            </v-row>
            <v-text-field label="Cost" v-model.number="selectedHandle.cost"></v-text-field>
            <v-select
              v-model="selectedHandle.orientations"
              :items="filterOrientationOptions(selectedHandle)"
              label="Orientations"
              multiple
              chips
            ></v-select>
            <v-checkbox v-model="selectedHandle.hasInsert" label="Has Insert" @change="onInsertChange"></v-checkbox>
            <v-row v-if="selectedHandle.hasInsert">
              <v-col cols="12">
                Insert Size
              </v-col>
              <v-col cols="6">
                <v-select v-model="selectedHandle.insertWidthUnit" :items="['mm', '%']" label="Width Unit"></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Width Value" v-model.number="selectedHandle.insertWidthValue"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="selectedHandle.insertHeightUnit" :items="['mm', '%']" label="Height Unit"></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Height Value" v-model.number="selectedHandle.insertHeightValue"></v-text-field>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" v-if="selectedHandle.value && selectedHandle.value == 'j'">
                <v-text-field
                  label="US Linear Cost Per Foot"
                  prefix="$"
                  v-model.number="selectedHandle.insertLinearFtCost"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="selectedHandle.value && !['j', 'invisipull', 'invisipull-short'].includes(selectedHandle.value)"
                  label="US Handles Additional Linear Foot"
                  prefix="Ft."
                  v-model.number="selectedHandle.insertAddLinearFt"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                handleDialog = false
              }
            "
            >Cancel</v-btn
          >
          <v-btn
            color="accent"
            depressed
            dark
            @click="dialogMode === 'Create' ? createNewHandle() : updateHandle()"
            :disabled="submitting"
            >{{ dialogMode === 'Create' ? 'Create' : 'Update' }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteHandleDialog" width="800">
      <v-card>
        <v-card-title class="primary white--text title">Delete Handle</v-card-title>
        <v-card-text class="mt-3">
          <p>Are you sure you want to delete {{ selectedHandle.text }}?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteHandleDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="deleteHandle()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import productData from '@/js/productData.js'

export default {
  name: 'HandleSettings',
  data() {
    return {
      productData: productData,
      tableSearch: '',
      handles: null,
      handleDialog: false,
      deleteHandleDialog: false,
      submitting: false,
      selectedHandle: {},
      newHandleForm: {},
      loading: true,
      dialogMode: null,
      orientations: [
        { text: 'Horizontal', value: 'h' },
        { text: 'Vertical', value: 'v' },
        { text: 'Centered', value: 'c' },
        { text: 'Vertically Centered', value: 'vc' },
        { text: 'Vertical Base Top', value: 'vbt' },
        { text: 'Vertical Wall Bottom', value: 'vwb' },
        { text: 'Centered Upside Down', value: 'updn' }
      ],
      headers: [
        {
          text: 'Name',
          sortable: false,
          value: 'text'
        },
        {
          text: 'Has Insert',
          sortable: false,
          value: 'hasInsert'
        },
        {
          text: 'Orientations',
          sortable: false,
          value: 'orientations'
        },
        {
          text: 'UK Price',
          sortable: false,
          value: 'costUK'
        },
        {
          text: 'US Price',
          sortable: false,
          value: 'costUS'
        },
        {
          text: 'Mu Adjust',
          sortable: false,
          value: 'mu'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ]
    }
  },
  async mounted() {
    this.$bind('handles', this.$db.collection('handles'))
    this.loading = false
  },
  methods: {
    async createNewHandle() {
      this.submitting = true

      const newDocRef = this.$db.collection('handles').doc()
      await newDocRef.set(this.newHandleForm)
      this.submitting = false
      this.handleDialog = false

      if (!this.selectedHandle) this.selectedHandle = this.handles[0]
    },
    async updateHandle() {
      this.submitting = true
      await this.$db
        .collection('handles')
        .doc(this.selectedHandle.id)
        .set(this.selectedHandle)

      this.submitting = false
      this.handleDialog = false
      this.$store.commit('openSnackbar', { snackbarText: 'Handle Updated' })
    },
    onInsertChange(value) {
      if (!value) {
        delete this.selectedHandle.insertWidthUnit
        delete this.selectedHandle.insertWidthValue
        delete this.selectedHandle.insertHeightUnit
        delete this.selectedHandle.insertHeightValue
      }
    },
    deleteHandle() {
      this.$db
        .collection('handles')
        .doc(this.selectedHandle.id)
        .delete()
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Handle Deleted 🙌'
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    changed(item, attribute) {
      // update in firebase
      this.$db
        .collection('handles')
        .doc(item.id)
        .update({
          [attribute]: item[attribute]
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Handle Updated 🙌'
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    filterOrientationOptions(handle) {
      if (['semiGrab', 'midSemiGrab', 'lrgSemiGrab', 'lrgHalfArc', 'halfArc'].includes(handle.value)) {
        return this.orientations
      } else {
        return this.orientations.filter(o => o.value !== 'updn')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table-chips {
  .v-input__slot::before {
    border: none !important;
  }
}
</style>
