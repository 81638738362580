<template>
  <div id="week">
    <v-container fluid class="flex-grow-0 pa-0">
      <v-row class="ma-0 header">
        <v-col v-for="(day, date) in tasksByDay" :key="date" class="py-0">
          <v-row>
            <v-col cols="4 no-border flex-grow-1 d-flex flex-column justify-space-between">
              <div class="text-body-2 pt-1" :class="$utils.datePPF(date)">
                {{ $moment(date).format('ddd') }}
              </div>
              <div class="d-flex align-baseline pb-1">
                <v-menu offset-y left open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="text-h4" :class="$utils.datePPF(date)">
                      {{ $moment(date).format('D') }}
                    </div>
                  </template>
                  <div class="staff-card">
                    <div
                      class="worker text-body-2"
                      v-for="(worker, key) in capacityObject[date].working
                        .concat(capacityObject[date].freelancers)
                        .concat(capacityObject[date].overtime)"
                      :key="key"
                    >
                      <div class="name">{{ worker.name }}</div>
                      <div class="hours ml-4 text--secondary">
                        {{ worker.hours }}
                      </div>
                    </div>
                    <div
                      class="worker text-body-2"
                      v-for="worker in capacityObject[date].holidays"
                      :key="worker.timetasticId"
                    >
                      <div class="name text--secondary text-decoration-line-through">
                        {{ worker.name }}
                      </div>
                      <div class="hours ml-4 text--secondary">H</div>
                    </div>
                  </div>
                </v-menu>
                <div
                  v-if="getLegacyCapacity(date)"
                  class="text-body-2 ml-1"
                  :class="{
                    'red--text': getLegacyCapacity(date) < 100,
                    'green--text': getLegacyCapacity(date) >= 100
                  }"
                >
                  {{ getLegacyCapacity(date) }}%
                </div>
              </div>
            </v-col>
            <v-col cols="8" class="text-caption px-0 d-flex flex-column">
              <v-row>
                <v-col cols="7" class="no-border d-flex flex-column">
                  <div class=" flex-grow-1 d-flex align-center justify-end">Cut</div>
                  <div class="text-right flex-grow-1 d-flex align-center justify-end">Finish (Mµ)</div>
                  <div class=" flex-grow-1 d-flex align-center justify-end">Pack</div>
                </v-col>
                <v-col cols="5" class="d-flex flex-column text-body-2">
                  <div class="coloured cut flex-grow-1 d-flex align-center justify-center py-1">
                    {{ getCutCapacity(date) }}%
                  </div>
                  <div class="coloured finish flex-grow-1 d-flex align-center justify-center py-1">
                    {{ getMuFinishCapacity(date) }}%
                  </div>
                  <div class="coloured pack flex-grow-1 d-flex align-center justify-center py-1">
                    {{ getPackCapacity(date) }}%
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="flex-grow-1 pa-0 d-flex flex-column">
      <v-row v-for="(taskTypeGroup, key) in taskTypeGroups" class="ma-0" :key="key">
        <v-col v-for="(day, date) in tasksByDay" :key="date" class="pa-0 d-flex flex-column">
          <div class="task-type body-2" :class="taskTypeGroup.name">
            <div>{{ getDailyUnitsByType(date, taskTypeGroup.name) }}</div>
            <div v-if="taskTypeGroup.name === 'finish'">
              <span class="mx-1">/</span>{{ getDailyMegaUnitsByType(date, taskTypeGroup.name) }} mµ
            </div>
          </div>
          <draggable
            v-model="tasksByDay[date]"
            group="tasks"
            class="draggable-list flex-grow-1"
            @end="onSortEnd"
            :id="date"
          >
            <div
              class="task body-2"
              v-for="task in tasksByDay[date]"
              v-if="taskTypeGroup.types.indexOf(task.type) >= 0"
              :class="[
                task.type,
                $utils.camelize(task.subtype),
                { coloured: !task.complete && !task.paused },
                { paused: task.paused },
                {
                  ghost: focussedTask !== null && task.shipmentID !== focussedTask.shipmentID
                },
                {
                  focussed: focussedTask !== null && task.shipmentID == focussedTask.shipmentID
                }
              ]"
              :id="task.id"
              :key="task.id"
            >
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-icon
                    v-if="task.type === 'cut' && !task.toolpathed"
                    :size="14"
                    :style="{
                      'padding-bottom': '1px',
                      color: task.complete ? 'black' : 'white'
                    }"
                    >mdi-alert</v-icon
                  >
                  <v-icon
                    v-if="task.type === 'dispatch' && task.subtype === 'To customer' && task.recutOrderNumber"
                    :size="14"
                    :style="{
                      'padding-bottom': '1px',
                      color: task.complete ? 'black' : 'white'
                    }"
                    >mdi-puzzle</v-icon
                  >
                  <v-icon
                    v-if="
                      task.type === 'dispatch' && task.subtype === 'To customer' && task.courierBooked && !task.complete
                    "
                    :size="15"
                    dark
                    >mdi-truck-check</v-icon
                  >
                  <div class="mr-2" @click="$emit('focusTask', task)">
                    {{ task.orderNumber || task.invoiceNumber }}
                  </div>
                  <div class="flex-grow-1 d-flex" @click="$emit('openTask', task)">
                    <div>
                      {{ task.objectType }}
                      {{ task.subtype ? task.subtype : task.type }}
                    </div>
                    <v-spacer />
                    <div>
                      {{ task.units }}
                    </div>
                    <div v-if="task.type === 'finish' || task.type === 'assemble'">
                      <span class="mx-1">/</span>{{ $utils.toFixedNumber(task.muUnits, 1) }} mµ
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </draggable>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'Week',
  components: { draggable },
  props: ['taskTypeGroups', 'tasksByDay', 'focussedTask', 'staffUnitsPerDay', 'capacityObject'],
  methods: {
    getDailyUnitsByType(date, type) {
      if (this.tasksByDay[date]) {
        var tasks = this.tasksByDay[date]
        var units = 0
        for (var i = 0; i < tasks.length; i++) {
          if (tasks[i].type == type) {
            units += parseInt(tasks[i].units)
          }
        }
        return units
      }
    },
    getDailyMegaUnitsByType(date, type) {
      if (this.tasksByDay[date]) {
        let tasks = this.tasksByDay[date]
        let mu = 0
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].type == type) {
            mu += tasks[i].muUnits
          }
          // add assemble mu to finish mu total
          if (tasks[i].type == 'assemble' && type == 'finish') {
            mu += tasks[i].muUnits
          }
        }
        mu = this.$utils.toFixedNumber(mu, 1)
        return mu
      }
    },
    getDailyRequiredCapacity(date) {
      let count = 0

      for (var i = 0; i < this.tasksByDay[date].length; i++) {
        // should we exclude this from capacity?
        var t = this.tasksByDay[date][i]
        if (t.type != 'dispatch') {
          count += parseInt(this.tasksByDay[date][i].units)
        }
      }

      return count
    },
    capacityForDay(day) {
      if (day) {
        let hours = 0
        let workers = day.working.concat(day.freelancers).concat(day.overtime)
        for (const worker of workers) {
          hours += worker.hours
        }
        return hours * (this.staffUnitsPerDay / 8) // 8 hour day
      }
    },
    finishMuCapacityForDay(day) {
      if (day) {
        let hours = 0
        let workers = day?.working.concat(day?.freelancers).concat(day?.overtime)
        for (const worker of workers) {
          hours += worker.hours
        }
        hours -= day?.cutting + day?.packing // remove cutting and packing hours
        return hours * (this.$store.state.mu.finishCapacity / 8) // 8 hour day
      }
    },
    onSortEnd(e) {
      this.$emit('onSortEnd', e)
    },
    getLegacyCapacity(date) {
      const req = this.getDailyRequiredCapacity(date)
      const available = this.capacityForDay(this.capacityObject[date])
      return req ? Math.round((available / req) * 100) : false
    },
    getCutCapacity(date) {
      const units = this.tasksByDay[date].reduce((sum, val) => {
        return val.type === 'cut' ? sum + parseInt(val.units) : sum
      }, 0)
      return Math.round((units / this.$store.state.mu.cutCapacity) * 100)
    },
    getMuFinishCapacity(date) {
      const capacity = this.finishMuCapacityForDay(this.capacityObject[date])

      const mu = this.tasksByDay[date].reduce((sum, val) => {
        if (val.type === 'finish' || val.type == 'assemble') {
          return sum + val.muUnits
        } else {
          return sum
        }
      }, 0)

      const utilisation = capacity && mu ? Math.round((mu / capacity) * 100) : 0
      // console.log({ date, mu, capacity, utilisation })

      return utilisation
    },
    getPackCapacity(date) {
      const units = this.tasksByDay[date].reduce((sum, val) => {
        return val.type === 'pack' ? sum + parseInt(val.units) : sum
      }, 0)

      return Math.round((units / this.$store.state.mu.packCapacity) * 100)
    }
  }
}
</script>

<style lang="scss" scoped>
// Capacity input styling
::v-deep .v-text-field {
  flex-grow: 0;
  width: 25px;
  input {
    padding: 0;
    text-align: right;
  }
}

.staff-card {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 4px 6px;
  cursor: pointer;
  .worker {
    display: flex;
    justify-content: space-between;
    padding: 2px 0px;
  }
}

#week {
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: width 0.5s;
  .col {
    border-right: solid 1px $ruleGrey;
    &:last-child,
    &.no-border {
      border: none;
    }
    .task-type {
      background: $sand;
      border-top: solid 1px $ruleGrey;
      display: flex;
      justify-content: flex-end;
      padding: 4px;
    }
    .count {
      white-space: nowrap;
    }
  }
}
</style>
