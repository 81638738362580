import Fronts from './fronts'
import Worktops from './worktops'
import Urtils from './urtils'
import Pantries from './pantries'

export default {
  get(version) {
    const fronts = Fronts.get(version.fronts.concat(version.coverPanels), version.materials, version.handles)
    const worktops = Worktops.get(version.worktops)
    const urtilsFinish = Urtils.get(version.urtils, version.materials, 'finish')
    const urtilsAssemble = Urtils.get(version.urtils, version.materials, 'assemble')
    const pantriesFinish = Pantries.get(version.pantries, version.materials, 'finish')
    const pantriesAssemble = Pantries.get(version.pantries, version.materials, 'assemble')
    return { fronts, worktops, urtilsFinish, urtilsAssemble, pantriesFinish, pantriesAssemble }
  }
}
