<template>
  <div id="staff-settings">
    <div id="view-sub-nav">
      <v-btn
        depressed
        outlined
        color="primary"
        @click="
          () => {
            dialogMode = 'Create'
            selectedItem = newItemForm
            itemDialog = true
          }
        "
        >Create New Staff Member</v-btn
      >
      <v-spacer />
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      v-if="data"
      :items="
        data.filter(o => {
          return !o.departureDate
        })
      "
      :loading="loading"
      :headers="headers"
      sort-by="name"
      :items-per-page="-1"
      :search="tableSearch"
      id="materials-table"
    >
      <template v-slot:item.capacity="{ item }">
        <div v-if="item.staffType === 'employed'">
          <v-chip
            v-if="item.capacity[key] !== null"
            v-for="(day, key) of item.capacity[item.capacity.length - 1].hours"
            :key="key"
            outlined
            class="mx-1"
            >{{ daysOfWeek[key] }}
            <v-avatar right dark>
              {{ item.capacity[item.capacity.length - 1].hours[key] }}
            </v-avatar>
          </v-chip>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <td class="d-flex justify-end align-center">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-1"
                @click="
                  () => {
                    dialogMode = 'Edit'
                    itemDialog = true
                    selectedItem = item
                  }
                "
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Edit Staff Member</span>
          </v-tooltip>
          <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                @click="
                  () => {
                    deleteDialog = true
                    selectedItem = item
                  }
                "
                v-on="on"
                >mdi-delete</v-icon
              >
            </template>
            <span>Delete Staff Member</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>

    <!-- CREATE / Edit -->
    <v-dialog v-model="itemDialog" width="800">
      <v-card>
        <v-card-title class="primary white--text title">
          {{ dialogMode == 'Create' ? 'Create New Staff Member' : 'Edit Staff Member' }}
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form>
            <v-row>
              <v-col>
                <v-text-field label="Name" v-model="selectedItem.name"></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedItem.staffType"
                  :items="staffTypes"
                  label="Staff Type"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="selectedItem.staffType === 'employed'">
              <v-col cols="12">
                <v-text-field
                  label="Timetastic ID"
                  v-model.number="selectedItem.timetasticId"
                  :disabled="dialogMode !== 'Create'"
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="label mb-4 mt-4" v-if="selectedItem.staffType === 'employed'">
              Daily Capacity in hours
            </div>
            <div v-if="selectedItem.staffType === 'employed'">
              <v-row v-for="(schedule, index) of selectedItem.capacity" :key="index">
                <v-col cols="3">
                  <v-menu
                    ref="menu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="schedule.from ? $moment.unix(schedule.from.seconds).format('Do MMM YYYY') : null"
                        label="Start date"
                        prepend-icon="mdi-calendar"
                        :disabled="dialogMode === 'Edit' && !draftSchedule(schedule)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :allowed-dates="allowedDates"
                      :value="schedule.from ? $utils.vuetifyTimestamp(schedule.from) : null"
                      no-title
                      scrollable
                      :first-day-of-week="1"
                      @input="
                        val => {
                          schedule.from = $utils.timestampFromVuetify(val)
                        }
                      "
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="9">
                  <v-row>
                    <v-col v-for="(day, index) of daysOfWeek" :key="index">
                      <v-text-field
                        :disabled="dialogMode === 'Edit' && !draftSchedule(schedule)"
                        v-if="selectedItem.capacity"
                        :key="index"
                        :label="day"
                        outlined
                        v-model.number="schedule.hours[index]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="dialogMode === 'Edit'" class="pa-0" text @click="createNewSchedule"
            ><v-icon>mdi-plus</v-icon>Create new schedule</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                itemDialog = false
                resetNewItemValues()
              }
            "
            >Cancel</v-btn
          >
          <v-btn
            color="accent"
            depressed
            dark
            @click="dialogMode === 'Create' ? createNewItem() : updateItem()"
            :disabled="submitting"
            >{{ dialogMode === 'Create' ? 'Create' : 'Update' }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Remove Staff Member</v-card-title>
        <v-card-text class="pt-6">
          <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="tmpDepartureDate ? $utils.humanFromVuetify($utils.vuetifyTimestamp(tmpDepartureDate)) : null"
                label="Departure date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="tmpDepartureDate ? $utils.vuetifyTimestamp(tmpDepartureDate) : null"
              no-title
              scrollable
              @input="
                val => {
                  tmpDepartureDate = $utils.timestampFromVuetify(val)
                }
              "
            >
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                deleteDialog = false
                tmpDepartureDate = null
              }
            "
            >Cancel</v-btn
          >
          <v-btn color="warning" depressed dark @click="removeStaffMember">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'StaffSettings',
  data() {
    return {
      loading: true,
      tableSearch: null,
      tmpDepartureDate: null,
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Capacity',
          sortable: true,
          value: 'capacity'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ],
      deleteDialog: false,
      itemDialog: false,
      submitting: false,
      selectedItem: {},
      dialogMode: null,
      newItemForm: {},
      data: null,
      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      staffTypes: [
        { text: 'Employed', value: 'employed' },
        { text: 'Freelance', value: 'freelance' }
      ]
    }
  },
  async mounted() {
    this.resetNewItemValues()
    await this.$bind('data', this.$db.collection('staff')).then(async () => {
      this.loading = false
    })
  },
  methods: {
    resetNewItemValues() {
      this.newItemForm = {
        name: null,
        staffType: null,
        timetasticId: null,
        capacity: [
          {
            from: null,
            hours: [null, null, null, null, null, null, null]
          }
        ]
      }
    },
    draftSchedule(s) {
      const nullHours = s.hours.findIndex(v => v === null)
      return s.from === null || nullHours !== -1 ? true : false
    },
    allowedDates(val) {
      if (this.dialogMode === 'Create') {
        return true
      } else {
        return this.$moment(val, 'YYYY-MM-DD').isAfter(
          this.$moment.unix(this.selectedItem.capacity[this.selectedItem.capacity.length - 2].from.seconds)
        )
      }
    },
    async createNewItem() {
      this.submitting = true

      const newDocRef = this.$db.collection('staff').doc()
      await newDocRef.set(this.newItemForm)

      this.resetNewItemValues()
      this.submitting = false
      this.itemDialog = false
    },
    async updateItem() {
      this.submitting = true
      await this.$db
        .collection('staff')
        .doc(this.selectedItem.id)
        .set(this.selectedItem)

      this.submitting = false
      this.itemDialog = false
      this.$store.commit('openSnackbar', {
        snackbarText: 'Staff Member Updated'
      })
    },
    deleteItem(item) {
      this.deleteDialog = false
      this.$db
        .collection('staff')
        .doc(item.id)
        .delete()
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Staff Member Deleted'
          })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    async removeStaffMember() {
      this.deleteDialog = false
      await this.$db
        .collection('staff')
        .doc(this.selectedItem.id)
        .update({
          departureDate: this.tmpDepartureDate
        })
      this.tmpDepartureDate = null
      this.$store.commit('openSnackbar', {
        snackbarText: 'That person is dead to us now.'
      })
    },
    createNewSchedule() {
      this.selectedItem.capacity.push({
        from: null,
        hours: [null, null, null, null, null, null, null]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-avatar {
  background: $sand;
}

::v-deep .v-dialog__content {
  position: absolute;
  .v-dialog {
    padding-left: 15px;
    position: absolute;
    left: auto;
    right: 0;
    width: 50%;
    box-shadow: none;
    &:before {
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      width: 15px;
      box-shadow: -15px 0 15px -15px inset rgb(0 0 0 / 15%);
      left: 0;
      z-index: 1;
    }
    // box-shadow: -5px 0px 15px 0px rgb(0 0 0 / 15%)
  }
}
</style>
