<template>
  <div class="mega-select" :class="{ selected: selected, focussed: focussed }" @click="mousedown">
    <v-autocomplete
      :disabled="disabled"
      :ref="uid"
      :value="data"
      :items="options"
      :item-text="itemText || getItemText"
      :placeholder="placeholder"
      :no-data-text="noOptions"
      hide-details
      class="caption"
      @change="onChange"
      solo
      flat
      dense
    >
    </v-autocomplete>
  </div>
</template>

<script>
import uid from 'uid'

export default {
  name: 'MegaSelect',
  props: ['row', 'col', 'options', 'data', 'placeholder', 'noOptions', 'displayValue', 'disabled', 'item-text'],
  data() {
    return {
      uid: null
    }
  },
  created() {
    this.uid = uid(10)
  },
  computed: {
    focussed() {
      return this.row == this.$store.state.focussedCell.row && this.col == this.$store.state.focussedCell.col
    },
    selected() {
      return (
        this.row == this.$store.state.selectedCell.row &&
        this.col == this.$store.state.selectedCell.col &&
        !this.focussed
      )
    }
  },
  watch: {
    focussed(to) {
      if (to) {
        this.$refs[this.uid].focus()
        this.$refs[this.uid].activateMenu()
      } else {
        this.$refs[this.uid].blur()
      }
    }
  },
  methods: {
    getItemText(o) {
      let text
      if (this.displayValue) {
        text = o.value
      } else {
        // text = o.thickness ? o.label + ' ' + o.thickness + 'mm' : o.label
        if (o.thickness) {
          text = o.label + ' ' + o.thickness + 'mm ' + (o?.sides == 1 ? '(Single)' : '')
        } else {
          text = o.label
        }
      }
      return text
    },
    mousedown(e) {
      e.preventDefault()
      this.$store.commit('setFocussedCell', { row: this.row, col: this.col })
    },
    focus() {
      this.$store.commit('setFocussedCell', { row: this.row, col: this.col })
    },
    onChange(val) {
      if (val) {
        this.$emit('update:data', val)
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style lang="scss">
.mega-select {
  flex-grow: 1;
  .v-input {
    .v-input__control {
      min-height: 45px !important;
      .v-input__slot {
        background: none !important;
        padding: 0 !important;
      }
    }
  }
  .v-select__selection--comma {
    margin: 0 !important;
  }
  &.focussed {
    border: solid 0px blue;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  &.selected {
    background: #ffd4cb;
    box-shadow: inset 0 0 0px #1c3145;
  }
}
</style>
