var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class Worktop {
  constructor(worktopSections) {
    this.models = {}
    let sectionCount = 0
    let yPos = 0
    for (const section of worktopSections) {
      if (section.dogleg) {
        this.models['section-' + sectionCount] = {
          layer: layerNames.cutout.label,
          paths: {
            // origin is top right corner
            // arc starts 0deg on the right and must work anti-clockwise; origin is center point of arc radius
            arc1: {
              type: 'arc',
              origin: [-section.radii.br, -section.radii.br],
              radius: section.radii.br,
              startAngle: 0,
              endAngle: 90
            },
            line1: {
              type: 'line',
              origin: [0, -section.radii.br],
              end: [0, -section.d + section.radii.fr]
            },
            arc2: {
              type: 'arc',
              origin: [-section.radii.fr, -section.d + section.radii.fr],
              radius: section.radii.fr,
              startAngle: 270,
              endAngle: 360
            },
            line2: {
              type: 'line',
              origin: [0 - section.radii.fr, -section.d],
              end: [-section.l + section.li + section.radii.fm, -section.d]
            },
            arc3: {
              type: 'arc',
              origin: [-section.l + section.li + section.radii.fm, -section.d + section.radii.fm],
              radius: section.radii.fm,
              startAngle: 180,
              endAngle: 270
            },
            line3: {
              type: 'line',
              origin: [-section.l + section.li, -section.d + section.radii.fm],
              end: [-section.l + section.li, -section.d + section.di - section.radii.i]
            },
            arc4: {
              type: 'arc',
              origin: [-section.l + section.li - section.radii.i, -section.d + section.di - section.radii.i],
              radius: section.radii.i,
              startAngle: 360,
              endAngle: 90
            },
            line4: {
              type: 'line',
              origin: [-section.l + section.li - section.radii.i, -section.d + section.di],
              end: [-section.l + section.radii.fl, -section.d + section.di]
            },
            arc5: {
              type: 'arc',
              origin: [-section.l + section.radii.fl, -section.d + section.di + section.radii.fl],
              radius: section.radii.fl,
              startAngle: 180,
              endAngle: 270
            },
            line5: {
              type: 'line',
              origin: [-section.l, -section.d + section.di + section.radii.fl],
              end: [-section.l, -section.radii.bl]
            },
            arc6: {
              type: 'arc',
              origin: [-section.l + section.radii.bl, -section.radii.bl],
              radius: section.radii.bl,
              startAngle: 90,
              endAngle: 180
            },
            line6: {
              type: 'line',
              origin: [-section.l + section.radii.bl, 0],
              end: [-section.radii.br, 0]
            }
          }
        }
      } else {
        this.models['section-' + sectionCount] = {
          layer: layerNames.cutout.label,
          paths: {
            arc1: {
              type: 'arc',
              origin: [-section.radii.br, -section.radii.br],
              radius: section.radii.br,
              startAngle: 0,
              endAngle: 90
            },
            line1: {
              type: 'line',
              origin: [0, -section.radii.br],
              end: [0, -section.d + section.radii.fr]
            },
            arc2: {
              type: 'arc',
              origin: [-section.radii.fr, -section.d + section.radii.fr],
              radius: section.radii.fr,
              startAngle: 270,
              endAngle: 360
            },
            line2: {
              type: 'line',
              origin: [0 - section.radii.fr, -section.d],
              end: [-section.l + section.radii.fl, -section.d]
            },

            arc3: {
              type: 'arc',
              origin: [-section.l + section.radii.fl, -section.d + section.radii.fl],
              radius: section.radii.fl,
              startAngle: 180,
              endAngle: 270
            },
            line3: {
              type: 'line',
              origin: [-section.l, -section.d + section.radii.fl],
              end: [-section.l, -section.radii.bl]
            },
            arc4: {
              type: 'arc',
              origin: [-section.l + section.radii.bl, -section.radii.bl],
              radius: section.radii.bl,
              startAngle: 90,
              endAngle: 180
            },
            line4: {
              type: 'line',
              origin: [-section.l + section.radii.bl, 0],
              end: [-section.radii.br, 0]
            }
          }
        }
      }

      makerjs.model.move(this.models['section-' + sectionCount], [0, yPos])
      yPos += section.l + 100
      sectionCount++
    }
  }
}
