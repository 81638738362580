<template>
  <div id="cockups">
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center"
      ><v-progress-circular indeterminate></v-progress-circular>
      <p class="mt-5">Just crunching some numbers... can take a little while...</p>
    </v-overlay>
    <div id="view-sub-nav">
      <DateRangePicker :empty="true" class="align-self-start" @change="dateRangeChanged"></DateRangePicker>
      <v-spacer />
      <v-btn class="ml-3" depressed outlined color="primary" @click="addDialog = true">Add Recut</v-btn>
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="3" class="text-center offset-2">
          <div class="text-h5	mb-6">Qty</div>
          <canvas ref="pieByCause"></canvas>
        </v-col>
        <v-col cols="3" class="text-center offset-2">
          <div class="text-h5	mb-6">Cost</div>
          <canvas ref="pieByCauseCost"></canvas>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :items="recuts" :headers="headers">
            <template v-slot:item.date="{ item }">{{ $moment.unix(item.date.seconds).format('Do MMM YYYY') }}</template>
            <template v-slot:item.cause="{ item }"
              ><v-chip v-for="cause of item.cause" :key="cause">{{
                cockupSettings.types.find(o => o.id === cause).name
              }}</v-chip></template
            >
            <template v-slot:item.total="{ item }">{{
              item.value ? $numeral(item.value).format('$0,0.00') : null
            }}</template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top :open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-icon v-if="item.quoteID" small class="mr-2" @click="viewQuote(item)" v-on="on"
                    >mdi-clipboard-text</v-icon
                  >
                </template>
                <span>Original Quote</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Add Dialog -->
    <v-dialog v-model="addDialog" width="800">
      <RecutTracker @cancel="addDialog = false" @complete="addDialog = false"></RecutTracker>
    </v-dialog>

    <v-dialog v-model="quoteDialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary" class="no-print elevation-0">
          <v-toolbar-title>Quote</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              () => {
                quoteDialog = false
                this.selectedRecut = null
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <QuoteEdit
          v-if="selectedRecut"
          :quoteID="selectedRecut.quoteID"
          :versionID="selectedRecut.versionID"
        ></QuoteEdit>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import QuoteEdit from '@/views/QuoteEdit'
import Chart from 'chart.js/auto'
import RecutTracker from '@/components/RecutTracker'
import DateRangePicker from '@/components/ui/DateRangePicker'

export default {
  name: 'Recuts',
  components: { RecutTracker, DateRangePicker, QuoteEdit },
  data() {
    return {
      recuts: [],
      cockupSettings: null,
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Cause(s)', value: 'cause' },
        { text: 'FOC', value: 'foc' },
        { text: 'Total', value: 'total' },
        { text: 'Actions', value: 'actions', align: 'right' }
      ],
      selectedRecut: null,
      loading: false,
      addDialog: false,
      quoteDialog: false,
      pieChart: null,
      backgroundColours: [
        'rgb(160,232,91)',
        'rgb(9,123,53)',
        'rgb(119,218,169)',
        'rgb(29,117,131)',
        'rgb(37,184,234)',
        'rgb(93,103,156)',
        'rgb(225,138,244)',
        'rgb(129,21,180)',
        'rgb(170,164,225)',
        'rgb(124,34,95)',
        'rgb(244,38,151)',
        'rgb(15,82,220)'
      ]
    }
  },
  async mounted() {
    await this.$bind('cockupSettings', this.$db.collection('settings').doc('cockup-settings'))
  },
  methods: {
    async recutsByCause() {
      let rbc = {}

      if (this.cockupSettings && this.recuts.length > 0) {
        for (const recut of this.recuts) {
          for (const cause of recut.cause) {
            if (!rbc[cause]) rbc[cause] = []
            rbc[cause].push(recut)
          }
        }
      }

      let pieData = {
        labels: [],
        datasets: [{ data: [], backgroundColor: this.backgroundColours }]
      }

      let costPieData = {
        labels: [],
        datasets: [{ data: [], backgroundColor: this.backgroundColours }]
      }

      for (const cause of Object.entries(rbc)) {
        let cockupType = this.cockupSettings.types.find(type => {
          return type.id === cause[0]
        })

        pieData.labels.push(cockupType.name)
        pieData.datasets[0].data.push(cause[1].length)

        costPieData.labels.push(cockupType.name)
        let total = 0
        for (const cockup of cause[1]) {
          if (cockup.foc) total += cockup.value
          console.log(cockup)
        }
        costPieData.datasets[0].data.push(total)
      }
      return { rbc, pieData, costPieData }
    },
    async createCharts() {
      const recutData = await this.recutsByCause()

      if (this.pieChart) this.pieChart.destroy()
      this.pieChart = new Chart(this.$refs['pieByCause'], {
        type: 'doughnut',
        data: recutData.pieData,
        options: {
          plugins: {
            legend: {
              display: false
            }
          }
        }
      })
      if (this.pieChartCost) this.pieChartCost.destroy()
      this.pieChartCost = new Chart(this.$refs['pieByCauseCost'], {
        type: 'doughnut',
        data: recutData.costPieData,
        options: {
          plugins: {
            legend: {
              display: false
            }
          }
        }
      })
      return
    },
    async dateRangeChanged(range) {
      this.loading = true
      await this.$bind(
        'recuts',
        this.$db
          .collection('cockUps')
          .where('date', '>=', range[0].startOf('day').toDate())
          .where('date', '<=', range[1].endOf('day').toDate())
      )
      await this.createCharts()
      this.loading = false
    },
    viewQuote(recut) {
      this.quoteDialog = true
      this.selectedRecut = recut
    }
  }
}
</script>
