<template>
  <v-container id="quote" fluid>
    <v-row wrap>
      <v-col cols="12" v-if="notifications.length > 0">
        <v-alert v-for="(notification, key) in notifications" type="warning" :key="key" :value="true" dismissible>{{
          notification
        }}</v-alert>
      </v-col>
      <v-col cols="12" md="8">
        <!-- MATERIALS -->
        <v-card class="compact mb-4 elevation-1">
          <v-card-title class="secondary subtitle-1">
            <div>Materials</div>
            <v-spacer></v-spacer>
            <v-icon @click="addMaterial">mdi-plus</v-icon>
          </v-card-title>

          <v-card-text class="pb-2">
            <v-row v-for="(material, key) in version.materials" :key="key" row align-center class="material mx-0">
              <v-col cols="3" class="d-flex align-center">
                <v-menu :disabled="!material.displayColour" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="colour"
                      :style="{ 'background-color': material.displayColour }"
                      v-bind="attrs"
                      v-on="on"
                    ></div>
                  </template>
                  <v-color-picker v-model="material.displayColour" mode="hexa" hide-mode-switch></v-color-picker>
                </v-menu>
                <v-autocomplete
                  v-model="material.value"
                  :items="masterMaterials"
                  item-text="text"
                  placeholder="Material"
                  @change="onMaterialChange(key, material.value)"
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="2">
                <v-autocomplete
                  v-if="material.value"
                  v-model="material.thickness"
                  :items="getMaterialThicknesses(material.value)"
                  placeholder="Thickness"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-if="material.value"
                  v-model="material.size"
                  :items="getSheetSizes(material.value, material.thickness)"
                  @change="onMaterialSizeChange(key, material.size)"
                  placeholder="Sheet Size"
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="2" v-if="material.value && !Materials.hasGrain(material.value)">
                <v-autocomplete
                  v-model="material.sides"
                  :items="getSheetSides(material.value, material.thickness, material.size)"
                  placeholder="Sheet Sides"
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="2" class="d-flex align-center" v-if="material.value">
                <!-- Laminates -->
                <v-autocomplete
                  v-if="!Materials.hasGrain(material.value)"
                  v-model="material.colourCode"
                  :items="getColourCodes(material.value)"
                  :item-text="
                    i => {
                      return i.value + ' - ' + i.text
                    }
                  "
                  @change="onColourChange(key)"
                  placeholder="Colour"
                  hide-details
                ></v-autocomplete>

                <!-- Wood Faced -->
                <v-checkbox
                  v-if="Materials.hasGrain(material.value)"
                  v-model="material.unfinished"
                  label="Unfinished"
                  hide-details
                  class="mr-4"
                ></v-checkbox>
                <v-checkbox
                  v-if="Materials.hasGrain(material.value)"
                  v-model="material.continuousGrain"
                  label="Continuous"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1" class="d-flex">
                <v-spacer></v-spacer>
                <v-icon @click="removeMaterial(key)" style="flex:0 1 auto!important">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- HANDLES -->
        <v-card class="compact mb-4 elevation-1">
          <v-card-title class="secondary subtitle-1">
            <div>Handles</div>
            <v-spacer></v-spacer>
            <v-icon @click="addHandle">mdi-plus</v-icon>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row v-for="(handle, key) in version.handles" :key="handle.id" class="py-2">
              <v-col cols="4">
                <v-autocomplete
                  v-model="handle.value"
                  :items="handles"
                  placeholder="Select handle"
                  @change="constructHandleLabel(handle)"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" v-if="requiresInsert(handle)">
                <v-select
                  v-model="handle.insert"
                  :items="
                    version.materials.filter(material => {
                      return material.thickness == 4
                    })
                  "
                  item-text="label"
                  item-value="id"
                  placeholder="Insert material"
                  no-data-text="No 4mm materials created"
                  @change="constructHandleLabel(handle)"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="3" v-if="getOrientations(handle)">
                <v-select
                  v-model="handle.orientation"
                  :items="getOrientations(handle)"
                  placeholder="Position"
                  @change="constructHandleLabel(handle)"
                  hide-details
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1" class="d-flex justify-end">
                <v-icon @click="duplicateHandle(key)">mdi-content-copy</v-icon>
                <v-icon @click="removeHandle(key)" style="flex: 0 1 auto !important">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Settings -->
        <v-card class="mb-4 elevation-1">
          <v-card-title class="secondary subtitle-1">
            <div>Settings</div>
          </v-card-title>
          <v-card-text class="pt-6">
            <v-row wrap>
              <v-col cols="12" md="7">
                <v-row id="grain-direction">
                  <v-col cols="12" class="d-flex flex-row">
                    <v-select
                      v-model="version.grainDirection"
                      label="Grain Direction"
                      :disabled="disableGrainSelection"
                      :items="productData.grains"
                      :item-text="
                        i => {
                          return Object.values(i)
                        }
                      "
                      :item-value="
                        i => {
                          return i
                        }
                      "
                      @change="resetItemOpposingGrain"
                      class="mt-0"
                    ></v-select>
                    <v-card variant="outlined" class="mx-3">
                      <v-card-subtitle class="font-weight-bold mx-2"
                        >Default Grain = {{ Object.values(this.defaultGrain)[0] }}</v-card-subtitle
                      >
                      <v-spacer></v-spacer>
                      <div
                        v-if="itemListOpposingGrain.length > 0"
                        class="mx-2 mb-2 d-flex flex-column justify-center align-center"
                      >
                        <p class="text-decoration-underline my-0">Item with opposing grain:</p>
                        <v-chip-group v-for="(item, key) of itemListOpposingGrain" :key="key">
                          <v-chip style="cursor:default, pointerEvents:none">{{
                            item.name
                              ? 'Urtil: ' + item.name + ' - [H' + item.h + ' x W' + item.w + ']'
                              : 'BK: ' + item.itemNum + ' - [H' + item.h + ' x W' + item.w + ']'
                          }}</v-chip>
                        </v-chip-group>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row id="early-items">
                  <v-col cols="6" v-if="version.spacerPanels.length > 0">
                    <v-switch v-model="version.earlySpacers" label="Spacers Early" class="mt-0"></v-switch>
                  </v-col>
                  <v-col cols="6" v-if="version.worktops.length > 0">
                    <v-switch v-model="version.earlyWorktops" label="Worktops Early"></v-switch>
                  </v-col>
                </v-row>
                <v-switch
                  v-if="quote.region === 'uk'"
                  v-model="version.excludeVat"
                  label="Exclude VAT"
                  :disabled="!$store.state.user.permissions.admin"
                ></v-switch>
                <v-select
                  v-model="quote.region"
                  label="Region"
                  :items="Object.values(productData.regions)"
                  @change="
                    () => {
                      this.materials = Materials.getMaterialsByRegion(this.quote.region)
                      this.version.deliveryLocation = null
                      this.updateDeliveryLocations()
                    }
                  "
                ></v-select>
                <v-autocomplete
                  v-model="version.deliveryLocation"
                  label="Delivery Location"
                  placeholder="Delivery Location"
                  :items="deliveryLocations"
                  :item-text="
                    i => {
                      return i.data().name
                    }
                  "
                  :item-value="
                    i => {
                      return i.id
                    }
                  "
                ></v-autocomplete>
                <v-select v-model="version.frontTypes" :items="frontTypes" label="Front Types" multiple> </v-select>
                <v-text-field v-model.number="version.discount" label="Fronts Discount" suffix="%"></v-text-field>
                <v-text-field
                  v-model.number="version.discountWorktops"
                  label="Worktop Discount"
                  suffix="%"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-textarea
                  auto-grow
                  class="mt-2"
                  v-model="version.notes"
                  placeholder="Place any notes here..."
                  outlined
                  label="Notes"
                ></v-textarea>
                <v-textarea
                  v-if="version.changeStatement"
                  readonly
                  auto-grow
                  class="mt-2"
                  v-model="version.changeStatement"
                  outlined
                  label="Changes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Additional Items -->
      <v-col cols="12" md="4">
        <v-card class="mb-4">
          <v-card-title class="secondary subtitle-1">
            <div>Additional Items</div>
            <v-spacer></v-spacer>
            <v-icon @click="addAdditionalItem(false)">mdi-plus</v-icon>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row v-for="(item, key) in additionalItemsPacked" :key="key" align-center class>
              <v-col cols="5">
                <v-text-field label="Item" v-model="item.description" hide-details></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="Price Ex-Vat" v-model.number="item.value" hide-details></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Discount"
                  v-model.number="item.discount"
                  type="number"
                  hide-details
                  suffix="%"
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex justify-end">
                <v-icon @click="removeAdditionalItems(item)" style="flex: 0 1 auto !important">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="secondary subtitle-1">
            <div>Additional Costs</div>
            <v-spacer></v-spacer>
            <v-icon @click="addAdditionalItem(true)">mdi-plus</v-icon>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row v-for="(item, key) in additionalItemsExcludeFromPacked" :key="key" align-center class>
              <v-col cols="5">
                <v-text-field label="Item" v-model="item.description" hide-details></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="Price Ex-Vat" v-model.number="item.value" hide-details></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Discount"
                  v-model.number="item.discount"
                  type="number"
                  hide-details
                  suffix="%"
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex justify-end">
                <v-icon @click="removeAdditionalItems(item)" style="flex: 0 1 auto !important">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4" id="the-numbers" v-if="this.fronts">
          <v-card-title class="secondary subtitle-1">
            <div>The Numbers</div>
          </v-card-title>
          <v-card-text class="pt-6">
            <section class="mb-3" v-if="version.fronts.length > 0 || version.coverPanels.length > 0 || true">
              <div class="d-flex mb-3">
                <div class="">Fronts, cover panels & spacers</div>
              </div>

              <v-divider class="mb-4"></v-divider>
              <v-container class="pa-0">
                <v-row
                  v-for="material in version.materials"
                  class="d-flex"
                  v-if="fronts[material.id] && (fronts[material.id].itemCount > 0 || fronts[material.id].total > 0)"
                  :key="material.id"
                >
                  <v-col cols="8" class="d-flex align-center">
                    <div class="colour mr-2" :style="{ 'background-color': material.displayColour }"></div>
                    <div class="text-truncate">
                      {{ material.thickness + 'mm - ' + material.label }}
                    </div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center justify-end" v-if="quote.region == 'uk'">
                    {{ $numeral(fronts[material.id].total * (1 - version.discount / 100)).format('$0,0.00') }}
                    {{ calcCog(material) ? '/ ' + calcCog(material) + '%' : '' }}
                  </v-col>
                  <v-col cols="4" class="d-flex align-center justify-end" v-else>
                    {{ $numeral(fronts[material.id].total * (1 - version.discount / 100)).format('$0,0.00') }}
                  </v-col>
                </v-row>
              </v-container>
            </section>

            <div class="d-flex justify-space-between" v-if="spacerPanels.totalLength > 0">
              <div>Spacer Panels</div>
              <div>
                {{ $numeral(spacerPanels.totalPrice * (1 - version.discount / 100)).format('$0,0.00') }}
              </div>
            </div>

            <div class="d-flex justify-space-between mb-1" v-if="version.worktops.length > 0">
              <div>Worktops</div>
              <div>
                {{ $numeral(worktops.total).format('$0,0.00') }}
              </div>
            </div>

            <div class="d-flex justify-space-between mb-1" v-if="version.urtils && version.urtils.length > 0">
              <div>Urtils</div>
              <div>{{ $numeral(urtils.total).format('$0,0.00') }}</div>
            </div>

            <div class="d-flex justify-space-between mb-1" v-if="version.pantries && version.pantries.length > 0">
              <div>{{ version.pantries.length === 1 ? 'Pantry' : 'Pantries' }}</div>
              <div>{{ $numeral(pantries.total).format('$0,0.00') }}</div>
            </div>

            <div
              class="d-flex justify-space-between mb-1"
              v-if="item.value"
              v-for="item in version.additionalItems"
              :key="item.id"
            >
              <div>{{ item.description }}</div>
              <div>
                {{ $numeral(parseFloat(item.value) * (1 - item.discount / 100)).format('$0,0.00') }}
              </div>
            </div>

            <v-divider class="my-3" />

            <div v-if="quote.region == 'uk'">
              <div class="d-flex justify-space-between">
                <div>Delivery</div>
                <div>
                  {{ shipping === 0 ? 'Included' : $numeral(shipping).format('$0,0.00') }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Subtotal</div>
                <div>
                  {{ $numeral(discountedSubtotal + shipping).format('$0,0.00') }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>VAT</div>
                <div>{{ $numeral(vatAmount).format('$0,0.00') }}</div>
              </div>
              <v-divider class="my-2" />
              <div class="d-flex justify-space-between text--primary">
                <div><b>Total</b></div>
                <div>
                  <b>{{ $numeral(discountedSubtotal + vatAmount + shipping).format('$0,0.00') }}</b>
                </div>
              </div>
            </div>
            <div v-else class="totals">
              <div class="d-flex justify-space-between">
                <div>Delivery</div>
                <div>{{ $numeral(shipping).format('$0,0.00') }}</div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Total</div>
                <div>
                  {{ $numeral(discountedSubtotal + shipping).format('$0,0.00') }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <!-- Material Usage -->
        <v-card id="material-usage" v-if="materialUsage" class="mb-4">
          <v-card-title class="secondary subtitle-1">
            <div>Material Usage</div>
          </v-card-title>
          <v-card-text class="">
            <div v-for="material in version.materials" v-if="material.value" class="d-flex mt-4" :key="material.id">
              <div class="colour mr-2" :style="{ 'background-color': material.displayColour }"></div>
              <div>
                {{ material.thickness + 'mm - ' + material.label }}
              </div>
              <v-spacer />
              <div>
                {{ (materialUsage[material.id].areaWithWastage / (material.w * material.h)).toFixed(3) }}
                sheets
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Material Reassign -->
    <v-dialog v-model="materialReassignVisible" persistent width="500">
      <v-card>
        <v-card-title> Reassign Material </v-card-title>
        <v-card-text>
          <v-radio-group v-model="materialReassignParams.replace" @change="checkReassignMaterial()">
            <v-radio
              v-for="(material, key) in version.materials"
              :key="key"
              :label="material.thickness + 'mm ' + material.label"
              :value="material.id"
              v-if="
                material.id != materialReassignParams.removeId && material.thickness == materialReassignParams.thickness
              "
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-alert v-if="reassignMaterialWarning !== ''" type="warning">
          {{ reassignMaterialWarning }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            dark
            @click="
              () => {
                materialReassignVisible = false
                reassignMaterialWarning = ''
              }
            "
            >Cancel</v-btn
          >
          <v-btn
            color="accent"
            depressed
            dark
            @click="
              () => {
                reassignMaterial()
                reassignMaterialWarning = ''
              }
            "
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Handle Reassign -->
    <v-dialog v-model="handleReassignVisible" persistent width="500">
      <v-card>
        <v-card-title> Reassign Handle </v-card-title>
        <v-card-text>
          <v-radio-group v-model="handleReassignParams.replace">
            <v-radio
              v-for="(handle, key) in version.handles"
              :key="key"
              :label="handle.label"
              :value="handle.id"
              v-if="handle.id != handleReassignParams.removeId"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text dark @click="handleReassignVisible = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="reassignHandle">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import uid from 'uid'
import productData from '@/js/productData'
import Materials from '@/js/Materials'
import usPricing from '@/js/pricing/usPricing'
import ukPricing from '@/js/pricing/ukPricing'
import mu from '@/js/mu'

export default {
  name: 'quote',
  props: ['config', 'quote', 'version', 'creator', 'customer'],
  data() {
    return {
      productData: productData,
      debug: false,
      Materials: Materials,
      masterMaterials: Materials.getMaterialsByRegion(this.quote.region),
      handles: this.$store.state.handles,
      vatRate: 1.2,
      materialReassignVisible: false,
      materialReassignParams: {},
      reassignMaterialWarning: '',
      handleReassignVisible: false,
      handleReassignParams: {},
      notifications: [],
      skuWarning: false,
      materialUsage: null,
      fronts: null,
      urtils: null,
      pantries: null,
      shipping: 0,
      mu: null,
      deliveryLocations: [],
      frontTypes: [
        { text: 'Metod', value: 'metod' },
        { text: 'Pax / Platsa', value: 'paxPlatsa' },
        { text: 'No Fronts', value: 'none' }
      ],
      itemListOpposingGrain: [],
      disableGrainSelection: false
    }
  },
  async created() {
    for (let material of this.version.materials) {
      if (material.value) {
        // Reset material if no longer valid
        let valid = this.masterMaterials.find(m => {
          return m.value === material.value
        })

        if (!valid) {
          delete material.value
          delete material.size
          delete material.w
          delete material.h
          delete material.thickness
        }
      }
    }

    // update legacy worktop thicknesses to int from string
    for (const worktop of this.version.worktops) {
      if (typeof worktop.thickness === 'string') {
        worktop.thickness = parseInt(worktop.thickness)
      }
    }

    // Set GBP as default if none present
    if (!this.version.currency) this.$set(this.version, 'currency', 'GBP')
    this.$numeral.locale(this.version.currency)

    // set discountWorktops if doesn't exist
    if (!this.version.discountWorktops) {
      this.version.discountWorktops = 0
    }

    // set default region if doesn't exist
    if (!this.quote.region) {
      this.quote.region = 'uk'
    }

    // Deal with material prop changes
    await this.updateDeliveryLocations()
    this.runCalculations()
    this.checkIncompleteItems()
    this.legacyDefaultGrain()
    this.getItemListOpposingGrain()
    this.legacyUPlight()
    this.checkForPreAssembledUrtil()
  },
  computed: {
    additionalItemsPacked() {
      if (!this.version.additionalItems) return null
      return this.version.additionalItems.filter(o => {
        return !o.excludeFromPacked
      })
    },
    additionalItemsExcludeFromPacked() {
      if (!this.version.additionalItems) return null
      return this.version.additionalItems.filter(o => {
        return o.excludeFromPacked
      })
    },
    worktops() {
      var o = {
        cost: 0,
        total: 0,
        incomplete: false,
        debug: {
          numWorktops: this.version.worktops.length,
          items: []
        }
      }

      if (this.quote.region == 'us') {
        usPricing.worktops(o, this.version, this.notifications)
        return o
      } else {
        return ukPricing.worktops(
          this.version.worktops,
          this.version.exchangeRates ? this.version.exchangeRates.rates[this.version.currency] : 1
        )
      }
    },
    spacerPanels() {
      var o = {
        totalLength: 0,
        totalPrice: 0,
        incomplete: false
      }

      for (var i = 0; i < this.version.spacerPanels.length; i++) {
        if (this.version.spacerPanels[i].l && this.version.spacerPanels[i].qty) {
          o.totalLength += this.version.spacerPanels[i].l * this.version.spacerPanels[i].qty
        } else {
          o.incomplete = true
        }
      }

      let spacerPanelsMultiplier =
        this.quote.region == 'uk'
          ? this.$store.state.pricing.ukMultiplier.externalMarkup.value
          : this.$store.state.pricing.usMultiplier.lucidMarkup.value

      o.totalPrice = this.$utils.toFixedNumber(
        this.convertedValue(
          (o.totalLength / 1000) * productData.spacerCostPerLinearMetre[this.quote.region] * spacerPanelsMultiplier
        ),
        2
      )
      return o
    },
    additionalItems() {
      var o = { full: 0, discounted: 0 }

      if (this.version.additionalItems) {
        for (const item of this.version.additionalItems) {
          if (!item.description || !item.value) continue

          const discount = item.discount ? item.discount : 0

          o.full += this.$utils.toFixedNumber(parseFloat(item.value), 2)
          o.discounted += this.$utils.toFixedNumber(parseFloat(item.value * (1 - discount / 100)), 2)
        }
      }

      // adjust currency if required
      if (this.version.exchangeRates) {
        o.full * this.version.exchangeRates.rates[this.version.currency]
        o.discounted * this.version.exchangeRates.rates[this.version.currency]
      }

      return o
    },
    subtotal() {
      const sub =
        this.fronts.total +
        this.spacerPanels.totalPrice +
        this.worktops.total +
        this.urtils.total +
        this.pantries.total +
        this.additionalItems.full
      return this.$utils.toFixedNumber(sub, 2)
    },
    discountedSubtotal() {
      var dfcs = (this.fronts.total + this.spacerPanels.totalPrice) * (1 - this.version.discount / 100)
      var dw = this.worktops.total * (1 - this.version.discountWorktops / 100)
      const total = dfcs + dw + this.urtils.total + this.pantries.total + this.additionalItems.discounted
      return this.$utils.toFixedNumber(total, 2)
    },
    vatAmount() {
      if (this.quote.region !== 'uk' || this.version.excludeVat) return 0

      const discount = this.version.discount > 0 ? (100 - this.version.discount) / 100 : 1
      const discountWorktops = this.version.discountWorktops > 0 ? (100 - this.version.discountWorktops) / 100 : 1

      // We have to round the VAT per line Item because that's how Xero does it ffs
      let vat = 0

      // Fronts
      this.version.materials.forEach(material => {
        if (this.fronts[material.id] && this.fronts[material.id].total) {
          vat += Math.round(this.fronts[material.id].total * discount * 0.2 * 100) / 100
        }
      })

      // Spacer Panels
      vat += Math.round(this.spacerPanels.totalPrice * discount * 0.2 * 100) / 100

      // Worktops
      vat += Math.round(this.worktops.total * discountWorktops * 0.2 * 100) / 100

      // Ürtils
      if (this?.urtils?.total) {
        vat += Math.round(this.urtils.total * 0.2 * 100) / 100
      }

      // Pantries
      if (this?.pantries?.total) {
        vat += Math.round(this.pantries.total * 0.2 * 100) / 100
      }

      // Additional Items
      if (this.version.additionalItems) {
        for (const item of this.version.additionalItems) {
          if (!item.description || !item.value) continue

          let discount = parseFloat(item.discount) > 0 ? (100 - parseFloat(item.discount)) / 100 : 1
          let vatForItem = parseFloat(item.value) * discount * 0.2
          vat += this.$utils.toFixedNumber(vatForItem, 2)
        }
      }

      // Shipping
      vat += Math.round(this.shipping * 0.2 * 100) / 100

      return vat
    },
    defaultGrain() {
      return this.findGrainDirection()
    }
  },
  watch: {
    version: {
      handler() {
        this.runCalculations()
      },
      deep: true
    },
    '$store.state.pricing': {
      handler() {
        this.runCalculations()
      },
      deep: true
    }
  },
  methods: {
    findGrainDirection() {
      // check if there's any pantries with veneer back = requires vertical grain
      if (this.version.pantries) {
        for (const pantry of this.version.pantries) {
          if (pantry && this.getMaterial(pantry.back)?.hasGrain) {
            this.version.grainDirection = { vert: 'Vertical Grain' }
            this.disableGrainSelection = true
            return { vert: 'Vertical Grain' }
          }
        }
      }

      const veneerFronts = this.version.fronts?.filter(front => this.getMaterial(front.material)?.hasGrain)
      const veneerUrtils = this.version.urtils?.filter(urtil => this.getMaterial(urtil.back)?.hasGrain)

      //check if job requires grain direction (only laminate front/urtil/pantry)
      const veneerBackPanels = this.version.coverPanels?.filter(coverPanel => {
        if (coverPanel.coverPanelType === 'back') {
          if (this.getMaterial(coverPanel.material)?.hasGrain) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      })
      if (!(veneerFronts?.length > 0 || veneerUrtils?.length > 0 || veneerBackPanels?.length > 0)) {
        this.version.grainDirection = { none: 'N/A Grain Direction' }
        this.disableGrainSelection = true
        return { none: 'N/A Grain Direction' }
      }

      // check if there are any fronts with width > 1210 (very unlikely)
      veneerFronts.forEach(front => {
        if (front.w > 1210) {
          this.version.grainDirection = { hoz: 'Horizontal Grain' }
          this.disableGrainSelection = true
          return { hoz: 'Horizontal Grain' }
        }
      })

      // stack same item fronts & collect all heights in array for algorithm check
      let checkHeights = []
      let frontsStackedHeights = []
      if (veneerFronts.length > 0) {
        // create groups of stacks by ITEMS
        let groupByItemNum = {}
        veneerFronts.forEach(front => {
          const itemNum = front['itemNum']
          if (groupByItemNum.hasOwnProperty(itemNum)) {
            groupByItemNum[itemNum].push(front)
          } else {
            groupByItemNum[itemNum] = []
            groupByItemNum[itemNum][0] = front
          }
        })
        // find stack height by QTY
        const stackByQty = front => {
          let stackHeight = 0
          // console.log(front)
          let specialType = front.frontType && front.frontType.includes('-') ? front.frontType.split('-')[2] : null
          let qty = ['2part', 'cnr'].includes(specialType) ? 1 : front.qty
          for (let i = 0; i < qty; i++) {
            stackHeight += front.h
          }
          return stackHeight
        }
        // then stack up QTY of ITEMS
        Object.values(groupByItemNum).forEach(group => {
          let totalItemHeight = 0
          let previousFront = null
          group.forEach(front => {
            if (!previousFront) {
              previousFront = front
              totalItemHeight += stackByQty(front)
            }
            // same itemNum fronts but not lhd+rhd, so stacked
            else if (
              !(
                (previousFront.frontType.split('-')[0] == 'lhd' && front.frontType.split('-')[0] == 'rhd') ||
                (previousFront.frontType.split('-')[0] == 'rhd' && front.frontType.split('-')[0] == 'lhd')
              )
            ) {
              // multiple qty, so stacked
              totalItemHeight += stackByQty(front)
              previousFront = front
            }
          })
          frontsStackedHeights.push(totalItemHeight)
        })
      }
      checkHeights.push(frontsStackedHeights)
      // collect all urtil heights in array
      let urtilsHeights = []
      if (veneerUrtils.length > 0) {
        veneerUrtils.forEach(urtil => {
          urtilsHeights.push(urtil.h)
        })
        checkHeights.push(urtilsHeights)
      }
      let tallestHeight
      if (checkHeights.length == 1) {
        tallestHeight = checkHeights.map(subArray => {
          return subArray.reduce((acc, curr) => {
            return curr > acc ? curr : acc
          }, 0)
        })
      } else {
        tallestHeight = checkHeights
          .map(subArray => {
            return subArray.reduce((acc, curr) => {
              return curr > acc ? curr : acc
            }, 0)
          })
          .reduce((acc, curr) => {
            return curr > acc ? curr : acc
          }, 0)
      }
      let defaultGrain
      if (tallestHeight > 1200) {
        this.version.grainDirection = { vert: 'Vertical Grain' }
        this.disableGrainSelection = true
        defaultGrain = { vert: 'Vertical Grain' }
      } else {
        defaultGrain = { hoz: 'Horizontal Grain' }
      }

      return defaultGrain
    },
    runCalculations() {
      let exchangeRate = this.version.exchangeRates ? this.version.exchangeRates.rates[this.version.currency] : 1

      if (this.quote.region == 'us') {
        this.fronts = usPricing.fronts(
          this.version.fronts.concat(this.version.coverPanels),
          this.version.materials,
          this.version.handles
        )

        this.notifications = this.notifications.concat(this.fronts.notifications)
      } else {
        this.fronts = ukPricing.fronts(
          this.version.fronts.concat(this.version.coverPanels),
          this.version.materials,
          this.version.handles,
          exchangeRate
        )

        this.notifications = this.notifications.concat(this.fronts.notifications)
      }

      this.urtils = ukPricing.urtils(this.version.urtils, this.version.materials, exchangeRate)
      this.pantries = ukPricing.pantries(this.version.pantries, this.version.materials, exchangeRate)
      this.mu = mu.get(this.version)
      this.updateMaterialUsage()
      this.updateShipping()

      this.$emit(
        'draftQuoteUpdated',
        {
          dateCreated: this.$firebase.firestore.Timestamp.now(),
          fronts: this.fronts,
          worktops: this.worktops,
          urtils: this.urtils,
          pantries: this.pantries,
          spacerPanels: this.spacerPanels,
          shipping: this.shipping,
          subtotal: this.subtotal,
          discountedSubtotal: this.discountedSubtotal,
          vatAmount: this.vatAmount,
          total: this.$utils.toFixedNumber(this.discountedSubtotal + this.vatAmount + this.shipping, 2)
        },
        this.materialUsage,
        this.mu
      )
    },
    checkIncompleteItems() {
      if (this.fronts.incomplete) {
        this.notifications.push(
          'There are incomplete fronts or cover panels in the quote which are being ignored. Complete or remove these items before publishing.'
        )
      }
      if (this.spacerPanels.incomplete) {
        this.notifications.push(
          'There are incomplete spacer panels in the quote which are being ignored. Complete or remove these items before publishing.'
        )
      }
      if (this.worktops.incomplete) {
        this.notifications.push(
          'There are incomplete worktops in the quote which are being ignored. Complete or remove these items before publishing.'
        )
      }
      if (this.urtils.incomplete) {
        this.notifications.push(
          'There are incomplete Ürtils in the quote which are being ignored. Complete or remove these items before publishing.'
        )
      }
      if (this.pantries.incomplete) {
        this.notifications.push(
          'There are incomplete Pantries in the quote which are being ignored. Complete or remove these items before publishing.'
        )
      }
      if (this.worktops.incomplete) {
        this.notifications.push(
          'There are incomplete Worktops in the quote which are being ignored. Complete or remove these items before publishing.'
        )
      }
    },
    updateShipping() {
      if (this.version.deliveryLocation) {
        // get the shipping zone for delivery location
        const shippingZone = this.deliveryLocations.find(l => {
          return l.id === this.version.deliveryLocation
        })

        // calculate the shipping cost for shipping zone
        let shippingCost
        if (this.quote.region === 'eu') {
          shippingCost = this.convertedValue(
            shippingZone?.data().cost + shippingZone?.data().customsClearance + shippingZone?.data().insurance / 100
          )
        } else {
          shippingCost = this.convertedValue(shippingZone?.data().cost)
        }
        shippingCost = this.$utils.toFixedNumber(shippingCost, 2)

        // For UK hide full cost of shipping. For EU and US incorporate half the shipping cost
        const incorporatedShippingCost =
          this.quote.region === 'uk'
            ? this.$utils.toFixedNumber(shippingCost, 2)
            : this.$utils.toFixedNumber(shippingCost / 2, 2)

        // calculate how much we add add delivery charges
        let deliveryCharge
        if (this.quote.region === 'uk') {
          deliveryCharge = this.fronts.total === 0 ? shippingCost : 0
        } else {
          deliveryCharge = shippingCost / 2
        }

        let shippingCostProportionedTotal = 0
        let lastAdjustedPricing

        // split shipping cost across materials

        for (const material of this.version.materials) {
          const materialPricing = this.fronts[material.id]

          if (materialPricing && materialPricing.total > 0) {
            // keep a reference of last adjusted material incase we need to adjust for rounding
            lastAdjustedPricing = materialPricing

            const incorporatedShippingCostProportioned = this.$utils.toFixedNumber(
              incorporatedShippingCost * (materialPricing.total / this.fronts.total),
              2
            )

            materialPricing.total = this.$utils.toFixedNumber(
              materialPricing.total + incorporatedShippingCostProportioned,
              2
            )

            shippingCostProportionedTotal = this.$utils.toFixedNumber(
              shippingCostProportionedTotal + incorporatedShippingCostProportioned,
              2
            )
          }
        }

        // making any rounding adjustment
        const roundingAdjustment = this.$utils.toFixedNumber(
          incorporatedShippingCost - shippingCostProportionedTotal,
          2
        )

        if (lastAdjustedPricing)
          lastAdjustedPricing.total = this.$utils.toFixedNumber(lastAdjustedPricing.total + roundingAdjustment, 2)

        if (this.fronts.total !== 0) {
          this.fronts.total = this.$utils.toFixedNumber(this.fronts.total + incorporatedShippingCost, 2)
        }

        // Early Items
        if (this.version.spacerPanels && this.version.spacerPanels.length > 0 && this.version.earlySpacers) {
          deliveryCharge = this.$utils.toFixedNumber(deliveryCharge + productData.additionalCharges.earlySpacers, 2)
        }
        if (this.version.earlyWorktops) {
          deliveryCharge = this.$utils.toFixedNumber(deliveryCharge + shippingZone.data().cost, 2)
        }
        this.shipping = this.$utils.toFixedNumber(deliveryCharge, 2)
      }
    },
    updateMaterialUsage() {
      this.materialUsage = {}

      for (var i = 0; i < this.version.materials.length; i++) {
        let material = this.version.materials[i]
        let m = (this.materialUsage[this.version.materials[i].id] = {
          areaWithWastage: 0
        })
        // Fronts
        if (this.fronts[material.id]) {
          m.areaWithWastage += this.fronts[material.id].areaWithWastage
        }
        // Urtils
        if (this.urtils[material.id]) {
          m.areaWithWastage += this.urtils[material.id].areaWithWastage
        }
        // Pantries
        if (this.pantries[material.id]) {
          m.areaWithWastage += this.pantries[material.id].areaWithWastage
        }
      }
    },
    async updateDeliveryLocations() {
      this.deliveryLocations = []
      const shippingZonesSnap = await this.$db
        .collection('shippingZones')
        .where('regions', 'array-contains', this.quote.region)
        .get()
      this.deliveryLocations = shippingZonesSnap.docs
      return true
    },
    convertedValue(value) {
      return this.version.exchangeRates && this.version.currency !== 'GBP'
        ? this.$utils.toFixedNumber(value * this.version.exchangeRates.rates[this.version.currency], 2)
        : value
    },
    addMaterial() {
      this.version.materials.push({ id: uid(10) })
    },
    removeMaterial(i) {
      let m = this.version.materials[i]
      this.materialReassignParams = { removeId: m.id, removeKey: i, thickness: m.thickness }

      /**
       * Remove material usage, check per product type (that uses version.materials)
       */
      // straight up delete if empty material
      if (Object.keys(m).length == 1) this.version.materials.splice(this.materialReassignParams.removeKey, 1)

      // Urtils
      if (!this.urtils[m.id]) {
        // deleting material not in urtils array - just delete
        this.version.materials.splice(this.materialReassignParams.removeKey, 1)
      }
      if (this.urtils[m.id]?.itemCount > 0) {
        // deleting material used in an urtil
        const matchingThicknessMaterials = this.version.materials.filter(
          material => material.thickness === m.thickness && material.id !== m.id
        )

        // if there is matching thickness that can be re-assigned
        if (matchingThicknessMaterials.length > 0) {
          this.materialReassignVisible = true
        } else {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Materials in use cannot be deleted; no reassignable material of the same thickness!'
          })
          return
        }
      }

      // Pantry
      if (!this.pantries[m.id]) {
        // deleting material not in pantries array - just delete
        this.version.materials.splice(this.materialReassignParams.removeKey, 1)
      }
      if (this.pantries[m.id]?.itemCount > 0) {
        // deleting material used in an pantry
        const matchingThicknessMaterials = this.version.materials.filter(
          material => material.thickness === m.thickness && material.id !== m.id
        )

        // if there is matching thickness that can be re-assigned
        if (matchingThicknessMaterials.length > 0) {
          this.materialReassignVisible = true
        } else {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Materials in use cannot be deleted; no reassignable material of the same thickness!'
          })
          return
        }
      }

      // Handles
      const matchingHandlesWithDeleteMaterial = this.version.handles.filter(handle => handle.insert == m.id)
      if (matchingHandlesWithDeleteMaterial.length > 0) {
        // deleting material used in handles
        const matchingThicknessMaterials = this.version.materials.filter(
          material => material.thickness === m.thickness && material.id !== m.id
        )

        // if there is matching thickness that can be re-assigned
        if (matchingThicknessMaterials.length > 0) {
          this.materialReassignVisible = true
        } else {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Materials in use cannot be deleted; no reassignable material of the same thickness!'
          })
          return
        }
      }

      // Fronts
      if (!this.fronts[m.id]) {
        // deleting material not in fronts array - just delete
        this.version.materials.splice(this.materialReassignParams.removeKey, 1)
      }
      if (this.fronts[m.id]?.itemCount > 0) {
        // deleting material used in an fronts
        const matchingThicknessMaterials = this.version.materials.filter(
          material => material.thickness === m.thickness && material.id !== m.id
        )

        // if there is matching thickness that can be re-assigned
        if (matchingThicknessMaterials.length > 0) {
          this.materialReassignVisible = true
        } else {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Materials in use cannot be deleted; no reassignable material of the same thickness!'
          })
          return
        }
      }

      // if remove material is unused
      if (
        this.urtils[m.id]?.itemCount == 0 &&
        this.pantries[m.id]?.itemCount == 0 &&
        this.fronts[m.id]?.itemCount == 0 &&
        matchingHandlesWithDeleteMaterial.length == 0
      ) {
        this.version.materials.splice(this.materialReassignParams.removeKey, 1)
      }
    },
    getMaterial(code) {
      const mat = this.version.materials.find(material => {
        return code === material.id
      })
      return mat
    },
    reassignMaterial() {
      /**
       * Reassign materials by item;
       */
      let invalidReassign = false
      const reassignMaterial = this.version.materials.find(
        material => material.id == this.materialReassignParams.replace
      )
      // Urtil
      let urtilsItems = this.version.urtils
      for (let i = 0; i < urtilsItems.length; i++) {
        // check carcass
        if (urtilsItems[i].carcass == this.materialReassignParams.removeId) {
          urtilsItems[i].carcass = this.materialReassignParams.replace
        }

        // check back
        if (urtilsItems[i].back == this.materialReassignParams.removeId) {
          // check if reassign back is double-sided, invalidate
          if (reassignMaterial?.sides == 2) {
            invalidReassign = true
          } else if (reassignMaterial?.hasGrain || reassignMaterial?.sides == 1) {
            urtilsItems[i].back = this.materialReassignParams.replace
          }
        }

        // check door
        let urtilType = urtilsItems[i].type
        if (urtilType != 'o') {
          // check if door is double sided or veneer
          if (reassignMaterial?.hasGrain || reassignMaterial?.sides == 2) {
            if (urtilType == 's') {
              if (urtilsItems[i].material1 == this.materialReassignParams.removeId) {
                urtilsItems[i].material1 = this.materialReassignParams.replace
              }
            } else if (urtilType == 't') {
              if (urtilsItems[i].material1 == this.materialReassignParams.removeId) {
                urtilsItems[i].material1 = this.materialReassignParams.replace
              }
              if (urtilsItems[i].material2 == this.materialReassignParams.removeId) {
                urtilsItems[i].material2 = this.materialReassignParams.replace
              }
            }
          } else {
            // cant be single sided
            invalidReassign = true
          }
        }
      }

      // Pantry
      let pantriesItems = this.version.pantries
      for (let i = 0; i < pantriesItems.length; i++) {
        // check base
        const pantryType = pantriesItems[i].pantryType
        if (pantryType == 'l' && pantriesItems[i].base == this.materialReassignParams.removeId) {
          if (reassignMaterial.hasGrain || reassignMaterial.id == pantriesItems[i].carcass) {
            // cant have veneer or carcass matching for laminate pantry base
            invalidReassign = true
          } else {
            pantriesItems[i].base = this.materialReassignParams.replace
          }
        }

        // check carcass
        if (pantriesItems[i].carcass == this.materialReassignParams.removeId) {
          if (pantryType == 'l' && pantriesItems[i].base == reassignMaterial.id) {
            // can't replace carcass with matching laminate base
            invalidReassign = true
          } else if (pantryType != 'l' || (pantryType == 'l' && pantriesItems[i].base != reassignMaterial.id)) {
            pantriesItems[i].carcass = this.materialReassignParams.replace
          }
        }

        // check back
        if (pantriesItems[i].back == this.materialReassignParams.removeId) {
          if (reassignMaterial?.sides == 2) {
            invalidReassign = true
          } else if (reassignMaterial?.hasGrain || reassignMaterial?.sides == 1) {
            pantriesItems[i].back = this.materialReassignParams.replace
          }
        }
      }

      // Fronts
      let frontsItems = this.version.fronts.concat(this.version.coverPanels)
      let handleItems = this.version.handles
      let reassignHandles = [] // for storing new reassign handles

      // check handles on fronts // 4mm can only be replaced by 4mm (regardless of handle type)
      // first replace handle material with replace material
      handleItems.forEach(handle => {
        if (handle.insert == this.materialReassignParams.removeId) {
          // create new handle, copy data from old handle, but replace insert
          let newHandle = {
            id: uid(10),
            removeId: handle.id,
            insert: this.materialReassignParams.replace,
            orientation: handle.orientation,
            value: handle.value
          }
          // add handle labal
          this.constructHandleLabel(newHandle)

          // store new handles
          reassignHandles.push(newHandle)
        }
      })

      // replace each handle from stored reassignHandles
      reassignHandles.forEach(newHandle => {
        frontsItems.forEach((front, index) => {
          if (front?.handle && front?.handle == newHandle.removeId) {
            // then replace fronts handles with new handle
            this.version.fronts[index].handle = newHandle.id
          }
        })
      })

      // replace old handle with new
      reassignHandles.forEach(newHandle => {
        handleItems.forEach((handle, index) => {
          if (handle.id == newHandle.removeId) {
            delete newHandle.removeId
            this.version.handles.splice(index, 1, newHandle)
          }
        })
      })

      // check main material
      for (let i = 0; i < frontsItems.length; i++) {
        if (frontsItems[i].material == this.materialReassignParams.removeId) {
          frontsItems[i].material = this.materialReassignParams.replace
        }
      }

      // after all reassign material item check, delete removing material and reset reassign params
      if (!invalidReassign) {
        this.version.materials.splice(this.materialReassignParams.removeKey, 1)
      } else {
        if (reassignMaterial.sides == 2 && reassignMaterial.thickness == 6) {
          // urtil/pantry back needs to be single-sided
          this.$store.commit('openSnackbar', {
            snackbarText: 'Material cannot be reassigned; urtil/pantry back needs to be single sided!'
          })
        } else if (reassignMaterial.sides == 1 && reassignMaterial.thickness == 6) {
          // urtil door needs to be double-sided
          this.$store.commit('openSnackbar', {
            snackbarText: 'Material cannot be reassigned; urtil door needs to be double sided!'
          })
        } else {
          // laminate base matching pantry carcass (or vice versa)
          this.$store.commit('openSnackbar', {
            snackbarText: 'Material cannot be reassigned; pantry laminate base cannot match carcass!'
          })
        }
      }
      this.materialReassignParams = {}
      this.materialReassignVisible = false
    },
    checkReassignMaterial() {
      this.reassignMaterialWarning = ''
      const replaceMaterial = this.version.materials.find(
        material => material.id == this.materialReassignParams.replace
      )
      const removeMaterial = this.version.materials.find(
        material => material.id == this.materialReassignParams.removeId
      )
      if ((removeMaterial?.sides == 1 || removeMaterial?.hasGrain) && replaceMaterial?.sides == 2) {
        // single sided or veneer does not match reassign double-sided material
        this.reassignMaterialWarning =
          'Reassigning material has double-sides and does not match material being deleted!'
      } else if (removeMaterial?.sides == 2 && (replaceMaterial?.hasGrain || replaceMaterial?.sides == 1)) {
        // double-sided remove material does not match single-sided or veneer reassign material
        this.reassignMaterialWarning =
          'Reassigning material has single-side or a veneer and does not match material being deleted!'
      } else {
        this.reassignMaterialWarning = ''
      }
    },
    onMaterialChange(key, value) {
      const newMaterial = this.masterMaterials.find(m => m.value === value)

      this.version.materials[key].hasGrain = newMaterial.hasGrain
      this.version.materials[key].requiresColourCode = newMaterial.colours !== undefined

      // set default ui colours
      this.version.materials[key].displayColour = newMaterial.texture.hex

      if (newMaterial.hasGrain) {
        this.version.materials[key].continuousGrain = true
        this.version.materials[key].grainDirection = 'h'
        delete this.version.materials[key].colourCode
        delete this.version.materials[key].sides
      } else {
        delete this.version.materials[key].continuousGrain
        delete this.version.materials[key].grainDirection
      }

      this.updateMaterialLabel(key)
      this.updateHandleLabels()
    },
    onMaterialSizeChange(key, size) {
      const dimensions = size.split('x')
      this.version.materials[key].w = parseInt(dimensions[0])
      this.version.materials[key].h = parseInt(dimensions[1])
      // this.updateMaterialLabel(key)
    },
    onColourChange(key) {
      const material = this.masterMaterials.find(m => {
        return m.value === this.version.materials[key].value
      })
      const colour = material.colours.find(m => m.value === this.version.materials[key].colourCode)
      this.version.materials[key].displayColour = colour.hex

      this.updateMaterialLabel(key)
    },
    updateMaterialLabel(key) {
      const material = this.version.materials[key]
      const masterMaterial = this.masterMaterials.find(m => material.value === m.value)
      const colour = material.colourCode
        ? masterMaterial.colours.find(colour => colour.value === material.colourCode)
        : null

      let label = masterMaterial.text
      if (colour) label += ' ' + colour.text

      // Removing as should grab size from data if needed
      // if (material.size) label += ' ' + material.size

      this.version.materials[key].label = label
    },
    addAdditionalItem(excludeFromPacked) {
      if (this.version.additionalItems == undefined) {
        this.$set(this.version, 'additionalItems', [])
      }
      this.version.additionalItems.push({
        description: null,
        value: null,
        discount: null,
        excludeFromPacked: excludeFromPacked
      })
    },
    removeAdditionalItems(item) {
      const itemIndex = this.version.additionalItems.findIndex(o => {
        return o === item
      })
      this.version.additionalItems.splice(itemIndex, 1)
    },
    addHandle() {
      this.version.handles.push({ id: uid(10) })
    },
    updateHandleLabels() {
      for (const handle of this.version.handles) {
        this.constructHandleLabel(handle)
      }
    },
    constructHandleLabel(handle) {
      const h = this.$store.state.handles.find(h => {
        return h.value === handle.value
      })

      if (h) {
        var n

        if (!h.orientations) {
          delete handle.orientation
          n = h.text
        } else {
          n = productData.handleOrientations[handle.orientation] + ' - ' + h.text
          if (!handle.orientation) handle.orientation = null
        }

        if (h && h.hasInsert) {
          if (!handle.insert) {
            handle.insert = null
          } else {
            for (var i = 0; i < this.version.materials.length; i++) {
              if (handle.insert == this.version.materials[i].id) {
                n += ' / ' + this.version.materials[i].label
                break
              }
            }
          }
        } else {
          delete handle.insert
        }
        handle.label = n
      }
    },
    requiresInsert(handle) {
      const h = this.$store.state.handles.find(h => {
        return h.value === handle.value
      })
      return h && h.hasInsert ? h.hasInsert : false
    },
    getOrientations(handle) {
      const h = this.$store.state.handles.find(h => {
        return h.value === handle.value
      })

      if (h && h.orientations) {
        let a = []
        for (let o of h.orientations) {
          a.push({
            value: o,
            text: this.productData.handleOrientations[o]
          })
        }
        return a
      } else {
        return null
      }
    },
    removeHandle(id) {
      var h = this.version.handles[id]
      this.handleReassignParams = { removeId: h.id, removeKey: id }

      // check if handle being used
      var handleInUse = false
      for (var i = 0; i < this.version.fronts.length; i++) {
        if (this.version.fronts[i].handle == h.id) {
          handleInUse = true
          break
        }
      }

      if (handleInUse && this.version.handles.length === 1) {
        this.$store.commit('openSnackbar', {
          snackbarText: 'Handle cannot be deleted as in use'
        })
        return
      }

      if (handleInUse && this.version.handles.length > 2) {
        this.handleReassignVisible = true
      } else if (handleInUse) {
        // if only two handles reassign to the one not being removed
        this.handleReassignParams.replace = id == 0 ? this.version.handles[1].id : this.version.handles[0].id
        this.reassignHandle()
      } else {
        this.version.handles.splice(this.handleReassignParams.removeKey, 1)
      }
    },
    duplicateHandle(id) {
      let clone = { ...this.version.handles[id] }
      clone.id = uid(10)
      this.version.handles.push(clone)
    },
    reassignHandle() {
      for (var i = 0; i < this.version.fronts.length; i++) {
        if (this.version.fronts[i].handle == this.handleReassignParams.removeId) {
          this.version.fronts[i].handle = this.handleReassignParams.replace
        }
      }

      this.version.handles.splice(this.handleReassignParams.removeKey, 1)
      this.handleReassignParams = {}
      this.handleReassignVisible = false
    },
    //
    getMaterialThicknesses(materialValue) {
      const material = this.masterMaterials.find(m => {
        return m.value === materialValue
      })

      if (!material) return null

      let thicknesses = []
      for (let size of material.sizes) {
        if (!thicknesses.some(o => o.value === size.thickness)) {
          thicknesses.push({
            text: size.thickness + 'mm',
            value: size.thickness
          })
        }
      }
      return thicknesses
    },
    getSheetSizes(materialValue, thickness) {
      const material = this.masterMaterials.find(m => {
        return m.value === materialValue
      })

      if (!material) return null

      let sizes = []
      // TODO add label to DB and include in text eg half sheet 4mm handle inserts
      for (let size of material.sizes) {
        if (size.thickness == thickness) {
          sizes.push({
            text: size.w + ' X ' + size.h,
            value: size.w + 'x' + size.h
          })
        }
      }
      return sizes
    },
    getSheetSides(materialValue, thickness, size) {
      if (!materialValue || !thickness || !size) return []

      const dimensions = size.split('x')
      const material = this.masterMaterials.find(m => {
        return m.value === materialValue
      })

      let sides = []
      // TODO add label to DB and include in text eg half sheet 4mm handle inserts
      for (let side of material.sizes) {
        if (side.thickness == thickness && side.w === parseInt(dimensions[0]) && side.h === parseInt(dimensions[1])) {
          sides.push({
            text: side?.sides === 1 ? 'Single' : 'Double',
            value: side.sides
          })
        }
      }
      return sides
    },
    getColourCodes(materialValue) {
      const material = this.masterMaterials.find(m => {
        return m.value === materialValue
      })

      if (!material) return null

      return material.colours
    },
    calcCog(material) {
      let m = this.fronts[material.id]
      let handlesCost = m.handlesCost || 0
      let cog = ((handlesCost + m?.pricing?.external + m?.pricing?.internal) / m.total) * 100

      return this.$utils.toFixedNumber(cog, 0)
    },
    legacyDefaultGrain() {
      if (this.version.grainDirection) return
      // console.log('legacyDefaultGrain set to:', this.findGrainDirection())
      this.version.grainDirection = this.findGrainDirection()
    },
    getItemListOpposingGrain() {
      let itemListOpposingGrain = []
      for (let cp of this.version.coverPanels) {
        if (cp.opposingGrain) {
          itemListOpposingGrain.push(cp)
        }
      }
      for (let urtil of this.version.urtils) {
        if (urtil.opposingGrain) {
          itemListOpposingGrain.push(urtil)
        }
      }
      this.itemListOpposingGrain = itemListOpposingGrain
    },
    resetItemOpposingGrain() {
      for (let cp of this.version.coverPanels) {
        if (cp.opposingGrain) {
          cp.opposingGrain = false
        }
      }
      for (let urtil of this.version.urtils) {
        if (urtil.opposingGrain) {
          urtil.opposingGrain = false
        }
      }
      this.itemListOpposingGrain = []
    },
    legacyUPlight() {
      // make all legacy UP Lights to be centrally positioned
      for (let cp of this.version.coverPanels) {
        if (cp.lighting && cp.lightingRef == '') {
          cp.lightingRef = 'c'

          this.$store.commit('openSnackbar', {
            snackbarText: 'Legacy Underside Panel Lighting; position kept central.'
          })
        }
      }
    },
    checkForPreAssembledUrtil() {
      let foundPreAssembledUrtil = this.version.urtils.filter(urtil => urtil.preAssembled)
      // Make Prod Notes
      if (!this.version.notes) this.version.notes = ''

      if (foundPreAssembledUrtil.length > 0 && !this.version.notes.includes('//Pre-assembled Urtil included.')) {
        this.version.notes += '//Pre-assembled Urtil included.'
      } else if (foundPreAssembledUrtil.length == 0 && this.version.notes.includes('//Pre-assembled Urtil included.')) {
        let splitNotes = this.version.notes.split('//Pre-assembled Urtil included.')
        let cleanNotesArray = splitNotes.filter(sentence => sentence != '//Pre-assembled Urtil included.')
        cleanNotesArray.length > 1
          ? (this.version.notes = cleanNotesArray.join(''))
          : cleanNotesArray.length == 1
          ? (this.version.notes = cleanNotesArray[0])
          : (this.version.notes = '')
      }

      // PreAssembly Cost
      let foundPreAssemblyCost
      if (this.version.additionalItems) {
        foundPreAssemblyCost = this.version.additionalItems.find(item => item.description == 'Urtil Pre-Assembly Cost')
      } else {
        this.version.additionalItems = []
      }

      if (foundPreAssembledUrtil.length > 0 && !foundPreAssemblyCost) {
        this.version.additionalItems.push({
          description: 'Urtil Pre-Assembly Cost',
          discount: 0,
          excludeFromPacked: true,
          value: this.$store.state.pricing.costs.preAssemblyCostPerUrtil.value * foundPreAssembledUrtil.length
        })
      } else if (foundPreAssembledUrtil.length > 0 && foundPreAssemblyCost) {
        if (
          foundPreAssemblyCost.value !==
          this.$store.state.pricing.costs.preAssemblyCostPerUrtil.value * foundPreAssembledUrtil.length
        ) {
          foundPreAssemblyCost.value =
            this.$store.state.pricing.costs.preAssemblyCostPerUrtil.value * foundPreAssembledUrtil.length
        }
      } else if (foundPreAssembledUrtil.length == 0 && foundPreAssemblyCost) {
        this.version.additionalItems = this.version.additionalItems.filter(
          item => item.description != 'Urtil Pre-Assembly Cost'
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#early-items {
  .v-input--selection-controls {
    margin-top: 0;
  }
}
.colour {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border: solid 1px #999;
  border-radius: 3px;
  margin-right: 12px;
}
.v-card__title {
  padding: 8px 16px;
}
.colour-box {
  min-width: 25px;
  height: 25px;
  border: solid 1px #999;
  border-radius: 3px;
  margin-right: 10px;
}
.compact {
  .v-input {
    margin-top: 0px;
    padding-top: 0px;
    &.label-active {
      margin-top: 12px;
    }
  }
}
</style>
