var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-overlay',{staticClass:"text-center",attrs:{"color":"primary","opacity":0.9}},[_c('v-progress-circular',{attrs:{"indeterminate":""}}),_c('p',{staticClass:"mt-5"},[_vm._v("Just crunching some numbers...")])],1):_vm._e(),_c('div',[_c('div',{attrs:{"id":"view-sub-nav"}},[_c('DateRangePicker',{staticClass:"align-self-start",attrs:{"empty":true},on:{"change":_vm.dateRangeChanged}})],1),_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Scheduled Value ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.scheduledValue ? _vm.$numeral(_vm.scheduledValue).format('$0,0.00') : '-')+" ")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Unscheduled Value ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.unscheduledValue ? _vm.$numeral(_vm.unscheduledValue).format('$0,0.00') : '-')+" ")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Scheduled FOC ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.scheduledFocValue ? _vm.$numeral(_vm.scheduledFocValue).format('$0,0.00') : '-')+" ")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Unscheduled FOC ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.uncheduledFocValue ? _vm.$numeral(_vm.uncheduledFocValue).format('$0,0.00') : '-')+" ")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.orders,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().orderNumber || '-')+" ")]}},{key:"item.invoiceNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().foc ? 'FOC' : item.data().xeroInvoiceNumber || '-')+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$numeral(item.data().total).format('$0,0.00'))+" ")]}},{key:"item.customerEmail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().customerContactDetails.email)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }