const makerjs = require('makerjs')

import Lengths from '@/js/cad/production/products/urtil/carcass/Lengths'
import Sides from '@/js/cad/production/products/urtil/carcass/Sides'
import Fins from '@/js/cad/production/products/urtil/fin/Fins'
import AdjustableShelves from '@/js/cad/production/products/urtil/shelves/AdjustableShelves'
import FixedShelves from '@/js/cad/production/products/urtil/shelves/FixedShelves'
import Door from '@/js/cad/production/products/urtil/door/Door'
import Back from '@/js/cad/production/products/urtil/back/Back'

export default class Urtil {
  constructor(urtil, materialThickness, materialID, carcassThickness, backThickness, label, font) {
    this.urtil = urtil
    this.materialThickness = materialThickness
    this.carcassThickness = carcassThickness
    this.backThickness = backThickness
    this.materialID = materialID

    // Engraving Info
    this.engravingInfo = {
      label,
      font,
      fontSize: 10,
      orientationLabel: 'top'
    }

    // Back Rebate Info
    this.backInfo = {
      rebateWidth: 29, // backRebate needs to accomodate mittled electric boxlightingDepth
      rebateToolDiameter: 8,
      drillGuideInset: 5.5,
      drillGuideCornerOffset: 70,
      drillGuideDiameter: 3,
      lightCableHoleDiameter: 5
    }

    // Side Rebate Info
    this.sideRebateInfo = {
      depth: 5,
      tolerance: 0.4 //added tolerance on top of material thickness to rebates on side pieces
    }
    // CALC THIS! in sub-class
    this.sideRebateWidth = materialThickness + this.sideRebateInfo.tolerance

    // Door Groove Info
    this.grooveInfo = {
      width: 10,
      inset: 10,
      spacing: 5
    }

    // Lighting Info
    this.lightingInfo = {
      padding: 1,
      height: 21,
      rebateCornerPoke: 1.5,
      cableCoverWidth: 10,
      cableWidth: 5
    }

    // Fin Info
    this.finInfo = {
      tSlotDepth: 13,
      heightLoss: 0.5, // amount fin slopes down from back to front per edge
      frontSetBack: 10
    }

    // TSlot Info
    this.tSlotInfo = {
      toolHeight: 8,
      rebateHeight: 28,
      tolerance: 0.7 // added tolerance to T-slot width
    }

    // Adj Shelf Info
    this.adjShelfInfo = {
      pinRadius: 2.5,
      pinInset: 25,
      spacing: 50,
      shelfInset: 0.5 // how much smaller should the shelf be than the available space?
    }

    // Trim Info
    this.trimInfo = {
      tolerance: 5, // added tolerance to either side of a trim-to-fit urtil
      pilotRad: 1.5,
      label: 'trim'
    }

    // Door Info
    this.doorInfo = {
      upperDepth: 14,
      lowerDepth: 6,
      clearance: 8,
      overlap: 5
    }
    this.doorInfo.doorHeight =
      urtil.h - carcassThickness * 2 + this.doorInfo.upperDepth + this.doorInfo.lowerDepth - this.doorInfo.clearance

    // Calcs
    // Number of fins/slots = compartments - 1
    this.numOfDividers = urtil.compartments - 1

    // Extends width with TRIMMING TOLERANCE
    if (this.urtil.trimming) {
      this.sideWidth =
        this.urtil.w - (this.materialThickness - this.sideRebateInfo.depth) * 2 + this.trimInfo.tolerance * 2
    } else {
      this.sideWidth = this.urtil.w - (this.materialThickness - this.sideRebateInfo.depth) * 2
    }

    switch (this.urtil.type) {
      case 'o':
        this.rebateDistFromFront = 25
        this.finDepth = this.urtil.d - this.backInfo.rebateWidth - this.finInfo.frontSetBack
        break
      case 's':
        this.rebateDistFromFront = 25 + 20
        this.finDepth =
          this.urtil.d -
          this.backInfo.rebateWidth -
          this.grooveInfo.inset -
          this.grooveInfo.width -
          this.finInfo.frontSetBack
        break
      case 't':
        this.rebateDistFromFront = 25 + 20 + 15
        this.finDepth =
          this.urtil.d -
          this.backInfo.rebateWidth -
          this.grooveInfo.inset -
          this.grooveInfo.width * 2 -
          this.grooveInfo.spacing -
          this.finInfo.frontSetBack
        break
    }

    // drill spacing needs to vary according to width
    let { drillGuideSpacing } = this.getWidthVar(urtil.w)
    this.backInfo.drillGuideSpacing = drillGuideSpacing

    // create empty model json
    this.models = {
      lengths: {},
      sides: {},
      leftDoor: {},
      rightDoor: {},
      back: {},
      fin: {},
      adjShelves: {},
      fixedShelves: {}
    }

    // initiate create methods on conditions
    if (this.urtil.carcass.id == materialID) {
      this.createCarcass()
      this.createShelving()
    }
    if (this.urtil.type !== 'o' && this.urtil.door1 && this.urtil.door1.id == materialID) this.createDoorLeft()
    if (this.urtil.type === 't' && this.urtil.door2 && this.urtil.door2.id == materialID) this.createDoorRight()
    if (this.urtil.back && this.urtil.back.id == materialID) this.createBack()
  }

  getWidthVar(urtWidth) {
    let drillGuideSpacing
    if (urtWidth < 300) {
      drillGuideSpacing = 50
    } else {
      drillGuideSpacing = 150
    }
    return { drillGuideSpacing }
  }

  createCarcass() {
    // Lengths (top & bottom pieces)
    this.models.lengths = new Lengths(
      this.urtil,
      this.materialThickness,
      this.sideWidth,
      this.lightingInfo,
      this.backInfo,
      this.rebateDistFromFront,
      this.tSlotInfo,
      this.grooveInfo,
      this.trimInfo,
      this.engravingInfo,
      this.sideRebateInfo
    )

    // Sides
    this.models.sides = new Sides(
      this.urtil,
      this.materialThickness,
      this.finDepth,
      this.backInfo,
      this.sideRebateInfo,
      this.trimInfo,
      this.grooveInfo,
      this.engravingInfo,
      this.adjShelfInfo,
      this.tSlotInfo,
      this.rebateDistFromFront
    )

    // Change for compartments!
    if (this.urtil.compartments > 1) {
      // Fin
      this.models.fin = new Fins(
        this.urtil,
        this.materialThickness,
        this.finDepth,
        this.finInfo,
        this.backInfo,
        this.tSlotInfo,
        this.sideRebateInfo,
        this.engravingInfo,
        this.adjShelfInfo
      )
    }
  }

  createShelving() {
    if (this.urtil.shelving) {
      switch (this.urtil.shelving) {
        case 'adjustable':
        case 'blank':
          this.models.adjShelves = new AdjustableShelves(
            this.urtil,
            this.materialThickness,
            this.finDepth,
            this.adjShelfInfo,
            this.trimInfo,
            this.engravingInfo
          )
          break
        case 'fixed':
          this.models.fixedShelves = new FixedShelves(
            this.urtil,
            this.materialThickness,
            this.finDepth,
            this.finInfo,
            this.tSlotInfo,
            this.sideRebateInfo,
            this.engravingInfo
          )
          break
      }
    }
  }

  createDoorLeft() {
    this.models.leftDoor = new Door(
      this.urtil,
      this.carcassThickness,
      this.doorInfo,
      this.sideRebateInfo,
      this.engravingInfo,
      'left'
    )
    makerjs.model.move(this.models.leftDoor, [
      -13 - (this.urtil.w / this.urtil?.compartments) * this.urtil?.doorCoverageOne,
      0
    ])
  }

  createDoorRight() {
    this.models.rightDoor = new Door(
      this.urtil,
      this.carcassThickness,
      this.doorInfo,
      this.sideRebateInfo,
      this.engravingInfo,
      'right'
    )
    makerjs.model.move(this.models.rightDoor, [
      -13 * 2 - (this.urtil.w / this.urtil?.compartments) * (this.urtil?.doorCoverageTwo + this.urtil?.doorCoverageOne),
      0
    ])
  }

  createBack() {
    this.models.back = new Back(
      this.urtil,
      this.carcassThickness,
      this.trimInfo,
      this.lightingInfo,
      this.backInfo,
      this.sideRebateInfo,
      this.tSlotInfo,
      this.engravingInfo
    )
  }
}
