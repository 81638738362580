<template>
  <div id="worktops-settings">
    <div id="view-sub-nav">
      <v-btn
        depressed
        outlined
        color="primary"
        @click="
          () => {
            worktopDialog = true
          }
        "
        >Create New Worktop</v-btn
      >
      <v-spacer />
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      v-if="worktops"
      :items="worktops"
      :loading="loading"
      :headers="headers"
      sort-by="text"
      :items-per-page="25"
      :search="tableSearch"
      id="worktops-table"
    >
      <template v-slot:item.actions="{ item }">
        <td class="d-flex justify-end align-center">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="
                  () => {
                    selectedWorktop = item
                    worktopForm = item
                    worktopDialog = true
                  }
                "
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="
                  () => {
                    selectedWorktop = item
                    sizesDialog = true
                  }
                "
                v-on="on"
                >mdi-image-size-select-small</v-icon
              >
            </template>
            <span>Edit Sizes</span>
          </v-tooltip>
          <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                @click="
                  () => {
                    deleteDialog = true
                    selectedWorktop = item
                  }
                "
                v-on="on"
                >mdi-delete</v-icon
              >
            </template>
            <span>Delete Worktop</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>

    <!-- CREATE -->
    <v-dialog v-model="worktopDialog" width="800">
      <v-card>
        <v-card-title class="primary white--text title">
          {{ selectedWorktop ? 'Edit Worktop' : 'Create New Worktop' }}
        </v-card-title>
        <v-card-text>
          <v-form ref="worktopForm">
            <v-text-field
              :disabled="selectedWorktop !== null"
              label="Code"
              v-model="worktopForm.value"
              :rules="[v => !!v || 'Code is required - ask Tim if you do not know what this should be!']"
            ></v-text-field>
            <v-text-field
              label="Name"
              v-model="worktopForm.text"
              :rules="[v => !!v || 'Name is required']"
            ></v-text-field>
            <v-select
              v-model="worktopForm.region"
              :items="Object.values(productData.regions)"
              label="Region"
              :rules="[v => !!v || 'Region required']"
            ></v-select>
            <v-select
              v-if="worktopForm.region"
              v-model="worktopForm.material"
              :items="Materials.getMaterialsByRegion(worktopForm.region)"
              label="Material"
              :rules="[v => !!v || 'Region required']"
            ></v-select>
            <v-text-field v-if="formError" v-model="formError" error outlined readonly> </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                worktopDialog = false
              }
            "
            >Cancel</v-btn
          >
          <v-btn
            color="accent"
            depressed
            dark
            @click="selectedWorktop ? updateWorktop() : createNewWorktop()"
            :disabled="submitting"
            >{{ selectedWorktop ? 'Update' : 'Create' }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sizes -->
    <v-dialog
      v-model="sizesDialog"
      v-if="selectedWorktop"
      fullscreen
      transition="slide-x-reverse-transition"
      origin="align-center"
      :max-width="300"
      attach="#worktops-table"
      @input="onSizesClose"
    >
      <v-card class="mb-3 elevation-0">
        <v-card-title class="subtitle-1">
          <div class="subheading">{{ selectedWorktop.text }} Sheet Sizes</div>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              () => {
                selectedWorktop.sizes.push({
                  thickness: null,
                  size: null,
                  sided: null,
                  cost: null
                })
              }
            "
            >mdi-plus</v-icon
          >
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row v-for="(sheet, key) in selectedWorktop.sizes" :key="key">
            <v-col :cols="3">
              <v-select hide-details v-model.number="sheet.thickness" label="Thickness" :items="thicknesses"></v-select>
            </v-col>
            <v-col :cols="3">
              <v-select hide-details v-model="sheet.size" label="Size" :items="sizes"></v-select>
            </v-col>
            <v-col :cols="3">
              <v-select hide-details v-model.number="sheet.sided" label="Sided" :items="sides"></v-select>
            </v-col>
            <v-col :cols="2" class="d-flex align-baseline">
              <v-text-field
                hide-details
                v-model.number="sheet.cost"
                label="Cost"
                :prefix="selectedWorktop.region === 'us' ? '$' : '£'"
              ></v-text-field>
              <v-icon class="ml-2" @click="deleteSize(selectedWorktop, key)">mdi-delete</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DELETE -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Delete Worktop</v-card-title>
        <v-card-text class="pt-6">
          Are you sure you want to delete this worktop?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="deleteWorktop(selectedWorktop)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import productData from '@/js/productData'
import Materials from '@/js/Materials'

export default {
  name: 'WorktopSettings',
  data() {
    return {
      Materials,
      loading: true,
      tableSearch: null,
      headers: [
        {
          text: 'Name',
          sortable: false,
          value: 'text'
        },
        {
          text: 'Region',
          sortable: false,
          value: 'region'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ],
      selectedWorktop: null,
      worktops: null,
      worktopDialog: false,
      sizesDialog: false,
      deleteDialog: false,
      worktopForm: {
        value: null,
        text: null,
        region: null
      },
      formError: null,
      submitting: false,
      thicknesses: [
        {
          text: '18mm',
          value: 18
        },
        {
          text: '24mm',
          value: 24
        },
        {
          text: '36mm',
          value: 36
        }
      ],
      sizes: [
        {
          text: 'Regular',
          value: 'regular'
        },
        {
          text: 'Jumbo',
          value: 'large'
        }
      ],
      sides: [
        {
          text: 'Single',
          value: 'single'
        },
        {
          text: 'Double',
          value: 'double'
        }
      ],
      productData
    }
  },
  async mounted() {
    await this.$bind('worktops', this.$db.collection('worktops')).then(() => {
      this.loading = false
    })
  },
  methods: {
    async createNewWorktop() {
      if (this.$refs.worktopForm.validate()) {
        this.submitting = true
        this.worktopForm.sizes = []

        const snap = await this.$db
          .collection('worktops')
          .where('value', '==', this.worktopForm.value)
          .get()
        if (snap.empty == false) {
          this.formError = 'Code already in use by another worktop'
          this.submitting = false
          return
        }

        await this.$db.collection('worktops').add(this.worktopForm)
        this.$refs.worktopForm.reset()
        this.submitting = false
        this.worktopDialog = false
      }
    },
    async updateWorktop() {
      this.submitting = true
      await this.$db
        .collection('worktops')
        .doc(this.selectedWorktop.id)
        .update(this.worktopForm)

      this.$refs.worktopForm.reset()
      this.submitting = false
      this.worktopDialog = false
    },
    async onSizesClose() {
      await this.$db
        .collection('worktops')
        .doc(this.selectedWorktop.id)
        .update({
          sizes: this.selectedWorktop.sizes
        })
      this.$store.commit('openSnackbar', { snackbarText: 'Sizes Updated' })
    },
    deleteWorktop(worktop) {
      this.deleteDialog = false
      this.$db
        .collection('worktops')
        .doc(worktop.id)
        .delete()
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Worktop Deleted'
          })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#worktops-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  #worktops-table {
    flex: 1;
    position: relative;
  }
}

::v-deep .v-dialog__content {
  position: absolute;
  .v-dialog {
    padding-left: 15px;
    position: absolute;
    left: auto;
    right: 0;
    width: 50%;
    box-shadow: none;
    &:before {
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      width: 15px;
      box-shadow: -15px 0 15px -15px inset rgb(0 0 0 / 15%);
      left: 0;
      z-index: 1;
    }
  }
}
</style>
