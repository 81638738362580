var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"production-table"}},[_c('v-data-table',{attrs:{"items":_vm.filteredOrders,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.unix(item.dateCreated.seconds).format('Do MMM YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.checkShipments(item)}}},on),[_vm._v("mdi-wrench")])]}}],null,true)},[_c('span',[_vm._v("Check Shipment")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",on:{"click":function($event){return _vm.markShipmentsCreated(item)}}},on),[_vm._v("mdi-check-bold")])]}}],null,true)},[_c('span',[_vm._v("Mark Shipments As Created")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.manuallyCreateShipments(item)}}},on),[_vm._v("mdi-plus-thick")])]}}],null,true)},[_c('span',[_vm._v("Manually Create Shipments")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }