import firebase from 'firebase/app'
import 'firebase/firestore'

const configLive = {
  apiKey: 'AIzaSyAj1tRgSxnaYTcJrE69WxzkoafnbHS3dyI',
  authDomain: 'plykea-mega-admin.firebaseapp.com',
  databaseURL: 'https://plykea-mega-admin.firebaseio.com',
  projectId: 'plykea-mega-admin',
  storageBucket: 'plykea-mega-admin.appspot.com',
  messagingSenderId: '830709651991'
}

const configDev = {
  apiKey: 'AIzaSyA7TU8M6fXLE2vJi-mDiE5Z-Vy7PocYMVU',
  authDomain: 'plykea-mega-admin-dev.firebaseapp.com',
  databaseURL: 'https://plykea-mega-admin-dev.firebaseio.com',
  projectId: 'plykea-mega-admin-dev',
  storageBucket: 'plykea-mega-admin-dev.appspot.com',
  messagingSenderId: '1056524756718'
}

const forceLive = false

if (window.location.hostname == 'mega.plykea.com' || forceLive) {
  firebase.initializeApp(configLive)
} else {
  firebase.initializeApp(configDev)
}
const db = firebase.firestore()

export default db
