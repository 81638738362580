<template><div></div></template>
<script>
export default {
  name: 'auth',
  async mounted() {
    if (this.$firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn')

      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }

      await this.$firebase.auth().signInWithEmailLink(email, window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      window.location.href = '/'
    } else {
      console.log('Not a valid login link')
    }
  }
}
</script>
