import utils from '@/js/utils'

const laminateEdgeMuFactor = 2.46
const veneerEdgeMuFactor = 1
const veneerSingleSurfaceMuFactor = 1.075 //half of double surface = 2.15 / 2
const singleMuPerimeter = 1000
const singleMuArea = 1000000 // single-sided
const assemblyAndPackMuFactorPantry = 35

export default {
  get(pantries, materials, taskType) {
    let mu = 0

    if (!pantries) return mu

    materials.forEach(material => {
      if (!material.value || !material.thickness || !material.w || !material.h) return

      let perimeter = 0
      let surface = 0

      for (const pantry of pantries) {
        // check incomplete
        if (!pantry.pantryType || !pantry.carcass) continue

        if (taskType == 'finish') {
          // Carcass
          if (material.id === pantry.carcass) {
            // exclude base
            perimeter += 1 * pantry.w + 2 * (pantry.h + 2 * pantry.d)
            if (material.hasGrain) {
              // only inside surface
              surface += 2 * pantry.d * pantry.h + pantry.d * pantry.w
            }

            // shelves
            const shelfPerimeter = (pantry.w + pantry.d) * 2
            const numShelves = 3 // 2 adjustable and 1 fixed

            perimeter += shelfPerimeter * numShelves
            if (material.hasGrain) {
              surface += pantry.w * pantry.d * 2 * numShelves
            }
          }

          // Base
          if (material.id === pantry.base) {
            perimeter += pantry.w
            if (material.hasGrain) {
              surface += pantry.d * pantry.w
            }
          }

          // Back
          if (material.id === pantry.back) {
            surface += pantry.w + pantry.h
          }

          // mu total per pantry
          const edgeMu = material.hasGrain
            ? (perimeter / singleMuPerimeter) * veneerEdgeMuFactor
            : (perimeter / singleMuPerimeter) * laminateEdgeMuFactor
          const surfaceMu = material.hasGrain ? (surface / singleMuArea) * veneerSingleSurfaceMuFactor : 0
          mu += edgeMu + surfaceMu
        }
      }
    })

    // calculating assembly mu in terms of per pantrie
    for (const pantry of pantries) {
      if (taskType == 'assemble') {
        let assemblyMu = assemblyAndPackMuFactorPantry
        // if (pantry.preAssembled) assemblyMu *= 0.7 // hasnt got this feature yet
        mu += assemblyMu
      }
    }
    return utils.toFixedNumber(mu, 2)
  }
}
