import paper from 'paper'

export default class SemiRecessedGrab {
  constructor(insertMaterial, handleWidth) {
    this.insertMaterial = insertMaterial
    this.path
    this.width = handleWidth
    this.height = 30
    this.radius = 5
    this.insetX = 35
    this.insetY = 0

    this.draw()
  }

  draw() {
    var rectangle = new paper.Rectangle(new paper.Point(0, 0), new paper.Size(this.width, this.height))
    var cornerSize = new paper.Size(this.radius, this.radius)
    var roundedRectangle = new paper.Path.Rectangle(rectangle, cornerSize)
    var regularRectangle = new paper.Path.Rectangle(
      new paper.Rectangle(new paper.Point(0, 0), new paper.Size(this.width, this.height / 2))
    )

    this.path = roundedRectangle.unite(regularRectangle)
    this.path.strokeColor = 'black'
    this.path.fillColor = this.insertMaterial.uiColour

    roundedRectangle.remove()
    regularRectangle.remove()
  }
}
