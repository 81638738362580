import { Validator } from 'vee-validate'
import axios from 'axios'
import { parsePhoneNumber } from 'awesome-phonenumber'

const postCode = {
  getMessage() {
    return 'Not a valid postcode'
  },
  async validate(value) {
    const cleanPostcode = value.replace(/[^A-Z0-9]/gi, '')
    const postcodeValidation = await axios.get('https://api.postcodes.io/postcodes/' + cleanPostcode + '/validate')
    return postcodeValidation.data.result
  }
}

const mobile = {
  getMessage() {
    return 'Not a valid UK mobile number'
  },
  validate(value) {
    const pn = parsePhoneNumber(value, 'GB')
    return value && pn.isValid() && pn.isMobile()
  }
}

Validator.extend('postcode', postCode)
Validator.extend('mobile', mobile)
