const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class SRCHandle {
  constructor(radius) {
    this.models = {}
    this.handleRad = parseFloat(radius)

    this.screwHoleRadius = 1.5
    this.numOfScrewHoles = 3

    this.screwHolePathRad = parseFloat(radius) - 5.5

    this.generateSRCHandle()
  }

  generateSRCHandle() {
    this.models.outline = {
      layer: layerNames.insertCutout.label,
      paths: {
        outline: new makerjs.paths.Circle([0, 0], this.handleRad)
      }
    }

    // calculate hole spacing
    // (perimeter of guide circle - ((number of holes -1 "-1 because start from centre") * diameter of holes)) / (number of holes + 1)
    let screwHoleXSpacing =
      (2 * Math.PI * this.screwHolePathRad - (this.numOfScrewHoles - 1) * this.screwHoleRadius * 2) /
      (this.numOfScrewHoles + 1)
    // add screw holes
    const screwHole = new makerjs.models.Ellipse(this.screwHoleRadius, this.screwHoleRadius)
    const screwHoleClones = makerjs.layout.cloneToRow(screwHole, this.numOfScrewHoles, screwHoleXSpacing)
    const screwHoleGuideCircle = new makerjs.paths.Arc([0, 0], this.screwHolePathRad, 0, 240)
    this.models.screwHoles = makerjs.layout.childrenOnPath(screwHoleClones, screwHoleGuideCircle)
  }
}
