import utils from '@/js/utils'
const worktopMuMultiplier = 3.71
const singleMuPerimeter = 1000

export default {
  get(worktops) {
    let mu = 0

    for (let w of worktops) {
      if (!w.thickness || !w.material || !w.size || !w.faces) return false

      // calculate the total required finished perimeter length
      let perimeter = 0

      for (let section of w.sections) {
        if (section.finished) {
          if (section.finished.front) perimeter += parseInt(section.l)
          if (section.finished.back) perimeter += parseInt(section.l)
          if (section.finished.left) perimeter += parseInt(section.d)
          if (section.finished.right) perimeter += parseInt(section.d)
        }
      }

      mu += (perimeter / singleMuPerimeter) * worktopMuMultiplier
    }

    return utils.toFixedNumber(mu, 2)
  }
}
