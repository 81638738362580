export default {
  calculateJoint(depth) {
    let pilotCount
    let pilotSpacing
    let pilotInset
    for (let i = 3; i < 11; i++) {
      pilotCount = i
      for (let j = 80; j < 160; j += 10) {
        pilotSpacing = j
        pilotInset = (depth - pilotSpacing * (pilotCount - 1)) / 2
        if (pilotInset / (pilotSpacing / 2) < 2 && pilotInset < 50 && pilotInset > 30) {
          break
        }
      }
      if (pilotInset / (pilotSpacing / 2) < 2 && pilotInset < 50 && pilotInset > 30) {
        break
      }
    }
    let pilotObj = {}
    pilotObj.pilotCount = pilotCount
    pilotObj.pilotSpacing = pilotSpacing
    pilotObj.pilotInset = pilotInset
    return pilotObj
  },

  calculateShelfHeights(urtil, fullTSlotHeight, sideRebateWidth, backHeight) {
    //VARS
    const numShelves = urtil.shelvingOptions.heights.length
    const internalVoidHeight = backHeight ? backHeight - sideRebateWidth * 2 : urtil.h - sideRebateWidth * 2
    const availableSpace = internalVoidHeight - fullTSlotHeight * numShelves

    let remainingHeight = null
    if (urtil.shelvingOptions.heights[0]) {
      remainingHeight =
        internalVoidHeight - (urtil.shelvingOptions.heights[0] + fullTSlotHeight) - fullTSlotHeight * (numShelves - 1)
    }
    let autoShelfStep
    remainingHeight
      ? (autoShelfStep = remainingHeight / numShelves)
      : (autoShelfStep = availableSpace / (numShelves + 1))

    let shelfPositionArr = []
    for (const key in urtil.shelvingOptions.heights) {
      let height = urtil.shelvingOptions.heights[key]
      if (urtil.shelvingOptions.heights[0]) {
        let shelfPos = height
          ? height + sideRebateWidth
          : internalVoidHeight - autoShelfStep * parseInt(key) - fullTSlotHeight * (parseInt(key) - 1)
        shelfPositionArr.push(shelfPos)
      } else {
        let shelfPos = autoShelfStep * (parseInt(key) + 1) + fullTSlotHeight * parseInt(key) + sideRebateWidth
        shelfPositionArr.push(shelfPos)
      }
    }
    return shelfPositionArr
  }
}
