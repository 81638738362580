var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"month"}},_vm._l((_vm.tasksByDay),function(day,date,key){return (_vm.$moment(date).weekday() < 5)?_c('div',{key:key,staticClass:"day"},[(key < 5)?_c('div',{staticClass:"header py-1 px-2 text-body-2 flex-grow-0",class:_vm.$utils.datePPF(date)},[_vm._v(" "+_vm._s(_vm.$moment(date).format('ddd'))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-center py-1 px-2 text-body-2 secondary flex-grow-0"},[_c('div',{class:_vm.$utils.datePPF(date)},[_vm._v(" "+_vm._s(_vm.$moment(date).format('Do'))+" ")]),_c('div',{staticClass:"ml-1",class:{
          'red--text':
            Math.round((_vm.capacityForDay(_vm.capacityObject[date]) / _vm.getDailyRequiredCapacity(date)) * 100) < 100,
          'green--text':
            Math.round((_vm.capacityForDay(_vm.capacityObject[date]) / _vm.getDailyRequiredCapacity(date)) * 100) >= 100
        }},[_vm._v(" "+_vm._s(Math.round((_vm.capacityForDay(_vm.capacityObject[date]) / _vm.getDailyRequiredCapacity(date)) * 100))+"% ")]),_c('v-spacer'),_c('div',[_c('v-chip',{staticClass:"px-2 coloured cut",attrs:{"small":""}},[_vm._v(_vm._s(_vm.getCutCapacity(date))+"%")]),_c('v-chip',{staticClass:"px-2 coloured finish mx-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.getMuFinishCapacity(date))+"%")]),_c('v-chip',{staticClass:"px-2 coloured pack",attrs:{"small":""}},[_vm._v(_vm._s(_vm.getPackCapacity(date))+"%")])],1)],1),_c('draggable',{staticClass:"draggable-list flex-grow-1",attrs:{"group":"tasks","animation":0,"id":date},on:{"end":_vm.onSortEnd},model:{value:(_vm.tasksByDay[date]),callback:function ($$v) {_vm.$set(_vm.tasksByDay, date, $$v)},expression:"tasksByDay[date]"}},_vm._l((_vm.tasksByDay[date]),function(task){return _c('div',{key:task.id,staticClass:"task body-2",class:[
          task.type,
          _vm.$utils.camelize(task.subtype),
          { coloured: !task.complete && !task.paused },
          { paused: task.paused },
          {
            ghost: _vm.focussedTask !== null && task.shipmentID !== _vm.focussedTask.shipmentID
          },
          {
            focussed: _vm.focussedTask !== null && task.shipmentID == _vm.focussedTask.shipmentID
          }
        ],attrs:{"id":task.id}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[(task.type === 'cut' && !task.toolpathed)?_c('v-icon',{style:({
                'padding-bottom': '1px',
                color: task.complete ? 'black' : 'white',
                'margin-right': '2px'
              }),attrs:{"size":14}},[_vm._v("mdi-alert")]):_vm._e(),(
                task.type === 'dispatch' && task.subtype === 'To customer' && task.courierBooked && !task.complete
              )?_c('v-icon',{attrs:{"size":15,"dark":""}},[_vm._v("mdi-truck-check")]):_vm._e(),_c('div',{staticClass:"mr-2",on:{"click":function($event){return _vm.$emit('focusTask', task)}}},[_vm._v(" "+_vm._s(task.orderNumber || task.invoiceNumber)+" ")]),_c('div',{staticClass:"flex-grow-1 d-flex",on:{"click":function($event){return _vm.$emit('openTask', task)}}},[_c('div',[_vm._v(" "+_vm._s(task.objectType)+" "+_vm._s(task.subtype ? task.subtype : task.type)+" ")]),_c('v-spacer'),_c('div',[_vm._v(" "+_vm._s(task.units)+" ")]),(task.type === 'finish' || task.type === 'assemble')?_c('div',[_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_vm._v(_vm._s(_vm.$utils.toFixedNumber(task.muUnits, 1))+" mµ ")]):_vm._e()],1)],1)],1)],1)}),0)],1):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }