var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'
import cadMethods from '@/js/cad/CadMethods.js'

export default class Pantry {
  constructor(pantry, materialThickness, materialID, carcassThickness, baseThickness, label, font) {
    // location engraving to identify pieces
    this.label = label

    this.pantry = pantry
    this.materialThickness = materialThickness
    this.carcassThickness = carcassThickness
    this.baseThickness = baseThickness

    // Add to pantry depth to make OPEN pantry flush to front of carcass
    this.pantryDepth
    this.pantry.hinge === 'o'
      ? (this.pantryDepth = this.pantry.d + 18 - 4) // 4mm tolerance
      : (this.pantryDepth = this.pantry.d)

    // Back Pocket Variables
    this.backRebateDepth = 13
    this.backRebateWidth = 29 // backRebate needs to accommodate mittled electric box
    this.backRebateToolDiameter = 8

    // End (side) Variables
    this.endRebateDepth = 5
    this.endRebateTolerance = 0.4 //added tolerance on top of material thickness to rebates on side pieces
    // pilot holes replaces lamello
    this.jointPilotRad = 1.5

    // Back Variables
    this.drillGuideInset = 5.5
    this.drillGuideCornerOffsetTop = 55
    this.drillGuideCornerOffsetBot = 55
    this.drillGuideCornerOffset = 35
    this.drillGuideSpacing = 120
    this.drillGuideDiameter = 3
    this.lightCableHoleDiameter = 5
    this.fixedShelfHoleSpacing = 250

    // Lighting Variables
    this.lightingPadding = 1
    this.lightingDepth = 21
    this.lightingHeight = 15
    this.lightingRebateCornerPoke = 1.5
    this.lightCableCoverWidth = 10
    this.lightCableWidth = 5
    this.lightCableCoverThickness = 6

    // Hinge position Variables
    this.hingeFromEdgeArray = [20, 51.5]
    this.hingeHoleRad = 2.5

    // Shelf Variables
    this.numOfAdjShelf = 2

    // Bot T-Slot
    this.tSlotRebateHeight = 28
    this.fixedShelfTSlotDepth = 13
    this.fixedShelfHeightLoss = 0.5 // amount fixedShelf slopes down from back to front per edge

    // Adj Holes
    this.firstAdjShelfGap = 150 // from the top of fixed bot shelf to the first adjustable hole (but also on the internal of top piece)
    this.shelfAdjustableSpacing = 50
    this.shelfAdjustablePinRadius = 2.5
    this.shelfAdjustablePinInset = 25
    this.shelfAdjustableShelfInset = 0.5 // how much smaller should the shelf be than the available space?
    this.shelfAdjustablePinAdjustY = 5 // actual height of pins itself

    this.font = font
    this.fontSize = 10

    // data that changes depending on width
    this.sideWidth = this.pantry.w - (this.carcassThickness - this.endRebateDepth) * 2

    let { lightingWidth } = this.getWidthVar(pantry.w)
    this.lightingWidth = lightingWidth

    // data that changes depending on height
    let { tSlotHeight, hingePosHeightArray } = this.getHeightVar(pantry.h)
    this.tSlotHeight = tSlotHeight
    this.hingePosHeightArray = hingePosHeightArray

    // data that changes depending on depth
    let { adjShelfDepth, fixedShelfDepth, tSlotDepth, botLightingInset, topLightingInset } = this.getDepthVar(pantry.d)
    this.adjShelfDepth = adjShelfDepth
    this.fixedShelfDepth = fixedShelfDepth
    this.tSlotDepth = tSlotDepth
    this.botLightingInset = botLightingInset
    this.topLightingInset = topLightingInset

    // create empty model json
    this.models = {
      bottomSide: { models: {} },
      leftEnd: { models: {} },
      back: {},
      base: {},
      fin: { models: {} },
      shelves: { models: {} }
    }

    if (this.pantry.carcass.id == materialID) {
      this.constructCarcass()
      this.constructFixedShelf()
      this.constructTwoAdjustableShelves()
    }
    if (this.pantry.base && this.pantry.base.id == materialID) this.constructBase()
    if (this.pantry.back && this.pantry.back.id == materialID) this.constructBack()
  }

  // variables that depend on pantry dims
  getWidthVar(pantryWidth) {
    let lightingWidth
    switch (pantryWidth) {
      case 600:
        lightingWidth = 400
        break
      case 800:
        lightingWidth = 600
        break
      case 1000:
      case 1200:
        lightingWidth = 800
        break
    }
    return { lightingWidth }
  }

  getHeightVar(pantryHeight) {
    let tSlotHeight
    let hingePosHeightArray
    switch (pantryHeight) {
      case 1200:
        tSlotHeight = 400
        hingePosHeightArray = [50, 1150]
        break
      case 1400:
        tSlotHeight = 400
        hingePosHeightArray = [50, 700, 1350]
        break
      case 1600:
        tSlotHeight = 400
        hingePosHeightArray = [50, 800, 1550]
        break
    }
    return { tSlotHeight, hingePosHeightArray }
  }

  getDepthVar(pantryDepth) {
    let adjShelfDepth
    let fixedShelfDepth
    let tSlotDepth
    let botLightingInset
    let topLightingInset
    switch (pantryDepth) {
      case 367:
        adjShelfDepth = 300
        fixedShelfDepth = 300
        tSlotDepth = fixedShelfDepth + 9 // plus 9 for t-slot
        botLightingInset = fixedShelfDepth - 50
        topLightingInset = pantryDepth - 50
        break
      case 591:
        adjShelfDepth = 300
        fixedShelfDepth = 400
        tSlotDepth = fixedShelfDepth + 9 // plus 9 for t-slot
        botLightingInset = fixedShelfDepth - 50
        topLightingInset = pantryDepth - 50
        break
    }
    return { adjShelfDepth, fixedShelfDepth, tSlotDepth, botLightingInset, topLightingInset }
  }

  constructCarcass() {
    // top and bottom pieces
    let bottom = this.models.bottomSide.models
    bottom.outline = new makerjs.models.Rectangle(this.sideWidth, this.pantryDepth)
    bottom.outline.layer = layerNames.cutout.label

    bottom.backRebate = new makerjs.models.Rectangle(
      this.sideWidth + this.backRebateToolDiameter,
      this.backRebateWidth + 1
    )
    bottom.backRebate.layer = layerNames.urtilBackPocket.label
    makerjs.model.move(bottom.backRebate, [-this.backRebateToolDiameter / 2, this.pantryDepth - this.backRebateWidth])

    // Back pocket cut line (on-path)
    bottom.backRebateLine = {
      layer: layerNames.backPocketLine.label,
      paths: {
        rebateLine: {
          type: 'line',
          // origin x = 11, 5 + 12mm toolbit / 2
          origin: [2, this.pantryDepth - this.backRebateWidth / 2],
          end: [this.sideWidth - 2, this.pantryDepth - this.backRebateWidth / 2]
        }
      }
    }

    // location label engraving
    const topEngrave = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    bottom.topEngrave = makerjs.model.mirror(topEngrave, true, false)
    bottom.topEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(bottom.topEngrave, [100, this.pantryDepth - this.backRebateWidth / 2 - 4])

    // cloning base to make top piece
    this.models.topSide = makerjs.cloneObject(this.models.bottomSide)
    makerjs.model.move(this.models.topSide, [0, this.pantryDepth + 50])

    // delete bottom piece if pantry base is laminate
    if (this.pantry.base) {
      delete this.models.bottomSide
    }

    // side (end) pieces [LEFT]
    const end = this.models.leftEnd.models

    end.outline = new makerjs.models.Rectangle(this.pantry.h, this.pantryDepth)
    end.outline.layer = layerNames.cutout.label

    // back rebate
    end.backRebate = new makerjs.models.Rectangle(this.pantry.h - 10, this.backRebateWidth + 1)
    end.backRebate.layer = layerNames.urtilBackPocket.label
    makerjs.model.move(end.backRebate, [5, this.pantryDepth - this.backRebateWidth])

    // Back pocket cut line (on-path)
    end.backRebateLine = {
      layer: layerNames.backPocketLine.label,
      paths: {
        rebateLine: {
          type: 'line',
          // origin x = 11, 5 + 12mm toolbit / 2
          origin: [11, this.pantryDepth - this.backRebateWidth / 2],
          end: [this.pantry.h - 11, this.pantryDepth - this.backRebateWidth / 2]
        }
      }
    }

    // bottom rebate
    let botRebateWidth
    let botRebateHeight
    if (this.baseThickness) {
      botRebateWidth = this.baseThickness + this.endRebateTolerance + 1
      botRebateHeight = this.pantryDepth + this.backRebateToolDiameter
    } else {
      botRebateWidth = this.materialThickness + this.endRebateTolerance + 1
      botRebateHeight = this.pantryDepth + this.backRebateToolDiameter
    }

    end.bottomRebate = new makerjs.models.Rectangle(botRebateWidth, botRebateHeight)
    end.bottomRebate.layer = layerNames.urtilSideRebate.label
    makerjs.model.move(end.bottomRebate, [-1, -this.backRebateToolDiameter / 2])

    // Bottom rebate cut line (on-path)
    let originPoint
    let endPoint
    if (this.baseThickness) {
      originPoint = [(this.baseThickness + this.endRebateTolerance) / 2, 2]
      endPoint = [(this.baseThickness + this.endRebateTolerance) / 2, this.pantryDepth - 2]
    } else {
      originPoint = [(this.materialThickness + this.endRebateTolerance) / 2, 2]
      endPoint = [(this.materialThickness + this.endRebateTolerance) / 2, this.pantryDepth - 2]
    }

    end.bottomRebateLine = {
      layer: layerNames.sideRebateLine.label,
      paths: {
        rebateLine: {
          type: 'line',
          // origin y = 2, - this.backRebateToolDiameter[=8]/2 + 12mm toolbit / 2
          origin: originPoint,
          end: endPoint
        }
      }
    }

    // top rebate is always material Thickness
    end.topRebate = new makerjs.models.Rectangle(
      this.materialThickness + this.endRebateTolerance + 1,
      this.pantryDepth + this.backRebateToolDiameter
    )
    end.topRebate.layer = layerNames.urtilSideRebate.label
    makerjs.model.move(end.topRebate, [
      this.pantry.h - this.materialThickness - this.endRebateTolerance,
      -this.backRebateToolDiameter / 2
    ])

    // top rebate cut line (on-path)
    end.topRebateLine = makerjs.cloneObject(end.bottomRebateLine)
    let topLineXPos
    if (this.baseThickness) {
      topLineXPos =
        this.pantry.h -
        this.baseThickness -
        this.endRebateTolerance +
        this.baseThickness / 2 -
        this.materialThickness / 2
    } else {
      topLineXPos = this.pantry.h - this.materialThickness - this.endRebateTolerance
    }
    end.topRebateLine.layer = layerNames.sideRebateLine.label
    makerjs.model.move(end.topRebateLine, [topLineXPos, 0])

    // add 5mm door hinge holes
    this.addHingeHoles(end)

    // joint screw pilot holes - to replace lamello
    this.addJointHoles(end)

    //T-slot on side (end) pieces
    this.addFixedShelfSlots(end)

    //adjustable shelf holes on side (end) pieces
    this.addAdjustableShelfHoles(end)

    if (this.pantry.lighting) {
      const top = this.models.topSide.models

      // //add Lighting to top piece (carcass top)
      this.addTopLighting(top)
    }

    // CLONE:: side (end) piece [RIGHT]
    makerjs.model.move(this.models.leftEnd, [this.pantry.w + 50, 0])
    this.models.rightEnd = makerjs.model.mirror(this.models.leftEnd, true, false)
    makerjs.model.move(this.models.rightEnd, [this.pantry.w + this.pantry.h + 50, this.pantryDepth + 50])

    // choose which side of hinge holes to keep - 'b' for both will do nothing to keep both hinges
    // depending on what was selected in this.pantry.pantryHinge
    if (this.pantry.hinge === 'l') {
      delete this.models.rightEnd.models.hinge
    } else if (this.pantry.hinge === 'r') {
      delete this.models.leftEnd.models.hinge
    } else if (this.pantry.hinge === 'o') {
      delete this.models.rightEnd.models.hinge
      delete this.models.leftEnd.models.hinge
    }

    // engraving on LEFT to show orientation
    const orientationLabelL = 'L'
    const orientationTextL = new makerjs.models.Text(this.font, orientationLabelL.toUpperCase(), this.fontSize)
    end.leftOrientationEngraving = makerjs.model.mirror(orientationTextL, true, false)
    end.leftOrientationEngraving.layer = layerNames.specialEngrave.label
    makerjs.model.move(end.leftOrientationEngraving, [50, this.pantryDepth - this.backRebateWidth / 2 - 4])
    makerjs.model.rotate(end.leftOrientationEngraving, -90, [50, this.pantryDepth - this.backRebateWidth / 2 - 2])

    // location engraving on left end
    const leftLocationEngrave = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    end.leftLocationEngrave = makerjs.model.mirror(leftLocationEngrave, true, false)
    end.leftLocationEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(end.leftLocationEngrave, [this.pantry.h - 50, this.pantryDepth - this.backRebateWidth / 2 - 4])

    const rightEnd = this.models.rightEnd.models
    // engraving on RIGHT to show orientation
    const orientationLabelR = 'R'
    const orientationTextR = new makerjs.models.Text(this.font, orientationLabelR.toUpperCase(), this.fontSize)
    rightEnd.rightOrientationEngraving = makerjs.model.mirror(orientationTextR, true, false)
    rightEnd.rightOrientationEngraving.layer = layerNames.specialEngrave.label
    makerjs.model.move(rightEnd.rightOrientationEngraving, [-50, this.pantryDepth - this.backRebateWidth / 2 - 4])
    makerjs.model.rotate(rightEnd.rightOrientationEngraving, 90, [-50, this.pantryDepth - this.backRebateWidth / 2 + 4])

    // location engraving on right end
    const rightLocationEngrave = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    rightEnd.rightLocationEngrave = makerjs.model.mirror(rightLocationEngrave, true, false)
    rightEnd.rightLocationEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(rightEnd.rightLocationEngrave, [
      -this.pantry.h + 100,
      this.pantryDepth - this.backRebateWidth / 2 - 4
    ])

    // add a mega warning
    const text = new makerjs.models.Text(
      this.font,
      'IMPORTANT! Set start point on Pantry T-slot path!!! Cut top piece cable line on flip side. While the fixed shelf cable and lighting on the bottom side.',
      200
    )
    this.models.warningText = makerjs.model.mirror(text, true, false)
    this.models.warningText.layer = layerNames.warning.label

    const textMeasurements = makerjs.measure.modelExtents(this.models.warningText)
    makerjs.model.moveRelative(this.models.warningText, [
      -textMeasurements.center[0],
      -textMeasurements.center[1] + 1000
    ])
  }

  // make shelves
  constructFixedShelf() {
    // bottom fixed shelf
    let shelfWidth = this.pantry.w - this.materialThickness * 2 + this.fixedShelfTSlotDepth * 2

    let fixedBotShelf = {
      layer: layerNames.cutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.fixedShelfHeightLoss, this.fixedShelfDepth]
        },
        line2: {
          type: 'line',
          origin: [this.fixedShelfHeightLoss, this.fixedShelfDepth],
          end: [shelfWidth - this.fixedShelfHeightLoss, this.fixedShelfDepth]
        },
        line3: {
          type: 'line',
          origin: [shelfWidth - this.fixedShelfHeightLoss, this.fixedShelfDepth],
          end: [shelfWidth, 0]
        },
        line4: {
          type: 'line',
          origin: [shelfWidth, 0],
          end: [0, 0]
        }
      }
    }

    let toothNotchA = new makerjs.models.Rectangle(this.fixedShelfTSlotDepth, 6.5)
    makerjs.model.move(toothNotchA, [0, this.fixedShelfDepth - 6.5])
    fixedBotShelf = makerjs.model.combineSubtraction(fixedBotShelf, toothNotchA)

    let toothNotchB = new makerjs.models.Rectangle(this.fixedShelfTSlotDepth, 6.5)
    makerjs.model.move(toothNotchB, [shelfWidth - this.fixedShelfTSlotDepth, this.fixedShelfDepth - 6.5])
    fixedBotShelf = makerjs.model.combineSubtraction(fixedBotShelf, toothNotchB)

    this.models.shelves.models['shelf-f'] = fixedBotShelf

    fixedBotShelf.models.notchA = new makerjs.models.Rectangle(5, this.fixedShelfTSlotDepth + 8)
    makerjs.model.move(fixedBotShelf.models.notchA, [this.fixedShelfTSlotDepth - 5, this.fixedShelfDepth - 18.5])
    fixedBotShelf.models.notchA.layer = layerNames.finNotch.label

    fixedBotShelf.models.notchB = new makerjs.models.Rectangle(5, this.fixedShelfTSlotDepth + 8)
    makerjs.model.move(fixedBotShelf.models.notchB, [
      shelfWidth - this.fixedShelfTSlotDepth,
      this.fixedShelfDepth - 18.5
    ])
    fixedBotShelf.models.notchB.layer = layerNames.finNotch.label

    this.models.shelves.models['shelf-f'] = makerjs.model.mirror(this.models.shelves.models['shelf-f'], false, true)
    makerjs.model.move(this.models.shelves.models['shelf-f'], [-this.pantry.w * 2 - 100, this.fixedShelfDepth])

    if (this.pantry.lighting) {
      //add Lighting to bottom fixed shelf
      this.addBotLighting(this.models.shelves.models, shelfWidth)
    }
  }

  constructTwoAdjustableShelves() {
    // two adjustable shelves
    let shelfWidth = this.pantry.w - this.materialThickness * 2
    shelfWidth -= this.shelfAdjustableShelfInset * 2
    let adjShelf = (this.models.adjShelf = { models: {} })

    let yPos = 0
    for (let i = 0; i < this.numOfAdjShelf; i++) {
      let currAdjShelf = (adjShelf.models['shelf-a-' + i] = {
        models: {
          outline: new makerjs.models.Rectangle(shelfWidth, this.adjShelfDepth)
        }
      })
      currAdjShelf.layer = layerNames.cutout.label

      currAdjShelf.models.holeA = new makerjs.models.Ellipse(
        this.shelfAdjustablePinRadius,
        this.shelfAdjustablePinRadius
      )
      currAdjShelf.models.holeA.layer = layerNames.shelfDrill.label

      currAdjShelf.models.holeB = makerjs.model.clone(currAdjShelf.models.holeA)
      currAdjShelf.models.holeC = makerjs.model.clone(currAdjShelf.models.holeA)
      currAdjShelf.models.holeD = makerjs.model.clone(currAdjShelf.models.holeA)

      makerjs.model.move(currAdjShelf.models.holeA, [
        10 - this.shelfAdjustableShelfInset,
        this.shelfAdjustablePinInset + this.shelfAdjustableShelfInset
      ])
      makerjs.model.move(currAdjShelf.models.holeB, [
        shelfWidth - (10 - this.shelfAdjustableShelfInset),
        this.shelfAdjustablePinInset + this.shelfAdjustableShelfInset
      ])
      makerjs.model.move(currAdjShelf.models.holeC, [
        10 - this.shelfAdjustableShelfInset,
        this.adjShelfDepth - this.shelfAdjustablePinInset + this.shelfAdjustableShelfInset
      ])
      makerjs.model.move(currAdjShelf.models.holeD, [
        shelfWidth - (10 - this.shelfAdjustableShelfInset),
        this.adjShelfDepth - this.shelfAdjustablePinInset + this.shelfAdjustableShelfInset
      ])

      // console.log(currAdjShelf)
      makerjs.model.move(currAdjShelf, [-100 - shelfWidth, yPos])
      yPos += this.adjShelfDepth + 50
    }
  }

  addTopLighting(top) {
    // lighting on the top piece
    let topLighting = (top.lighting = { models: {} })
    topLighting.models.rebate = new makerjs.models.Rectangle(
      this.lightingWidth + this.lightingPadding,
      this.lightingDepth
    )
    topLighting.models.rebate.layer = layerNames.mittledGroove.label

    topLighting.models.lines = {
      layer: layerNames.mittledCorner.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke + 5],
          end: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke]
        },
        line2: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke],
          end: [this.lightingRebateCornerPoke + 5, this.lightingRebateCornerPoke]
        },
        line3: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding - 5,
            this.lightingRebateCornerPoke
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            this.lightingRebateCornerPoke
          ]
        },
        line4: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            this.lightingRebateCornerPoke
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            this.lightingRebateCornerPoke + 5
          ]
        },
        line5: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            -this.lightingRebateCornerPoke + this.lightingDepth - 5
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            -this.lightingRebateCornerPoke + this.lightingDepth
          ]
        },
        line6: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            -this.lightingRebateCornerPoke + this.lightingDepth
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding - 5,
            -this.lightingRebateCornerPoke + this.lightingDepth
          ]
        },
        line7: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke + 5, -this.lightingRebateCornerPoke + this.lightingDepth],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth]
        },
        line8: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth - 5]
        }
      }
    }

    makerjs.model.move(topLighting, [
      (this.sideWidth - this.lightingWidth + this.lightingPadding) / 2,
      this.pantryDepth - this.topLightingInset
    ])

    // Cable housing
    // Channel
    topLighting.models['cable-channel'] = new makerjs.models.RoundRectangle(
      this.lightCableWidth,
      this.topLightingInset - this.lightCableWidth - this.lightCableCoverWidth - this.backRebateWidth,
      this.lightCableWidth / 2
    )
    makerjs.model.move(topLighting.models['cable-channel'], [
      this.lightingWidth +
        this.lightingPadding -
        this.lightCableWidth -
        (this.lightCableCoverWidth - this.lightCableWidth) / 2 +
        (this.lightCableCoverWidth - this.lightCableWidth) / 2,
      this.lightCableCoverWidth * 2 + this.lightCableWidth - this.lightCableCoverWidth / 2
    ])
    topLighting.models['cable-channel'].layer = layerNames.cableChannelGroove.label

    // Channel plug boxes (near light)
    topLighting.models['cable-channel-plug-box-front'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(topLighting.models['cable-channel-plug-box-front'], [
      this.lightingWidth + this.lightingPadding - 8,
      this.lightingDepth - 2
    ])
    topLighting.models['cable-channel-plug-box-front'].layer = layerNames.cableChannelGroove.label

    // Channel plug boxes (near back pocket)
    topLighting.models['cable-channel-plug-box-back'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(topLighting.models['cable-channel-plug-box-back'], [
      this.lightingWidth + this.lightingPadding - 8,
      this.topLightingInset - 13 - this.backRebateWidth
    ])
    topLighting.models['cable-channel-plug-box-back'].layer = layerNames.cableChannelGroove.label
  }

  addBotLighting(bot, shelfWidth) {
    // lighting on the bot fixed shelf
    let botLighting = (bot.lighting = { models: {} })

    botLighting.models.rebate = new makerjs.models.Rectangle(
      this.lightingWidth + this.lightingPadding,
      this.lightingDepth
    )
    botLighting.models.rebate.layer = layerNames.mittledGroove.label

    botLighting.models.lines = {
      layer: layerNames.mittledCorner.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke + 5],
          end: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke]
        },
        line2: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke],
          end: [this.lightingRebateCornerPoke + 5, this.lightingRebateCornerPoke]
        },
        line3: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding - 5,
            this.lightingRebateCornerPoke
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            this.lightingRebateCornerPoke
          ]
        },
        line4: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            this.lightingRebateCornerPoke
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            this.lightingRebateCornerPoke + 5
          ]
        },
        line5: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            -this.lightingRebateCornerPoke + this.lightingDepth - 5
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            -this.lightingRebateCornerPoke + this.lightingDepth
          ]
        },
        line6: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding,
            -this.lightingRebateCornerPoke + this.lightingDepth
          ],
          end: [
            -this.lightingRebateCornerPoke + this.lightingWidth + this.lightingPadding - 5,
            -this.lightingRebateCornerPoke + this.lightingDepth
          ]
        },
        line7: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke + 5, -this.lightingRebateCornerPoke + this.lightingDepth],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth]
        },
        line8: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth - 5]
        }
      }
    }

    makerjs.model.move(botLighting, [
      -this.pantry.w * 2 - 100 + (shelfWidth - this.lightingWidth + this.lightingPadding) / 2,
      this.fixedShelfDepth - this.botLightingInset
    ])

    // Channel
    botLighting.models['cable-channel'] = new makerjs.models.RoundRectangle(
      this.lightCableWidth,
      this.botLightingInset - this.lightCableWidth - this.lightCableCoverWidth,
      this.lightCableWidth / 2
    )
    makerjs.model.move(botLighting.models['cable-channel'], [
      this.lightingWidth +
        this.lightingPadding -
        this.lightCableWidth -
        (this.lightCableCoverWidth - this.lightCableWidth) / 2 +
        (this.lightCableCoverWidth - this.lightCableWidth) / 2,
      this.lightCableCoverWidth * 2 + this.lightCableWidth - this.lightCableCoverWidth / 2
    ])
    botLighting.models['cable-channel'].layer = layerNames.cableChannelGroove.label

    // Channel plug boxes (near light)
    botLighting.models['cable-channel-plug-box-front'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(botLighting.models['cable-channel-plug-box-front'], [
      this.lightingWidth + this.lightingPadding - 8,
      this.lightingDepth - 2
    ])
    botLighting.models['cable-channel-plug-box-front'].layer = layerNames.cableChannelGroove.label

    // Channel plug boxes (near back pocket)
    botLighting.models['cable-channel-plug-box-back'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(botLighting.models['cable-channel-plug-box-back'], [
      this.lightingWidth + this.lightingPadding - 8,
      this.botLightingInset - 13
    ])
    botLighting.models['cable-channel-plug-box-back'].layer = layerNames.cableChannelGroove.label

    // *****************************DISABLED - DESIGN CHOICE TO NOT INCLUDE (for now....)**************//
    // // Cable housing
    // // Cover Channel only for fixed bottom shelf
    // botLighting.models['cable-cover-channel'] = new makerjs.models.RoundRectangle(
    //   this.lightCableCoverWidth,
    //   this.botLightingInset - this.lightCableCoverWidth,
    //   this.lightCableCoverWidth / 2
    // )
    // makerjs.model.move(botLighting.models['cable-cover-channel'], [
    //   this.lightingWidth - this.lightCableCoverWidth + (this.lightCableCoverWidth - this.lightCableWidth) / 2,
    //   this.lightCableCoverWidth * 2
    // ])
    // botLighting.models['cable-cover-channel'].layer = layerNames.cableCoverGroove.label

    // // Cover Piece
    // const coverRect = new makerjs.models.Rectangle(
    //   this.lightCableCoverWidth,
    //   this.botLightingInset + this.lightingDepth / 2 - this.backRebateWidth - this.lightCableCoverWidth / 2 - 1.2 // 1.2 is distort tolerance
    // )
    // const coverCirc = new makerjs.models.Ellipse(this.lightCableCoverWidth / 2, this.lightCableCoverWidth / 2)
    // makerjs.model.move(coverCirc, [5, 0])

    // botLighting.models['cable-cover'] = makerjs.model.combineUnion(coverRect, coverCirc)
    // botLighting.models['cable-cover'] = makerjs.model.distort(botLighting.models['cable-cover'], 1.05, 1)

    // botLighting.models['cable-cover'] = makerjs.layout.cloneToRow(botLighting.models['cable-cover'], 2, 6)
    // makerjs.model.move(botLighting.models['cable-cover'], [shelfWidth, 0])

    // botLighting.models['cable-cover'].layer = layerNames.cableCoverCutout.label

    // // cover piece mill
    // const millRect = new makerjs.models.Rectangle(
    //   this.lightCableCoverWidth * 2 + 6 + 20,
    //   this.botLightingInset + this.lightingDepth / 2 - this.backRebateWidth + 20
    // )
    // makerjs.model.move(millRect, [shelfWidth + this.lightCableCoverWidth - 20.5, -10 - this.lightCableCoverWidth / 2])
    // botLighting.models['mill'] = millRect
    // botLighting.models['mill'].layer = layerNames.millCableCover.label
  }

  addHingeHoles(end) {
    // 5mm hinge holes on Pantry sides
    let hinge = (end['hinge'] = { models: {} })

    hinge.models.firstHole = new makerjs.models.Ellipse(this.hingeHoleRad, this.hingeHoleRad)
    hinge.models.firstHole.layer = layerNames.hingeDrillMedium.label

    hinge.models.secondHole = makerjs.cloneObject(hinge.models.firstHole)
    // set pos of initial hinge
    makerjs.model.move(hinge.models.firstHole, [this.hingePosHeightArray[0], this.hingeFromEdgeArray[0]])
    makerjs.model.move(hinge.models.secondHole, [this.hingePosHeightArray[0], this.hingeFromEdgeArray[1]])

    this.hingePosHeightArray.slice(1).forEach((height, layer) => {
      hinge.models[`firstHole${layer}`] = makerjs.cloneObject(hinge.models.firstHole)
      makerjs.model.move(hinge.models[`firstHole${layer}`], [height, this.hingeFromEdgeArray[0]])
      hinge.models[`secondHole${layer}`] = makerjs.cloneObject(hinge.models.secondHole)
      makerjs.model.move(hinge.models[`secondHole${layer}`], [height, this.hingeFromEdgeArray[1]])
    })
  }

  addJointHoles(end) {
    // Pilot Holes for Pantry Sides - screws to replace lamello
    end.botJointPilot = new makerjs.models.Ellipse(this.jointPilotRad, this.jointPilotRad)
    end.botJointPilot.layer = layerNames.maxPilotHole.label

    const pilot = cadMethods.calculateJoint(this.pantry.d)

    end.botJointPilot = makerjs.layout.cloneToColumn(
      end.botJointPilot,
      pilot.pilotCount,
      pilot.pilotSpacing - this.jointPilotRad * 2
    )

    let botJointXPos
    if (this.baseThickness) {
      botJointXPos = this.baseThickness / 2
    } else {
      botJointXPos = this.materialThickness / 2
    }
    makerjs.model.move(end.botJointPilot, [botJointXPos, pilot.pilotInset])

    end.topJointPilot = makerjs.cloneObject(end.botJointPilot)
    makerjs.model.move(end.topJointPilot, [this.pantry.h - this.materialThickness / 2, pilot.pilotInset])
  }

  addAdjustableShelfHoles(end) {
    // Side Pins
    let numPins = Math.floor(
      //internal void - tSlotHeight & botFixed thickness
      (this.pantry.h -
        this.materialThickness * 2 -
        (this.tSlotHeight + this.materialThickness) -
        this.firstAdjShelfGap -
        this.shelfAdjustableSpacing) /
        this.shelfAdjustableSpacing
    )
    if (!(numPins % 2)) numPins--

    end.pinsFront = new makerjs.models.Ellipse(this.shelfAdjustablePinRadius, this.shelfAdjustablePinRadius)
    end.pinsFront.layer = layerNames.shelfDrill.label
    end.pinsFront = makerjs.layout.cloneToRow(
      end.pinsFront,
      numPins,
      this.shelfAdjustableSpacing - this.shelfAdjustablePinRadius * 2
    )

    // const pinsHeight = (numPins - 1) * this.shelfAdjustableSpacing
    let yPos = this.materialThickness + this.tSlotHeight + this.materialThickness + this.firstAdjShelfGap
    if (this.baseThickness) yPos += this.baseThickness - this.materialThickness

    makerjs.model.move(end.pinsFront, [
      yPos,
      this.pantryDepth - this.backRebateWidth - this.adjShelfDepth + this.shelfAdjustablePinInset
    ])

    end.pinsBack = makerjs.model.clone(end.pinsFront)
    makerjs.model.move(end.pinsBack, [yPos, this.pantryDepth - this.backRebateWidth - this.shelfAdjustablePinInset])
  }

  addFixedShelfSlots(end) {
    let slot = (end['slot'] = { models: {} })

    slot.models.slotRebate = new makerjs.models.Rectangle(this.materialThickness + 0.5, this.tSlotDepth)
    slot.models.slotRebate.layer = layerNames.urtilFinPocket.label

    slot.models.tSlotTrack = {
      layer: layerNames.tSlotGroove.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.materialThickness + 0.5 - 10, 0]
        },
        line2: {
          type: 'line',
          origin: [this.materialThickness + 0.5 - 10, 0],
          end: [this.materialThickness + 0.5 - 10, this.tSlotDepth - this.tSlotRebateHeight / 2]
        },
        line3: {
          type: 'line',
          origin: [this.materialThickness + 0.5 - 10, this.tSlotDepth - this.tSlotRebateHeight / 2],
          end: [this.materialThickness + 0.5 - 10, 0]
        },
        line4: {
          type: 'line',
          origin: [this.materialThickness + 0.5 - 10, 0],
          end: [0, 0]
        }
      }
    }

    makerjs.model.move(slot.models.tSlotTrack, [5, 5])

    let slotHeight = this.tSlotHeight + this.materialThickness
    if (this.baseThickness) slotHeight += this.baseThickness - this.materialThickness

    makerjs.model.move(slot, [slotHeight, this.pantryDepth - this.fixedShelfDepth - 14])
  }

  constructBase() {
    let base = (this.models.base = {
      models: {
        outline: new makerjs.models.Rectangle(this.sideWidth, this.pantryDepth)
      }
    })
    base.layer = layerNames.cutout.label

    base.models.backRebate = new makerjs.models.Rectangle(
      this.sideWidth + this.backRebateToolDiameter,
      this.backRebateWidth + 1
    )
    base.models.backRebate.layer = layerNames.urtilBackPocket.label
    makerjs.model.move(base.models.backRebate, [
      -this.backRebateToolDiameter / 2,
      this.pantryDepth - this.backRebateWidth
    ])

    // Back pocket cut line (on-path)
    base.models.backRebateLine = {
      layer: layerNames.backPocketLine.label,
      paths: {
        rebateLine: {
          type: 'line',
          // origin x = 11, 5 + 12mm toolbit / 2
          origin: [2, this.pantryDepth - this.backRebateWidth / 2],
          end: [this.sideWidth - 2, this.pantryDepth - this.backRebateWidth / 2]
        }
      }
    }

    // location label engraving
    const baseEngrave = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    base.models.topEngrave = makerjs.model.mirror(baseEngrave, true, false)
    base.models.topEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(base.models.topEngrave, [100, this.pantryDepth - this.backRebateWidth / 2 - 4])
  }

  constructBack() {
    let backWidth = this.pantry.w - this.carcassThickness * 2 + 25
    const backHeight = this.pantry.h - this.carcassThickness * 2 + 25

    let back = (this.models.back = {
      models: {
        outline: new makerjs.models.RoundRectangle(backWidth, backHeight, 10)
      }
    })
    back.layer = layerNames.backCutout.label

    // Drill holes
    const drillHole = new makerjs.models.Ellipse(this.drillGuideDiameter / 2, this.drillGuideDiameter / 2)

    // Bottom
    const bottomGuides = makerjs.layout.cloneToRow(drillHole, this.pantry.w / this.drillGuideSpacing, 0)
    const bottomGuide = new makerjs.paths.Line(
      [this.drillGuideCornerOffsetBot, this.drillGuideInset],
      [backWidth - this.drillGuideCornerOffsetBot, this.drillGuideInset]
    )
    back.models.drillGuideBottom = makerjs.layout.childrenOnPath(bottomGuides, bottomGuide)
    back.models.drillGuideBottom.layer = layerNames.backScrewHole.label

    // Top
    const topGuides = makerjs.layout.cloneToRow(drillHole, this.pantry.w / this.drillGuideSpacing, 0)
    const topGuide = new makerjs.paths.Line(
      [this.drillGuideCornerOffsetTop, backHeight - this.drillGuideInset],
      [backWidth - this.drillGuideCornerOffsetTop, backHeight - this.drillGuideInset]
    )
    back.models.drillGuideTop = makerjs.layout.childrenOnPath(topGuides, topGuide)
    back.models.drillGuideTop.layer = layerNames.backScrewHole.label

    // Left
    const leftGuides = makerjs.layout.cloneToRow(
      drillHole,
      this.pantry.h / this.drillGuideSpacing >= 2 ? this.pantry.h / this.drillGuideSpacing : 2,
      0
    )
    const leftGuide = new makerjs.paths.Line(
      [this.drillGuideInset, this.drillGuideCornerOffset],
      [this.drillGuideInset, backHeight - this.drillGuideCornerOffset]
    )
    back.models.drillGuideLeft = makerjs.layout.childrenOnPath(leftGuides, leftGuide)
    back.models.drillGuideLeft.layer = layerNames.backScrewHole.label

    // Right
    const rightGuides = makerjs.layout.cloneToRow(
      drillHole,
      this.pantry.h / this.drillGuideSpacing >= 2 ? this.pantry.h / this.drillGuideSpacing : 2,
      0
    )
    const rightGuide = new makerjs.paths.Line(
      [backWidth - this.drillGuideInset, this.drillGuideCornerOffset],
      [backWidth - this.drillGuideInset, backHeight - this.drillGuideCornerOffset]
    )
    back.models.drillGuideRight = makerjs.layout.childrenOnPath(rightGuides, rightGuide)
    back.models.drillGuideRight.layer = layerNames.backScrewHole.label

    // Lighting Pilot Hole
    if (this.pantry.lighting) {
      // Top Light Hole
      back.models.topLightHole = new makerjs.models.Ellipse(
        this.lightCableHoleDiameter / 2,
        this.lightCableHoleDiameter / 2
      )
      back.models.topLightHole.layer = layerNames.lightHole.label
      makerjs.model.move(back.models.topLightHole, [
        (backWidth - this.lightingWidth) / 2 - 2.5 + this.lightingWidth,
        backHeight - this.lightCableHoleDiameter / 2
      ])

      // Bot Fixed Hole
      back.models.fixedBotLightHole = makerjs.cloneObject(back.models.topLightHole)
      makerjs.model.move(back.models.fixedBotLightHole, [
        (backWidth - this.lightingWidth) / 2 - 2.5 + this.lightingWidth,
        this.tSlotHeight -
          (this.materialThickness - this.backRebateDepth) +
          (this.endRebateDepth + this.endRebateTolerance) -
          this.lightCableHoleDiameter +
          8 // +3 to accomodate the 3mm pilot hole and then +5 to accomodate 4mm radius of the 8mm drill used to punch out the hole (1mm tolerance)
      ])

      // Top Light Label Engraving
      const topLightEngrave = new makerjs.models.Text(this.font, 'LIGHT', this.fontSize)
      back.models.topLightEngrave = makerjs.model.mirror(topLightEngrave, true, false)
      back.models.topLightEngrave.layer = layerNames.engrave.label
      // find light position
      makerjs.model.move(back.models.topLightEngrave, [
        (backWidth - this.lightingWidth) / 2 - 2.5 + this.lightingWidth + 15,
        backHeight - this.lightCableHoleDiameter / 2 - 15
      ])

      // Bottom Light Label Engraving
      const botLightEngrave = new makerjs.models.Text(this.font, 'LIGHT', this.fontSize)
      back.models.botLightEngrave = makerjs.model.mirror(botLightEngrave, true, false)
      back.models.botLightEngrave.layer = layerNames.engrave.label
      // find light position
      makerjs.model.move(back.models.botLightEngrave, [
        (backWidth - this.lightingWidth) / 2 - 2.5 + this.lightingWidth + 15,
        this.tSlotHeight -
          (this.materialThickness - this.backRebateDepth) +
          (this.endRebateDepth + this.endRebateTolerance) -
          this.lightCableHoleDiameter +
          8 -
          15
      ])
    }

    // Fixed Shelf Pilot Holes
    const fShelfGuides = makerjs.layout.cloneToRow(
      drillHole,
      this.pantry.w / this.fixedShelfHoleSpacing >= 2 ? this.pantry.w / this.fixedShelfHoleSpacing : 2,
      0
    )
    const fShelfGuide = new makerjs.paths.Line(
      [
        this.drillGuideCornerOffset,
        this.baseThickness
          ? this.tSlotHeight + this.carcassThickness + this.baseThickness / 2 - 5 // 5mm is the gap between the back pocket and outer edge
          : this.tSlotHeight + this.carcassThickness + this.carcassThickness / 2 - 5
      ],
      [
        backWidth - this.drillGuideCornerOffset,
        this.baseThickness
          ? this.tSlotHeight + this.carcassThickness + this.baseThickness / 2 - 5
          : this.tSlotHeight + this.carcassThickness + this.carcassThickness / 2 - 5
      ]
    )
    back.models.drillGuideBotFixed = makerjs.layout.childrenOnPath(fShelfGuides, fShelfGuide)
    back.models.drillGuideBotFixed.layer = layerNames.backPilotHole.label

    // engraving on back to show orientation
    const orientationLabel = 'top'
    const orientationText = new makerjs.models.Text(this.font, orientationLabel.toUpperCase(), this.fontSize)
    back.models.backOrientationEngraving = makerjs.model.mirror(orientationText, true, false)
    back.models.backOrientationEngraving.layer = layerNames.engrave.label
    makerjs.model.move(back.models.backOrientationEngraving, [backWidth / 2 + 10, backHeight - 40])
  }
}
