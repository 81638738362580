<template>
  <div id="production-table">
    <div class="table-header">
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      :items="orders"
      :headers="headers"
      :search="tableSearch"
      :loading="loading"
      :no-data-text="noDataText"
      :items-per-page="-1"
      hide-default-footer
      sort-by="dateCreated"
      sort-desc
      must-sort
    >
      <template v-slot:item="props">
        <tr>
          <td>{{ $utils.relativeTime(props.item.dateCreated) }}</td>
          <td>{{ props.item.orderNumber }}</td>
          <td>{{ props.item.customerContactDetails.email }}</td>
          <td>{{ props.item.creatorEmail || 'n/a' }}</td>
          <td>
            {{ $utils.formatCurrency(props.item.currency, props.item.total) }}
          </td>
          <td class="text-right">
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  @click="
                    () => {
                      selectedOrder = props.item
                      paymentDialog = true
                      payment.date = $moment().format('YYYY-MM-DD')
                      payment.value = null
                    }
                  "
                  v-on="on"
                  >mdi-currency-usd</v-icon
                >
              </template>
              <span>Manually add payment</span>
            </v-tooltip>
            <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  @click="
                    () => {
                      deleteOrderDialog = true
                      selectedOrder = props.item
                    }
                  "
                  v-on="on"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Delete Order</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="paymentDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">Manually Add Payment</v-card-title>
        <v-card-text class="pt-6">
          <v-date-picker v-model="payment.date" landscape></v-date-picker>
          <v-text-field
            v-if="selectedOrder"
            class="mt-3"
            label="Amount"
            v-model="payment.amount"
            :prefix="selectedOrder.region == 'us' ? '$' : '£'"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="paymentDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            :disabled="!payment.amount"
            depressed
            :dark="payment.amount != null"
            @click="addPayment"
            >Add Payment</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteOrderDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Delete Order</v-card-title>
        <v-card-text class="pt-6">Are you sure you want to delete this order?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteOrderDialog = false">Cancel</v-btn>
          <v-btn depressed dark color="warning" @click="deleteOrder(selectedOrder)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AwaitingPayment',
  data() {
    return {
      loading: true,
      orders: null,
      selectedOrder: null,
      paymentDialog: false,
      deleteOrderDialog: false,
      payment: {
        date: this.$moment().format('YYYY-MM-DD'),
        amount: null
      },
      headers: [
        {
          text: 'Due',
          sortable: true,
          value: 'dateCreated'
        },
        {
          text: 'Order Number',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Customer',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'Creator',
          sortable: false,
          value: 'creatorEmail'
        },
        {
          text: 'Value',
          sortable: false,
          value: 'total'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          value: null
        }
      ],
      tableSearch: ''
    }
  },
  computed: {
    noDataText() {
      return this.loading ? '' : 'Nothing to display'
    }
  },
  created() {
    this.$bind(
      'orders',
      this.$db
        .collection('orders')
        .where('type', '==', 'mega-admin')
        .where('numPayments', '==', 0)
        .where('foc', '!=', true)
    ).then(() => {
      this.loading = false
    })
  },
  methods: {
    view(qID, vID) {
      this.$router.push({
        name: 'quoteEdit',
        params: { quoteID: qID, versionID: vID }
      })
    },
    addPayment() {
      this.selectedOrder.payments.push({
        amount: parseFloat(this.payment.amount),
        date: this.$utils.timestampFromVuetify(this.payment.date)
      })

      let paymentsTotal = 0
      for (var i = 0; i < this.selectedOrder.payments.length; i++) {
        paymentsTotal += this.selectedOrder.payments[i].amount
      }

      this.$db
        .collection('orders')
        .doc(this.selectedOrder.id)
        .update({
          numPayments: this.selectedOrder.numPayments + 1,
          payments: this.selectedOrder.payments,
          balanceOutstanding: paymentsTotal < this.selectedOrder.total
        })

      this.paymentDialog = false
    },
    async deleteOrder(order) {
      this.deleteOrderDialog = false
      const deleteOrderFunction = this.$firebase.functions().httpsCallable('deleteOrder')
      await deleteOrderFunction({
        order: order,
        orderID: order.id
      })
      this.$store.commit('openSnackbar', {
        text: 'Order deleted',
        timeout: 5000
      })
    }
  }
}
</script>
