<template>
  <div>
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center"
      ><v-progress-circular indeterminate></v-progress-circular>
      <p class="mt-5">Just crunching some numbers...</p>
    </v-overlay>
    <div>
      <div id="view-sub-nav">
        <DateRangePicker :empty="true" class="align-self-start" @change="dateRangeChanged"></DateRangePicker>
      </div>
      <div>
        <v-container fluid> </v-container>
      </div>
      <v-data-table :items="orders" :headers="headers">
        <template #item.dateCreated="{item}">
          {{ $moment.unix(item.dateCreated.seconds).format('Do MMM YYYY') }}
        </template>
        <template #item.salesTax="{item}">
          {{ item.version.pricing.salesTax ? item.version.pricing.salesTax.amount_to_collect : 'None' }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script type="text/javascript">
import DateRangePicker from '@/components/ui/DateRangePicker'

export default {
  name: 'Scheduled',
  components: { DateRangePicker },
  data() {
    return {
      activeDateRange: null,
      orders: [],
      loading: false,
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Date Created',
          sortable: false,
          value: 'dateCreated'
        },
        {
          text: 'Customer',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'FOC',
          sortable: false,
          value: 'foc'
        },
        {
          text: 'Total',
          sortable: false,
          value: 'total'
        },
        {
          text: 'Sales Tax',
          sortable: false,
          value: 'salesTax'
        }
      ]
    }
  },
  methods: {
    dateRangeChanged(range) {
      this.activeDateRange = range
      ;(this.scheduledValue = null), (this.scheduledFocValue = null), (this.orders = null)
      this.loadData()
    },
    async loadData() {
      this.loading = true
      this.orders = []

      const orderSnap = await this.$db
        .collection('orders')
        .where('region', '==', 'us')
        .where('type', '==', 'mega-admin')
        .where('dateCreated', '>=', this.activeDateRange[0].startOf('day').toDate())
        .where('dateCreated', '<=', this.activeDateRange[1].endOf('day').toDate())
        .get()

      this.loading = false

      for (const order of orderSnap.docs) {
        let o = order.data()
        o.id = order.id

        const quoteSnap = await this.$db
          .collection('quotes')
          .doc(order.data().quoteID)
          .get()
        const version = quoteSnap.data().versions.find(o => {
          return o.id === order.data().versionID
        })
        o.version = version

        this.orders.push(o)
      }

      console.log(this.orders)
    }
  }
}
</script>
