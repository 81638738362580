import paper from 'paper'
import cadMethods from '@/js/cad/CadMethods'

export default class Urtil {
  constructor(name, data, mback, mcarc, mdoor1, mdoor2, grainDirection) {
    this.data = data
    this.grainDirection = grainDirection
    this.mback = mback
    this.mcarc = mcarc
    this.mdoor1 = mdoor1
    this.mdoor2 = mdoor2

    this.materialThickness = 18
    this.rebateDepth = 6
    this.sideRebateDepth = 5
    this.doorOverlap = 5
    this.centerDivideThreshold = 1000
    this.handleOffset = 17.5
    this.handleSize = 35
    this.labelPadding = 50
    this.labelSize = 40
    this.iconSize = 30

    this.trimmingTolerance = 5 // Visual cue of trimming extension
    this.internalVoid = this.data.w - this.materialThickness * 2 - (this.data.compartments - 1) * this.materialThickness

    this.createBack()
    this.createCarcass()
    this.createShelving()
    this.createFin()
    this.addDoors()
    var label = this.getLabel()
    this.grain = this.getGrainDirection()
    label.name = 'label'
    this.outline.name = 'outline'
    this.dimension = this.getDimension()
    this.dimension.name = 'dimension'
    let itemGroup = [this.back, this.shelves, this.fin, this.doors, label, this.outline, this.dimension]
    if (this.grain) {
      this.grain.name = 'grain'
      itemGroup.push(this.grain)
    }
    this.item = new paper.Group([...itemGroup])
    this.item.name = name
    this.item.data.originalBounds = this.item.bounds
  }

  createBack() {
    let backRect = new paper.Path.Rectangle(
      new paper.Point(this.materialThickness / 2, this.materialThickness / 2),
      new paper.Size(this.data.w - this.materialThickness, this.data.h - this.materialThickness)
    )

    backRect.fillColor = this.mback ? this.mback.uiColour : '#FBE6CB'
    backRect.selectedColor = 'transparent'
    this.back = new paper.Group(backRect)
    this.back.name = 'back'
  }

  createFin() {
    if (this.data.compartments > 1) {
      let dividerGroup = []
      for (let i = 0; i < this.data.compartments - 1; i++) {
        let divider = new paper.Path.Rectangle(
          new paper.Point(
            (this.internalVoid / this.data.compartments + this.materialThickness) * (i + 1),
            this.materialThickness
          ),
          new paper.Size(this.materialThickness, this.data.h - this.materialThickness * 2)
        )
        divider.fillColor = '#FBE6CB'
        divider.strokeColor = 'black'
        divider.selectedColor = 'transparent'
        dividerGroup.push(divider)
      }

      this.fin = new paper.Group(dividerGroup)
    }
  }

  createShelving() {
    const shelves = []
    let shelfPositionArr
    if (this.data.shelving === 'fixed') {
      const shelfPosArrUnreversed = cadMethods.calculateShelfHeights(
        this.data,
        this.materialThickness,
        this.materialThickness
      )
      // paperjs works top to bottom
      shelfPositionArr = shelfPosArrUnreversed.map(shelfPos => this.data.h - shelfPos)
    } else if (this.data.shelving === 'adjustable' || this.data.shelving === 'blank') {
      const numAdjShelves = this.data.shelvingOptions.qty
      const internalVoidHeight = this.data.h - this.materialThickness * 2 - this.materialThickness * numAdjShelves
      const shelfHeight = internalVoidHeight / (numAdjShelves + 1)
      shelfPositionArr = []
      for (let i = 0; i < numAdjShelves; i++) {
        shelfPositionArr.push((shelfHeight + this.materialThickness) * (i + 1))
      }
    }
    shelfPositionArr?.forEach(shelfPos => {
      let shelf = new paper.Path.Rectangle(
        new paper.Point(this.materialThickness, shelfPos),
        new paper.Size(this.data.w - this.materialThickness * 2, this.materialThickness)
      )

      shelf.fillColor = '#FBE6CB'
      shelf.strokeColor = 'black'
      shelf.selectedColor = 'transparent'
      shelves.push(shelf)
    })
    this.shelves = new paper.Group(shelves)
  }

  createCarcass() {
    const l = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(this.materialThickness, this.data.h))
    const r = new paper.Path.Rectangle(
      new paper.Point(this.data.w - this.materialThickness, 0),
      new paper.Size(this.materialThickness, this.data.h)
    )
    const t = new paper.Path.Rectangle(
      new paper.Point(this.materialThickness - this.rebateDepth, 0),
      new paper.Size(this.data.w - 2 * (this.materialThickness - this.rebateDepth), this.materialThickness)
    )
    const b = new paper.Path.Rectangle(
      new paper.Point(this.materialThickness - this.rebateDepth, this.data.h - this.materialThickness),
      new paper.Size(this.data.w - 2 * (this.materialThickness - this.rebateDepth), this.materialThickness)
    )

    if (this.data.trimming) {
      const trimIconPath =
        'M356.038,349.44c-2.427,0-4.824,0.128-7.199,0.338l-27.862-48.259l-43.318,75.029l7.923,13.724 c-6.87,11.923-10.824,25.729-10.824,40.45c0,44.818,36.462,81.28,81.28,81.28c44.818,0,81.28-36.462,81.28-81.28 C437.318,385.904,400.855,349.44,356.038,349.44z M356.038,474.486c-24.133,0-43.766-19.633-43.766-43.766 c0-24.133,19.633-43.766,43.766-43.766c24.133,0,43.766,19.633,43.766,43.766C399.804,454.853,380.169,474.486,356.038,474.486z' +
        'M365.106,0L163.162,349.778c-2.373-0.21-4.771-0.338-7.199-0.338c-44.818,0-81.28,36.462-81.28,81.28 c0,44.818,36.462,81.28,81.28,81.28c44.818,0,81.28-36.462,81.28-81.28c0-14.719-3.954-28.527-10.824-40.449l166.15-287.781 C413.288,66.606,400.991,20.718,365.106,0z M155.964,474.486c-24.133,0-43.766-19.633-43.766-43.766 c0-24.133,19.633-43.766,43.766-43.766s43.766,19.633,43.766,43.766C199.73,454.853,180.096,474.486,155.964,474.486z' +
        'M146.896,0c-35.886,20.718-48.183,66.606-27.464,102.49l71.593,124.002l43.318-75.03L146.896,0z'

      const makeTrimIcon = (posX, posY, deg) => {
        const trimIcon = new paper.CompoundPath(trimIconPath)
        trimIcon.scale(0.1)
        trimIcon.rotate(deg)
        trimIcon.position = new paper.Point(posX, posY)
        trimIcon.fillColor = 'red'
        trimIcon.selectedColor = 'transparent'
        return trimIcon
      }

      const trimIconTopL = makeTrimIcon(-this.materialThickness + 75, 20, -90)
      const trimIconTopR = makeTrimIcon(this.materialThickness + this.data.w - 75, 20, 90)
      const trimIconBotL = makeTrimIcon(-this.materialThickness + 75, this.data.h - 20, -90)
      const trimIconBotR = makeTrimIcon(this.materialThickness + this.data.w - 75, this.data.h - 20, 90)

      const topExtL = new paper.Path.Rectangle(
        new paper.Point(this.materialThickness - this.rebateDepth, 0),
        new paper.Size(this.trimmingTolerance, this.materialThickness)
      )
      const topExtR = new paper.Path.Rectangle(
        new paper.Point(this.data.w - this.materialThickness + this.rebateDepth, 0),
        new paper.Size(this.trimmingTolerance, this.materialThickness)
      )
      const botExtL = new paper.Path.Rectangle(
        new paper.Point(this.materialThickness - this.rebateDepth, this.data.h - this.materialThickness),
        new paper.Size(this.trimmingTolerance, this.materialThickness)
      )
      const botExtR = new paper.Path.Rectangle(
        new paper.Point(this.data.w - this.materialThickness + this.rebateDepth, this.data.h - this.materialThickness),
        new paper.Size(this.trimmingTolerance, this.materialThickness)
      )

      const trimStyle = {
        strokeColor: 'red',
        dashArray: [2, 5],
        strokeWidth: 3,
        strokeCap: 'round'
      }

      topExtL.style = trimStyle
      topExtR.style = trimStyle
      botExtL.style = trimStyle
      botExtR.style = trimStyle

      const basicOutlineStyle = {
        strokeColor: 'black',
        fillColor: '#FBE6CB'
      }
      l.style = basicOutlineStyle
      r.style = basicOutlineStyle
      b.style = basicOutlineStyle
      t.style = basicOutlineStyle

      this.outline = new paper.Group([
        l,
        r,
        b,
        t,
        topExtL,
        topExtR,
        botExtL,
        botExtR,
        trimIconBotL,
        trimIconBotR,
        trimIconTopL,
        trimIconTopR
      ])
    } else {
      this.outline = new paper.Group([l, r, b, t])
      this.outline.strokeColor = 'black'
      this.outline.fillColor = '#FBE6CB'
    }
  }

  addDoors() {
    if ((this.data.type == 's' && !this.data.singleDoorSide) || this.data.type == 't') {
      // first door
      let doorOneWidth =
        (this.internalVoid / this.data.compartments) * this.data.doorCoverageOne +
        this.materialThickness * this.data.doorCoverageOne +
        this.sideRebateDepth +
        this.doorOverlap
      let l = new paper.Path.Rectangle(
        new paper.Point(0, this.materialThickness),
        new paper.Size(doorOneWidth, this.data.h - this.materialThickness * 2)
      )
      let lh = new paper.Path.Circle(
        new paper.Point(
          this.handleOffset + this.handleSize / 2,
          this.data.h - this.materialThickness - this.handleOffset - this.handleSize / 2
        ),
        this.handleSize / 2
      )
      let left = l.subtract(lh)
      l.remove()
      lh.remove()

      left.fillColor = this.mdoor1 ? this.mdoor1.uiColour : 'white'
      left.strokeColor = 'black'
      left.selectedColor = 'transparent'
      left.translate(new paper.Point(this.materialThickness - this.sideRebateDepth, 0))
      this.doors = new paper.Group(left)
      this.doors.name = 'doors'
    }
    if ((this.data.type == 's' && this.data.singleDoorSide) || this.data.type == 't') {
      let doorCoverageTwo
      if (this.data.type == 't') {
        doorCoverageTwo = this.data.doorCoverageTwo
      } else if (this.data.type == 's' && this.data.singleDoorSide) {
        doorCoverageTwo = this.data.doorCoverageOne
      }
      let doorTwoWidth =
        (this.internalVoid / this.data.compartments) * doorCoverageTwo +
        this.materialThickness * doorCoverageTwo +
        this.sideRebateDepth +
        this.doorOverlap
      let r = new paper.Path.Rectangle(
        new paper.Point(0, this.materialThickness),
        new paper.Size(doorTwoWidth, this.data.h - this.materialThickness * 2)
      )
      let rh = new paper.Path.Circle(
        new paper.Point(
          doorTwoWidth - this.handleOffset - this.handleSize / 2,
          this.data.h - this.materialThickness - this.handleOffset - this.handleSize / 2
        ),
        this.handleSize / 2
      )
      let right = r.subtract(rh)
      r.remove()
      rh.remove()
      let materialRightDoor
      if (this.data.type == 't') {
        materialRightDoor = this.mdoor2 ? this.mdoor2.uiColour : 'white'
      } else if (this.data.type == 's' && this.data.singleDoorSide) {
        materialRightDoor = this.mdoor1 ? this.mdoor1.uiColour : 'white'
      }
      right.fillColor = materialRightDoor
      right.strokeColor = 'black'
      right.selectedColor = 'transparent'
      right.translate(new paper.Point(this.data.w - this.materialThickness + this.sideRebateDepth - doorTwoWidth, 0))

      if (this.data.type == 't') {
        this.doors.addChild(right)
      } else if (this.data.type == 's' && this.data.singleDoorSide) {
        this.doors = new paper.Group(right)
      }

      this.doors.name = 'doors'
    }
  }

  getAdjIcon() {
    let adjIconGroup = []
    if (this.data.shelving === 'adjustable') {
      const arrowStyle = {
        justification: 'center',
        fillColor: 'transparent',
        selectedColor: 'transparent',
        fontSize: this.iconSize,
        fontFamily: 'Roboto Mono'
      }

      const numAdjShelves = this.data.shelvingOptions.qty
      const availableSpace = this.data.h - this.materialThickness * 2 - this.materialThickness * numAdjShelves
      const adjShelfStep = availableSpace / (numAdjShelves + 1)
      for (let i = 0; i < numAdjShelves; i++) {
        let yPos = this.data.h - (this.materialThickness + adjShelfStep) * (i + 1) - this.materialThickness / 2

        if (this.data.compartments > 1) {
          let shelfWidth =
            (this.data.w - this.materialThickness * 2 - (this.data.compartments - 1) * this.materialThickness) /
            this.data.compartments
          for (let j = 0; j < this.data.compartments; j++) {
            let xPos = 4 + shelfWidth / 2 + (shelfWidth * j + this.materialThickness * (j + 1))
            let upIcon = new paper.PointText(new paper.Point(xPos, yPos - this.materialThickness / 2))
            let downIcon = new paper.PointText(new paper.Point(xPos, yPos + this.materialThickness / 2))
            downIcon.rotate(180)

            upIcon.style = arrowStyle
            upIcon.content = '^'
            downIcon.style = arrowStyle
            downIcon.content = '^'

            adjIconGroup.push(upIcon, downIcon)
          }
        }
      }
    }
    return adjIconGroup
  }

  getLabel() {
    const text = new paper.PointText(new paper.Point(this.data.w / 2, this.data.h + this.labelSize + this.labelPadding))
    text.justification = 'center'
    text.fillColor = 'black'
    text.selectedColor = 'transparent'
    text.fontSize = this.labelSize
    text.fontFamily = 'Roboto Mono'
    text.content = this.data.name ? 'Ürtil - ' + this.data.name : 'URTIL'

    let carcassDotty = new paper.Path.Circle(
      new paper.Point(
        (this.data.w - text.bounds.width) / 2 - this.labelPadding,
        this.data.h + text.bounds.height / 2 + this.labelPadding
      ),
      20
    )
    carcassDotty.fillColor = this.mcarc.uiColour
    carcassDotty.selectedColor = 'transparent'
    carcassDotty.strokeColor = 'black'

    let lightIconGroup = []
    if (this.data.lighting) {
      const lightIconPath =
        'M748 782 c-32 -13 -436 -374 -441 -394 -14 -50 41 -107 87 -91 36 12 436 363 448 392 12 33 -6 75 -38 90 -28 12 -32 13 -56 3z' +
        'M1296 769 l-26 -20 0 -305 c0 -291 1 -305 20 -324 11 -11 33 -20 50 -20 17 0 39 9 50 20 19 19 20 33 20 324 l0 305 -26 20 c-15 12 -34 21 -44 21 -10 0 -29 -9 -44 -21z' +
        'M1874 778 c-28 -13 -46 -53 -38 -83 7 -27 411 -385 450 -398 46 -16 100 41 87 91 -5 20 -409 381 -441 394 -23 9 -33 8 -58 -4z'

      const makeLightIcon = (posX, posY, deg) => {
        const lightIcon = new paper.CompoundPath(lightIconPath)
        lightIcon.scale(0.04)
        lightIcon.rotate(deg)
        lightIcon.position = new paper.Point(posX, posY)
        lightIcon.fillColor = '#f73123'
        lightIcon.selectedColor = 'transparent'
        return lightIcon
      }

      const lightIconDownL = makeLightIcon(this.data.w / 5, this.data.h + this.materialThickness, 180)
      const lightIconDownR = makeLightIcon((this.data.w * 4) / 5, this.data.h + this.materialThickness, 180)

      lightIconGroup.push(lightIconDownL, lightIconDownR)
    }

    let labelIconGroup = [text, carcassDotty]
    if (this.data.lighting) labelIconGroup.push(...lightIconGroup)

    // get adjIcon()
    let adjIcon = this.getAdjIcon().length > 0 ? this.getAdjIcon() : null
    if (adjIcon) {
      let adjIconGroup = new paper.Group(adjIcon)
      adjIconGroup.name = 'adjIcon'
      labelIconGroup.push(adjIconGroup)
    }

    return new paper.Group(labelIconGroup)
  }

  getDimension() {
    var dimension = new paper.PointText(new paper.Point(this.data.w, this.data.h + 20))
    dimension.justification = 'center'
    dimension.fillColor = 'transparent'
    dimension.selectedColor = 'transparent'
    dimension.fontSize = this.labelSize
    dimension.fontFamily = 'Roboto Mono'
    dimension.content = `W${this.data.w
      .toString()
      .toUpperCase()} x H${this.data.h.toString().toUpperCase()} x D${this.data.d.toString().toUpperCase()}`
    dimension.translate(-dimension.bounds.width / 2 - 20, 0)
    if (this.data.h >= this.data.w && this.data.w < 400) {
      dimension.rotate(-90)
    } else {
      dimension.translate(0, dimension.bounds.height / 2)
    }
    return dimension
  }

  getGrainDirection() {
    if (!this.mback.hasGrain || !this.grainDirection) return null

    const grainIconPath =
      'M36.875,74.49023a3.99582,3.99582,0,0,1,.626-5.61328A77.36856,77.36856,0,0,1,65.02979,56.11914c15.38134-3.84375,39.17675-4.78906,65.189,12.55274,18.82227,12.54785,38.5,16.42773,58.48682,11.52734a70.77906,70.77906,0,0,0,24.80175-11.32715,3.99975,3.99975,0,0,1,4.9917,6.251,77.36856,77.36856,0,0,1-27.52881,12.75781,77.24806,77.24806,0,0,1-18.71533,2.31152c-13.38574.001-29.4541-3.51758-46.47363-14.86426C106.959,62.7793,87.28076,58.89941,67.29443,63.80078A70.77906,70.77906,0,0,0,42.49268,75.12793,4.00386,4.00386,0,0,1,36.875,74.49023Z' +
      'm176.63232,50.38184a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.02051-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,124.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90234,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,131.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z' +
      'm0,56a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.01953-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,180.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90332,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,187.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z'

    const makeGrainIcon = (posX, posY, deg) => {
      const grainIcon = new paper.CompoundPath(grainIconPath)
      grainIcon.scale(0.3)
      grainIcon.rotate(deg)
      grainIcon.position = new paper.Point(posX, posY)
      grainIcon.strokeCap = 'round'
      grainIcon.fillColor = 'transparent'
      return grainIcon
    }

    let grainInfo
    if (this.data.opposingGrain) {
      if (Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        grainInfo = makeGrainIcon(70, 70, 0)
      } else {
        grainInfo = makeGrainIcon(70, 70, 90)
      }
    } else {
      if (Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        grainInfo = makeGrainIcon(70, 70, 90)
      } else {
        grainInfo = makeGrainIcon(70, 70, 0)
      }
    }
    return grainInfo
  }
}
