var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class PlatsaDrawerFixings {
  constructor(w, h) {
    this.models = {}
    this.width = w
    this.height = h

    this.slitOffsetX = 37
    this.slitOffsetY = [11.5, 36]

    this.fixingOffsetX = 38.5
    this.fixingPosArray = [40, 65, 115, 140]
    this.fixingCutLength = 10

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.bottomSlits = {
      layer: layerNames.platsaBaseGroove.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.slitOffsetX, this.slitOffsetY[0]],
          end: [this.width - this.slitOffsetX, this.slitOffsetY[0]]
        },
        line2: {
          type: 'line',
          origin: [this.slitOffsetX, this.slitOffsetY[1]],
          end: [this.width - this.slitOffsetX, this.slitOffsetY[1]]
        }
      }
    }

    this.models.platsaToolCuts = {
      layer: layerNames.platsaFormOutline.label,
      paths: {
        line1a: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[0] - this.fixingCutLength],
          end: [this.fixingOffsetX, this.fixingPosArray[0]]
        },
        line1b: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[0]],
          end: [this.fixingOffsetX, this.fixingPosArray[0] - this.fixingCutLength]
        },
        line2a: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[1] - this.fixingCutLength],
          end: [this.fixingOffsetX, this.fixingPosArray[1]]
        },
        line2b: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[1]],
          end: [this.fixingOffsetX, this.fixingPosArray[1] - this.fixingCutLength]
        },
        line3a: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[2] - this.fixingCutLength],
          end: [this.fixingOffsetX, this.fixingPosArray[2]]
        },
        line3b: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[2]],
          end: [this.fixingOffsetX, this.fixingPosArray[2] - this.fixingCutLength]
        },
        line4a: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[3] - this.fixingCutLength],
          end: [this.fixingOffsetX, this.fixingPosArray[3]]
        },
        line4b: {
          type: 'line',
          origin: [this.fixingOffsetX, this.fixingPosArray[3]],
          end: [this.fixingOffsetX, this.fixingPosArray[3] - this.fixingCutLength]
        },
        line5a: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[0] - this.fixingCutLength],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[0]]
        },
        line5b: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[0]],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[0] - this.fixingCutLength]
        },
        line6a: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[1] - this.fixingCutLength],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[1]]
        },
        line6b: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[1]],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[1] - this.fixingCutLength]
        },
        line7a: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[2] - this.fixingCutLength],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[2]]
        },
        line7b: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[2]],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[2] - this.fixingCutLength]
        },
        line8a: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[3] - this.fixingCutLength],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[3]]
        },
        line8b: {
          type: 'line',
          origin: [this.width - this.fixingOffsetX, this.fixingPosArray[3]],
          end: [this.width - this.fixingOffsetX, this.fixingPosArray[3] - this.fixingCutLength]
        }
      }
    }

    this.models.holes = new makerjs.models.Holes(5.5, [
      [this.fixingOffsetX, this.fixingPosArray[0]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[0]],
      [this.fixingOffsetX, this.fixingPosArray[1]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[1]],
      [this.fixingOffsetX, this.fixingPosArray[2]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[2]],
      [this.fixingOffsetX, this.fixingPosArray[3]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[3]]
    ])
    this.models.holes.layer = layerNames.platsaKeyhole.label

    this.models.chamfer = new makerjs.models.Holes(6, [
      [this.fixingOffsetX, this.fixingPosArray[0]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[0]],
      [this.fixingOffsetX, this.fixingPosArray[1]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[1]],
      [this.fixingOffsetX, this.fixingPosArray[2]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[2]],
      [this.fixingOffsetX, this.fixingPosArray[3]],
      [this.width - this.fixingOffsetX, this.fixingPosArray[3]]
    ])
    this.models.chamfer.layer = layerNames.platsaKeyholeChamfer.label
  }
}
