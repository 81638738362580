var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class SpacerPanel {
  constructor(spacer, width) {
    this.models = {}

    this.models.spacer = new makerjs.models.RoundRectangle(width, spacer.l, 2)

    this.models.spacer.layer = layerNames.cutout.label
  }
}
