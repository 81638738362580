const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class JHandle {
  constructor(width, height) {
    this.models = {}

    this.handleWidth = parseFloat(width)
    this.handleHeight = parseFloat(height)

    this.handleCornerRadius = 2
    this.screwHoleHeight = 9
    this.screwHoleRadius = 1.5

    // calculate spacing and hole count
    this.screwHoleCount = null
    this.screwHoleXoffset = null
    this.screwHoleSpacing = null

    //change i for hole spacing
    for (let i = 60; i < 92; i++) {
      this.screwHoleSpacing = i
      // change j for gap from edge
      for (let j = 8; j < 15; j += 0.5) {
        this.screwHoleXoffset = j
        this.screwHoleCount =
          (this.handleWidth - 2 * this.screwHoleXoffset + this.screwHoleSpacing) /
          (2 * this.screwHoleRadius + this.screwHoleSpacing)
        if (this.screwHoleCount % 1 == 0) {
          break
        }
      }
      if (this.screwHoleCount % 1 == 0) {
        break
      }
    }

    this.generateJHandle()
  }

  generateJHandle() {
    // check if j-handle
    this.models.outline = {
      layer: layerNames.insertCutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.handleWidth, 0]
        },
        line2: {
          type: 'line',
          origin: [this.handleWidth, 0],
          end: [this.handleWidth, this.handleHeight]
        },
        line3: {
          type: 'line',
          origin: [this.handleWidth, this.handleHeight],
          end: [0, this.handleHeight]
        },
        line4: {
          type: 'line',
          origin: [0, this.handleHeight],
          end: [0, 0]
        }
      }
    }

    if (this.handleHeight == 52.5) {
      this.models.outline.paths.arc1 = makerjs.path.fillet(
        this.models.outline.paths.line3,
        this.models.outline.paths.line2,
        this.handleCornerRadius
      )
      this.models.outline.paths.arc2 = makerjs.path.fillet(
        this.models.outline.paths.line3,
        this.models.outline.paths.line4,
        this.handleCornerRadius
      )
    }
    // array for all screw hole position
    const screwHolesPos = []
    let nextHolePos
    nextHolePos = this.screwHoleXoffset + 1.5
    screwHolesPos.push([nextHolePos, this.screwHoleHeight]) // most left screw
    if (this.screwHoleCount > 2) {
      for (let num = 0; num < this.screwHoleCount - 2; num++) {
        nextHolePos += this.screwHoleSpacing + 3
        screwHolesPos.push([nextHolePos, this.screwHoleHeight])
      }
    }
    screwHolesPos.push([this.handleWidth - this.screwHoleXoffset - 1.5, this.screwHoleHeight]) // most right screw
    // add screw holes
    this.models.screwHoles = new makerjs.models.Holes(this.screwHoleRadius, screwHolesPos)
    this.models.screwHoles.layer = layerNames.insertCountersink.label

    if (this.handleHeight > 52.5) {
      this.models.bracketHoles = makerjs.model.mirror(this.models.screwHoles, false, true)
      makerjs.model.moveRelative(this.models.bracketHoles, [0, this.handleHeight])
    }
  }
}
