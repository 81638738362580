var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class JProfile {
  constructor(front, width, twoPart) {
    // common
    this.models = {}
    this.width = width
    this.height = 25
    this.insetX = 0
    this.insetY = 26
    this.front = front

    this.pocketHeight = 31.5

    // unique
    this.outlineOvershoot = 0
    this.outerRadius = 1
    this.innerRadius = 2
    this.pocketOffsetLarge = 19.5
    this.pocketOffsetSmall = 5

    if (twoPart) {
      this.generateTwoPartVariation()
    } else {
      this.generateProductionPaths()
    }
  }

  generateProductionPaths() {
    this.models.corebox = {
      layer: layerNames.corebox.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.width, 0]
        }
      }
    }

    this.models.pocket = new makerjs.models.Rectangle(this.width + 10, -this.pocketHeight)
    makerjs.model.move(this.models.pocket, [-5, 5])
    this.models.pocket.layer = layerNames.cbHandlePocket.label
  }

  generateTwoPartVariation() {
    const twoPartInset = 50
    const twoPartHeight = 26
    const twoPartPocketHeight = 52.5

    this.models.pocket = new makerjs.models.Rectangle(this.width + 10, twoPartPocketHeight + this.pocketOffsetSmall)
    this.models.pocket.layer = layerNames.cbHandlePocket.label
    makerjs.model.move(this.models.pocket, [-5, -twoPartPocketHeight])

    this.models.corebox = {
      layer: layerNames.corebox.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.width - twoPartInset, 0],
          end: [this.width - twoPartInset, -twoPartHeight]
        },
        line2: {
          type: 'line',
          origin: [this.width - twoPartInset, -twoPartHeight],
          end: [0, -twoPartHeight]
        }
      }
    }

    this.front.handleOutlineA = {
      layer: layerNames.cutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [50, 0],
          end: [50, -twoPartHeight - this.outerRadius]
        },
        arc2: {
          type: 'arc',
          origin: [50 + this.outerRadius, -twoPartHeight - this.outerRadius],
          radius: this.outerRadius,
          startAngle: 90,
          endAngle: 180
        },
        line2: {
          type: 'line',
          origin: [50 + this.outerRadius, -twoPartHeight],
          end: [this.width - this.outerRadius, -twoPartHeight]
        },
        arc3: {
          type: 'arc',
          origin: [this.width - this.outerRadius, -twoPartHeight + this.outerRadius],
          radius: this.outerRadius,
          startAngle: 270,
          endAngle: 0
        },
        line3: {
          type: 'line',
          origin: [this.width, -twoPartHeight + this.outerRadius],
          end: [this.width, -this.outerRadius]
        },
        arc4: {
          type: 'arc',
          origin: [this.width + this.outerRadius, -this.outerRadius],
          radius: this.outerRadius,
          startAngle: 90,
          endAngle: 180
        },
        line4: {
          type: 'line',
          origin: [this.width + this.outerRadius, 0],
          end: [50, 0]
        }
      }
    }
  }
}
