<template>
  <v-row v-if="order">
    <v-col cols="12" md="8" offset-md="2">
      <div v-if="newOrder" id="order-confirmation" class="text-center">
        <div v-if="form.bankTransfer">
          <h2 class="mb-3">
            Nearly done...
          </h2>
          <p>
            We have sent an email containing details on how pay for your order to <b>{{ form.email }}</b>
          </p>
          <p>
            Your order will be moved into production once we receive payment. You will then also receive an order
            confirmation email confirming estimated shipping dates.
          </p>
        </div>
        <div v-else>
          <h2 class="mb-3">
            Your order has been placed!<br />Order Number
            {{ order.orderNumber }}
          </h2>
          <div v-if="!foc && !form.bankTransfer">
            <p>
              A confirmation email will be sent to <b>{{ form.email }}</b>
            </p>
            <p>
              If you've not received the confirmation email please check your junk folder.
            </p>
          </div>
        </div>
      </div>
      <div v-else id="order-confirmation" class="text-center">
        <h2 class="mb-3">Your payment has been successfully processed.</h2>
        <p>
          The member of our quote team dealing with your order has been informed that the payment has been made 👍
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Confirmation',
  props: ['order', 'form', 'newOrder', 'foc'],
  mounted() {
    if (!this.foc) {
      window.dataLayer.push({
        event: 'checkout',
        checkoutProduct: 'kitchen',
        checkoutCurrency: this.order.currency,
        checkoutValue: this.order.total
      })
    }
  }
}
</script>

<style lang="scss">
#order-confirmation {
  h2 {
    color: $pinkLink;
  }
}
</style>
