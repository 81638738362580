<template>
  <v-card>
    <v-card-title class="primary white--text">Recut Tracker</v-card-title>
    <v-card-text class="pt-4">
      <v-form ref="recutForm">
        <v-container class="pa-0">
          <v-row>
            <v-col cols="6">
              <v-select
                label="Cause(s)"
                :items="cockupSettings.types"
                item-text="name"
                item-value="id"
                v-model="recutForm.cause"
                :rules="[v => !!v || 'Cause is required']"
                multiple
              >
              </v-select>
              <v-checkbox v-model="recutForm.lucidCoverCost" label="Lucid to cover recut costs"></v-checkbox>
              <v-text-field v-if="!quote" v-model="recutForm.orderNumber" label="Order Number"></v-text-field>
              <v-text-field
                v-if="!quote && !version"
                v-model.number="recutForm.itemCount"
                label="Number of items"
              ></v-text-field>
            </v-col>
            <v-col cols="5" offset="1" v-if="quote && version">
              <v-switch v-model="empty" label="Create empty recut"></v-switch>
              <v-switch v-model="recutForm.materialRequired" label="Material order required"></v-switch>
              <v-switch v-model="recutForm.holdOriginalOrder" label="Send recut with original order"></v-switch>
            </v-col>
          </v-row>
        </v-container>
        <v-textarea
          v-model="recutForm.description"
          label="Problem"
          outlined
          :validate-on-blur="true"
          :rules="[v => !!v || 'Description is required']"
        ></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text dark @click="cancel">Cancel</v-btn>
      <v-btn color="accent" depressed :dark="!submitting" @click="submit" :disabled="submitting">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script type="text/javascript">
export default {
  name: 'RecutTracker',
  props: ['quote', 'version'],
  data() {
    return {
      submitting: false,
      recutForm: {},
      cockupSettings: [],
      empty: true
    }
  },
  created() {
    this.$bind('cockupSettings', this.$db.collection('settings').doc('cockup-settings'))
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    resetForm() {
      this.recutForm = {
        date: this.$firebase.firestore.Timestamp.now(),
        materialRequired: false,
        description: null,
        recutID: null,
        lucidCoverCost: null,
        holdOriginalOrder: false,
        createdBy: this.$store.state.user.email,
        orderNumber: null,
        itemCount: null,
        // if created from recut populate data;./
        customerEmail: this.quote ? this.quote.customerEmail : null,
        quoteID: this.quote ? this.quote.id : null,
        value: this.version ? this.version.pricing.total : null
      }
    },
    async submit() {
      this.submitting = true
      if (this.$refs.recutForm.validate()) {
        this.submitting = true
        const doc = this.$db.collection('cockUps').doc()
        await doc.set(this.recutForm)
        this.$emit('complete', doc.id, this.empty, this.recutForm.lucidCoverCost)
        this.resetForm()
      }
    }
  }
}
</script>
