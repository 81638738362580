<template>
  <v-card>
    <v-card-title class="warning white--text title">Add Sample</v-card-title>
    <v-card-text class="mt-3">
      <v-form ref="sampleForm">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="form.materials"
              class="no-padding"
              required
              hide-details
              placeholder="Materials"
              :rules="[v => !!v || 'You must select the materials you want']"
              :items="[
                { text: 'Veneer', value: 'veneer' },
                { text: 'Laminate', value: 'laminate' },
                { text: 'Both', value: 'both' }
              ]"
            >
            </v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="form.firstName"
              class="no-padding"
              required
              placeholder="First Name"
              :rules="[v => !!v || 'First name is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="form.lastName"
              class="no-padding"
              required
              placeholder="Last Name"
              :rules="[v => !!v || 'Last name is required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          v-model="form.email"
          required
          :rules="emailRules"
          :validate-on-blur="true"
          placeholder="Email"
        ></v-text-field>
        <v-text-field
          v-model="form.addressLine1"
          required
          placeholder="Address Line 1"
          :rules="[v => !!v || 'Address Line 1 is required']"
        ></v-text-field>
        <v-text-field v-model="form.addressLine2" placeholder="Address Line 2"></v-text-field>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="form.city"
              required
              placeholder="City"
              :rules="[v => !!v || 'City is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="form.postcode"
              required
              name="postal-code"
              placeholder="Postcode"
              :rules="[v => !!v || 'Postcode is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('cancel', form)">Cancel</v-btn>
      <v-btn color="primary" depressed dark @click="validate">Add Sample</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ukSampleForm',
  data() {
    return {
      feedBackText: null,
      form: {
        materials: null,
        firstName: null,
        lastName: null,
        email: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        postcode: null,
        country: 'GB'
      },
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']
    }
  },
  methods: {
    validate() {
      if (this.$refs.sampleForm.validate()) {
        this.$emit('add', this.form)
      }
    }
  }
}
</script>
