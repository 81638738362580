<template>
  <div id="material-settings">
    <div id="view-sub-nav">
      <v-btn
        depressed
        outlined
        color="primary"
        @click="
          () => {
            materialDialog = true
          }
        "
        >Create New Material</v-btn
      >
      <v-spacer />
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      v-if="materials"
      :items="materials"
      :loading="loading"
      :headers="headers"
      sort-by="text"
      :items-per-page="25"
      :search="tableSearch"
      id="materials-table"
    >
      <template v-slot:item.hasGrain="{ item }">
        {{ item.hasGrain ? 'True' : 'False' }}
      </template>
      <template v-slot:item.colours="{ item }">
        {{ item.colours ? item.colours.length : '-' }}
      </template>
      <template v-slot:item.sizes="{ item }">
        {{ item.sizes.length }}
      </template>
      <template v-slot:item.actions="{ item }">
        <td class="d-flex justify-end align-center">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="
                  () => {
                    selectedMaterial = item
                    newMaterialForm = item
                    materialDialog = true
                  }
                "
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Edit Material</span>
          </v-tooltip>
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                @click="
                  () => {
                    selectedMaterial = item
                    sizesDialog = true
                  }
                "
                v-on="on"
                >mdi-image-size-select-small</v-icon
              >
            </template>
            <span>Edit Sheets</span>
          </v-tooltip>
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                :disabled="item.hasGrain"
                small
                class="mr-2"
                @click="
                  () => {
                    selectedMaterial = item
                    coloursDialog = true
                  }
                "
                v-on="on"
                >mdi-palette</v-icon
              >
            </template>
            <span>Edit Colours</span>
          </v-tooltip>
          <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                @click="
                  () => {
                    deleteMaterialDialog = true
                    selectedMaterial = item
                  }
                "
                v-on="on"
                >mdi-delete</v-icon
              >
            </template>
            <span>Delete Material</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>

    <!-- Sizes -->
    <v-dialog
      v-model="sizesDialog"
      v-if="selectedMaterial"
      fullscreen
      transition="slide-x-reverse-transition"
      origin="align-center"
      :max-width="300"
      attach="#materials-table"
      @input="onSizesClose"
    >
      <v-card class="mb-3 elevation-0">
        <v-card-title class="subtitle-1">
          <div class="subheading">{{ selectedMaterial.text }} Sheet Sizes</div>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              () => {
                selectedMaterial.sizes.push({
                  w: null,
                  h: null,
                  thickness: null,
                  cost: null
                })
              }
            "
            >mdi-plus</v-icon
          >
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row v-for="(sheet, key) in selectedMaterial.sizes" :key="key">
            <v-col cols="2" v-if="!selectedMaterial.hasGrain">
              <v-select
                hide-details
                v-model.number="sheet.sides"
                :items="[
                  { text: 'Single', value: 1 },
                  { text: 'Double', value: 2 }
                ]"
                label="Sides"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field hide-details v-model.number="sheet.w" label="Width (long length)"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field hide-details v-model.number="sheet.h" label="Height"></v-text-field>
            </v-col>
            <v-col :cols="selectedMaterial.hasGrain ? 3 : 2">
              <v-text-field hide-details v-model.number="sheet.thickness" label="Thickness"></v-text-field>
            </v-col>
            <v-col :cols="selectedMaterial.hasGrain ? 3 : 2" class="d-flex align-baseline">
              <v-text-field hide-details v-model.number="sheet.cost" label="Cost" prefix="£"></v-text-field>
              <v-icon class="ml-2" @click="deleteSize(selectedMaterial, key)">mdi-delete</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- COLOURS -->
    <v-dialog
      v-model="coloursDialog"
      v-if="selectedMaterial"
      fullscreen
      transition="slide-x-reverse-transition"
      origin="align-center"
      :max-width="300"
      attach="#materials-table"
      @input="onColoursClose"
    >
      <v-card>
        <v-card-title class="subtitle-1">
          <div class="subheading">{{ selectedMaterial.text }} Colours</div>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              () => {
                selectedMaterial.colours.push({
                  value: null,
                  text: null,
                  hex: '#FFFFFF'
                })
              }
            "
            >mdi-plus</v-icon
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <v-row v-for="(colour, key) in selectedMaterial.colours" :key="key">
            <v-col cols="5">
              <v-text-field hide-details v-model="colour.value" label="Value"></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field hide-details v-model="colour.text" label="Name"></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="1" class="d-flex align-center justify-end">
              <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <div class="colour" :style="{ 'background-color': colour.hex }" v-bind="attrs" v-on="on"></div>
                </template>
                <v-color-picker
                  v-model="colour.hex"
                  label="Colour"
                  color-pick
                  mode="hexa"
                  hide-mode-switch
                ></v-color-picker>
              </v-menu>
              <v-icon @click="deleteColour(selectedMaterial, key)">mdi-delete</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CREATE -->
    <v-dialog v-model="materialDialog" width="800">
      <v-card>
        <v-card-title class="primary white--text title">
          {{ selectedMaterial ? 'Edit Material' : 'Create New Material' }}
        </v-card-title>
        <v-card-text>
          <v-form ref="newForm">
            <v-text-field
              :disabled="selectedMaterial !== null"
              label="Code"
              v-model="newMaterialForm.value"
              :rules="[v => !!v || 'Code is required - as Tim if you do not know what this should be!']"
            ></v-text-field>
            <v-text-field
              label="Name"
              v-model="newMaterialForm.text"
              :rules="[v => !!v || 'Name is required']"
            ></v-text-field>
            <v-select
              v-model="newMaterialForm.regions"
              :items="regions"
              label="Regions"
              :rules="[v => !!v || 'Regions required']"
              multiple
              chips
            ></v-select>
            <v-checkbox v-model="newMaterialForm.hasGrain" label="Has Grain"></v-checkbox>
            <v-color-picker
              v-if="newMaterialForm.hasGrain"
              v-model="newMaterialForm.texture.hex"
              :rules="[v => !!v || 'Colour is required']"
              label="Colour"
              color-pick
              mode="hexa"
              hide-mode-switch
            ></v-color-picker>
            <v-text-field
              v-if="newMaterialForm.hasGrain"
              label="Image"
              v-model="newMaterialForm.texture.image"
              :rules="[v => !!v || 'Image is required']"
            ></v-text-field>
            <v-text-field v-if="formError" v-model="formError" error outlined readonly> </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                materialDialog = false
                resetNewMaterialValues()
              }
            "
            >Cancel</v-btn
          >
          <v-btn
            color="accent"
            depressed
            dark
            @click="selectedMaterial ? updateMaterial() : createNewMaterial()"
            :disabled="submitting"
            >{{ selectedMaterial ? 'Update' : 'Create' }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE -->
    <v-dialog v-model="deleteMaterialDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Delete Material</v-card-title>
        <v-card-text class="pt-6">
          Are you sure you want to delete this material?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteMaterialDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="deleteMaterial(selectedMaterial)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MaterialSettings',
  data() {
    return {
      loading: true,
      materialData: null,
      tableSearch: null,
      formError: null,
      headers: [
        {
          text: 'Name',
          sortable: false,
          value: 'text'
        },
        {
          text: 'Regions',
          sortable: false,
          value: 'regions'
        },
        {
          text: 'Wood Veneer',
          sortable: false,
          value: 'hasGrain'
        },
        {
          text: 'Colours',
          sortable: false,
          value: 'colours'
        },
        {
          text: 'Sheet Sizes',
          sortable: false,
          value: 'sizes'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ],
      sizesDialog: false,
      coloursDialog: false,
      deleteMaterialDialog: false,
      //
      materialDialog: false,
      submitting: false,
      selectedMaterial: null,
      regions: [
        { text: 'UK', value: 'uk' },
        { text: 'EU', value: 'eu' },
        { text: 'US', value: 'us' }
      ],
      newMaterialForm: {},
      materials: null
    }
  },
  async mounted() {
    this.resetNewMaterialValues()
    await this.$bind('materials', this.$db.collection('materials')).then(() => {
      this.loading = false
    })
  },
  methods: {
    /*
		async updateMaterialColours(){
			for(let material of this.materials){
				if(material.colours && material.colours[0].hex.hex){
					// console.log('Old', material.colours)
					for(let colour of material.colours){
						colour.hex = colour.hex.hex
					}
					
					await this.$db.collection('materials').doc(material.id).update({
						colours: material.colours
					})
				}
			}
		},
		*/
    resetNewMaterialValues() {
      this.newMaterialForm = {
        value: null,
        text: null,
        regions: null,
        stock: null,
        texture: {
          hex: '#FFFFFF',
          image: null,
          repeat: { u: 1, v: 1 }
        },
        hasGrain: null,
        sizes: []
      }
      this.formError = null
    },
    async createNewMaterial() {
      if (this.$refs.newForm.validate()) {
        this.submitting = true

        if (!this.newMaterialForm.hasGrain) {
          this.newMaterialForm.colours = []
        }

        // check material ID not it use
        const snap = await this.$db
          .collection('materials')
          .where('value', '==', this.newMaterialForm.value)
          .get()
        if (snap.empty == false) {
          console.log(snap)
          this.formError = 'ID already in use by another material'
          this.submitting = false
          return
        }

        const newDocRef = this.$db.collection('materials').doc()
        await newDocRef.set(this.newMaterialForm)

        this.resetNewMaterialValues()
        this.submitting = false
        this.materialDialog = false
      }
    },
    async updateMaterial() {
      this.submitting = true
      await this.$db
        .collection('materials')
        .doc(this.selectedMaterial.id)
        .update(this.newMaterialForm)

      this.resetNewMaterialValues()
      this.submitting = false
      this.materialDialog = false
    },
    deleteMaterial(material) {
      this.deleteMaterialDialog = false
      this.$db
        .collection('materials')
        .doc(material.id)
        .delete()
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Material Deleted'
          })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    async deleteColour(material, index) {
      material.colours.splice(index, 1)
      await this.$db
        .collection('materials')
        .doc(material.id)
        .update({
          colours: material.colours
        })
      this.$store.commit('openSnackbar', { snackbarText: 'Colour Deleted' })
    },
    async deleteSize(material, index) {
      material.sizes.splice(index, 1)
      await this.$db
        .collection('materials')
        .doc(material.id)
        .update({
          sizes: material.sizes
        })
      this.$store.commit('openSnackbar', { snackbarText: 'Sheet Size Deleted' })
    },
    // TODO only save when updated - need to track changed param locally with emitters from dialog fields
    async onColoursClose() {
      await this.$db
        .collection('materials')
        .doc(this.selectedMaterial.id)
        .update({
          colours: this.selectedMaterial.colours
        })
      this.$store.commit('openSnackbar', { snackbarText: 'Colours Updated' })
    },
    async onSizesClose() {
      await this.$db
        .collection('materials')
        .doc(this.selectedMaterial.id)
        .update({
          sizes: this.selectedMaterial.sizes
        })
      this.$store.commit('openSnackbar', { snackbarText: 'Sizes Updated' })
    }
  }
}
</script>

<style lang="scss" scoped>
.colour {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border: solid 1px #999;
  border-radius: 50%;
  margin-right: 5px;
}

#material-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  #materials-table {
    flex: 1;
    position: relative;
  }
}

::v-deep .v-dialog__content {
  position: absolute;
  .v-dialog {
    padding-left: 15px;
    position: absolute;
    left: auto;
    right: 0;
    width: 50%;
    box-shadow: none;
    &:before {
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      width: 15px;
      box-shadow: -15px 0 15px -15px inset rgb(0 0 0 / 15%);
      left: 0;
      z-index: 1;
    }
    // box-shadow: -5px 0px 15px 0px rgb(0 0 0 / 15%)
  }
}
</style>
