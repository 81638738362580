const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class Door {
  constructor(urtil, carcassThickness, doorInfo, sideRebateInfo, engravingInfo, doorSide) {
    this.models = { left: { models: {} }, right: { models: {} } }
    //VARS
    this.urtil = urtil
    this.carcassThickness = carcassThickness
    this.doorHeight = doorInfo?.doorHeight
    this.engravingInfo = engravingInfo
    this.urtilDoorHandleRad = 17.5
    this.urtilDoorHandleOffset = 45

    //CALCS
    this.internalVoid = urtil.w - carcassThickness * 2 - (urtil.compartments - 1) * carcassThickness

    switch (doorSide) {
      case 'left':
        this.leftDoorWidth =
          (this.internalVoid / urtil.compartments) * urtil.doorCoverageOne +
          carcassThickness * urtil.doorCoverageOne +
          sideRebateInfo?.depth +
          doorInfo?.overlap
        this.createDoorLeft()
        break
      case 'right':
        this.rightDoorWidth =
          (this.internalVoid / urtil.compartments) * urtil.doorCoverageTwo +
          carcassThickness * urtil.doorCoverageTwo +
          sideRebateInfo?.depth +
          doorInfo?.overlap
        this.createDoorRight()
        break
    }
  }

  createDoorLeft() {
    let left = this.models.left.models
    left.outline = new makerjs.models.Rectangle(this.leftDoorWidth, this.doorHeight)
    left.outline.layer = layerNames.urtilDoorCutout.label
    left.hole = new makerjs.models.Ellipse(this.urtilDoorHandleRad, this.urtilDoorHandleRad)
    left.hole.layer = layerNames.urtilDoorHandle.label
    makerjs.model.move(left.hole, [this.leftDoorWidth - this.urtilDoorHandleOffset, this.urtilDoorHandleOffset])
    //CHANGE DOOR SPACING TO 11mm??
    // makerjs.model.move(left, [this.urtil.w * 2 + 11, 0])

    // add Urtil Label and Left Door Mark in Notes layer
    let leftText = new makerjs.models.Text(this.engravingInfo.font, 'LEFT - ' + this.engravingInfo.label, 50)
    left.leftDoorText = makerjs.model.mirror(leftText, true, false)
    left.leftDoorText.layer = layerNames.warning.label

    let textMeasurements = makerjs.measure.modelExtents(left.leftDoorText)
    makerjs.model.moveRelative(left.leftDoorText, [
      this.leftDoorWidth / 2 - textMeasurements.center[0],
      this.doorHeight / 2 - textMeasurements.center[1]
    ])
  }

  createDoorRight() {
    let right = this.models.right.models
    right.outline = new makerjs.models.Rectangle(this.rightDoorWidth, this.doorHeight)
    right.outline.layer = layerNames.urtilDoorCutout.label
    right.hole = new makerjs.models.Ellipse(this.urtilDoorHandleRad, this.urtilDoorHandleRad)
    right.hole.layer = layerNames.urtilDoorHandle.label
    makerjs.model.move(right.hole, [this.urtilDoorHandleOffset, this.urtilDoorHandleOffset])
    // makerjs.model.move(right, [-22 - this.urtil.w, 0])

    // add Urtil Label and Right Door Mark in Notes layer
    let rightText = new makerjs.models.Text(this.engravingInfo.font, 'RIGHT - ' + this.engravingInfo.label, 50)
    right.rightDoorText = makerjs.model.mirror(rightText, true, false)
    right.rightDoorText.layer = layerNames.warning.label

    let textMeasurements = makerjs.measure.modelExtents(right.rightDoorText)
    makerjs.model.moveRelative(right.rightDoorText, [
      this.rightDoorWidth / 2 - textMeasurements.center[0],
      this.doorHeight / 2 - textMeasurements.center[1]
    ])
  }
}
