<template>
  <v-card>
    <v-card-title class="primary title white--text align-baseline">Adjust Available Capacity</v-card-title>
    <v-card-text class="mt-6">
      <v-text-field label="Available hours" v-model.number="data.hours" @change="$emit('change')"></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AdjustmentDetail',
  props: ['data']
}
</script>
