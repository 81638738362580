import paper from 'paper'
import utils from '@/js/utils'
import GrabHandle from '@/js/cad/customer/handles/GrabHandle'
import SemiRecessedGrab from '@/js/cad/customer/handles/SemiRecessedGrab'
import Circle from '@/js/cad/customer/handles/Circle'
import SemiRecessedCircle from '@/js/cad/customer/handles/SemiRecessedCircle'
import JProfile from '@/js/cad/customer/handles/JProfile'
import EdgePull from '@/js/cad/customer/handles/EdgePull'
import DPull from '@/js/cad/customer/handles/DPull'
import HalfQuartArc from '@/js/cad/customer/handles/HalfQuartArc'

export default class Front {
  constructor(name, data, omitLabel, region, grainDirection) {
    // PRIVATE - OR ARE THEY?
    this.data = data
    this.region = region
    this.grainDirection = grainDirection

    this.width = data.w
    this.height = data.h

    this.cornerRadius = 5
    this.invisipullExtension = 30
    this.invisipullDimensionExtension = region == 'uk' ? 30 : 1.181
    this.labelPadding = 50
    this.labelSize = 40

    this.createFront()
    var label = omitLabel ? null : this.getLabel()
    this.dimension = this.getDimension()

    if (data.type !== 'blank') {
      var hinge = this.createHinge()
    }

    this.outline.name = 'outline'
    this.dimension.name = 'dimension'
    this.grain = this.getGrainDirection()
    let itemGroup = [this.outline, hinge, label, this.dimension]
    if (this.grain) {
      this.grain.name = 'grain'
      itemGroup.push(this.grain)
    }
    this.item = new paper.Group([...itemGroup])
    this.item.name = name
    this.item.data.originalBounds = this.item.bounds

    if (data.joiningPos) {
      const joining = this.getJoined()
      joining.name = 'joining'
      this.item.addChild(joining)
    }
  }

  createFront() {
    // console.log('Creating Front', this.data.w, this.data.h)
    let rectangle = new paper.Rectangle(
      new paper.Point(0, 0),
      new paper.Size(parseFloat(this.data.w), parseFloat(this.data.h))
    )

    let handleType = this.data.handle.value
    let insertMaterial = this.data.handle.insertMaterial
    let handleWidth
    switch (handleType) {
      case 'semiGrab':
        handleWidth = 140
        break
      case 'midSemiGrab':
        handleWidth = 200
        break
      case 'lrgSemiGrab':
        handleWidth = 400
        break
      case 'lrgHalfArc':
        handleWidth = 105
        break
      case 'halfArc':
        handleWidth = 90
        break
      case 'lrgQuartArc':
        handleWidth = 52.5
        break
      case 'quartArc':
        handleWidth = 45
        break
    }

    switch (handleType) {
      case 'none':
        // dp nothing innit
        break
      case 'grab':
        this.handle = new GrabHandle()
        break
      case 'semiGrab':
      case 'midSemiGrab':
      case 'lrgSemiGrab':
        this.handle = new SemiRecessedGrab(insertMaterial, handleWidth)
        break
      case 'lrgHalfArc':
      case 'halfArc':
      case 'lrgQuartArc':
      case 'quartArc':
        this.handle = new HalfQuartArc(insertMaterial, handleWidth, handleType)
        break
      case 'circle':
        this.handle = new Circle()
        break
      case 'semiCir':
        this.handle = new SemiRecessedCircle(insertMaterial)
        break
      case 'j':
        var w = this.data.handle.orientation.charAt(0) == 'v' ? this.data.h : this.data.w
        this.handle = new JProfile(insertMaterial, w, this.cornerRadius)
        break
      case 'edge-br-s':
      case 'edge-br-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#D8C384')
        break
      case 'edge-br-l':
      case 'edge-br-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#D8C384')
        break
      case 'edge-bl-s':
      case 'edge-bl-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#000000')
        break
      case 'edge-bl-l':
      case 'edge-bl-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#000000')
        break
      case 'edge-w-s':
      case 'edge-w-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#ffffff')
        break
      case 'edge-w-l':
      case 'edge-w-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#ffffff')
        break
      case 'edge-sn-s':
      case 'edge-sn-s-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 75, '#e0e0e0')
        break
      case 'edge-sn-l':
      case 'edge-sn-l-r':
        this.handle = new EdgePull(this.data.w, this.data.h, 200, '#e0e0e0')
        break
      case 'dpull-br-s':
        this.handle = new DPull(this.data.w, this.data.h, 137, '#D8C384')
        break
      case 'dpull-br-l':
        this.handle = new DPull(this.data.w, this.data.h, 201, '#D8C384')
        break
      case 'dpull-bl-s':
        this.handle = new DPull(this.data.w, this.data.h, 137, '#000000')
        break
      case 'dpull-bl-l':
        this.handle = new DPull(this.data.w, this.data.h, 201, '#000000')
        break
      case 'dpull-w-s':
        this.handle = new DPull(this.data.w, this.data.h, 137, '#ffffff')
        break
      case 'dpull-w-l':
        this.handle = new DPull(this.data.w, this.data.h, 201, '#ffffff')
        break
      case 'invisipull':
        // just need to extend the bottom of the door 30mm
        rectangle = rectangle.expand(0, this.invisipullExtension)
        break
      case 'invisipull-short':
        // Don't do anything - it's invisible!
        break
      default:
        console.log('Handle type not implemented in Customer CAD', handleType)
    }

    var cornerSize = new paper.Size(this.cornerRadius, this.cornerRadius)
    var front = new paper.Path.Rectangle(rectangle, cornerSize)
    front.fillColor = this.data.material.uiColour
    front.strokeColor = utils.isColourDark(this.data.material.uiColour) ? 'white' : 'black'

    if (this.handle) {
      this.positionHandle()
    }
    if (this.handle && handleType == 'grab') {
      this.outline = front.subtract(this.handle.path)
      front.remove()
      this.handle.path.remove()
      this.outline.fillColor = this.data.material.uiColour
      this.outline.strokeColor = utils.isColourDark(this.data.material.uiColour) ? 'white' : 'black'
    } else {
      var handlePath = this.handle ? this.handle.path : null
      this.outline = new paper.Group([front, handlePath])
    }
  }

  positionHandle() {
    let xPos, yPos
    let rotation
    let cabinet = this.data.cabinet
    let frontType = this.data.type
    let handleType = this.data.handle.value
    let orientation = this.data.handle.orientation
    let handleWidth = this.handle.width
    let handleHeight = this.handle.height
    let insetX = this.handle.insetX
    let insetY = this.handle.insetY
    let path = this.handle.path

    // UPSIDE DOWN HANDLES
    if (orientation == 'updn') {
      path.rotate(180)
      xPos = this.data.w / 2
      yPos = this.data.h - this.handle.height / 2 - this.handle.insetY
    }

    // DRAWERS
    else if (frontType == 'drw') {
      xPos = this.data.w / 2
      yPos = handleHeight / 2 + insetY
    }

    // ALL VERTICALS
    else {
      if (orientation.charAt(0) == 'v') {
        // rotate
        rotation = frontType == 'lhd' ? 90 : 270
        path.rotate(rotation)
        xPos = frontType == 'lhd' ? this.data.w - handleHeight / 2 - insetY : handleHeight / 2 + insetY

        if (orientation == 'vc') {
          yPos = this.data.h / 2
        } else if (orientation == 'vbt') {
          yPos = this.data.h - 800 + handleWidth / 2 + insetX
        } else if (orientation == 'vwb') {
          yPos = this.data.h - handleWidth / 2 - 800 - insetX
        } else {
          yPos = cabinet == 'b' ? handleWidth / 2 + insetX : this.data.h - handleWidth / 2 - insetX
          if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
            yPos = cabinet == 'b' ? handleWidth + insetX : this.data.h - handleWidth - insetX
          }
        }
      }

      // ALL HORIZONTALS
      else if (orientation == 'h' && frontType != 'bhd' && frontType != 'thd') {
        if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
          if (cabinet == 'w') {
            rotation = frontType == 'lhd' ? 180 : -90
          } else {
            rotation = frontType == 'lhd' ? 90 : 0
          }
          path.rotate(rotation)
        } else if ((handleType != 'quartArc' || handleType != 'lrgQuartArc') && cabinet == 'w') {
          path.rotate(180)
        }

        if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
          xPos = frontType == 'lhd' ? this.data.w - handleWidth / 2 - insetY : handleWidth / 2 + insetY
          yPos = cabinet == 'w' ? this.data.h - handleHeight / 2 - insetY : handleHeight / 2 + insetY
        } else {
          xPos = frontType == 'lhd' ? this.data.w - handleWidth / 2 - insetX : handleWidth / 2 + insetX
          yPos = cabinet == 'w' ? this.data.h - handleHeight / 2 - insetY : handleHeight / 2 + insetY
        }

        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          xPos = frontType == 'lhd' ? this.data.w - handleWidth - insetX : handleWidth + insetX
        }
      } else if (orientation == 'c' || frontType == 'bhd' || frontType == 'thd') {
        if (cabinet == 'w') path.rotate(180)
        xPos = this.data.w / 2
        yPos = cabinet == 'w' ? this.data.h - handleHeight / 2 - insetY : handleHeight / 2 + insetY
      }
    }
    path.position = new paper.Point(xPos, yPos)
  }

  createHinge() {
    if (this.data.type == 'drw') return null

    var size = 150
    var s = new paper.Point(-size / 2, -size / 2)
    var m = new paper.Point(-30, 30)
    var e = new paper.Point(size / 2, size / 2)

    var vector = e.subtract(s).normalize(30)
    var arrow = new paper.Group([
      new paper.Path.Arc(s, m, e),
      new paper.Path([
        e.add(vector.rotate(100)),
        e,
        e.add(vector.rotate(185)) //top
      ])
    ])
    arrow.strokeWidth = 1
    arrow.selectedColor = 'transparent'
    // console.log(arrow.settings.handleSize)

    arrow.strokeColor = utils.isColourDark(this.data.material.uiColour) ? 'white' : 'black'

    switch (this.data.type) {
      case 'lhd':
        arrow.scale(-1, 1)
        arrow.translate(new paper.Point(this.data.w - size / 2, this.data.h / 2))
        break
      case 'rhd':
        arrow.translate(new paper.Point(size / 2, this.data.h / 2))
        break
      case 'bhd':
        arrow.rotate(90, s)
        arrow.translate(new paper.Point(this.data.w / 2 + size, size / 2))
        break
      case 'thd':
        arrow.rotate(-90, s)
        arrow.translate(new paper.Point(this.data.w / 2, this.data.h + size / 2))
        break
    }
    return arrow
  }

  getLabel() {
    var text = new paper.PointText(
      new paper.Point(this.data.w / 2, this.outline.bounds.height + this.labelSize + this.labelPadding)
    )
    text.justification = 'center'
    text.fillColor = 'black'
    text.selectedColor = 'transparent'
    text.fontSize = this.labelSize
    text.fontFamily = 'Roboto Mono'
    text.content = this.data.itemNum.toString().toUpperCase()
    return text
  }

  getDimension() {
    let width, height
    let handleType = this.data.handle.value
    let cabinetType = this.data.cabinet
    this.region == 'us' ? (width = this.data.w / 25.4) : (width = this.data.w)
    this.region == 'us' ? (height = this.data.h / 25.4) : (height = this.data.h)
    if (handleType == 'invisipull' && cabinetType == 'w') height += this.invisipullDimensionExtension

    let dimension = new paper.PointText(new paper.Point(this.data.w / 2, this.data.h / 2))
    dimension.justification = 'center'
    dimension.fillColor = 'transparent'
    dimension.selectedColor = 'transparent'
    dimension.fontSize = this.labelSize
    dimension.fontFamily = 'Roboto Mono'
    dimension.content = `W${parseFloat(width.toFixed(3))} x H${parseFloat(height.toFixed(3))}`
    if (this.data.h >= this.data.w) {
      dimension.rotate(-90)
      // dimension.translate(dimension.bounds.width / 4, 0)
    } else {
      dimension.translate(0, dimension.bounds.height / 2)
    }
    return dimension
  }

  getJoined() {
    let joinIconGroup = []

    const makeJoinIcon = (posX, posY, deg) => {
      let path = new paper.Path.Arc({
        to: [40, 40],
        through: [20, 20],
        from: [0, 40]
      })
      path.strokeColor = 'transparent'
      path.selectedColor = 'transparent'
      path.rotate(deg)
      path.position = new paper.Point(posX, posY)
      return path
    }

    const joinIconTopL = makeJoinIcon(this.data.w / 5, 10, 180)
    const joinIconTopR = makeJoinIcon((this.data.w * 4) / 5, 10, 180)
    const joinIconBotL = makeJoinIcon(this.data.w / 5, this.data.h - 10, 0)
    const joinIconBotR = makeJoinIcon((this.data.w * 4) / 5, this.data.h - 10, 0)

    switch (this.data.joiningPos) {
      case 'top':
        joinIconGroup.push(joinIconBotL, joinIconBotR)
        break
      case 'mid':
        joinIconGroup.push(joinIconTopL, joinIconTopR, joinIconBotL, joinIconBotR)
        break
      case 'bot':
        joinIconGroup.push(joinIconTopL, joinIconTopR)
        break
    }

    return new paper.Group(joinIconGroup)
  }

  getGrainDirection() {
    if (this.data.material.hasGrain && this.grainDirection) {
      const grainIconPath =
        'M36.875,74.49023a3.99582,3.99582,0,0,1,.626-5.61328A77.36856,77.36856,0,0,1,65.02979,56.11914c15.38134-3.84375,39.17675-4.78906,65.189,12.55274,18.82227,12.54785,38.5,16.42773,58.48682,11.52734a70.77906,70.77906,0,0,0,24.80175-11.32715,3.99975,3.99975,0,0,1,4.9917,6.251,77.36856,77.36856,0,0,1-27.52881,12.75781,77.24806,77.24806,0,0,1-18.71533,2.31152c-13.38574.001-29.4541-3.51758-46.47363-14.86426C106.959,62.7793,87.28076,58.89941,67.29443,63.80078A70.77906,70.77906,0,0,0,42.49268,75.12793,4.00386,4.00386,0,0,1,36.875,74.49023Z' +
        'm176.63232,50.38184a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.02051-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,124.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90234,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,131.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z' +
        'm0,56a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.01953-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,180.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90332,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,187.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z'

      const makeGrainIcon = (posX, posY, deg) => {
        const grainIcon = new paper.CompoundPath(grainIconPath)
        grainIcon.scale(0.3)
        grainIcon.rotate(deg)
        grainIcon.position = new paper.Point(posX, posY)
        grainIcon.strokeCap = 'round'
        grainIcon.fillColor = 'transparent'
        return grainIcon
      }

      let grainInfo
      if (Object.values(this.grainDirection)[0] === 'Vertical Grain') {
        grainInfo = makeGrainIcon(70, 70, -90)
      } else {
        grainInfo = makeGrainIcon(70, 70, 0)
      }
      return grainInfo
    } else {
      return null
    }
  }
}
