import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'

import router from './router'
import store from './store'
import utils from './js/utils'
import db from './js/db'

// Plugins
import vuetify from './plugins/vuetify'
import './plugins/numeral'
import './plugins/moment'

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

import './sass/global.scss' // Import global styles
import 'leaflet/dist/leaflet.css'

// add globally
Vue.prototype.$utils = utils
Vue.prototype.$firebase = firebase
Vue.prototype.$db = db

Vue.config.productionTip = false

import App from './App.vue'

let app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
})

firebase.auth().onAuthStateChanged(async user => {
  let userData
  if (user) {
    const userRef = await db
      .collection('users')
      .doc(user.uid)
      .get()

    if (userRef.exists) {
      userData = userRef.data()
      userData.isInit = true
      userData.isAuth = true
    } else {
      userData = {
        isInit: true,
        isAuth: false
      }
    }
  } else {
    userData = {
      isInit: true,
      isAuth: false
    }
  }

  store.commit('setUser', userData)

  // Run initial redirect if needed
  const { name, meta } = router.history.current
  if (meta.allowPublic) {
    if (name == 'login' && userData.isAuth) {
      router.push('/')
    }
  } else if (!userData.isAuth) {
    router.push('/user/login')
  } else if (name == 'dash' && !userData.permissions.uk) {
    router.push('/production')
  }

  app.$mount('#app')
})
