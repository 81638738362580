import paper from 'paper'
import store from '@/store'

export default class Worktop {
  constructor(worktopData, sectionData, region) {
    this.worktopData = worktopData
    this.sectionData = sectionData
    this.region = region
    this.labelSize = 40
    this.labelPadding = 75

    this.createWorktop()
    var label = this.getLabel()
    this.outline.name = 'outline'
    this.dimension = this.getDimension()
    this.dimension.name = 'dimension'
    this.item = new paper.Group([this.outline, this.dimension, label])
    this.item.name = sectionData.name
    this.item.data.originalBounds = this.item.bounds
  }

  getWorktopHex(worktopMaterial) {
    if (!worktopMaterial) return null
    if (!store) return null

    let worktopColour
    if (store.state.materials) {
      const colouredMaterials = store.state.materials?.filter(material => material.colours)
      const colours = colouredMaterials?.map(colouredMaterial => colouredMaterial.colours)
      for (const materialSet of colours) {
        for (const colourInfo of materialSet) {
          if (colourInfo.value === worktopMaterial.colourCode) {
            worktopColour = colourInfo
          }
        }
      }
      return worktopColour.hex
    } else {
      return null
    }
  }

  createWorktop() {
    if (this.sectionData.customCoreThickness) {
      this.outline = new paper.Path.Rectangle(
        new paper.Point(0, 0),
        new paper.Size(parseInt(this.sectionData.l), parseInt(this.sectionData.d))
      )
      this.outline.fillColor = this.getWorktopHex(this.worktopData)
    } else {
      const worktopOutlineRecX =
        this.sectionData.displayVerticalRight || this.sectionData.displayVerticalLeft
          ? this.worktopData.thickness
          : this.sectionData.l
      const worktopOutlineRecY =
        this.sectionData.displayVerticalRight || this.sectionData.displayVerticalLeft
          ? this.sectionData.l
          : this.worktopData.thickness
      this.outline = new paper.Path.Rectangle(
        new paper.Point(0, 0),
        new paper.Size(parseInt(worktopOutlineRecX), parseInt(worktopOutlineRecY))
      )
      this.outline.fillColor = '#FBE6CB'
    }
    this.outline.strokeColor = 'black'
  }

  getLabel() {
    var text = new paper.PointText(new paper.Point(0, 0))
    text.justification = 'center'
    text.fillColor = 'black'
    text.selectedColor = 'transparent'
    text.fontFamily = 'Roboto Mono'
    text.fontSize = this.labelSize
    text.content = this.sectionData.name.toUpperCase()

    let dotty = false
    dotty = new paper.Path.Circle(new paper.Point(0, 0), 20)
    dotty.fillColor = this.getWorktopHex(this.worktopData)
    dotty.strokeColor = 'black'
    dotty.selectedColor = 'transparent'

    if (this.sectionData.displayVerticalRight) {
      text.rotate(90)
    } else if (this.sectionData.displayVerticalLeft) {
      text.rotate(-90)
    }

    if (this.sectionData.displayVerticalRight) {
      text.translate(-this.labelPadding, this.sectionData.l / 2 + text.bounds.width)
      dotty.translate(-this.labelPadding, this.sectionData.l / 2 - text.bounds.width * 3)
    } else if (this.sectionData.displayVerticalLeft) {
      text.translate(this.labelPadding, this.sectionData.l / 2 + text.bounds.width)
      dotty.translate(this.labelPadding, this.sectionData.l / 2 - text.bounds.width * 3)
    } else {
      text.translate(this.sectionData.l / 2, -this.labelPadding)
      dotty.translate(this.sectionData.l / 2 - text.bounds.width / 2 - 40, -this.labelPadding - text.bounds.height / 4)
    }
    let labelIconGroup = [text, dotty]
    return new paper.Group(labelIconGroup)
  }

  getDimension() {
    let length, depth, thickness

    this.region == 'us' ? (length = Math.round((this.sectionData.l / 25.4) * 100) / 100) : (length = this.sectionData.l)
    this.region == 'us' ? (depth = Math.round((this.sectionData.d / 25.4) * 100) / 100) : (depth = this.sectionData.d)
    this.region == 'us' && this.worktopData
      ? (thickness = Math.round((this.worktopData.thickness / 25.4) * 100) / 100)
      : (thickness = this.worktopData.thickness)
    let dimension = this.sectionData.customCoreThickness
      ? new paper.PointText(new paper.Point(this.sectionData.l / 2, this.sectionData.d / 2))
      : this.sectionData.displayVerticalRight
      ? new paper.PointText(new paper.Point(this.labelPadding, this.sectionData.l / 2))
      : this.sectionData.displayVerticalLeft
      ? new paper.PointText(new paper.Point(-this.labelPadding, this.sectionData.l / 2))
      : new paper.PointText(new paper.Point(this.sectionData.l / 2, this.labelPadding))
    dimension.justification = 'center'
    dimension.fillColor = 'transparent'
    dimension.selectedColor = 'transparent'
    dimension.fontSize = this.labelSize
    dimension.fontFamily = 'Roboto Mono'
    dimension.content = this.sectionData.customCoreThickness
      ? `L${length.toString().toUpperCase()} x H${depth.toString().toUpperCase()} x MT${this.sectionData.coreThickness}`
      : this.worktopData
      ? `L${length
          .toString()
          .toUpperCase()} x D${depth.toString().toUpperCase()} x MT${thickness.toString().toUpperCase()}`
      : `L${length.toString().toUpperCase()} x D${depth.toString().toUpperCase()}`
    if (this.sectionData.customCoreThickness) dimension.translate(0, dimension.bounds.height / 4)

    if (this.sectionData.displayVerticalRight) {
      dimension.rotate(90)
    } else if (this.sectionData.displayVerticalLeft) {
      dimension.rotate(-90)
    }
    return dimension
  }
}
