var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"materialOrderView"}},[_c('div',{attrs:{"id":"view-sub-nav"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":""},on:{"click":function($event){_vm.$utils.copyToClipboard(_vm.frontsText.concat(_vm.insertsText).concat(_vm.worktopsText))}}},[_vm._v("Copy to Clipboard")])],1),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-3"},[_c('v-card-title',{staticClass:"primary white--text"},[(_vm.version)?_c('span',[_vm._v("Sheets")]):_vm._e()]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.materials.concat(_vm.inserts, _vm.pantryLamBase, _vm.copiedMaterial),"headers":_vm.headers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(props.item.qty > 0)?_c('tr',[_c('td',[_vm._v(_vm._s(props.item.qty))]),_c('td',{on:{"click":function () {
                        _vm.selectedMaterial = props.item
                        _vm.qtyOverrideDialog = true
                      }}},[(props.item.qtyOverride)?_c('div',[_vm._v(" "+_vm._s(props.item.qtyOverride)+" ")]):_c('div',{staticClass:"text--disabled"},[_vm._v(" Override sheet qty ")])]),_c('td',[_vm._v(_vm._s(props.item.thickness))]),_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(props.item.dimensions))]),_c('td',[_c('v-select',{staticClass:"hide-line mt-0 pt-0 text-body-2",attrs:{"items":props.item.sheetSizes,"value":props.item.sheetOverride,"placeholder":"Override sheet size","hide-details":"","clearable":props.item.isCopy ? false : true,"clear-icon":"mdi-close-circle"},on:{"focus":function($event){_vm.selectedMaterial = props.item},"click:clear":function($event){_vm.selectedMaterial = props.item},"change":_vm.overrideSheetSize}})],1),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(props.item.colour))]),_c('td',{staticClass:"d-flex flex-row justify-end"},[_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","disabled":!props.item.colour ||
                              props.item.isCopy ||
                              props.item.sections ||
                              !['18mm'].includes(props.item.thickness) ||
                              props.item.sheetSizes.length == 0},on:{"click":function () {
                              _vm.selectedMaterial = props.item
                              _vm.copyMaterial()
                            }}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","disabled":!props.item.isCopy},on:{"click":function () {
                              _vm.selectedMaterial = props.item
                              _vm.deleteCopyMaterial()
                            }}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]):_vm._e()]}}])})],1)],1)],1),(_vm.worktops.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',[_vm._v("Worktops")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.worktops,"headers":_vm.headers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.qty))]),_c('td',[_vm._v("n/a")]),_c('td',[_vm._v(_vm._s(props.item.thickness))]),_c('td',[_vm._v(_vm._s(props.item.material))]),_c('td',[_vm._v(_vm._s(props.item.size))]),_c('td',[_vm._v("n/a")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(props.item.colour + ' / ' + props.item.colourName))])])]}}],null,false,3119340747)})],1)],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.qtyOverrideDialog),callback:function ($$v) {_vm.qtyOverrideDialog=$$v},expression:"qtyOverrideDialog"}},[(_vm.selectedMaterial)?_c('v-card',[_c('v-card-title',{staticClass:"primary white--text title"},[_vm._v("Override Sheet Qty")]),_c('v-card-text',{staticClass:"pt-6"},[_c('v-text-field',{staticClass:"text-center",attrs:{"placeholder":"Number of sheets"},model:{value:(_vm.qtyOverrideValue),callback:function ($$v) {_vm.qtyOverrideValue=_vm._n($$v)},expression:"qtyOverrideValue"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function () {
              _vm.qtyOverrideDialog = false
              _vm.qtyOverrideValue = null
            }}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","dark":""},on:{"click":_vm.overrideSheetQty}},[_vm._v("Save")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }