<template>
  <div id="production">
    <v-tabs class="flex-grow-0" :height="60" background-color="primary" dark slider-color="accent" v-model="activeTab">
      <v-tab :key="0" v-if="$store.state.user.permissions.uk">Shipments</v-tab>
      <v-tab :key="1" v-if="$store.state.user.permissions.uk">Missing Shipments</v-tab>
      <v-tab :key="2" v-if="$store.state.user.permissions.uk">Schedule</v-tab>
      <v-tab :key="3" v-if="$store.state.user.permissions.uk">Deliveries</v-tab>
      <v-tab :key="4" v-if="$store.state.user.permissions.us">US Production</v-tab>
    </v-tabs>
    <component :is="tabComponents[activeTab]"></component>
  </div>
</template>

<script>
import MissingShipments from '@/components/production/MissingShipments'
import Shipments from '@/components/production/Shipments'
import Schedule from '@/components/production/Schedule'
import Deliveries from '@/components/production/Deliveries'
import UsProduction from '@/components/production/UsProduction'

export default {
  name: 'Production',
  components: {
    Schedule,
    Shipments,
    MissingShipments,
    Deliveries,
    UsProduction
  },
  data() {
    return {
      activeTab: this.$store.state.user.permissions.uk ? 0 : 3,
      tabComponents: ['Shipments', 'MissingShipments', 'Schedule', 'Deliveries', 'UsProduction']
    }
  }
}
</script>
<style lang="scss" scoped>
#production {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
</style>
