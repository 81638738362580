const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'
import cadMethods from '@/js/cad/CadMethods'

export default class FixedShelvingSlots {
  constructor(urtil, materialThickness, rebateDistFromFront, tSlotInfo, sideRebateInfo) {
    this.models = {}

    // VARS
    this.urtil = urtil

    this.rebateDistFromFront = rebateDistFromFront

    this.fullTSlotHeight = materialThickness + tSlotInfo?.tolerance
    this.tSlotRebateHeight = tSlotInfo?.rebateHeight

    //CALCS
    this.sideRebateWidth = materialThickness + sideRebateInfo?.tolerance

    this.addFixedShelvingSlots()
  }

  addFixedShelvingSlots() {
    if (this.urtil.shelvingOptions.heights.length > 0) {
      const shelfPositionArr = cadMethods.calculateShelfHeights(this.urtil, this.fullTSlotHeight, this.sideRebateWidth)

      shelfPositionArr.forEach((shelfPos, index) => {
        let slot = (this.models['slot-' + index] = { models: {} })

        slot.models.slotRebate = new makerjs.models.Rectangle(
          this.fullTSlotHeight,
          this.urtil.d - this.rebateDistFromFront
        )
        slot.models.slotRebate.layer = layerNames.urtilFinPocket.label
        makerjs.model.move(slot, [shelfPos, this.rebateDistFromFront])

        slot.models.tSlotTrack = {
          layer: layerNames.tSlotGroove.label,
          paths: {
            line1: {
              type: 'line',
              origin: [0, 0],
              end: [this.fullTSlotHeight - 10, 0]
            },
            line2: {
              type: 'line',
              origin: [this.fullTSlotHeight - 10, 0],
              end: [this.fullTSlotHeight - 10, this.urtil.d - this.rebateDistFromFront - this.tSlotRebateHeight / 2]
            },
            line3: {
              type: 'line',
              origin: [this.fullTSlotHeight - 10, this.urtil.d - this.rebateDistFromFront - this.tSlotRebateHeight / 2],
              end: [this.fullTSlotHeight - 10, 0]
            },
            line4: {
              type: 'line',
              origin: [this.fullTSlotHeight - 10, 0],
              end: [0, 0]
            }
          }
        }
        makerjs.model.move(slot.models.tSlotTrack, [5, 5])
      })
    }
  }
}
