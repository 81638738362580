const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'
import cadMethods from '@/js/cad/CadMethods'
import AdjustableShelvingPins from '../features/adjustableShelvingPins'
import FixedShelvingSlots from '../features/fixedShelvingSlots'

export default class Sides {
  constructor(
    urtil,
    materialThickness,
    finDepth,
    backInfo,
    sideRebateInfo,
    trimInfo,
    grooveInfo,
    engravingInfo,
    adjShelfInfo,
    tSlotInfo,
    rebateDistFromFront
  ) {
    this.models = {
      leftEnd: { models: {} },
      rightEnd: { models: {} }
    }
    //VARS
    this.urtil = urtil
    this.materialThickness = materialThickness
    this.finDepth = finDepth
    this.adjShelfInfo = adjShelfInfo
    this.rebateDistFromFront = rebateDistFromFront
    this.tSlotInfo = tSlotInfo
    this.backInfo = backInfo
    this.sideRebateInfo = sideRebateInfo

    this.backRebateWidth = backInfo?.rebateWidth
    this.backRebateToolDiameter = backInfo?.rebateToolDiameter

    this.sideRebateWidth = materialThickness + sideRebateInfo?.tolerance
    this.sideRebateTolerance = sideRebateInfo?.tolerance

    this.trimPilotRad = trimInfo?.pilotRad
    this.grooveWidth = grooveInfo?.width
    this.grooveInset = grooveInfo?.inset
    this.grooveSpacing = grooveInfo?.spacing

    this.engravingInfo = engravingInfo
    this.font = engravingInfo?.font
    this.fontSize = engravingInfo?.fontSize
    this.label = engravingInfo?.label

    this.createSides()
  }

  createSides() {
    // ENDS
    let end = this.models.leftEnd.models

    end.outline = new makerjs.models.Rectangle(this.urtil.h, this.urtil.d)
    end.outline.layer = layerNames.cutout.label

    // OLD
    end.backRebate = new makerjs.models.Rectangle(this.urtil.h - 10, this.backRebateWidth + 1)
    end.backRebate.layer = layerNames.urtilBackPocket.label
    makerjs.model.move(end.backRebate, [5, this.urtil.d - this.backRebateWidth])

    // New side back pocket cut line (on-path)
    end.backRebateLine = {
      layer: layerNames.backPocketLine.label,
      paths: {
        rebateLine: {
          type: 'line',
          // origin x = 11, 5 + 12mm toolbit / 2
          origin: [11, this.urtil.d - this.backRebateWidth / 2],
          end: [this.urtil.h - 11, this.urtil.d - this.backRebateWidth / 2]
        }
      }
    }

    end.bottomRebate = new makerjs.models.Rectangle(
      this.sideRebateWidth + 1,
      this.urtil.d + this.backRebateToolDiameter
    )
    end.bottomRebate.layer = layerNames.urtilSideRebate.label
    makerjs.model.move(end.bottomRebate, [-1, -this.backRebateToolDiameter / 2])

    // New side rebate [bottom] cut line (on-path)
    end.bottomRebateLine = {
      layer: layerNames.sideRebateLine.label,
      paths: {
        rebateLine: {
          type: 'line',
          // origin y = 2, - this.backRebateToolDiameter[=8]/2 + 12mm toolbit / 2
          origin: [this.sideRebateWidth / 2, 2],
          end: [this.sideRebateWidth / 2, this.urtil.d - 2]
        }
      }
    }

    end.topRebate = makerjs.cloneObject(end.bottomRebate)
    makerjs.model.move(end.topRebate, [
      this.urtil.h - this.materialThickness - this.sideRebateTolerance,
      -this.backRebateToolDiameter / 2
    ])

    // New side rebate [top] cut line (on-path)
    end.topRebateLine = makerjs.cloneObject(end.bottomRebateLine)
    makerjs.model.move(end.bottomRebateLine, [this.urtil.h - this.materialThickness - this.sideRebateTolerance, 2])

    // Pilot Holes for Urtil Sides - screws to replace lamello for trim-fit-urtil
    if (this.urtil.trimming) {
      end.topTrimPilot = new makerjs.models.Ellipse(this.trimPilotRad, this.trimPilotRad)
      end.topTrimPilot.layer = layerNames.maxPilotHole.label

      const pilot = cadMethods.calculateJoint(this.urtil.d)

      end.topTrimPilot = makerjs.layout.cloneToColumn(
        end.topTrimPilot,
        pilot.pilotCount,
        pilot.pilotSpacing - this.trimPilotRad * 2
      )
      makerjs.model.move(end.topTrimPilot, [this.materialThickness / 2, pilot.pilotInset])
      end.botTrimPilot = makerjs.cloneObject(end.topTrimPilot)
      makerjs.model.move(end.botTrimPilot, [this.urtil.h - this.materialThickness / 2, pilot.pilotInset])
    }

    if (this.urtil.type === 's' || this.urtil.type === 't') {
      end.frontGroove = new makerjs.models.Rectangle(this.urtil.h - 10, this.grooveWidth)
      end.frontGroove.layer = layerNames.urtilSideGroove.label
      makerjs.model.move(end.frontGroove, [5, this.grooveInset])
    }

    if (this.urtil.type === 't') {
      end.backGroove = makerjs.cloneObject(end.frontGroove)
      end.backGroove.layer = layerNames.urtilSideGroove.label
      makerjs.model.move(end.backGroove, [5, this.grooveInset + this.grooveSpacing + this.grooveWidth])
    }

    // adjustable shelving pins & shelving slots on side pieces
    if (this.urtil.shelving && this.urtil.shelvingOptions && this.urtil.shelvingOptions.qty > 0) {
      switch (this.urtil.shelving) {
        case 'adjustable':
          end.adjShelvingPins = new AdjustableShelvingPins(
            this.urtil,
            this.materialThickness,
            this.finDepth,
            this.adjShelfInfo,
            this.backInfo,
            null,
            'sides'
          )
          break
        case 'fixed':
          end.fixedShelvingSlots = new FixedShelvingSlots(
            this.urtil,
            this.materialThickness,
            this.rebateDistFromFront,
            this.tSlotInfo,
            this.sideRebateInfo
          )
          break
      }

      if (this.urtil.shelving && this.urtil.shelving === 'fixed') {
        // add a mega warning
        let text = new makerjs.models.Text(this.font, 'IMPORTANT! Set start point on Urtil T-slot path!!!', 200)
        this.models.warningText = makerjs.model.mirror(text, true, false)
        this.models.warningText.layer = layerNames.warning.label

        let textMeasurements = makerjs.measure.modelExtents(this.models.warningText)
        makerjs.model.moveRelative(this.models.warningText, [
          -textMeasurements.center[0],
          -textMeasurements.center[1] + 1000
        ])
      }
    }

    // CLONE:: side (end) piece [RIGHT]
    makerjs.model.move(this.models.leftEnd, [this.urtil.w + 50, 0])
    this.models.rightEnd = makerjs.model.mirror(this.models.leftEnd, true, false)
    makerjs.model.move(this.models.rightEnd, [this.urtil.w + this.urtil.h + 50, this.urtil.d + 50])

    // engraving on LEFT to show orientation
    const orientationLabelL = 'L'
    const orientationTextL = new makerjs.models.Text(this.font, orientationLabelL.toUpperCase(), this.fontSize)
    end.leftOrientationEngraving = makerjs.model.mirror(orientationTextL, true, false)
    end.leftOrientationEngraving.layer = layerNames.specialEngrave.label
    makerjs.model.move(end.leftOrientationEngraving, [30, this.urtil.d - this.backRebateWidth / 2 - 4])
    makerjs.model.rotate(end.leftOrientationEngraving, -90, [30, this.urtil.d - this.backRebateWidth / 2 - 2])

    // location engraving on left end
    const leftLocationEngrave = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    end.leftLocationEngrave = makerjs.model.mirror(leftLocationEngrave, true, false)
    end.leftLocationEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(end.leftLocationEngrave, [this.urtil.h - 50, this.urtil.d - this.backRebateWidth / 2 - 4])

    const rightEnd = this.models.rightEnd.models
    // engraving on RIGHT to show orientation
    const orientationLabelR = 'R'
    const orientationTextR = new makerjs.models.Text(this.font, orientationLabelR.toUpperCase(), this.fontSize)
    rightEnd.rightOrientationEngraving = makerjs.model.mirror(orientationTextR, true, false)
    rightEnd.rightOrientationEngraving.layer = layerNames.specialEngrave.label
    makerjs.model.move(rightEnd.rightOrientationEngraving, [-30, this.urtil.d - this.backRebateWidth / 2 - 4])
    makerjs.model.rotate(rightEnd.rightOrientationEngraving, 90, [-30, this.urtil.d - this.backRebateWidth / 2 + 4])

    // location engraving on right end
    const rightLocationEngrave = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    rightEnd.rightLocationEngrave = makerjs.model.mirror(rightLocationEngrave, true, false)
    rightEnd.rightLocationEngrave.layer = layerNames.specialEngrave.label
    makerjs.model.move(rightEnd.rightLocationEngrave, [
      -this.urtil.h + 100,
      this.urtil.d - this.backRebateWidth / 2 - 4
    ])
  }
}
