<template>
  <div>
    <div v-if="ready && svg !== false" id="customer-svg">
      <span v-if="svg !== false" v-html="svg"></span>
    </div>
    <v-overlay v-else>
      <v-progress-circular v-if="svg !== false" :size="50" color="primary" indeterminate></v-progress-circular>
      <div v-else>Visual does not exist for this shipment</div>
    </v-overlay>
  </div>
</template>

<script>
import paper from 'paper'

export default {
  name: 'CustomerSVG',
  props: ['quoteID', 'versionID'],
  data() {
    return {
      quote: null,
      version: null,
      svg: null,
      margins: 0,
      ready: false
    }
  },
  created() {
    this.$bind('quote', this.$db.collection('quotes').doc(this.quoteID)).then(async () => {
      for (var i = 0; i < this.quote.versions.length; i++) {
        if (this.versionID == this.quote.versions[i].id) {
          this.version = this.quote.versions[i]

          const visualSnap = await this.$db
            .collection('visuals')
            .doc(this.version.customerVisualID)
            .get()

          this.ready = true

          if (visualSnap.exists) {
            var p = new paper.Project()
            p.importJSON(visualSnap.data().json)
            p.activeLayer.fitBounds(
              new paper.Rectangle(
                new paper.Point(this.margins, this.margins * 2),
                new paper.Size(1024 - this.margins * 2, 710 - this.margins * 2)
              )
            )

            this.svg = p.exportSVG({
              asString: true,
              bounds: new paper.Rectangle(new paper.Point(0, 0), new paper.Size(1024, 710))
            })
          } else {
            this.svg = false
          }
        }
      }
    })
  }
}
</script>

<style lang="scss">
#customer-svg {
  .details {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
