<template>
  <div
    class="date-range-picker"
    :class="{
      'text-right': align === 'right'
    }"
  >
    <v-menu v-model="showMenu" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">{{ rangeString }}<v-icon>mdi-chevron-down</v-icon></div>
      </template>
      <v-card id="main-card" class="d-flex">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col>
              <v-date-picker no-title v-model="vuetifyRange" range :key="vuetifyRange[0]"> </v-date-picker>
              <div class="pa-2">
                <v-btn color="primary" text @click="showMenu = false">Cancel</v-btn>
                <v-btn color="primary" depressed dark @click="updateRangeFromPicker">Update</v-btn>
              </div>
            </v-col>
            <v-col class="options">
              <div class="pa-4">
                <div class="title">Presets</div>
                <v-btn
                  class="text-body-2 text-capitalize text-left"
                  v-for="(preset, key) in presets"
                  color="primary"
                  text
                  block
                  :key="key"
                  @click="setPreset(preset.value)"
                  >{{ preset.text }}</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'DateRangePicker',
  props: ['align', 'empty'],
  data() {
    return {
      showMenu: false,
      range: this.empty ? null : this.lastSixMonths(),
      pickerDisplayDate: null,
      vuetifyRange: [],
      presets: [
        { text: 'Last 6 months', value: 0 },
        { text: 'Last 12 months', value: 1 },
        { text: 'This Quarter', value: 3 },
        { text: 'Last Month', value: 4 },
        { text: 'Last Quarter', value: 5 }
      ]
    }
  },
  watch: {
    range: {
      handler() {
        if (this.range) {
          this.vuetifyRange = [this.range[0].format('YYYY-MM-DD'), this.range[1].format('YYYY-MM-DD')]
          this.pickerDisplayDate = this.vuetifyRange[0]
          this.$emit('change', this.range)
        }
      },
      immediate: true
    }
  },
  computed: {
    rangeString() {
      return this.range
        ? this.range[0].format('MMM DD, YYYY') + ' - ' + this.range[1].format('MMM DD, YYYY')
        : 'Select a date range'
    }
  },
  methods: {
    updateRangeFromPicker() {
      this.range = [this.$moment(this.vuetifyRange[0], 'YYYY-MM-DD'), this.$moment(this.vuetifyRange[1], 'YYYY-MM-DD')]
      this.showMenu = false
    },
    setPreset(num) {
      this.showMenu = false
      switch (num) {
        case 0:
          this.range = this.lastSixMonths()
          break
        case 1:
          this.range = this.lastTwelveMonths()
          break
        case 2:
          this.range = this.thisMonth()
          break
        case 3:
          this.range = this.thisQuarter()
          break
        case 4:
          this.range = this.lastMonth()
          break
        case 5:
          this.range = this.lastQuarter()
          break
        default:
          break
      }
    },
    lastSixMonths() {
      return [
        this.$moment()
          .subtract(6, 'months')
          .startOf('day'),
        this.$moment().startOf('day')
      ]
    },
    lastTwelveMonths() {
      return [
        this.$moment()
          .subtract(12, 'months')
          .startOf('day'),
        this.$moment().startOf('day')
      ]
    },
    last30() {
      return [
        this.$moment()
          .subtract(30, 'days')
          .startOf('day'),
        this.$moment().startOf('day')
      ]
    },
    last90() {
      return [
        this.$moment()
          .subtract(90, 'days')
          .startOf('day'),
        this.$moment().startOf('day')
      ]
    },
    thisMonth() {
      return [this.$moment().startOf('month'), this.$moment().startOf('day')]
    },
    thisQuarter() {
      return [this.$moment().startOf('quarter'), this.$moment().endOf('quarter')]
    },
    lastMonth() {
      return [
        this.$moment()
          .subtract(1, 'month')
          .startOf('month'),
        this.$moment()
          .subtract(1, 'month')
          .endOf('month')
      ]
    },
    lastQuarter() {
      return [
        this.$moment()
          .subtract(1, 'quarter')
          .startOf('quarter'),
        this.$moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.date-range-picker {
  width: 500px !important;
}
.options {
  border-left: solid 1px black;
}
</style>
