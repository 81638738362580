var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class DPull {
  constructor(front, width, doorWidth, doorHeight) {
    // common
    this.models = {}
    this.width = width
    this.insetX = 50
    this.insetY = 50
    this.front = front
    this.doorWidth = doorWidth
    this.doorHeight = doorHeight

    // unique
    this.drillRadius = 2.5
    this.profileLength = 3.5

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    this.models.drill = new makerjs.models.Holes(this.drillRadius, [
      [0, 0],
      [this.width, 0]
    ])
    this.models.drill.layer = layerNames.dHandleDrill.label

    this.models.countersink = new makerjs.models.Holes(this.drillRadius, [
      [0, 0],
      [this.width, 0]
    ])
    this.models.countersink.layer = layerNames.dHandleCountersink.label
  }
}
