<template>
  <div id="month">
    <div v-for="(day, date, key) in tasksByDay" v-if="$moment(date).weekday() < 5" class="day" :key="key">
      <div class="header py-1 px-2 text-body-2 flex-grow-0" v-if="key < 5" :class="$utils.datePPF(date)">
        {{ $moment(date).format('ddd') }}
      </div>
      <div class="d-flex align-center py-1 px-2 text-body-2 secondary flex-grow-0">
        <div :class="$utils.datePPF(date)">
          {{ $moment(date).format('Do') }}
        </div>

        <div
          class="ml-1"
          :class="{
            'red--text':
              Math.round((capacityForDay(capacityObject[date]) / getDailyRequiredCapacity(date)) * 100) < 100,
            'green--text':
              Math.round((capacityForDay(capacityObject[date]) / getDailyRequiredCapacity(date)) * 100) >= 100
          }"
        >
          {{ Math.round((capacityForDay(capacityObject[date]) / getDailyRequiredCapacity(date)) * 100) }}%
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-chip small class="px-2 coloured cut">{{ getCutCapacity(date) }}%</v-chip>
          <v-chip small class="px-2 coloured finish mx-1">{{ getMuFinishCapacity(date) }}%</v-chip>
          <v-chip small class="px-2 coloured pack">{{ getPackCapacity(date) }}%</v-chip>
        </div>

        <!--  -->
        <!--  -->
      </div>
      <draggable
        v-model="tasksByDay[date]"
        group="tasks"
        @end="onSortEnd"
        class="draggable-list flex-grow-1"
        :animation="0"
        :id="date"
      >
        <div
          class="task body-2"
          v-for="task in tasksByDay[date]"
          :class="[
            task.type,
            $utils.camelize(task.subtype),
            { coloured: !task.complete && !task.paused },
            { paused: task.paused },
            {
              ghost: focussedTask !== null && task.shipmentID !== focussedTask.shipmentID
            },
            {
              focussed: focussedTask !== null && task.shipmentID == focussedTask.shipmentID
            }
          ]"
          :id="task.id"
          :key="task.id"
        >
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <v-icon
                v-if="task.type === 'cut' && !task.toolpathed"
                :size="14"
                :style="{
                  'padding-bottom': '1px',
                  color: task.complete ? 'black' : 'white',
                  'margin-right': '2px'
                }"
                >mdi-alert</v-icon
              >
              <v-icon
                v-if="
                  task.type === 'dispatch' && task.subtype === 'To customer' && task.courierBooked && !task.complete
                "
                :size="15"
                dark
                >mdi-truck-check</v-icon
              >
              <div class="mr-2" @click="$emit('focusTask', task)">
                {{ task.orderNumber || task.invoiceNumber }}
              </div>
              <div class="flex-grow-1 d-flex" @click="$emit('openTask', task)">
                <div>
                  {{ task.objectType }}
                  {{ task.subtype ? task.subtype : task.type }}
                </div>
                <v-spacer />
                <div>
                  {{ task.units }}
                </div>
                <div v-if="task.type === 'finish' || task.type === 'assemble'">
                  <span class="mx-1">/</span>{{ $utils.toFixedNumber(task.muUnits, 1) }} mµ
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  name: 'Month',
  components: { draggable },
  props: ['tasksByDay', 'capacityObject', 'focussedTask', 'staffUnitsPerDay'],
  methods: {
    getDailyRequiredCapacity(date) {
      let count = 0

      for (var i = 0; i < this.tasksByDay[date].length; i++) {
        // should we exclude this from capacity?
        var t = this.tasksByDay[date][i]
        if (t.type != 'dispatch') {
          count += parseInt(this.tasksByDay[date][i].units)
        }
      }
      return count
    },
    capacityForDay(day) {
      if (day) {
        let hours = 0
        let workers = day.working.concat(day.freelancers).concat(day.overtime)
        for (const worker of workers) {
          hours += worker.hours
        }
        return hours * (this.staffUnitsPerDay / 8)
      }
    },
    getCutCapacity(date) {
      const units = this.tasksByDay[date].reduce((sum, val) => {
        return val.type === 'cut' ? sum + parseInt(val.units) : sum
      }, 0)
      return Math.round((units / this.$store.state.mu.cutCapacity) * 100)
    },
    getMuFinishCapacity(date) {
      const capacity = this.finishMuCapacityForDay(this.capacityObject[date])
      const mu = this.tasksByDay[date].reduce((sum, val) => {
        if (val.type === 'finish' || val.type == 'assemble') {
          return sum + val.muUnits
        } else {
          return sum
        }
      }, 0)
      const utilisation = capacity && mu ? Math.round((mu / capacity) * 100) : 0
      return utilisation
    },
    finishMuCapacityForDay(day) {
      if (day) {
        let hours = 0
        let workers = day?.working.concat(day?.freelancers).concat(day?.overtime)
        for (const worker of workers) {
          hours += worker.hours
        }
        hours -= day?.cutting + day?.packing // remove cutting and packing hours
        return hours * (this.$store.state.mu.finishCapacity / 8) // 8 hour day
      }
    },
    getPackCapacity(date) {
      const units = this.tasksByDay[date].reduce((sum, val) => {
        return val.type === 'pack' ? sum + parseInt(val.units) : sum
      }, 0)
      return Math.round((units / this.$store.state.mu.packCapacity) * 100)
    },
    onSortEnd(e) {
      this.$emit('onSortEnd', e)
    }
  }
}
</script>
<style lang="scss">
#month {
  position: absolute;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  transition: width 0.5s;
  .day {
    width: 20%;
    border-right: solid 1px $ruleGrey;
    border-bottom: solid 1px $ruleGrey;
    display: flex;
    flex-direction: column;

    .draggable-list {
      min-height: 36px;
    }

    &:last-child,
    &.no-border {
      border: none;
    }
    .task-type {
      background: $sand;
      border-top: solid 1px $ruleGrey;
      display: flex;
      justify-content: flex-end;
      padding: 4px;
    }
  }
}
.staff-card {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 4px 6px;
  cursor: pointer;
  .worker {
    display: flex;
    justify-content: space-between;
    padding: 2px 0px;
  }
}
</style>
