var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"samples"}},[_c('div',{attrs:{"id":"view-sub-nav"}},[(_vm.$store.state.user.permissions.uk)?_c('v-select',{staticClass:"ma-0 pa-0",attrs:{"items":[
        { text: 'EU', value: 'EU' },
        { text: 'US', value: 'US' }
      ],"hide-details":""},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}):_vm._e(),_c('v-switch',{staticClass:"dispatch-toggle ma-0 pa-0 ml-3",attrs:{"label":_vm.dispatched ? 'Dispatched' : 'Awaiting Dispatch',"hide-details":""},on:{"change":_vm.bindSamples},model:{value:(_vm.dispatched),callback:function ($$v) {_vm.dispatched=$$v},expression:"dispatched"}}),_c('v-spacer'),(_vm.selected.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.selected.length)+" Items Selected "),_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","outlined":"","color":"primary","disabled":_vm.selected.length == 0 || _vm.dispatched},on:{"click":_vm.markAsDispatched}},[_vm._v("Mark As Dispatched")]),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","outlined":"","color":"primary","disabled":_vm.selected.length == 0},on:{"click":function () {
            _vm.region == 'US' ? _vm.csvUS() : _vm.csv()
          }}},[_vm._v("Download CSV")]),_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":_vm.updateMissingCustomers}},[_vm._v("Update Customers")])],1):_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.addDialog = true}}},[_vm._v("Add Sample Pack")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSamples,"loading":_vm.loading,"show-select":"","item-key":"id","sortBy":"dateCreated","sort-desc":"","hide-default-footer":!_vm.dispatched,"items-per-page":_vm.dispatched ? 10 : -1},scopedSlots:_vm._u([{key:"header.date",fn:function(){return [_vm._v(" "+_vm._s(_vm.dispatched ? 'Dispatched' : 'Ordered')+" ")]},proxy:true},{key:"item.orderNumber",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderNumber || item.xeroInvoiceNumber || 'FOC')+" ")]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dispatched ? _vm.$utils.convertDate(item.dispatchDate) : _vm.$utils.convertDate(item.dateCreated))+" ")]}},{key:"item.materials",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.materials || 'Mixed (OLD)')+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c(_vm.region === 'EU' ? 'ukForm' : 'usForm',{tag:"component",on:{"cancel":function($event){_vm.addDialog = false},"add":_vm.addSamplePack}})],1),(_vm.submitting)?_c('v-overlay',{staticClass:"text-center",attrs:{"opacity":".95","color":"primary"}},[_c('v-progress-circular',{staticClass:"mt-4",attrs:{"size":50,"width":3,"color":"accent","indeterminate":""}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }