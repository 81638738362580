<template>
  <div id="settings">
    <v-tabs :height="60" background-color="primary" dark slider-color="accent" v-model="activeTab">
      <v-tab :key="0">Production</v-tab>
      <v-tab :key="1">Pricing</v-tab>
      <v-tab :key="2">Materials</v-tab>
      <v-tab :key="3">Worktops</v-tab>
      <v-tab :key="4">Handles</v-tab>
      <v-tab :key="5">Shipping</v-tab>
      <v-tab :key="6">Staff</v-tab>
      <v-tab :key="7">Cock-ups</v-tab>
    </v-tabs>
    <component :is="tabComponents[activeTab]"></component>
  </div>
</template>
<script>
import ProductionSettings from '@/components/admin/settings/ProductionSettings'
import PricingSettings from '@/components/admin/settings/PricingSettings'
import MaterialSettings from '@/components/admin/settings/MaterialSettings'
import WorktopSettings from '@/components/admin/settings/WorktopSettings'
import HandleSettings from '@/components/admin/settings/HandleSettings'
import ShippingSettings from '@/components/admin/settings/ShippingSettings'
import StaffSettings from '@/components/admin/settings/StaffSettings'
import CockUpSettings from '@/components/admin/settings/CockUpSettings'

export default {
  name: 'settings',
  components: {
    ProductionSettings,
    PricingSettings,
    MaterialSettings,
    HandleSettings,
    ShippingSettings,
    StaffSettings,
    CockUpSettings,
    WorktopSettings
  },
  data() {
    return {
      activeTab: 0,
      tabComponents: [
        'ProductionSettings',
        'PricingSettings',
        'MaterialSettings',
        'WorktopSettings',
        'HandleSettings',
        'ShippingSettings',
        'StaffSettings',
        'CockUpSettings'
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
#settings {
  height: 100%;
  display: flex;
  flex-direction: column;
}
::v-deep .v-tabs {
  flex: 0;
}
</style>
