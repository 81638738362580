<template>
  <div>
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center">
      <v-progress-circular indeterminate></v-progress-circular>
      <p class="mt-5">Just crunching some numbers...</p>
    </v-overlay>
    <div v-else>
      <div id="view-sub-nav">
        <v-checkbox class="mt-0" hide-details v-model="include4mm" label="4mm"></v-checkbox>
        <v-checkbox class="mt-0 ml-4" hide-details v-model="include6mm" label="6mm"></v-checkbox>
        <v-checkbox class="mt-0 ml-4" hide-details v-model="include18mm" label="18mm"></v-checkbox>
        <v-spacer />
        <v-text-field
          class="mt-0 pt-0"
          v-model="tableSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :clearable="true"
        >
        </v-text-field>
      </div>
      <v-data-table
        :items="data"
        :headers="headers"
        sortBy="value"
        hide-default-footer
        :items-per-page="-1"
        :search="tableSearch"
      >
        <template #item.requiredSheets="{item}">
          {{ Math.ceil(item.requiredSheets) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'Stock',
  data() {
    return {
      veneerSheetArea: 2440 * 1220,
      loading: true,
      materialUsage: {},
      tableSearch: null,
      include4mm: false,
      include6mm: false,
      include18mm: true,
      headers: [
        {
          text: 'Material',
          sortable: true,
          value: 'label'
        },
        {
          text: 'Thickness',
          sortable: false,
          value: 'thickness'
        },
        {
          text: 'Sheets',
          sortable: false,
          value: 'requiredSheets'
        },
        {
          text: 'Sheets Rounded',
          sortable: false,
          value: 'requiredSheetsRounded'
        }
      ]
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    data() {
      let a = []
      for (const m of Object.values(this.materialUsage)) {
        if (!this.include4mm && m.thickness === 4) continue
        if (!this.include6mm && m.thickness === 6) continue
        if (!this.include18mm && m.thickness === 18) continue
        if (m.requiredSheets) {
          a.push(m)
        }
      }
      return a
    }
  },
  methods: {
    async loadData() {
      const shipmentsSnap = await this.$db
        .collection('shipments')
        .where('shipped', '==', false)
        .where('requiresSprayer', '==', true)
        .get()
      const quoteIDs = []
      const quotes = []

      for (const shipment of shipmentsSnap.docs) {
        // Grab tasks for shipment
        const tasksSnap = await this.$db
          .collection('tasks')
          .where('shipmentRef', '==', shipment.ref)
          .get()

        // Find cut task
        const cutTask = tasksSnap.docs.find(o => {
          return o.data().type === 'cut'
        })

        // If requires cutting and cutting not complete add quote ID to array
        if (cutTask && !cutTask.data().complete) {
          if (!quoteIDs.includes(shipment.data().quoteID)) {
            quoteIDs.push(shipment.data().quoteID)
            quotes.push({
              quoteID: shipment.data().quoteID,
              versionID: shipment.data().versionID
            })
          }
        }
      }

      for (const quote of quotes) {
        const quoteSnap = await this.$db
          .collection('quotes')
          .doc(quote.quoteID)
          .get()
        const version = quoteSnap.data().versions.find(version => {
          return version.id === quote.versionID
        })

        if (version) {
          for (const material of version.materials) {
            if (!material.hasGrain) continue
            const materialCode = material.value + '-' + material.thickness

            // add material to object if first instance
            if (!this.materialUsage[materialCode]) {
              this.materialUsage[materialCode] = {
                label: material.label,
                value: material.value,
                thickness: material.thickness,
                numOrders: 0,
                requiredSheets: 0,
                requiredSheetsRounded: 0,
                hasGrain: material.hasGrain
              }
            }
            if (version.materialUsage && version.materialUsage[material.id]) {
              const requiredSheets = version.materialUsage[material.id].qtyOverride
                ? parseInt(version.materialUsage[material.id].qtyOverride)
                : version.materialUsage[material.id].areaWithWastage / this.veneerSheetArea
              this.materialUsage[materialCode].requiredSheets += requiredSheets
              this.materialUsage[materialCode].requiredSheetsRounded += Math.ceil(requiredSheets)
            } else {
              console.log('No material usage data', {
                quoteID: quote.quoteID,
                versionID: quote.versionID
              })
            }
          }
        } else {
          console.log('No version found', {
            quoteID: quote.quoteID,
            versionID: quote.versionID
          })
        }
      }
      this.loading = false
    }
  }
}
</script>
