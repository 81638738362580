const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'
import cadMethods from '@/js/cad/CadMethods'

export default class Back {
  constructor(urtil, carcassThickness, trimInfo, lightingInfo, backInfo, sideRebateInfo, tSlotInfo, engravingInfo) {
    this.models = { back: { models: {} } }
    //VARS
    // need to take carcassThickness since materialThickness refers to 6mm back
    this.urtil = urtil

    this.trimmingTolerance = trimInfo?.tolerance

    this.lightingPadding = lightingInfo?.padding

    this.drillGuideDiameter = backInfo?.drillGuideDiameter
    this.lightCableHoleDiameter = backInfo?.lightCableHoleDiameter
    this.drillGuideSpacing = backInfo?.drillGuideSpacing
    this.drillGuideCornerOffset = backInfo?.drillGuideCornerOffset
    this.drillGuideInset = backInfo?.drillGuideInset

    this.font = engravingInfo?.font
    this.backLabel = engravingInfo?.orientationLabel + ' - ' + engravingInfo?.label
    this.fontSize = engravingInfo?.fontSize

    //CALCS
    this.sideRebateWidth = carcassThickness + sideRebateInfo?.tolerance
    this.fullTSlotHeight = carcassThickness + tSlotInfo?.tolerance
    this.halfTSlotHeight = (carcassThickness + tSlotInfo?.tolerance) / 2

    this.backWidth = urtil.w - carcassThickness * 2 + 25 // 25 is 2 x 12.5mm where back rebate is 13mm deep
    this.backHeight = urtil.h - carcassThickness * 2 + 25
    this.numOfDividers = urtil.compartments - 1
    this.internalVoid = urtil.w - carcassThickness * 2 - this.fullTSlotHeight * this.numOfDividers

    this.createBack()
  }

  createBack() {
    if (this.urtil.trimming) {
      let trimBackWidth = this.backWidth + this.trimmingTolerance * 2
      this.models.back = new makerjs.models.Rectangle(trimBackWidth, this.backHeight)
      makerjs.model.move(this.models.back, [-this.trimmingTolerance, 0])
    } else {
      this.models.back = new makerjs.models.RoundRectangle(this.backWidth, this.backHeight, 10)
    }
    this.models.back.layer = layerNames.backCutout.label

    // Lighting Pilot Hole
    if (this.urtil.lighting) {
      // Light Label Engraving
      const lightEngrave = new makerjs.models.Text(this.font, 'LIGHT', this.fontSize)
      this.models.lightEngrave = makerjs.model.mirror(lightEngrave, true, false)
      this.models.lightEngrave.layer = layerNames.engrave.label

      // find light position
      const lightingWidth = this.urtil.lighting + this.lightingPadding

      makerjs.model.move(this.models.lightEngrave, [
        (this.backWidth - lightingWidth) / 2 + 15,
        this.drillGuideDiameter + 15
      ])

      // light drill hole
      this.models.backCableHole = new makerjs.models.Ellipse(
        this.lightCableHoleDiameter / 2,
        this.lightCableHoleDiameter / 2
      )
      this.models.backCableHole.layer = layerNames.lightHole.label
      makerjs.model.move(this.models.backCableHole, [
        (this.backWidth - lightingWidth) / 2 + this.lightCableHoleDiameter / 2,
        this.lightCableHoleDiameter / 2
      ])
    }

    // Drill holes
    let drillHole = new makerjs.models.Ellipse(this.drillGuideDiameter / 2, this.drillGuideDiameter / 2)

    // Bottom
    const bottomGuides = makerjs.layout.cloneToRow(drillHole, this.urtil.w / this.drillGuideSpacing, 0)
    const bottomGuide = new makerjs.paths.Line(
      [this.drillGuideCornerOffset, this.drillGuideInset],
      [this.backWidth - this.drillGuideCornerOffset, this.drillGuideInset]
    )
    this.models.drillGuideBottom = makerjs.layout.childrenOnPath(bottomGuides, bottomGuide)
    this.models.drillGuideBottom.layer = layerNames.backScrewHole.label

    // Top
    const topGuides = makerjs.layout.cloneToRow(drillHole, this.urtil.w / this.drillGuideSpacing, 0)
    const topGuide = new makerjs.paths.Line(
      [this.drillGuideCornerOffset, this.backHeight - this.drillGuideInset],
      [this.backWidth - this.drillGuideCornerOffset, this.backHeight - this.drillGuideInset]
    )
    this.models.drillGuideTop = makerjs.layout.childrenOnPath(topGuides, topGuide)
    this.models.drillGuideTop.layer = layerNames.backScrewHole.label

    // Left
    const leftGuides = makerjs.layout.cloneToRow(
      drillHole,
      this.urtil.h / this.drillGuideSpacing >= 2 ? this.urtil.h / this.drillGuideSpacing : 2,
      0
    )
    const leftGuide = new makerjs.paths.Line(
      [this.drillGuideInset, this.drillGuideCornerOffset],
      [this.drillGuideInset, this.backHeight - this.drillGuideCornerOffset]
    )
    this.models.drillGuideLeft = makerjs.layout.childrenOnPath(leftGuides, leftGuide)
    this.models.drillGuideLeft.layer = layerNames.backScrewHole.label

    // Right
    const rightGuides = makerjs.layout.cloneToRow(
      drillHole,
      this.urtil.h / this.drillGuideSpacing >= 2 ? this.urtil.h / this.drillGuideSpacing : 2,
      0
    )
    const rightGuide = new makerjs.paths.Line(
      [this.backWidth - this.drillGuideInset, this.drillGuideCornerOffset],
      [this.backWidth - this.drillGuideInset, this.backHeight - this.drillGuideCornerOffset]
    )
    this.models.drillGuideRight = makerjs.layout.childrenOnPath(rightGuides, rightGuide)
    this.models.drillGuideRight.layer = layerNames.backScrewHole.label

    // Divider Pilot Holes
    if (this.numOfDividers > 0) {
      for (let i = 0; i < this.numOfDividers; i++) {
        let xPos =
          (this.internalVoid / this.urtil.compartments) * (i + 1) +
          this.fullTSlotHeight * i +
          12.5 +
          this.halfTSlotHeight
        let dividerGuides = makerjs.layout.cloneToRow(drillHole, 3, 0)
        let dividerGuide = new makerjs.paths.Line([xPos, 25], [xPos, this.backHeight - 25])
        this.models['dividerGuideFin' + (i + 1)] = makerjs.layout.childrenOnPath(dividerGuides, dividerGuide)
        this.models['dividerGuideFin' + (i + 1)].layer = layerNames.backPilotHole.label
      }
    }

    if (this.urtil.trimming) {
      delete this.models.drillGuideLeft
      delete this.models.drillGuideRight
    }

    // Fixed Shelves Back Pilot Holes
    if (this.urtil.shelving === 'fixed' && this.urtil.shelvingOptions.heights.length > 0) {
      const shelfPositionArr = cadMethods.calculateShelfHeights(this.urtil, this.fullTSlotHeight, this.sideRebateWidth)

      shelfPositionArr.forEach((shelfPos, index) => {
        const horizontalHoles = makerjs.layout.cloneToRow(drillHole, 4, 0)
        const horizontalGuide = new makerjs.paths.Line(
          [40, shelfPos + this.halfTSlotHeight - this.drillGuideDiameter],
          [this.backWidth - 40, shelfPos + this.halfTSlotHeight - this.drillGuideDiameter]
        )

        this.models['shelfHole' + index] = makerjs.layout.childrenOnPath(horizontalHoles, horizontalGuide, 0.5)
        this.models['shelfHole' + index].layer = layerNames.backPilotHole.label
      })
    }

    // engraving on back to show orientation & urtil name
    const backText = new makerjs.models.Text(this.font, this.backLabel.toUpperCase(), this.fontSize)
    this.models.backEngraving = makerjs.model.mirror(backText, true, false)
    this.models.backEngraving.layer = layerNames.engrave.label
    let textMeasurements = makerjs.measure.modelExtents(this.models.backEngraving)
    makerjs.model.move(this.models.backEngraving, [
      this.backWidth / 2 - textMeasurements.center[0],
      this.backHeight - 40 - textMeasurements.center[1]
    ])
  }
}
