import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

// Views
import Dash from '@/views/Dash'
import Quotes from '@/views/Quotes'
import AwaitingPayment from '@/views/AwaitingPayment'
import Production from '@/views/Production'
import Dispatched from '@/views/Dispatched'
import Samples from '@/views/Samples'
import Settings from '@/views/Settings'
import Orders from '@/views/Orders'
import Analytics from '@/views/Analytics'
import Recuts from '@/views/Recuts'
import Stock from '@/views/Stock'
import User from '@/views/User'
import SprayerView from '@/views/SprayerView'
import PackingView from '@/views/PackingView'
import CustomerQuote from '@/views/CustomerQuote'
import LucidProductionView from '@/views/LucidProductionView'
import QuoteEdit from '@/views/QuoteEdit'
import Offcuts from '@/views/Offcuts'

import Login from '@/components/user/Login'
import Auth from '@/components/user/Auth'
import QuoteAdd from '@/components/QuoteAdd'
import MegaCad from '@/components/MegaCad'
import MaterialOrderView from '@/components/MaterialOrderView'

import CustomerSVG from '@/components/customer/CustomerSVG'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    // Private
    { path: '/', component: Dash, name: 'dash', meta: { title: 'Mega Admin' } },
    { path: '/quote/add', component: QuoteAdd, name: 'quoteAdd' },
    {
      path: '/quotes',
      component: Quotes,
      name: 'quotes',
      meta: { title: 'Quotes' }
    },
    {
      path: '/invoiced',
      component: AwaitingPayment,
      name: 'invoiced',
      meta: { title: 'Awaiting Payment' }
    },
    {
      path: '/production',
      component: Production,
      name: 'production',
      props: true,
      meta: {
        title: 'Production',
        fillScreen: true
      }
    },
    {
      path: '/dispatched',
      component: Dispatched,
      name: 'dispatched',
      meta: { title: 'Dispatched' }
    },
    { path: '/user', component: User, name: 'user' },
    {
      path: '/megacad/:quoteID/:versionID',
      component: MegaCad,
      name: 'megacad',
      props: true
    },

    {
      path: '/quote/:quoteID/:versionID?',
      component: QuoteEdit,
      name: 'quoteEdit',
      props: true
    },
    {
      path: '/production/packing-list/:shipmentID',
      component: PackingView,
      name: 'packingView',
      props: true,
      meta: { printView: true }
    },
    {
      path: '/production/lucid/:orderID',
      component: LucidProductionView,
      name: 'lucidProductionView',
      props: true,
      meta: { printView: true }
    },
    {
      path: '/production/sprayer-list/:shipmentID',
      component: SprayerView,
      name: 'sprayerView',
      props: true,
      meta: { printView: true }
    },
    {
      path: '/production/material-order/:shipmentID',
      component: MaterialOrderView,
      name: 'materialOrderView',
      props: true
    },
    {
      path: '/samples',
      component: Samples,
      name: 'samples',
      meta: {
        title: 'Samples'
      }
    },
    {
      path: '/offcuts',
      component: Offcuts,
      name: 'offcuts',
      meta: {
        title: 'Offcuts'
      }
    },
    // Public
    {
      path: '/user/login',
      component: Login,
      name: 'login',
      meta: {
        allowPublic: true,
        fillScreen: true
      }
    },
    {
      path: '/user/login/processlink',
      component: Auth,
      name: 'auth',
      meta: {
        allowPublic: true,
        fillScreen: true
      }
    },
    {
      path: '/settings',
      component: Settings,
      name: 'settings',
      meta: { title: 'Settings' }
    },
    {
      path: '/orders',
      component: Orders,
      name: 'orders',
      meta: { title: 'Orders' }
    },
    {
      path: '/recuts',
      component: Recuts,
      name: 'recuts',
      meta: { title: 'Recuts' }
    },
    {
      path: '/stock',
      component: Stock,
      name: 'stock',
      meta: { title: 'Veneer Stock Requirements' }
    },
    {
      path: '/analytics',
      component: Analytics,
      name: 'analytics',
      meta: { title: 'Analytics' }
    },
    {
      path: '/customer/quote/:quoteID/:versionID',
      name: 'customerQuote',
      component: CustomerQuote,
      props: true,
      meta: {
        allowPublic: true,
        fillScreen: true
      }
    },
    {
      path: '/customer/svg/:quoteID/:versionID',
      name: 'customerSVG',
      component: CustomerSVG,
      props: true,
      meta: { printView: true }
    }
  ]
})

// TODO implement access level based guards for different user types - eg quoter / production / management
router.beforeEach((to, from, next) => {
  // set the page title
  store.commit('setPageTitle', to.meta.title)

  if (store.state.user.isInit) {
    if (to.matched.some(record => record.meta.allowPublic)) {
      if (to.name == 'login' && store.state.user.isAuth) {
        next('/')
      } else {
        next()
      }
    } else {
      if (store.state.user.isAuth) {
        next()
      } else {
        next('/user/login')
      }
    }
  } else {
    next()
  }
})

export default router
