import paper from 'paper'
import utils from '@/js/utils'

export default class Pantry {
  constructor(name, data, mcarc, mback, mbase, grainDirection) {
    this.data = data
    this.grainDirection = grainDirection
    this.mcarc = mcarc
    this.mback = mback
    this.mbase = mbase

    this.materialThickness = 18
    this.rebateDepth = 6
    this.labelPadding = 40
    this.labelSize = 40
    this.iconSize = 40

    this.botFixedHeight = data.d == 391 ? data.h - 400 : data.h - 400
    this.midAdjShelfHeight = null
    this.topAdjShelfHeight = null

    this.createBack()
    this.createCarcass()
    this.createShelving()

    let label = this.getLabel()
    this.outline.name = 'outline'
    this.dimension = this.getDimension()
    this.dimension.name = 'dimension'
    this.grain = this.getGrainDirection()
    this.back.name = 'pantryBack'
    let itemGroup = [this.back, this.shelves, label, this.outline, this.dimension]
    if (this.grain) {
      this.grain.name = 'grain'
      itemGroup.push(this.grain)
    }
    this.item = new paper.Group([...itemGroup])
    this.item.name = name
    this.item.data.originalBounds = this.item.bounds
  }

  createBack() {
    let backRect = new paper.Path.Rectangle(
      new paper.Point(this.materialThickness / 2, this.materialThickness / 2),
      new paper.Size(this.data.w - this.materialThickness, this.data.h - this.materialThickness)
    )

    backRect.fillColor = this.mback ? this.mback.uiColour : '#FBE6CB'
    backRect.selectedColor = 'transparent'
    this.back = new paper.Group(backRect)
  }

  createShelving() {
    const shelves = []
    const numShelves = 3

    const adjShelfVoidHeight = this.botFixedHeight
    const availableSpace = adjShelfVoidHeight - this.materialThickness * numShelves - 1
    const adjShelfStep = availableSpace / (numShelves - 1 + 1)

    for (let i = 0; i < numShelves; i++) {
      let yPos
      switch (i) {
        case 0:
          yPos = this.botFixedHeight
          break
        case 1:
          yPos = this.midAdjShelfHeight = this.botFixedHeight - this.materialThickness - adjShelfStep
          break
        case 2:
          yPos = this.topAdjShelfHeight =
            this.botFixedHeight - this.materialThickness - adjShelfStep - this.materialThickness - adjShelfStep
          break
      }

      let shelf = new paper.Path.Rectangle(
        new paper.Point(this.materialThickness, yPos),
        new paper.Size(this.data.w - this.materialThickness * 2, this.materialThickness)
      )

      shelf.fillColor = '#FBE6CB'
      shelf.strokeColor = 'black'
      shelf.selectedColor = 'transparent'
      shelves.push(shelf)
    }
    this.shelves = new paper.Group(shelves)
  }

  createCarcass() {
    const l = new paper.Path.Rectangle(new paper.Point(0, 0), new paper.Size(this.materialThickness, this.data.h))
    const r = new paper.Path.Rectangle(
      new paper.Point(this.data.w - this.materialThickness, 0),
      new paper.Size(this.materialThickness, this.data.h)
    )
    const t = new paper.Path.Rectangle(
      new paper.Point(this.materialThickness - this.rebateDepth, 0),
      new paper.Size(this.data.w - 2 * (this.materialThickness - this.rebateDepth), this.materialThickness)
    )
    const b = new paper.Path.Rectangle(
      new paper.Point(this.materialThickness - this.rebateDepth, this.data.h - this.materialThickness),
      new paper.Size(this.data.w - 2 * (this.materialThickness - this.rebateDepth), this.materialThickness)
    )
    this.outline = new paper.Group([l, r, b, t])
    this.outline.strokeColor = 'black'
    this.outline.fillColor = '#FBE6CB'
  }

  getLabel() {
    let text = new paper.PointText(new paper.Point(this.data.w / 2, this.data.h + this.labelSize + this.labelPadding))
    text.justification = 'center'
    text.fillColor = 'black'
    text.selectedColor = 'transparent'
    text.fontSize = this.labelSize
    text.fontFamily = 'Roboto Mono'
    text.content = this.data.name ? 'Pantry - ' + this.data.name : 'PANTRY'

    let baseDotty
    if (this.mbase !== this.mcarc && this.data.base) {
      baseDotty = new paper.Path.Circle(
        new paper.Point(this.data.w - this.materialThickness * 3, this.data.h + this.materialThickness * 2),
        20
      )
      baseDotty.fillColor = this.mbase.uiColour
      baseDotty.strokeColor = 'black'
      baseDotty.selectedColor = 'transparent'
    }

    let carcassDotty = new paper.Path.Circle(
      new paper.Point(
        (this.data.w - text.bounds.width) / 2 - this.labelPadding,
        this.data.h + text.bounds.height / 2 + this.labelPadding
      ),
      20
    )
    carcassDotty.fillColor = this.mcarc.uiColour
    carcassDotty.strokeColor = 'black'
    carcassDotty.selectedColor = 'transparent'

    const arrowStyle = {
      justification: 'center',
      fillColor: utils.isColourDark(this.mback.uiColour) ? 'white' : 'black',
      selectedColor: 'transparent',
      fontSize: this.iconSize,
      fontFamily: 'Roboto Mono'
    }

    const numAdjShelf = 2
    let arrowGroup = []
    for (let i = 0; i < numAdjShelf; i++) {
      let yPos
      switch (i) {
        case 0:
          yPos = this.midAdjShelfHeight
          break
        case 1:
          yPos = this.topAdjShelfHeight
          break
      }

      let upIcon = new paper.PointText(new paper.Point(this.data.w / 2, yPos))

      let downIcon = new paper.PointText(new paper.Point(this.data.w / 2, yPos + this.materialThickness))
      downIcon.rotate(180)

      // console.log(this.mback.uiColour, utils.isColourDark(this.mback.uiColour))
      upIcon.style = arrowStyle
      upIcon.content = '^'
      downIcon.style = arrowStyle
      downIcon.content = '^'

      arrowGroup.push(upIcon, downIcon)
    }
    let adjIconGroup = new paper.Group(arrowGroup)

    let lightIconGroup = []
    if (this.data.lighting) {
      const lightIconPath =
        'M748 782 c-32 -13 -436 -374 -441 -394 -14 -50 41 -107 87 -91 36 12 436 363 448 392 12 33 -6 75 -38 90 -28 12 -32 13 -56 3z' +
        'M1296 769 l-26 -20 0 -305 c0 -291 1 -305 20 -324 11 -11 33 -20 50 -20 17 0 39 9 50 20 19 19 20 33 20 324 l0 305 -26 20 c-15 12 -34 21 -44 21 -10 0 -29 -9 -44 -21z' +
        'M1874 778 c-28 -13 -46 -53 -38 -83 7 -27 411 -385 450 -398 46 -16 100 41 87 91 -5 20 -409 381 -441 394 -23 9 -33 8 -58 -4z'

      const makeLightIcon = (posX, posY, deg) => {
        const lightIcon = new paper.CompoundPath(lightIconPath)
        lightIcon.scale(0.04)
        lightIcon.rotate(deg)
        lightIcon.position = new paper.Point(posX, posY)
        lightIcon.fillColor = utils.isColourRed(this.mback.uiColour) ? '#62e0fc' : '#f73123'
        lightIcon.selectedColor = 'transparent'
        return lightIcon
      }

      const lightIconTopL = makeLightIcon(this.data.w / 3, this.materialThickness * 2, 180)
      const lightIconTopR = makeLightIcon((this.data.w * 2) / 3, this.materialThickness * 2, 180)
      const lightIconBotL = makeLightIcon(this.data.w / 3, this.botFixedHeight + this.materialThickness * 2, 180)
      const lightIconBotR = makeLightIcon((this.data.w * 2) / 3, this.botFixedHeight + this.materialThickness * 2, 180)

      lightIconGroup.push(lightIconTopL, lightIconTopR, lightIconBotL, lightIconBotR)
    }

    let hingeIconGroup = []
    if (this.data.pantryHinge) {
      const size = 75
      const s = new paper.Point(-size / 2, -size / 2)
      const m = new paper.Point(-15, 15)
      const e = new paper.Point(size / 2, size / 2)

      const vector = e.subtract(s).normalize(15)
      const makeHingeIcon = (posX, posY) => {
        const hingeIcon = new paper.CompoundPath(
          new paper.Path.Arc(s, m, e),
          new paper.Path([
            e.add(vector.rotate(100)),
            e,
            e.add(vector.rotate(185)) //top
          ])
        )
        hingeIcon.position = new paper.Point(posX, posY)
        hingeIcon.strokeColor = utils.isColourDark(this.mback.uiColour) ? 'white' : 'black'
        hingeIcon.selectedColor = 'transparent'
        return hingeIcon
      }

      let leftHingeIcon
      let rightHingeIcon
      switch (this.data.pantryHinge) {
        case 'l':
          leftHingeIcon = makeHingeIcon(size, this.data.h / 2)
          leftHingeIcon.scale(-1, 1)
          hingeIconGroup.push(leftHingeIcon)
          break
        case 'r':
          rightHingeIcon = makeHingeIcon(this.data.w - size, this.data.h / 2)
          hingeIconGroup.push(rightHingeIcon)
          break
        case 'b':
          leftHingeIcon = makeHingeIcon(size, this.data.h / 2)
          leftHingeIcon.scale(-1, 1)
          rightHingeIcon = makeHingeIcon(this.data.w - size, this.data.h / 2)
          hingeIconGroup.push(rightHingeIcon, leftHingeIcon)
          break
      }
    }

    let labelIconGroup = [text, carcassDotty, adjIconGroup, ...hingeIconGroup] // will always include these

    if (baseDotty && this.data.lighting) {
      labelIconGroup.push(...lightIconGroup, baseDotty)
    } else if (baseDotty && !this.data.lighting) {
      labelIconGroup.push(baseDotty)
    } else if (!baseDotty && this.data.lighting) {
      labelIconGroup.push(...lightIconGroup)
    }

    return new paper.Group(labelIconGroup)
  }

  getDimension() {
    let dimension = new paper.PointText(new paper.Point(this.data.w / 2, (this.data.h * 7) / 8))
    dimension.justification = 'center'
    dimension.fillColor = 'transparent'
    dimension.selectedColor = 'transparent'
    dimension.fontSize = this.labelSize
    dimension.fontFamily = 'Roboto Mono'
    dimension.content = `W${this.data.w
      .toString()
      .toUpperCase()} x H${this.data.h.toString().toUpperCase()} x D${this.data.d.toString().toUpperCase()}`
    return dimension
  }

  getGrainDirection() {
    if (!this.mback || !this.grainDirection) return null

    const grainIconPath =
      'M36.875,74.49023a3.99582,3.99582,0,0,1,.626-5.61328A77.36856,77.36856,0,0,1,65.02979,56.11914c15.38134-3.84375,39.17675-4.78906,65.189,12.55274,18.82227,12.54785,38.5,16.42773,58.48682,11.52734a70.77906,70.77906,0,0,0,24.80175-11.32715,3.99975,3.99975,0,0,1,4.9917,6.251,77.36856,77.36856,0,0,1-27.52881,12.75781,77.24806,77.24806,0,0,1-18.71533,2.31152c-13.38574.001-29.4541-3.51758-46.47363-14.86426C106.959,62.7793,87.28076,58.89941,67.29443,63.80078A70.77906,70.77906,0,0,0,42.49268,75.12793,4.00386,4.00386,0,0,1,36.875,74.49023Z' +
      'm176.63232,50.38184a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.02051-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,124.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90234,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,131.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z' +
      'm0,56a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.01953-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,180.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90332,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,187.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z'

    const makeGrainIcon = (posX, posY, deg) => {
      const grainIcon = new paper.CompoundPath(grainIconPath)
      grainIcon.scale(0.3)
      grainIcon.rotate(deg)
      grainIcon.position = new paper.Point(posX, posY)
      grainIcon.strokeCap = 'round'
      grainIcon.fillColor = 'transparent'
      return grainIcon
    }

    return makeGrainIcon(70, 70, 90)
  }
}
