import paper from 'paper'

export default class JProfile {
  constructor(insertMaterial, width, radius, twoPart) {
    this.insertMaterial = insertMaterial
    this.path
    this.width = width
    this.height = 30
    this.radius = radius
    this.insetX = 0
    this.insetY = 0

    if (twoPart) {
      this.drawTwoPartVariation()
    } else {
      this.draw()
    }
  }

  draw() {
    var rectangle = new paper.Rectangle(new paper.Point(0, 0), new paper.Size(this.width, this.height))
    var cornerSize = new paper.Size(this.radius, this.radius)
    var roundedRectangle = new paper.Path.Rectangle(rectangle, cornerSize)
    var regularRectangle = new paper.Path.Rectangle(
      new paper.Rectangle(new paper.Point(0, this.height / 2), new paper.Size(this.width, this.height / 2))
    )

    this.path = roundedRectangle.unite(regularRectangle)
    this.path.strokeColor = 'black'
    this.path.fillColor = this.insertMaterial ? this.insertMaterial.uiColour : '#FF0000'

    roundedRectangle.remove()
    regularRectangle.remove()
  }

  drawTwoPartVariation() {
    var rectangle = new paper.Rectangle(new paper.Point(0, 0), new paper.Size(this.width - 50, 30))
    var cornerSize = new paper.Size(this.radius, this.radius)
    var roundedRectangle = new paper.Path.Rectangle(rectangle, cornerSize)
    var regularRectangle = new paper.Path.Rectangle(
      new paper.Rectangle(new paper.Point(0, 0), new paper.Size(this.width - 50, 15))
    )

    this.path = roundedRectangle.unite(regularRectangle)
    this.path.strokeColor = 'black'
    this.path.fillColor = this.insertMaterial ? this.insertMaterial.uiColour : '#FF0000'

    roundedRectangle.remove()
    regularRectangle.remove()
  }
}
