<template>
  <v-card id="add-quote">
    <v-card-title class="primary white--text title">New Quote</v-card-title>
    <v-card-text class="pt-6">
      <v-form ref="form">
        <v-select :items="Object.values(productData.regions)" v-model="form.region" label="Region"> </v-select>
        <v-text-field
          v-model="form.customerEmail"
          label="Customer Email"
          v-validate="'required|email'"
          data-vv-name="email"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('email')"
          required
        >
        </v-text-field>
        <v-text-field
          v-model="form.intercomConversation"
          label="Intercom Conversation"
          :autocomplete="uid(10)"
          v-validate="'required|url'"
          data-vv-name="Intercom Conversation"
          data-vv-validate-on="blur"
          :error-messages="errors.collect('Intercom Conversation')"
          required
        >
        </v-text-field>
        <v-autocomplete
          v-model="form.customerType"
          :items="customerTypes"
          label="Customer Type"
          v-validate="'required'"
          data-vv-name="customer type"
          :error-messages="errors.collect('customer type')"
          required
        ></v-autocomplete>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="
          () => {
            $refs.form.reset()
            $validator.reset()
            $emit('cancel')
          }
        "
        >Cancel</v-btn
      >
      <v-btn depressed color="accent" @click="validate" :disabled="submitting">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import uid from 'uid'
import productData from '@/js/productData'

export default {
  name: 'QuoteAdd',
  data() {
    return {
      productData: productData,
      uid: uid,
      submitting: false,
      form: {
        creatorEmail: this.$firebase.auth().currentUser.email,
        deadReason: null,
        region: 'uk',
        dateCreated: this.$firebase.firestore.Timestamp.now(),
        versions: [
          {
            id: null,
            creatorEmail: this.$firebase.auth().currentUser.email,
            published: false,
            title: 'Quote 1',
            date: this.$firebase.firestore.Timestamp.now(),
            fronts: [],
            coverPanels: [],
            spacerPanels: [],
            materials: [],
            urtils: [],
            pantries: [],
            handles: [],
            worktops: [],
            discount: 0,
            discountWorktops: 0,
            pricing: null,
            foc: false
          }
        ]
      },
      customerTypes: [
        { text: 'Home Owner', value: 'Home Owner' },
        { text: 'Architect', value: 'Architect' },
        { text: 'Interior Designer', value: 'Interior Designer' },
        { text: 'Property Developer', value: 'Property Developer' },
        { text: 'Contractor', value: 'Contractor' }
      ]
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then(result => {
        if (result && !this.submitting) {
          this.submitting = true

          // set the default currency on the version
          this.form.versions[0].currency = productData.getDefaultCurrencyForRegion(this.form.region)
          this.form.versions[0].id = uid(10)
          this.submit()
        }
      })
    },
    submit() {
      var doc = this.$db.collection('quotes').doc()
      doc.set(this.form).then(() => {
        this.submitting = false
        this.$refs.form.reset()
        this.$validator.reset()
        this.$emit('complete')
        this.$router.push({
          name: 'quoteEdit',
          params: { quoteID: doc.id }
        })
      })
    }
  }
}
</script>
